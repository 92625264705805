import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useGetFeatureQuery, useUpdateFeatureMutation } from 'store/apiSlice'
import { FeatureStatus } from 'store/dto/feature.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminFeatureDataId, setCurrentAdminFeatureDataId } from 'store/uxSlice'
import { Paper } from 'styles'

export const AdminFeatureEditScreen = () => {
  const currentFeatureId = useAppSelector(currentAdminFeatureDataId)

  const { isLoading: featureIsLoading, error: featureError, data: feature, refetch: refetchFeature } = useGetFeatureQuery(String(currentFeatureId), { skip: !currentFeatureId } )
  const [updateFeature, { data: updatedFeature, isLoading: updateFeatureIsLoading, error: updateFeatureError }] = useUpdateFeatureMutation()
  const dispatch = useAppDispatch()

  const formObj = useForm<{
    status: FeatureStatus,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      status: feature?.status,
    }
  })
  const { handleSubmit, setValue, formState: { isDirty, isValid, errors } } = formObj

  useEffect(() => {
    if (feature) {
      setValue('status', feature?.status)
    }
  }, [feature])

  useEffect(() => {
    if (updatedFeature) {
      close()
    }
  }, [updatedFeature])


  const onSubmit = async attributes => {
    await updateFeature({
      id: feature.id,
      status: attributes?.status,
    })
  }

  const close = () => {
    dispatch(setCurrentAdminFeatureDataId(undefined))
  }

  const isLoading = featureIsLoading || updateFeatureIsLoading
  const error: any = featureError || updateFeatureError

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={featureIsLoading ? undefined : ['Saving...']}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>      
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: feature?.id,
            copyableValue: true,
          },
          {
            label: `Code`,
            value: feature?.code,
          },
          {
            label: `Name`,
            value: feature?.name,
          },
          {
            label: `Description`,
            value: feature?.description,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedAutoCompleteInput
        name={'status'}
        formObj={formObj}
        label={'Status'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(FeatureStatus)}
        required={true}
      />
    </ModalProcessScreen>
  )
}

