import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editAvatarClient, setEditAvatarClient } from 'store/uxSlice'
import { ClientAvatarEditScreen } from './Screens/ClientAvatarEditScreen'

export type ClientAvatarEditModalProps = {
  onDismiss?: any
  theme?: any
}

export const ClientAvatarEditModal = (props: ClientAvatarEditModalProps) => {

  const client = useAppSelector(editAvatarClient)

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditAvatarClient(undefined))

  const visible = !!useAppSelector(editAvatarClient)
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Change Profile Picture'
      onDismiss={onDismiss}
      screen={<ClientAvatarEditScreen
        client={client}
        onDismiss={onDismiss}
      />}
    />
  )
}
