import { ClientSetup_01_Shortcut, ClientSetup_02_FirstName, ClientSetup_03_Surname, ClientSetup_04_Title, ClientSetup_05_Gender, ClientSetup_06_DateOfBirth, ClientSetup_07_Terms, ClientSetup_99_Result } from './Screens'
import React from 'react'
import { ProcessStackBuilder, ProcessStackBuilderScreen } from 'navigation/stacks/ProcessStackBuilder'
import { concat, pick } from 'lodash'
import { InviteDto } from 'store/dto/invite.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'
import { PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { UserDto, UserMetadata } from 'store/dto/user.dto'

export const ClientSetupProcessStack = ({ route, navigation }) => {
  const { existingClient, openInvite, openSchemeInvite, pendingEnrolment }: {
     existingClient: ClientMeDto, openInvite: InviteDto, openSchemeInvite: PublicGroupSchemeInviteDto, pendingEnrolment: PublicPendingSchemeEnrolmentDto }  = route?.params || {}

  const metadata: UserMetadata =
    existingClient ? pick(existingClient, ['firstName', 'surname', 'title', 'gender', 'birthDate'])
    : openInvite?.metadata || openSchemeInvite?.metadata || pendingEnrolment?.metadata
  
  const haveAllMetadata = metadata?.firstName
    && metadata?.surname
    && metadata?.title
    && metadata?.gender
    && metadata?.birthDate

  const processScreens: ProcessStackBuilderScreen[] = concat(
    existingClient || haveAllMetadata ? [
      { 
        navigationId: 'Shortcut',
        component: ClientSetup_01_Shortcut,
      },
    ] : [],
    [
      { 
        navigationId: 'FirstName',
        component: ClientSetup_02_FirstName,
      },
      {
        navigationId: 'Surname',
        component: ClientSetup_03_Surname,
      },
      {
        navigationId: 'Title',
        component: ClientSetup_04_Title,
      },
      {
        navigationId: 'Gender',
        component: ClientSetup_05_Gender,
      },
      {
        navigationId: 'DateOfBirth',
        component: ClientSetup_06_DateOfBirth,
      },
      {
        navigationId: 'Terms',
        component: ClientSetup_07_Terms,
      },
    ]
  )

  return (
    <ProcessStackBuilder
      navigatorId='ClientSetup'
      processTitle='About You'
      previousRoute='ClientSetupIntroScreen'
      nextRoute='ClientSetupHeroScreen'
      initialRouteName={route?.params?.initialRouteName}
      initialParams={{
        ...route?.params,
        metadata,
      }}
      processScreens={processScreens}
      resultScreen={{
        navigationId: 'Results',
        component: ClientSetup_99_Result,
      }}
    />
  )
}
