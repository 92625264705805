import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { MAX_TARGET_RETIREMENT_AGE, MIN_TARGET_RETIREMENT_AGE } from 'lib/constants'
import { Logger } from 'lib/logger'
import { useGuidanceContext } from 'providers'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useGetMeQuery, useGetRetirementProfileQuery, useUpdateRetirementProfileMutation } from 'store/apiSlice'
import { useAppDispatch } from 'store/hooks'
import { setEditRetirementAgeVisible } from 'store/uxSlice'
import { pick } from 'lodash'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'

export const RetirementTargetAgeEditScreen = () => {
  
  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching } = useGuidanceContext()
  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, refetch: refetchRp } = useGetRetirementProfileQuery()  
  const [updateRetirementProfile, { data: updatedRp, error: updatedRpError, isLoading: updatedRpIsLoading }] = useUpdateRetirementProfileMutation()

  const isLoading = meIsLoading || rpIsLoading || currentViableAgeIsLoading || updatedRpIsLoading
  const error: any = meError || rpError || updatedRpError

  //Form refs for focussing
  const targetRetirementAgeRef = useRef()

  //Setup form
  const formObj = useForm<{
    targetRetirementAge: number
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      targetRetirementAge: retirementProfile?.targetRetirementAge
    },
  })
  const { handleSubmit, formState: { isDirty, isValid} } = formObj

  const onSubmit = attributes => {
    Logger.info(`Updating retirement age...`)
    updateRetirementProfile({
      ...attributes,
    })
  }

  //Close on save result
  useEffect(() => {
    if (updatedRp) {
      ampli.retirementProfileUpdate({
        ...pick(updatedRp, [
          'asCouple',
          'insideLondon',
          'expensesNetTotalAmount',
          'targetRetirementAge',
          'baseBudgetLevel',
        ]),
      })
      dispatch(setEditRetirementAgeVisible(false))
    }
  }, [updatedRp])

  const dispatch = useAppDispatch()

  const minTargetRetirementAge = me ? deriveMinimumTargetRetirementAge(me) : MIN_TARGET_RETIREMENT_AGE

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={updatedRpIsLoading ? ['Saving target age...'] : undefined}
      error={error}
      errorTryAgain={meError ? refetchMe : rpError ? refetchRp : handleSubmit(onSubmit)}
      buttonTitle={'Done'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
      headline={'At what age do you want to retire?'}
      subHeading={'This age is used by Jarvis when building financial forecasts'}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.TARGET_RETIREMENT_AGE} />}
    >      
      <ManagedIntegerInput
        ref={targetRetirementAgeRef}
        name={'targetRetirementAge'}
        formObj={formObj}
        label={'Target Age'}
        placeholder={'Enter age at which you would like to retire'}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={false}
        minValue={minTargetRetirementAge}
        maxValue={MAX_TARGET_RETIREMENT_AGE}
      />
        {
          currentViableAge ?
          <>
            <Paragraph>{`Your current achievable age is ${currentViableAge.output.viableRetirementAge}`}</Paragraph>
          </>
          : <></>
        }
    </ModalProcessScreen>
  )
}
