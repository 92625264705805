import { ManagedCardMultipleChoiceInput, ManagedCardMultipleChoiceInputOption } from 'components/Inputs/ManagedCardMultipleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Subheading } from 'components/Typography'
import { RetirementAssetAbandonedModalContent } from 'components/Utility/RetirementAssetAbandonedModalContent'
import { RetirementAssetRetransferModalContent } from 'components/Utility/RetirementAssetRetransferModalContent'
import { RetirementAssetTransferProcessStack } from 'features/ModalProcesses/RetirementAssetTransfer/RetirementAssetTransferProcessStack'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { filterRetransferModifiedAssets, filterRetransferablePensionAssets, isModifiedForRetransfer, isModifiedAndReadyForRetransfer } from 'lib/retirementAssetHelpers'
import { goToMainAppStack } from 'lib/RootNavigation'
import { compact, difference, intersection, pull, union } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SectionList, View } from 'react-native'
import { useGetMeQuery, useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetRetirementAssetsQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { RetirementAssetDto, RetirementAssetTransferStatus, RetirementAssetUntransferableReason } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBulkTransfer, workingBulkTransfer } from 'store/tempDataSlice'
import { Typography, Paper } from 'styles'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

interface ReturnType {
  title: string,
  data: {
    id: 'ready' | 'onHold' | 'rejected' | 'abandoned'
    assets: RetirementAssetDto[]
  }[]
}

export const BulkRetransfer_01_Assets = ({ route, navigation }) => {
  const { nextScreen } = route.params

  const dispatch = useAppDispatch()

  const [ updateAsset, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading } ] = useUpdateRetirementAssetMutation()

  const workingBulkTransferData = useAppSelector(workingBulkTransfer)

  const [reprepareAssetData, setReprepareAssetData] = useState(undefined)
  const [doneDefaulting, setDoneDefaulting] = useState(false)
  const [deselectedPensionIds, setDeselectedPensionIds] = useState([])
  
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()
  const { data: me, isLoading: meIsLoading, error: meError, refetch: refetchMe } = useGetMeQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const refetchAll = () => {
    refetchBrands()
    refetchProviders()
    refetchMe()
    refetchAssets()
  }

  const isLoading = meIsLoading || assetsIsLoading || brandsIsLoading || providersIsLoading || assetsIsFetching
  const error: any = meError || assetsError || brandsError || providersError

  //Setup form
  const formObj = useForm<{
    assetIdsToTransfer: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      assetIdsToTransfer: workingBulkTransferData?.assetIdsToTransfer || []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid } } = formObj

  const usableAssets = filterRetransferablePensionAssets(assets, me)

  const abandonTransfer = (id) => {
    updateAsset({
      id,
      transferStatus: RetirementAssetTransferStatus.ABANDONED,
    })
  }

  //Update the asset IDs only one time...
  useEffect(() => {
    if (!doneDefaulting) {
      //First pass - set everything ready as selected
      setValue('assetIdsToTransfer', filterRetransferModifiedAssets(usableAssets, me).map(asset => asset.id), { shouldDirty: true })
      setDoneDefaulting(true)
    } else {
      //Subsequent passes
      const readyAssetIds = filterRetransferModifiedAssets(usableAssets, me).map(asset => asset.id)
      const readyAndPreviouslySelected = intersection(readyAssetIds, selectedAssets)
      const otherReadyAndNotDeselected = difference(readyAssetIds, selectedAssets, deselectedPensionIds)
      const newSelected = union(readyAndPreviouslySelected, otherReadyAndNotDeselected)
      setValue('assetIdsToTransfer', newSelected, { shouldDirty: true })
    }
  }, [assets])

  const hasAssets = usableAssets && usableAssets.length
  const selectedAssets = watch('assetIdsToTransfer')

  const onSubmit = (attributes) => {
    const assetIdsToTransfer = compact(attributes.assetIdsToTransfer) 
    dispatch(updateWorkingBulkTransfer({
      assetIdsToTransfer,
    }))
    //Go to next screen if any selectableAssets, else to result
    navigation.navigate(nextScreen)
  }

  const buildAssetsList = (): ReturnType[] => {
    let ready = []
    let onHold = []
    let rejected = []
    let abandoned = []
    if (usableAssets && me) {
      usableAssets.forEach(asset => {
        //On hold if client name mismatch
        const assetOnHold = asset?.untransferableReason === RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH
      
        if (isModifiedAndReadyForRetransfer(asset)) {
          ready.push(asset)
        } else if (assetOnHold) {
          onHold.push(asset)
        } else if (asset.transferStatus === RetirementAssetTransferStatus.ABANDONED) {
          abandoned.push(asset)
        } else {
          rejected.push(asset)
        }
      })
      return [
        { title: 'Ready', data: [{ id: 'ready', assets: ready}] },
        { title: 'On Hold', data: [{ id: 'onHold', assets: onHold}] },
        { title: 'Rejected', data: [{ id: 'rejected', assets: rejected}] },
        { title: 'Abandoned', data: [{ id: 'abandoned', assets: abandoned}] },
      ]
    }
    return []
  }

  const assetsList = buildAssetsList()

  const selectAsset = (asset: RetirementAssetDto) => {
    //Remove from deselected list and add to selected array
    setDeselectedPensionIds(difference(deselectedPensionIds, [asset.id]))
    setValue('assetIdsToTransfer', union(selectedAssets, [asset.id]), { shouldDirty: true })
  }

  const deselectAsset = (asset: RetirementAssetDto) => {
    //Put in deselected list and remove from selected array
    setDeselectedPensionIds(union(deselectedPensionIds, [asset.id]))
    setValue('assetIdsToTransfer', pull(selectedAssets, asset.id), { shouldDirty: true })
  }

  const AssetSection = ({ item }) => {
    const { id, assets } = item
    const isReady = id === 'ready'

    const options: ManagedCardMultipleChoiceInputOption[] = assets.map(asset => {

      const isSelected = selectedAssets.includes(asset.id)

      const provider = providers ? providers.find(provider => {
        return provider.id === asset?.pensionProviderId
      }) : undefined

      const providerBrand = provider && brands ? brands.find(brand => {
        return brand.key === provider.primaryBrandKey
      }) : undefined

      const imageSource = providerBrand?.logo ? { uri: providerBrand?.logo } : require('assets/icons/default_provider.png')

      const base: Partial<ManagedCardMultipleChoiceInputOption> = {
        value: isReady ? asset.id : undefined,
        title: asset.name,
        imageSource,
      }

      switch (id) {
        case 'ready':
          return {
            ...base,
            description: isSelected ? 'Tap to deselect' : 'Tap to select to try again',
            onPress: isSelected ? () => deselectAsset(asset) : () => selectAsset(asset),
            modalContentFunction: () => <RetirementAssetRetransferModalContent
              asset={asset}
              isReady={true}
              imageSource={imageSource}
              provider={provider}
              isSelected={isSelected}
              abandonAssetAction={() => abandonTransfer(asset.id)}
              editAssetAction={() => setReprepareAssetData({ asset, client: me})}
            />,
            modalProceedLabel: isSelected ? `Don't try again` : 'Select to Try Again',
            modalCancelLabel: isSelected ? 'Leave selected' : 'Leave unselected',
          }
        case 'onHold':
          return {
            ...base,
            description: 'Tap to finish preparation',
            onPress: () => setReprepareAssetData({ asset, client: me}),
            modalContentFunction: () => <RetirementAssetRetransferModalContent
              asset={asset}
              isReady={false}
              imageSource={imageSource}
              provider={provider}
              isSelected={isSelected}
              abandonAssetAction={() => abandonTransfer(asset.id)}
              editAssetAction={() => {}}
            />,
            modalProceedLabel: 'Continue preparation',
            modalCancelLabel: 'Cancel',
          }
        case 'rejected':
          return {
            ...base,
            description: 'Tap to review and resolve',
            onPress: () => setReprepareAssetData({ asset, client: me}),
            modalContentFunction: () => <RetirementAssetRetransferModalContent
              asset={asset}
              isReady={false}
              imageSource={imageSource}
              provider={provider}
              isSelected={isSelected}
              abandonAssetAction={() => abandonTransfer(asset.id)}
              editAssetAction={() => {}}
            />,
            modalProceedLabel: 'Re-attempt Transfer',
            modalCancelLabel: 'Cancel',
          }
        // case 'onHold':
        //   return {
        //     ...base,
        //     description: 'Tap to finish preparation',
        //     onPress: () => setReprepareAssetData({ asset, client: me}),
        //   }
        // case 'rejected':
        //   return {
        //     ...base,
        //     description: 'Tap to review and resolve',
        //     onPress: () => setReprepareAssetData({ asset, client: me}),
        //   }
        default:
          return {
            ...base,
            description: 'Tap for more information',
            onPress: undefined,
            modalContentFunction: () => <RetirementAssetAbandonedModalContent asset={asset} imageSource={imageSource} isSelected={isSelected}/>,
            modalProceedLabel: 'Close',
            modalCancelLabel: undefined,
          }
      }
    })  

    return (
      <View key={id}>
        {
        assets?.length ?
          <ManagedCardMultipleChoiceInput
            formObj={formObj}
            name={'assetIdsToTransfer'}
            options={options}
          />
          : <></>
        }
      </View>
      )
    }


  const { colors: themeColors } = Paper.useAppTheme()
  const transferCount = watch('assetIdsToTransfer').length
  const readyAssetCount = filterRetransferModifiedAssets(usableAssets, me).length

  const renderHeader = (section: any) => {
    return <Subheading style={Typography.defined.sectionListHeader}>{section?.title || ''} </Subheading>
  }

  return (
    <>
      {
        reprepareAssetData ?
          <RetirementAssetTransferProcessStack
            asset={reprepareAssetData?.asset}
            client={reprepareAssetData?.client}
            onDismiss={() => setReprepareAssetData(undefined)}
          /> :
          <ProcessScreen
            isLoading={isLoading}
            loadingMessage={getLookingLoadingMessages('Loading your transfers...')}
            error={error}
            errorTryAgain={refetchAll}
            buttonTitle={hasAssets
              ? selectedAssets?.length ? `Proceed with ${transferCount} Pension${transferCount === 1 ? '' : 's'}` : readyAssetCount ? `Select at least one` : `Resolve at least one`
              : `Close`
            }
            buttonAction={hasAssets
              ? handleSubmit(onSubmit)
              : goToMainAppStack
            }
            showButton={true}
            enableButton={!hasAssets || !!selectedAssets?.length}
            headline={`Review rejected transfers`}
            subHeading={`Use the cards to review issues and correct information to reattempt transfers`
            }
          >
            <SectionList
              showsVerticalScrollIndicator={isWeb}
              stickySectionHeadersEnabled={false}
              sections={assetsList}
              // keyExtractor={({ id }, index) => id}
              renderSectionHeader={({ section }) => (
                section?.data[0]?.assets?.length > 0 ? renderHeader(section) : <></>
              )}
              renderItem={({ item }) => <AssetSection item={item} />}
            />
          </ProcessScreen>
      }
    </>
  )
}