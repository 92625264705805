import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { Paragraph, Subheading } from "components/Typography"
import { FooterButton } from 'components/Utility/FooterButton'
import React, { useState } from 'react'
import { Image, ScrollView, View } from "react-native"
import { GroupSchemeDto, GroupSchemeStatus } from "store/dto/group-scheme.dto"
import { Colors, Paper, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { DetailedListItem } from "./DetailedListItem"
import { OptionalSectionList } from "./OptionalSectionList"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from "lib/platformHelpers"
import { setSnackbarData } from "store/uxSlice"
import { useAppDispatch } from "store/hooks"

type EmployerSchemeInfoSwitchModalProps = {
  currentScheme: GroupSchemeDto
  otherSchemes: GroupSchemeDto[]
  visible: boolean
  onDismiss: any
  changeSchemeFunction: Function
}

const isWeb = platformIsWeb()

export const EmployerSchemeInfoSwitchModal = (props: EmployerSchemeInfoSwitchModalProps) => {
  const { visible, onDismiss, currentScheme, otherSchemes, changeSchemeFunction } = props

  const [refreshing, setRefreshing] = useState(false)

  const dispatch = useAppDispatch()

  const hasOtherSchemes = otherSchemes?.length > 0

  const { colors: themeColors } = Paper.useAppTheme()

  const { name: schemeName, organization, employerPensionSchemeReference } = currentScheme || {}
  const { logo, name: organizationName, advisorEmail, advisorName, primaryContactUser } = organization || {}

  const handleSchemeSwitch = (groupScheme: GroupSchemeDto) => {
    dispatch(setSnackbarData({
      message: `You have switched scheme`,
      subMessage: `You are now working in scheme '${groupScheme.name}' for organisation '${groupScheme?.organization?.displayName}'`,
      iconName: 'alert-circle-outline',
      duration: 5000,
    }))
    changeSchemeFunction(groupScheme)
    onDismiss()
  }

  const otherSchemeList = () => {
    let schemes = []
    if (otherSchemes.length) {
      otherSchemes.map((scheme, id) => {
        schemes.push(scheme)
      })
      return [
        { title: 'Switch Scheme', data: schemes },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
  const { name: schemeName, status, organization, employerPensionSchemeReference } = item as GroupSchemeDto
  const { logo } = organization || {}

  const titleRightStyle = {
    color: status === GroupSchemeStatus.INACTIVE ? themeColors.error : Colors.brand.purple1
  }
    return (
      <DetailedListItem
        icon={logo
          ? <Image source={{ uri: logo }} style={{
              width: Sizing.x25,
              height: Sizing.x25,
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />
          : <MaterialCommunityIcons name={'dots-horizontal'} size={Sizing.x25} color={themeColors.primary} />
        }
        title={schemeName}
        titleRight={status}
        titleRightStyle={titleRightStyle}
        subTitle={employerPensionSchemeReference}
        titleStyle={{ color: themeColors.primary }}
        subTitleStyle={{ color: themeColors.primary }}
        onPress={() => handleSchemeSwitch(item)}
      />
    )
  }
  

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                  <Image source={logo ? { uri: logo} : undefined} style={{
                    width: Sizing.x100,
                    height: Sizing.x100,
                    resizeMode: 'contain',
                    alignSelf: 'center',
                  }} />
                  <Subheading>{organizationName}</Subheading>
              </View>
            </View>
            <ContentDivider />
            <Subheading style={{ color: themeColors.primary }}>{`Current Scheme`}</Subheading>
            <Paragraph>{`Scheme Name: ${schemeName}`}</Paragraph>
            <Paragraph>{`Employer Pension Scheme Reference: ${employerPensionSchemeReference}`}</Paragraph>
            <Paragraph>{`Advisor: ${advisorName} (${advisorEmail})`}</Paragraph>
            <Paragraph>{`Employer Contact: ${primaryContactUser?.firstName} ${primaryContactUser?.surname} (${primaryContactUser?.email})`}</Paragraph>           
            
            {
              hasOtherSchemes
                ? <>
                    <ContentDivider />
                    <OptionalSectionList
                      sections={otherSchemeList()}
                      renderItem={renderItem}
                      noDataMessage={`No other schemes available`}
                      noRefresh={true}
                    />
                  </>
                : <></>
            }
          </ScrollView>
          <FooterButton mode={'text'} onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}
