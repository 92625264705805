import { ModalProcessScreen } from 'components/Layout'
import { Text } from 'components/Typography/Text'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { ampli } from 'src/ampli'
import { useAddContributionMutation, useGetBankConnectionsQuery, useUpdateMeMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { workingContributionSetup, workingLumpSumContributionPayment } from 'store/tempDataSlice'
import { Sizing } from 'styles'
import { BankLink } from '../Components/BankLink'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'

export const MakeContributions_19_LumpSumSave = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}

  const workingLumpSumContributionPaymentData: any = useAppSelector(workingLumpSumContributionPayment)

  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()

  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()
  const [createContribution, { data: newContribution, error: newContributionError, isLoading: newContributionIsLoading }] = useAddContributionMutation()

  const isLoading = banksIsLoading || newContributionIsLoading || clientUpdateIsLoading
  const error: any = banksError || newContributionError || clientUpdateError

  // Save on get banks
  useEffect(() => {
    if (banks) {
      saveContribution()
    }
  }, [banks])

  //Save client when contribution saved
  useEffect(() => {
    if (newContribution) {
      ampli.contributionAdd({
        amount: workingLumpSumContributionPaymentData?.amount,
      })
      saveClient()
    }
  }, [newContribution])

  const saveContribution = () => {
    Logger.info(`Creating contribution...`)
    createContribution({
      ...workingLumpSumContributionPaymentData,
      accountId: contributionsData?.account?.id,
    })
  }

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        contribute: true,
      },
    })
  }

  //Clear data and either close or next step
  const next = () => {
    navigation.navigate(nextScreen)
  }

  const workingContributionData = useAppSelector(workingContributionSetup)
  const contributionConfiguration = contributionsData?.existingContributionConfiguration || workingContributionData

  const manualSetup = workingLumpSumContributionPaymentData?.manualSetup

  const contributionBank = banks ? banks.find(bank => {
    return bank.moneyHubId === contributionConfiguration?.bankId
  }) : undefined

  const paymentData: UnborderedTableRow[] = [
    {
      label: `Amount`,
      value: formatCurrencyAmount(workingLumpSumContributionPaymentData?.amount, 2),
    },
    {
      label: `Reference`,
      value: contributionConfiguration?.paymentReference,
    },
    {
      label: `Payment Date`,
      value: format(new Date(), 'do MMMM'),
    },
  ]

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Finalizing contribution...']}
      error={error}
      errorTryAgain={banksError ? refetchBanks : newContribution ? saveClient : saveContribution}
      errorCancel={onDismiss}
      buttonTitle={'Next'}
      buttonAction={next}
      showButton={true}
      headline={manualSetup ? `Ok, we're expecting the following contribution` : `Ok, we've set that contribution up`}
      subHeading={`It may take 24-48 hours for the contribution to reach your ${JAR_NAME_PERSONAL}`}
    >
      {
        contributionBank ? <BankLink bank={contributionBank} isComplete={true} /> : <></>
      }
      <View style={{ paddingVertical: Sizing.x10 }}>
        <UnborderedTable
          itemContainerStyle={{
            paddingVertical: Sizing.x5,
          }}
          data={paymentData?.map(item => {
            return {
              ...item,
              value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
            }
          })}
        />
      </View>
    </ModalProcessScreen>
  )
}
