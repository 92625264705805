import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminAssetDataId, setCurrentAdminAssetDataId } from 'store/uxSlice'
import { AdminEditAssetScreen } from './Screens/AdminEditAssetScreen'

export const AdminEditAssetsModal = () => {
  const dispatch = useAppDispatch()
  const currentAsset = useAppSelector(currentAdminAssetDataId)
  return (
    <ModalFormBuilder
      visible={!!currentAsset}
      formTitle='Edit Asset Details'
      onDismiss={() => dispatch(setCurrentAdminAssetDataId(undefined))}
      screen={<AdminEditAssetScreen />}
    />
  )

}

