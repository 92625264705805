import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from 'components/Layout/CardModal'
import { Paragraph } from 'components/Typography'
import { getDiagnosticInformation, getDiagnosticString, InformationSectionKey } from "lib/appInfoHelpers"
import { composeIntercomMessage, composeIntercomMessageForUnregistered } from "lib/intercomHelpers"
import { goToDashboard, goToLogout } from "lib/RootNavigation"
import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from "react-native"
import { Portal, ThemeProvider } from 'react-native-paper'
import { useGetCurrentUserQuery, useGetMeQuery } from "store/apiSlice"
import { ErrorDto } from 'store/dto/error.dto'
import { Colors, Flex, Paper, Sizing } from "styles"
import { backgroundStyles, layoutStyles } from 'styles/common'
import { Button } from './Button'
import { FooterButton } from "./FooterButton"
import { captureException } from 'platform-lib/sentry'
import { Logger } from "lib/logger"
import { platformIsWeb } from "lib/platformHelpers"
import { useSelector } from "react-redux"

type ErrorProps = {
  iconOnly?: boolean
  iconOnlySize?: number
  error?: ErrorDto
  noDashboardButton?: boolean
  noLogoutButton?: boolean
  errorTryAgain?: any
  errorCancel?: any
}

const isWeb = platformIsWeb()

export const ErrorScreen = (props: ErrorProps) => {
  const { error, iconOnly, iconOnlySize, noDashboardButton, noLogoutButton, errorTryAgain, errorCancel } = props

  const userHasClient = useSelector((state: any) => state.auth.userHasClient)

  const { colors: themeColors } = Paper.useAppTheme()
  
  const { data: user, isLoading: userIsLoading, error: userError } = useGetCurrentUserQuery()
  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery(undefined, { skip: !(user && userHasClient) } )

  const [modalVisible, setModalVisible] = useState(false)
  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const isNetwork = error?.code === 'FETCH_ERROR'
  const isMaintenance = error?.code === 'MAINTENANCE_MODE'
  const isTimeout = error?.code === 'SERVICE_TIMEOUT'

  const iconName = isNetwork
    ? 'access-point-network-off'
    : isMaintenance
      ? 'progress-clock'
      : isTimeout
        ? 'clock-alert-outline'
        : 'alert-rhombus'
  const iconColor = isNetwork
    ? Colors.neutral.white
    : isMaintenance
      ? Colors.brand.red3
      : isTimeout
        ? Colors.warning.s400
        : Colors.warning.s400

  const message = error?.userMessage || `Something went wrong`

  const sendErrorViaIntercom = async () => {
    const informationArray = getDiagnosticInformation(user, client, error)
    const infoBody = getDiagnosticString(informationArray, [
      InformationSectionKey.DATE,
      InformationSectionKey.USER,
      InformationSectionKey.APP,
      InformationSectionKey.DEVICE,
      InformationSectionKey.ERROR,
    ])

    let initialMessage = `----- Jarvis Error Report -----\n\n`
    initialMessage += infoBody
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Please add any additional information about this error below and hit the send button!\n`
    initialMessage += `---------------------------------------\n`
    if (user) {
      await composeIntercomMessage(initialMessage)
    } else {
      await composeIntercomMessageForUnregistered(initialMessage)
    }
  }

  const sendErrorFromModal = () => {
    sendErrorViaIntercom()
    hideModal()
  }

  const retry = () => {
    hideModal()
    errorTryAgain()
  }

  //Send error to Sentry
  useEffect(() => {
    if (!isNetwork && !isMaintenance) {
      Logger.info(`Capturing exception to Sentry...`)
      captureException(new Error(error?.developerMessage || error?.userMessage || 'An error occurred'),{
        extra: {
          userMessage: error?.userMessage,
          developerMessage: error?.developerMessage,
          code: error?.code,
        },
      })
    }
    }, [])

  const ErrorContent = () => {

    const { colors: themeColors } = Paper.useAppTheme()

    return (
      <View style={localStyles.container}>
        {
          <MaterialCommunityIcons name={iconName} size={Sizing.x100} color={iconColor}/>
        }
        <Paragraph style={{ paddingTop: Sizing.x30 }}>{
          isTimeout
            ? `Jarvis is busy serving a high volume of users right now.`
            : isMaintenance
              ? `Jarvis is currently offline for maintenance.`
              : message
          }
        </Paragraph>
        {
          isTimeout
            ? <View style={{ paddingTop: Sizing.x10, flexDirection: 'column', alignItems: 'center' }}>
                <MaterialCommunityIcons name={'run-fast'} size={Sizing.x50} color={themeColors.primary}/>
                <Paragraph>{`Please try again shortly`}</Paragraph>
              </View>
            : isMaintenance
              ? <View style={{ paddingTop: Sizing.x10, flexDirection: 'column', alignItems: 'center' }}>
                  <MaterialCommunityIcons name={'tools'} size={Sizing.x50} color={themeColors.primary}/>
                  <Paragraph>{`We'll be back with you as soon as possible!`}</Paragraph>
                </View>
              : <></>
        }
        {
          errorTryAgain ?
            <View style={localStyles.buttonContainer}>
              <Button
                  mode={'contained'}
                  onPress={retry}
                >{isMaintenance ? 'Check again now' : 'Try again'}</Button>
            </View>
              : <></>
        }
        {
          errorCancel ?
          <View style={localStyles.buttonContainer}>
            <Button
              mode={'text'}
              color={themeColors.accent}
              onPress={errorCancel}
            >{'Cancel'}</Button>
          </View>
          : <></>
        }

      </View>
    )
  }
  
  return (
    <>
      { iconOnly ?
        <>
          <Portal>
            <CardModal visible={modalVisible} onDismiss={hideModal} >
              <View style={layoutStyles.cardModalTitleContainer}>
                <View></View>
                <View style={layoutStyles.cardModalTitleTextContainer}></View>
                <View>
                  <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={hideModal} color={Colors.brand.purple1}/>
                </View>
              </View>
              <View style={layoutStyles.cardModalContentContainer}>
                <ScrollView
                  showsVerticalScrollIndicator={true}
                >
                  <ThemeProvider theme={ Paper.darkTheme }>
                    <ErrorContent />
                  </ThemeProvider>
                </ScrollView>
                {
                  noDashboardButton ? <></> :
                    <FooterButton
                      mode={'text'} 
                      color={themeColors.accent}
                      onPress={goToDashboard}>
                        {'Return to Dashboard'}
                    </FooterButton>
                }
                {
                  noLogoutButton ? <></> :
                    <FooterButton
                      mode={'text'} 
                      color={themeColors.accent}
                      onPress={goToLogout}>
                        {'Logout'}
                    </FooterButton>
                }
                {
                  isWeb ? <></>  : <FooterButton onPress={sendErrorFromModal}>{`Send to Support`}</FooterButton>
                }
              </View>

            </CardModal>
          </Portal>
          <View style={localStyles.container}>
            {            
              <MaterialCommunityIcons name={'alert-rhombus'} size={iconOnlySize || Sizing.x100} color={Colors.warning.s400} onPress={showModal}/>
            }
          </View>
        </>
        :
        <View style={backgroundStyles.pageContainer}>
            <View style={[layoutStyles.mainContentContainer, { alignItems: 'center'}]}>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={true}
              >
                <ErrorContent />
                {
                  isMaintenance ? <></> :
                    <>
                    {
                      noDashboardButton ? <></> :
                        <Button
                          mode={'text'}
                          color={themeColors.accent}
                          onPress={goToDashboard}
                        >
                          {'Return to Dashboard'}
                        </Button>
                    }
                    {
                      noLogoutButton ? <></> :
                        <Button
                          mode={'text'} 
                          color={themeColors.accent}
                          onPress={goToLogout}>
                            {'Logout'}
                        </Button>
                    }
                    {
                      isWeb ? <></>  : 
                        <View style={localStyles.buttonContainer}>
                          <Button onPress={sendErrorViaIntercom}>{`Send to Support`}</Button>
                        </View>
                    }
                    </>
                }
              </ScrollView>
            </View>
          </View>
      }
    </>
  )
}


const localStyles = StyleSheet.create({
  container: {
    ...Flex.column.center,
    flex:1,
    alignItems: 'center',
    // paddingVertical: Sizing.x10,
  },
  outerContainer: {
    ...Flex.column.between,  
    alignItems: 'center'
  },
  innerContainer: {
    alignItems: 'center',
    paddingHorizontal: Sizing.x20,
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    paddingVertical: Sizing.x10,
    paddingHorizontal: Sizing.x30,
  },
  title: {
    textAlign: 'center'
  },
  text: {
    textAlign: 'center',
  }
})