import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation, useUpdateSpouseMutation } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { StateBenefitDto } from 'store/dto/reference-data.dto'

interface RetirementIncomesEditStatePensionScreenProps {
  client: ClientBaseDto
  onDismiss: any
  disabled: boolean
  stateBenefit: StateBenefitDto
}

export const RetirementIncomesEditStatePensionScreen = (props: RetirementIncomesEditStatePensionScreenProps) => {
  const { onDismiss, disabled, stateBenefit, client } = props

  const [updateMe, { data: updatedMe, isLoading: updateMeIsLoading, error: updateMeError }] = useUpdateMeMutation()
  const [updateSpouse, { data: updatedSpouse, isLoading: updateSpouseIsLoading, error: updateSpouseError }] = useUpdateSpouseMutation()

  const isLoading = updateMeIsLoading || updateSpouseIsLoading
  const error: any = updateMeError || updateSpouseError

  //Setup form
  const formObj = useForm<{statePensionAmount: number}>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      statePensionAmount: client?.statePensionAmount
    }
  })
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: {isDirty, isValid}
  } = formObj

  const onSubmit = attributes => {
    if (client?.isSpouse) {
      Logger.info(`Updating spouse...`)
      updateSpouse(attributes)
    } else {
      Logger.info(`Updating client...`)
      updateMe(attributes)
    }
  }

  useEffect(() => {
    if (updatedMe || updatedSpouse) {
      ampli.statePensionSet({
        amount: updatedSpouse ? updatedSpouse?.statePensionAmount : updatedMe?.statePensionAmount,
        isSpouse: updatedSpouse ? true : false,
      })
      onDismiss()
    }
  }, [updatedMe, updatedSpouse])

  const setFullAmount = () => {
    setValue('statePensionAmount', stateBenefit?.maxAmount, { shouldDirty: true } )
    trigger('statePensionAmount')
  }

  return (
    <ModalProcessScreen
      error={error}
      isLoading={isLoading}
      loadingMessage={['Saving...']}
      errorTryAgain={handleSubmit(onSubmit)}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      {
        disabled
        ? <Paragraph>{`This information is read only because you have linked your account with ${client?.firstName}.`}</Paragraph>
        : <></>
      }
      <ManagedCurrencyInput
        name={'statePensionAmount'}
        autoFocus={true}
        formObj={formObj}
        // label={'Eligible State Pension'}
        placeholder={'Expected annual amount (or zero)'}
        disabled={disabled}
        required={true}
        minValue={0}
        maxValue={stateBenefit?.maxAmount}
        blurOnSubmit={false}
      />
      { disabled ? <></> :
        <Button mode='text' onPress={setFullAmount}>
          {'Set Full Amount'}
        </Button>
      }
    </ModalProcessScreen>
  )
}
