import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { envVariables } from 'environment'
import { AUTHTYPE } from './dto/base.dto'
// import { TokenResponseConfig } from 'expo-auth-session'

const { apiUrl } = envVariables

const noAuthHeaders = (headers) => {
  headers.set('Content-type', 'application/json')
  return headers
}

export interface PasswordlessStartDto {
  email: string
}

export interface PasswordlessTokenDto {
  email: string
  code: string
}

export interface PasswordlessHandoffDto {
  email: string
  authType: AUTHTYPE
}

export const passwordless = createApi({
  reducerPath: 'passwordless',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: noAuthHeaders,
  }),
  tagTypes: [],
  endpoints: (build) => ({
    passwordlessStart: build.mutation<any, PasswordlessStartDto>({
      query(body) {
        return {
          url: `passwordless/start`,
          method: 'POST',
          body,
        }
      },
    }),
    passwordlessToken: build.mutation<any, PasswordlessTokenDto>({
      query(body) {
        return {
          url: `passwordless/token`,
          method: 'POST',
          body,
        }
      },
    }),
    sendHandOffLink: build.mutation<{ completed: boolean }, PasswordlessHandoffDto>({
      query(body) {
        return {
          url: `passwordless/send-handoff-link`,
          method: 'POST',
          body,
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
    }),
  }),
})

export const {
  usePasswordlessStartMutation,
  usePasswordlessTokenMutation,
  useSendHandOffLinkMutation,
} = passwordless