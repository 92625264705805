  import { MaterialCommunityIcons } from '@expo/vector-icons'
import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ManagedSimpleChoiceInput } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { ContributionRecord, EmployerContributionSetChoice } from 'features/ModalProcesses/EmployerAddContributionJob/Screens/EmployerAddContributionJobScreen'
import { formatUkDate } from 'lib/dateHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { Gender } from 'store/dto/base.dto'
import { Colors, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { useAppTheme } from 'styles/paper'

type EmployerContributionSetProps = {
  formObj: UseFormReturn<any>
  name: string
}

const isWeb = platformIsWeb()

export const MAX_CONTRIBUTION_AMOUNT = 9999999.99

export const EmployerContributionSet = (props: EmployerContributionSetProps) => {
  const { formObj, name } = props
  const { control, watch, setValue, trigger } = formObj
  const { fields } = useFieldArray({
    control,
    name,
  })

  const setAmounts = (index: number, employerContributionAmount: number, employeeContributionAmount: number)  => {
    setValue(`${name}.${index}.data.employerContributionAmount`, employerContributionAmount, { shouldDirty: true, shouldValidate: true })
    setValue(`${name}.${index}.data.employeeContributionAmount`, employeeContributionAmount, { shouldDirty: true, shouldValidate: true })
  }

  const setNilAmounts = index => {
    setValue(`${name}.${index}.data.employerContributionAmount`, 0, { shouldDirty: true, shouldValidate: true })
    setValue(`${name}.${index}.data.employeeContributionAmount`, 0, { shouldDirty: true, shouldValidate: true })
  }

  return (
    <View style={layoutStyles.inputContainer}>
      {
        fields.map((field, index) => {
          const data: ContributionRecord = watch(`${name}.${index}.data`)
          const {
            firstName,
            surname,
            nationalInsuranceNo,
            employeeId,
            employeeEmail,
            gender,
            birthDate,
            choice,
            lastEmployerContributionAmount,
            lastEmployeeContributionAmount,
            enrolmentEndDate,
            autoEnrolmentOptOutDate,
            autoEnrolmentWindowOptOutDate,
            inOptOutWindow,
            employerContributionAmount,
            employeeContributionAmount,
          } = data 

          const hasLastAmounts = lastEmployerContributionAmount !== 0 || lastEmployeeContributionAmount !== 0
          const isForcedNilReturn = !!autoEnrolmentOptOutDate || inOptOutWindow
          const isDisabled = choice !== EmployerContributionSetChoice.NEW
          const needsValues = employerContributionAmount === 0 && employeeContributionAmount === 0

          const informationMessage = choice === EmployerContributionSetChoice.NIL
            ? !!enrolmentEndDate
              ? `Ceased membership on ${formatUkDate(new Date(enrolmentEndDate))}. Only submit contributions where they are still due.`
              : !!autoEnrolmentOptOutDate
                ? `Opted out on ${formatUkDate(new Date(autoEnrolmentOptOutDate))}. You may not submit contributions.`
                : inOptOutWindow
                  ? `Within Opt Out window${autoEnrolmentWindowOptOutDate ? ` until ${formatUkDate(new Date(autoEnrolmentWindowOptOutDate))}` : ``}. You may only submit contribtuions after this window ends.`
                  : undefined
            : choice === undefined
              ? hasLastAmounts
                ? `Last contributions amounts were ${formatCurrencyAmount(lastEmployerContributionAmount || 0, 2)} + ${formatCurrencyAmount(lastEmployeeContributionAmount || 0, 2)} (Employer + Employee).`
                : `No last contributions amounts are available`
              : undefined

          const informationMessageIsError = choice === EmployerContributionSetChoice.NEW && needsValues

          const disabledChoices: EmployerContributionSetChoice[] = isForcedNilReturn
            ? [
                EmployerContributionSetChoice.LAST,
                EmployerContributionSetChoice.NEW,
              ]
            : hasLastAmounts
              ? []
              : [
                  EmployerContributionSetChoice.LAST,
                ]

          const atLeastOneAmountRequired = (value: string) => {
            const choice = watch(`${name}.${index}.data.choice`)

            if (choice === undefined) {
              return `Please make a selection`
            } 

            const employerContributionAmount = watch(`${name}.${index}.data.employerContributionAmount`)
            const employeeContributionAmount = watch(`${name}.${index}.data.employeeContributionAmount`)

            if (choice === EmployerContributionSetChoice.NEW && !employerContributionAmount && !employeeContributionAmount) {
              return 'Enter at least one amount'
            } else {
              return true
            }
          }

          return (
            <View
              key={field.id}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ flex: 1 }}>
                  <View style={{flexDirection: 'column', justifyContent: 'flex-start'}}>
                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}>
                      <View style={{
                        paddingHorizontal: Sizing.x10,
                      }}>
                        <View style={[localStyles.genderImage, {
                          backgroundColor: gender === Gender.FEMALE ? Colors.brand.red3 : Colors.brand.blue3,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }]}>
                          <MaterialCommunityIcons name={gender === Gender.FEMALE ? 'human-female' : 'human-male'} size={Sizing.x20} color={Colors.neutral.white}/>
                        </View>
                      </View>
                      <View style={{flexDirection: 'column', justifyContent: 'center'}}>
                        <Subheading style={{ textAlign: 'left', fontWeight: 'bold' }}>{`${firstName} ${surname}`}</Subheading>
                      </View>
                    </View>
                    <View style={{
                      paddingTop: Sizing.x10,
                      paddingLeft: Sizing.x10,
                    }}>
                      <BulletItem style={{ textAlign: 'left' }}>{`National Insurance No: ${nationalInsuranceNo}`}</BulletItem>
                      <BulletItem style={{ textAlign: 'left' }}>{`Employee ID: ${employeeId}`}</BulletItem>
                      <BulletItem style={{ textAlign: 'left' }}>{`Employee Email: ${employeeEmail}`}</BulletItem>
                      <BulletItem style={{ textAlign: 'left' }}>{`Date of Birth: ${formatUkDate(new Date(birthDate))}`}</BulletItem>
                    </View>
                  </View>
                </View>
                <View style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: scaleNormalizer(250),
                }}>
                  <ManagedSimpleChoiceInput
                    formObj={formObj}
                    name={`${name}.${index}.data.choice`}
                    options={enumToAutocompleteOptions(EmployerContributionSetChoice, disabledChoices)}
                    disabled={isForcedNilReturn}
                    customRules={{
                      atLeastOneAmountRequired,
                    }}
                    noSeparator={true}
                    submitHandler={(value: EmployerContributionSetChoice) => {
                      if (value === EmployerContributionSetChoice.NIL) {
                        setNilAmounts(index)
                      } else if (value === EmployerContributionSetChoice.LAST) {
                        setAmounts(index, lastEmployerContributionAmount, lastEmployeeContributionAmount)
                      }
                    }}
                    informationMessage={choice === undefined ? 'Please make a selection' : undefined}
                    informationMessageIsError={true}
                  />
                </View>
                <View style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: scaleNormalizer(250),
                }}>
                  {
                    choice == undefined || choice === EmployerContributionSetChoice.NIL
                      ? <>
                          <View style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}>
                            {/* Input errors are based around employeeContributionAmount */}
                            <InputErrorMessages
                              formObj={formObj}
                              name={`${name}.${index}.data.employeeContributionAmount`}
                              informationMessage={informationMessage}
                              informationMessageIsError={informationMessageIsError}
                              // textStyle={{
                              //   textAlign: 'left',
                              // }}
                            />
                          </View>
                        </>
                      : <>
                          <ManagedCurrencyInput
                            name={`${name}.${index}.data.employerContributionAmount`}
                            label={`Employer Amount`}
                            autoFocus={false}
                            returnKeyType="next"
                            formObj={formObj}
                            placeholder={'Employer contribution'}
                            disabled={isDisabled}
                            forceZeroValueOnEmpty={true}
                            withSubUnit={true}
                            minValue={0}
                            maxValue={MAX_CONTRIBUTION_AMOUNT}
                            blurOnSubmit={false}
                            onChange={() => { trigger() }}
                            clearButtonFunction={trigger}
                          />
                          <ManagedCurrencyInput
                            name={`${name}.${index}.data.employeeContributionAmount`}
                            label={`Employee Amount`}
                            autoFocus={false}
                            returnKeyType="next"
                            formObj={formObj}
                            placeholder={'Employee contribution'}
                            disabled={isDisabled}
                            forceZeroValueOnEmpty={true}
                            withSubUnit={true}
                            minValue={0}
                            maxValue={MAX_CONTRIBUTION_AMOUNT}
                            blurOnSubmit={false}
                            onChange={() => { trigger() }}
                            clearButtonFunction={trigger}
                          />
                        </>
                  }
                </View>
              </View>
              <ContentDivider />
            </View>
          )
        })
      }
    </View>
  )
}

const localStyles = StyleSheet.create({
  genderImage: {
    alignSelf: 'center',
    height: scaleNormalizer(30),
    width: scaleNormalizer(30),
    borderRadius: scaleNormalizer(15),
  },
})
