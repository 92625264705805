import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Logger } from 'lib/logger'
import { identity, pickBy } from 'lodash'
import React, { ComponentProps, forwardRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Keyboard, Platform, TextStyle, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { TextInput } from 'react-native-paper'
import { Flex, Sizing, Paper, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'

export type ManagedIntegerInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  name: string
  required?: boolean
  minValue?: number
  maxValue?: number
  submitHandler?: any
  informationMessage?: string
  informationMessageIsError?: boolean
}

export const ManagedIntegerInput = forwardRef((props: ManagedIntegerInputProps, ref: any) => {
  const { returnKeyType, disabled, formObj, name, required, minValue, maxValue, label, submitHandler, style, informationMessage, informationMessageIsError }=  props
  const { control, setValue, trigger, formState: { isValid, errors } } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  const fieldErrors = errors[name]
  const hasError = fieldErrors ? true : false

  const textInputStyles: TextStyle = {
    textAlign: 'center',
    width: '90%',
    alignSelf: 'center',
  }

  const textInputProps: ComponentProps<typeof TextInput> = {
    ...props,
    theme: { colors: { text: themeColors.inputText }},
    style: [Typography.defined.inputLarge, textInputStyles, style],
    label: undefined, //No label allowed as cannot center it
    mode: 'flat',
    error: hasError,
    render: renderProps => {
      return <TextInputMask
      {...renderProps}
      type={'only-numbers'}
      returnKeyType={getBestReturnKeyType({ returnKeyType }, isValid)}
      ref={ref}
      />
      }
  }

  const decrementValue = (value: string) => {
    if (disabled) { return }
    const newValue = parseFloat(value) - 1
    if (!minValue || newValue >= minValue) {
      setValue(name, newValue, { shouldDirty: true })
      trigger(name)
    }  
  }

  const incrementValue = (value: string) => {
    if (disabled) { return }
    const newValue = parseFloat(value) + 1
    if (!maxValue || newValue <= maxValue) {
      setValue(name, newValue, { shouldDirty: true })
      trigger(name)
    }  
  }

  const canBeDecremented = (value: string) => {
    if (disabled) { return false }
    return value && (!minValue || minValue < parseFloat(value))
  }

  const canBeIncremented = (value: string) => {
    if (disabled) { return false }
    return value && (!maxValue || maxValue > parseFloat(value))
  }

  const getNumericValue = (formattedValue: string): number => {
    return parseFloat(formattedValue)
  }

  const isNoBiggerThan = (formattedValue: string) => {
    return getNumericValue(formattedValue) <= maxValue ? true : `Cannot be more than ${maxValue}`
  }
  
  const isNoSmallerThan = (formattedValue: string) => {
    return getNumericValue(formattedValue) >= minValue ? true : `Cannot be less than ${minValue}`
  }

  const customValidatorObj: any = pickBy({
    isNoSmallerThan: minValue ? isNoSmallerThan : undefined,
    isNoBiggerThan: maxValue ? isNoBiggerThan : undefined,
  }, identity)

  const getBestReturnKeyType = (textInputProps: ComponentProps<typeof TextInput>, isValid: boolean) => {
    const { returnKeyType } = textInputProps
    //Always use 'done' for numeric keypad on iOS when valid
    if (Platform.OS === 'ios') {
      return isValid ? 'done' : undefined
    }
    return returnKeyType ? returnKeyType : 'done'
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          required: required || false,
          validate: customValidatorObj,
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          const stringValue: string = value ? value.toString() : '0'
          return (
          <>
            <View style={{...Flex.row.between}}>
              { disabled ? <></> :
              <MaterialCommunityIcons style={{paddingTop: Sizing.x10}} name='minus-circle' onPress={() => {decrementValue(value)}} size={Sizing.x40} color={canBeDecremented(value) ? themeColors.primary : themeColors.disabled} />
              }
              <View style={{flex: 1}}>
                <TextInput
                  {...textInputProps}
                  value={stringValue}
                  onBlur={onBlur}             
                  onChangeText={text => {
                    const intValue = text ? parseInt(text) : undefined
                    onChange(intValue || '')
                  }}
                  onSubmitEditing={() => {
                    if(submitHandler) {
                      try {
                        submitHandler()
                      } catch(e) {
                        Logger.error(`Submit handler failed`, e)
                      }
                    }else{
                  Keyboard.dismiss()
                }
                  }}
                />
                <InputErrorMessages formObj={formObj} name={name} informationMessage={informationMessage} informationMessageIsError={informationMessageIsError} />
              </View>
              { disabled ? <></> :
                <MaterialCommunityIcons style={{paddingTop: Sizing.x10}} name='plus-circle' onPress={() => {incrementValue(value)}} size={Sizing.x40} color={canBeIncremented(value) ? themeColors.primary : themeColors.disabled} />
              }
            </View>
          </>
        )}}
        name={name}
      />
    </View>
  )
})