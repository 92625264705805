import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { pickBy, identity, pick } from 'lodash'
import { AUTHTYPE } from './dto/base.dto'

export enum AppContext {
  CLIENT = 'client',
  ADMIN = 'admin',
  EMPLOYER = 'employer',
}

export interface AuthUserModel {
  localToken: any,
  loginMethod: any,
  data: any,
  loggingIn: boolean,
  token: any,
  refreshToken: any,
  error: any,
  impersonation: any,
}

export interface LinkParams {
  affiliateCode?: string,
  affiliateRef?: string,
  affiliateClientRef?: string,
  preferredInviteId?: string,
  preferredSchemeInviteId?: string,
  preferredSchemeEnrolmentId?: string,
}

export interface LoginParams {
  appContext?: AppContext
  emailAddress?: string
  authType?: AUTHTYPE
  autoLogin?: string
  fromCalculator?: boolean
}

export interface AuthState {
  user: AuthUserModel
  haveAppUser: boolean,
  appContext: AppContext
  networkUnavailable: boolean
  serverUnavailable: boolean
  needsClientUpgrade: boolean
  userHasClient: boolean
  linkParams: LinkParams
  loginParams: LoginParams
  clientHash: any
  pushToken: string
}

const initialState: AuthState = {
  user: {
    localToken: undefined, //Undefined means "not yet known"
    loginMethod: undefined,
    data: null,
    loggingIn: false,
    token: null,
    refreshToken: null,
    error: null,
    impersonation: null,
  },
  haveAppUser: false,
  appContext: undefined,
  networkUnavailable: false,
  serverUnavailable: false,
  needsClientUpgrade: false,
  userHasClient: undefined,
  linkParams: undefined,
  loginParams: undefined,
  clientHash: undefined,
  pushToken: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthUserModel>) => {
      state.user = action.payload
    },
    setHaveAppUser: (state, action: PayloadAction<boolean>) => {
      state.haveAppUser = action.payload
    },
    setAppContext: (state, action: PayloadAction<AppContext>) => {
      state.appContext = action.payload
    },
    logoutUser: (state) => {
      // Handled at rootReducer level to clear all slice state
    },
    setNetworkUnavailable: (state, action: PayloadAction<boolean>) => {
      state.networkUnavailable = action.payload
    },
    setServerUnavailable: (state, action: PayloadAction<boolean>) => {
      state.serverUnavailable = action.payload
    },
    setNeedsClientUpgrade: (state, action: PayloadAction<boolean>) => {
      state.needsClientUpgrade = action.payload
    },
    setUserHasClient: (state, action: PayloadAction<boolean>) => {
      state.userHasClient = action.payload
    },
    setLinkParams: (state, action: PayloadAction<LinkParams & { preferExisting?: boolean }>) => {
      if (action.payload === undefined) {
        state.linkParams = undefined //Reset
      } else {
        const { preferExisting, ...remaining } = action.payload || {}
        if (preferExisting) {
          const newState = {
            ...pickBy(remaining, identity),
            ...state.linkParams,
          }
          state.linkParams = newState
        } else {
          const newState = {
            ...state.linkParams,
            ...pickBy(remaining, identity),
          }
          state.linkParams = newState
        }
      }
    },
    setLoginParams: (state, action: PayloadAction<LoginParams>) => {
      if (action.payload === undefined) {
        state.loginParams = undefined //Reset
      } else {
        const { ...remaining } = action.payload || {}
        const newState = {
          ...state.loginParams,
          ...pickBy(remaining, identity),
        }
        state.loginParams = newState
      
      }
    },
    setClientHash: (state, action: PayloadAction<string>) => {
      state.clientHash = action.payload
    },
    setPushToken: (state, action: PayloadAction<string>) => {
      state.pushToken = action.payload
    },
  },
})


export const user = (state: RootState) => state.auth.user
export const haveAppUser = (state: RootState) => state.auth.haveAppUser
export const appContext = (state: RootState) => state.auth.appContext
export const networkUnavailable = (state: RootState) => state.auth.networkUnavailable
export const serverUnavailable = (state: RootState) => state.auth.serverUnavailable
export const needsClientUpgrade = (state: RootState) => state.auth.needsClientUpgrade
export const userHasClient = (state: RootState) => state.auth.userHasClient
export const linkParams = (state: RootState) => state.auth.linkParams
export const loginParams = (state: RootState) => state.auth.loginParams
export const clientHash = (state: RootState) => state.auth.clientHash
export const pushToken = (state: RootState) => state.auth.pushToken

// Action creators are generated for each case reducer function
export const {
  setUser,
  setHaveAppUser,
  setAppContext,
  logoutUser,
  setNetworkUnavailable,
  setServerUnavailable,
  setNeedsClientUpgrade,
  setUserHasClient,
  setLinkParams,
  setLoginParams,
  setClientHash,
  setPushToken,
 } = authSlice.actions

export default authSlice.reducer
