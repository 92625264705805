import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useGetMeQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editMeVisible, setEditMeVisible } from 'store/uxSlice'
import { ClientEditScreen } from './Screens/ClientEditScreen'

export type ClientEditModalProps = {
  visible: boolean
  onDismiss?: any
  theme?: any
}

export const ClientEditModal = (props: ClientEditModalProps) => {

  const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery()

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditMeVisible(false))

  const visible = props.visible || useAppSelector(editMeVisible)
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  return (    
    <ModalFormBuilder
      isLoading={meIsLoading}
      error={meError}
      visible={visible}
      formTitle='Update Your Details'
      onDismiss={onDismiss}
      screen={<ClientEditScreen
        me={me}
        onDismiss={onDismiss}
      />}
    />
  )
}
