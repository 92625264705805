import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { getAssetTypeOptions } from 'lib/retirementAssetHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ContributionSource } from 'store/dto/base.dto'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetsAddAsset_01_Type = ({ route, navigation }) => {
  const { nextScreen, onlyPensions }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ assetType: RetirementAssetType }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      assetType: workingRetirementAssetData?.assetType,
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    //Update including contributionSource for workplace
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      contributionSource: attributes.assetType === RetirementAssetType.WORKPLACE ? ContributionSource.EMPLOYER : undefined,
    }))
    //Skip employer if not workplace and contributions unless personal/workplace
    navigation.navigate(attributes.assetType === RetirementAssetType.WORKPLACE
      ? nextScreen
      : attributes.assetType === RetirementAssetType.PERSONAL
        ? 'Brand'
        : 'Value'
      )
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What is it exactly?`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.OTHER_ASSET_TYPES} />}
    >
      <ManagedSimpleChoiceInput
        name={'assetType'}
        formObj={formObj}
        options={getAssetTypeOptions(onlyPensions)}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
