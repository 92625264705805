import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddInvestmentPlanVisible, showAdminAddInvestmentPlanVisible } from 'store/uxSlice'
import { AddInvestmentPlansScreen } from './Screens/AddInvestmentPlansScreen'

export const AddInvestmentPlansModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddInvestmentPlanVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Investment Plan'
      onDismiss={() => dispatch(setShowAdminAddInvestmentPlanVisible(false))}
      screen={<AddInvestmentPlansScreen />}
    />
  )
}

