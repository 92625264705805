import { LightModal } from 'components/Layout'
import { ModalEditHeader } from 'components/Layout/ModalEditHeader'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import React from 'react'
import { StatusBar, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { Flex } from 'styles'

export type ModalFormBuilderProps = {
  isLoading?: boolean
  error?: any
  visible: boolean
  onDismiss: Function
  onDelete?: Function
  deleteDialogText?: string
  formTitle: string
  screen: React.ReactNode
  theme?: any
}


export const ModalFormBuilder = (props: ModalFormBuilderProps) => {
  const { isLoading, error, visible, onDismiss, onDelete, deleteDialogText, formTitle, screen, theme } = props

  const ready = !isLoading && !error

  const viewConstraints: any = getScreenWidthConstraints()

  return (
    <>
      <StatusBar
        barStyle={'dark-content'}
      />
      <Portal>
        <LightModal
          theme={theme}
          visible={visible}
          dismissable={false}
        > 
          <View style={[
            {
            ...Flex.column.start,
            },
            viewConstraints,
          ]}>
            <ModalEditHeader
              formTitle={formTitle}
              onDismiss={onDismiss}
              onDelete={ready ? onDelete : undefined}
              deleteDialogText={deleteDialogText}
            />
            {screen}
          </View>
        </LightModal>
      </Portal>
    </>
  )
}
