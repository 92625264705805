import * as LocalAuthentication from 'expo-local-authentication'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { envVariables } from 'environment'
import { parse } from 'expo-linking'
import jwtDecode from 'jwt-decode'
import { Logger } from 'lib/logger'
import { intersection, omit, isArray } from 'lodash'
import { Platform } from 'react-native'
import { ThunkDispatch } from 'redux-thunk'
import { ampli } from 'src/ampli'
import { AppContext, logoutUser, setUser } from 'store/authSlice'
import { logoutFromIntercom } from './intercomHelpers'
import { getJsonItemFromStore, removeJsonItemFromStore, setJsonItemInStore } from './localStoreHelpers'
import { ANALYTICS_KEY_NAME, JWT_KEY_NAME, LINK_PARAMS_KEY_NAME, LAST_USER_EMAIL_KEY_NAME, LAST_USER_CONTEXT_KEY_NAME } from './constants'
import { batch } from 'react-redux'
import { api } from 'store/apiSlice'
import { passwordless } from 'store/passwordlessSlice'
import { UserDto } from 'store/dto/user.dto'
import { startsWith } from 'lodash'
import { platformIsWeb } from './platformHelpers'

export const ACTION_PARAM_PASSWORDLESS = 'passwordless'
export const URL_PATH_FEDERATED = 'federated'

const isWeb = platformIsWeb()

const { appUrl, envName, auth0CustomScheme, disableBiometrics } = envVariables

export const useProxy = Platform.select({ web: false, default: true })

export const getJwtKeyName = () => {
  return `${envName}-${JWT_KEY_NAME}`
}

export const getLastUserEmailKeyName = () => {
  return `${envName}-${LAST_USER_EMAIL_KEY_NAME}`
}

export const getLastUserContextKeyName = () => {
  return `${envName}-${LAST_USER_CONTEXT_KEY_NAME}`
}

export const jwtKeyName = getJwtKeyName()
export const lastUserEmailKeyName = getLastUserEmailKeyName()
export const lastUserContextKeyName = getLastUserContextKeyName()

export enum UserPermissions {
  'administer:system' = 'administer:system',
  'administer:financials' = 'administer:financials',
  'administer:user' = 'administer:user',
  'administer:client' = 'administer:client',
  'administer:pension' = 'administer:pension',
  'administer:affiliate' = 'administer:affiliate',
  'administer:investmentChoice' = 'administer:investmentChoice',
  'administer:groupSchemes' = 'administer:groupSchemes',
  'administer:transfer' = 'administer:transfer',
  'administer:stat' = 'administer:stat',
  'contribute:any' = 'contribute:any', //Used for testing small contributions
}

export const userIsAdministrator = (authUser: any) => {
  if (!userIsReady(authUser)) {
    return false
  }
  //Check lowest permissions...
  return hasAnyPermission(authUser, [
    UserPermissions['administer:client'],
    UserPermissions['administer:user'],
  ])

}


// Gets the auth token set from secure store
export const getCachedToken = async () => {
  return getJsonItemFromStore(jwtKeyName)
}

// Sets the auth token set in secure store
export const setCachedToken = async (tokenSet) => {
  await setJsonItemInStore(jwtKeyName, omit(tokenSet, ['idToken']))
}

// Clears the auth token set in secure store
export const clearCachedToken = async () => {
  await removeJsonItemFromStore(jwtKeyName)
}

export const getLastUserEmail = async () => {
  return getJsonItemFromStore(lastUserEmailKeyName)
}

// Sets the last user email in secure store
export const setLastUserEmail = async (email: string) => {
  // Logger.debug(`Setting last user email: ${email}`)
  await setJsonItemInStore(lastUserEmailKeyName, email)
}

export const getLastUserContext = async () => {
  const context = await getJsonItemFromStore(lastUserContextKeyName)
  if (context) {
    // Logger.debug(`Last user context was: ${context}`)
    return context
  } else {
    // Logger.debug(`No last user context - using ${AppContext.CLIENT}`)
    return AppContext.CLIENT
  }
}

// Sets the last user email in secure store
export const setLastUserContext = async (context: AppContext) => {
  Logger.info(`Setting last user context: ${context}`)
  await setJsonItemInStore(lastUserContextKeyName, context)
}

export const clearLastUserContext = async () => {
  Logger.info('Clearing last user context')
  await removeJsonItemFromStore(lastUserContextKeyName)
}

export const verifyBiometricsIfAvailable = async () => {
  if (disableBiometrics) {
    return true
  }
  const isBiometricSupported = isWeb ? false : await LocalAuthentication.hasHardwareAsync()
  const savedBiometrics = isWeb ? undefined : await LocalAuthentication.isEnrolledAsync()

  //Biometrics if available and enabled
  if (isBiometricSupported && savedBiometrics) {
    Logger.debug('Using biometrics...')
    const response = await LocalAuthentication.authenticateAsync({
      promptMessage: 'Login to Jarvis with Biometrics',
      disableDeviceFallback: false,
    })
    return response.success
  } else {
    return true
  }
}
// Check if everything resolved
export const userIsReady = (authUser: any) => {
  const isAuthorized = authUser?.data?.sub && !authUser?.error
  if (!isAuthorized) {
    return false
  }
  return true
}

// Check if user loggingIn state
export const userIsLoggingIn = (authUser: any) => {
  return authUser?.loggingIn
}

export const hasPermission = (authUser, permission) => {
  return isArray(authUser.permissions)
    ? authUser.permissions.includes(permission)
    : false
}

export const hasAnyPermission = (authUser: any, permissions: UserPermissions[]) => {
  const userPermissions = authUser?.data.permissions || []
  return intersection(permissions, userPermissions).length > 0
}

export const hasAllPermissions = (authUser: any, permissions: UserPermissions[]) => {
  const userPermissions = authUser?.data.permissions || []
  let all = true
  permissions.forEach(permission => {
    if (!hasPermission(authUser, permission)) {
      all = false
      return
    }
  })
  return all
}


//Set user with token in central state and secure store
export const setUserWithToken = async (
  tokenSet: any, //AuthSession.TokenResponseConfig,
  loginMethod: string,
  dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>,
  currentImpersonation?: any,
  emailAddress?: string,
) => {
    //Store in secure store
    await setCachedToken(tokenSet)

    //Also store last user email if provided
    if (emailAddress) {
      await setLastUserEmail(emailAddress)
    }

    //Decode the jwt for getting profile information
    const token = tokenSet.accessToken
    const refreshToken = tokenSet.refreshToken
    let impersonation = null
    let data: any = jwtDecode(token)

    //Preserve impersonation if provided
    if (currentImpersonation) {
      data.sub = currentImpersonation.providerId
      data.permissions = []
      impersonation = currentImpersonation
    }

    const permissions = data?.permissions || []
    const isAdmin = !!permissions.find(permission => {
      return startsWith(permission, 'administer')
    })

    const newUserData = {
      localToken: true,
      loginMethod,
      data,
      loggingIn: false,
      token,
      refreshToken,
      error: null,
      impersonation,
    }
    // Logger.debug({ newUserData, isAdmin }, `Setting user in state...`)
    // storing token in state
    batch(() => {
      dispatch(
        setUser(newUserData)
      )
      //PA-1657 REMOVE
      // dispatch(
      //   setLastUserWasAdmin(isAdmin)
      // )
    })
}

//Set impersonated user
export const setImpersonatedUser = async (
  impersonatedUser: UserDto,
  currentUserState: any,
  dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>
) => {
    const { data } = currentUserState || {}
    const impersonatedUserData = {
      ...currentUserState,
      data: {
        ...data,
        sub: impersonatedUser?.providerId,
        permissions: [], //Remove all permissions
      },
      impersonation: {
        userId: impersonatedUser?.id,
        userProviderId: impersonatedUser?.providerId,
        userEmail: impersonatedUser?.email,
        adminSub: data.sub,
        adminPermissions: data.permissions,
      }
    }
    Logger.debug({ impersonatedUserData }, `Setting impersonated user...`)

    batch(() => {
      dispatch(api.util.resetApiState())
      dispatch(passwordless.util.resetApiState())
      dispatch(setUser(impersonatedUserData))
    })
}

//Remove impersonated user
export const removeImpersonatedUser = async (
  impersonatedUserState: any,
  dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>
) => {
    const { data } = impersonatedUserState || {}
    const originalUserData = {
      ...impersonatedUserState,
      data: {
        ...data,
        sub: data.impersonation?.adminSub,
        permissions: data.impersonation?.adminPermissions, //Resinstate original permissions
      },
      impersonation: null,
    }
    Logger.debug({ originalUserData }, `Resetting original user...`)

    batch(() => {
      dispatch(api.util.resetApiState())
      dispatch(passwordless.util.resetApiState())
      dispatch(setUser(originalUserData))
    })

}

//Logout user from central state and secure store
export const logoutUserFromState = async (
  dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>,
  clearSession?: any,
  clearCredentials?: any,
  webLogout?: any,
) => {

  //Clear central storage
  batch(() => {
    dispatch(api.util.resetApiState())
    dispatch(passwordless.util.resetApiState())
    dispatch(logoutUser())
  })

  Logger.debug('logoutUserFromState...')
  if (isWeb) {
    if (webLogout) {
      const returnTo = appUrl
      const logoutParams = { returnTo }
      Logger.debug({ logoutParams }, 'Logout web!')
      await webLogout({ logoutParams })
    } else {
      Logger.debug('webLogout not provided.')
    }
  } else {
    if (clearCredentials) {
      Logger.debug('Clearing credentials...')
      try {
        await clearCredentials()
      } catch (e) {
        Logger.debug('Call to clearCredentials rejected')
      }
    } else {
      Logger.debug('clearCredentials not provided.')
    }
    if (clearSession) {
      Logger.debug('Clearing session...')
      try {
        if (Platform.OS === 'android') {
          Logger.debug(`Cleaning up Auth0 session...`)
          await clearSession({
            customScheme: isWeb ? undefined : auth0CustomScheme
          })
        } else {
          Logger.debug(`Skipping Auth0 session cleanup (ios ephemeral)...`)
        }
      } catch (e) {
        Logger.debug('Call to clearSession rejected')
      }
    } else {
      Logger.debug('clearSession not provided.')
    }
  }
  Logger.debug('Logging out from Intercom...')
  await logoutFromIntercom()
  Logger.debug('Flushing amplitude events...')
  await ampli.flush().promise
  Logger.debug('Clearing user from state...')

  Logger.debug('Cleaning up secure store...')
  await removeJsonItemFromStore(LINK_PARAMS_KEY_NAME)
  await removeJsonItemFromStore(ANALYTICS_KEY_NAME)
}


export enum LoginConnection {
  GOOGLE = 'google-oauth2',
  APPLE = 'apple',
}

export const urlPathIsFederated = (path: string) => {
  return path === URL_PATH_FEDERATED
}

export const parsePasswordlessLoginFromUrl = (url): { code: any, email?: any } => {
  const parsed = parse(url)
  const actionParam = parsed.queryParams?.action
  const codeParam = parsed.queryParams?.code
  const emailParam = parsed.queryParams?.email
  const complete = actionParam === ACTION_PARAM_PASSWORDLESS && codeParam && emailParam
  return {
    code: complete ? codeParam : undefined,
    email: complete ? emailParam : undefined,
  }
}
