import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'

export const SchemeEnrolmentChangeStatusRequestAdd_01_Info = ({ route, navigation }) => {
  const { nextScreen, onDismiss, enrolment, requestType }: { nextScreen: string, onDismiss: any, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, requestType: GroupSchemeRequestType } = route?.params || {}
  const { groupScheme } = enrolment || {}
  const { organizationName } = groupScheme || {}

  const { autoEnrolmentWindowOptOutDate, enrolmentEndDate } = enrolment

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={next}
      enableButton={true}
      headline={requestType === GroupSchemeRequestType.OPT_IN
        ? `About Opting In to Workplace Schemes`
        : requestType === GroupSchemeRequestType.OPT_OUT
        ? `About Opting Out of Workplace Schemes`
        : `About Leaving Workplace Schemes`
      }
      subHeading={`Your employer should follow the UK Government regulations`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.AUTOMATIC_ENROLMENT} buttonTitle={'About Automatic Enrolment'} />}
    >
      {
        requestType  === GroupSchemeRequestType.OPT_IN
          ? <>
          <Subheading>{`You previously left the scheme ${enrolmentEndDate ? `on ${formatUkDate(new Date(enrolmentEndDate))}` : ''}.`}</Subheading>
          <Paragraph>{`We can send a request to ${organizationName} for you to opt back in to their workplace scheme.`}</Paragraph>
          </>
          : requestType  === GroupSchemeRequestType.OPT_OUT
          ? <>
              <Subheading>{`You are still within your 'Opt Out' period ${autoEnrolmentWindowOptOutDate ? `until ${formatUkDate(new Date(autoEnrolmentWindowOptOutDate))}` : ''}.`}</Subheading>
              <Paragraph>{`We can therefore send a request to ${organizationName} for you to opt out of their workplace scheme.`}</Paragraph>
              <Paragraph>{`If you are close to the end of your 'Opt Out' period, we would also suggest speaking to your employer to make sure that the request is actioned in time.`}</Paragraph>
            </>
          : <>
              <Subheading>{`You are no longer within your 'Opt Out' period ${autoEnrolmentWindowOptOutDate ? `(it ended on ${formatUkDate(new Date(autoEnrolmentWindowOptOutDate))} )` : ''}.`}</Subheading>
              <Paragraph>{`We can therefore send a request to ${organizationName} for you to leave their workplace scheme.`}</Paragraph>
            </>
      }
    </ModalProcessScreen>
  )
}
