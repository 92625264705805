import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowEmployerAddContributionJobVisible, showEmployerAddContributionJobVisible } from 'store/uxSlice'
import { EmployerAddContributionJobScreen } from './Screens/EmployerAddContributionJobScreen'

export const EmployerAddContributionJobModal = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showEmployerAddContributionJobVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Submit Contributions Manually'
      onDismiss={() => dispatch(setShowEmployerAddContributionJobVisible(false))}
      screen={<EmployerAddContributionJobScreen />}
    />
  )
}

