import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate } from 'lib/dateHelpers'
import { startCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDeleteGroupSchemeInviteMutation, useGetGroupSchemeInviteQuery, useResendGroupSchemeInviteMutation } from 'store/apiSlice'
import { InviteStatus } from 'store/dto/invite.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerInviteDataId, currentGroupScheme, setCurrentEmployerInviteDataId, setSnackbarData } from 'store/uxSlice'
import { Paper } from 'styles'

export const EmployerInviteEditScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const currentInviteId = useAppSelector(currentEmployerInviteDataId)

  const [resendDialogVisible, setResendDialogVisible] = useState(false)
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)

  const { isLoading: inviteIsLoading, error: inviteError, data: invite, refetch: refetchInvite } = useGetGroupSchemeInviteQuery({ inviteId: currentInviteId, groupSchemeId: currentScheme.id }, { skip: !currentInviteId } )
  const [resendInvite, { data: resendInviteResult, isLoading: resendInviteIsLoading, error: resendInviteError }] = useResendGroupSchemeInviteMutation()
  const [deleteInvite, { data: deleteInviteResult, isLoading: deleteInviteIsLoading, error: deleteInviteError }] = useDeleteGroupSchemeInviteMutation()

  const { status, email, firstName, surname, title, gender, birthDate, nationalInsuranceNo, createdAt } = invite || {}

  const dispatch = useAppDispatch()

  const close = () => {
    dispatch(setCurrentEmployerInviteDataId(undefined))
  }

  const isLoading = inviteIsLoading || resendInviteIsLoading || deleteInviteIsLoading
  const error: any = inviteError || resendInviteError || deleteInviteError

  const { colors: themeColors } = Paper.useAppTheme()

  const handleResend = async () => {
    resendInvite({
      groupSchemeId: currentScheme?.id,
      inviteId: invite?.id,
    })
    setResendDialogVisible(false)
  }

  const handleDelete = async attributes => {
    deleteInvite({
      groupSchemeId: currentScheme?.id,
      inviteId: invite?.id,
    })
    setDeleteDialogVisible(false)
  }

  useEffect(() => {
    if (resendInviteResult) {
      dispatch(setSnackbarData({
        message: `Invite resent!`,
        subMessage: `If the recipient accepts, you'll receive an 'Opt In' request`,
        iconName: 'email-send-outline',
        duration: 5000,
      }))
      close()
    }
  }, [resendInviteResult])

  useEffect(() => {
    if (deleteInviteResult) {
      dispatch(setSnackbarData({
        message: `Invite revoked!`,
        subMessage: `The recipient will no longer be able to use their invite email.`,
        iconName: 'email-remove-outline',
        duration: 5000,
      }))
      close()
    }
  }, [deleteInviteResult])

  const isResendable = status !== InviteStatus.ACCEPTED
  const isRevokable = status !== InviteStatus.ACCEPTED

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      errorTryAgain={resendInviteError ? handleResend : deleteInviteError ? handleDelete : refetchInvite}
      isLoading={isLoading}
      loadingMessage={inviteIsLoading ? undefined : ['Saving...']}
      buttonTitle={isResendable ? 'Resend Invite' : 'Close'}
      buttonAction={isResendable ? setResendDialogVisible : close}
      showButton={true}
      enableButton={true}
      allowTextButton={isRevokable}
      textButtonAction={setDeleteDialogVisible}
      textButtonTitle={status === InviteStatus.DECLINED ? `Delete Invite` : `Revoke Invite`}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: invite?.id,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Invitee Details`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Name`,
            value: `${firstName} ${surname}`,
          },
          {
            label: `Title`,
            value: title,
          },
          {
            label: `Gender`,
            value: gender,
          },
          {
            label: `Date of Birth`,
            value: birthDate ? formatUkDate(new Date(birthDate)) : 'n/a',
          },
          {
            label: `Email`,
            value: email,
            copyableValue: true,
          },
          {
            label: `National Insurance No`,
            value: formatNationalInsuranceNumber(nationalInsuranceNo),
            copyableValue: nationalInsuranceNo,
          },
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Status`,
            value: startCase(status),
          },
          {
            label: `First Invited`,
            value: createdAt ? formatUkDate(new Date(createdAt)) : 'n/a',
          },
        ]}
        noContentDivider={true}
      />

      <ConfirmationDialog
        visible={resendDialogVisible}
        title={'Are you sure?'}
        content={status === InviteStatus.DECLINED
          ? 'This will resend the invite to the email address shown. Please note that the employee previously declined this invite, so only resend it if you are sure they want to receive it again.'
          : 'This will resend the invite to the email address shown. If you need to send to a different email, please delete and create a new invite.'
        }
        onCancel={() => setResendDialogVisible(false)}
        onConfirm={handleResend}
        confirmLabel={'Confirm Resend'}
        cancelLabel={'Go Back'}
      />
      <ConfirmationDialog
        visible={deleteDialogVisible}
        title={'Are you sure?'}
        content={status === InviteStatus.DECLINED
          ? 'This will delete the invite - you will need to create a new one if you want to invite the employee again.'
          : 'This will revoke the invite, and the email recipient will not be able to use the invite to request enrolment in the scheme.'
        }
        onCancel={() => setDeleteDialogVisible(false)}
        onConfirm={handleDelete}
        confirmLabel={'Confirm Delete'}
        cancelLabel={'Go Back'}
      />
    </ModalProcessScreen>
  )
}

