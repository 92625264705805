import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FileUploadArea, FileUploadStatus } from 'components/Utility/FileUploadArea'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { PAPDIS_UPLOAD_FILE_TYPES } from 'lib/constants'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useAddGroupSchemeJobWithFileMutation, useBulkAcknowledgeGroupSchemeJobsMutation, useGetGroupSchemeJobsQuery, useGetSampleFileQuery } from 'store/apiSlice'
import { GroupSchemeJobType, GroupSchemeJobStatus, GroupSchemeJobDataSetFormat } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerJobDataId, setShowEmployerAddEnrolmentJobVisible, setSnackbarData } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerEnrolmentListItem } from '../Components/EmployerEnrolmentListItem'
import { platformIsWeb } from 'lib/platformHelpers'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { getActionDate } from 'lib/dateHelpers'
import { addMinutes, isBefore } from 'date-fns' 
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { getScreenAppWidth } from 'lib/scaleHelpers'
import { FooterButton } from 'components/Utility/FooterButton'
import { Button } from 'components/Utility/Button'
import Collapsible from 'react-native-collapsible'
import { BulkSelectToolbar } from 'components/Utility/BulkSelectToolbar'
import { useForm } from 'react-hook-form'
import { difference, union, pull } from 'lodash'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'

const isWeb = platformIsWeb()

const JOB_POLLING_INTERVAL_MS = 60000
const JOB_STALE_MINUTES = 60

export const EmployerEnrolmentsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
    
  const { colors: themeColors } = Paper.useAppTheme()

  const [bulkSelectMode, setBulkSelectMode] = useState(false)
  
  const enableBulkSelect = () => {
    setBulkSelectMode(true)
  }

  const cancelBulkSelect = () => {
    setBulkSelectMode(false)
    unselectAllRecords()
  }
  
  const formObj = useForm<{
    selectedJobIds: string[],
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      selectedJobIds: [],
    },
  })

  const { handleSubmit, watch, reset, setValue } = formObj

  const [closeDialogVisible, setCloseDialogVisible] = useState(false)
  const [newCollapsed, setNewCollapsed] = useState(false)
  const hideNew  = () => { setNewCollapsed(true) }
  const showNew = () => { setNewCollapsed(false) }

  const screenWidth = getScreenAppWidth()
  const useHorizontalLayout = isWeb && screenWidth >= 1000

  const { data: enrolmentSampleFile, isLoading: enrolmentSampleFileIsLoading, error: enrolmentSampleFileError } = useGetSampleFileQuery('jarvis_sample_papdis_enrolment_upload.csv')

  const [hasPendingJobs, setHasPendingJobs] = useState(false)

  const { data: jobs, isLoading: jobsIsLoading, error: jobsError, isFetching: jobsIsFetching, refetch: refetchJobs } = useGetGroupSchemeJobsQuery({
    groupSchemeId: currentScheme?.id,
    resultAcknowledged: 'false',
    jobType: GroupSchemeJobType.MEMBER,
  }, { skip: !currentScheme, pollingInterval: hasPendingJobs ? JOB_POLLING_INTERVAL_MS : undefined })

  const [addEnrolmentJob, { data: addEnrolmentResult, isLoading: addEnrolmentJobIsLoading, error: addEnrolmentJobError }] = useAddGroupSchemeJobWithFileMutation()
  const [acknowledgeMultpleJobs, { isLoading: acknowledgeMultipleIsLoading, error: acknowledgeMultipleError }] = useBulkAcknowledgeGroupSchemeJobsMutation()

  const allCompletedJobIds = jobs ? jobs.filter(job => {
    return job.status === GroupSchemeJobStatus.COMPLETED
  }).map(job => {
    return job.id
  }) : [] 

  const selectedJobIds = watch('selectedJobIds')
  
  const anySelected = !!selectedJobIds.length

  const handleAcknowledgeMultiple = async () => {
    acknowledgeMultpleJobs({
      groupSchemeId: currentScheme?.id,
      jobIds: selectedJobIds,
    })
    setValue('selectedJobIds', [], { shouldValidate: true, shouldDirty: false })
    setCloseDialogVisible(false)
    setBulkSelectMode(false)
  }

  const unselectRecord = (id: string) => {
    setValue('selectedJobIds', pull(selectedJobIds, id))
  }
  
  const selectRecord = (id: string) => {
    setValue('selectedJobIds', union(selectedJobIds, [id]))
  }

  const unselectAllRecords = () => {
    setValue('selectedJobIds', [])
  }

  const selectAllRecords = () => {
    setValue('selectedJobIds', allCompletedJobIds)
  }

  const [refreshing, setRefreshing] = useState(false)

  //Update hasPendingJobs on jobs change
  useEffect(() => {
    const now = new Date()
    if (jobs) {
      const anyRecentPendingJob = jobs ? jobs.find(job => {
        return [GroupSchemeJobStatus.PENDING, GroupSchemeJobStatus.PROCESSING].includes(job.status) && isBefore(now, addMinutes(new Date(job.createdAt), JOB_STALE_MINUTES))
      }) : undefined
      if (hasPendingJobs !== !!anyRecentPendingJob) {
        setHasPendingJobs(!!anyRecentPendingJob)
      }
    }
  }, [jobs])
  
  const [enrolmentFile, setEnrolmentFile] = useState<File>(undefined)
  const [enrolmentFileUploadStatus, setEnrolmentFileUploadStatus] = useState<FileUploadStatus>(FileUploadStatus.IDLE)

  const handleEnrolmentFileChange = (file: File) => {
    setEnrolmentFile(file)
    setEnrolmentFileUploadStatus(FileUploadStatus.IDLE)
  }

  const handleEnrolmentFileUpload = () => {
    setEnrolmentFileUploadStatus(FileUploadStatus.IDLE)
    const formData = new FormData()
    formData.append('file', enrolmentFile)
    formData.append('jobType', GroupSchemeJobType.MEMBER)
    formData.append('dataSetFormat', GroupSchemeJobDataSetFormat.PAPDIS)
    addEnrolmentJob({
      groupSchemeId: currentScheme?.id,
      formData,
    })
  }

  //Sync enrolment upload status
  useEffect(() => {
    setEnrolmentFileUploadStatus(
      addEnrolmentResult ? FileUploadStatus.COMPLETE
      : addEnrolmentJobError ? FileUploadStatus.ERROR_UPLOAD
      : addEnrolmentJobIsLoading ? FileUploadStatus.UPLOADING
      : FileUploadStatus.IDLE
    )
  }, [addEnrolmentResult, addEnrolmentJobIsLoading, addEnrolmentJobError])
  
  //Show Snackbar when uploaded/error - enrolment
  useEffect(() => {
    if (addEnrolmentResult || addEnrolmentJobError) {
      dispatch(setSnackbarData({
        message: addEnrolmentJobError ? `File Upload Failed` : `File Uploaded Successfully`,
        subMessage: addEnrolmentJobError
          ? `Please check that the file exists and is a valid PAPIS CSV file`
          : `We'll send you a message when it has been processed${addEnrolmentResult?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addEnrolmentResult?.expectedCompleteAt )})` : ``}`,
        iconName: addEnrolmentJobError ? 'file-alert-outline' : 'file-check-outline',
        isError: !!addEnrolmentJobError,
        duration: 5000,
      }))
    }
  }, [addEnrolmentResult, addEnrolmentJobError])
  
  const dispatch = useAppDispatch()

  const handleShowJobDetails = (id: string) => {
    dispatch(setCurrentEmployerJobDataId(id))
  }

  const jobsList = () => {
    let completed = []
    let completedWithErrors = []
    let rejected = []
    let investigating = []
    let processing = []
    let pending = []
    if (jobs) {
      jobs.map((job, id) => {
        if (job.status === GroupSchemeJobStatus.COMPLETED) {
          completed.push(job)
        } else if (job.status === GroupSchemeJobStatus.COMPLETED_WITH_ERRORS) {
          completedWithErrors.push(job)
        } else if (job.status === GroupSchemeJobStatus.REJECTED) {
          rejected.push(job)
        } else if (job.status === GroupSchemeJobStatus.INVESTIGATING) {
          investigating.push(job)
        } else if (job.status === GroupSchemeJobStatus.PROCESSING) {
          processing.push(job)
        } else {
          pending.push(job)
        } 
      })

      const headerTools = completed.length ? <BulkSelectToolbar
        enabled={bulkSelectMode}
        anySelected={anySelected}
        enableFunction={enableBulkSelect}
        cancelFunction={cancelBulkSelect}
        selectAllFunction={selectAllRecords}
        unselectAllFunction={unselectAllRecords}
        bulkActionFunction={() => setCloseDialogVisible(true)}
        bulkActionTitle={'Archive Selected'}
        bulkActionIconName={'archive-check-outline'}
      /> : undefined

      return [
        { title: 'Pending', data: pending },
        { title: 'Processing', data: processing },
        { title: 'Investigating', data: investigating  },
        { title: 'Rejected', data: rejected },
        { title: 'Completed With Errors', data: completedWithErrors },
        { title: 'Completed', data: completed, headerTools },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    const { id } = item
    const selected = selectedJobIds.includes(id)
    return (
      <EmployerEnrolmentListItem
        job={item}
        bulkSelectMode={bulkSelectMode}
        selected={selected}
        onPressFunction={bulkSelectMode
          ? selected
            ? () => unselectRecord(item?.id)
            : () => selectRecord(item?.id)
          : () => handleShowJobDetails(item?.id)}
      />
    )
  }

  const isLoading = jobsIsLoading || enrolmentSampleFileIsLoading || acknowledgeMultipleIsLoading
  const error: any = jobsError

  const onRefresh = () => {
    setRefreshing(true)
    refetchJobs()
    setRefreshing(false)
  }

  const handleAddEnrolmentJob = () => {
    dispatch(setShowEmployerAddEnrolmentJobVisible(true))
  }

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Checking enrolment jobs...')} /> :
        error ?  <ErrorScreen errorTryAgain={refetchJobs} error={error?.data} /> : 
          <View style={ Flex.column.start } >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
            >
              {
                isWeb ?
                <>
                  <View style={{
                    paddingHorizontal: Sizing.x30,                    paddingBottom: Sizing.x10,

                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}>
                    <Subheading style={Typography.defined.sectionListHeader}>{'New Enrolments'}</Subheading>
                    <Collapsible collapsed={newCollapsed}>
                      <View style={{
                        flexDirection: useHorizontalLayout ? 'row' : 'column',
                        width: useHorizontalLayout ? '100%' : undefined,
                        justifyContent: useHorizontalLayout ? 'space-between' : 'flex-start',
                      }}>
                        <View style={{
                          ...Flex.column.start,
                          width: useHorizontalLayout ? '50%' : '100%',
                          height: useHorizontalLayout ? '100%' : undefined,
                          paddingHorizontal: Sizing.x10,
                          paddingTop: Sizing.x10,
                        }}>
                          <View style={{ flex: 1, paddingTop: Sizing.x5 }}>
                            <Subheading>
                              {'Upload PAPDIS files to enrol new members in your scheme.'}
                            </Subheading>
                            <Paragraph>
                              {'Select a single PAPDIS CSV, optionally zipped for quicker upload.'}
                            </Paragraph>
                            <ExternalLinkButton
                              url={enrolmentSampleFile?.downloadUrl}
                              downloadAsFile={true}
                              downloadFilename={enrolmentSampleFile?.id || 'sample'}
                              labelStyle={{
                                textDecorationLine: 'none',
                                textTransform: 'none',
                              }}
                              iconName={'file-eye-outline'}
                              color={Colors.brand.purple1}
                              iconColor={Colors.brand.purple1}
                            >
                              {'Download Sample File'}
                            </ExternalLinkButton>
                            <Paragraph style={{
                              textAlign: 'left'
                            }}>
                              {'Data Handling:'}
                            </Paragraph>
                            <BulletItem style={{ textAlign: 'left' }}>{'Any new members (based on National Insurance Number) will be enrolled and invited to the Jarvis App.'}</BulletItem>
                            <BulletItem style={{ textAlign: 'left' }}>{'Existing members (based on National Insurance Number) will be updated (e.g. Opt Out Status)'}</BulletItem>
                            <BulletItem style={{ textAlign: 'left' }}>{'Any contribution data in the file will NOT be processed.'}</BulletItem>                          
                            <Button
                              icon={'book-edit-outline'}
                              color={Colors.brand.purple1}
                              labelStyle={{
                                color: Colors.brand.purple1,
                              }}
                              mode={'text'}
                              onPress={handleAddEnrolmentJob}
                            >
                              {'Enrol New Member Manually'}
                            </Button>
                          </View>
                        </View>
                        <View style={{
                          ...Flex.column.center,
                          width: useHorizontalLayout ? '50%' : '100%',
                          height: useHorizontalLayout ? '100%' : undefined,
                          paddingHorizontal: Sizing.x10,
                          paddingVertical: Sizing.x10,
                        }}>
                          <FileUploadArea
                            title={'Member Enrolment'}
                            iconName={'file-account-outline'}
                            dragDropMessage={'Drag and drop a PAPDIS enrolment file'}
                            handleChange={handleEnrolmentFileChange}
                            handleRemove={() => handleEnrolmentFileChange(undefined)}
                            types={PAPDIS_UPLOAD_FILE_TYPES}
                            multiple={false}
                            hoverTitle={'Release to add file'}
                            fileOrFiles={enrolmentFile}
                            submitHandler={handleEnrolmentFileUpload}
                            fileUploadStatus={enrolmentFileUploadStatus}
                          />
                        </View>
                      </View>
                    </Collapsible>
                    <Button
                      mode='text'
                      color={themeColors.accent}
                      icon={newCollapsed ? 'chevron-down' : 'chevron-up'}
                      onPress={newCollapsed ? showNew : hideNew}
                    >
                      {newCollapsed ? 'Enrol members...' : 'Hide'}
                    </Button>
                  </View>
                  <ContentDivider />
                </>
              : <Subheading>{`To enrol new members, please visit your Jarvis Employer Portal in a browser.`}</Subheading>
            }
            <OptionalSectionList
              sections={jobsList()}
              renderItem={renderItem}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`No current enrolment jobs`}
            />
          </ScrollView>
          <ConfirmationDialog
            visible={closeDialogVisible}
            title={'Are you sure?'}
            content={`This action cannot be undone!\n\nPlease confirm that you want to archive ${selectedJobIds.length} jobs${selectedJobIds.length > 1 ? 's' : ''}.`}
            onCancel={() => setCloseDialogVisible(false)}
            onConfirm={handleAcknowledgeMultiple}
            confirmLabel={`Mark ${selectedJobIds.length} Exception${selectedJobIds.length > 1 ? 's' : ''} as Actioned`}
            cancelLabel={'Go Back'}
          />
        </View>
      }
    </MainAppScreen>
  )
}
