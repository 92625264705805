import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { addDays, isAfter, startOfDay } from 'date-fns'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { useGetGroupSchemeMemberQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerMemberDataId, currentGroupScheme, setCurrentEmployerMemberDataId } from 'store/uxSlice'
import { Paper } from 'styles'
import { compact } from 'lodash'

export const EmployerMemberEditScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const currentMemberId = useAppSelector(currentEmployerMemberDataId)

  const { isLoading: memberIsLoading, error: memberError, data: member, refetch: refetchMember } = useGetGroupSchemeMemberQuery({ memberId: currentMemberId, id: currentScheme.id }, { skip: !currentMemberId } )
  const dispatch = useAppDispatch()

  const { id, client, employeeId, employeeEmail, autoEnrolmentOptOutDate, enrolmentEndDate, enrolmentStatus, employmentStartDate, enrolmentDate, enrolmentEndReason, autoEnrolmentWindowOptOutDate } = member || {}

  const close = () => {
    dispatch(setCurrentEmployerMemberDataId(undefined))
  }

  const isLoading = memberIsLoading
  const error: any = memberError

  const { colors: themeColors } = Paper.useAppTheme()

  const enrolmentStatusDetails =
    enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE
      ? enrolmentEndDate
        ? `Left scheme on: ${formatUkDate(new Date(enrolmentEndDate))}`
        : `Previously left scheme`
    : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT
      ? autoEnrolmentOptOutDate
        ? `Opted out on: ${formatUkDate(new Date(autoEnrolmentOptOutDate))}`
        : `Previously opted out`
    : enrolmentDate ? `Enrolled: ${formatUkDate(new Date(enrolmentDate))}`
    : employmentStartDate ? `Started: ${formatUkDate(new Date(employmentStartDate))}`
    : `Previously enrolled`

    const now = new Date()
    const optOutBoundaryDate = autoEnrolmentWindowOptOutDate ? addDays(startOfDay(new Date(autoEnrolmentWindowOptOutDate)), 1) : undefined
    const withinOptOutWindow = !!optOutBoundaryDate && isAfter(optOutBoundaryDate, now)
  

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      buttonTitle={'Close'}
      buttonAction={close}
      showButton={true}
      enableButton={true}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Jarvis Identifier`,
            value: member?.id,
            copyableValue: true,
          },
          {
            label: `Employee Identifier`,
            value: employeeId,
            copyableValue: true,
          },
          {
            label: `National Insurance No`,
            value: formatNationalInsuranceNumber(client?.nationalInsuranceNo),
            copyableValue: client?.nationalInsuranceNo,
          },
        ]}
        noContentDivider={true}
      />

      <Subheading style={{ color: themeColors.primary }}>{`Member Details`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Name`,
            value: `${client?.firstName} ${member?.client?.surname}`,
          },
          {
            label: `Gender`,
            value: client?.gender,
          },
          {
            label: `Date of Birth`,
            value: client?.birthDate ? formatUkDate(new Date(member?.client?.birthDate)) : '',
          },
          {
            label: `Employee Email`,
            value: employeeEmail,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Enrolment Status`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Status`,
            value: enrolmentStatus,
          },
          {
            label: `Details`,
            value: enrolmentStatusDetails,
          },
          enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE ? {
            label: `Reason`,
            value: enrolmentEndReason,
          } : undefined,
          enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE ? {
            label: `Opt Out Window ${withinOptOutWindow ? 'Ends' : 'Ended'}`,
            value: autoEnrolmentWindowOptOutDate ? formatUkDate(new Date(autoEnrolmentWindowOptOutDate)) : ``,
          } : undefined,
        ])}
        noContentDivider={true}
      />

    </ModalProcessScreen>
  )
}

