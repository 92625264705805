import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useGetCurrentUserQuery, useGetGroupSchemePaymentsQuery } from 'store/apiSlice'
import { GroupSchemePaymentCollectionStatus, GroupSchemePaymentStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerPaymentDataId } from 'store/uxSlice'
import { Flex, Paper } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerPaymentListItem } from '../Components/EmployerPaymentListItem'
import { platformIsWeb } from 'lib/platformHelpers'
import { canUserAdministerScheme, canUserExecutePaymentsForScheme } from 'lib/groupSchemeHelpers'

const isWeb = platformIsWeb()

export const EmployerPaymentsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)

  const { data: user, isLoading: userIsLoading, error: userError, refetch: refetchUser } = useGetCurrentUserQuery()
  const { data: payments, isLoading: paymentsIsLoading, error: paymentsError, isFetching: paymentsIsFetching, refetch: refetchPayments } = useGetGroupSchemePaymentsQuery({
    groupSchemeId: currentScheme?.id,
    incomplete: true
  }, { skip: !currentScheme })
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowPaymentDetails = (id: string) => {
    dispatch(setCurrentEmployerPaymentDataId(id))
  }

  const canAdminister = canUserAdministerScheme(currentScheme, user?.id)
  const canExecutePayments = canUserExecutePaymentsForScheme(currentScheme, user?.id)

  const paymentsList = () => {
    let failed = []
    let awaitingConfirmation = []
    let awaitingPayment = []
    let paid = []
    if (payments) {
      payments.map((data, id) => {
        if (data.collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS || data.collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED) {
          failed.push(data)
        } else if (data.status === GroupSchemePaymentStatus.AWAITING_CONFIRMATION) {
          awaitingConfirmation.push(data)
        } else if (data.status === GroupSchemePaymentStatus.AWAITING_PAYMENT) {
          awaitingPayment.push(data)
        } else if (data.status === GroupSchemePaymentStatus.DECLARED || data.status === GroupSchemePaymentStatus.PAID) {
          paid.push(data)
        } 
      })
      return [
        { title: 'Failed Collection', data: failed },
        { title: 'Awaiting Confirmation', data: awaitingConfirmation },
        { title: 'Awaiting Payment', data: awaitingPayment },
        { title: 'Paid', data: paid },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerPaymentListItem
        payment={item}
        onPressFunction={() => handleShowPaymentDetails(item?.id)}
        canAdminister={canAdminister}
        canExecutePayments={canExecutePayments}
      />
    )
  }

  const isLoading = paymentsIsLoading || paymentsIsFetching || userIsLoading
  const error: any = paymentsError || userError

  const onRefresh = () => {
    setRefreshing(true)
    refetchPayments()
    setRefreshing(false)
  }

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding payments...')} /> :
        error ?  <ErrorScreen errorTryAgain={userError ? refetchUser : refetchPayments} error={error?.data} /> : 
          <View style={ Flex.column.start } >
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
            >
              <OptionalSectionList
                sections={paymentsList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`No current payemnts`}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
