import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { useLazyGetGroupSchemeJobsQuery } from 'store/apiSlice'
import { GroupSchemeJobType, GroupSchemeJobSearchStatus, GroupSchemeJobSearchType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerJobDataId } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerHistoryEnrolmentListItem } from '../Components/EmployerHistoryEnrolmentListItem'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const EmployerHistoryEnrolmentsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const [getJobsQuery, { data: jobs, isLoading: jobsIsLoading, error: jobError, isFetching: jobsIsFetching }] = useLazyGetGroupSchemeJobsQuery()
  const [refreshing, setRefreshing] = useState(false)

  //Swap results when switching scheme
  useEffect(() => {
    if (currentScheme && isValid) {
      onSubmit(watch())
    }
  }, [currentScheme])

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowJobDetails = (id: string) => {
    dispatch(setCurrentEmployerJobDataId(id))
  }

  const jobsList = () => {
    let all = []
    if (jobs) {
      jobs.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerHistoryEnrolmentListItem
        job={item}
        onPressFunction={() => handleShowJobDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{
    search: string,
    status: GroupSchemeJobSearchStatus,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      status: GroupSchemeJobSearchStatus.ANY,
    },
  })

  const { handleSubmit, reset, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const status = attributes.status === GroupSchemeJobSearchStatus.ANY ? undefined : attributes.status
    getJobsQuery({
      groupSchemeId: currentScheme.id,
      search: attributes.search,
      status,
      jobType: GroupSchemeJobType.MEMBER,
    })
  }

  const isLoading = jobsIsLoading || jobsIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      status: GroupSchemeJobSearchStatus.ANY,
    })
  }
 
  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding jobs...')} /> :
          <View style={ Flex.column.start } >
            <View style={{ paddingHorizontal: Sizing.x30 }} >
              <ManagedTextInput
                name={'search'}
                label={'Search for an enrolment job'}
                autoFocus={false}
                formObj={formObj}
                placeholder={'Search by original upload filename'}
                blurOnSubmit={false}
                submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                rules={{
                  required: true,
                  minLength:{
                    value: 3,
                    message: 'Must be at least 3 characters'
                  },
                }} />
              <Collapsible collapsed={searchCollapsed}>
                <ScrollView>
                  <ManagedAutoCompleteInput
                    name={'status'}
                    label={'Status'}
                    selectOnlyMode={true}
                    formObj={formObj}
                    dataSet={enumToAutocompleteOptions(GroupSchemeJobSearchStatus)}
                  />
                  <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                  <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                </ScrollView>
              </Collapsible>
              <Button
                mode='text'
                color={themeColors.accent}
                icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                onPress={searchCollapsed ? showAdvanced : hideAdvanced}
              >
                {searchCollapsed ? 'Advanced search...' : 'Hide'}
              </Button>
              <ContentDivider />
            </View>
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
            >
              <OptionalSectionList
                sections={jobsList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`Please search for an enrolment job`}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
