import ModalProcessStackBuilder from "navigation/stacks/ModalProcessStackBuilder"
import React, { useEffect } from 'react'
import { ClientBaseDto } from "store/dto/client.dto"
import { useAppDispatch } from 'store/hooks'
import { updateWorkingRetirementAsset } from "store/tempDataSlice"
import { setAddRetirementAssetClient } from 'store/uxSlice'
import { RetirementAssetsAddAsset_00_Intro, RetirementAssetsAddAsset_01_Type, RetirementAssetsAddAsset_02_Employer, RetirementAssetsAddAsset_03_PublicSectorChoice, RetirementAssetsAddAsset_04_ProviderBrand, RetirementAssetsAddAsset_07_ContributionChoice, RetirementAssetsAddAsset_08_ContributionSource, RetirementAssetsAddAsset_09_ContributionAmount, RetirementAssetsAddAsset_10_Value, RetirementAssetsAddAsset_11_Name, RetirementAssetsAddAsset_99_Result } from "./Screens"

export type RetirementAssetsAddAssetProcessStackProps = {
  client: ClientBaseDto
  onlyPensions?: boolean
  forTransfer?: boolean
  onDismiss?: any
}

export const RetirementAssetsAddAssetProcessStack = (props: RetirementAssetsAddAssetProcessStackProps) => {
  const { onlyPensions, forTransfer, client } = props

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setAddRetirementAssetClient(undefined))
  
  const visible = !!client
  const onDismiss = props.onDismiss || globalDismiss

  //Clear on start
  useEffect(() => {
    dispatch(updateWorkingRetirementAsset(undefined))
  }, []
  )

  return (
    <ModalProcessStackBuilder
      visible={visible}
      onDismiss={onDismiss}
      dismissConfirmationMessage={`Cancel adding this ${onlyPensions ? `pension` : `asset`}?`}
      processTitle='Add Retirement Asset'
      initialRouteName='Intro'
      initialParams={{
        onlyPensions,
        forTransfer,
        client,
      }}
      screens={[
        {
          navigationId: 'Intro',
          title: 'Add Asset',
          component: RetirementAssetsAddAsset_00_Intro,
        },
        {
          navigationId: 'Type',
          title: 'Type',
          component: RetirementAssetsAddAsset_01_Type,
        },
        {
          navigationId: 'Employer',
          title: 'Employer',
          component: RetirementAssetsAddAsset_02_Employer,
        },
        {
          navigationId: 'PublicSectorChoice',
          title: 'PublicSectorChoice',
          component: RetirementAssetsAddAsset_03_PublicSectorChoice,
        },
        {
          navigationId: 'Brand',
          title: 'Brand',
          component: RetirementAssetsAddAsset_04_ProviderBrand,
        },        
        {
          navigationId: 'ContributionChoice',
          title: 'Contributions',
          component: RetirementAssetsAddAsset_07_ContributionChoice,
        },
        {
          navigationId: 'ContributionSource',
          title: 'Contributions',
          component: RetirementAssetsAddAsset_08_ContributionSource,
        },
        {
          navigationId: 'ContributionAmount',
          title: 'Contributions',
          component: RetirementAssetsAddAsset_09_ContributionAmount,
        },
        {
          navigationId: 'Value',
          title: 'Current Value',
          component: RetirementAssetsAddAsset_10_Value,
        },
        {
          navigationId: 'Name',
          title: 'Name',
          component: RetirementAssetsAddAsset_11_Name,
        },
        {
          navigationId: 'Results',
          title: '',
          component: RetirementAssetsAddAsset_99_Result,
        },
      ]}
    />
  )
}

