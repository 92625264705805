import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { RetirementAssetsList } from 'features/MenuSections/OtherPensions/Components/RetirementAssetsList'
import { RetirementAssetsAddAssetProcessStack } from 'features/ModalProcesses/RetirementAssetAdd/RetirementAssetsAddAssetProcessStack'
import { RetirementAssetsEditAssetModal } from 'features/ModalProcesses/RetirementAssetEdit/RetirementAssetsEditAssetModal'
import { SpouseAddProcessStack } from 'features/ModalProcesses/SpouseAdd/SpouseAddProcessStack'
import { getScreenAppHeight, scaleNormalizer } from 'lib/scaleHelpers'
import { isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useGetMeQuery, useGetRetirementAssetsQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementAssetDto, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch } from 'store/hooks'
import { enableHeader, setOnAssetSpouseView } from 'store/uxSlice'

export const RetirementAssetSetup_04_Assets = ({ route, navigation }) => {
  const { nextScreen } =  route?.params || {}

  const dispatch = useAppDispatch()

  const [addSpouse, setAddSpouse] = useState(false)
  const [addAssetClient, setAddAssetClient] = useState(undefined)
  const [editAsset, setEditAsset] = useState(undefined)

  const [hasAssets, setHasAssets] = useState(false)

  const [showDialog, setShowDialog] = useState(false)

  const { data: me, isLoading: meIsLoading, error: meError, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const refetchAll = () => {
    refetchMe()
    refetchSpouse()
    refetchAssets()
  }

  // const disableHeader = !!addSpouse || !!addAssetClient || !!editAsset
  // dispatch(enableHeader(!disableHeader))

  const isLoading = meIsLoading || assetsIsLoading || spouseIsLoading
  const error: any = meError || assetsError

  const onSubmit = () => {
    setShowDialog(false)
    const pensionTypes = [RetirementAssetType.PERSONAL, RetirementAssetType.WORKPLACE]
    const selectableAssets = assets ? assets.filter(asset => {
      return asset.clientId === me.id && pensionTypes.includes(asset.assetType)
    }) : []

    //Go to next screen if any selectableAssets, else to result
    if (selectableAssets.length) {
      navigation.navigate(nextScreen)
    } else {
      navigation.navigate('Results')
    }    
  }

  //Update hasAssets when assets change
  useEffect(() => {
    setHasAssets(assets && isArray(assets) && assets.length ? true : false)
  }, [assets])

  //Reset tab on entry
  useEffect(() => {
    dispatch(setOnAssetSpouseView(false))
  }, [])

  const appScreenHeight = getScreenAppHeight()
  const navigatorHeight = appScreenHeight - scaleNormalizer(400)
    
  return (
    <>
      {
        addSpouse ?
        <SpouseAddProcessStack
          visible={addSpouse}
          onDismiss={() => setAddSpouse(false)}
        />
        : addAssetClient ?
        <RetirementAssetsAddAssetProcessStack
          client={addAssetClient}
          onDismiss={() => setAddAssetClient(undefined)}
        />
        : editAsset ?
        <RetirementAssetsEditAssetModal
          asset={editAsset}
          onDismiss={() => setEditAsset(undefined)}
        />
        :
        <ProcessScreen
          isLoading={isLoading}
          error={error}
          errorTryAgain={refetchAll}
          buttonTitle={'Finish'}
          buttonAction={onSubmit}
          showButton={true}
          enableButton={!assetsIsLoading && !assetsIsFetching && hasAssets}
          allowTextButton={!hasAssets}
          textButtonTitle={spouse ? `We don't have any`: `I don't have any`}
          textButtonAction={() => setShowDialog(true)}
          headline={`Let's capture all your retirement assets`}
          subHeading={`Include any existing pensions, savings or assets you plan to keep until retirement`}
        >
          <RetirementAssetsList
            showAddSpouse={true}
            addAssetStateFunction={(client: ClientBaseDto) => setAddAssetClient(client)}
            editAssetStateFunction={(asset: RetirementAssetDto) => setEditAsset(asset)}
            addSpouseStateFunction={(visible: boolean) => setAddSpouse(visible)}
            navigatorHeight={navigatorHeight}
            refreshFunctions={[refetchAssets]}
          />
          <ConfirmationDialog
            visible={showDialog}
            onCancel={() => setShowDialog(false)}
            title={'Are you sure?'}
            content={`You can add retirement assets later from the menu`}
            onConfirm={onSubmit}
          />
        </ProcessScreen>
      }
    </>
  )
}