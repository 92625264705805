import { ModalProcessScreen, ModalProcessScreenButtonProps } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatSortCode } from 'lib/clientHelpers'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { canUserAdministerScheme, canUserExecutePaymentsForScheme } from 'lib/groupSchemeHelpers'
import { getBestName } from 'lib/userHelpers'
import { compact } from 'lodash'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useConfirmGroupSchemePaymentMutation, useExecuteGroupSchemePaymentMutation, useGetContributionBankAccountQuery, useGetCurrentUserQuery, useGetGroupOrganizationMandateQuery, useGetGroupSchemeJobQuery, useGetGroupSchemePaymentQuery } from 'store/apiSlice'
import { PaymentMethod } from 'store/dto/account.dto'
import { GroupOrganizationMandateStatus } from 'store/dto/group-organization.dto'
import { GroupSchemePaymentCollectionStatus, GroupSchemePaymentStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerPaymentDataId, currentGroupScheme, setCurrentEmployerPaymentDataId, setSnackbarData } from 'store/uxSlice'
import { Paper, Sizing } from 'styles'

const PAID_STATUSES: GroupSchemePaymentStatus[] = [
  GroupSchemePaymentStatus.PAID,
  GroupSchemePaymentStatus.DECLARED,
  GroupSchemePaymentStatus.COMPLETED,
] 

export const EmployerPaymentEditScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const currentPaymentId = useAppSelector(currentEmployerPaymentDataId)

  const { organization, defaultContributionMethod: schemeDefaultContributionMethod } = currentScheme || {}
  const { bankDetails, mandateStatus, defaultContributionMethod: organizationDefaultContributionMethod } = organization || {}
  const { accountName, accountNumber, sortCode } = bankDetails || {}
  const organizationHasActiveMandate = mandateStatus === GroupOrganizationMandateStatus.ACTIVE

  const [executeManualDialogVisible, setExecuteManualDialogVisible] = useState(false)
  const [approveCollectionDialogVisible, setApproveCollectionDialogVisible] = useState(false)
  const [conrfirmDialogVisible, setConfirmDialogVisible] = useState(false)

  const { data: user, isLoading: userIsLoading, error: userError, refetch: refetchUser } = useGetCurrentUserQuery()
  
  const canAdminister = canUserAdministerScheme(currentScheme, user?.id)
  const canExecutePayments = canUserExecutePaymentsForScheme(currentScheme, user?.id)

  const { data: payment, isLoading: paymentIsLoading, error: paymentError, refetch: refetchPayment } = useGetGroupSchemePaymentQuery({ paymentId: currentPaymentId, groupSchemeId: currentScheme.id }, { skip: !currentPaymentId } )
  const { data: job, isLoading: jobIsLoading, error: jobError, refetch: refetchJob } = useGetGroupSchemeJobQuery({ jobId: payment?.groupSchemeJobId, groupSchemeId: currentScheme.id }, { skip: !canAdminister || !payment?.groupSchemeJobId } )
  const { data: payee, error: payeeError, isLoading: payeeIsLoading, refetch: refetchPayee } = useGetContributionBankAccountQuery()
  const { data: mandate, isLoading: mandateIsLoading, error: mandateError } = useGetGroupOrganizationMandateQuery(currentScheme?.organizationId, { skip: !organizationHasActiveMandate} )

  const [confirmPayment, { data: confirmedPayment, isLoading: confirmIsLoading, error: confirmError }] = useConfirmGroupSchemePaymentMutation()
  const [executePayment, { data: executedPayment, isLoading: executeIsLoading, error: executeError }] = useExecuteGroupSchemePaymentMutation()

  const { contributionSortCode, contributionAccountLast4 } = mandate || {}

  const {
    id: paymentId,
    status,
    reference,
    amount,
    confirmer,
    contributionMethod,
    collectionStatus,
    payer,
    paidAt,
    expectedCollectionAt,
    expectedCompleteAt,
  } = payment || {}

  const dispatch = useAppDispatch()

  const close = () => {
    dispatch(setCurrentEmployerPaymentDataId(undefined))
  }

  const isLoading = paymentIsLoading || jobIsLoading || confirmIsLoading || executeIsLoading || payeeIsLoading || mandateIsLoading || userIsLoading
  const error: any = paymentError || jobError || confirmError || executeError || payeeError || userError

  const { colors: themeColors } = Paper.useAppTheme()

  const handleConfirm = async () => {
    confirmPayment({
      groupSchemeId: currentScheme?.id,
      paymentId,
    })
    setConfirmDialogVisible(false)
  }

  const handleExceute = async (contributionMethod: PaymentMethod) => {
    executePayment({
      groupSchemeId: currentScheme?.id,
      paymentId,
      contributionMethod,
    })
    setExecuteManualDialogVisible(false)
    close()
  }

  useEffect(() => {
    if (executedPayment) {
      const { contributionMethod, amount } = executedPayment
      if (contributionMethod === PaymentMethod.DIRECT_DEBIT) {
        dispatch(setSnackbarData({
          message: `Direct Debit Collection`,
          subMessage: `We've requested collection of ${formatCurrencyAmount(amount, 2)} via Direct Debit`,
          iconName: 'bank-transfer-out',
          duration: 5000,
        }))
      }
    }
  }, [executedPayment])

  const ExecuteManualDialogContent = () => {
    return (
      <View style={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}>
        <Paragraph>{isAlreadyPaid && contributionMethod !== PaymentMethod.DIRECT_DEBIT
          ? `This payment is already recorded as paid. If you have not yet made payment, please do so from your account '${accountName}' (${formatSortCode(sortCode)} ${accountNumber}) with these details:`
          : `Please make payment now from your account '${accountName}' (${formatSortCode(sortCode)} ${accountNumber}) with these details:`
        }</Paragraph>
        <UnborderedTable
          data={compact([
            {
              label: `Payee Type`,
              value: `Business`,
            },
            {
              label: `Payee Name`,
              value: `Seccl Custody`,
              copyableValue: `Seccl Custody`,
            },
            {
              label: `Sort Code`,
              value: formatSortCode(payee?.sortCode),
              copyableValue: payee?.sortCode,
            },
            {
              label: `Account Number`,
              value: payee?.accountNumber,
              copyableValue: payee?.accountNumber,
            },
            {
              label: `Amount`,
              value: formatCurrencyAmount(amount, 2),
              copyableValue: amount,
            },
            {
              label: `Reference`,
              value: reference,
              copyableValue: true,
            },
          ])}
          noContentDivider={true}
        />
      </View>
    )
  }


  const currentlyDirectDebit = contributionMethod === PaymentMethod.DIRECT_DEBIT

  const isAwaitingConfirmation = status === GroupSchemePaymentStatus.AWAITING_CONFIRMATION
  const isAwaitingPayment = status === GroupSchemePaymentStatus.AWAITING_PAYMENT
  const isFailedCollection = isAwaitingPayment && [
    GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS, 
    GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED,
  ].includes(collectionStatus)
  const isAlreadyPaid = PAID_STATUSES.includes(status)

  const showDdCollectionStatus = contributionMethod === PaymentMethod.DIRECT_DEBIT &&
  (status === GroupSchemePaymentStatus.PAID || (
    collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS ||
    collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED
  ))


  const buttonProps: ModalProcessScreenButtonProps = isAwaitingConfirmation ? {
      buttonTitle: 'Confirm Payment Amount',
      buttonAction: () => setConfirmDialogVisible(true),
      enableButton: canAdminister,
      allowTextButton: true,
      textButtonTitle: 'Close',
      textButtonAction: close,
      enableTextButton: true,
      footerInfo: canAdminister ? undefined : <Text style={localStyles.text}>{`You do not have access to confirm payment amounts.`}</Text>,
    }
    : isAwaitingPayment ?
      organizationHasActiveMandate ?
        currentlyDirectDebit ?{
          buttonTitle: isFailedCollection ? 'Re-attempt Direct Debit Collection' : 'Approve Direct Debit Collection',
          buttonAction: () => setApproveCollectionDialogVisible(true),
          enableButton: canExecutePayments,
          allowTextButton: true,
          textButtonTitle: 'Pay By BACS Transfer Instead',
          textButtonAction: () => setExecuteManualDialogVisible(true),
          enableTextButton: canExecutePayments,
          footerInfo: canExecutePayments ? undefined : <Text style={localStyles.text}>{`You do not have access to approve/declare payment.`}</Text>,
        } : {
          buttonTitle: 'Pay By BACS Transfer',
          buttonAction: () => setExecuteManualDialogVisible(true),
          enableButton: canExecutePayments,
          allowTextButton: true,
          textButtonTitle: 'Collect By Direct Debit Instead',
          textButtonAction: () => setApproveCollectionDialogVisible(true),
          enableTextButton: canExecutePayments,
          footerInfo: canExecutePayments ? undefined : <Text style={localStyles.text}>{`You do not have access to approve/declare payment.`}</Text>,
        }
      : {
        buttonTitle: 'Pay By BACS Transfer',
        buttonAction: () => setExecuteManualDialogVisible(true),
        enableButton: canExecutePayments,
        allowTextButton: true,
        textButtonTitle: 'Close',
        textButtonAction: close,
        enableTextButton: true,
        footerInfo: canExecutePayments ? undefined : <Text style={localStyles.text}>{`You do not have access to approve/declare payment.`}</Text>,
      }
    : isAlreadyPaid ?
      currentlyDirectDebit ? {
        buttonTitle: 'Close',
        buttonAction: close,
        enableButton: true,
        allowTextButton: false,
      } : {
        buttonTitle: 'Close',
        buttonAction: close,
        enableButton: true,
        allowTextButton: canExecutePayments,
        textButtonTitle: 'Show BACS Transfer Instructions',
        textButtonAction: () => setExecuteManualDialogVisible(true),
        enableTextButton: true,
      }
    : {
      buttonTitle: 'Close',
      buttonAction: close,
      enableButton: true,
      allowTextButton: false,
    }

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={
        executeError ? handleExceute
        : confirmError ? handleConfirm
        : paymentError ? refetchPayment
        : payeeError ? refetchPayee
        : userError ? refetchUser
        : refetchJob
      }
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={undefined}
      showButton={true}
      {...buttonProps}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Payment Details`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Identifier`,
            value: paymentId,
            copyableValue: true,
          },
          {
            label: `Payment Reference`,
            value: reference,
            copyableValue: true,
          },
          {
            label: `Amount`,
            value: formatCurrencyAmount(amount, 2),
            copyableValue: amount,
          },
        ])}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Payment Status`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Status`,
            value: status,
          },
          status !== GroupSchemePaymentStatus.AWAITING_CONFIRMATION && confirmer ?
          {
            label: `Confirmed By`,
            value: getBestName(confirmer)
          }
          : undefined,
          isAlreadyPaid && payer ?
          {
            label: `Paid By`,
            value: getBestName(payer)
          }
          : undefined,
          isAlreadyPaid && paidAt ?
          {
            label: `Paid At`,
            value: getActionDate(paidAt)
          }
          : undefined,
          isAlreadyPaid ? {
            label: `Payment Method`,
            value: contributionMethod,
          } : undefined,
          showDdCollectionStatus ?
          {
            label: `Direct Debit Status`,
            value: collectionStatus
          }
          : undefined,
          isAlreadyPaid && expectedCollectionAt && currentlyDirectDebit && collectionStatus === GroupSchemePaymentCollectionStatus.AWAITING_COLLECTION ?
          {
            label: `Expected Collection`,
            value: getActionDate(expectedCollectionAt, true)
          }
          : undefined,
          isAlreadyPaid && expectedCompleteAt ?
          {
            label: `Expected Completion`,
            value: getActionDate(expectedCompleteAt, true)
          }
          : undefined,
        ])}
        noContentDivider={true}
      />

      {
        canAdminister ?
        <>
          <ContentDivider />
          <Subheading style={{ color: themeColors.primary }}>{`Related Contribution Job`}</Subheading>
          <UnborderedTable
            data={[
              {
                label: `Identifier`,
                value: job?.id,
                copyableValue: true,
              },
              {
                label: `Uploaded At`,
                value: job?.createdAt ? formatUkDate(new Date(job?.createdAt)) : ``,
              },
              {
                label: `Upload Result`,
                value: job?.status,
              },
            ]}
            noContentDivider={true}
          />
        </>
        : <></>
      }
  
      <ConfirmationDialog
        visible={conrfirmDialogVisible}
        title={'Confirm Payment?'}
        content={`Please confirm that the payment amount is as expected.\n\nConfirming the payment will make it available for payment by ${currentScheme?.organization?.financeUser ? 'the primary and finance users' : 'the primary user'}.`}
        onCancel={() => setConfirmDialogVisible(false)}
        onConfirm={handleConfirm}
        confirmLabel={'Confirm'}
        cancelLabel={'Go Back'}
      />
      <ConfirmationDialog
        visible={executeManualDialogVisible}
        title={`BACS Transfer Instructions`}
        content={<ExecuteManualDialogContent />}
        onCancel={isAlreadyPaid ? undefined : () => setExecuteManualDialogVisible(false)}
        onConfirm={isAlreadyPaid
          ? () => setExecuteManualDialogVisible(false)
          : () => handleExceute(PaymentMethod.BANK_TRANSFER)}
        confirmLabel={isAlreadyPaid ? 'Close' : 'Confirm As Paid'}
        cancelLabel={'Go Back'}
      />
      <ConfirmationDialog
        visible={approveCollectionDialogVisible}
        title={`Direct Debit Collection`}
        content={`Please confirm you would like us to attempt collection of this payment via Direct Debit from your account ${formatSortCode(contributionSortCode)} ****${contributionAccountLast4}.\n\nOnce approved, it normally takes around 3 business days for collection to occur.`}
        onCancel={() => setApproveCollectionDialogVisible(false)}
        onConfirm={() => handleExceute(PaymentMethod.DIRECT_DEBIT)}
        confirmLabel={'Approve Collection'}
        cancelLabel={'Go Back'}
      />
    </ModalProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  text: {
    paddingBottom: Sizing.x5,
  },
})