import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ProcessHeaderTitle } from 'components/Typography/ProcessHeaderTitle'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React, { useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import { Flex, Sizing, Paper } from 'styles'
import { layoutStyles } from 'styles/common'

export type ModalEditHeaderProps = {
  formTitle: string
  onDismiss: any
  onDelete?: any
  deleteDialogText: string
}

export const ModalEditHeader = (props: ModalEditHeaderProps) => {
  const { formTitle, onDismiss, deleteDialogText, onDelete } = props
  
  const { colors: themeColors } = Paper.useAppTheme()

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const showDelete = !!onDelete

  const handleDelete = () => {
    hideDialog()
    onDelete()
  }

  const handleClose = () => {
    Keyboard.dismiss()
    onDismiss()
  }

  return (
    <>
      <View style={layoutStyles.modalHeaderContainer}>
        <View style={localStyles.headerRow}>
          <View style={localStyles.headerIconContainer}>
            { showDelete ?
              <MaterialCommunityIcons name="delete-outline" size={Sizing.x30} color={themeColors.primary} onPress={showDialog} />
              : <></>
            }
          </View>
          <View style={localStyles.headerTitleContainer}>
            <ProcessHeaderTitle>{formTitle}</ProcessHeaderTitle>
          </View>
          <View style={localStyles.headerIconContainer}>
            <MaterialCommunityIcons name="close" size={Sizing.x30} color={themeColors.primary} onPress={handleClose} />
          </View>
        </View>
      </View>
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={deleteDialogText}
        onCancel={hideDialog}
        onConfirm={handleDelete}
      />
    </>
  )
}

const localStyles = StyleSheet.create({
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
  },
  headerIconContainer: {
    ...Flex.column.center,
    width: Sizing.x50,
    alignItems: 'center'
  },
  headerTitleContainer: {
    ...Flex.column.center,
    flex: 1,
    paddingHorizontal: Sizing.x20,
  },
})