import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useUpdateBeneficiariesMutation } from 'store/apiSlice'
import { Title } from 'store/dto/base.dto'
import { BeneficiaryRelationship, Nomination } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editBeneficiaryData, setEditBeneficiaryData } from 'store/uxSlice'

export const EditBeneficiaryEditScreen = () => {
  const dispatch = useAppDispatch()
  const editBeneficiary = useAppSelector(editBeneficiaryData)
  const { allNominations, editIndex, currentNomination} = editBeneficiary || {}

  //Form refs for focussing
  const firstNameRef = useRef()
  const surnameRef = useRef()

  const [updateBeneficiaries, { data: savedBeneficiaries, error, isLoading}] = useUpdateBeneficiariesMutation()

  //Setup form
  const formObj = useForm<{
    title: Title
    firstName: string
    surname: string
    beneficiaryRelationship: BeneficiaryRelationship
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: currentNomination?.title,
      firstName: currentNomination?.firstName,
      surname: currentNomination?.surname,
      beneficiaryRelationship: currentNomination?.beneficiaryRelationship
    }
  })
  const {
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: {isDirty, isValid}
  } = formObj

  const onSubmit = async attributes => {
    let filterData = allNominations?.map(
      (data: Nomination, index: number) => {
        if (editIndex === index) {
          return {
            title: isCharity ? Title.MR : attributes.title,
            firstName: attributes.firstName,
            surname: isCharity ? attributes.firstName : attributes.surname,
            beneficiaryRelationship: attributes.beneficiaryRelationship,
            beneficiaryProportion: currentNomination.beneficiaryProportion
          }
        }
        return data
      }
    )

    updateBeneficiaries({nominations: [...filterData]})

  }

  useEffect(() => {
    if (savedBeneficiaries) {    
      const { nominations } = savedBeneficiaries
      ampli.beneficiariesChange({
        beneficiaryCount: nominations ? nominations.length : 0,
        beneficiaryRelationships: nominations ? nominations.map(nomination => {
          return nomination.beneficiaryRelationship
        }) : [],
        changeReason: 'edit',
      })
      close()
    }
  }, [savedBeneficiaries])

  const close = () => {
    dispatch(setEditBeneficiaryData(undefined))
  }

  const isCharity = watch('beneficiaryRelationship') === BeneficiaryRelationship.CHARITY

  return (
    <ModalProcessScreen
      buttonTitle={'Save'}
      isLoading={isLoading}
      loadingMessage={['Saving beneficiary...']}
      error={error}
      errorTryAgain={handleSubmit(onSubmit)}
      errorCancel={close}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      {
        isCharity ? <></> :
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Title)}
          required={true}
        />
      }
      <ManagedTextInput
        ref={firstNameRef}
        name={'firstName'}
        formObj={formObj}
        label={isCharity ? 'Charity Name' : 'First Name'}
        placeholder={isCharity ? 'The charity legal name' : 'Their legal first name'}
        returnKeyType={'next'}
        submitHandler={isCharity ? undefined : () => surnameRef?.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40
        }}
      />
      {
        isCharity ? <></> :
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Surname'}
          placeholder={'Their legal surname'}
          returnKeyType={'next'}
          blurOnSubmit={true}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40
          }}
        />
      }

      <ManagedAutoCompleteInput
        name={'beneficiaryRelationship'}
        formObj={formObj}
        label={'Relationship'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(BeneficiaryRelationship)}
        required={true}
      />
    </ModalProcessScreen>
  )
}
