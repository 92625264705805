import { Text } from 'components/Typography/Text'
import { envVariables } from 'environment'
import React from 'react'
import { StyleSheet } from 'react-native'
import { Sizing, Typography } from 'styles'
import { ExternalLinkText } from './ExternalLinkText'

export const RaindropInfo = () => {

  const { raindropPrivacyUrl, pensionFindingTermsUrl } = envVariables

  return (
    <>
      <Text style={localStyles.text}>
        {`Jarvis manages your SIPP account and transfers, whily Raindrop Technology Holding Ltd assists in finding your pensions. `}
        <ExternalLinkText url={pensionFindingTermsUrl} usePdfViewer={true}>{`Pension Finding Terms Apply`}</ExternalLinkText>
        {`\n\n`}
      </Text>
      <Text style={localStyles.smallText}>
        {`Raindrop Technologies Holdings Ltd (FRN: 931052) is authorised and regulated by the Financial Conduct Authority (FRN: 1003844). `}
        <ExternalLinkText style={localStyles.smallText} url={raindropPrivacyUrl}>{`Privacy Policy`}</ExternalLinkText> 
      </Text>
      
    </>
  )
}

const localStyles = StyleSheet.create({
  text: {
    textAlign: 'left',
    paddingBottom: Sizing.x5,
  },
  smallText: {
    textAlign: 'left',
    paddingBottom: Sizing.x10,
    ...Typography.fontSize.x10
  },
})
