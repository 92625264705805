import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { PensionProviderModalContent, PensionProviderModalContentSection } from 'components/Utility/PensionProviderModalContent'
import { RaindropInfo } from 'components/Utility/RaindropInfo'
import { InformationSectionKey, getDiagnosticInformation, getDiagnosticString } from 'lib/appInfoHelpers'
import { featureIsEnabledForUser } from 'lib/featureHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import { Logger } from 'lib/logger'
import { orderBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetUserFeaturesQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { ClientMeDto } from 'store/dto/client.dto'
import { FeatureCode } from 'store/dto/feature.dto'
import { RetirementAssetDto, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Colors, Sizing, Flex } from 'styles'
import { ProviderInfoButton } from '../Components/ProviderInfoButton'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export const RetirementAssetTransfer_04_ProviderList = ({ route, navigation }) => {
  const { nextScreen, asset, isRetransfer, fromSuggestions, client }: { nextScreen: string, asset: RetirementAssetDto, isRetransfer: boolean, fromSuggestions: boolean, client: ClientMeDto } = route?.params || {}
  const [showDialog, setShowDialog] = useState(false)

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()
  const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery()

  const providerOptions: AutoCompleteItem[] = providers && brands ? orderBy(providers, ['name'], ['asc']).map(provider => {
    const primaryBrand = brands.find(brand => {
      return brand.key === provider.primaryBrandKey
    })
    return {
      value: provider?.id,
      label: provider?.name,
      description: provider?.description,
      icon: primaryBrand?.logo
      ? () => <Image source={{ uri: primaryBrand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }

  }) : []

  const [updateAssetProvider, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()

  const error = featuresError || brandsError || providersError || updateAssetError
  const isLoading = featuresIsLoading || brandsIsLoading || providersIsLoading || updateAssetIsLoading

  const isWorkplace = asset?.assetType === RetirementAssetType.WORKPLACE

  const alreadyHadProvider = asset?.pensionProviderId

  //Temp state
  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Form refs for focussing
  const providerRef = useRef()

  //Setup form
  const formObj = useForm<{ pensionProviderId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionProviderId: workingRetirementAssetData?.pensionProviderId !== undefined ? workingRetirementAssetData?.pensionProviderId : asset?.pensionProviderId,
    },
  })
  const { handleSubmit, watch, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: false,
    }))
    //Skip save if no changed...
    if (asset?.pensionProviderId === attributes?.pensionProviderId) {
      end()
    } else {
      Logger.info(`Updating asset provider...`)
      updateAssetProvider({
        id: asset?.id,
        tracingRequested: false,
        pensionProviderId: attributes?.pensionProviderId,
      })
    }
  }

  //Navigate on finish
  useEffect(() => {
    if (updatedAsset) {
      end()
    }
  }, [updatedAsset])

  const end = () => {
    navigation.navigate(nextScreen)
  }

  const goToResult = () => {
    setShowDialog(false)
    navigation.navigate('Result')
  }

  //Previously traced if has any sort of tracingStatus
  const previouslyTraced = asset?.tracingStatus
  const tracingEnabledForUser = featureIsEnabledForUser(features, FeatureCode.TRACING)
  const tracingRouteAvailable = tracingEnabledForUser && !previouslyTraced && !isRetransfer

  const goToTracing = () => {
    dispatch(updateWorkingRetirementAsset({
      tracingRequested: true,
    }))
    setShowDialog(false)
    navigation.navigate('PersonalDetailsIntro')
  }

  const pensionProviderId = watch('pensionProviderId')
  const noChange = pensionProviderId && pensionProviderId === asset?.pensionProviderId

  const selectedProvider = providers ? providers.find(provider => {
    return provider.id === pensionProviderId
  }) : undefined

  const selectedProviderBrand = selectedProvider && brands ? brands.find(brand => {
    return brand.key === selectedProvider?.primaryBrandKey
  }) : undefined

  const providerImageSource = selectedProviderBrand?.logo ? { uri: selectedProviderBrand?.logo } : require('assets/icons/default_provider.png')

  const contactSupport = async () => {
    const informationArray = getDiagnosticInformation(undefined, client)
    const infoBody = getDiagnosticString(informationArray, [
      InformationSectionKey.DATE,
      InformationSectionKey.USER,
    ])
    let initialMessage = `----- Jarvis Missing Pension Provider -----\n\n`
    initialMessage += infoBody
    if (workingRetirementAssetData?.pensionProviderBrandName) {
      initialMessage += `\n---------------------------------------\n`
      initialMessage += `Pension Brand Name: ${workingRetirementAssetData?.pensionProviderBrandName} \n`
    }
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Please enter details of the missing provider below.\n`
    initialMessage += `---------------------------------------\n`

    await composeIntercomMessage(initialMessage)
  }

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={!features ? refetchFeatures : !brands ? refetchBrands : !providers ? refetchProviders : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : ['Finding providers...']}
      buttonTitle={isRetransfer
        ? noChange
          ? `Yes, It's Correct`
          : `Change Provider`
        : pensionProviderId
          ? `Confirm`
          : `Make a Selection`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={isRetransfer
        ? 'Is the current provider correct?'
        : alreadyHadProvider
          ? 'Confirm current provider'
          : !workingRetirementAssetData?.pensionProviderBrandName
            ? `Ok, can you identify them from this full list?`
            : !workingRetirementAssetData?.pensionProviderBrandKey
              ? `Ok, we don't yet know that provider name`
              : fromSuggestions
                ? `Ok, here's the full list`
                : `Ok, we need to identify the current provider`
      }
      subHeading={isRetransfer
        ? 'Pension providers may have different lines of business'
        : !workingRetirementAssetData?.pensionProviderBrandName
          ? `Try searching for by company, brand or pension scheme names`
          : !workingRetirementAssetData?.pensionProviderBrandKey
            ? `Try to identify them from this full list by company, brand or pension scheme name`
            : fromSuggestions
              ? `Try searching for by company, brand or pension scheme names`
              : `Search for your current pension provider by name`
      }
      subHeadingInfo={<NamedInformationButton name={NamedInformation.PENSION_PROVIDER_CHANGES} buttonTitle={'About Pension Providers'} />}
      allowTextButton={!pensionProviderId}
      textButtonTitle={
        isRetransfer
          ? `I don't know the right one`
          : !workingRetirementAssetData?.pensionProviderBrandName
            ? `I still don't know which one`
            : !workingRetirementAssetData?.pensionProviderBrandKey
              ? `I don't know the right one`
              : fromSuggestions
                ? `I still don't know which one`
                : `I still don't know which one`
      }
      textButtonAction={() => setShowDialog(true)}
    >
      <ManagedAutoCompleteInput
        name={'pensionProviderId'}
        placeholder={'Tap to search...'}
        formObj={formObj}
        modalTitle={'Find Provider'}
        dataSet={providerOptions}
        required={true}
      />
      {
        selectedProvider ?
        <View style={{ paddingTop: Sizing.x20 }}>
          <Paragraph>{`If you are unsure, check any recent emails or documents, or contact the provider directly to confirm.`}</Paragraph>
          <ProviderInfoButton
            modalContent={<PensionProviderModalContent
              provider={selectedProvider}
              imageSource={providerImageSource}
              sections={[
                PensionProviderModalContentSection.CONTACT,
                PensionProviderModalContentSection.REFERENCE,
                PensionProviderModalContentSection.GENERAL,
              ]}
            />}
          >{`Contact Information`}</ProviderInfoButton>
        </View>
        :
        <>
          <Paragraph >{`If you are unsure, try selecting different providers and we'll show you any contact information we have. This is usually the quickest way to check your details.`}</Paragraph>
          <Paragraph >{`If you know your provider, but they're not on our list, please contact support`}</Paragraph>
          <Button
            mode={'text'}
            color={Colors.brand.purple2}
            onPress={contactSupport}
          >
            {'Contact Support'}
          </Button>
        </>
      }
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={tracingRouteAvailable ? 'We can find your pension for you!' : 'We need to know the exact provider'}
        illustrationFilename={'support.png'}
        content={tracingRouteAvailable
          ? <Text>
              {`The fastest way to get your ${isWorkplace ? 'workplace' : 'personal'} pension transferred is to find the details from any emails/documents, or by contacting your old employer.\n\nHowever, we understand this isn't always possible or easy, so our team of experts can help find your pension for you, based on a few simple questions.\n\n`}
              <RaindropInfo />
            </Text>
          : isRetransfer
            ? 'We can only reattempt the transfer if we know the current provider.\n\nIf you want to give up on the transfer, close this process and choose to Abandon Transfer from the main list.'
            : 'We can only transfer your pension if we know the current provider.\n\nIf you prefer, you can save here and come back later once you have the details.'
        }
        confirmLabel={tracingRouteAvailable ? `Use Finding Service` : isRetransfer ? `Close` : `Save Here`}
        cancelLabel={`Try again`}
        onConfirm={tracingRouteAvailable ? goToTracing : goToResult}
      />
    </ModalProcessScreen>
  )
}
