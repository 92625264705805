import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddPensionProviderVisible, showAdminAddPensionProviderVisible } from 'store/uxSlice'
import { AddPensionProvidersScreen } from './Screens/AddPensionProvidersScreen'

export const AddPensionProvidersModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddPensionProviderVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Pension Provider'
      onDismiss={() => dispatch(setShowAdminAddPensionProviderVisible(false))}
      screen={<AddPensionProvidersScreen />}
    />
  )
}

