import { MaterialCommunityIcons } from '@expo/vector-icons'
import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { unformatSortCode } from 'lib/clientHelpers'
import { ACCOUNT_NO_MASK, COMPANY_MASK, CORPORATION_TAX_REF_MASK, PAYE_TAX_REF_MASK, SORTCODE_MASK } from 'lib/constants'
import { isValidCompanyNumber } from 'lib/generalHelpers'
import { getAffliliateAutoCompleteOptions } from 'lib/referenceDataHelpers'
import { sortBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddGroupOrganizationMutation, useGetAffiliatesQuery, useGetBankConnectionsQuery, useGetCompanyQuery } from 'store/apiSlice'
import { PaymentMethod } from 'store/dto/account.dto'
import { Address, ContributionSource } from 'store/dto/base.dto'
import { BankDetails } from 'store/dto/client.dto'
import { GroupOrganizationMandateStatus } from 'store/dto/group-organization.dto'
import { useAppDispatch } from 'store/hooks'
import { setShowAdminAddGroupOrganizationVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

const DEFAULT_ADVISOR_NAME = 'Dave Dresner Barnes'
const DEFAULT_ADVISOR_EMAIL = 'ddb@digitalwealthsystems.co.uk'

export const AddGroupOrganizationsScreen = () => {
  const [addGroupOrganizationMutation, { data: addedGroupOrganization, isLoading: addGroupOrganizationIsLoading, error: addGroupOrganizationError }] = useAddGroupOrganizationMutation()

  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()
  const { data: affiliates, isLoading: affiliatesIsLoading, error: affiliatesError, refetch: refetchAffiliates } = useGetAffiliatesQuery()
  const affiliateOptions = getAffliliateAutoCompleteOptions(affiliates)
  const dispatch = useAppDispatch()

  const [companyNoToCheck, setCompanyNoToCheck] = useState(undefined)
  const [confirmed, setConfirmed] = useState<boolean>(undefined)

  const formObj = useForm<{
    name: string
    displayName: string
    logo: string
    companyNo: string
    payeReference: string
    taxReference: string
    registeredOfficeAddressBuilding: string
    registeredOfficeAddressStreet1: string
    registeredOfficeAddressTown: string
    registeredOfficeAddressPostCode: string
    tradingAddressBuilding: string
    tradingAddressStreet1: string
    tradingAddressTown: string
    tradingAddressPostCode: string
    advisorName: string
    advisorEmail: string
    primaryContactFirstName: string
    primaryContactSurname: string
    primaryContactEmail: string
    affiliateId?: string
    bankId?: string
    accountName?: string
    sortCode?: string
    accountNumber?: string
    generateApiKey: boolean
    apiDisabled: boolean
    financeUserFirstName: string
    financeUserSurname: string
    financeUserEmail: string
    mandateStatus?: GroupOrganizationMandateStatus
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      displayName: '',
      logo: '',
      companyNo: '',
      payeReference: '',
      taxReference: '',
      registeredOfficeAddressBuilding: '',
      registeredOfficeAddressStreet1: '',
      registeredOfficeAddressTown: '',
      registeredOfficeAddressPostCode: '',
      tradingAddressBuilding: '',
      tradingAddressStreet1: '',
      tradingAddressTown: '',
      tradingAddressPostCode: '',
      advisorName: DEFAULT_ADVISOR_NAME,
      advisorEmail: DEFAULT_ADVISOR_EMAIL,
      primaryContactFirstName: '',
      primaryContactSurname: '',
      primaryContactEmail: '',
      bankId: undefined,
      accountName: '',
      sortCode: '',
      accountNumber: '',
      generateApiKey: false,
      affiliateId: undefined,
      financeUserFirstName: '',
      financeUserSurname: '',
      financeUserEmail: '',
      mandateStatus: GroupOrganizationMandateStatus.NOT_REQUESTED,
    },
  })
  //Form refs for focussing
  const nameRef = useRef(null)
  const displayNameRef = useRef(null)
  const companyNoRef = useRef(null)
  const payeReferenceRef = useRef(null)
  const taxReferenceRef = useRef(null)
  const registeredOfficeAddressBuildingRef = useRef(null)
  const registeredOfficeAddressStreet1Ref = useRef(null)
  const registeredOfficeAddressTownRef = useRef(null)
  const registeredOfficeAddressPostCodeRef = useRef(null)
  const tradingAddressBuildingRef = useRef(null)
  const tradingAddressStreet1Ref = useRef(null)
  const tradingAddressTownRef = useRef(null)
  const tradingAddressPostCodeRef = useRef(null)
  const advisorNameRef = useRef(null)
  const advisorEmailRef = useRef(null)
  const primaryContactFirstNameRef = useRef(null)
  const primaryContactSurnameRef = useRef(null)
  const primaryContactEmailRef = useRef(null)
  const accountNameRef = useRef(null)
  const sortCodeRef = useRef(null)
  const accountNumberRef = useRef(null)
  const financeUserFirstNameRef = useRef(null)
  const financeUserSurnameRef = useRef(null)
  const financeUserEmailRef = useRef(null)

  const { handleSubmit, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true, shouldValidate: true }) 
    trigger()
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true, shouldValidate: true })
  }

  const logo = watch('logo')

  const onSubmit = async attributes => {
    const {
      registeredOfficeAddressBuilding,
      registeredOfficeAddressStreet1,
      registeredOfficeAddressTown,
      registeredOfficeAddressPostCode,
      tradingAddressBuilding,
      tradingAddressStreet1,
      tradingAddressTown,
      tradingAddressPostCode,
      accountName,
      sortCode,
      accountNumber,
      financeUserFirstName,
      financeUserSurname,
      financeUserEmail,
      ...remaining
    } = attributes

    const bankDetails: BankDetails = {
      accountNumber,
      sortCode: unformatSortCode(sortCode),
      accountName,
    }

    const registeredOfficeAddressDetail: Address = {
      building: registeredOfficeAddressBuilding,
      street1: registeredOfficeAddressStreet1,
      town: registeredOfficeAddressTown,
      postCode: registeredOfficeAddressPostCode,
      country: 'GB',
    }
    
    const tradingAddressDetail: Address = {
      building: tradingAddressBuilding,
      street1: tradingAddressStreet1,
      town: tradingAddressTown,
      postCode: tradingAddressPostCode,
      country: 'GB',
    }

    const financeUserAttributes = financeUserEmail ? {
      financeUserFirstName,
      financeUserSurname,
      financeUserEmail,
    } : {}

    addGroupOrganizationMutation({
      defaultContributionMethod: PaymentMethod.BANK_TRANSFER,
      registeredOfficeAddressDetail,
      tradingAddressDetail,
      bankDetails,
      ...financeUserAttributes,
      ...remaining,
    })
  }

  useEffect(() => {
    if (addedGroupOrganization) {
      close()
    }
  }, [addedGroupOrganization])

  const close = () => {
    dispatch(setShowAdminAddGroupOrganizationVisible(false))
  }

  const isLoading = banksIsLoading || affiliatesIsLoading  || addGroupOrganizationIsLoading
  const error: any = banksError || affiliatesError || addGroupOrganizationError

  const bankOptions: AutoCompleteItem[] = banks ? sortBy(banks.filter(bank => {
    return bank.contributionSources.includes(ContributionSource.EMPLOYER)
  }).map(bank => {
    return {
      value: bank.moneyHubId,
      label: bank.name,
      description: bank.description,
      icon: bank?.logo
        ? () => <Image source={{ uri: bank?.logo}} style={{
          width: Sizing.x40,
          height: Sizing.x40,
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        : () => <View style={{
            ...Flex.column.center,
            alignItems: 'center',
          }}>
            <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
        </View>
    }
  }), 'title') : []

  const generateApiKeyOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'No, not currently required',
    },
    {
      value: true,
      label: 'Yes, generate an API Key',
    },
  ]
  
  const apiDisabledOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Yes',
    },
    {
      value: true,
      label: 'No',
    },
  ]

  const mandateSetupOptions: ManagedSimpleChoiceItem[] = [
    {
      value: GroupOrganizationMandateStatus.NOT_REQUESTED,
      label: 'Decide Later',
    },
    {
      value: GroupOrganizationMandateStatus.REQUESTED,
      label: 'Requested',
    },
    {
      value: GroupOrganizationMandateStatus.NOT_REQUIRED,
      label: 'Not Required',
    },
  ]

  const generateApiKey = watch('generateApiKey')

  //Check the companyNo is valid
  const { data: companyNoCheckResult, error: companyNoCheckError, isLoading: companyNoCheckIsLoading, isFetching: companyNoCheckIsFetching, refetch: refetchCompanyNoCheck } = useGetCompanyQuery({
    id: companyNoToCheck,
  }, { skip: !companyNoToCheck})

  const companyNo = watch('companyNo')

  //Update companyNoToCheck when value changes
  useEffect(() => {  
    setConfirmed(undefined)
    const newValue = companyNo && companyNo.length === 8
      ? companyNo
      : undefined
    setCompanyNoToCheck(newValue)
  }, [companyNo])

  //Force referch when Company No to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (companyNoToCheck) {
      refetchCompanyNoCheck()
    }    
  }, [companyNoToCheck])

  //Update available status based on check result
  useEffect(() => {  
    if (companyNoCheckError) {
      setConfirmed(false)
    } else if (companyNoCheckIsLoading || companyNoCheckIsFetching) {
      setConfirmed(undefined)
    } else if (companyNoCheckResult) {
      setConfirmed(true)
      populateRegisteredAddress()
    }
  }, [companyNoCheckResult, companyNoCheckError, companyNoCheckIsLoading, companyNoCheckIsFetching])

  const populateRegisteredAddress = () => {
    if (watch('registeredOfficeAddressBuilding') === '') {
      setValue('registeredOfficeAddressBuilding', companyNoCheckResult?.addressDetail?.building, { shouldDirty: true, shouldValidate: true })
    }
    if (watch('registeredOfficeAddressStreet1') === '') {
      setValue('registeredOfficeAddressStreet1', companyNoCheckResult?.addressDetail?.street1, { shouldDirty: true, shouldValidate: true })
    }
    if (watch('registeredOfficeAddressTown') === '') {
      setValue('registeredOfficeAddressTown', companyNoCheckResult?.addressDetail?.town, { shouldDirty: true, shouldValidate: true })
    }
    if (watch('registeredOfficeAddressPostCode') === '') {
      setValue('registeredOfficeAddressPostCode', companyNoCheckResult?.addressDetail?.postCode, { shouldDirty: true, shouldValidate: true })
    }
  }

  //Trigger validation when available changes
  useEffect(() => {
    trigger('companyNo')
  }, [confirmed])

  const isConfirmedCompanyNo = () => {
    if (confirmed === false) {
      return 'Could not find company at Companies House'
    } else if (confirmed === undefined) {
      return 'Verifying...'
    }
    return true
  }

  const copyRegisteredAddressToTradingAddress = () => {
    setValue('tradingAddressBuilding', watch('registeredOfficeAddressBuilding'), { shouldDirty: true, shouldValidate: true }) 
    setValue('tradingAddressStreet1', watch('registeredOfficeAddressStreet1'), { shouldDirty: true, shouldValidate: true}) 
    setValue('tradingAddressTown', watch('registeredOfficeAddressTown'), { shouldDirty: true, shouldValidate: true})
    setValue('tradingAddressPostCode', watch('registeredOfficeAddressPostCode'), { shouldDirty: true, shouldValidate: true})
  }

  const financeUserEmail = watch('financeUserEmail')

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={banksError ? refetchBanks : affiliatesError ? refetchAffiliates : handleSubmit(onSubmit)}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={['Saving group organization...']}
      buttonTitle={'Create Group Organization'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <Subheading>{'Basic Details'}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Legal organization name'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
        }}/>
      <ManagedTextInput
        ref={displayNameRef}
        name={'displayName'}
        formObj={formObj}
        label={'Display Name'}
        placeholder={'Short Organization name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
        }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Subheading>{'Identification'}</Subheading>
      <Paragraph>{`Companies House Registration Number`}</Paragraph>
      <ManagedTextInput
        name={'companyNo'}
        ref={companyNoRef}
        formObj={formObj}
        returnKeyType={'next'}
        informationMessage={confirmed
          ? companyNoCheckResult?.name
          : `Valid 8-digit Companies House number`
        }
        mask={{
          type: 'custom',
          options: {
            mask: COMPANY_MASK,
          }
        }}
        placeholder={'Enter company number'}
        autoCapitalize={'characters'}
        submitHandler={() => payeReferenceRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: {
            value: 8,
            message: 'Must be 8 characters, include leading zeros'
          },
          maxLength: {
            value: 8,
            message: 'Must be at most 8 characters'
          },
          validate: {
            isValidCompanyNumber,
            isConfirmedCompanyNo,
          }
        }} />
      <ManagedTextInput
        ref={payeReferenceRef}
        name={'payeReference'}
        formObj={formObj}
        label={'PAYE Reference'}
        placeholder={'HMRC PAYE Reference in format 123/AB456'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        mask={{
          type: 'custom',
          options: {
            mask: PAYE_TAX_REF_MASK,
          }
        }}
        rules={{
          required: false,
          minLength: 9,
          maxLength: 14,
      }}/>
      <ManagedTextInput
        ref={taxReferenceRef}
        name={'taxReference'}
        formObj={formObj}
        label={'Corporation Tax Reference (UTR)'}
        placeholder={'HMRC Tax Reference'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        mask={{
          type: 'custom',
          options: {
            mask: CORPORATION_TAX_REF_MASK,
          }
        }}
        rules={{
          required: false,
          minLength: 10,
          maxLength: 10,
      }}/>
      <Paragraph>{'Registered Office Address'}</Paragraph>
      <ManagedTextInput
        ref={registeredOfficeAddressBuildingRef}
        name={`registeredOfficeAddressBuilding`}
        formObj={formObj}
        label={'Building Number/Name'}
        placeholder={'Enter building number/name'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => registeredOfficeAddressStreet1Ref.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={registeredOfficeAddressStreet1Ref}
        name={`registeredOfficeAddressStreet1`}
        formObj={formObj}
        label={'Street'}
        placeholder={'Enter street'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => registeredOfficeAddressTownRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={registeredOfficeAddressTownRef}
        name={`registeredOfficeAddressTown`}
        formObj={formObj}
        label={'Town'}
        placeholder={'Enter town/locality'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => registeredOfficeAddressPostCodeRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={registeredOfficeAddressPostCodeRef}
        name={`registeredOfficeAddressPostCode`}
        formObj={formObj}
        label={'Post Code'}
        placeholder={'Enter postcode'}
        autoCapitalize={'characters'}
        returnKeyType={'next'}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 10,
        }} />
      <Paragraph>{'Trading Address'}</Paragraph>
      <Button mode={'text'} onPress={copyRegisteredAddressToTradingAddress}>{'Use Registered Office Address'}</Button>
      <ManagedTextInput
        ref={tradingAddressBuildingRef}
        name={`tradingAddressBuilding`}
        formObj={formObj}
        label={'Building Number/Name'}
        placeholder={'Enter building number/name'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => tradingAddressStreet1Ref.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={tradingAddressStreet1Ref}
        name={`tradingAddressStreet1`}
        formObj={formObj}
        label={'Street'}
        placeholder={'Enter street'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => tradingAddressTownRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={tradingAddressTownRef}
        name={`tradingAddressTown`}
        formObj={formObj}
        label={'Town'}
        placeholder={'Enter town/locality'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => tradingAddressPostCodeRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={tradingAddressPostCodeRef}
        name={`tradingAddressPostCode`}
        formObj={formObj}
        label={'Post Code'}
        placeholder={'Enter postcode'}
        autoCapitalize={'characters'}
        returnKeyType={'next'}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 10,
        }} />
      <Subheading>{'Pension Advisor'}</Subheading>
      <ManagedTextInput
        ref={advisorNameRef}
        name={'advisorName'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name of advisor to the employer'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => advisorEmailRef.current?.focus()}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 40,
      }}/>
      <ManagedTextInput
        ref={advisorEmailRef}
        name={'advisorEmail'}
        keyboardType='email-address'
        formObj={formObj}
        label={'Email Address'}
        placeholder={'Their email address'}
        autoCapitalize={'none'}
        returnKeyType={'next'}
        submitHandler={() => primaryContactFirstNameRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid email address"
          },
          required: true,
          minLength: 2,
          maxLength: 200,
      }} />
      <Subheading>{'Primary Employer Contact'}</Subheading>
      <ManagedTextInput
        ref={primaryContactFirstNameRef}
        name={'primaryContactFirstName'}
        formObj={formObj}
        label={'First Name'}
        placeholder={'First name of primary contact for the employer'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => primaryContactSurnameRef.current?.focus()}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 40,
      }}/>
      <ManagedTextInput
        ref={primaryContactSurnameRef}
        name={'primaryContactSurname'}
        formObj={formObj}
        label={'Surname'}
        placeholder={'Surname of primary contact for the employer'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => primaryContactEmailRef.current?.focus()}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 40,
      }}/>
      <ManagedTextInput
        ref={primaryContactEmailRef}
        name={'primaryContactEmail'}
        keyboardType='email-address'
        formObj={formObj}
        label={'Email Address'}
        placeholder={'Their email address'}
        autoCapitalize={'none'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => financeUserFirstNameRef.current?.focus()}
        rules={{
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid email address"
          },
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      <Subheading>{'Finance Contact'}</Subheading>
      <ManagedTextInput
        ref={financeUserFirstNameRef}
        name={'financeUserFirstName'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name of finance contact for the employer'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => financeUserSurnameRef.current?.focus()}
        rules={{
          required: !!financeUserEmail,
          minLength: 3,
          maxLength: 40,
      }}/>
      <ManagedTextInput
        ref={financeUserSurnameRef}
        name={'financeUserSurname'}
        formObj={formObj}
        label={'Surname'}
        placeholder={'Surname of finance contact for the employer'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => financeUserEmailRef.current?.focus()}
        rules={{
          required: !!financeUserEmail,
          minLength: 3,
          maxLength: 40,
      }}/>
      <ManagedTextInput
        ref={financeUserEmailRef}
        name={'financeUserEmail'}
        keyboardType='email-address'
        formObj={formObj}
        label={'Email Address'}
        placeholder={'Their email address'}
        autoCapitalize={'none'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid email address"
          },
          minLength: 2,
          maxLength: 200,
      }} />
      <Subheading>{'Affiliation'}</Subheading>
      <ManagedAutoCompleteInput
        name={'affiliateId'}
        formObj={formObj}
        label={'Affiliate'}
        selectOnlyMode={true}
        required={false}
        dataSet={affiliateOptions}
      />
      <Subheading>{'Contribution Source'}</Subheading>
      <ManagedAutoCompleteInput
        name={'bankId'}
        formObj={formObj}
        label={'Bank'}
        modalTitle={'Find Bank'}
        dataSet={bankOptions}
        required={true}
      />
      <ManagedTextInput
        ref={accountNameRef}
        name={'accountName'}
        formObj={formObj}
        label={'Account Name'}
        placeholder={'Contribution Bank Account Name'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
        }}/>
      <Paragraph>{`Sort Code`}</Paragraph>
      <ManagedTextInput
        ref={sortCodeRef}
        name={'sortCode'}
        returnKeyType={"next"}
        keyboardType={'numeric'}
        formObj={formObj}
        mask={{
          type: 'custom',
          options: {
            mask: SORTCODE_MASK,
          }
        }}
        blurOnSubmit={false}
        submitHandler={() => accountNumberRef.current?.focus()}
        rules={{
          required: true,
          minLength: {
            value: 8,
            message: 'Must be 6 characters'
          },
          maxLength: {
            value: 8,
            message: 'Must be 6 characters'
          },

      }} />
      <Paragraph>{`Account Number`}</Paragraph>
      <ManagedTextInput
        ref={accountNumberRef}
        name={'accountNumber'}
        keyboardType={'numeric'}
        formObj={formObj}
        mask={{
          type: 'custom',
          options: {
            mask: ACCOUNT_NO_MASK,
          }
        }}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: {
            value: 8,
            message: 'Must be 8 characters'
          },
          maxLength: {
            value: 8,
            message: 'Must be 8 characters'
          },
      }} />
      <Paragraph>{'Direct Debit Mandate Setup?'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'mandateStatus'}
        formObj={formObj}
        options={mandateSetupOptions}
        required={true}
      />
      <Subheading>{'API Access'}</Subheading>
      <Paragraph>{'Provide the organization with API access to upload files?'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'generateApiKey'}
        formObj={formObj}
        options={generateApiKeyOptions}
        required={false}
      />
      <Paragraph>{'API Access Enabled?'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'apiDisabled'}
        formObj={formObj}
        options={apiDisabledOptions}
        required={generateApiKey}
        disabled={!generateApiKey}
      />
    </ModalProcessScreen>
  )
}

