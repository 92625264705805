import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useFocusEffect } from '@react-navigation/native'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { getScreenAppHeight, scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { getAssetCategory, getAssetIconName, sortAssets } from 'lib/retirementAssetHelpers'
import { sumBy } from 'lodash'
import React, { useCallback, useRef } from 'react'
import { ScrollView } from 'react-native'
import { Pressable, StyleSheet, View } from 'react-native'
import { useGetMeQuery, useGetRetirementAssetsQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementAssetDto, RetirementAssetTransferStatus } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { onAssetSpouseView, setOnAssetSpouseView } from 'store/uxSlice'
import { Colors, Paper, Flex, Sizing, Typography } from 'styles'

const Tab = createMaterialTopTabNavigator()

interface RetirementAssetsListProps {
  showAddSpouse?: boolean
  addAssetStateFunction: Function
  editAssetStateFunction: Function
  addSpouseStateFunction: Function
  navigatorHeight: number
  refreshFunctions: Function[]
}

export const RetirementAssetsList = (props: RetirementAssetsListProps) => {
  const { showAddSpouse, addAssetStateFunction, editAssetStateFunction, addSpouseStateFunction, navigatorHeight, refreshFunctions } = props

  const { data: spouse } = useGetSpouseQuery()

  const { colors: themeColors } = Paper.useAppTheme()

  const onSpouseTab = useAppSelector(onAssetSpouseView)
    
  type AssetListProps = {
    client: ClientBaseDto
    otherClient: ClientBaseDto
    assets: RetirementAssetDto[]
  }
  const AssetList = (props: AssetListProps) => {
    const { client, otherClient, assets } = props

    const clientAssets = assets.filter(asset => {
      return asset.clientId === client?.id
    })

    const otherclientAmount = sumBy(assets.filter(asset => {
      return asset.clientId !== client?.id
    }), 'currentValue')

    const overallAmount = sumBy(assets, 'currentValue')

    const scrollRef = useRef()

    return (
      <View style={localStyles.listContainer}>
        <RefreshableScrollView
          ref={scrollRef}
          refreshFunctions={refreshFunctions}
          showsVerticalScrollIndicator={true}
          keyboardShouldPersistTaps='always'
        >
        {
            clientAssets ? sortAssets(clientAssets).map((asset, idx) => {
              return (
                <RetirementAssetRow
                  key={idx}
                  asset={asset}
                  onPress={() => editAssetStateFunction(asset)}
                />                    
              )
            }) : <></>
          }
          { !client?.isSpouse || (client?.isSpouse && !client?.userId) ?
            <AddRow
              title={'Add a pension/asset'}
              onPress={() => addAssetStateFunction(client)}
            /> : <></>
          }
          { otherClient ?
            <OtherTotalRow
              otherClient={otherClient}
              amount={otherclientAmount}
            />
            : <></>
          }
          <OverallTotalRow
            amount={overallAmount}
          />
        </RefreshableScrollView>
      </View>
    )
  }

  type AddRowProps = {
    title: string
    onPress?: any
  }
  const AddRow = (props: AddRowProps) => {
    const { title, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    return (
      <View>
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={'plus-circle'} size={Sizing.x30} color={themeColors.primary}/>
          </View>
          <View>
            <Subheading>{title}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading> </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }

  type RetirementAssetRowProps = {
    asset: RetirementAssetDto
    onPress?: any
  }
  const RetirementAssetRow = (props: RetirementAssetRowProps) => {
    const { asset, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const assetCategory = getAssetCategory(asset.assetType)
    const iconName: any = getAssetIconName(asset.assetType, assetCategory)
    return (
      <View>
        <Pressable style={localStyles.itemContainer} onPress={asset?.id ? onPress : undefined}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{asset.name}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>{formatCurrencyAmount(asset.currentValue)}</Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }

  type OtherTotalRowProps = {
    otherClient: ClientBaseDto
    amount: number
  }
  const OtherTotalRow = (props: OtherTotalRowProps) => {
    const { amount, otherClient } = props
    return (
      <View>
        <View style={localStyles.itemContainer}>
          <View>
            <Subheading numberOfLines={1}>{`${otherClient?.firstName}'s Total`}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>{formatCurrencyAmount(amount)}</Subheading>
          </View>
        </View>
        <ContentDivider />
      </View>    
    )
  }

  type OverallTotalRowProps = {
    amount: number
  }
  const OverallTotalRow = (props: OverallTotalRowProps) => {
    const { amount } = props
    return (
      <View>
        <View style={localStyles.itemContainer}>
          <View>
            <Subheading style={{ ...Typography.fontWeight.bold }}>Overall</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading style={{ ...Typography.fontWeight.bold }}>{formatCurrencyAmount(amount)}</Subheading>
          </View>
        </View>
        <ContentDivider />
      </View>    
    )
  }

  const ClientScreen = () => {

    //Update UX state when focused
    const dispatch = useAppDispatch()
    useFocusEffect(
      useCallback(() => {
        dispatch(setOnAssetSpouseView(false))
      }, [])
    )
    
    const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
    const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
    const { data: assets, error: assetsError, isLoading: assetsIsLoading, refetch: refetchAssets } = useGetRetirementAssetsQuery()

    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchAssets()
    }
    const error: any = meError || assetsError
    const isLoading = meIsLoading || assetsIsLoading || spouseIsLoading

    const nonTransferredAssets = assets.filter(asset => {
      return asset.transferStatus !== RetirementAssetTransferStatus.COMPLETED
    })

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your assets...')} /> : <></>
            }
          </View>
          :
          <AssetList 
            client={me}
            otherClient={spouse}
            assets={nonTransferredAssets}
          />
        }
      </>
    )
  }

  const SpouseScreen = () => {

    //Update UX state when focused
    const dispatch = useAppDispatch()
    useFocusEffect(
      useCallback(() => {
        dispatch(setOnAssetSpouseView(true))
      }, [])
    )
    
    const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
    const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
    const { data: assets, error: assetsError, isLoading: assetsIsLoading, refetch: refetchAssets } = useGetRetirementAssetsQuery()

    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchAssets()
    }
    
    const error: any = meError || assetsError
    const isLoading = meIsLoading || assetsIsLoading || spouseIsLoading

    const nonTransferredAssets = assets.filter(asset => {
      return asset.transferStatus !== RetirementAssetTransferStatus.COMPLETED
    })

    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your assets...')} /> : <></>
            }
          </View>
          :
          spouse ?
          <AssetList 
            client={spouse}
            otherClient={me}
            assets={nonTransferredAssets}
          />
          :
          <View style={{...Flex.column.start}} >
            <View style={{
              marginTop: Sizing.x30,
              marginHorizontal: Sizing.x15,
            }}>
              <Paragraph style={{textAlign: 'center', paddingVertical: Sizing.x20}}>First, add your partner to start creating your shared retirement universe and see everything in one place</Paragraph>
              {
                showAddSpouse ?
                <Button mode='text' onPress={() => addSpouseStateFunction(true)} color={Colors.brand.red3}>Add Partner</Button>              
                : <></>
              }
            </View>
          </View>          

        }
      </>
    )
  }

  return (
    <View style={{ height: scaleNormalizer(navigatorHeight)}}>
      <Tab.Navigator
        initialRouteName={onSpouseTab ? 'Spouse' : 'Me'}
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        screenOptions={{
          tabBarActiveTintColor: themeColors.accent,
          tabBarInactiveTintColor: themeColors.primary,
          tabBarStyle: {
            backgroundColor: 'transparent',
          },
          tabBarIndicatorStyle: {
            backgroundColor: themeColors.accent,
          },
        }} 
      >
        <Tab.Screen name={'Me'} component={ClientScreen} options={{ title: 'You'}} />
        <Tab.Screen name={'Spouse'} component={SpouseScreen} options={{ title: spouse?.firstName || 'Partner' }} />
      </Tab.Navigator>
    </View>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20,
  },
  header: {
    ...Flex.row.between,
  },
  iconContainer: {
    paddingRight: Sizing.x5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: Sizing.x10,
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start,
  },
  amountContainer: {
    ...Flex.override.right,
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between,
  },
})