import { Paragraph } from 'components/Typography'
import { BudgetTable } from 'components/Utility/BudgetTable'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { differenceInYears, formatISO } from 'date-fns'
import { mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { concat, max, sumBy } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { useGetBudgetsQuery, useGetMeQuery, useGetRetirementIncomesQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { timelinePinned, setEditRetirementAgeVisible, setEditRetirementBudgetVisible } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'

import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { MainAppHeader } from 'components/Layout/MainAppHeader'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Text } from 'components/Typography/Text'
import { ActionCard } from 'components/Utility/ActionCard'
import { NamedInformation } from 'components/Utility/InformationButton'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { PieChartCategory, PieChartDataSet, PieChartWithLegend } from 'components/Utility/PieChartWithLegend'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { isNotFoundError } from 'lib/errorHelpers'
import { getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { RetirementIncomeCategory, getIncomeCategory } from 'lib/retirementIncomeHelpers'
import { ModelGoalIdentifier, OnboardingPhase, OnboardingStateStepIdentifier, useGuidanceContext } from 'providers'
import { ContributionSource } from 'store/dto/base.dto'
import { ClientAnyDto } from 'store/dto/client.dto'
import { DashboardHeroItem } from '../Components/DashboardHeroItem'
import { DashboardMeter } from '../Components/DashboardMeter'
import { DashboardSection } from '../Components/DashboardSection'
import { DashboardSectionSet } from '../Components/DashboardSectionSet'
import { DashboardSwipeableScreen } from '../Components/DashboardSwipeableScreen'
import { SuggestionCardSet } from '../Components/SuggestionCardSet'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'

// Some config
export const DashboardRetirement = ({ route, navigation }) => {
  const dispatch = useAppDispatch()

  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching, currentViableAgeError, refetchCurrentViableAge, currentModel, currentModelError, currentModelIsFetching, currentModelIsLoading, getOnboardingStateByPhase, getAvailableGoalActionCardProps } = useGuidanceContext()

  const timelineIsPinned = useAppSelector(timelinePinned)
  const scrollRef = useRef()
  useEffect(() => {
    if (timelineIsPinned && scrollRef?.current) {
      scrollRef?.current?.scrollTo({ y: 0 })
    }
  }, [timelineIsPinned])

  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching, refetch: refetchProfile } = useGetRetirementProfileQuery()
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError, isFetching: incomesIsFetching, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

  const isLoading = clientIsLoading || spouseIsLoading
  const error: any = clientError

  const needsProfileOnboarding = !retirementProfile
  const needsIncomesOnboarding = !client?.onboardingFlags?.incomes

  const targetRetirementDate = currentViableAge?.basis?.targetRetirementAge ? new Date(currentViableAge.basis.targetRetirementDate) : new Date()
  const modelRetirementDate = currentViableAge?.timeline?.modelRetirementDate ? new Date(currentViableAge.timeline.modelRetirementDate) : new Date()
  const viableRetirementAge = currentViableAge?.output?.viableRetirementAge || 0
  const targetRetirementAge = currentViableAge?.basis?.targetRetirementAge || 0
  const targetRetirementAgeIsMet = currentViableAge?.output?.targetRetirementAgeIsMet

  const bestContributionSource = client?.contributionConfiguration?.source || ContributionSource.PERSONAL
  const usePersonalContributions = bestContributionSource === ContributionSource.PERSONAL
  const requiredMonthlyContributions = currentModel
    ? usePersonalContributions
      ? currentModel?.output?.requiredPersonalMonthlyContributions
      : currentModel?.output?.requiredGrossMonthlyContributions
    : undefined

  //Retirement Budget
  const { data: budgets, error: budgetsError, isLoading: budgetsIsLoading, isFetching: budgetsIsFetching } = useGetBudgetsQuery({
    asCouple: retirementProfile?.asCouple,
    insideLondon: retirementProfile?.insideLondon,
  }, { skip: !retirementProfile })

  const calculateRetirementIncomeValue = () => {
    if (!incomes) {
      return 0
    }
    const totalOtherIncome = sumBy(incomes, 'annualIncomeAmount')
    return totalOtherIncome + client?.statePensionAmount + (spouse?.statePensionAmount || 0)
  }

  const getAssetValueAtRetirement = () => {
    if (!currentModel) {
      return 0
    }
    const viableRetirementAge = currentModel.output.viableRetirementAge
    const retirementTerm = currentModel.terms.find(term => {
      return term.age === viableRetirementAge
    })
    return retirementTerm ? retirementTerm.startValue : 0
  }

  const assetValueAtRetirement = getAssetValueAtRetirement()

  const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0

  const totalRetirementIncomeValue = calculateRetirementIncomeValue()
  const valueRequiredFromSavings = max([(retirementProfile?.expensesGrossTotalAmount || 0) - totalRetirementIncomeValue, 0])
  const surplusIncome = max([totalRetirementIncomeValue - (retirementProfile?.expensesGrossTotalAmount || 0), 0])

  const onboardinGContributeIsFinished = getOnboardingStateByPhase(OnboardingPhase.CONTRIBUTE)?.complete

  function AnnualBudgetSummaryContent() {
    return (
      <View style={{ paddingHorizontal: Sizing.x30 }}>
        <UnborderedTable
          data={concat([
            {
              label: 'Gross Lifestyle Cost',
              value: <Text style={{ color: Colors.brand.red3 }}>
                {retirementProfile ? formatCurrencyAmount(retirementProfile?.expensesGrossTotalAmount) : 'Set Budget'}
              </Text>,
              iconSource: <MaterialCommunityIcons name={'calculator'} size={Sizing.x20} color={Colors.neutral.white} />,
              isLoading: rpIsLoading,
              isFetching: rpIsFetching,
              linkFunction: retirementProfile
                ? undefined
                : () => rootNavigate('RetirementProfileSetupIntroScreen'),
            },
            {
              label: `Total Regular Income${totalRetirementIncomeValue ? ' *' : ''}`,
              value: <Text style={{ color: Colors.brand.red3 }}>
                {needsIncomesOnboarding ? 'Add Incomes' : formatCurrencyAmount(totalRetirementIncomeValue)}
              </Text>,
              iconSource: <MaterialCommunityIcons name={'cash-fast'} size={Sizing.x20} color={Colors.neutral.white} />,
              isLoading: incomesIsLoading,
              isFetching: incomesIsFetching,
              linkFunction: needsIncomesOnboarding
                ? () => rootNavigate('RetirementIncomeSetupIntroScreen')
                : () => mainAppNavigate(MainAppNavScreen.REGULAR_INCOMES),
            },
          ],
            surplusIncome === 0 ? [] : [
              {
                label: 'Disposable Income',
                isTotal: true,
                value: <Text style={{ color: Colors.brand.red3, fontFamily: 'LabGrotesque-Medium' }}>
                  {!retirementProfile || needsIncomesOnboarding ? '?' : formatCurrencyAmount(surplusIncome)}
                </Text>,
                iconSource: <MaterialCommunityIcons name={'party-popper'} size={Sizing.x20} color={Colors.neutral.white} />,
                isLoading: rpIsLoading || incomesIsLoading,
                isFetching: rpIsFetching || incomesIsFetching,
              }
            ],
            {
              label: 'Required Drawdown',
              isTotal: true,
              value: <Text style={{ color: Colors.brand.red3, fontFamily: 'LabGrotesque-Medium' }}>
                {!retirementProfile || needsIncomesOnboarding ? '?' : formatCurrencyAmount(valueRequiredFromSavings)}
              </Text>,
              iconSource: <MaterialIcons name={'trending-down'} size={Sizing.x20} color={Colors.neutral.white} />,
              isLoading: rpIsLoading || incomesIsLoading,
              isFetching: rpIsFetching || incomesIsFetching,
            }
          )}
        />
        {
          totalRetirementIncomeValue ?
            <Paragraph style={{ ...Typography.fontSize.x10, textAlign: 'right' }}>{`* When all incomes available`}</Paragraph>
            : <></>
        }
      </View>
    )
  }

  function RetirementBudgetContent() {

    const noSpouseAlert = !spouse && retirementProfile?.asCouple === true
    const spouseAlert = spouse && retirementProfile?.asCouple === false

    return (
      <OnboardingGuard
        explanation={'Jarvis can help you define your retirement lifestyle and budget as the basis for understanding how close you are to achieving it, and how to close the gap.'}
        size={'small'}
        onboardingSteps={[
          OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
        ]}
      >
        <View style={{ paddingHorizontal: Sizing.x30 }}>
          <Paragraph>{`You are currently retiring ${retirementProfile?.asCouple ? spouse ? `with ${spouse?.firstName}` : `with your partner` : `on your own`} ${retirementProfile?.insideLondon ? `in London` : `somewhere other than London`}`}</Paragraph>
          <BudgetTable
            isLoading={budgetsIsLoading || rpIsLoading}
            rows={budgets ? budgets.map(budget => {
              return {
                budget,
              }
            }) : []}
            expenses={retirementProfile?.expenses}
            netMonthlyTotal={(retirementProfile?.expensesNetTotalAmount || 0) / 12}
          />
          <Paragraph>{`This budget requires a gross annual income of ${formatCurrencyAmount(retirementProfile ? retirementProfile?.expensesGrossTotalAmount : 0)}`}</Paragraph>
          {
            spouseAlert || noSpouseAlert ?
              <>
                <View style={Flex.row.center}>
                  <MaterialCommunityIcons name={'alert-rhombus'} size={Sizing.x40} color={Colors.warning.s400} />
                  <Paragraph style={{
                    textAlign: 'left',
                    paddingTop: scaleNormalizer(13),
                    ...Typography.fontWeight.bold
                  }}>
                    {'Budget update recommended!'}
                  </Paragraph>
                </View>
                <Paragraph>
                  {
                    spouseAlert
                      ? `You previously defined your budget without a partner, but have since added one. We recommend updating your budget to reflect this change in circumstances.`
                      : `You previously defined your budget with a partner, but have since removed them. We recommend updating your budget to reflect this change in circumstances.`
                  }
                </Paragraph>
              </>
              : <></>
          }
          <Button mode={spouseAlert || noSpouseAlert ? 'contained' : 'text'} onPress={() => dispatch(setEditRetirementBudgetVisible(true))}>{`Update Your Budget`}</Button>
        </View>
      </OnboardingGuard>
    )
  }

  function RetirementTargetContent() {
    const difference = differenceInYears(modelRetirementDate, targetRetirementDate)
    const availableGoalActionCardProps = getAvailableGoalActionCardProps([
      ModelGoalIdentifier.ACHIEVE_TARGET_RETIREMENT_AGE,
      ModelGoalIdentifier.REDUCE_VIABLE_RETIREMENT_AGE,
      ModelGoalIdentifier.REDUCE_TARGET_RETIREMENT_AGE,
    ], 'small')

    const sliderWidth = getScreenAppWidth() - Sizing.x30

    const renderGoalCard = ({ item, index }) => {
      return (
        <View style={{
          paddingHorizontal: Sizing.x10,
        }}>
          <ActionCard
            {...item}
          />
        </View>
      )
    }

    return (
      <>
        <View>
          <View style={{ paddingHorizontal: Sizing.x30 }}>
            <Paragraph>
              {
                currentViableAge
                  ? targetRetirementAgeIsMet
                    ? `You should meet your target retirement age of ${targetRetirementAge}`
                    : `You are about ${difference} year${difference === 1 ? '' : 's'} off your target retirement age of ${targetRetirementAge}`
                  : retirementProfile
                    ? `You are aiming to retire at ${retirementProfile?.targetRetirementAge}`
                    : `Your state pension age is ${client?.statePensionAge}`
              }
            </Paragraph>
            <View style={{ paddingVertical: Sizing.x20 }}>
              <DashboardMeter
                viableAge={currentViableAge?.output?.viableRetirementAge || retirementProfile?.targetRetirementAge || client?.statePensionAge}
                currentAge={+clientAge}
                targetAge={+retirementProfile?.targetRetirementAge || client?.statePensionAge}
                expectedDeathAge={+client?.lastSurvivorDeathAge}
              />
            </View>
          </View>
        </View>

        <OnboardingGuard
          explanation={'Jarvis can help you set a target retirement age and who you how to achieve it - complete the steps to get personalised suggestions.'}
          size={'small'}
          onboardingSteps={[
            OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
            OnboardingStateStepIdentifier.INCOMES,
            OnboardingStateStepIdentifier.ASSETS,
          ]}
        >
          <View style={{ paddingHorizontal: Sizing.x30 }}>
            <View style={{ paddingBottom: Sizing.x10 }}>
              <Button mode='text' onPress={() => dispatch(setEditRetirementAgeVisible(true))}>{`Change Target Age`}</Button>
            </View>
            {
              targetRetirementAgeIsMet || !requiredMonthlyContributions ? <></> :
                <View style={{ paddingBottom: Sizing.x10 }}>
                  <Paragraph>{`Achieving your target age would need a ${usePersonalContributions ? 'personal monthly contribution' : 'monthly contribution from your limited company'} of ${formatCurrencyAmount(requiredMonthlyContributions)}.`}</Paragraph>
                </View>
            }
          </View>
          <OnboardingGuard
            explanation={`To see more options for how to achieve your target retirement age, open your ${JAR_NAME_PERSONAL} today!`}
            size={'small'}
            onboardingSteps={[
              OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
              OnboardingStateStepIdentifier.PLAN_CHOICE,
            ]}
          >
            {
              currentViableAge && availableGoalActionCardProps?.length ?
              <View style={{ paddingHorizontal: Sizing.x15 }}>
                <ManagedCarousel
                  loop={false}
                  width={sliderWidth}
                  height={Sizing.x140}
                  data={availableGoalActionCardProps}
                  renderItem={renderGoalCard}
                  isLoading={currentModelIsLoading}
                  isFetching={currentModelIsFetching}
                />
              </View>
              : <></>
            }
          </OnboardingGuard>
        </OnboardingGuard>
      </>
    )
  }

  function IncomeBreakdownContent() {
    //Revised version that keeps single item per category
    const buildIncomePieChartData = (clients: ClientAnyDto[], incomes: RetirementIncomeDto[]): PieChartDataSet => {
      //Pie chart categories
      let dbTotal: number = 0
      let personalTotal: number = 0
      let otherTotal: number = 0

      //Segregate other incomes
      incomes.forEach(income => {
        const category = getIncomeCategory(income.incomeType)
        if (category === RetirementIncomeCategory.GUARANTEED_INCOME) {
          dbTotal += income.annualIncomeAmount
        } else if (category === RetirementIncomeCategory.PERSONAL) {
          personalTotal += income.annualIncomeAmount
        } else {
          otherTotal += income.annualIncomeAmount
        }
      })

      return {
        categories: [
          {
            name: 'State Pensions', items: [{
              x: '',
              y: sumBy(clients, 'statePensionAmount'),
            }]
          },
          {
            name: 'Defined Benefit Pensions', items: [{
              x: '',
              y: dbTotal,
            }]
          },
          {
            name: 'Personal/Employment', items: [{
              x: '',
              y: personalTotal,
            }]
          },
          {
            name: 'Other Income', items: [{
              x: '',
              y: otherTotal,
            }]
          },
        ]
      }
    }

    const noClient: ClientAnyDto[] = []
    const allClients = concat(client ? [client] : noClient, spouse ? [spouse] : noClient)
    const dataSet: PieChartDataSet = buildIncomePieChartData(allClients, incomes)

    const incomeTotal = sumBy(dataSet.categories, function (category: PieChartCategory) {
      return sumBy(category.items, 'y')
    })

    const drawdownRequired = retirementProfile ? max([retirementProfile?.expensesGrossTotalAmount - incomeTotal, 0]) : 0
    dataSet.categories.push({
      name: 'Required Pension Drawdown', items: [
        {
          x: '',
          y: drawdownRequired,
        }
      ]
    })

    return (
      <OnboardingGuard
        explanation={'Any regular incomes you have in retirement immediately help reduce the amount you need to save today - setup you retirement budget and record your incomes to understand how much of your future lifestyle needs funding from pension savings.'}
        size={'small'}
        onboardingSteps={[
          OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
          OnboardingStateStepIdentifier.INCOMES,
        ]}
      >
        <View style={{ paddingHorizontal: Sizing.x30 }}>
          <Paragraph>{`Annual breakdown when all retirement incomes are available`}</Paragraph>
        </View>
        <PieChartWithLegend dataSet={dataSet} />
        <View style={{ paddingHorizontal: Sizing.x30 }}>
          <Button mode='text' onPress={() => mainAppNavigate(MainAppNavScreen.REGULAR_INCOMES)}>{`Manage Retirement Incomes`}</Button>
        </View>
      </OnboardingGuard>
    )
  }

  const swipeableRef = useRef(undefined)

  return (
    <>
      <MainAppHeader
        sectionTitle={'Retirement'}
        sectionTitleInfo={NamedInformation.SECTION_DASHBOARD_RETIREMENT}
      />
      <DashboardSwipeableScreen
        leftNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_TODAY })}
        rightNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_LEGACY })}
        leftNavigationLabel={'TODAY'}
        rightNavigationLabel={'LEGACY'}
      >
        {
          isLoading ? <Loading useHolidayGraphics={true} /> : error ? <ErrorScreen error={error?.data} noDashboardButton={true} /> :
            <RefreshableScrollView
              ref={scrollRef}
              refreshFunctions={[
                refetchProfile,
              ]}
            >
              <View style={layoutStyles.dashboardMainContent}>
                <DashboardHeroItem
                  error={clientError || currentViableAgeError}
                  errorTryAgain={refetchCurrentViableAge}
                  isLoading={clientIsLoading || rpIsLoading || currentViableAgeIsLoading}
                  isFetching={clientIsFetching || rpIsFetching || currentViableAgeIsFetching}
                  title={currentViableAge ? `Achievable Age` : retirementProfile ? `Target Age` : `Pension Age`}
                  footer={currentModel ? formatCurrencyAmount(assetValueAtRetirement) : ''}
                  heroText={currentViableAge ? viableRetirementAge.toString() : retirementProfile ? retirementProfile.targetRetirementAge.toString() : client?.statePensionAge.toString()}
                  leftNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_TODAY })}
                  rightNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_LEGACY })}
                />
                <SuggestionCardSet screen={MainAppNavScreen.DASHBOARD_RETIREMENT} />
              </View>

              {
                //First Dashboard section for financials
                <ThemeProvider theme={Paper.lightThemeOnDarkerPurple}>
                  <DashboardSection
                    visible={true}
                    isLoading={rpIsLoading || incomesIsLoading}
                    isFetching={rpIsFetching || incomesIsFetching}
                    title={`Annual Budget Summary`}
                    titleInfoName={NamedInformation.DASHBOARD_RETIREMENT_ANNUAL_BUDGET_SUMMARY}
                    content={<AnnualBudgetSummaryContent />}
                    previousColor={Colors.brand.purple0}
                  />
                </ThemeProvider>
              }

              <DashboardSectionSet
                sections={[
                  {
                    title: `Retirement Target`,
                    titleInfoName: NamedInformation.DASHBOARD_RETIREMENT_TARGET,
                    visible: true,
                    isLoading: rpIsLoading || currentModelIsLoading || currentViableAgeIsLoading,
                    isFetching: rpIsFetching || currentModelIsFetching || currentViableAgeIsFetching,
                    error: rpError && !isNotFoundError(rpError),
                    refreshFunction: refetchProfile,
                    content: <RetirementTargetContent />
                  },
                  {
                    title: `Monthly Budget`,
                    titleInfoName: NamedInformation.DASHBOARD_RETIREMENT_BUDGET,
                    visible: true,
                    isLoading: rpIsLoading || budgetsIsLoading || spouseIsLoading,
                    isFetching: rpIsFetching || budgetsIsFetching || spouseIsFetching,
                    error: (rpError && !isNotFoundError(rpError)) || budgetsError,
                    refreshFunction: refetchProfile,
                    content: <RetirementBudgetContent />
                  },
                  {
                    title: `Income Breakdown`,
                    titleInfoName: NamedInformation.DASHBOARD_RETIREMENT_INCOME_BREAKDOWN,
                    visible: true,
                    isLoading: rpIsLoading || incomesIsLoading || clientIsLoading || spouseIsLoading,
                    isFetching: rpIsFetching || incomesIsFetching || clientIsFetching || spouseIsFetching,
                    error: (rpError && !isNotFoundError(rpError)) || incomesError || clientError,
                    refreshFunction: refetchIncomes,
                    content: <IncomeBreakdownContent />
                  },
                ]}
              />
            </RefreshableScrollView>
        }
      </DashboardSwipeableScreen>
    </>
  )
}

const localStyles = StyleSheet.create({
  swipeActionContainer: {
    width: Sizing.x80,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})