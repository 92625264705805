import { createNavigationContainerRef, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { FunctionalAreaHeader } from 'components/Layout/FunctionalAreaHeader'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { Portal, ThemeProvider } from 'react-native-paper'
import { Colors, Paper } from 'styles'

const Stack = createNativeStackNavigator()

export type FunctionalAreaStackBuilderProps = {
  navigatorId: string
  title: string
  subTitle?: string
  initialRouteName?: string
  initialParams?: any
  screens: FunctionalAreaStackBuilderScreen[]
  presentation?: "modal" | "transparentModal" | "containedModal" | "containedTransparentModal" | "fullScreenModal" | "formSheet" | "card"
  exitRoute: string
}

export type FunctionalAreaStackBuilderScreen = {
  navigationId: string
  component: React.ComponentType<any>
}

export const FunctionalAreaStackBuilder = (props: FunctionalAreaStackBuilderProps) => {
  const { navigatorId, title, subTitle, screens, exitRoute, initialRouteName, initialParams, presentation } = props
  let stackScreens: Stack.Screen[] = []

  const baseInitialParams = {
    exitRoute,
  }

  //Build stackScreens from process screens
  stackScreens = screens.map((screen, idx) => {
    return <Stack.Screen
      key={idx}
      name={screen.navigationId}
      component={screen.component}
      initialParams={{
        ...baseInitialParams,
        ...initialParams,
      }}
      options={{
        headerShown: false,
      }}
    />
  })

  const processNavigationRef = createNavigationContainerRef() as any

  const isWeb = platformIsWeb()

  return (
    <ThemeProvider theme={isWeb ? Paper.darkThemeOnLightGrey : Paper.lightTheme}>
      <Portal.Host>
      <FunctionalAreaHeader
        sectionTitle={title}
        sectionSubTitle={subTitle}
        navigationRef={processNavigationRef}
        exitRoute={exitRoute}
        routeParams={{
          ...baseInitialParams,
          ...initialParams,
        }}
      />
      <NavigationContainer
        documentTitle={{
          enabled: false,
        }}
        ref={processNavigationRef}
        independent={true}
      >
        <Stack.Navigator
          id={navigatorId}
          screenOptions={{
            animation: 'slide_from_right',
            presentation: presentation || 'card',
            headerShadowVisible: false,
            headerStyle: {          
              backgroundColor: Colors.neutral.white,
            },
          }}
          initialRouteName={initialRouteName}
        >
          <Stack.Group>
            { stackScreens.map((screen, idx) => { return screen })
            }
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
      </Portal.Host>
    </ThemeProvider>
  )
}
