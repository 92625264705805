import { Logger } from 'lib/logger'
import { isLockedForTransfer } from 'lib/retirementAssetHelpers'
import { pick } from 'lodash'
import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { ampli } from 'src/ampli'
import { useDeleteRetirementAssetMutation, useGetRetirementAssetQuery, useGetSpouseQuery } from 'store/apiSlice'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch } from 'store/hooks'
import { setEditRetirementAsset } from 'store/uxSlice'
import { RetirementAssetsEditAssetScreen } from './Screens/RetirementAssetsEditAssetScreen'

export type RetirementAssetsEditAssetModalProps = {
  asset: RetirementAssetDto
  onDismiss?: any
}

export const RetirementAssetsEditAssetModal = (props: RetirementAssetsEditAssetModalProps) => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditRetirementAsset(undefined))
  
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  const [ deleteAsset ] = useDeleteRetirementAssetMutation()

  //Get full asset including transfer
  const { data: asset, isLoading: assetIsLoading, error: assetError, isFetching: assetIsFetching } = useGetRetirementAssetQuery(props.asset.id)
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()

  //Disabled if spouse with a real user
  const isSpouse = asset?.clientId === spouse?.id
  const spouseControlled = isSpouse && !!spouse?.userId

  const handleDelete = () => {
    Logger.info(`Deleting asset...`)
    ampli.retirementAssetDelete({
      ...pick(asset, [
        'assetType',
        'isTransferable',
        'pensionProviderId',
        'currentValue',
        'monthlyContributionAmount',
        'contributionSource',
        'employerId',
        'employerName',
        'employerIsPublicSector',
        'pensionProviderBrandKey',
        'pensionProviderBrandName',
      ]),
      isSpouse,
      pensionProviderBrandName: asset?.pensionProviderBrandName,
    })
    deleteAsset(asset.id)
    onDismiss()
  }  

  const isLoading = spouseIsLoading || assetIsLoading
  const error: any =  assetError

  const isTransferLocked = isLockedForTransfer(asset)

  return (
    <ModalFormBuilder
      isLoading={isLoading}
      error={error}
      visible={!!asset}
      formTitle='Update Asset'
      onDismiss={onDismiss}
      onDelete={spouseControlled || isTransferLocked ? undefined : handleDelete}
      deleteDialogText='Really delete this asset?'
      screen={<RetirementAssetsEditAssetScreen
        asset={asset}
        onDismiss={onDismiss}
        spouseControlled={spouseControlled}
        spouse={spouse}
      />}
    />
  )
}
