import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useGetStatsFinancialsQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { Sizing, Paper, Flex } from 'styles'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminFinancialsScreen = () => {
  const { data: financialStats, isLoading: financialStatsIsLoading, error: financialStatsError, isFetching: financialStatsIsFetching, refetch: refetchFinancialStats } = useGetStatsFinancialsQuery()
  const [refreshing, setRefreshing] = useState(false)

  const isLoading = financialStatsIsLoading
  const error = financialStatsError
  const { colors: themeColors } = Paper.useAppTheme()

  const onRefresh = async () => {
    setRefreshing(true)
    await refetchFinancialStats()
    setRefreshing(false)
  }

  const statsList = () => {
    if (financialStats) {
      return [
        { title: 'Financial Stats', data: [{ tableData: financialStats }] },
      ]
    }
    return []
  }

  const renderItem = ({ item }) => {
    let total = 0
    const data: UnborderedTableRow[] = []
    if (item?.tableData) {
      item.tableData.forEach(row => {
        total += row.count
        data.push({
          label: `${row.label} (${row.totalCount})`,
          value: formatCurrencyAmount(row.totalValue),
        })
      })
    }
    return (
      <View style={{ paddingBottom: Sizing.x30 }}>
        <UnborderedTable
          data={data}
        />
      </View>
    )
  }

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:financials']]}
      >
        {
          isLoading ? <Loading /> : error ? <ErrorScreen errorTryAgain={onRefresh} error={error?.data} /> :
            <View style={ Flex.column.start } >
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={isWeb}
              >
                <OptionalSectionList
                  sections={statsList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={``}
                />
              </ScrollView>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
