import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetBankConnectionQuery, useUpdateBankConnectionMutation } from 'store/apiSlice'
import { MoneyHubBankConnectionPaymentType, UpdateBankConnectionDto } from 'store/dto/bank-connection.dto'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminBankConnectionDataId, setCurrentAdminBankConnectionDataId } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AdminEditBankConnectionScreen = () => {
  const currentBankConnectionId = useAppSelector(currentAdminBankConnectionDataId)

  const { data: bank, isLoading: bankIsLoading, error: bankError } = useGetBankConnectionQuery(currentBankConnectionId, { skip: !currentBankConnectionId } )
  const [updateBankConnection, { data: updatedBankConnection, isLoading: bankUpdateIsLoading, error: bankUpdateError }] = useUpdateBankConnectionMutation()
  const dispatch = useAppDispatch()

  const formObj = useForm<UpdateBankConnectionDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      moneyHubName: bank?.moneyHubName,
      moneyHubBankRef: bank?.moneyHubBankRef,
      name: bank?.name,
      description: bank?.description,
      logo: bank?.logo,
      contributionSources: bank?.contributionSources || [],
      paymentTypes: bank?.paymentTypes || [],
      isBeta: bank?.isBeta,
    }
  })
  const { handleSubmit, register, setValue, setError, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger()
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')

  useEffect(() => {
    if (bank) {
      setValue('moneyHubName', bank?.moneyHubName)
      setValue('moneyHubBankRef', bank?.moneyHubBankRef)
      setValue('name', bank?.name)
      setValue('description', bank?.description)
      setValue('logo', bank?.logo)
      setValue('contributionSources', bank?.contributionSources || [])
      setValue('paymentTypes', bank?.paymentTypes || [])
      setValue('isBeta', bank?.isBeta)
    }
  }, [bank])

  //Form refs for focussing
  const moneyHubNameRef = useRef(null)
  const moneyHubBankRefRef = useRef(null)
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)

  const onSubmit = async attributes => {
    await updateBankConnection({ id: bank.id, ...attributes })
  }

  useEffect(() => {
    if (updatedBankConnection) {
      close()
    }
  }, [updatedBankConnection])

  const close = () => {
    dispatch(setCurrentAdminBankConnectionDataId(undefined))
  }

  const isLoading = bankIsLoading ||  bankUpdateIsLoading
  const error: any = bankError || bankUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const isBetaOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'No',
    },
    {
      value: true,
      label: 'Yes',
    },
  ]

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={bankUpdateIsLoading ? ['Saving bank...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: bank?.id,
            copyableValue: true,
          },
          {
            label: `MoneyHub Identifier`,
            value: bank?.moneyHubId,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => descriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={descriptionRef}
        name={'description'}
        formObj={formObj}
        label={'Description'}
        placeholder={'Optional description shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => moneyHubNameRef.current?.focus()}
        rules={{
          required: false,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={moneyHubNameRef}
        name={'moneyHubName'}
        formObj={formObj}
        label={'Money Hub Name (internal)'}
        placeholder={'Name as known by Money Hub'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => moneyHubBankRefRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={moneyHubBankRefRef}
        name={'moneyHubBankRef'}
        formObj={formObj}
        label={'Money Hub Bank Reference (internal)'}
        placeholder={'Bank reference as known by Money Hub'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Paragraph>{'Supported Payment Types'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'paymentTypes'}
        options={enumToAutocompleteOptions(MoneyHubBankConnectionPaymentType)}
      />
      <Paragraph>{'Supported Contribution Sources'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'contributionSources'}
        options={enumToAutocompleteOptions(ContributionSource)}
      />
      <Subheading>{'Beta Status (not visible to clients)'}</Subheading>
      <ManagedSimpleChoiceInput
        name={'isBeta'}
        formObj={formObj}
        options={isBetaOptions}
        required={true}
      />
    </ModalProcessScreen>
  )
}

