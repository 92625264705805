import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { ExternalLinkText } from 'components/Utility/ExternalLinkText'
import { InformationButton } from 'components/Utility/InformationButton'
import { Loading } from 'components/Utility/Loading'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { SchemeEnrolmentChangeForecastTreatmentProcessStack } from 'features/ModalProcesses/SchemeEnrolmentChangeForecastTreatment/SchemeEnrolmentChangeForecastTreatmentProcessStack'
import { SchemeEnrolmentChangeStatusRequestAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentChangeStatusRequestAdd/SchemeEnrolmentChangeStatusRequestAddProcessStack'
import { SchemeEnrolmentSetContributionRequestAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentSetContributionRequestAdd/SchemeEnrolmentSetContributionRequestAddProcessStack'
import { JAR_NAME_ALL, JAR_NAME_GROUP, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatUkDate } from 'lib/dateHelpers'
import { deriveAvailableStatusChangeRequestType, deriveExtendedEnrolmentStatus, isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import { round } from 'lodash'
import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useAddGroupSchemeEnrolmentRequestMutation, useGetGroupSchemeEnrolmentQuery, useGetGroupSchemeEnrolmentRequestsQuery, useGetUserContributionsQuery } from 'store/apiSlice'
import { AccountTransactionStatus, GroupSchemeEnrolmentContributionForecastTreatment, GroupSchemeEnrolmentStatus, GroupSchemeExtendedEnrolmentStatus, GroupSchemeRequestStatus, GroupSchemeRequestType, TransactionSubType } from 'store/dto/account.dto'
import { GroupSchemeEnrolmentPayrollFrequency } from 'store/dto/group-scheme.dto'
import { Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { platformIsWeb } from 'lib/platformHelpers'
import { AppIllustration } from 'components/Utility/AppIllustration'

const isWeb = platformIsWeb()

const actionableExtendedEnrolmentStatuses: GroupSchemeExtendedEnrolmentStatus[] = [
  GroupSchemeExtendedEnrolmentStatus.ACTIVE,
  GroupSchemeExtendedEnrolmentStatus.OPTED_OUT,
  GroupSchemeExtendedEnrolmentStatus.INACTIVE,
]

export const Enrolments_01_Main = ({ route, navigation }) => {
  const { enrolmentId }: { enrolmentId: string } = route?.params || {}

  const [changeForecastTreatment, setChangeForecastTreatment] = useState(false)
  const [showContributionRequest, setShowContributionRequest] = useState(false)
  const [showChangeStatusRequest, setShowChangeStatusRequest] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const { data: enrolment, error: enrolmentError, isLoading: enrolmentIsLoading, refetch: refetchEnrolment } = useGetGroupSchemeEnrolmentQuery(enrolmentId)
  const { data: requests, error: requestsError, isLoading: requestsIsLoading, refetch: refetchRequests } = useGetGroupSchemeEnrolmentRequestsQuery(enrolmentId)
  const { data: contributions, error: contributionsError, isLoading: contributionsIsLoading, refetch: refetchContributions } = useGetUserContributionsQuery({
    companyRegNo: enrolment?.groupScheme?.companyNo,
  }, { skip: !enrolment?.groupScheme?.companyNo })
  const [ addRequest, { data: addedRequest, isLoading: addRequestIsLoading, error: addRequestError} ] = useAddGroupSchemeEnrolmentRequestMutation()

  const {
    groupScheme,
    enrolmentStatus,
    employmentStartDate,
    enrolmentDate,
    enrolmentEndDate,
    contributionForecastTreatment,
    customEmployerContributionAmount,
    customPayrollFrequency,
  } = enrolment || {}

  const { logo, organizationName, schemeName, companyNo, primaryContactUser, defaultContributionFrequency, enabledRequestTypes } = groupScheme || {}
  const { firstName, surname, email } = primaryContactUser || {}

  const withinOptOutWindow = isWithinOptOutWindow(enrolment)

  const isLoading = enrolmentIsLoading || requestsIsLoading || contributionsIsLoading || addRequestIsLoading
  const error: any = enrolmentError || requestsError || contributionsError || addRequestError

  const refetchAll = () => {
    refetchEnrolment()
    refetchRequests()
    refetchContributions()
  }

  const pendingRequestTypes = requests ? requests.filter(request => {
    return request.status === GroupSchemeRequestStatus.PENDING
  }).map(request => {
    return request.requestType
  }) : []

  const extendedEnrolmentStatus = requests && enrolment ? deriveExtendedEnrolmentStatus(enrolment, pendingRequestTypes) : undefined

  const mostRecentEmployerContribution = contributions ? contributions.find(contribution => {
    return (
      contribution.transactionSubType === TransactionSubType.EMPLOYER
    )
  }) : undefined

  const mostRecentEmployeeContribution = mostRecentEmployerContribution ? contributions.find(contribution => {
    return (
      contribution.linkId === mostRecentEmployerContribution.linkId &&
      contribution.transactionSubType === TransactionSubType.EMPLOYEE
    )
  }) : undefined

  let contributionTableData: UnborderedTableRow[] = []

  const employerAmount = mostRecentEmployerContribution?.amount
  const employeeAmount = mostRecentEmployeeContribution?.amount || 0
  const taxReclaimAmount = employeeAmount ? round(mostRecentEmployeeContribution?.amount * PERSONAL_TAX_RELIEF_FACTOR) : 0
  const totalAmount = employerAmount + employeeAmount + taxReclaimAmount
  const subLabel = mostRecentEmployerContribution
    ? mostRecentEmployerContribution.status === AccountTransactionStatus.PENDING
      ? 'Expected Soon'
      : 'Received'
    : 'None made yet'
  contributionTableData.push({
    label: `Employer (Gross)`,
    value: employerAmount ? formatCurrencyAmount(employerAmount, 2) : '---',
    subLabel,
  })
  contributionTableData.push({
    label: `Employee (Net)`,
    value: employeeAmount ? formatCurrencyAmount(employeeAmount, 2) : '---',
    subLabel,
  })
  if (mostRecentEmployeeContribution) {
    contributionTableData.push({
      label: `Expected Tax Reclaim`,
      value: formatCurrencyAmount(taxReclaimAmount, 2),
      subLabel: `Based on ${formatPercentageAmount(PERSONAL_TAX_RELIEF_FACTOR * 100)} of Employee (Net)`
    })
  }
  contributionTableData.push({
    label: `Total Payroll Contribution`,
    subLabel: mostRecentEmployerContribution
      ? mostRecentEmployerContribution.status === AccountTransactionStatus.PENDING
        ? `Recorded: ${format(new Date(mostRecentEmployerContribution.transactionDate), 'do MMMM yyyy')}`
        : mostRecentEmployerContribution.completedDate ? `Received: ${format(new Date(mostRecentEmployerContribution.completedDate), 'do MMMM yyyy')}` : ''
      : subLabel,
    value: employeeAmount ? formatCurrencyAmount(totalAmount, 2) : '---',
    isTotal: true,
  })

  const getEachPhrase = (frequency: GroupSchemeEnrolmentPayrollFrequency): string => {
    return frequency === GroupSchemeEnrolmentPayrollFrequency.ANNUALLY ? ' each year'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.BI_ANNUALLY ? ' every 6 months'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.QUARTERLY ? ' each quarter'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.MONTHLY ? ' each month'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.FOUR_WEEKLY ? ' every 4 weeks'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.FORTNIGHTLY ? ' each fortnight'
      : frequency === GroupSchemeEnrolmentPayrollFrequency.WEEKLY ? ' each week'
      : ''
  }

  const requestType = deriveAvailableStatusChangeRequestType(enrolment, pendingRequestTypes)

  const informationTexts =
    contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
      ? [
          `With your income configured as 'Ad-hoc', Jarvis will use the following approach when forecasting:`,
          `Pension contributions from this employer will be treated as ad-hoc.`,
          `Forcasting will not expect any future regular pension contributions from this employer.`,
          `Contributions will still increase the current balance of your ${JAR_NAME_GROUP}, which will be used to model investment growth`,

        ]
      : contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
      ? [
          `With your income configured as 'Regular', Jarvis will use the following approach when forecasting:`,
          `Pension contributions from this employer will be treated as regular contributions until you retire`,
          `Forecasting will use the last contribution amount received from this employer as the regular amount being contributed ${getEachPhrase(defaultContributionFrequency)} for this employment.`,
          `If you leave the pension scheme in the future, pension contributions will be assumed to have ceased.`,
      ]
      : [
          `With your custom configuration, Jarvis will use the following approach when forecasting:`,
          `Pension contributions from this employer will be treated as regular contributions until you retire`,
          `Forecasting will be use the contribution amount and frequency you have set to model future contributions.`,
          `If you leave the pension scheme in the future, pension contributions will be assumed to have ceased.`,
        ]
    
  return (
    <>
      {
        changeForecastTreatment ?
          <SchemeEnrolmentChangeForecastTreatmentProcessStack
            onDismiss={() => setChangeForecastTreatment(false)}
            enrolment={enrolment}
          />
        : showContributionRequest ?
        <SchemeEnrolmentSetContributionRequestAddProcessStack
          onDismiss={() => setShowContributionRequest(false)}
          enrolment={enrolment}
        />
        : showChangeStatusRequest ?
        <SchemeEnrolmentChangeStatusRequestAddProcessStack
          onDismiss={() => setShowChangeStatusRequest(false)}
          enrolment={enrolment}
          requestType={requestType}
        />
        :
          <MainAppScreen>
            {
              isLoading
              ? <Loading message={addRequestIsLoading ? ['Submitting your request...'] : undefined} />
              : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
              <View style={[
                layoutStyles.mainContentContainer,
                localStyles.pageContainer,
                ]} >
                <RefreshableScrollView
                  contentContainerStyle={layoutStyles.scrollContainerContent}
                  showsVerticalScrollIndicator={isWeb}
                  refreshFunctions={[
                    refetchAll,
                  ]}
                >
                  <View style={{ paddingVertical: Sizing.x20 }}>
                    <Image source={{ uri: logo }} style={{
                      width: scaleNormalizer(150),
                      height: scaleNormalizer(150),
                      resizeMode: 'contain',
                      alignSelf: 'center',
                    }} />
                    {/* <Subheading>{organizationName}</Subheading> */}
                  </View>

                  <ContentDivider />
                  <Subheading style={Typography.defined.sectionListHeader}>{'Scheme Membership'} </Subheading>
                  <View style={{ paddingVertical: Sizing.x20 }}>
                    <Headline style={{ color: themeColors.accent }}>{extendedEnrolmentStatus ? extendedEnrolmentStatus : ''}</Headline>
                    <BulletItem style={{ textAlign: 'left' }}>{`Scheme Name: ${schemeName}`}</BulletItem>
                    {
                      primaryContactUser
                        ? <>
                            <BulletItem style={{ textAlign: 'left' }}>{`Contact: ${firstName} ${surname}`}</BulletItem>
                            <BulletItem style={{ textAlign: 'left' }}>{`Email: `}
                            <ExternalLinkText
                              url={`mailto:${email}`}
                              directToBrowser={true}
                            >{email}</ExternalLinkText></BulletItem>
                          </>
                        : <></>
                    }
                    <BulletItem style={{ textAlign: 'left' }}>{`Employed Since: ${employmentStartDate ? formatUkDate(new Date(employmentStartDate)) : 'Unknown'}`}</BulletItem>
                    <BulletItem style={{ textAlign: 'left' }}>{`Enrolled Since: ${enrolmentDate ? formatUkDate(new Date(enrolmentDate)) : 'Unknown'}`}</BulletItem>
                    {
                      enrolmentEndDate
                        ? <BulletItem style={{ textAlign: 'left' }}>{`Enrolment End: ${enrolmentEndDate ? formatUkDate(new Date(enrolmentEndDate)) : 'Unknown'}`}</BulletItem>
                        : <></>
                    }
                    {
                      actionableExtendedEnrolmentStatuses.includes(extendedEnrolmentStatus)
                      ?
                        <Button
                          mode='text'
                          color={themeColors.accent}
                          onPress={() => setShowChangeStatusRequest(true)
                        }>
                          {
                            extendedEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.OPTED_OUT || extendedEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.INACTIVE
                              ? `Request (Re)enrolment`
                              : extendedEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.ACTIVE
                                ? withinOptOutWindow
                                  ? `Request Opt-Out`
                                  : `Request to Leave Scheme`
                              : ''
                          }
                        </Button>
                        : <>
                            <Paragraph>
                              {
                                extendedEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.REQUESTED_OPT_IN ? `You currently have a pending request to re-enrol in the scheme`
                                : extendedEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.REQUESTED_OPT_OUT ? `You currently have a pending request to opt out of the scheme`
                                : extendedEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.REQUESTED_CEASE ? `You currently have a pending request to leave the scheme`
                                : ''
                              }
                            </Paragraph>
                            <Button mode='text' color={themeColors.accent} onPress={() => navigation.navigate('Requests')}>{`View Requests`}</Button>
                          </>
                    }
                  </View>
                  <ContentDivider />

                  <Subheading style={Typography.defined.sectionListHeader}>{'Contributions'} </Subheading>
                  <View style={{ paddingVertical: Sizing.x20 }}>
                    <View style={{ paddingBottom: Sizing.x20 }}>
                      <AppIllustration
                        filename={'regular_contributions.png'} style={[
                        {
                          width: Sizing.x100,
                          height: Sizing.x100,
                          resizeMode: 'contain',
                          alignSelf: 'center'
                        },
                      ]} />
                    </View>
                      {
                      mostRecentEmployerContribution
                        ? <>
                            <Subheading>{`These are the details of the last contribution made by your employer.`}</Subheading>
                          </>
                        : <>
                            {
                              enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE
                               ? <>
                                  <Subheading>{`You haven't received any contributions from your employer yet.`}</Subheading>
                                  <Paragraph>{`This may be because you are still in your 'Opt-Out' period - please contact your employer for more details.`}</Paragraph>
                                 </>
                              : <Subheading>{`You haven't received any contributions from this employer.`}</Subheading>
                            }
                          </>
                    }
                    <UnborderedTable
                      data={contributionTableData}
                    />
                    {
                      mostRecentEmployerContribution
                        ? <Button mode='text' color={themeColors.accent} onPress={() => navigation.navigate('Contributions')}>{`View Employment Contributions`}</Button>
                        : <></>
                    }
                    {
                      enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE && enabledRequestTypes && enabledRequestTypes?.includes(GroupSchemeRequestType.SET_GROSS_CONTRIBUTION)
                        ? <>
                            <Paragraph>{`Your employer allows you to request a change to the contribution amount.`}</Paragraph>
                            <Button mode='text' color={themeColors.accent} onPress={() => setShowContributionRequest(true)}>{`Request a Change`}</Button>  
                          </>
                        : <Paragraph>{`Please speak to your employer if you want to make a change to the contribution amount.`}</Paragraph>
                    }
                  </View>
                  <ContentDivider />

                  {
                    enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE
                      ? <>
                          <Subheading style={Typography.defined.sectionListHeader}>{'Forecasting Behaviour'} </Subheading>
                          <View style={{ paddingVertical: Sizing.x20 }}>
                            <View style={{ paddingBottom: Sizing.x20 }}>
                              <AppIllustration
                                filename={'path_to_freedom.png'} style={[
                                {
                                  width: Sizing.x100,
                                  height: Sizing.x100,
                                  resizeMode: 'contain',
                                  alignSelf: 'center'
                                },
                              ]} />
                            </View>
                            <Subheading>{'Current configuration:'}</Subheading>
                            <Headline style={{ color: themeColors.accent }}>
                              {
                                contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
                                ? `Ad-hoc Income`
                                : contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
                                ? `Regular ${defaultContributionFrequency} Income`
                                : `${formatCurrencyAmount(customEmployerContributionAmount)}${getEachPhrase(customPayrollFrequency)}*`
                              }
                            </Headline>
                            {
                              contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM
                              ? <Paragraph>{`* Your custom configuration`}</Paragraph>
                              : <></>
                            }
                            <InformationButton
                              title={
                                contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.NONE
                                  ? `Ad-hoc Income`
                                  : contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.LAST_PERIOD
                                  ? `Regular Income`
                                  : `Custom Configuration`
                              }
                              texts={informationTexts}
                              illustrationFilename={'gamified_approach.png'}
                              buttonTitle={`What does this mean?`}
                            />
                            <Button mode='text' color={themeColors.accent} onPress={() => setChangeForecastTreatment(true)}>{`Change income type`}</Button>
                          </View>
                          <ContentDivider />
                        </>
                      : <></>
                  }

                  <Subheading style={Typography.defined.sectionListHeader}>{'Employer Requests'} </Subheading>
                  <View style={{ paddingVertical: Sizing.x20 }}>
                    <View style={{ paddingBottom: Sizing.x20 }}>
                      <AppIllustration
                        filename={'envelope_with_star.png'} style={[
                        {
                          width: Sizing.x100,
                          height: Sizing.x100,
                          resizeMode: 'contain',
                          alignSelf: 'center'
                        },
                      ]} />
                    </View>
                    {
                      pendingRequestTypes.length
                        ? <>
                            <Paragraph>{`You currently have ${pendingRequestTypes.length} request${pendingRequestTypes.length === 1 ? '' : 's'} pending with your employer.`}</Paragraph>
                          </>
                        : <>
                            <Paragraph>{`You don't currently have any pending requests open with this employer.`}</Paragraph>
                          </>
                    }
                    {
                      requests?.length
                      ? <Button mode='text' color={themeColors.accent} onPress={() => navigation.navigate('Requests')}>{
                        pendingRequestTypes?.length
                          ? `View all Requests`
                          : `View old Requests`
                      }</Button>
                      : <></>
                    }
                  </View>
                </RefreshableScrollView>
              </View>
            }
          </MainAppScreen>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})