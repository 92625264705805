import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { ampli } from 'src/ampli'
import { api, useDeleteSpouseMutation, useGetSpouseQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editSpouseVisible, setEditSpouseVisible } from 'store/uxSlice'
import { SpouseEditScreen } from './Screens/SpouseEditScreen'
import { JAR_NAME_ALL } from 'lib/constants'

export type SpouseEditModalProps = {
  visible: boolean
  onDismiss?: any
  theme?: any
}

export const SpouseEditModal = (props: SpouseEditModalProps) => {

  const { data: spouse, error: spouseError, isLoading: spouseIsLoading } = useGetSpouseQuery()

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditSpouseVisible(false))

  const visible = props.visible || useAppSelector(editSpouseVisible)
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  const [ deleteSpouse ] = useDeleteSpouseMutation()

  const handleDelete = async () => {
    Logger.info(`Deleting spouse...`)
    const copy = {...spouse}
    deleteSpouse()
    //IMPORTANT NOTE
    //This call to resetApiState is overkill but is the only way that has currently been found to
    //forcibly clear the RTK query cache and make sure we remove the cached spouse representation
    //Attempts have been made using api.util.updateQueryData and api.util.prefetch, both here
    //and in apiSlice (where this would ideally be located). None of them appear to remove the
    //cached spouse entry
    //Hence this call destroys all cached data, which will then be re-pulled from the backend.
    //Given spouse deletion should be a rare occurrence, this is acceptable for now
    ampli.spouseDelete(pick(copy, [
      'gender',
      'birthDate',
      'age',
    ]))
    Logger.debug(`Resetting API state to clear caches...`)
    dispatch(api.util.resetApiState())
    onDismiss()
  }

  return (    
    <ModalFormBuilder
      isLoading={spouseIsLoading}
      error={spouseError}
      theme={props.theme}
      visible={visible}
      formTitle='Update Partner'
      onDismiss={onDismiss}
      //Deleting not yet working - it deletes at the backend, but the rtk-query caching is not being invalidated
      //so the UI is not updating and thinks there is still a client
      //TODO - needs fixing to enable spouse deletion
      onDelete={handleDelete}
      deleteDialogText={spouse?.userId
        ? `This will disconnect you and your partner, and neither or you will have any visibility on the other's ${JAR_NAME_ALL}, other pensions/assets and retirement incomes. This may have a significant affect on financial forecasting and your predicted retirement age!`
        : `This will delete your partner along with all retirement incomes and other pensions/assets. This may have a significant affect on financial forecasting and your predicted retirement age!`
      }
      screen={<SpouseEditScreen
        spouse={spouse}
        onDismiss={onDismiss}
      />}
    />
  )
}
