import { getScreenWidthConstraints, scaleNormalizer } from 'lib/scaleHelpers';
import { platformIsWeb } from 'lib/platformHelpers';
import { StyleSheet } from 'react-native';
import { Colors, Flex, Sizing, Typography } from 'styles';

const viewConstraints: any = getScreenWidthConstraints()

const isWeb = platformIsWeb()

export const layoutStyles = StyleSheet.create({
  inputContainer: {
    ...viewConstraints,
    width: '100%',
  },
  mainAppScrollPageScrollContainer: {
    // ...Flex.column.start,
    flex: 1
  },
  appScrollViewContainer:{
    width: '100%',
    // paddingHorizontal: Sizing.x15,
  },
  mainContentContainer: {
    ...Flex.column.start,
    ...Flex.override.fill
  },
  dashboardMainContent: {
    paddingHorizontal: Sizing.x15,
    paddingBottom: Sizing.x30,
  },
  scrollContainer: {},
  scrollContainerContent: {
    flexGrow: 1,
  },
  //Modals

  modalHeaderContainer: {
    ...Flex.column.start,
    paddingTop: Sizing.x20,
    height: Sizing.x110,
  },
  //Bottom banner modal
  bottomBannerModalContainer: {
    ...Flex.column.end,
    alignSelf: 'flex-end',
    height: '30%',
    width: '100%',
  },
  //Card modals
  cardModalContainer: {
    ...Flex.column.start,
    alignSelf: 'center',
    borderRadius: Sizing.x10,
  },
  cardModalTitleContainer: {
    ...Flex.row.between,
    padding: Sizing.x10
  },
  cardModalTitleIconContainer: {
    alignSelf: 'center'
  },
  cardModalTitleTextContainer: {
    paddingHorizontal: Sizing.x10,
    alignSelf: 'center'
  },
  cardModalContentContainer: {
    padding: Sizing.x10,
    ...Flex.column.start,
    ...Flex.override.fill
  },
  cardModalButtonContainer: {
    padding: Sizing.x10,
    ...Flex.override.bottom
  },
  //Cards
  card: {
    marginTop: Sizing.x10
  },
  cardTitle: {
    color: Colors.brand.red3
  },
  cardSubTitle: {
    color: Colors.neutral.white
  },
  cardContent: {},
  cardActions: {
    ...Flex.row.center
  },
  //Suggestion Cards
  suggestionCard: {
    marginTop: Sizing.x20
  },
  suggestionCardTitle: {
    color: Colors.brand.red2,
  },
  suggestionCardContent: {},
  suggestionCardActions: {
    ...Flex.row.center
  },
  notificationDot: {
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.brand.red3,
    height: scaleNormalizer(18),
    width: scaleNormalizer(18),
    borderRadius: scaleNormalizer(9),
    position: 'absolute',
    zIndex: 1000,
    paddingTop: isWeb ? Sizing.x2 : undefined,
  },
  notificationDotText: {
    ...Typography.defined.dashboardTabDotText
  }
});
