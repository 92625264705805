import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Subheading } from 'components/Typography'
import { FooterButton } from 'components/Utility/FooterButton'
import { ShareModal } from 'components/Utility/ShareModal'
import * as Notifications from 'expo-notifications'
import { Dashboard } from 'features/Dashboard/Dashboard'
import { ContributionsSectionScreen } from 'features/MenuSections/Contributions/ContributionsSectionScreen'
import { GroupPensionSectionScreen } from 'features/MenuSections/GroupPension/GroupPensionSectionScreen'
import { LegacySectionScreen } from 'features/MenuSections/Legacy/LegacySectionScreen'
import { MessagesSectionScreen } from 'features/MenuSections/Messages/MessagesSectionScreen'
import { OtherPensionsSectionScreen } from 'features/MenuSections/OtherPensions/OtherPensionsSectionScreen'
import { PersonalPensionSectionScreen } from 'features/MenuSections/PersonalPension/PersonalPensionSectionScreen'
import { ProfileSectionScreen } from 'features/MenuSections/Profile/ProfileSectionScreen'
import { RegularIncomeSectionScreen } from 'features/MenuSections/RegularIncomes/RegularIncomeSectionScreen'
import { RetirementLifestyleSectionScreen } from 'features/MenuSections/RetirementLifestyle/RetirementLifestyleSectionScreen'
import { SpouseSectionScreen } from 'features/MenuSections/Spouse/SpouseSectionScreen'
import { StatementsSectionScreen } from 'features/MenuSections/Statements/StatementsSectionScreen'
import { TransfersSectionScreen } from 'features/MenuSections/Transfers/TransfersSectionScreen'
import { ModalProcesses } from 'features/ModalProcesses/ModalProcesses'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL, MIN_WIDTH_FOR_PERMANENT_MENU } from 'lib/constants'
import { featureIsEnabledForUser } from 'lib/featureHelpers'
import { getScreenAppWidth, scaleNormalizer, webMaxWidth } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { dismissAllNotifications, getPushNotificationToken, handleNotificationRefreshAction } from 'lib/notificationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { changeAppContext, goToLogout, rootNavigate } from 'lib/RootNavigation'
import { DrawerScreen, DrawerScreenGroup } from 'navigation/components/CustomDrawerItemList'
import React, { useEffect, useRef } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useAddTokenForCurrentUserMutation, useGetCurrentUserQuery, useGetMeQuery, useGetSpouseQuery, useGetUserFeaturesQuery } from 'store/apiSlice'
import { AppContext, setPushToken, setUserHasClient } from 'store/authSlice'
import { FeatureCode } from 'store/dto/feature.dto'
import { NotificationMethod } from 'store/dto/notification.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShareModalVisible, shareModalVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing, Typography } from 'styles'
import { MainAppStackDrawerContent } from './Components/MainAppStackDrawerContent'
import { LoadingScreen } from './Gates'
import { WebHoldingScreen } from './WebHoldingScreen'
import { useFocusEffect } from '@react-navigation/native'
import { AppIllustration } from 'components/Utility/AppIllustration'

const screenWidth = getScreenAppWidth()

const Drawer = createDrawerNavigator()
const DRAWER_ICON_SIZE = 20

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true, //Required for notification to show when app is open
    shouldSetBadge: false,
  }),
})

export const MainAppStack = () => {
  Logger.info(`#### Stack: MainApp`)

  const dispatch = useAppDispatch()
  
  const authUser = useSelector((state: any) => state.auth.user)
  const userHasClient = useSelector((state: any) => state.auth.userHasClient)

  const { data: user, isLoading: userIsLoading, error: userError } = useGetCurrentUserQuery()
  //Skip if userHasClient === false so that it checks once (when undefined)
  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery(undefined, { skip: !user || userHasClient === false })
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching, refetch: refetchSpouse } = useGetSpouseQuery(undefined, { skip: !client })
  const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery(undefined, { skip: !user } )
  const [addPushToken] = useAddTokenForCurrentUserMutation()

  //Determine if the web app is available
  const webAppEnabledForUser = featureIsEnabledForUser(features, FeatureCode.CLIENT_WEB_APP)

  //NOTE: DO NOT include any isFetching, as they will cause the client app to reload with changes to those entities,
  //destroying the navigation state, etc.
  const isLoading = userIsLoading || clientIsLoading || spouseIsLoading || featuresIsLoading
  const clientNotYetCreated = !!clientError && clientError?.status === 404

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  //Navigate to client setup if no client yet or not agreed terms
  useEffect(()=>{
    if (userHasClient === undefined) {
      Logger.debug(`Checking if user already has a client...`)
      if (client && client?.termsAgreed) {
        Logger.debug(`User already has client with terms agreed`)
        dispatch(setUserHasClient(true))
      } else if ((client && !client?.termsAgreed) || (clientNotYetCreated)) {
        Logger.debug(`Client not accepted terms, or doesn't yet exist`)
        dispatch(setUserHasClient(false))
        if (!isWeb || webAppEnabledForUser) {
          Logger.debug(`Navigating to client setup...`)
          rootNavigate('ClientSetupIntroScreen', { existingClient: client, onFinish: () => dispatch(setUserHasClient(true)) })
        }
      } else if (!!clientError) {
        Logger.error({ clientError }, `A different clientError occurred`)
      }
    }
  },[client, clientError])

  const notificationListener = useRef()

  useEffect(() => {
    //Skip for admins impersonating users
    const impersonation = authUser?.impersonation
    if (impersonation) {
      return
    }
    
    //Attempt to get token, requesting permission if user has not yet made
    //a decision, and if we get one, store it and send to the backend
    getPushNotificationToken().then(token => {
      if (token) {
        dispatch(setPushToken(token))
        addPushToken({
          method: NotificationMethod.PUSH,
          token,
        })
      }
    })

    //Register handler to perform actions when notifications are received
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      handleNotificationRefreshAction(notification, dispatch)
    })

    //Clear all notifications when coming into the app
    dismissAllNotifications()
  }, [])

  const isWeb = platformIsWeb()
  
  const shareModalActive = useAppSelector(shareModalVisible)
  
  const dashboardDrawerScreenGroup: DrawerScreenGroup = {
    id: '01_dashboard',
    title: 'Dashboard',
    renderFlat: true,
  }

  const jarvisPensionDrawerScreenGroup: DrawerScreenGroup = {
    id: '02_jarvis_pension',
    title: JAR_NAME_ALL,
    defaultExpanded: true,
    renderFlat: false,
  }
  
  const planningDrawerScreenGroup: DrawerScreenGroup = {
    id: '03_planning',
    title: 'Retirement Planning',
    renderFlat: false,
  }

  const documentsDrawerScreenGroup: DrawerScreenGroup = {
    id: '04_documents',
    title: 'Documents',
    renderFlat: false,
  }

  const profileDrawerScreenGroup: DrawerScreenGroup = {
    id: '05_profile',
    title: 'Profile',
    renderFlat: true,
  }

  const screenGroupMap = {
    [MainAppNavScreen.DASHBOARD]: dashboardDrawerScreenGroup,
    [MainAppNavScreen.PERSONAL_PENSION]: jarvisPensionDrawerScreenGroup,
    [MainAppNavScreen.GROUP_PENSION]: jarvisPensionDrawerScreenGroup,
    [MainAppNavScreen.CONTRIBUTIONS]: jarvisPensionDrawerScreenGroup,
    [MainAppNavScreen.TRANSFERS]: jarvisPensionDrawerScreenGroup,
    [MainAppNavScreen.RETIREMENT_ASSETS]: planningDrawerScreenGroup,    
    [MainAppNavScreen.REGULAR_INCOMES]: planningDrawerScreenGroup,    
    [MainAppNavScreen.RETIREMENT_LIFESTYLE]: planningDrawerScreenGroup,    
    [MainAppNavScreen.RETIREMENT_LEGACY]: planningDrawerScreenGroup,    
    [MainAppNavScreen.DOCUMENTS]: documentsDrawerScreenGroup,    
    [MainAppNavScreen.STATEMENTS]: documentsDrawerScreenGroup,    
    [MainAppNavScreen.PROFILE]: profileDrawerScreenGroup,
    [MainAppNavScreen.SPOUSE]: profileDrawerScreenGroup,
  }

  const screens: DrawerScreen[] = [
    {
      name: MainAppNavScreen.DASHBOARD,
      component: Dashboard,
      title: 'Dashboard',
      icon: 'view-dashboard',
    },
    {
      name: MainAppNavScreen.PERSONAL_PENSION,
      component: PersonalPensionSectionScreen,
      title: JAR_NAME_PERSONAL,
      icon: 'piggy-bank',
    },
    {
      name: MainAppNavScreen.GROUP_PENSION,
      component: GroupPensionSectionScreen,
      title: JAR_NAME_GROUP,
      icon: 'briefcase',
    },
    {
      name: MainAppNavScreen.CONTRIBUTIONS,
      component: ContributionsSectionScreen,
      title: 'Contributions',
      icon: 'bank-transfer-in',
    },
    {
      name: MainAppNavScreen.TRANSFERS,
      component: TransfersSectionScreen,
      title: 'Transfers',
      icon: 'consolidate',
    },
    {
      name: MainAppNavScreen.RETIREMENT_ASSETS,
      component: OtherPensionsSectionScreen,
      title: 'Other Assets',
      icon: 'piggy-bank-outline',
    },
    {
      name: MainAppNavScreen.REGULAR_INCOMES,
      component: RegularIncomeSectionScreen,
      title: 'Incomes',
      icon: 'cash-fast',
    },
    {
      name: MainAppNavScreen.RETIREMENT_LIFESTYLE,
      component: RetirementLifestyleSectionScreen,
      title: 'Lifestyle',
      icon: 'nature-people',
    },
    {
      name: MainAppNavScreen.RETIREMENT_LEGACY,
      component: LegacySectionScreen,
      title: 'Legacy',
      icon: 'grave-stone',
    },
    {
      name: MainAppNavScreen.DOCUMENTS,
      component: MessagesSectionScreen,
      title: 'Documents',
      icon: 'inbox-full',
    },
    {
      name: MainAppNavScreen.STATEMENTS,
      component: StatementsSectionScreen,
      title: 'Statements',
      icon: 'file-document-outline',
    },
    {
      name: MainAppNavScreen.PROFILE,
      component: ProfileSectionScreen,
      title: 'Profile',
      icon: 'account',
      hide: true,
    },
    {
      name: MainAppNavScreen.SPOUSE,
      component: SpouseSectionScreen,
      title: 'Partner',
      icon: 'account-multiple',
      hide: true,
    },
  ]

  function getDrawerIcon(iconName, focused) {
    return (
      <MaterialCommunityIcons
        name={iconName}
        size={DRAWER_ICON_SIZE}
        color={focused ? Colors.brand.purple2 : Colors.brand.grey2}
      />
    )
  }

  return (
    <>
      {
        isLoading || userHasClient === undefined ? <LoadingScreen messages={[`Preparing the Jarvis App...`]} />
        : isWeb && user && !webAppEnabledForUser ? <WebHoldingScreen />
        :
          <Portal.Host>
            {
              user ?
                <>
                  <Drawer.Navigator
                    id="MainApp"
                    initialRouteName={MainAppNavScreen.DASHBOARD}
                    drawerContent={(props) => <MainAppStackDrawerContent
                      {...props}
                      screens={screens}
                      screenGroupMap={screenGroupMap}
                    />}
                    screenOptions={{
                      headerShown: false,
                      headerShadowVisible: false,
                      drawerActiveTintColor: Colors.brand.purple2,
                      drawerType: isWeb ? screenWidth < MIN_WIDTH_FOR_PERMANENT_MENU ? 'front' : 'permanent' : 'slide',
                      drawerStyle: {
                        backgroundColor: Colors.brand.purplex0,
                        width: scaleNormalizer(280),
                      },
                      swipeEdgeWidth: Sizing.x80,
                      drawerLabelStyle: Typography.defined.mainAppDrawerItem,
                      sceneContainerStyle: isWeb ? {
                        backgroundColor: isWeb ? Colors.brand.grey4 : undefined,
                        padding: Sizing.x20,
                        maxWidth: webMaxWidth,
                      } : undefined
                    }}>
                    <Drawer.Group>
                      {
                        screens.map((screen, idx) => {
                          return (
                            <Drawer.Screen
                              key={idx}
                              name={screen.name}
                              component={screen.component}
                              options={{
                                title: screen.title,
                                drawerIcon: ({ focused, color, size }) => getDrawerIcon(screen.icon, focused),
                                drawerItemStyle: {
                                  display: screen.hide ? 'none' : undefined,
                                },
                              }}
                            />
                          )
                        })
                      }
                    </Drawer.Group>
                  </Drawer.Navigator>
                  <ModalProcesses/>
                  <Portal>
                    <ShareModal
                      user={user}
                      campaign={'suggestions'}
                      onDismiss={() => dispatch(setShareModalVisible(false))}
                      visible={shareModalActive}
                    />
                  </Portal>
                </>
              :
                <BackgroundGradientPurple>
                  <SafeAreaView style={{
                    ...Flex.column.center,
                    ...Flex.override.fill
                  }}>
                    <View style={{
                      paddingTop: Sizing.x30,
                      paddingHorizontal: Sizing.x30,
                      ...Flex.column.center,
                      ...Flex.override.fill,
                    }}>
                      <Headline>{`Sorry, you don't have access`}</Headline>
                      <Subheading>{`Please login to access the Jarvis Web App.`}</Subheading>
                      <AppIllustration filename={'magnifying_glass.png'} style={{
                        width: '100%',
                        height: Sizing.x200,
                        resizeMode: 'contain',
                        alignSelf: 'center'
                      }}/>
                      <FooterButton
                          mode='contained'
                          onPress={goToLogout}
                        >
                          {'Go to Login'}
                      </FooterButton>
                    </View>
                  </SafeAreaView>
                </BackgroundGradientPurple> 
            }
          </Portal.Host>
        }
    </>
  )
}

const localStyles = StyleSheet.create({
  profileContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingHorizontal: Sizing.x10,
  },
  smallProfileImageContainer: {
  },
  smallProfileImage: {
    alignSelf: 'center',
    height: scaleNormalizer(60),
    width: scaleNormalizer(60),
    borderRadius: scaleNormalizer(30),
  },
})
