import { platformIsWeb } from 'lib/platformHelpers'
import { refreshUsingFunctions } from 'lib/refreshHelpers'
import React, { ComponentProps, forwardRef, useState } from 'react'
import { RefreshControl, ScrollView } from 'react-native'
import { Colors } from 'styles'

export type RefreshableScrollViewProps = ComponentProps<typeof ScrollView> & {
  refreshFunctions: Function[]
}

const isWeb = platformIsWeb()

export const RefreshableScrollView = forwardRef((props: RefreshableScrollViewProps, ref: any) => {
  const { children, refreshFunctions } = props

  const [refreshing, setRefreshing] = useState(false)

  const onRefresh = async () => {
    await refreshUsingFunctions(
      refreshFunctions,
      refreshing,
      setRefreshing,
    )
  }

  return (
    <ScrollView
      ref={ref}
      showsVerticalScrollIndicator={isWeb}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          tintColor={Colors.neutral.white}
        />
      }
    >
      {children}
    </ScrollView>
  )
})
