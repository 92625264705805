import { ModalProcessResultScreen } from 'components/Layout/ModalProcessResultScreen'
import { Logger } from 'lib/logger'
import { pick } from 'lodash'
import React, { useEffect } from 'react'
import { batch } from 'react-redux'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { RetirementAssetTransferStatus, RetirementAssetUntransferableReason } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, updateWorkingClient, workingRetirementAsset, workingClient } from 'store/tempDataSlice'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'
import { getAmplitudeRetirementAssetAttributes } from 'lib/amplitudeHelpers'

export const RetirementAssetTransfer_99_Result = ({ navigation, route }) => {
  const { onDismiss, asset, isRetransfer }  = route?.params || {}

  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)
  const workingClientData = useAppSelector(workingClient)

  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()
  const [updateAsset, { data: updatedAsset, error: assetUpdateError, isLoading: assetUpdateIsLoading }] = useUpdateRetirementAssetMutation()

  const isLoading = clientUpdateIsLoading || assetUpdateIsLoading
  const error = clientUpdateError || assetUpdateError

  //Save on entry
  useEffect(() => {
    //If any previous-name client data, save client first
    if (workingClientData?.previousFirstName || workingClientData?.previousSurname || workingClientData?.hasPreviousName) {
      saveClient()
    } else {
      saveAsset()
    }
  }, [])

  const saveClient = () => {
    Logger.info(`Updating client previous name...`)
    updateClient({
      ...pick(workingClientData, [
        'previousFirstName',
        'previousSurname',
      ]),
      hasPreviousName: true,
    })
  }  

  //Save asset on updated client
  useEffect(() => {
    if (updatedClient) {
      saveAsset()
    }
  }, [updatedClient])

  //Amplitude reporting on asset save
  useEffect(() => {
    if (updatedAsset) {
      ampli.transferPrepare({
        ...getAmplitudeRetirementAssetAttributes(asset),
        isRetransfer,
        useTracing: tracingRequested,
      })
    }
  }, [updatedAsset])
  

  const saveAsset = () => {
    Logger.info(`Updating asset for transfer...`)
    updateAsset({
      id: asset?.id,
      ...workingRetirementAssetData,
      transferStatus: isRetransfer ? RetirementAssetTransferStatus.MODIFIED : undefined,
    })
  }

  const close = () => {
    batch(() => {
      dispatch(updateWorkingRetirementAsset(undefined))
      dispatch(updateWorkingClient(undefined))
    })

    onDismiss()
  }

  const tasks: RetirementAssetTransferStepIdentifier[] = [
    RetirementAssetTransferStepIdentifier.PROVIDER,
    RetirementAssetTransferStepIdentifier.REFERENCE,
    RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS,
  ]
  if (workingRetirementAssetData?.tracingRequested) {
    tasks.push(RetirementAssetTransferStepIdentifier.TRACING)
  }

  const tracingRequested = workingRetirementAssetData?.tracingRequested
  const tracingIncomplete = workingRetirementAssetData?.isActiveEmployer || workingRetirementAssetData?.employerShortDuration

  //Derive isUntransferable
  const isUntransferable = isRetransfer
    ? updatedAsset?.untransferableReason && updatedAsset?.untransferableReason !== RetirementAssetUntransferableReason.TRANSFER_ACTIVE
    : updatedAsset?.untransferableReason

  //Derive isUntraceable
  const isUntraceable = tracingRequested && updatedAsset?.untransferableReason

  //On hold if client name mismatch OR (not retransfer AND missing data but has provider)
  const assetOnHold = 
    updatedAsset?.untransferableReason === RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH
      || (!isRetransfer && updatedAsset?.untransferableReason === RetirementAssetUntransferableReason.MISSING_DATA && updatedAsset?.pensionProviderId)

  const nameMismatch = updatedAsset?.untransferableReason === RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH

  const pendingSteps: RetirementAssetTransferStepIdentifier[] = []
  if (workingRetirementAssetData?.tracingRequested || !updatedAsset?.pensionProviderId) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.PROVIDER)
  }
  if (workingRetirementAssetData?.tracingRequested || !updatedAsset?.externalProviderReference) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.REFERENCE)
  }
  if (!tracingRequested && (pendingSteps.length || assetOnHold)) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS)
  }
  if (tracingRequested && tracingIncomplete) {
    pendingSteps.push(RetirementAssetTransferStepIdentifier.TRACING)
  }
  
  return (
    <ModalProcessResultScreen
      isLoading={isLoading}
      loadingMessage={['Saving your work...']}
      error={error}
      errorTryAgain={clientUpdateError ? saveClient : saveAsset}
      headline={isUntraceable
          ? `Ok, we'll leave this one with you`
          : isUntransferable
            ? assetOnHold
              ? `Ok, we'll keep this one on hold`
              : `Ok, maybe another time`
            : isRetransfer
              ? `Ok, this one's ready to try again`
              : `That's everything we need!`
      }
      subHeading={isUntraceable
        ? updatedAsset.untransferableReason === RetirementAssetUntransferableReason.CLIENT_NAME_MISMATCH
          ? `If you can find your pension details, you can follow the process again to submit for transfer`
          : `If you can find your pension details, you can follow the process again to submit for transfer`
        : isUntransferable
          ? assetOnHold && nameMismatch
            ? `Please contact your old provider to update their records with your current name`
            : `You can return to this flow later to complete the process`
          : isRetransfer
            ? `We can now make another attempt to transfer your pension`
            : tracingRequested
              ? `This pension is now ready to be found by our team`
              : `This pension is now ready to be transferred from your old provider`
      }
      illustrationFilename={'task_list.png'} 
      buttonTitle={isUntransferable || isUntraceable ? 'Close' : 'Finish'}
      buttonAction={close}
      showButton={true}
    >
      <TaskList
        asset={asset}
        allTasks={tasks}
        nextTask={undefined}
        nextTaskAction={() => {}}
        pendingSteps={pendingSteps}
        allComplete={true}
        isRetransfer={isRetransfer}
      />
    </ModalProcessResultScreen>
  )
}
