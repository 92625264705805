import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatUkDate } from 'lib/dateHelpers'
import { isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import React from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppDispatch } from 'store/hooks'
import { updateWorkingSchemeEnrolmentRequest } from 'store/tempDataSlice'

export const SchemeEnrolmentChangeStatusRequestAdd_02_Confirm = ({ route, navigation }) => {
  const { nextScreen, onDismiss, enrolment, requestType }: { nextScreen: string, onDismiss: any, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, requestType: GroupSchemeRequestType } = route?.params || {}

  const dispatch = useAppDispatch()

  const withinOptOutWindow = isWithinOptOutWindow(enrolment)

  const next = () => {
    dispatch(updateWorkingSchemeEnrolmentRequest({
      requestType,
      data: {},
    }))
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      buttonTitle={enrolment.isVerified ? `Confirm & Finish` : 'Confirm'}
      buttonAction={next}
      enableButton={true}
      headline={enrolment.isVerified
        ? `Ok, we're ready to send to your employer`
        : `Ok, we just need your confirmation`
      }
      subHeading={`Please check the details below and confirm`}
    >
      <UnborderedTable
        data={[
          {
            label: `Request Type`,
            value: requestType === GroupSchemeRequestType.OPT_IN
            ? `Opt In`
            : requestType === GroupSchemeRequestType.OPT_OUT
            ? `Opt Out`
            : `Leave Scheme`
          },
          {
            label: `Request Date`,
            value: formatUkDate(new Date())
          },
        ]}
      />
      {
        !enrolment.isVerified
          ? <Paragraph>{`Your request will be sent when you have finished setting up this employer.`}</Paragraph>
          : <></>
      }
      <Paragraph>{`Depending on your employment circumstances, your employer may need to contact you about this request.`}</Paragraph>
    </ModalProcessScreen>
  )
}
