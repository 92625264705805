import { formatISO } from 'date-fns'
import { envVariables } from 'environment'
import * as Application from 'expo-application'
import Constants from 'expo-constants'
import * as Device from 'expo-device'
import * as Updates from 'expo-updates'
import { ClientMeDto } from 'store/dto/client.dto'
import { ErrorDto } from 'store/dto/error.dto'
import { UserDto } from 'store/dto/user.dto'
import { toString, concat } from 'lodash'
import { platformIsWeb } from './platformHelpers'

export enum InformationSectionKey {
  DATE,
  USER,
  APP,
  DEVICE,
  SUPPORT,
  ERROR,
}

export interface InformationSection {
  key: InformationSectionKey,
  needsAuthenticatedUser: boolean,
  sectionTitle: string,
  items: InformationItem[]
}

export interface InformationItem {
  title: string,
  value: any
}

const isWeb = platformIsWeb()

export const getDiagnosticInformation = (user?: UserDto, client?: ClientMeDto, error?: ErrorDto): InformationSection[] => {  
  const { supportEmail, envName, commitIdentifier } = envVariables

  const informationArray = [
    {
      key: InformationSectionKey.DATE,
      needsAuthenticatedUser: false,
      sectionTitle: `Date`,
      items: [
        { title: `Timestamp`, value: formatISO(new Date()) },
      ],
    },
    {
      key: InformationSectionKey.USER,
      needsAuthenticatedUser: true,
      sectionTitle: `User Information`,
      items: [
        { title: `User ID`, value: user ? user?.id : 'n/a'},
        { title: `Client ID`, value: client ? client?.id : 'n/a'},
        { title: `Client Name`, value: client ? `${client?.firstName} ${client?.surname}` : 'n/a'},
      ],
    },
    {
      key: InformationSectionKey.APP,
      needsAuthenticatedUser: false,
      sectionTitle: `App Information`,
      items: concat(
        [
          { title: `Version`, value: isWeb ? Constants.manifest.version : Application.nativeApplicationVersion},
        ],
        isWeb ? [
          { title: `Build Identifier`, value: commitIdentifier},
        ] : [
          { title: `Build`, value: Application.nativeBuildVersion},
          { title: `Identifier`, value: Application.applicationId},
          { title: `OTA Channel`, value: Updates?.channel || 'n/a'},
          { title: `OTA Revision`, value: Updates.updateId || 'n/a'},
          { title: `OTA Date`, value: Updates.createdAt ? formatISO(Updates.createdAt) : 'n/a'},
        ],
        [
          { title: `Environment`, value: envName},  
        ]
      ),
    },
    {
      key: InformationSectionKey.DEVICE,
      needsAuthenticatedUser: false,
      sectionTitle: `Device Information`,
      items: concat(
        [
          { title: `Brand`, value: isWeb ? 'Browser' : Device.brand},
        ],
        isWeb ? [] : [
          { title: `Model`, value: isWeb ? 'n/a' : Device.modelName},
        ], [
          { title: `OS`, value: Device.osName},
        ],
        isWeb ? [] : [
          { title: `OS Version`, value: isWeb ? 'n/a' : Device.osVersion}, 
        ]
      ),
    },
    {
      key: InformationSectionKey.SUPPORT,
      needsAuthenticatedUser: false,
      sectionTitle: `Support Information`,
      items: [
        { title: `Email`, value: supportEmail},
      ],
    },
  ]

  if (error) {
    informationArray.push({
      key: InformationSectionKey.ERROR,
      needsAuthenticatedUser: false,
      sectionTitle: `Error Details`,
      items: [
        { title: `Error Code`, value: toString(error.code)},
        { title: `User Message`, value: error.userMessage},
        { title: `Developer Message`, value: error.developerMessage},
      ],
    },)
  }

  return informationArray
}

export const getDiagnosticString = (informationArray: InformationSection[], relevantSections: InformationSectionKey[]): string => {

  //Build body for sharing via email/other
  let infoBody = ''  
  informationArray.forEach((section, idx) => {
    if (relevantSections.includes(section.key)) {
      infoBody += `${idx === 0 ? '' : '\n'}---- ${section.sectionTitle} ----\n`
      section.items.forEach(item => {
        infoBody += `${item.title}: ${item.value}\n`
      })
    }
  })

  return infoBody

}

