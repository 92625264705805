import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowEmployerAddInviteVisible, showEmployerAddInviteVisible } from 'store/uxSlice'
import { EmployerAddInviteScreen } from './Screens/EmployerAddInviteScreen'

export const EmployerAddInviteModal = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showEmployerAddInviteVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Send New Invite'
      onDismiss={() => dispatch(setShowEmployerAddInviteVisible(false))}
      screen={<EmployerAddInviteScreen />}
    />
  )
}

