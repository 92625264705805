import { MaterialCommunityIcons } from '@expo/vector-icons'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { format } from 'date-fns'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import { getAssetTransferStatusIconName, isUnacknowledgedCompletedTrace } from 'lib/retirementAssetHelpers'
import React from 'react'
import { RetirementAssetDto, RetirementAssetTracingAttempt, RetirementAssetTracingStatus, RetirementAssetTransferAttempt, RetirementAssetTransferStatus, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { Sizing, Paper } from 'styles'
import { orderBy } from 'lodash'
import { getActionDate } from 'lib/dateHelpers'
import { PensionTransferStatus } from 'store/dto/account.dto'

type TransferListItemProp = {
  asset: RetirementAssetDto
  onPressFunction?: () => void
}
export const TransferListItem = (props: TransferListItemProp) => {
  const { asset, onPressFunction } = props
  const { assetType, name, transferStatus, transferAttempts, tracingStatus, tracingAttempts, currentValue, externalProviderReference, employerStartDate, employerEndDate, pensionProviderBrandName, transferDelayExecutionDate, completedTransferAcknowledged, transferCompletionDate, monthlyContributionAmount } = asset || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const untransferableValue =
    transferStatus === RetirementAssetTransferStatus.REJECTED
    || transferStatus === RetirementAssetTransferStatus.MODIFIED
    || tracingStatus === RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE
    || tracingStatus === RetirementAssetTracingStatus.INVALID
    || tracingStatus === RetirementAssetTracingStatus.NOT_FOUND

  const sortedTransferAttempts = orderBy(transferAttempts, ['updatedAt'], ['desc'])
  const sortedTracingAttempts = orderBy(tracingAttempts, ['updatedAt'], ['desc'])

  const currentTransferAttempt: RetirementAssetTransferAttempt = sortedTransferAttempts.length ? sortedTransferAttempts[0] : undefined
  const currentTracingAttempt: RetirementAssetTracingAttempt = sortedTracingAttempts.length ? sortedTracingAttempts[0] : undefined

  const iconName: any = getAssetTransferStatusIconName(asset, currentTransferAttempt)

  const bestAmount = currentTransferAttempt?.transferValue || currentTracingAttempt?.foundValue || currentValue
  const amountString =
    untransferableValue
      ? `${formatCurrencyAmount(bestAmount, 2)}`
      : `+${formatCurrencyAmount(bestAmount, 2)}`

  const isUnacknowledgedTrace = isUnacknowledgedCompletedTrace(asset)
  const isRejected = [RetirementAssetTransferStatus.REJECTED, RetirementAssetTransferStatus.MODIFIED].includes(transferStatus)

  //Action required if
  //1. Tracing needs decision
  //2. Execution complete and not acknowledged
  const actionRequired = isUnacknowledgedTrace
    || isRejected
    || (transferStatus === RetirementAssetTransferStatus.COMPLETED && !completedTransferAcknowledged)

  const subTitle =
      tracingStatus === RetirementAssetTracingStatus.FOUND_DELAY ? `Found: Progressing on ${transferDelayExecutionDate ? format(new Date(transferDelayExecutionDate), 'do MMMM') : 'n/a'}`
      : tracingStatus === RetirementAssetTracingStatus.FOUND_NEEDS_DECISION ? `Found: Decision Required`
      : tracingStatus === RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE ? `Untransferrable: Action Required`
      : tracingStatus === RetirementAssetTracingStatus.INVALID ? `Inelligible: Action Required`
      : tracingStatus === RetirementAssetTracingStatus.NOT_FOUND ? `Not Found: Action Required`
      : tracingStatus === RetirementAssetTracingStatus.INITIATED ? `With Finding Service`
      : tracingStatus === RetirementAssetTracingStatus.ACTIVE ? `With Finding Service`
      : transferStatus === RetirementAssetTransferStatus.INITIATED ? `Waiting for Previous Provider`
      : transferStatus === RetirementAssetTransferStatus.ACTIVE
        ? currentTransferAttempt?.transferStatus === PensionTransferStatus.ONBOARDING_ASSETS
          ? `Funds Expected Soon` 
          : `Waiting for Previous Provider`
      : transferStatus === RetirementAssetTransferStatus.WITH_SUPPORT ? `Being Investigated`
      : transferStatus === RetirementAssetTransferStatus.REJECTED ? `Rejected: Action Required`
      : transferStatus === RetirementAssetTransferStatus.MODIFIED ? `Rejected: Action Required`
      : transferStatus === RetirementAssetTransferStatus.COMPLETED
        ? completedTransferAcknowledged
          ? `Transferred${transferCompletionDate ? `: ${getActionDate(transferCompletionDate, true)}` : ``}`
          : monthlyContributionAmount
            ? `Transferred: Decision Required`
            : `Transferred: Action Required`
      : `Tap for Details`

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons
        name={iconName}
        size={Sizing.x25}
        color={isRejected
          ? themeColors.disabled
          : themeColors.primary
        }
      />}
      onPress={onPressFunction}
      title={name}
      titleStyle={isRejected ? { color: themeColors.disabled } : undefined}
      titleRight={amountString}
      titleRightStyle={[
        untransferableValue ? {textDecorationLine: 'line-through'} : {},
        isRejected ? {color: themeColors.disabled } : {},
      ]}
      subTitle={subTitle}
      subTitleStyle={actionRequired
        ? { color: themeColors.accent }
        : isRejected
          ? { color: themeColors.disabled }
          : undefined}
      adjustsFontSizeToFit={true}
    />
  )
}
