import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { envVariables } from 'environment'
import { goToLogout } from 'lib/RootNavigation'
import { addEntityToList, addHeaders, buildQuery, createOptimisticBaseDatedEntity, entityContainsAttributes, extractData, mergeEntity, removeEntityInListById, updateEntityInListById, wrapData } from 'lib/apiHelpers'
import { setUserWithToken } from 'lib/authHelpers'
import { Logger } from 'lib/logger'
import { concat } from 'lodash'
import { parse } from 'node-html-parser'
import { captureException } from 'platform-lib/sentry'
import { ModelGoalIdentifier } from 'providers'
import { setNetworkUnavailable, setServerUnavailable } from './authSlice'
import {
  AccountDto,
  BeneficiariesDto,
  ContributionDto,
  ContributionIllustrationRequestDto,
  ContributionsIllustrationDto,
  CreateAccountDto,
  CreateContributionAuthDto,
  CreateContributionDto,
  CreateSchemeEnrolmentChangeRequestDto,
  SchemeEnrolmentChangeRequestDto,
  GroupSchemeEnrolmentDto,
  PublicPendingSchemeEnrolmentDto,
  UpdateAccountDto,
  UpdateBeneficiariesDto,
  UpdateSchemeEnrolmentChangeRequestDto,
  UpdateSchemeEnrolmentDto,
  VerifySchemeEnrolmentDto,
  ContributionsQueryDto
} from './dto/account.dto'
import { AffiliateDto, AffiliateFilterDto, CreateAffiliateDto, UpdateAffiliateDto } from './dto/affiliate.dto'
import { AssetCategoryDto, AssetCategoryFilterDto, CreateAssetCategoryDto, UpdateAssetCategoryDto } from './dto/asset-category.dto'
import { AssetDto, AssetFilterDto, CreateAssetDto, UpdateAssetDto } from './dto/asset.dto'
import { BankConnectionDto, BankConnectionFilterDto, CreateBankConnectionDto, UpdateBankConnectionDto } from './dto/bank-connection.dto'
import { Gender } from './dto/base.dto'
import { CalculatorModelRequestDto, CalculatorSendReportRequestDto, CalculatorTimelineDto, CalculatorTimelineRequestDto } from './dto/calculator.dto'
import {
  ClientAmlUpdateResultDto,
  ClientAnyDto,
  ClientBusinessVerificationUpdateResultDto,
  ClientFilterDto,
  ClientMeDto,
  ClientSignatureDto,
  ClientSpouseDto,
  CompanyDto,
  CreateClientMeDto,
  CreateSpouseDto,
  NiCheckResultDto,
  UpdateClientMeDto,
  UpdateClientSignatureDto,
  UpdateSpouseDto
} from './dto/client.dto'
import { FeatureDto, FeatureFilterDto, UpdateFeatureDto, UserFeatureDto } from './dto/feature.dto'
import { CreateGroupOrganizationDto, CreateGroupOrganizationMandateDto, GroupOrganizationDto, GroupOrganizationFilterDto, GroupOrganizationMandateDto, UpdateGroupOrganizationDto } from './dto/group-organization.dto'
import { CreateGroupPortfolioDto, GroupPortfolioDto, GroupPortfolioFilterDto, UpdateGroupPortfolioDto } from './dto/group-portfolio.dto'
import { AcceptGroupSchemeInviteDto, GroupSchemeJobAcknowledgeDto, CreateGroupSchemeDto, CreateGroupSchemeInviteDto, DeclineGroupSchemeInviteDto, GroupSchemeDto, GroupSchemeFilterDto, GroupSchemeInviteDto, GroupSchemeInviteFilterDto, GroupSchemeJobDto, GroupSchemeJobFilterDto, GroupSchemeMemberDto, GroupSchemeMemberFilterDto, GroupSchemePaymentDto, GroupSchemePaymentFilterDto, GroupSchemeRequestDto, GroupSchemeRequestFilterDto, NiCheckMatchesResultDto, PublicGroupSchemeInviteDto, UpdateGroupSchemeDto, GroupSchemePaymentExecuteDto, UpdateGroupSchemeRequestDto, GroupSchemeJobSetActionedRecordsDto, BulkCreateGroupSchemeInviteOutputDto, GroupSchemeJobCheckStatusDto, CreateGroupSchemeJobDto, GroupSchemeJobsBulkAcknowledgeDto, GroupSchemePaymentConfirmDto, GroupSchemePaymentCancelDto } from './dto/group-scheme.dto'
import { CreateInvestmentPlanDto, InvestmentPlanDto, InvestmentPlanFilterDto, UpdateInvestmentPlanDto } from './dto/investment-plan.dto'
import { AcceptInviteDto, CreateInviteDto, InviteDto } from './dto/invite.dto'
import { MessageDto, MessageQueryDto, UpdateMessageDto, ValuationStatementRequestQueryDto } from './dto/message.dto'
import { ModelRepresentationDto, ModelScenarioRequest, ScenarioModelRepresentationDto, ViableAgeRepresentationDto } from './dto/model.dto'
import { CreatePensionBrandDto, PensionBrandDto, PensionBrandFilterDto, UpdatePensionBrandDto } from './dto/pension-brand.dto'
import { CreatePensionProviderDto, PensionProviderDto, PensionProviderFilterDto, UpdatePensionProviderDto } from './dto/pension-provider.dto'
import { PreferenceDto, UpdatePreferenceDto } from './dto/preference.dto'
import {
  BudgetDto,
  CedingProviderDto, ContributionBankAccountDto, EmployerDto, LifeExpectancyDto,
  NationalityDto,
  SampleFileDto,
  StateBenefitDto, StatePensionDto
} from './dto/reference-data.dto'
import {
  BulkTransferRetirementAssetDto,
  CreateRetirementAssetDto,
  RetirementAssetDto,
  RetirementAssetPolicyDetailsDto,
  RetirementAssetSetInterestDto,
  RetirementAssetTracingStatus,
  RetirementAssetTransferDto,
  RetirementAssetTransferFilterDto,
  RetirementAssetTransferStatus,
  RetirementAssetUntransferableReason,
  TransferRetirementAssetDto,
  UpdateRetirementAssetDto,
  UpdateRetirementAssetTransferInformationDto,
} from './dto/retirement-asset.dto'
import {
  CreateRetirementIncomeDto,
  RetirementIncomeDto,
  UpdateRetirementIncomeDto
} from './dto/retirement-income.dto'
import {
  CreateRetirementProfileDto,
  RetirementProfileDto,
  UpdateRetirementProfileDto,
} from './dto/retirement-profile.dto'
import { FinancialStatDto, StatsEntryDto } from './dto/stats.dto'
import { StatusDto } from './dto/status.dto'
import { CreateUserDto, CreateUserMeDto, MeAddTokenDto, UpdateCurrentUserDto, UpdateCurrentUserSupportAccessDto, UpdateUserDto, UserDto, UserFilterDto, UserSupportAccessDto } from './dto/user.dto'
import { CloseMultipleExceptionsDto, ExceptionDto, ExceptionFilterDto } from './dto/exception.dto'

const mutex = new Mutex()
const { apiUrl } = envVariables

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: addHeaders
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result: any = await baseQuery(args, api, extraOptions)

  //Note network issues
  if (result?.error?.status === 'FETCH_ERROR') {
    api.dispatch(setNetworkUnavailable(true))
    return {
      error: {
        data: {
          developerMessage: 'No connection to the internet',
          userMessage: 'No connection to the internet',
          code: 'FETCH_ERROR'
        },
      }
    }
  }

  //Detect successful call to status to clear serverUnavailable and networkUnavailable...
  //This feels very hacky - need to find a different way
  //to "sync" the result of status with serverUnavailable state
  //or to invalidate status when getting a 503
  if (args === 'status' || args === '/status') {
    if (result.data?.data) {
      // Logger.debug(`Clearing networkUnavailable and serverUnavailable...`)
      api.dispatch(setNetworkUnavailable(false))
      api.dispatch(setServerUnavailable(false))
    }
  }

  //Local dev mainenance mode
  if (result.error && result.error.data?.code === 503 && result.error.data?.userMessage === 'maintenance_mode') {
    api.dispatch(setNetworkUnavailable(false))
    api.dispatch(setServerUnavailable(true))
  }
  
  //Handle heroku router errors
  //This is a rather nasty hack to try and detect Heroku Router 503 responses, and categorise them into:
  //1. Maintenance mode (at Heroku level)
  //2. Timeout errors (Heroku H12 - 30 second timeout)
  //In reality, we cannot distinguush H12 (timeout) from any other 503 error (see: https://devcenter.heroku.com/articles/error-codes)
  //So, here we make an assumption that ANY 503 is a temporary error so we can provide a "less error-ey" look to users and say we
  //are busy right now.
  //This code relies on parsing the HTML 503 response from Heroku to get the source URL for the iframe into which we put custom JSON content
  //It looks at the URL and the text within decides if is is maintenance mode or not
  if (result.error && result.error.originalStatus === 503 && result.error.status === 'PARSING_ERROR') {
    try {
      const errorData = result?.error?.data
      const parsed = parse(errorData)
      const errorUrl = parsed.querySelector('iframe').getAttribute('src').toString()
      const isMaintenance = errorUrl.includes('maintenance_mode')
      api.dispatch(setNetworkUnavailable(false))
      if (isMaintenance) {
        api.dispatch(setServerUnavailable(true))
        Logger.warn(`Detected Router 503 - Maintenance Mode`)
      } else {
        Logger.warn(`Detected Router 503 - Service Timeout`)
      } 
      return {
        error: {
          data: {
            developerMessage: isMaintenance ? 'Application is set to maintenance mode' : 'Service currently unavailable',
            userMessage: isMaintenance ? 'Jarvis is currently offline' : 'Jarvis is temporarily unavailable',
            code: isMaintenance ? 'MAINTENANCE_MODE' : 'SERVICE_TIMEOUT'
          },
        }
      }
    } catch(error) {
      Logger.debug({ error }, 'Could not parse or decipher 503 response')
    }
  }
  
  //Handled other types of error...

  //401 Unauthorized: attempt token refresh
  if (result.error && result.error.status === 401) {
    const state: any = api.getState()
    const user = state?.auth?.user
    const accessToken = user?.token
    const refreshToken = user?.refreshToken
    if (!refreshToken) {
      Logger.warn(`No refresh token in state`)
      // captureException(new Error('No refresh token in state'))
    } else {
      //User authentication error - try to refresh token
      Logger.info(`Found old refresh token`)
      //Logger.debug({ oldAccessToken: accessToken, oldRefreshToken: refreshToken }, `Detected 401 - attempting token refresh`)
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const refreshResult = await baseQuery({
            url: `token-refresh`,
            method: 'POST',
            body: {
              refreshToken,
            },
          },
            api,
            extraOptions
          )
          // Logger.debug({ refreshResult }, `Refresh result`)
          const data: any = refreshResult.data
          if (data && data?.access_token && data?.refresh_token) {
            Logger.info(`Got new access and refresh token`)
            const tokenSet = {
              accessToken: data?.access_token,
              refreshToken: data?.refresh_token
            }
            //Logger.debug({ newAccessToken: tokenSet?.accessToken, newRefreshToken: tokenSet?.refreshToken }, `apiSlice token refresh succeeded`)

            //Set user, preserving any impersonation
            const currentImpersonation = user?.impersonation
            await setUserWithToken(tokenSet, 'device-token', api.dispatch, currentImpersonation)
            // retry the initial query
            Logger.info(`Retrying query...`)
            result = await baseQuery(args, api, extraOptions)
          } else {
            Logger.warn(`Token refresh failed - forcing logout...`)
            // captureException(new Error('Token refresh failed'))
            goToLogout()
          }
        } catch (error) {
          Logger.error({ error }, `Error during refresh!`)
          captureException(new Error('Error during refresh!'),{
            extra: {
              error
            },
          })
        } finally {
          Logger.info(`Releasing mutex...`)
          // release must be called once the mutex should be released again.
          await release()
        }
      } else {
        // wait until the mutex is available without locking it
        Logger.info(`Waiting for mutex unlock...`)
        await mutex.waitForUnlock()
        Logger.info(`Retrying...`)
        result = await baseQuery(args, api, extraOptions)
      }
    }
  }
  return result
}

const isFetchError = (error) => {
  return error?.status === 'FETCH_ERROR'
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'FETCH_ERROR', //Abstract tag to mark fetch errors in the cache
    'Affiliate',
    'AffiliateByCode',
    'Asset',
    'AssetCategory',
    'BankConnection',
    'ContributionsIllustration',
    'Client',
    'ClientSignature',
    'MeSchemeInvites',
    'Invite',
    'OpenInvite',
    'OpenSchemeInvite',
    'InvestmentPlan',
    'GroupPortfolio',
    'GroupOrganization',
    'GroupOrganizationMandate',
    'GroupScheme',
    'GroupSchemeJob',
    'GroupSchemePayment',
    'GroupSchemeRequest',
    'GroupSchemeInvite',
    'GroupSchemeMember',
    'InviteAsTarget',
    'InviteAssets',
    'InviteIncomes',
    'Message',
    'Model',
    'Account',
    'Enrolment',
    'EnrolmentRequests',
    'Beneficiaries',
    'Contribution',
    'PensionProvider',
    'PensionBrand',
    'PendingSchemeEnrolment',
    'Employers',
    'Preference',
    'ReferenceData',
    'RetirementAsset',
    'RetirementAssetAsTransfer',
    'RetirementAssetPolicyDocument',
    'RetirementAssetSuggestedProviders',
    'RetirementIncome',
    'RetirementProfile',
    'Scenarios',
    'Status',
    'Timeout',
    'User',
    'UserSupportAccess',
    'ViableAge',
    'Stats',
    'Feature',
    'UserFeature',
    'Calculator',
    'Exception',
    'SampleFile'
  ],
  endpoints: build => ({
    //Dummy mutation to trigger invalidation of fetch error results
    refetchErroredQueries: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['FETCH_ERROR'],
    }),
    //Status
    getStatus: build.query<StatusDto, void>({
      query: () => `/status`,
      transformResponse: extractData,
      providesTags: (result, error, id) => [{type: 'Status', id: 'SYSTEM'}],
    }),
    //Timeout
    getTimeout: build.query<any, void>({
      query: () => `/timeout`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Timeout', id: 'SYSTEM'}],
    }),
    //Client / Spouse
    getClients: build.query<ClientAnyDto[], Partial<ClientFilterDto>>({
      query: (attributes) => {
        return {
          url: 'clients/',
          params: attributes,
        }
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'Client', id} as const)
              ),
              {type: 'Client', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Client', id: 'LIST'}]
    }),    
    getClient: build.query<ClientAnyDto, string>({
      query: id => `/clients/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>      
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Client', id}]
    }),
    updateClientVerificationResult: build.mutation<any, ClientAmlUpdateResultDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `clients/${id}/verification-result`,
          method: 'PUT',
          body: wrapData(attributes, 'verification-result')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => [
        {type: 'Client', id: entity.id },
        {type: 'Client', id: 'LIST'},
      ]
    }),
    deleteClientVerificationResult: build.mutation<void, string>({
      query(id) {
        return {
          url: `clients/${id}/verification-result`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'Client', id },
        {type: 'Client', id: 'LIST'},
      ]
    }),
    verifyClient: build.mutation<ClientAnyDto, string>({
      query(id) {
        return {
          url: `clients/${id}/verify`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Client', id },
        {type: 'Client', id: 'LIST'},
      ]
    }),
    updateBusinessVerificationResult: build.mutation<any, ClientBusinessVerificationUpdateResultDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `clients/${id}/business-verification-result`,
          method: 'PUT',
          body: wrapData(attributes, 'business-verification-result')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => [
        {type: 'Client', id: entity.id },
        {type: 'Client', id: 'LIST'},
      ]
    }),
    deleteBusinessVerificationResult: build.mutation<void, string>({
      query(id) {
        return {
          url: `clients/${id}/business-verification-result`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'Client', id },
        {type: 'Client', id: 'LIST'},
      ]
    }),
    getMe: build.query<ClientMeDto, void>({
      query: () => `/clients/me`,
      transformResponse: response => {
        //Custom transform to declare as NOT spouse
        const flattened = extractData(response)
        return {
          ...flattened,
          isSpouse: false
        }
      },
      providesTags: (result, error, id) => 
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Client', id: 'ME'}]
    }),
    updateMe: build.mutation<ClientMeDto, UpdateClientMeDto>({
      query(attributes) {
        return {
          url: `clients/me`,
          method: 'PATCH',
          body: wrapData(attributes, 'client')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getMe', undefined, (draft) => {
            mergeEntity(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => {
        //Any change always invalidates client
        const baseTags = [
          {type: 'Client', id: 'ME'},
          {type: 'ContributionsIllustration'}, //Invalidate all contributions illustrations
        ]
        //Changes to these attributes also invalidate spouse, model and scenarios
        const additionalTags = entityContainsAttributes(entity, ['gender', 'birthDate', 'statePensionAmount'])
          ? [
              {type: 'Client', id: 'SPOUSE'},
              {type: 'ViableAge', id: 'ME'},
              {type: 'Scenarios'}, //Invalidate all scenario sets 
            ]
          : []
        const allTags = concat(baseTags, additionalTags)
        return allTags
      }
    }),    
    verifyMe: build.mutation<ClientMeDto, void>({
      query(attributes) {
        return {
          url: `clients/me/verify`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Client', id: 'ME'},
      ]
    }),
    createMe: build.mutation<ClientMeDto, CreateClientMeDto>({
      query(attributes) {
        return {
          url: `clients/me`,
          method: 'PUT',
          body: wrapData(attributes, 'client')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Client', id: 'ME'},
        {type: 'Client', id: 'SPOUSE'}, //This is required so that when accepting an invite in onboarding, the spouse gets reloaded
        {type: 'InviteAsTarget', id: 'LIST'}, //This is required to clear invites when processed in client setup
      ]
    }),
    getSignature: build.query<ClientSignatureDto, void>({
      query: () => `/clients/me/signature`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ClientSignature', id: 'ME'}]
    }),
    updateSignature: build.mutation<ClientSignatureDto, UpdateClientSignatureDto>({
      query(attributes) {
        return {
          url: `clients/me/signature`,
          method: 'PUT',
          body: wrapData(attributes, 'client-signature')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getSignature', undefined, (draft) => {
            mergeEntity(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'ClientSignature', id: 'ME'},
      ]
    }),
    getSpouse: build.query<ClientSpouseDto, void>({
      query: () => `/clients/spouse`,
      transformResponse: response => {
        if (response) {
          //Custom transform to declare as spouse
          const flattened = extractData(response)
          return {
            ...flattened,
            isSpouse: true
          }
        } else {
          return null
        }
      },
      providesTags: (result, error, id) => 
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Client', id: 'SPOUSE'}]
    }),
    updateSpouse: build.mutation<ClientSpouseDto, UpdateSpouseDto>({
      query(attributes) {
        return {
          url: `clients/spouse`,
          method: 'PATCH',
          body: wrapData(attributes, 'client')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getSpouse', undefined, (draft) => {
            mergeEntity(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => {
        //Any change always invalidates spouse
        const baseTags = [{type: 'Client', id: 'SPOUSE'}]
        //Changes to these attributes also invalidate client, model and scenarios
        const additionalTags = entityContainsAttributes(entity, ['gender', 'birthDate', 'statePensionAmount'])
          ? [
              {type: 'Client', id: 'ME'},
              {type: 'ViableAge', id: 'ME'},
              {type: 'Scenarios'}, //Invalidate all scenario sets    
            ]
          : []
        const allTags = concat(baseTags, additionalTags)
        return allTags
      }
    }),
    createSpouse: build.mutation<ClientSpouseDto, CreateSpouseDto>({
      query(attributes) {
        return {
          url: `clients/spouse`,
          method: 'PUT',
          body: wrapData(attributes, 'client')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Client', id: 'ME'},
        {type: 'Client', id: 'SPOUSE'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    deleteMe: build.mutation<void, void>({
      query(id) {
        return {
          url: `clients/me`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'Client', id: 'ME'}, //NOTE this doesn't actually seem to remove the cached data!
        {type: 'Client', id: 'SPOUSE'}, //NOTE this doesn't actually seem to remove the cached data!
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets,
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementIncome', id: 'LIST'},
        {type: 'RetirementProfile', id: 'ME'},
      ]
    }),
    deleteSpouse: build.mutation<void, void>({
      query(id) {
        return {
          url: `clients/spouse`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'Client', id: 'ME'},
        {type: 'Client', id: 'SPOUSE'}, //NOTE this doesn't actually seem to remove the cached data!
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets,
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementIncome', id: 'LIST'},
        {type: 'RetirementProfile', id: 'ME'},
        {type: 'RetirementProfile', id: 'SPOUSE'},
      ]
    }),
    checkNiNumber: build.query<NiCheckResultDto, {nationalInsuranceNo: string}>(
      {
        query: params =>
          `/clients/check-ni-number?nationalInsuranceNo=${params.nationalInsuranceNo}`,
        transformResponse: extractData,
      }
    ),
    //Preferences
    getPreferences: build.query<PreferenceDto, void>({
      query: () => `/preferences`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Preference', id: 'ME'}],
    }),
    updatePreferences: build.mutation<PreferenceDto, UpdatePreferenceDto>({
      query(attributes) {
        return {
          url: `preferences`,
          method: 'PATCH',
          body: wrapData(attributes, 'preference')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getPreferences', undefined, (draft) => {
            //Temp create preferences...
            const existing: PreferenceDto = draft || {
              type: 'preference',
              clientId: '',
              dismissals: {},
            }
            mergeEntity(existing, {
              dismissals: {
                ...existing.dismissals,
                attributes,
              }
            })
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Preference', id: 'ME'},
      ]
    }),


    //Message
    getMessages: build.query<MessageDto[], MessageQueryDto>({
      query:(attributes)=> `messages?${buildQuery(attributes)}`,
      transformResponse: extractData,
      providesTags: (result, error, attributes) => {
        const { accountId } = attributes || {}
        return isFetchError(error) ? ['FETCH_ERROR']
        : result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'Message', id} as const)
              ),
              {type: 'Message', id: accountId ? `LIST_${accountId}` : 'LIST_ALL'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Message', id: accountId ? `LIST_${accountId}` : 'LIST_ALL'}]
    }}),
    getMessage: build.query<MessageDto, string>({
      query: id => `/messages/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Message', id}]
    }),
    updateMessage: build.mutation<MessageDto, UpdateMessageDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `messages/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'message')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getMessages', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id, accountId }) => {
        return concat(
          [
            {type: 'Message', id},
            {type: 'Message', id: 'LIST_ALL'},
          ],
          accountId ? [
            {type: 'Message', id: `LIST_${accountId}`},
          ] : []
        )
      }
    }),
    requestValuationStatement: build.mutation<
      any,
      ValuationStatementRequestQueryDto
    >({
      
      query(attributes) {
        return {
          url: `messages/request-valuation-statement?${buildQuery(attributes)}`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Message'} //Invalidate everything for safety
      ]
    }),

    //Model
    getModel: build.query<ModelRepresentationDto, number>({
      query: (viableRetirementAge) => {
        return {
          url: `clients/me/model${viableRetirementAge ? `?viableRetirementAge=${viableRetirementAge}` : ``}`,
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Model', id: 'ME'}]
    }),
    getViableAge: build.query<ViableAgeRepresentationDto, void>({
      query: () => `/clients/me/model/viable-age`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ViableAge', id: 'ME'}]
    }),
    getScenarios: build.query<ScenarioModelRepresentationDto[], {
      scenarioRequest: ModelScenarioRequest,
      setId: ModelGoalIdentifier,
    }>({
      query: params => {
        const { scenarioRequest, setId } = params
        return {
          url: `/clients/me/model/scenarios`,
          method: 'POST',
          body: wrapData(scenarioRequest, 'scenario-request')
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, params) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Scenarios', id: params.setId}]
    }),

    //Features
    getFeatures: build.query<FeatureDto[], Partial<FeatureFilterDto> | void>({
      query: (attributes) => {
        return `features?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'Feature', id} as const)
              ),
              {type: 'Feature', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Feature', id: 'LIST'}]
    }), 
    getFeature: build.query<FeatureDto, string>({
      query: id => `/features/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Feature', id}]
    }),
    getUserFeatures: build.query<UserFeatureDto[], void>({
      query: () => `/features/me`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'UserFeature', id: 'ME'}],
    }),
    updateFeature: build.mutation<FeatureDto, UpdateFeatureDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `features/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'feature')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'Feature', id},
        {type: 'Feature', id: 'LIST'},
      ]
    }),

    //Account
    getUserAccounts: build.query<AccountDto[], void>({
      query: () => `/accounts/me`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Account', id: 'ME'}],
    }),
    syncUserAccounts: build.mutation<AccountDto[], void>({
      query() {
        return {
          url: `accounts/me/sync`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Account', id: 'ME'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    updateAccount: build.mutation<AccountDto, UpdateAccountDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `accounts/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'pension')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getUserAccounts', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Account', id: 'ME'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
        {type: 'ContributionsIllustration'}, //Invalidate all contributions illustrations
      ]
    }),
    getBeneficiaries: build.query<BeneficiariesDto, void>({
      query: () => `/beneficiaries/me`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Beneficiaries', id: 'ME'}],
    }),
    updateBeneficiaries: build.mutation<BeneficiariesDto, UpdateBeneficiariesDto>({
      query(attributes) {
        return {
          url: `beneficiaries/me`,
          method: 'PUT',
          body: wrapData(attributes, 'pension-beneficiaries')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getBeneficiaries', undefined, (draft) => {
            mergeEntity(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Beneficiaries', id: 'ME'},
      ]
    }),
    getGroupSchemeEnrolment: build.query<GroupSchemeEnrolmentDto, string>({
      query(id) {
        return {
          url: `scheme-enrolments/${id}`,
          method: 'GET'
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Enrolment', id }],
    }),
    updateGroupSchemeEnrolment: build.mutation<GroupSchemeEnrolmentDto, UpdateSchemeEnrolmentDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `scheme-enrolments/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'scheme-enrolment')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'Enrolment', id },
        {type: 'Account'}, //Invalidate all account sets
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    checkNiNumberGroupSchemeEnrolment: build.query<NiCheckMatchesResultDto, VerifySchemeEnrolmentDto>({
      query(attributes) {
        const { id, nationalInsuranceNo } = attributes
        return {
          url: `scheme-enrolments/${id}/check-ni-number?nationalInsuranceNo=${nationalInsuranceNo}`,
        }
      },
      transformResponse: extractData,
    }),
    verifyGroupSchemeEnrolment: build.mutation<GroupSchemeEnrolmentDto, VerifySchemeEnrolmentDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `scheme-enrolments/${id}/verify`,
          method: 'POST',
          body: wrapData(attributes, 'scheme-enrolment-verify')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => [
        // {type: 'Client', id: 'ME'},
        {type: 'Account'}, //Invalidate all account sets
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    getGroupSchemeEnrolmentRequests: build.query<SchemeEnrolmentChangeRequestDto[], string>({
      query(id) {
        return {
          url: `scheme-enrolments/${id}/requests`,
          method: 'GET'
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'EnrolmentRequests', id }],
    }),
    addGroupSchemeEnrolmentRequest: build.mutation<SchemeEnrolmentChangeRequestDto, CreateSchemeEnrolmentChangeRequestDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `scheme-enrolments/${id}/requests`,
          method: 'POST',
          body: wrapData(attributes, 'scheme-enrolment-request')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        { type: 'Account' }, //Invalidate all account sets
        { type: 'EnrolmentRequests', id },
      ]
    }),
    updateGroupSchemeEnrolmentRequest: build.mutation<GroupSchemeEnrolmentDto, UpdateSchemeEnrolmentChangeRequestDto>({
      query(attributes) {
        const { enrolmentId, requestId } = attributes
        return {
          url: `scheme-enrolments/${enrolmentId}/requests/${requestId}`,
          method: 'PATCH',
          body: wrapData(attributes, 'scheme-enrolment-request')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { enrolmentId }) => [
        {type: 'Account'}, //Invalidate all account sets
        { type: 'EnrolmentRequests', id: enrolmentId },
      ]
    }),

    //Contributions
    getContributionsIllustration: build.query<ContributionsIllustrationDto, ContributionIllustrationRequestDto>({
      query: params => {
        return {
          url: `contributions/illustration-request`,
          method: 'POST',
          body: wrapData(params, 'contributions-illustration-request')
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, params) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ContributionsIllustration', id: params.investmentAmount}]
    }),
    getContribution: build.query<ContributionDto, string>({
      query: id => `contributions/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Contribution', id}]
    }),
    getUserContributions: build.query<ContributionDto[], ContributionsQueryDto>({
      query:(attributes)=> `contributions/me?${buildQuery(attributes)}`,
      transformResponse: extractData,
      providesTags: (result, error, attributes) => {
        const { accountId } = attributes || {}
        return isFetchError(error) ? ['FETCH_ERROR'] : [
          {type: 'Contribution', id: accountId ? `LIST_${accountId}` : 'LIST_ALL'}
        ]
    }}),
    addRecurringContributionAuth: build.mutation<Partial<any>, CreateContributionAuthDto>({
      query(attributes) {
        return {
          url: `contributions/recurring-payment-auth-request`,
          method: 'POST',
          body: wrapData(attributes, 'payment-auth-request')
        }
      },
      transformResponse: extractData,
    }),
    addSinglePaymentContributionAuth: build.mutation<
      Partial<any>,
      CreateContributionAuthDto
    >({
      query(attributes) {
        return {
          url: `contributions/single-payment-auth-request`,
          method: 'POST',
          body: wrapData(attributes, 'payment-auth-request')
        }
      },
      transformResponse: extractData,
    }),
    addContribution: build.mutation<{ completed: boolean }, CreateContributionDto>({
      query(attributes) {
        return {
          url: `contributions`,
          method: 'POST',
          body: wrapData(attributes, 'transaction')
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: (result, error, attributes) => {
        const { accountId } = attributes || {}
        return concat(
          [
            {type: 'Contribution', id: 'LIST_ALL'},
            {type: 'Account', id: 'ME'},
            {type: 'ViableAge', id: 'ME'},
            {type: 'Scenarios'}, //Invalidate all scenario sets
          ],
          accountId ? [
            {type: 'Contribution', id: `LIST_${accountId}`},
          ] : []
        )
      }
    }),
    createAccount: build.mutation<AccountDto, CreateAccountDto>({
      query(attributes) {
        return {
          url: `accounts`,
          method: 'POST',
          body: wrapData(attributes, 'account')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'Account'},
        {type: 'Beneficiaries', id: 'ME'}, //Invalidate because will be an error until pension is created
      ]
    }),

    //Bank Connections
    getBankConnections: build.query<BankConnectionDto[], Partial<BankConnectionFilterDto> | void>({
      query: (attributes) => {
        return `bank-connections?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'BankConnection', id} as const)
              ),
              {type: 'BankConnection', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'BankConnection', id: 'LIST'}]
    }),
    getBankConnection: build.query<BankConnectionDto, string>({
      query: id => `/bank-connections/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'BankConnection', id}]
    }),
    addBankConnection: build.mutation<BankConnectionDto, CreateBankConnectionDto>({
      query(attributes) {
        return {
          url: `bank-connections`,
          method: 'POST',
          body: wrapData(attributes, 'bank-connection')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'BankConnection', id: 'LIST'},
      ]
    }),
    updateBankConnection: build.mutation<BankConnectionDto, UpdateBankConnectionDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `bank-connections/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'bank-connection')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'BankConnection', id},
        {type: 'BankConnection', id: 'LIST'}
      ]
    }),   

    //Pension Brand
    getPensionBrands: build.query<PensionBrandDto[], Partial<PensionBrandFilterDto> | void>({
      query: (attributes) => {
        return `pension-brands?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>        
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'PensionBrand', id} as const)
              ),
              {type: 'PensionBrand', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'PensionBrand', id: 'LIST'}]
    }),
    getPensionBrand: build.query<PensionBrandDto, string>({
      query: id => `/pension-brands/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'PensionBrand', id}]
    }),
    addPensionBrand: build.mutation<PensionBrandDto, CreatePensionBrandDto>({
      query(attributes) {
        return {
          url: `pension-brands`,
          method: 'POST',
          body: wrapData(attributes, 'pension-brand')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'PensionBrand', id: 'LIST'},
      ]
    }),
    updatePensionBrand: build.mutation<PensionBrandDto, UpdatePensionBrandDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `pension-brands/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'pension-brand')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'PensionBrand', id},
        {type: 'PensionBrand', id: 'LIST'},
      ]
    }),
    deletePensionBrand: build.mutation<void, string>({
      query(id) {
        return {
          url: `pension-brands/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'PensionBrand', id},
        {type: 'PensionBrand', id: 'LIST'},
      ]
    }),

    //Pension Provider
    getPensionProviders: build.query<PensionProviderDto[], Partial<PensionProviderFilterDto> | void>({
      query: (attributes) => {
        return `pension-providers?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'PensionProvider', id} as const)
              ),
              {type: 'PensionProvider', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'PensionProvider', id: 'LIST'}]
    }),
    getPensionProvider: build.query<PensionProviderDto, string>({
      query: id => `/pension-providers/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'PensionProvider', id}]
    }),
    addPensionProvider: build.mutation<PensionProviderDto, CreatePensionProviderDto>({
      query(attributes) {
        return {
          url: `pension-providers`,
          method: 'POST',
          body: wrapData(attributes, 'pension-provider')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'PensionProvider', id: 'LIST'},
      ]
    }),
    updatePensionProvider: build.mutation<PensionProviderDto, UpdatePensionProviderDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `pension-providers/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'pension-provider')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'PensionProvider', id},
        {type: 'PensionProvider', id: 'LIST'},
      ]
    }),
    deletePensionProvider: build.mutation<void, string>({
      query(id) {
        return {
          url: `pension-providers/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'PensionProvider', id},
        {type: 'PensionProvider', id: 'LIST'},
      ]
    }),

    //Asset
    getAssets: build.query<AssetDto[], Partial<AssetFilterDto> | void>({
      query: (attributes) => {
        return `assets?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'Asset', id} as const)
              ),
              {type: 'Asset', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Asset', id: 'LIST'}]
    }),
    getAsset: build.query<AssetDto, string>({
      query: id => `/assets/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Asset', id}]
    }),
    addAsset: build.mutation<AssetDto, CreateAssetDto>({
      query(attributes) {
        return {
          url: `assets`,
          method: 'POST',
          body: wrapData(attributes, 'asset')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'Asset', id: 'LIST'},
      ]
    }),
    updateAsset: build.mutation<AssetDto, UpdateAssetDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `assets/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'asset')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'Asset', id},
        {type: 'Asset', id: 'LIST'},
      ]
    }),
    deleteAsset: build.mutation<void, string>({
      query(id) {
        return {
          url: `assets/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'Asset', id},
        {type: 'Asset', id: 'LIST'},
      ]
    }),
    updateAssetFinancialData: build.mutation<AssetDto, string>({
      query(id) {
        return {
          url: `assets/${id}/update-financial-data`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Asset', id},
        {type: 'Asset', id: 'LIST'},
      ]
    }),
    updateAssetMetadata: build.mutation<AssetDto, string>({
      query(id) {
        return {
          url: `assets/${id}/update-metadata`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Asset', id},
        {type: 'Asset', id: 'LIST'},
      ]
    }),

    //Asset Categories
    getAssetCategories: build.query<AssetCategoryDto[], Partial<AssetCategoryFilterDto> | void>({
      query: (attributes) => {
        return `asset-categories?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'AssetCategory', id} as const)
              ),
              {type: 'AssetCategory', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'AssetCategory', id: 'LIST'}]
    }),
    getAssetCategory: build.query<AssetCategoryDto, string>({
      query: id => `/asset-categories/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'AssetCategory', id}]
    }),
    addAssetCategory: build.mutation<AssetCategoryDto, CreateAssetCategoryDto>({
      query(attributes) {
        return {
          url: `asset-categories`,
          method: 'POST',
          body: wrapData(attributes, 'asset-category')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'AssetCategory', id: 'LIST'},
      ]
    }),
    updateAssetCategory: build.mutation<AssetCategoryDto, UpdateAssetCategoryDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `asset-categories/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'asset-category')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'AssetCategory', id},
        {type: 'AssetCategory', id: 'LIST'},
      ]
    }),
    deleteAssetCategory: build.mutation<void, string>({
      query(id) {
        return {
          url: `asset-categories/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'AssetCategory', id},
        {type: 'AssetCategory', id: 'LIST'},
      ]
    }),

    //Investment Plan
    getInvestmentPlans: build.query<InvestmentPlanDto[], Partial<InvestmentPlanFilterDto> | void>({
      query: (attributes) => {
        return `investment-plans?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'InvestmentPlan', id} as const)
              ),
              {type: 'InvestmentPlan', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'InvestmentPlan', id: 'LIST'}]
    }),
    getInvestmentPlan: build.query<InvestmentPlanDto, string>({
      query: id => `/investment-plans/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'InvestmentPlan', id}]
    }),
    addInvestmentPlan: build.mutation<InvestmentPlanDto, CreateInvestmentPlanDto>({
      query(attributes) {
        return {
          url: `investment-plans`,
          method: 'POST',
          body: wrapData(attributes, 'investment-plan')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'InvestmentPlan', id: 'LIST'},
      ]
    }),
    updateInvestmentPlan: build.mutation<InvestmentPlanDto, UpdateInvestmentPlanDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `investment-plans/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'investment-plan')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'InvestmentPlan', id},
        {type: 'InvestmentPlan', id: 'LIST'},
      ]
    }),
    deleteInvestmentPlan: build.mutation<void, string>({
      query(id) {
        return {
          url: `investment-plans/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'InvestmentPlan', id},
        {type: 'InvestmentPlan', id: 'LIST'},
      ]
    }),

    //Group Portfolios
    getGroupPortfolios: build.query<GroupPortfolioDto[], Partial<GroupPortfolioFilterDto> | void>({
      query: (attributes) => {
        return `group-portfolios?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupPortfolio', id} as const)
              ),
              {type: 'GroupPortfolio', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupPortfolio', id: 'LIST'}]
    }),
    getGroupPortfolio: build.query<GroupPortfolioDto, string>({
      query: id => `/group-portfolios/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupPortfolio', id}]
    }),
    addGroupPortfolio: build.mutation<GroupPortfolioDto, CreateGroupPortfolioDto>({
      query(attributes) {
        return {
          url: `group-portfolios`,
          method: 'POST',
          body: wrapData(attributes, 'group-portfolio')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'GroupPortfolio', id: 'LIST'},
      ]
    }),
    updateGroupPortfolio: build.mutation<GroupPortfolioDto, UpdateGroupPortfolioDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `group-portfolios/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'group-portfolio')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'GroupPortfolio', id},
        {type: 'GroupPortfolio', id: 'LIST'},
      ]
    }),
    deleteGroupPortfolio: build.mutation<void, string>({
      query(id) {
        return {
          url: `group-portfolios/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'GroupPortfolio', id},
        {type: 'GroupPortfolio', id: 'LIST'},
      ]
    }),


    //Group Organizations
    getGroupOrganizations: build.query<GroupOrganizationDto[], Partial<GroupOrganizationFilterDto> | void>({
      query: (attributes) => {
        return `group-organizations?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupOrganization', id} as const)
              ),
              {type: 'GroupOrganization', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupOrganization', id: 'LIST'}]
    }),
    getGroupOrganization: build.query<GroupOrganizationDto, string>({
      query: id => `/group-organizations/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupOrganization', id}]
    }),
    addGroupOrganization: build.mutation<GroupOrganizationDto, CreateGroupOrganizationDto>({
      query(attributes) {
        return {
          url: `group-organizations`,
          method: 'POST',
          body: wrapData(attributes, 'group-organization')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'GroupOrganization', id: 'LIST'},
      ]
    }),
    updateGroupOrganization: build.mutation<GroupOrganizationDto, UpdateGroupOrganizationDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `group-organizations/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'group-organization')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'GroupOrganization', id},
        {type: 'GroupOrganization', id: 'LIST'},
      ]
    }),
    deleteGroupOrganization: build.mutation<void, string>({
      query(id) {
        return {
          url: `group-organizations/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'GroupOrganization', id},
        {type: 'GroupOrganization', id: 'LIST'},
      ]
    }),
    refreshGroupOrganizationApiKey: build.mutation<GroupOrganizationDto, string>({
      query(id) {
        return {
          url: `group-organizations/${id}/refresh-api-key`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'GroupOrganization', id},
        {type: 'GroupOrganization', id: 'LIST'}
      ]
    }),
    revokeGroupOrganizationApiKey: build.mutation<GroupOrganizationDto, string>({
      query(id) {
        return {
          url: `group-organizations/${id}/revoke-api-key`,
          method: 'POST',

        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'GroupOrganization', id},
        {type: 'GroupOrganization', id: 'LIST'}
      ]
    }),
    getGroupOrganizationMandate: build.query<GroupOrganizationMandateDto, string>({
      query: id => `group-organizations/${id}/mandate`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupOrganizationMandate', id}]
    }),
    addGroupOrganizationMandate: build.mutation<GroupOrganizationMandateDto, CreateGroupOrganizationMandateDto>({
      query(attributes) {
        const { id, ...remaining } = attributes
        return {
          url: `group-organizations/${id}/mandate`,
          method: 'POST',
          body: wrapData(remaining, 'group-organization-mandate')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'GroupOrganizationMandate', id},
        {type: 'GroupOrganization', id},
        {type: 'GroupOrganization', id: 'LIST'}
      ]
    }),
    deleteGroupOrganizationMandate: build.mutation<void, string>({
      query(id) {
        return {
          url: `group-organizations/${id}/mandate`,
          method: 'DELETE',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'GroupOrganizationMandate', id},
        {type: 'GroupOrganization', id},
        {type: 'GroupOrganization', id: 'LIST'}
      ]
    }),

    //Group Schemes
    getGroupSchemes: build.query<GroupSchemeDto[], Partial<GroupSchemeFilterDto> | void>({
      query: (attributes) => {
        return `group-schemes?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupScheme', id} as const)
              ),
              {type: 'GroupScheme', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupScheme', id: 'LIST'}]
    }),
    getMeGroupSchemes: build.query<GroupSchemeDto[], void>({
      query: () => {
        return `group-schemes/me`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupScheme', id} as const)
              ),
              {type: 'GroupScheme', id: 'ME'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupScheme', id: 'ME'}]
    }),
    getGroupScheme: build.query<GroupSchemeDto, string>({
      query: id => `/group-schemes/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupScheme', id}]
    }),
    addGroupScheme: build.mutation<GroupSchemeDto, CreateGroupSchemeDto>({
      query(attributes) {
        return {
          url: `group-schemes`,
          method: 'POST',
          body: wrapData(attributes, 'group-scheme')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'GroupScheme', id: 'LIST'},
      ]
    }),
    updateGroupScheme: build.mutation<GroupSchemeDto, UpdateGroupSchemeDto>({
      query(attributes) {
        const { id } = attributes
        return {
          url: `group-schemes/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'group-scheme')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'GroupScheme', id},
        {type: 'GroupScheme', id: 'LIST'},
      ]
    }),
    deleteGroupScheme: build.mutation<void, string>({
      query(id) {
        return {
          url: `group-schemes/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'GroupScheme', id},
        {type: 'GroupScheme', id: 'LIST'},
      ]
    }),
    getGroupSchemeMembers: build.query<GroupSchemeMemberDto[], GroupSchemeMemberFilterDto>({
      query: (attributes) => {
        const { groupSchemeId, ...remaining } = attributes
        return `/group-schemes/${groupSchemeId}/members/?${buildQuery(remaining)}`
      },
      transformResponse: extractData,
      providesTags: (result, error, attributes) => {
        const listQuery = buildQuery(attributes)
        return isFetchError(error)
          ? ['FETCH_ERROR']
          : result ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupSchemeMember', id} as const)
              ),
              {type: 'GroupSchemeMember', id: `LIST_${listQuery}`}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupSchemeMember', id: `LIST_${listQuery}`}]
        }
    }),
    getGroupSchemeMember: build.query<GroupSchemeMemberDto, { id: string, memberId: string}>({
      query(attributes) {
        const { id, memberId } = attributes
        return {
          url: `/group-schemes/${id}/members/${memberId}`,
          method: 'GET',
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, { id, memberId }) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupSchemeMember', memberId}]
    }),    
    getGroupSchemeRequests: build.query<GroupSchemeRequestDto[], GroupSchemeRequestFilterDto>({
      query: (attributes) => {
        const { groupSchemeId, ...remaining } = attributes
        return `/group-schemes/${groupSchemeId}/requests/?${buildQuery(remaining)}`
      },
      transformResponse: extractData,
      providesTags: (result, error, { groupSchemeId }) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupSchemeRequest', id} as const)
              ),
              {type: 'GroupSchemeRequest'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupSchemeRequest'}]
    }),
    getGroupSchemeRequest: build.query<GroupSchemeRequestDto, { id: string, requestId: string}>({
      query(attributes) {
        const { id, requestId } = attributes
        return {
          url: `/group-schemes/${id}/requests/${requestId}`,
          method: 'GET',
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, { requestId }) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupSchemeRequest', id: requestId}]
    }),
    updateGroupSchemeRequest: build.mutation<GroupSchemeRequestDto, UpdateGroupSchemeRequestDto>({
      query(attributes) {
        const { groupSchemeId, requestId } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/requests/${requestId}`,
          method: 'PATCH',
          body: wrapData(attributes, 'group-scheme-request')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getGroupSchemeRequest', undefined, (draft) => {
            mergeEntity(draft, attributes)
          })
        )
        const patchResultList = dispatch(
          api.util.updateQueryData('getGroupSchemeRequests', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(() => {
          patchResult.undo()
          patchResultList.undo()
        })
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { requestId }) => [
          {type: 'GroupSchemeRequest', id: requestId},
          {type: 'GroupSchemeRequest'},
      ]
    }),
    getGroupSchemeJobs: build.query<GroupSchemeJobDto[], GroupSchemeJobFilterDto>({
      query: (attributes) => {
        const { groupSchemeId, ...remaining } = attributes
        return `/group-schemes/${groupSchemeId}/jobs?${buildQuery(remaining)}`
      },
      transformResponse: extractData,
      providesTags: (result, error, attributes) => {
        const listQuery = buildQuery(attributes)
        return isFetchError(error)
          ? ['FETCH_ERROR']
          : result ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupSchemeJob', id} as const)
              ),
              {type: 'GroupSchemeJob', id: `LIST_${listQuery}`}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupSchemeJob', id: `LIST_${listQuery}`}]
        }
    }),
    getGroupSchemeJob: build.query<GroupSchemeJobDto, { groupSchemeId: string, jobId: string}>({
      query(attributes) {
        const { groupSchemeId, jobId } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/jobs/${jobId}`,
          method: 'GET',
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, { jobId }) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupSchemeJob', id: jobId}]
    }),
    addGroupSchemeJobWithData: build.mutation<GroupSchemeJobDto, CreateGroupSchemeJobDto>({
      query(attributes) {
        const { groupSchemeId, ...remaining } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/jobs`,
          method: 'POST',
          body: wrapData(remaining, 'group-scheme-job')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'GroupSchemeJob'},
      ]
    }),
    addGroupSchemeJobWithFile: build.mutation<GroupSchemeJobDto, { groupSchemeId: string, formData: FormData }>({
      query({ groupSchemeId, formData }) {
        return {
          url: `group-schemes/${groupSchemeId}/jobs`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { groupSchemeId }) => [
        {type: 'GroupSchemeJob'},
      ]
    }),
    bulkAcknowledgeGroupSchemeJobs: build.mutation<{ completed: boolean }, GroupSchemeJobsBulkAcknowledgeDto>({
      query(attributes) {
        const { groupSchemeId, ...remaining } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/jobs/bulk-acknowledge`,
          method: 'POST',
          body: wrapData(remaining, 'jobs-bulk-acknowledge')
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: [
        {type: 'GroupSchemeJob'},
      ]
    }),
    checkGroupSchemeJobStatus: build.mutation<GroupSchemeJobDto, GroupSchemeJobCheckStatusDto>({
      query(attributes) {
        const { groupSchemeId, jobId } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/jobs/${jobId}/check-status`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: ({ status }, error, { groupSchemeId, jobId, currentStatus }) => {
        return status !== currentStatus ? [
          {type: 'GroupSchemeJob', id: jobId},
          {type: 'GroupSchemeJob'},
          {type: 'GroupSchemePayment'}, //Because there could be a new payment
        ] : []
      },
    }),
    acknowledgeGroupSchemeJob: build.mutation<{ completed: boolean }, GroupSchemeJobAcknowledgeDto>({
      query(attributes) {
        const { groupSchemeId, jobId } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/jobs/${jobId}/acknowledge`,
          method: 'POST',
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patch = {
          id: attributes.jobId,
          resultAcknowledged: true,
        }
        const patchResult = dispatch(
          api.util.updateQueryData('getGroupSchemeJobs', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, patch)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: (result, error, { groupSchemeId, jobId }) => {
        return [
          {type: 'GroupSchemeJob', id: jobId},
          {type: 'GroupSchemeJob'},
        ]
      },
    }),
    setActionedResultsGroupSchemeJob: build.mutation<{ completed: boolean }, GroupSchemeJobSetActionedRecordsDto>({
      query(attributes) {
        const { groupSchemeId, jobId, actionedRecordIds } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/jobs/${jobId}/set-actioned-records`,
          method: 'POST',
          body: wrapData({ actionedRecordIds }, 'group-scheme-job-set-actioned-records')
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: (result, error, { jobId }) => {
        return [
          {type: 'GroupSchemeJob', id: jobId},
        ]
      },
    }),
    getGroupSchemePayments: build.query<GroupSchemePaymentDto[], GroupSchemePaymentFilterDto>({
      query: (attributes) => {
        const { groupSchemeId, ...remaining } = attributes
        return `/group-schemes/${groupSchemeId}/payments?${buildQuery(remaining)}`
      },
      transformResponse: extractData,
      providesTags: (result, error, attributes) => {
        const listQuery = buildQuery(attributes)
        return isFetchError(error)
          ? ['FETCH_ERROR']
          : result ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupSchemePayment', id} as const)
              ),
              {type: 'GroupSchemePayment', id: `LIST_${listQuery}`}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupSchemePayment', id: `LIST_${listQuery}`}]
        }
    }),
    getGroupSchemePayment: build.query<GroupSchemePaymentDto, { groupSchemeId: string, paymentId: string}>({
      query(attributes) {
        const { groupSchemeId, paymentId } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/payments/${paymentId}`,
          method: 'GET',
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, { paymentId }) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupSchemePayment', id: paymentId}]
    }),
    confirmGroupSchemePayment: build.mutation<GroupSchemePaymentDto, GroupSchemePaymentConfirmDto>({
      query(attributes) {
        const { groupSchemeId, paymentId } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/payments/${paymentId}/confirm`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { groupSchemeId, paymentId }) => {
        return [
          {type: 'GroupSchemePayment', id: paymentId},
          {type: 'GroupSchemePayment'},
          {type: 'GroupSchemeJob'}, //Invalidate all group scheme jobs
        ]
      },
    }),
    cancelGroupSchemePayment: build.mutation<GroupSchemePaymentDto, GroupSchemePaymentCancelDto>({
      query(attributes) {
        const { groupSchemeId, paymentId } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/payments/${paymentId}/cancel`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { groupSchemeId, paymentId }) => {
        return [
          {type: 'GroupSchemePayment', id: paymentId},
          {type: 'GroupSchemePayment'},
          {type: 'GroupSchemeJob'}, //Invalidate all group scheme jobs
        ]
      },
    }),
    executeGroupSchemePayment: build.mutation<GroupSchemePaymentDto, GroupSchemePaymentExecuteDto>({
      query(attributes) {
        const { groupSchemeId, paymentId, ...remaining } = attributes
        return {
          url: `group-schemes/${groupSchemeId}/payments/${paymentId}/execute`,
          method: 'POST',
          body: wrapData(remaining, 'group-scheme-payment-execution')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { groupSchemeId, paymentId }) => {
        return [
          {type: 'GroupSchemePayment', id: paymentId},
          {type: 'GroupSchemePayment'},
          {type: 'GroupSchemeJob'}, //Invalidate all group scheme jobs
        ]
      },
    }),
    addGroupSchemeInvite: build.mutation<GroupSchemeInviteDto, CreateGroupSchemeInviteDto>({
      query(attributes) {
        const { groupSchemeId, ...remaining } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites`,
          method: 'POST',
          body: wrapData(remaining, 'group-scheme-invite')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'GroupSchemeInvite', id: 'LIST'},
      ]
    }),
    importGroupSchemeInvites: build.mutation<BulkCreateGroupSchemeInviteOutputDto, { groupSchemeId: string, formData: FormData }>({
      query({ groupSchemeId, formData }) {
        return {
          url: `group-schemes/${groupSchemeId}/invites/import`,
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { groupSchemeId }) => {
        return [
          {type: 'GroupSchemeInvite'},
        ]
      },
    }),
    getGroupSchemeInvites: build.query<GroupSchemeInviteDto[], GroupSchemeInviteFilterDto>({
      query: (attributes) => {
        const { groupSchemeId, ...remaining } = attributes
        return `/group-schemes/${groupSchemeId}/invites/?${buildQuery(remaining)}`
      },
      transformResponse: extractData,
      providesTags: (result, error, attributes) => {
        const listQuery = buildQuery(attributes)
        return isFetchError(error)
          ? ['FETCH_ERROR']
          : result ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'GroupSchemeInvite', id} as const)
              ),
              {type: 'GroupSchemeInvite', id: `LIST_${listQuery}`}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'GroupSchemeInvite', id: `LIST_${listQuery}`}]
        }
    }),
    getGroupSchemeInvite: build.query<GroupSchemeInviteDto, { groupSchemeId: string, inviteId: string}>({
      query(attributes) {
        const { groupSchemeId, inviteId } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites/${inviteId}`,
          method: 'GET',
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, { inviteId }) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'GroupSchemeInvite', id: inviteId}]
    }),
    deleteGroupSchemeInvite: build.mutation<{ completed: boolean }, { groupSchemeId: string, inviteId: string}>({
      query(attributes) {
        const { groupSchemeId, inviteId } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites/${inviteId}`,
          method: 'DELETE'
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getGroupSchemeInvites', undefined, (draft) => {
            //Optimistic delete in list
            removeEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: (result, error, { groupSchemeId, inviteId }) => [
        {type: 'GroupSchemeInvite', id: inviteId},
        {type: 'GroupSchemeInvite'}
      ]
    }),
    resendGroupSchemeInvite: build.mutation<{ completed: boolean }, { groupSchemeId: string, inviteId: string}>({
      query(attributes) {
        const { groupSchemeId, inviteId } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites/${inviteId}/resend`,
          method: 'POST'
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: (result, error, { groupSchemeId, inviteId }) => [
        {type: 'GroupSchemeInvite', id: inviteId},
        {type: 'GroupSchemeInvite'}
      ]
    }),
    checkNiNumberGroupSchemeInvite: build.query<NiCheckMatchesResultDto, AcceptGroupSchemeInviteDto>({
      query(attributes) {
        const { groupSchemeId, inviteId, nationalInsuranceNo } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites/${inviteId}/check-ni-number?nationalInsuranceNo=${nationalInsuranceNo}`,
        }
      },
      transformResponse: extractData,
    }),
    acceptGroupSchemeInvite: build.mutation<PublicGroupSchemeInviteDto, AcceptGroupSchemeInviteDto>({
      query(attributes) {
        const { groupSchemeId, inviteId, ...remaining } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites/${inviteId}/accept`,
          method: 'POST',
          body: wrapData(remaining, 'group-scheme-invite-accept')
        }
      },
      invalidatesTags: (result, error, { inviteId }) => [
        { type: 'OpenSchemeInvite' },
        { type: 'MeSchemeInvites' },
      ]
    }),
    declineGroupSchemeInvite: build.mutation<PublicGroupSchemeInviteDto, DeclineGroupSchemeInviteDto>({
      query(attributes) {
        const { groupSchemeId, inviteId } = attributes
        return {
          url: `/group-schemes/${groupSchemeId}/invites/${inviteId}/decline`,
          method: 'POST'
        }
      },
      invalidatesTags: (result, error, { inviteId }) => [
        { type: 'OpenSchemeInvite' },
        { type: 'MeSchemeInvites' },
      ]
    }),

    //Reference Data
    getBudgets: build.query<
      BudgetDto[],
      {
        asCouple: boolean
        insideLondon: boolean
      }
    >({
      query: params =>
        `reference-data/budgets?asCouple=${params.asCouple}&insideLondon=${params.insideLondon}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ReferenceData', id: 'BUDGETS'}]
    }),
    getContributionBankAccount: build.query<
      ContributionBankAccountDto,
      void
    >({
      query: params =>
        `reference-data/contribution-bank-account`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ReferenceData', id: 'CONTRIBUTION_BANK_ACCOUNT'}]
    }),
    getCompany: build.query<
      CompanyDto,
      {
        id: number
      }
    >({
      query: params =>
        `reference-data/companies/${params.id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ReferenceData', id: 'COMPANY'}]
    }),
    getStateBenefit: build.query<
      StateBenefitDto,
      { birthDate: string
        gender: Gender
      }
    >({
      query: params =>
        `reference-data/state-benefit?birthDate=${params.birthDate}&gender=${params.gender}`,
      transformResponse: extractData,
      providesTags: (result, error, id) => [
        {type: 'ReferenceData', id: 'STATEBENEFITAGE'}
      ]
    }),
    getStatePensionCurrent: build.query<StatePensionDto, void>({
      query: () => `reference-data/state-pension/current`,
      transformResponse: extractData,
      providesTags: (result, error, id) => [
        {type: 'ReferenceData', id: 'STATEPENSIONCURRENT'}
      ]
    }),
    getLifeExpectancy: build.query<
      LifeExpectancyDto,
      {
        birthDate: string
        gender: Gender
        retirementDate: string
      }
    >({
      query: params => {
        return `reference-data/life-expectancy?birthDate=${params.birthDate}&gender=${params.gender}&retirementDate=${params.retirementDate}`
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ReferenceData', id: 'LIFEEXPECTANCY'}]
    }),
    getNationalities: build.query<NationalityDto[], void>({
      query: () => `reference-data/nationalities`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ReferenceData', id: 'NATIONALITIES'}]
    }),
    getCedingProviders: build.query<CedingProviderDto[], void>({
      query: () => `reference-data/ceding-providers`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'ReferenceData', id: 'CEDING_PROVIDERS'}]
    }),
    getEmployers: build.query<EmployerDto[], string>({
      query: employerName =>
        `reference-data/employers?employerName=${employerName}`,
      transformResponse: extractData,
      providesTags: (result, error, employerName) => [{type: 'Employers', employerName}]
    }),
    getSampleFile: build.query<SampleFileDto, string>({
      query: id => `reference-data/sample-files/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'SampleFile', id}]
    }),

    //Retirement Asset
    getRetirementAssets: build.query<RetirementAssetDto[], void>({
      query: () => `retirement-assets`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'RetirementAsset', id} as const)),
              {type: 'RetirementAsset', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'RetirementAsset', id: 'LIST'}]
    }),
    getRetirementAssetsAsTransfers: build.query<RetirementAssetTransferDto[], Partial<RetirementAssetTransferFilterDto>>({
      query: (attributes) => {
        return `retirement-assets/as-transfers?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'RetirementAssetAsTransfer', id} as const)),
              {type: 'RetirementAssetAsTransfer', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'RetirementAssetAsTransfer', id: 'LIST'}]
    }),
    getRetirementAsset: build.query<RetirementAssetDto, string>({
      query: id => `/retirement-assets/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'RetirementAsset', id}]
    }),
    getRetirementAssetAsTransfer: build.query<RetirementAssetTransferDto, string>({
      query: id => `/retirement-assets/${id}/as-transfer`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'RetirementAssetAsTransfer', id}]
    }),
    getRetirementAssetPolicyDocumentDetails: build.query<RetirementAssetPolicyDetailsDto, string>({
      query: id => `/retirement-assets/${id}/policy-document`,
      transformResponse: extractData,
      providesTags: (result, error, id) => [{type: 'RetirementAssetPolicyDocument', id}]
    }),
    getRetirementAssetSuggestedProviders: build.query<PensionProviderDto[], string>({
      query: id => `/retirement-assets/${id}/suggested-pension-providers`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'RetirementAssetSuggestedProviders', id}]
    }),
    addRetirementAsset: build.mutation<RetirementAssetDto, CreateRetirementAssetDto>({
      query(attributes) {
        return {
          url: `retirement-assets`,
          method: 'POST',
          body: wrapData(attributes, 'retirement-asset')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {        
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic add to list
            addEntityToList(draft, createOptimisticBaseDatedEntity({
              untransferableReason: RetirementAssetUntransferableReason.UNKNOWN,
              ...attributes,
            }, { valuationDate: undefined }))
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    updateRetirementAsset: build.mutation<RetirementAssetDto, UpdateRetirementAssetDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `retirement-assets/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'retirement-asset')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => {
        //Any change always invalidates asset and list
        const baseTags = [
          {type: 'RetirementAsset', id: entity.id},
          {type: 'RetirementAsset', id: 'LIST'},
        ]
        //Changes to these attributes also invalidate model and scenarios
        const additionalTags = entityContainsAttributes(entity, ['assetType', 'currentValue', 'monthlyContributionAmount', 'contributionSource'])
          ? [
              {type: 'ViableAge', id: 'ME'},
              {type: 'Scenarios'}, //Invalidate all scenario sets    
            ]
          : []
        const allTags = concat(baseTags, additionalTags)
        return allTags
      }
    }),
    updateRetirementAssetTransferInformation: build.mutation<RetirementAssetTransferDto, UpdateRetirementAssetTransferInformationDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `retirement-assets/${id}/transfer-information`,
          method: 'PATCH',
          body: wrapData(attributes, 'retirement-asset-transfer-information')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAssetsAsTransfers', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => [
        {type: 'RetirementAssetAsTransfer', id: entity.id},
        {type: 'RetirementAssetAsTransfer', id: 'LIST'},
      ]
    }),
    deleteRetirementAsset: build.mutation<void, string>({
      query(id) {
        return {
          url: `retirement-assets/${id}`,
          method: 'DELETE'
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic delete in list
            removeEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: (result, error, id) => [
        {type: 'RetirementAsset', id},
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    transferRetirementAsset: build.mutation<RetirementAssetDto, TransferRetirementAssetDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `retirement-assets/${id}/transfer`,
          method: 'POST',
          body: wrapData(attributes, 'retirement-asset-transfer')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementAsset', id: id},
      ]
    }),
    acknowledgeTransferRetirementAsset: build.mutation<RetirementAssetDto, string>({
      query(id) {
        return {
          url: `retirement-assets/${id}/transfer-acknowledge`,
          method: 'POST',
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patch = {
          id,
          completedTransferAcknowledged: true,
        }
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAsset', id, (draft) => {
            mergeEntity(draft, patch)
          })
        )
        const patchResultList = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, patch)
          })
        )
        queryFulfilled.catch(() => {
          patchResult.undo()
          patchResultList.undo()
        })
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementAsset', id },
      ]
    }),
    confirmProgressTransferRetirementAsset: build.mutation<RetirementAssetDto, string>({
      query(id) {
        return {
          url: `retirement-assets/${id}/transfer-confirm-progress`,
          method: 'POST',
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patch = {
          id,
          transferProgressConfirmed: true,
        }
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAsset', id, (draft) => {
            mergeEntity(draft, patch)
          })
        )
        const patchResultList = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, patch)
          })
        )
        queryFulfilled.catch(() => {
          patchResult.undo()
          patchResultList.undo()
        })
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementAsset', id },
      ]
    }),
    approveTransferRetirementAsset: build.mutation<RetirementAssetDto, string>({
      query(id) {
        return {
          url: `retirement-assets/${id}/transfer-approve`,
          method: 'POST',
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patch = {
          id,
          tracingStatus: RetirementAssetTracingStatus.COMPLETED,
          transferStatus: RetirementAssetTransferStatus.INITIATED,
          transferTermsAgreed: true,
          untransferableReason: RetirementAssetUntransferableReason.TRANSFER_ACTIVE,
        }
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAsset', id, (draft) => {
            mergeEntity(draft, patch)
          })
        )
        const patchResultList = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, patch)
          })
        )
        queryFulfilled.catch(() => {
          patchResult.undo()
          patchResultList.undo()
        })
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementAsset', id },
      ]
    }),
    cancelTransferRetirementAsset: build.mutation<RetirementAssetDto, string>({
      query(id) {
        return {
          url: `retirement-assets/${id}/transfer-cancel`,
          method: 'POST',
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patch = {
          id,
          tracingStatus: RetirementAssetTracingStatus.ABANDONED,
          transferStatus: RetirementAssetTransferStatus.ABANDONED,
        }
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementAsset', id, (draft) => {
            mergeEntity(draft, patch)
          })
        )
        const patchResultList = dispatch(
          api.util.updateQueryData('getRetirementAssets', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, patch)
          })
        )
        queryFulfilled.catch(() => {
          patchResult.undo()
          patchResultList.undo()
        })
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementAsset', id },
      ]
    }),
    bulkTransferRetirementAssets: build.mutation<RetirementAssetDto[], BulkTransferRetirementAssetDto>({
      query(attributes) {
        return {
          url: `retirement-assets/bulk-transfer`,
          method: 'POST',
          body: wrapData(attributes, 'bulk-retirement-asset-transfer')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'RetirementAsset'},
      ]
    }),
    setInterestRetirementAssets: build.mutation<{ completed: boolean }, RetirementAssetSetInterestDto>({
      query(attributes) {
        return {
          url: `retirement-assets/transfer-interest`,
          method: 'POST',
          body: wrapData(attributes, 'transfer-interest'),
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: [
        {type: 'RetirementAsset'},
      ]
    }),

    //Retirement Income
    getRetirementIncomes: build.query<RetirementIncomeDto[], void>({
      query: () => `retirement-incomes`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'RetirementIncome', id} as const)
              ),
              {type: 'RetirementIncome', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'RetirementIncome', id: 'LIST'}]
    }),
    getRetirementIncome: build.query<RetirementIncomeDto, string>({
      query: id => `/retirement-incomes/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'RetirementIncome', id}]
    }),
    addRetirementIncome: build.mutation<RetirementIncomeDto, CreateRetirementIncomeDto>({
      query(attributes) {
        return {
          url: `retirement-incomes`,
          method: 'POST',
          body: wrapData(attributes, 'retirement-income')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementIncomes', undefined, (draft) => {
            //Optimistic add to list
            addEntityToList(draft, createOptimisticBaseDatedEntity(attributes, { valuationDate: undefined }))
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'RetirementIncome', id: 'LIST'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    updateRetirementIncome: build.mutation<RetirementIncomeDto, UpdateRetirementIncomeDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `retirement-incomes/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'retirement-income')
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementIncomes', undefined, (draft) => {
            //Optimistic edit in list
            updateEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, entity) => {
        //Any change always invalidates income and list
        const baseTags = [
          {type: 'RetirementIncome', id: entity.id},
          {type: 'RetirementIncome', id: 'LIST'},
        ]
        //Changes to these attributes also invalidate model and scenarios
        const additionalTags = entityContainsAttributes(entity, ['incomeType', 'annualIncomeAmount', 'incomeStartAge'])
          ? [
              {type: 'ViableAge', id: 'ME'},
              {type: 'Scenarios'}, //Invalidate all scenario sets    
            ]
          : []
        const allTags = concat(baseTags, additionalTags)
        return allTags
      }
    }),
    deleteRetirementIncome: build.mutation<void, string>({
      query(id) {
        return {
          url: `retirement-incomes/${id}`,
          method: 'DELETE'
        }
      },
      async onQueryStarted(attributes, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getRetirementIncomes', undefined, (draft) => {
            //Optimistic delete in list
            removeEntityInListById(draft, attributes)
          })
        )
        queryFulfilled.catch(patchResult.undo)
      },
      invalidatesTags: (result, error, id) => [
        {type: 'RetirementIncome', id},
        {type: 'RetirementIncome', id: 'LIST'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),

    //Retirement Profile
    getRetirementProfile: build.query<RetirementProfileDto, void>({
      query: () => `/retirement-profile/me`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'RetirementProfile', id: 'ME'}]
    }),
    getSpouseRetirementProfile: build.query<RetirementProfileDto, void>({
      query: () => `/retirement-profile/spouse`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'RetirementProfile', id: 'SPOUSE'}]
    }),
    updateRetirementProfile: build.mutation<RetirementProfileDto, UpdateRetirementProfileDto>({
      query(attributes) {
        return {
          url: `retirement-profile/me`,
          method: 'PATCH',
          body: wrapData(attributes, 'retirement-profile')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'RetirementProfile', id: 'ME'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets
      ]
    }),
    createRetirementProfile: build.mutation<RetirementProfileDto, CreateRetirementProfileDto>({
      query(attributes) {
        return {
          url: `retirement-profile/me`,
          method: 'PUT',
          body: wrapData(attributes, 'retirement-profile')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'RetirementProfile', id: 'ME'}
      ]
    }),

    //User
    getCurrentUser: build.query<UserDto, void>({
      query: () => `users/me`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'User', id: 'CURRENT_USER'}],
    }),
    createCurrentUser: build.mutation<UserDto, CreateUserMeDto>({
      query(attributes) {
        return {
          url: `users/me`,
          method: 'PUT',
          body: wrapData(attributes, 'user')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'User', id: 'CURRENT_USER'},
      ]
    }),
    getUsers: build.query<UserDto[], Partial<UserFilterDto>>({
      query: (attributes) => `users?${buildQuery(attributes)}`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'User', id} as const)
              ),
              {type: 'User', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'User', id: 'LIST'}]
    }),
    getUser: build.query<UserDto, string>({
      query: id => `/users/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'User', id}]
    }),
    addUser: build.mutation<UserDto, CreateUserDto>({
      query(attributes) {
        return {
          url: `users`,
          method: 'POST',
          body: wrapData(attributes, 'user')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'User', id: 'LIST'},
      ]
    }),
    updateUser: build.mutation<UserDto, UpdateUserDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `users/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'user')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'User', id},
        {type: 'User', id: 'LIST'}
      ]
    }),
    updateCurrentUser: build.mutation<UserDto, UpdateCurrentUserDto>({
      query(attributes) {
        return {
          url: `users/me`,
          method: 'PATCH',
          body: wrapData(attributes, 'user')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [        
        {type: 'User', id: 'CURRENT_USER'},
        {type: 'User', id: 'LIST'}
      ]
    }),
    deleteUser: build.mutation<void, string>({
      query(id) {
        return {
          url: `users/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'User', id},
        {type: 'User', id: 'LIST'}
      ]
    }),
    deleteCurrentUser: build.mutation<void, void>({
      query(id) {
        return {
          url: `users/me`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'User', id: 'CURRENT_USER'},
      ]
    }),
    addTokenForCurrentUser: build.mutation<void, MeAddTokenDto>({
      query(attributes) {
        return {
          url: `users/me/tokens`,
          body: wrapData(attributes, 'user-token'),
          method: 'POST',
        }
      }
    }),
    deleteTokenForCurrentUser: build.mutation<void, string>({
      query(token) {
        return {
          url: `users/me/tokens/${token}`,
          method: 'DELETE',
        }
      }
    }),
    blockUser: build.mutation<UserDto, string>({
      query(id) {
        return {
          url: `users/${id}/block`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'User', id},
        {type: 'User', id: 'LIST'}
      ]
    }),
    unblockUser: build.mutation<UserDto, string>({
      query(id) {
        return {
          url: `users/${id}/unblock`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'User', id},
        {type: 'User', id: 'LIST'}
      ]
    }),
    getUserSupportAccess: build.query<UserSupportAccessDto, string>({
      query: id => `/users/${id}/support-access`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'UserSupportAccess', id}]
    }),
    getCurrentUserSupportAccess: build.query<UserSupportAccessDto, void>({
      query: () => `users/me/support-access`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'UserSupportAccess', id: 'CURRENT_USER'}],
    }),
    updateCurrentUserSupportAccess: build.mutation<UserSupportAccessDto, UpdateCurrentUserSupportAccessDto>({
      query(attributes) {
        return {
          url: `users/me/support-access`,
          method: 'PUT',
          body: wrapData(attributes, 'user-support-access')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error) => [
        {type: 'UserSupportAccess', id: 'CURRENT_USER'}
      ]
    }),
    deleteCurrentUserSupportAccess: build.mutation<void, void>({
      query() {
        return {
          url: `users/me/support-access`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'UserSupportAccess', id: 'CURRENT_USER'}
      ]
    }),
    getMeSchemeInvites: build.query<PublicGroupSchemeInviteDto[], void>({
      query: () => `/users/me/scheme-invites`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'MeSchemeInvites', id: 'ME'}]
    }),

    //Invites
    getInvites: build.query<InviteDto[], void>({
      query: () => `invites`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'Invite', id} as const)),
              {type: 'Invite', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Invite', id: 'LIST'}]
    }),
    getInvitesAsTarget: build.query<InviteDto[], void>({
      query: () => `invites/as-target`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'InviteAsTarget', id} as const)),
              {type: 'InviteAsTarget', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'InviteAsTarget', id: 'LIST'}]
    }),
    declineAllInvites: build.mutation<{ completed: boolean }, void>({
      query() {
        return {
          url: `invites/as-target/decline-all`,
          method: 'POST',
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },

      invalidatesTags: (result, error) => [
        {type: 'InviteAsTarget', id: 'LIST'}
      ]
    }),
    getInvite: build.query<InviteDto, string>({
      query: id => `/invites/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) => [{type: 'Invite', id}]
    }),
    getInviteAssets: build.query<RetirementAssetDto[], string>({
      query: id => `invites/${id}/assets`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'InviteAssets', id} as const)),
              {type: 'InviteAssets', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'InviteAssets', id: 'LIST'}]
    }),
    getInviteIncomes: build.query<RetirementIncomeDto[], string>({
      query: id => `invites/${id}/incomes`,
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'InviteIncomes', id} as const)),
              {type: 'InviteIncomes', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'InviteIncomes', id: 'LIST'}]
    }),
    addInvite: build.mutation<InviteDto, CreateInviteDto>({
      query(attributes) {
        return {
          url: `invites`,
          method: 'POST',
          body: wrapData(attributes, 'invite')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'Invite', id: 'LIST'},
      ]
    }),
    deleteInvite: build.mutation<void, string>({
      query(id) {
        return {
          url: `invites/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (result, error, id) => [
        {type: 'Invite', id},
        {type: 'Invite', id: 'LIST'},
      ]
    }),
    resendInvite: build.mutation<InviteDto, string>({
      query(id) {
        return {
          url: `invites/${id}/resend`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Invite', id},
        {type: 'Invite', id: 'LIST'}
      ]
    }),
    declineInvite: build.mutation<InviteDto, string>({
      query(id) {
        return {
          url: `invites/${id}/decline`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Invite', id},
        {type: 'InviteAsTarget', id: 'LIST'},
      ]
    }),
    acceptInvite: build.mutation<InviteDto, AcceptInviteDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `invites/${id}/accept`,
          method: 'POST',
          body: wrapData(attributes, 'invite-accept')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'InviteAsTarget', id: 'LIST'},
        {type: 'ViableAge', id: 'ME'},
        {type: 'Scenarios'}, //Invalidate all scenario sets,
        //These are required so that when accepting an invite, the client and spouse get reloaded (changes to survivor dates, etc.)
        {type: 'Client', id: 'ME'},
        {type: 'Client', id: 'SPOUSE'},
        {type: 'RetirementProfile', id: 'SPOUSE'},
        {type: 'RetirementAsset', id: 'LIST'},
        {type: 'RetirementIncome', id: 'LIST'},
      ]
    }),
    //Affiliates
    getAffiliates: build.query<AffiliateDto[], Partial<AffiliateFilterDto> | void>({
      query: (attributes) => {
        return `affiliates?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(
                ({id}) => ({type: 'Affiliate', id} as const)
              ),
              {type: 'Affiliate', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Affiliate', id: 'LIST'}]
    }),
    getAffiliate: build.query<AffiliateDto, string>({
      query: id => `/affiliates/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Affiliate', id}]
    }),
    addAffiliate: build.mutation<AffiliateDto, CreateAffiliateDto>({
      query(attributes) {
        return {
          url: `affiliates`,
          method: 'POST',
          body: wrapData(attributes, 'affiliate')
        }
      },
      transformResponse: extractData,
      invalidatesTags: [
        {type: 'Affiliate', id: 'LIST'},
      ]
    }),
    updateAffiliate: build.mutation<AffiliateDto, UpdateAffiliateDto>({
      query(attributes) {
        const {id} = attributes
        return {
          url: `affiliates/${id}`,
          method: 'PATCH',
          body: wrapData(attributes, 'affiliate')
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, { id }) => [
        {type: 'Affiliate', id},
        {type: 'Affiliate', id: 'LIST'}
      ]
    }),
    refreshAffiliateApiKey: build.mutation<AffiliateDto, string>({
      query(id) {
        return {
          url: `affiliates/${id}/refresh-api-key`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Affiliate', id},
        {type: 'Affiliate', id: 'LIST'}
      ]
    }),
    revokeAffiliateApiKey: build.mutation<AffiliateDto, string>({
      query(id) {
        return {
          url: `affiliates/${id}/revoke-api-key`,
          method: 'POST',

        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Affiliate', id},
        {type: 'Affiliate', id: 'LIST'}
      ]
    }),

    //Public
    getAffiliateByCode: build.query<AffiliateDto, string>({
      query: id => `/affiliate-by-code/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'AffiliateByCode', id}]
    }),
    getOpenInviteById: build.query<InviteDto, string>({
      query: id => `/open-invite/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'OpenInvite', id}]
    }),
    getOpenSchemeInviteById: build.query<PublicGroupSchemeInviteDto, string>({
      query: id => `/open-scheme-invite/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'OpenSchemeInvite', id}]
    }),
    getPendingEnrolmentById: build.query<PublicPendingSchemeEnrolmentDto, string>({
      query: id => `/pending-scheme-enrolment/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'PendingSchemeEnrolment', id}]
    }),

    //Stats
    getStatsClient: build.query<StatsEntryDto[], { groupBy: string }>({
      query: (query) => `stats/entity-count/clients?groupBy=${query.groupBy}`,
      transformResponse: extractData,
      providesTags: (result, error, query) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Stats', id: `CLIENT_${query.groupBy}`}]
    }),
    getStatsUser: build.query<StatsEntryDto[], { groupBy: string }>({
      query: (query) => `stats/entity-count/users?groupBy=${query.groupBy}`,
      transformResponse: extractData,
      providesTags: (result, error, query) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Stats', id: `USER_${query.groupBy}`}]
    }),
    getStatsTransfer: build.query<StatsEntryDto[], { groupBy: string }>({
      query: (query) => `stats/entity-count/transfers?groupBy=${query.groupBy}`,
      transformResponse: extractData,
      providesTags: (result, error, query) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Stats', id: `TRANSFER_${query.groupBy}`}]
    }),
    getStatsFinancials: build.query<FinancialStatDto[], void>({
      query: () => `stats/financials`,
      transformResponse: extractData,
      providesTags: (result, error, query) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Stats', id: `FINANCIALS`}]
    }),

    //Calculator
    getCalculatorBudgets: build.query<
      BudgetDto[],
      {
        asCouple: boolean
        insideLondon: boolean
      }
    >({
      query: params => {
        return {
          url: `calculator/budgets?asCouple=${params.asCouple}&insideLondon=${params.insideLondon}`,
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Calculator', id: 'Budgets'}]
    }),
    getCalculatorTimeline: build.query<CalculatorTimelineDto, CalculatorTimelineRequestDto>({
      query: params => {
        return {
          url: `/calculator/timeline`,
          method: 'POST',
          body: wrapData(params, 'calculator-timeline-request')
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Calculator', id: 'Timeline'}]
    }),
    getCalculatorModel: build.query<ModelRepresentationDto, CalculatorModelRequestDto>({
      query: params => {
        return {
          url: `/calculator/model`,
          method: 'POST',
          body: wrapData(params, 'calculator-model-request')
        }
      },
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Calculator', id: 'Model'}]
    }),
    requestCalculatorReport: build.mutation<{ completed: boolean }, CalculatorSendReportRequestDto>({
      query: params => {
        return {
          url: `calculator/send-report`,
          method: 'POST',
          body: wrapData(params, 'calculator-report-request')
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
    }),

    
    //Exceptions
    getExceptions: build.query<ExceptionDto[], ExceptionFilterDto>({
      query: (attributes) => {
        return `exceptions?${buildQuery(attributes)}`
      },
      transformResponse: extractData,
      providesTags: (result, error) =>
        isFetchError(error) ? ['FETCH_ERROR'] :
        result
          ? // successful query
            [
              ...result.map(({id}) => ({type: 'Exception', id} as const)),
              {type: 'Exception', id: 'LIST'}
            ]
          : // an error occurred, but we still want to refetch this query when list is invalidated
            [{type: 'Exception', id: 'LIST'}]
    }),
    getException: build.query<ExceptionDto, string>({
      query: id => `/exceptions/${id}`,
      transformResponse: extractData,
      providesTags: (result, error, id) =>
        isFetchError(error) ? ['FETCH_ERROR'] : [{type: 'Exception', id}]
    }),
    closeException: build.mutation<ExceptionDto, string>({
      query(id) {
        return {
          url: `exceptions/${id}/close`,
          method: 'POST',
        }
      },
      transformResponse: extractData,
      invalidatesTags: (result, error, id) => [
        {type: 'Exception', id},
        {type: 'Exception', id: 'LIST'},
      ]
    }),
    closeMultipleExecptions: build.mutation<{ completed: boolean }, CloseMultipleExceptionsDto>({
      query(attributes) {
        return {
          url: `exceptions/close`,
          method: 'POST',
          body: wrapData(attributes, 'exception-close'),
        }
      },
      transformResponse: () => {
        return {
          completed: true,
        }
      },
      invalidatesTags: [
        {type: 'Exception'},
      ]
    }),
  })
})

export const {
  //Control
  useRefetchErroredQueriesMutation,
  //Status
  useGetStatusQuery,
  useLazyGetTimeoutQuery,
  useLazyGetStatusQuery,
  //Client / Spouse
  useLazyGetClientsQuery,
  useGetClientQuery,
  useGetMeQuery,
  useCreateMeMutation,
  useUpdateMeMutation,
  useVerifyMeMutation,
  useGetSignatureQuery,
  useUpdateSignatureMutation,
  useGetMeSchemeInvitesQuery,
  useGetSpouseQuery,
  useCreateSpouseMutation,
  useUpdateSpouseMutation,
  useDeleteMeMutation,
  useDeleteSpouseMutation,
  useCheckNiNumberQuery,
  useUpdateClientVerificationResultMutation,
  useDeleteClientVerificationResultMutation,
  useUpdateBusinessVerificationResultMutation,
  useDeleteBusinessVerificationResultMutation,
  useVerifyClientMutation,
  //Preference
  useGetPreferencesQuery,
  useUpdatePreferencesMutation,
  //Message
  useGetMessagesQuery,
  useGetMessageQuery,
  useUpdateMessageMutation,
  useRequestValuationStatementMutation,
  //Model
  useGetViableAgeQuery,
  useGetModelQuery,
  useLazyGetScenariosQuery,
  // useGetScenarioModelsQuery,
  useLazyGetModelQuery,
  // useLazyGetScenarioModelsQuery,
  //Pension
  useGetUserAccountsQuery,
  useGetBeneficiariesQuery,
  useSyncUserAccountsMutation,
  useLazyGetUserAccountsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useUpdateBeneficiariesMutation,
  useLazyGetContributionsIllustrationQuery,
  useAddRecurringContributionAuthMutation,
  useAddSinglePaymentContributionAuthMutation,
  useAddContributionMutation,
  useGetContributionQuery,
  useGetUserContributionsQuery,
  useGetGroupSchemeEnrolmentQuery,
  useCheckNiNumberGroupSchemeEnrolmentQuery,
  useUpdateGroupSchemeEnrolmentMutation,
  useVerifyGroupSchemeEnrolmentMutation,
  useGetContributionsIllustrationQuery,
  useGetGroupSchemeEnrolmentRequestsQuery,
  useAddGroupSchemeEnrolmentRequestMutation,
  useUpdateGroupSchemeEnrolmentRequestMutation,

  // useGetPensionTransferQuery,
  // useListPensionTransfersQuery,
  //Bank Connection
  useGetBankConnectionsQuery,
  useLazyGetBankConnectionsQuery,
  useGetBankConnectionQuery,
  useAddBankConnectionMutation,
  useUpdateBankConnectionMutation,
  //Pension Brand
  useGetPensionBrandsQuery,
  useLazyGetPensionBrandsQuery,
  useGetPensionBrandQuery,
  useAddPensionBrandMutation,
  useUpdatePensionBrandMutation,
  useDeletePensionBrandMutation,
  //Pension Provider
  useGetPensionProvidersQuery,
  useLazyGetPensionProvidersQuery,
  useGetPensionProviderQuery,
  useAddPensionProviderMutation,
  useUpdatePensionProviderMutation,
  useDeletePensionProviderMutation,
  //Employer
  useLazyGetEmployersQuery,
  //Asset
  useGetAssetsQuery,
  useLazyGetAssetsQuery,
  useGetAssetQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  //Asset Category
  useGetAssetCategoriesQuery,
  useLazyGetAssetCategoriesQuery,
  useGetAssetCategoryQuery,
  useAddAssetCategoryMutation,
  useUpdateAssetCategoryMutation,
  useDeleteAssetCategoryMutation,
  useUpdateAssetFinancialDataMutation,
  useUpdateAssetMetadataMutation,
  //Investment Plan
  useGetInvestmentPlansQuery,
  useLazyGetInvestmentPlansQuery,
  useGetInvestmentPlanQuery,
  useAddInvestmentPlanMutation,
  useUpdateInvestmentPlanMutation,
  useDeleteInvestmentPlanMutation,
  //Group Portfoilio
  useGetGroupPortfoliosQuery,
  useLazyGetGroupPortfoliosQuery,
  useGetGroupPortfolioQuery,
  useAddGroupPortfolioMutation,
  useUpdateGroupPortfolioMutation,
  useDeleteGroupPortfolioMutation,
  //Group Organization
  useGetGroupOrganizationsQuery,
  useLazyGetGroupOrganizationsQuery,
  useGetGroupOrganizationQuery,
  useAddGroupOrganizationMutation,
  useUpdateGroupOrganizationMutation,
  useDeleteGroupOrganizationMutation,
  useRefreshGroupOrganizationApiKeyMutation,
  useRevokeGroupOrganizationApiKeyMutation,
  useGetGroupOrganizationMandateQuery,
  useAddGroupOrganizationMandateMutation,
  useDeleteGroupOrganizationMandateMutation,
  //Group Scheme
  useGetGroupSchemesQuery,
  useGetMeGroupSchemesQuery,
  useLazyGetGroupSchemesQuery,
  useGetGroupSchemeQuery,
  useAddGroupSchemeMutation,
  useUpdateGroupSchemeMutation,
  useDeleteGroupSchemeMutation,
  useGetGroupSchemeRequestsQuery,
  useLazyGetGroupSchemeRequestsQuery,
  useGetGroupSchemeRequestQuery,
  useUpdateGroupSchemeRequestMutation,
  useGetGroupSchemeMembersQuery,
  useLazyGetGroupSchemeMembersQuery,
  useGetGroupSchemeMemberQuery,
  useGetGroupSchemeJobsQuery,
  useLazyGetGroupSchemeJobsQuery,
  useGetGroupSchemeJobQuery,
  useAddGroupSchemeJobWithFileMutation,
  useAddGroupSchemeJobWithDataMutation,
  useBulkAcknowledgeGroupSchemeJobsMutation,
  useAcknowledgeGroupSchemeJobMutation,
  useSetActionedResultsGroupSchemeJobMutation,
  useCheckGroupSchemeJobStatusMutation,
  useGetGroupSchemePaymentsQuery,
  useLazyGetGroupSchemePaymentsQuery,
  useGetGroupSchemePaymentQuery,
  useConfirmGroupSchemePaymentMutation,
  useCancelGroupSchemePaymentMutation,
  useExecuteGroupSchemePaymentMutation,
  useAddGroupSchemeInviteMutation,
  useImportGroupSchemeInvitesMutation,
  useGetGroupSchemeInvitesQuery,
  useLazyGetGroupSchemeInvitesQuery,
  useGetGroupSchemeInviteQuery,
  useDeleteGroupSchemeInviteMutation,
  useCheckNiNumberGroupSchemeInviteQuery,
  useResendGroupSchemeInviteMutation,
  useAcceptGroupSchemeInviteMutation,
  useDeclineGroupSchemeInviteMutation,
  //Reference Data
  useGetBudgetsQuery,
  useGetContributionBankAccountQuery,
  useGetLifeExpectancyQuery,
  useGetStateBenefitQuery,
  useGetStatePensionCurrentQuery,
  useGetNationalitiesQuery,
  useGetCedingProvidersQuery,
  useLazyGetCompanyQuery,
  useGetCompanyQuery,
  useGetSampleFileQuery,
  //Retirement Asset
  useGetRetirementAssetsQuery,
  useLazyGetRetirementAssetsAsTransfersQuery,
  useGetRetirementAssetQuery,
  useGetRetirementAssetAsTransferQuery,
  useGetRetirementAssetPolicyDocumentDetailsQuery,
  // useGetRetirementAssetSuggestedProvidersQuery,
  useLazyGetRetirementAssetSuggestedProvidersQuery,
  useAddRetirementAssetMutation,
  useUpdateRetirementAssetMutation,
  useUpdateRetirementAssetTransferInformationMutation,
  useDeleteRetirementAssetMutation,
  useTransferRetirementAssetMutation,
  useAcknowledgeTransferRetirementAssetMutation,
  useConfirmProgressTransferRetirementAssetMutation,
  useApproveTransferRetirementAssetMutation,
  useCancelTransferRetirementAssetMutation,
  useBulkTransferRetirementAssetsMutation,
  useSetInterestRetirementAssetsMutation,
  //Retirement Income
  useGetRetirementIncomesQuery,
  useGetRetirementIncomeQuery,
  useAddRetirementIncomeMutation,
  useUpdateRetirementIncomeMutation,
  useDeleteRetirementIncomeMutation,
  //Retirement Profile
  useGetRetirementProfileQuery,
  useGetSpouseRetirementProfileQuery,
  useCreateRetirementProfileMutation,
  useUpdateRetirementProfileMutation,
  //User
  useGetCurrentUserQuery,
  useCreateCurrentUserMutation,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useUpdateCurrentUserMutation,
  useDeleteUserMutation,
  useDeleteCurrentUserMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useGetCurrentUserSupportAccessQuery,
  useGetUserSupportAccessQuery,
  useUpdateCurrentUserSupportAccessMutation,
  useDeleteCurrentUserSupportAccessMutation,
  useAddTokenForCurrentUserMutation,
  useDeleteTokenForCurrentUserMutation,
  //Invites
  useGetInvitesQuery,
  useGetInvitesAsTargetQuery,
  useGetInviteQuery,
  useGetInviteAssetsQuery,
  useGetInviteIncomesQuery,
  useAddInviteMutation,
  useDeleteInviteMutation,
  useAcceptInviteMutation,
  useDeclineInviteMutation,
  useResendInviteMutation,
  useDeclineAllInvitesMutation,
  //Affiliate
  useGetAffiliatesQuery,
  useLazyGetAffiliatesQuery,
  useGetAffiliateQuery,
  useAddAffiliateMutation,
  useUpdateAffiliateMutation,
  useRefreshAffiliateApiKeyMutation,
  useRevokeAffiliateApiKeyMutation,
  //Public
  useGetAffiliateByCodeQuery,
  useGetOpenInviteByIdQuery,
  useGetOpenSchemeInviteByIdQuery,
  useGetPendingEnrolmentByIdQuery,
  //Stats
  useGetStatsClientQuery,
  useGetStatsUserQuery,
  useGetStatsTransferQuery,
  useGetStatsFinancialsQuery,
  //Features
  useGetFeaturesQuery,
  useLazyGetFeaturesQuery,
  useGetFeatureQuery,
  useUpdateFeatureMutation,
  useGetUserFeaturesQuery,
  //Calculator
  useGetCalculatorBudgetsQuery,
  useLazyGetCalculatorTimelineQuery,
  useLazyGetCalculatorModelQuery,
  useRequestCalculatorReportMutation,
  //Exceptions
  useGetExceptionsQuery,
  useGetExceptionQuery,
  useCloseExceptionMutation,
  useCloseMultipleExecptionsMutation,
} = api
