import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useLazyGetUsersQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminUserDataId, setShowAdminAddUserVisible } from 'store/uxSlice'
import { Sizing, Paper, Flex } from 'styles'
import { AdminUserListItem } from '../Components/AdminUserListItem'
import { UserSearchBlockStatus } from 'store/dto/user.dto'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminUsersListScreen = () => {
  const [getUsersQuery, { data: users, isLoading: usersIsLoading, error: userError, isFetching: usersIsFetching }] = useLazyGetUsersQuery()
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowUserDetails = (id: string) => {
    dispatch(setCurrentAdminUserDataId(id))
  }

  const usersList = () => {
    let all = []
    if (users) {
      users.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <AdminUserListItem
        user={item}
        onPressFunction={() => handleShowUserDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{ search: string, blockType: UserSearchBlockStatus }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      blockType: UserSearchBlockStatus.ANY,
    },
  })

  const { handleSubmit, reset, watch } = formObj

  const onSubmit = attributes => {
    const isBlocked =
      attributes.blockType === UserSearchBlockStatus.BLOCKED ? true :
        attributes.blockType === UserSearchBlockStatus.NOTBLOCKED ? false :
          attributes.blockType === UserSearchBlockStatus.NEEDS_DECISION ? null :
            undefined
    getUsersQuery({
      isBlocked,
      search: attributes.search
    })
  }

  const isLoading = usersIsLoading || usersIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const handleAddUser = () => {
    dispatch(setShowAdminAddUserVisible(true))
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      blockType: UserSearchBlockStatus.ANY,
    })
  }
  
  const blockType = watch('blockType')
  const searchTermRequired = blockType === UserSearchBlockStatus.ANY

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:user']]}
      >
        {
          isLoading ? <Loading message={getLookingLoadingMessages('Finding users...')} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for user'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of email address'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                    required: searchTermRequired,
                    minLength: searchTermRequired ? {
                      value: 3,
                      message: 'Must be at least 3 characters if no other filter is set'
                    } : undefined,
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                    <ManagedAutoCompleteInput
                      name={'blockType'}
                      label={'Status'}
                      selectOnlyMode={true}
                      formObj={formObj}
                      dataSet={enumToAutocompleteOptions(UserSearchBlockStatus)}
                    />
                    <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                    <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={isWeb}
              >
                <OptionalSectionList
                  sections={usersList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for a user`}
                />
              </ScrollView>
              <FooterButton
                onPress={handleAddUser}
              >
                {'Add/invite New User'}
              </FooterButton>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
