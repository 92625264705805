import { ModalProcessScreen } from 'components/Layout'
import { BudgetTable, WrappedRetirementProfileCategoryBudget } from 'components/Utility/BudgetTable'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { RetirementBudgetCategoryEditModal } from 'features/ModalProcesses/RetirementBudgetChange/EditBudgetCategory/RetirementBudgetCategoryEditModal'
import { GROSS_TOTAL_BUDGET_ID, NET_TOTAL_BUDGET_ID } from 'lib/constants'
import { sumBy, union, uniqBy } from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetBudgetsQuery } from 'store/apiSlice'
import { BudgetLevelIdentifier } from 'store/dto/reference-data.dto'
import { RetirementProfileCategoryBudget } from 'store/dto/retirement-profile.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { Paper } from 'styles'

export const ChangeRetirementBudget_04_FineTuneChoice = ({ route, navigation }) => {
  const { nextScreen, onDismiss }  = route?.params || {}
  const [editBudget, setEditBudget] = useState(undefined)

  const dispatch = useAppDispatch()

  // const disableModalHeader = !!editBudget
  // dispatch(enableModalHeader(!disableModalHeader))

  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  const { data: budgets, error, isLoading, refetch } = useGetBudgetsQuery({
    asCouple: workingRetirementProfileData?.asCouple,
    insideLondon: workingRetirementProfileData?.insideLondon,
  }, { skip: !workingRetirementProfileData })

  const filteredBudgets = budgets.filter(budget => {
    return budget.id !== NET_TOTAL_BUDGET_ID && budget.id !== GROSS_TOTAL_BUDGET_ID
  })
  
  const possibleExpenses: RetirementProfileCategoryBudget[] = filteredBudgets.map(budget => {
    return {
      id: budget.id,
      selectedLevel: BudgetLevelIdentifier.CUSTOM,
      selectedCost: 0,
    }
  })

  const mergedExpenses = uniqBy(union(workingRetirementProfileData?.expenses || [], possibleExpenses), 'id')

  //Create a wrapped array (because id needs to be managed by react-hook-form field array)
  //And convert to monthly
  const formExpenseArray: WrappedRetirementProfileCategoryBudget[] = mergedExpenses.map(e => {
    return {
      data: {
        id: e.id,
        selectedLevel: e.selectedLevel,
        selectedCost: e.selectedCost,
      }
    }
  })

  //Setup form
  const formObj = useForm<{
    expenses: WrappedRetirementProfileCategoryBudget[],    
    customAmount: number,
   }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      expenses: formExpenseArray,
      customAmount: 0,
    }
  })
  const { handleSubmit, watch, formState: { isDirty, isValid} } = formObj  
  
  const onSubmit = attributes => {
    //Removing wrapper and convert back to annual
    const expenses = attributes.expenses.map(wrappedExpense => {
      return {
        id: wrappedExpense.data.id,
        selectedLevel: wrappedExpense.data.selectedLevel,
        selectedCost: wrappedExpense.data.selectedCost,
      }
    })
    dispatch(updateWorkingRetirementProfile({
      expenses,
    }))
    navigation.navigate(nextScreen)
  }

  const rawExpenses = watch('expenses').map(wrappedExpense => {
    return wrappedExpense.data
  })
  const netMonthlyTotal = sumBy(rawExpenses, 'selectedCost') / 12
  
  return (
    <>
      {
        editBudget ?
        <RetirementBudgetCategoryEditModal
          formObj={formObj}
          budget={editBudget}
          onDismiss={() => setEditBudget(undefined)}
          theme={Paper.darkThemeOnLightGrey}
        />
        :
        <ModalProcessScreen
          error={error}
          errorTryAgain={refetch}
          isLoading={isLoading}
          buttonTitle={'Finish & Update Budget'}
          buttonAction={handleSubmit(onSubmit)}
          showButton={true}
          enableButton={true}
          headline={`Customise categories?`}
          subHeadingInfo={<NamedInformationButton name={NamedInformation.RETIREMENT_BUDGET_ASSUMPTIONS} />}
        >
          <BudgetTable
            isLoading={isLoading}
            rows={budgets.map(budget => {
              return {
                budget,
                onPress: () => setEditBudget(budget)
              }
            })}
            expenses={rawExpenses}
            netMonthlyTotal={netMonthlyTotal}
          />
        </ModalProcessScreen>
      }
    </>
  )
}
