import { ProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { SchemeEnrolmentChangeStatusRequestAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentChangeStatusRequestAdd/SchemeEnrolmentChangeStatusRequestAddProcessStack'
import { deriveAvailableStatusChangeRequestType, deriveExtendedEnrolmentStatus, isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import { concat } from 'lodash'
import React, { useState } from 'react'
import { Image } from 'react-native'
import { GroupSchemeEnrolmentDto, GroupSchemeExtendedEnrolmentStatus, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { WorkingSchemeEnrolmentRequest, updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'

const handledRequestTypes: GroupSchemeRequestType[] = [
  GroupSchemeRequestType.OPT_IN,
  GroupSchemeRequestType.OPT_OUT,
  GroupSchemeRequestType.CEASE_MEMBERSHIP,
]

export const SchemeEnrolmentSetup_03_Adopt = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const currentRequests = workingSetupSchemeEnrolmentData?.requests || []
  const currentChangeStatusRequest = currentRequests.find(request => {
    return handledRequestTypes.includes(request.requestType)
  })

  const [addRequest, setAddRequest] = useState(false)

  const pendingRequestTypes: GroupSchemeRequestType[] = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.map(request => {
    return request.requestType
  }) : []

  const baseEnrolmentStatus = deriveExtendedEnrolmentStatus(enrolment, [])
  const requestType = deriveAvailableStatusChangeRequestType(enrolment, pendingRequestTypes)
  const withinOptOutWindow = isWithinOptOutWindow(enrolment)

  const dispatch = useAppDispatch()

  const handleAddRequest = (request: Partial<WorkingSchemeEnrolmentRequest>) => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return !handledRequestTypes.includes(request.requestType)
    }) : []
    const newRequest: WorkingSchemeEnrolmentRequest = {
      requestType: request.requestType,
      data: request.data,
    }
    const requests = concat(otherRequests, newRequest)
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests,
    }))
    setAddRequest(false)
  }

  const handleRemoveRequest = () => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return !handledRequestTypes.includes(request.requestType)
    }) : []
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests: otherRequests,
    }))
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  const end = () => {
    navigation.navigate('Results')
  }

  return (
    <>
    {
      addRequest ?
        <SchemeEnrolmentChangeStatusRequestAddProcessStack
          onDismiss={() => setAddRequest(false)}
          resultHandlerFunction={handleAddRequest}
          enrolment={enrolment}
          requestType={requestType}
        />
      :
        <ProcessScreen
          isLoading={false}
          error={undefined}
          headline={baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.ACTIVE
            ? `About your scheme membership`
            : baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.OPTED_OUT
            ? `You have previously opted out of this scheme`
            : `You have previously left this workplace scheme`
          }
          subHeading={baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.ACTIVE
            ? `Your employer has told us you are currently an active member of their pension scheme`
            : baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.OPTED_OUT
            ? `Your employer has told us that you have opted out of this pension scheme`
            : `Your employer has told us that you have have left this pension scheme`
          }
          buttonTitle={
            currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? 'Request Opt In & Continue'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? 'Request Opt Out & Finish'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? 'Request To Leave & Finish'
            : requestType === GroupSchemeRequestType.OPT_IN ? 'Confirm & Finish'
            : `Confirm Membership`
          }
          buttonAction={
            currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? next
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? end
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? end
            : requestType === GroupSchemeRequestType.OPT_IN ? end
            : next
          }
          enableButton={true}
          allowTextButton={true}
          textButtonTitle={
            currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? 'Cancel Opt In Request'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? 'Cancel Opt Out Instead'
            : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? 'Cancel Leave Request'
            : requestType === GroupSchemeRequestType.OPT_IN ? 'Request to Opt In'
            : withinOptOutWindow
              ? 'Request Opt Out'
              : 'Request to Leave'
          }
          textButtonAction={currentChangeStatusRequest
            ? handleRemoveRequest
            : () => setAddRequest(true)}
          footerInfo={
            currentChangeStatusRequest
              ? <Subheading>{`We will send a request to your employer to ${
                currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_IN ? 'opt into'
                : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.OPT_OUT ? 'opt out of'
                : currentChangeStatusRequest?.requestType === GroupSchemeRequestType.CEASE_MEMBERSHIP ? 'leave'
                : ''
              } their scheme.`}</Subheading>
              : <Subheading>{`If you${requestType === GroupSchemeRequestType.OPT_IN ? ' now' : ' do not'} wish to be part of this employer pension scheme, you may instead request to ${
                requestType === GroupSchemeRequestType.OPT_IN ? 'Opt In'
                  : withinOptOutWindow
                    ? 'Opt Out'
                    : 'Leave'
                }.`}</Subheading>
          }
        >
          <AppIllustration
            filename={baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.ACTIVE
              ? 'great_partnership.png'
              : 'info.png'
            } style={[
            {
              width: Sizing.x100,
              height: Sizing.x100,
              resizeMode: 'contain',
              alignSelf: 'center'
            },
          ]} />
          {
            baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.ACTIVE ?
              <>
                <Paragraph>{`Depending on your individual circumstances, you may have been enrolled due to:`}</Paragraph>
                <BulletItem style={{ textAlign: 'left' }}>{`Automatical enrolment based on the UK Govermnent's pension legislation`}</BulletItem>
                <BulletItem style={{ textAlign: 'left' }}>{`Elective 'Opt In' to the scheme based on an agreement with your employer.`}</BulletItem>
              </>
            : baseEnrolmentStatus === GroupSchemeExtendedEnrolmentStatus.OPTED_OUT ?
              <>
                <Paragraph>{`You may have already told your employer directly that you did not wish to join their workplace scheme, within the initial 'Opt Out' window.`}</Paragraph>
              </>
            : <>
                <Paragraph>{`You may have already told your employer directly that you wished to leave their workplace scheme, after the initial 'Opt Out' window.`}</Paragraph>
              </>
          }
        </ProcessScreen>
      }
    </>
  )
}
