import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useGetCurrentUserQuery, useGetMeQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editMeVisible, setEditCurrentUserVisible, setEditMeVisible } from 'store/uxSlice'
import { UserEditScreen } from './Screens/UserEditScreen'

export type UserEditModalProps = {
  visible: boolean
  onDismiss?: any
  theme?: any
}

export const UserEditModal = (props: UserEditModalProps) => {

  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading } = useGetCurrentUserQuery()

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditCurrentUserVisible(false))

  const visible = props.visible || useAppSelector(editMeVisible)
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  return (    
    <ModalFormBuilder
      isLoading={currentUserIsLoading}
      error={currentUserError}
      visible={visible}
      formTitle='Account Settings'
      onDismiss={onDismiss}
      screen={<UserEditScreen
        currentUser={currentUser}
        onDismiss={onDismiss}
      />}
    />
  )
}
