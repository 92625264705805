import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedAutoCompleteMultipleInput } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { PensionProviderContactDetailSet } from 'components/Utility/PensionProviderContactDetailSet'
import { PensionProviderReferenceFormatInformationSet } from 'components/Utility/PensionProviderReferenceFormatInformationSet'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { orderBy, compact } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProviderQuery, useUpdatePensionProviderMutation } from 'store/apiSlice'
import { PensionProviderContactDetail, PensionProviderReferenceFormatDetail, PensionProviderRelationship, PensionProviderRelationshipType, WrappedPensionProviderContactDetail, WrappedPensionProviderReferenceFormatDetail } from 'store/dto/pension-provider.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminPensionProviderDataId, setCurrentAdminPensionProviderDataId } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

enum SuggestionPensionTypeChoice {
  PERSONAL = 'Personal Pensions Only',
  WORKPLACE = 'Workplace Pensions Only',
  BOTH = 'Both',
  NEITHER = 'Neither',
}

export const AdminEditPensionProviderScreen = () => {
  const currentPensionProviderId = useAppSelector(currentAdminPensionProviderDataId)

  const { data: provider, isLoading: providerIsLoading, error: providerError, refetch: refetchProvider } = useGetPensionProviderQuery(currentPensionProviderId, { skip: !currentPensionProviderId } )
  const { data: brands, isLoading: brandsIsLoading, error: brandsError, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const [updatePensionProvider, { data: updatedPensionProvider, isLoading: providerUpdateIsLoading, error: providerUpdateError }] = useUpdatePensionProviderMutation()
  const dispatch = useAppDispatch()

  const formObj = useForm<{
    name: string,
    description: string,
    notes: string,
    primaryBrandKey: string,
    suggestionPensionType: SuggestionPensionTypeChoice,
    websiteUrl?: string,
    relationshipKeys?: string[],
    contactInformationNotes: string,
    wrappedContactDetails: WrappedPensionProviderContactDetail[]
    referenceFormatInformationSummary: string,
    wrappedReferenceInformationFormats: WrappedPensionProviderReferenceFormatDetail[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: provider?.name,
      description: provider?.description,
      notes: provider?.notes,
      primaryBrandKey: provider?.primaryBrandKey,
      suggestionPensionType: provider?.isEnabled
        ? provider?.providesWorkplacePensions && provider?.providesPersonalPensions ? SuggestionPensionTypeChoice.BOTH
          : provider?.providesWorkplacePensions ? SuggestionPensionTypeChoice.WORKPLACE
          : SuggestionPensionTypeChoice.PERSONAL
        : SuggestionPensionTypeChoice.NEITHER,
      websiteUrl: provider?.websiteUrl,
      relationshipKeys: provider?.relationships ? provider.relationships.map(rel => { return rel.sourceId }) : [],
      contactInformationNotes: provider?.contactInformation?.notes,
      wrappedContactDetails: provider?.contactInformation?.contactDetails
        ? provider.contactInformation.contactDetails.map(detail => {
          return {
            data: detail,
          }
        })
        : [],
      referenceFormatInformationSummary: provider?.referenceFormatInformation?.summary,
      wrappedReferenceInformationFormats: provider?.referenceFormatInformation?.formats
      ? provider.referenceFormatInformation.formats.map(format => {
        return {
          data: format,
        }
      })
      : [],
    }
  })
  const { handleSubmit, setValue, setError, watch, trigger, formState: { isDirty, isValid } } = formObj

  useEffect(() => {
    if (provider) {
      setValue('name', provider?.name)
      setValue('description', provider?.description)
      setValue('notes', provider?.notes)
      setValue('primaryBrandKey', provider?.primaryBrandKey)
      setValue('suggestionPensionType', provider?.isEnabled
        ? provider?.providesWorkplacePensions && provider?.providesPersonalPensions ? SuggestionPensionTypeChoice.BOTH
          : provider?.providesWorkplacePensions ? SuggestionPensionTypeChoice.WORKPLACE
          : SuggestionPensionTypeChoice.PERSONAL
        : SuggestionPensionTypeChoice.NEITHER)
      setValue('websiteUrl', provider?.websiteUrl)
      setValue('relationshipKeys', provider?.relationships ? provider.relationships.map(rel => { return rel.sourceId }) : [])
      setValue('contactInformationNotes', provider?.contactInformation?.notes)
      setValue('wrappedContactDetails', provider?.contactInformation?.contactDetails
        ? provider.contactInformation.contactDetails.map(detail => {
          return {
            data: detail,
          }
        })
        : [])
      setValue('referenceFormatInformationSummary', provider?.referenceFormatInformation?.summary)
      setValue('wrappedReferenceInformationFormats', provider?.referenceFormatInformation?.formats
        ? provider.referenceFormatInformation.formats.map(format => {
          return {
            data: format,
          }
        })
        : [])
    }
  }, [provider])

  //Form refs for focussing
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const notesRef = useRef(null)
  const websiteUrlRef = useRef(null)
  const contactInformationNotesRef = useRef(null)
  const referenceFormatInformationSummaryRef = useRef(null)

  const onSubmit = async attributes => {
    const {
      relationshipKeys,
      contactInformationNotes,
      wrappedContactDetails,
      referenceFormatInformationSummary,
      wrappedReferenceInformationFormats,
      suggestionPensionType,
      ...remaining
    } = attributes
    const relationships: PensionProviderRelationship[] = relationshipKeys.map(key => {
      return {
        relationshipType: PensionProviderRelationshipType.BRAND,
        source: 'internal',
        sourceId: key,
      }
    })
    const suggestionsAttributes = {
      isEnabled: suggestionPensionType !== SuggestionPensionTypeChoice.NEITHER,
      providesWorkplacePensions: [SuggestionPensionTypeChoice.NEITHER, SuggestionPensionTypeChoice.WORKPLACE].includes(suggestionPensionType),
      providesPersonalPensions: [SuggestionPensionTypeChoice.NEITHER, SuggestionPensionTypeChoice.PERSONAL].includes(suggestionPensionType),
    }
    const contactDetails: PensionProviderContactDetail[] = compact(wrappedContactDetails.map(wrapped => {
      const { telephone, email, contactUrl, portalUrl, notes } = wrapped?.data || {}      
      return telephone || email || contactUrl || portalUrl || notes ? wrapped.data : undefined
    }))
    const formats: PensionProviderReferenceFormatDetail[] = compact(wrappedReferenceInformationFormats.map(wrapped => {
      const { pattern, example } = wrapped?.data || {}      
      return pattern && example ? wrapped.data : undefined
    }))
    await updatePensionProvider({
      id: provider.id,
      relationships,
      contactInformation: {
        notes: contactInformationNotes,
        contactDetails,
      },
      referenceFormatInformation: {
        summary: referenceFormatInformationSummary,
        formats,
      },
      ...suggestionsAttributes,
      ...remaining,
    })
  }

  useEffect(() => {
    if (updatedPensionProvider) {
      close()
    }
  }, [updatedPensionProvider])

  const close = () => {
    dispatch(setCurrentAdminPensionProviderDataId(undefined))
  }

  const isLoading = providerIsLoading || brandsIsLoading ||  providerUpdateIsLoading
  const error: any = providerError || brandsError || providerUpdateError

  const { colors: themeColors } = Paper.useAppTheme()


  //Build autocomplete options
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  const suggestionPensionType = watch('suggestionPensionType')

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      errorTryAgain={providerUpdateError ? handleSubmit(onSubmit) : providerError ? refetchProvider : refetchBrands}
      isLoading={isLoading}
      loadingMessage={providerUpdateIsLoading ? ['Saving provider...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: provider?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: provider?.name,
          },
          {
            label: `Origo Identifier`,
            value: provider?.origoId,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => descriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={descriptionRef}
        name={'description'}
        formObj={formObj}
        label={'Description'}
        placeholder={'Optional description shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => notesRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={notesRef}
        name={'notes'}
        formObj={formObj}
        label={'Notes (internal)'}
        placeholder={'Internal notes'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: false,
          minLength: 1,
          maxLength: 200,
      }}/>
      <ManagedAutoCompleteInput
        name={'primaryBrandKey'}
        formObj={formObj}
        label={'Primary Pension Brand'}
        modalTitle={'Select Brand'}
        placeholder={'Select a pension brand to use for logo'}
        searchPlaceholder={'Search for a pension brand'}
        dataSet={brandOptions}
        required={true}
      />
      <Subheading>{'Brand Linkage'}</Subheading>
      <ManagedSimpleChoiceInput
        name={'suggestionPensionType'}
        formObj={formObj}
        options={enumToAutocompleteOptions(SuggestionPensionTypeChoice)}
        required={true}
      />
      <ManagedAutoCompleteMultipleInput
        name={'relationshipKeys'}
        formObj={formObj}
        label={'Linked Pension Brands'}
        modalTitle={'Select brands'}
        placeholder={'Select linked pension brands'}
        searchPlaceholder={'Search for pension brands'}
        dataSet={brandOptions}
        disabled={suggestionPensionType === SuggestionPensionTypeChoice.NEITHER}
      />
      <Subheading>{'Contact Information'}</Subheading>
      <ManagedTextInput
        ref={websiteUrlRef}
        name={'websiteUrl'}
        formObj={formObj}
        label={'Website URL'}
        placeholder={'Link to provider website'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        autoCapitalize={'none'}
        keyboardType={'url'}
        rules={{
          pattern: {
            value: /^(http|https):\/\/[^ "]+$/,
            message: "Invalid URL (include http/https)"
          },
          required: false,
        }}/>
        <ManagedTextInput
          ref={contactInformationNotesRef}
          name={'contactInformationNotes'}
          formObj={formObj}
          label={'Notes'}
          placeholder={'Contact information notes visible to end users'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoCapitalize={'none'}
          keyboardType={'url'}
          rules={{
            required: false,
            minLength: 1,
            maxLength: 200,
          }}/>
        <PensionProviderContactDetailSet
          formObj={formObj}
          name={'wrappedContactDetails'}
        />
        <Subheading>{'Reference Format Information'}</Subheading>
        <ManagedTextInput
          ref={referenceFormatInformationSummaryRef}
          name={'referenceFormatInformationSummary'}
          formObj={formObj}
          label={'Format Summary'}
          placeholder={'Summary information describing the potential format or formats of pension references.'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 1,
            maxLength: 500,
          }}/>
        <PensionProviderReferenceFormatInformationSet
          formObj={formObj}
          name={'wrappedReferenceInformationFormats'}
        />
    </ModalProcessScreen>
  )
}

