import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddAssetCategoryVisible, showAdminAddAssetCategoryVisible } from 'store/uxSlice'
import { AddAssetCategoriesScreen } from './Screens/AddAssetCategoriesScreen'

export const AddAssetCategoriesModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddAssetCategoryVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Asset Category'
      onDismiss={() => dispatch(setShowAdminAddAssetCategoryVisible(false))}
      screen={<AddAssetCategoriesScreen />}
    />
  )
}

