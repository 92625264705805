import { ProcessStackBuilder } from 'navigation/stacks/ProcessStackBuilder'
import React from 'react'
import { SchemeEnrolmentSetup_01_About, SchemeEnrolmentSetup_02_Verify, SchemeEnrolmentSetup_03_Adopt, SchemeEnrolmentSetup_04_Contributions, SchemeEnrolmentSetup_05_ForecastTreatment, SchemeEnrolmentSetup_06_PreviousPension, SchemeEnrolmentSetup_99_Result } from './Screens'

export const SchemeEnrolmentSetupProcessStack = ({ route, navigation }) => {
  return (
    <ProcessStackBuilder
      navigatorId='SchemeEnrolmentSetup'
      processTitle='Set up Employer'
      previousRoute='SchemeEnrolmentSetupIntroScreen'
      nextRoute='SchemeEnrolmentSetupHeroScreen'
      initialParams={route?.params}
      processScreens={[
        { 
          navigationId: 'About',
          title: 'About',
          component: SchemeEnrolmentSetup_01_About,
        },
        {
          navigationId: 'Verify',
          title: 'Verify',
          component: SchemeEnrolmentSetup_02_Verify,
        },
        {
          navigationId: 'Adopt',
          title: 'Adopt',
          component: SchemeEnrolmentSetup_03_Adopt,
        },
        {
          navigationId: 'ContributionInfo',
          title: 'ContributionInfo',
          component: SchemeEnrolmentSetup_04_Contributions,
        },
        {
          navigationId: 'ForecastTreatment',
          title: 'ForecastTreatment',
          component: SchemeEnrolmentSetup_05_ForecastTreatment,
        },
        {
          navigationId: 'PreviousPensionChoice',
          title: 'PreviousPensionChoice',
          component: SchemeEnrolmentSetup_06_PreviousPension,
        },
      ]}
      resultScreen={{
        navigationId: 'Results',
        component: SchemeEnrolmentSetup_99_Result,
      }}
    />
  )
}
  