import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerInviteDataId, setCurrentEmployerInviteDataId } from 'store/uxSlice'
import { EmployerInviteEditScreen } from './Screens/EmployerInviteEditScreen'

export const EmployerInviteEditModal = () => {
  const dispatch = useAppDispatch()
  const currentClient = useAppSelector(currentEmployerInviteDataId)
  return (
    <ModalFormBuilder
      visible={!!currentClient}
      formTitle='Invite Details'
      onDismiss={() => dispatch(setCurrentEmployerInviteDataId(undefined))}
      screen={<EmployerInviteEditScreen />}
    />
  )

}

