import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { JAR_NAME_ALL } from 'lib/constants'
import { isArray } from 'lodash'
import { OnboardingPhase, getSuggestedStep, useGuidanceContext } from 'providers'
import React from 'react'
import { useGetBeneficiariesQuery } from 'store/apiSlice'

export const BeneficiariesSetupHeroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const { data: beneficiaries, isLoading: beneficiariesIsLoading, error: beneficiariesError } = useGetBeneficiariesQuery()

  const nominations = beneficiaries?.nominations || []
  const hasBeneficiaries = nominations && isArray(nominations) && nominations.length > 0

  const { getOnboardingStateByPhase } = useGuidanceContext()
  const { complete, steps, suggestedStepId, completeAction, completeButtonTitle } = getOnboardingStateByPhase(fromOnboarding) || {}
  const suggestedStep = getSuggestedStep(steps, suggestedStepId)

  const carouselScreens: ProcessHeroCarouselData[] = [
    { 
      illustrationFilename: 'thanks.png',
      headline: `Thanks!`,
      subHeading: hasBeneficiaries
      ? `We've recorded your wishes and you can change them at any time in the Legacy screen`
      : `You haven't added any beneficiaries`,

    },
    { 
      illustrationFilename: 'inheritance.png',
      headline: `Inheritance`,
      subHeading: hasBeneficiaries
        ? `Your beneficiaries can be used alongside any will you hold, to decide who inherits residual savings in your ${JAR_NAME_ALL}`
        : `Any residual savings in your ${JAR_NAME_ALL} would be distributed in accordance with any will you hold, or applicable laws.`,
    },
  ]

  return (
    <ProcessHeroScreen
      isLoading={beneficiariesIsLoading}
      error={!!beneficiariesError}
      carouselScreens={carouselScreens}
      onFinish={!fromOnboarding ? goToMainAppStack : complete ? () => goToOnboardingTasks(fromOnboarding) : suggestedStep ? suggestedStep.startFunction : goToMainAppStack}
      finishButtonLabel={!fromOnboarding ? 'Finished' : complete ? 'Back to Tasks' : suggestedStep ? suggestedStep.title : 'Finished'}
      finishButtonExplanation={fromOnboarding && suggestedStep ? `Suggested Next Task:` : undefined}
      cancelButtonAction={fromOnboarding && !complete && suggestedStep ? () => goToOnboardingTasks(fromOnboarding) : undefined}
      cancelButtonText={fromOnboarding && !complete && suggestedStep ? 'Back to Tasks' : undefined}
    />
  )
}
