import { MaterialCommunityIcons } from "@expo/vector-icons"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { CardModal } from "components/Layout/CardModal"
import { Paragraph, Subheading } from "components/Typography"
import { SegmentBadge } from "components/Typography/SegmentBadge"
import { FooterButton } from 'components/Utility/FooterButton'
import { addMonths, format } from "date-fns"
import { formatCurrencyAmount, formatPercentageAmount } from "lib/generalHelpers"
import React from 'react'
import { StyleSheet, View } from "react-native"
import { ScrollView } from 'react-native'
import { ClientClientSegmentDto } from "store/dto/client.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { TieredChargeTable } from "./TieredChargeTable"
import { UnborderedTable, UnborderedTableRow } from "./UnborderedTable"
import { ContentDivider } from "components/Layout/ContentDivider"

type SegmentInfoModalProps = {
  segment: ClientClientSegmentDto
  visible: boolean
  onDismiss: any
}

export const SegmentInfoModal = (props: SegmentInfoModalProps) => {
  const { segment, visible, onDismiss } = props

  const platformChargeTiers = segment?.platformCharge?.tiers || []

  const segmentHasConditions = segment?.requiredBalance && segment?.monthsToReachRequiredBalance && segment?.dateToReachRequiredBalance

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          contentContainerStyle={{
            paddingVertical: Sizing.x20,
          }}
          showsVerticalScrollIndicator={true}
        >
          <View>
            <View style={{
              paddingVertical: Sizing.x20,
            }}>
              <JarvisLogoType size={Sizing.x60} color='black' />
              <SegmentBadge
                segment={segment}
                withoutInfoModal={true}
                containerStyle={{
                  paddingTop: Sizing.x20,
                }}
                style={{
                  fontSize: Sizing.x30,
                }}
              />
            </View>
          </View>

          <ContentDivider />
          <Subheading style={localStyles.subTitle}>{'About'}</Subheading>
          <Paragraph style={localStyles.text}>{segment?.description}</Paragraph>

          <ContentDivider />
          <Subheading style={localStyles.subTitle}>{'Annual Platform Charges'}</Subheading>
          <Paragraph style={localStyles.text}>
            {`You receive a `}
            <Paragraph style={{ fontWeight: '900' }}>{formatPercentageAmount(segment?.platformChargeDiscount * 100, 0)}</Paragraph>
            {` discount on our standard Platform Charges. ${segmentHasConditions ? `Conditions Apply *` : ''}`}
          </Paragraph>
          <TieredChargeTable
            tiers={platformChargeTiers}
            discount={segment?.platformChargeDiscount}
          />
          {
            segmentHasConditions
              ? <Paragraph style={localStyles.smallText}>{`* To continue benefiting from the ${segment?.badgeName} discount, your Jarvis balance needs to reach at least ${formatCurrencyAmount(segment?.requiredBalance, 2)} within ${segment?.monthsToReachRequiredBalance} months of becoming a client (by ${format(new Date(segment?.dateToReachRequiredBalance),'do MMMM yyyy')})`}</Paragraph>
              : <></>
          }

          <ContentDivider />
          <Subheading style={localStyles.subTitle}>{'Fund Charge'}</Subheading>
          <Paragraph style={localStyles.text}>
            {'Please note that in addition to the above, a Fund Charge is also applicable, dependent on the Jarvis Plan chosen. For a full breakdown, see the detailed information for each Jarvis Plan.'}
          </Paragraph>
          {
            segment?.termsUrl ?
              <>
                <ContentDivider />
                <Subheading style={localStyles.subTitle}>{'Full Terms'}</Subheading>
                <Paragraph style={localStyles.text}>
                  {`The following link provides the full terms for the ${segment?.name}.`}
                </Paragraph>
                <ExternalLinkButton
                  url={segment?.termsUrl}
                  usePdfViewer={true}
                >
                  {`Terms & Conditions`}
                </ExternalLinkButton>
              </>
              : <></>
          }
        </ScrollView>
        <FooterButton onPress={onDismiss} >{'Close'}</FooterButton>
      </View>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
    color: Colors.neutral.black,
  },
  smallText: {
    fontSize: Sizing.x10,
    textAlign: 'left',
  },
})