import { Headline, Subheading } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { Image, KeyboardAvoidingView, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export type ModalProcessIntroScreenProps = {
  onDismiss: any
  children?: any
  error?: any
  errorTryAgain?: any
  isLoading?: boolean
  loadingMessage?: string[]
  illustrationFilename?: string
  imageSource?: any
  headline: string
  subHeading?: string
  subHeadingInfo?: React.ReactNode
  buttonTitle: string
  buttonAction: any
  showButton?: boolean
  enableButton?: boolean
  containerStyle?: StyleProp<ViewStyle>
  headlineLines?: number
  subHeadingLines?: number
}

const isWeb = platformIsWeb()

export const ModalProcessIntroScreen = (props: ModalProcessIntroScreenProps) => {

  const { onDismiss, children, error, isLoading, loadingMessage, imageSource, illustrationFilename, headline, headlineLines, subHeading, subHeadingLines, subHeadingInfo, buttonTitle, buttonAction, showButton, enableButton, errorTryAgain, containerStyle } = props


  const { colors: themeColors } = Paper.useAppTheme()

  return (<>
    <View style={[
      layoutStyles.mainContentContainer,
      containerStyle ? containerStyle : {},
    ]} >
      {
        //Error/loading
        error || isLoading ?
          <View style={{
            ...Flex.column.start,
            paddingHorizontal: Sizing.x30,
          }}>
            <View style={{ ...Flex.override.fill }}>
              {
                error ? <ErrorScreen errorTryAgain={errorTryAgain} error={error?.data} noDashboardButton={true} /> : isLoading ? <Loading message={loadingMessage} /> : <></>
              }
            </View>
            <View style={{ height: Sizing.x90 }}></View>
          </View>

          :

          <View style={{
            ...Flex.column.start,
            // backgroundColor: 'yellow',
          }}>
            {/* <Paragraph>Helo</Paragraph> */}
            <View style={{
              ...Flex.override.fill,
              paddingHorizontal: Sizing.x30,
              // backgroundColor: 'green',
            }}>
              {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}> */}
                <ScrollView
                  nestedScrollEnabled
                  style={layoutStyles.scrollContainer}
                  contentContainerStyle={layoutStyles.scrollContainerContent}
                  keyboardShouldPersistTaps='handled'
                  showsVerticalScrollIndicator={isWeb}
                >
                  <View style={{
                    ...Flex.column.start,
                    ...Flex.override.fill,
                  }}>
                    <View>
                      {
                        imageSource
                          ? <Image source={imageSource} style={[
                              {
                                width: '100%',
                                height: Sizing.x100,
                                resizeMode: 'contain',
                                alignSelf: 'center'
                              },
                            ]} />
                          : <AppIllustration filename={illustrationFilename || 'info.png'} style={[
                              {
                                width: '100%',
                                height: Sizing.x100,
                                resizeMode: 'contain',
                                alignSelf: 'center'
                              },
                            ]} />
                      }
                    </View>
                    {headline ?
                      <View style={localStyles.headlineContainer}>
                        {headline ? <Headline adjustsFontSizeToFit numberOfLines={headlineLines || 2}>{headline}</Headline> : <></>}
                      </View>
                      : <></>
                    }
                    {subHeading || subHeadingInfo ?
                      <View style={localStyles.subHeadingContainer}>
                        {subHeading
                          ? <Subheading adjustsFontSizeToFit numberOfLines={subHeadingLines || 2}>{subHeading}</Subheading>
                          : <></>
                        }
                        {subHeadingInfo}
                      </View>
                      : <></>
                    }
                    <View style={{
                      flex: 1,
                    }}>
                      {children}
                    </View>
                  </View>
                </ScrollView>
              {/* </TouchableWithoutFeedback> */}
            </View>
            <View style={{ ...Flex.override.bottom }}>
              <FooterButton
                mode='text'
                color={themeColors.accent}
                onPress={onDismiss}
              >{'Cancel'}
              </FooterButton>
              {showButton === undefined || showButton ?
                <FooterButton
                  onPress={buttonAction}
                  disabled={enableButton !== undefined && !enableButton}
                >{buttonTitle}</FooterButton>

                : <></>
              }
            </View>
          </View>
      }
    </View>
  </>
  )
}

const localStyles = StyleSheet.create({
  headlineContainer: {
    paddingBottom: Sizing.x20,
  },
  subHeadingContainer: {
    paddingBottom: Sizing.x10,
  }
})