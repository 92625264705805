import { AutoCompleteItem } from "components/Inputs/ManagedAutoCompleteInput"
import { addDays, addMonths, addYears, differenceInYears, format, formatDistance, formatISO, formatRelative, getDate, isSameYear, isValid, parseISO, setDate, startOfDay, subDays, subYears } from "date-fns"
import { enGB } from "date-fns/esm/locale"

export const formatISODateOrUndefined = (dateString: string) => {
  if (!dateString) {
    return undefined
  }
  const date = parseISO(dateString)
  if (!isValid(date)) {
    return undefined
  }
  return format(date, 'yyyy-MM-dd')
}

export const formatUkDate = (date: Date) => {
  if (!isValid(date)) {
    return undefined
  }
  return format(date, 'dd/MM/yyyy')
}

export const formatOrdinalDay = (date: Date) => {
  if (!isValid(date)) {
    return undefined
  }
  return format(date, 'do')
}

export const convertUkDateToIso = (dateString: string) => {
  if (dateString && dateString.length === 10) {
    return parseISO(`${dateString.substring(6, 10)}-${dateString.substring(3, 5)}-${dateString.substring(0, 2)}`)
  }
  //Return an invalid date
  return parseISO('9999-99-99')
}

export const convertIsoDateStringToUkString = (dateString: string) => {
  try {
    return formatUkDate(parseISO(dateString))
  } catch (e) {
    return ''
  }

}

export const calculateAgeAtDate = (referenceDate: string, birthDate: string) => {
  return differenceInYears(parseISO(referenceDate), parseISO(birthDate))
}

export const calculateAgeNow = (birthDate: string) => {
  return differenceInYears(new Date(), parseISO(birthDate))
}
export const calculateDateAtAge = (age: number, birthDate) => {
  return formatISO(addYears(parseISO(birthDate), age), { representation: 'date' })
}

export const getActionDate = (data: any, dateOnly?: boolean, includeTime?: boolean) => {
  if(!data) {
    return ''
  }
  const date = new Date(data)
  const baseDate = new Date()
  const isThisYear = isSameYear(date, baseDate)
  var formats = {
    lastWeek: includeTime ? "do MMM '@' p" : "do MMM",
    yesterday: includeTime ? "'Yesterday @' p" : "'Yesterday'",
    today: dateOnly ? "'Today'" : "p",
    tomorrow: includeTime ? "'Tomorrow at' p": "'Tomorrow'",
    nextWeek: includeTime ? "eeee 'at' p" : 'eeee',
    other: isThisYear
      ? includeTime ? "do MMM '@' p" : "do MMM"
      : includeTime ? "do MMM yy '@' p" : "do MMM yy"
  }

  const locale = {
    ...enGB,
    formatRelative: token => formats[token],
  }
  return formatRelative(date, baseDate, { locale })
}

export const getDateWithDay = (day: number) => {
  const myDate = new Date(new Date().getFullYear(),
    new Date().getDate() > day
      ? new Date().getMonth() + 1 :
      new Date().getMonth() + 0, day)
  return format(myDate, 'do MMM yyyy')
}

export const getNextInstanceDayOfMonth = (dayOfMonth: number, referenceDate?: Date) => {
  const ref = referenceDate || new Date()
  const currentDoM = getDate(ref)
  if (dayOfMonth < currentDoM) {
    return setDate(addMonths(ref, 1), dayOfMonth)
  } else {
    return setDate(ref, dayOfMonth)
  }
}

export const getAutoCompleteDateOptions = (startDate: Date, stopDate: Date, maxDayOfMonth?: number): AutoCompleteItem[] => {
  let dateArray = []
  let currentDate = startDate
  let endDate = stopDate
  while (currentDate <= endDate) {
    if (!maxDayOfMonth || currentDate.getDate() <= maxDayOfMonth) {
      dateArray.push({
        value: formatISO(currentDate, { representation: 'date'}),
        label: format(currentDate, 'do MMMM'),
      })
    }
    currentDate = addDays(currentDate, 1)
  }
  return dateArray
}

export const getBirthDateForAge = (age: number): Date => {
  return subYears(startOfDay(new Date()), age)
}
