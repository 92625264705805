import { useFocusEffect } from '@react-navigation/native'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { JarvisLogoType } from 'components/Brand/JarvisLogoType'
import { Headline } from 'components/Typography'
import { FooterButton } from 'components/Utility/FooterButton'
import { changeAppContext, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { UserDto } from 'store/dto/user.dto'
import { useAppDispatch } from 'store/hooks'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

const isWeb = platformIsWeb()

export const EntryPointAdminScreen = ({ route, navigation }) => {
  
  const dispatch = useAppDispatch()

  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  const { colors: themeColors } = Paper.useAppTheme()

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.ADMIN, user, dispatch, false)
  })
  
  const goToLogin = () => {
    dispatch(setLoginParams({
      authType: AUTHTYPE.LOGIN,
    }))
    rootNavigate('AuthenticationProcessStack')
  }

  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={layoutStyles.mainContentContainer} >
        <View style={{
          ...Flex.override.fill,
          ...Flex.column.center,
        }}>
          <JarvisLogoType size={Sizing.x100} color={isWeb ? 'black' : 'white'} style={{
            width: Sizing.x300
          }}/>
          <Headline>{'Administration Portal'}</Headline>
        </View>
        <View style={{ ...Flex.override.bottom }}>
          <FooterButton
            mode='text'
            color={isWeb ? Colors.brand.purple1 : themeColors.accent}
            onPress={() => changeAppContext(AppContext.CLIENT, user, dispatch, true)}>
            {'Go to Jarvis Client App'}
          </FooterButton>
          <FooterButton
            onPress={user ? () => rootNavigate('AdminAppStack') : goToLogin}>
            {user ? 'Return to Admin Portal' : 'Administrator Login'}
          </FooterButton>
        </View>
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}
