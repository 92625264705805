import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { envVariables } from 'environment'
import { getGroupPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { concat, difference } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetMeQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAccount, workingAccount } from 'store/tempDataSlice'


export const InvestmentChoice_30_Terms = ({ route, navigation }) => {
  const { nextScreen, contextAccount }  = route?.params || {}
  
  const workingAccountData = useAppSelector(workingAccount)
  const { investmentPlanName, assetAllocation, groupPortfolioName, accountDescriptor } = workingAccountData || {}
  
  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchClient } = useGetMeQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching, refetch: refetchPension } = useGetUserAccountsQuery()

  const groupPension = getGroupPension(accounts)

  const segment = client?.segment
  const segmentHasTerms = segment?.termsUrl

  const isLoading = clientIsLoading || accountsIsLoading
  const error: any = clientError || accountsError

  //Temp state
  const dispatch = useAppDispatch()

  //Setup form
  const formObj = useForm<{ termsAgreed: string[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      termsAgreed: []
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingAccount({
      termsAgreed: true
    }))
    navigation.navigate(nextScreen)
  }

  enum Option {
    TERMS = 'TERMS',
    FEATURES = 'FEATURES',
    DECLARATION = 'DECLARATION',
    ILLUSTRATION = 'ILLUSTRATION',
    SEGMENT_TERMS = 'SEGMENT_TERMS',
  }

  const { personalPensionTermsUrl, personalPensionKeyFeaturesUrl, personalPensionMemberDeclarationUrl, genericPensionIllustrationUrl } = envVariables

  const options: ManagedMultipleChoiceInputOption[] = concat(
    contextAccount ? [] : [
      {
        value: Option.TERMS,
        label: `Terms & Conditions`,
        linkUrl: personalPensionTermsUrl,
        linkIsPdf: true,
      },
      {
        value: Option.FEATURES,
        label: `Key Features Document`,
        linkUrl: personalPensionKeyFeaturesUrl,
        linkIsPdf: true,
      },
      {
        value: Option.DECLARATION,
        label: `Member Declaration`,
        linkUrl: personalPensionMemberDeclarationUrl,
        linkIsPdf: true,
      },
    ],
    [
      {
        value: Option.ILLUSTRATION,
        label: `Pension Illustration`,
        linkUrl: genericPensionIllustrationUrl,
        linkIsPdf: true,
      }
    ],
    contextAccount || !segmentHasTerms ? [] : [
      {
        value: Option.SEGMENT_TERMS,
        label: `${segment?.name} Terms`,
        linkUrl: segment?.termsUrl,
        linkIsPdf: true,
      }
    ]
  )

  //Set all as required
  const requiredOptions = options.map(option => {
    return option.value
  })

  const termsAgreed = watch('termsAgreed')
  const unchecked = difference(requiredOptions, termsAgreed)
  const hasConfirmedAll = unchecked.length === 0

  const newAccountDescriptor = JAR_NAME_PERSONAL

  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetchClient}
      buttonTitle={
        hasConfirmedAll
          ? contextAccount
            ? 'Request Investment Change'
            : `Open My ${newAccountDescriptor}`
          : 'Please Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasConfirmedAll}
      headline={contextAccount ? `Ok, we're ready to make that change` : `Important Information`}
      subHeading={contextAccount
        ? `Please confirm you have read the statement and pension illustration below`
        : `Please confirm that you have reviewed and agree with the following legal disclosures`
      }
      subHeadingInfo={contextAccount ? undefined : <NamedInformationButton buttonTitle={'What are they?'} name={NamedInformation.PENSION_TERMS} />}
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'termsAgreed'}
        options={options}
        noSeparator={true}
      />
      {
        contextAccount ?
        <Paragraph>
          {
            investmentPlanName
              ? `I want to change to the ${investmentPlanName} Plan. I understand this will trigger a reinvestment of my ${accountDescriptor} into the new plan.`
              : groupPortfolioName
                ? `I want to change to the ${groupPortfolioName} Managed Portfolio. I understand this will trigger a reinvestment of my ${accountDescriptor} into the new plan.`
                : `I want to change my investments. I understand this will trigger a reinvestment of my ${accountDescriptor} into the fund${assetAllocation?.length === 1 ? `` : `s`} I have selected.`
          }
        </Paragraph>
        : <></>
      }

    </ProcessScreen>
  )
}
