import {View, Text} from 'react-native'
import React from 'react'
import {ModalFormBuilder} from 'navigation/stacks/ModalFormBuilder'
import {AdjustProportionScreen} from './Screens/AdjustProportionScreen'
import {useAppDispatch, useAppSelector} from 'store/hooks'
import {BeneficiaryRoute} from 'store/dto/account.dto'
import {
  adjustBeneficiaryProportionData,
  setAdjustBeneficiaryProportionData
} from 'store/uxSlice'

export type AdjustProportionModalProps = {
}

const AdjustProportionModal = (props: AdjustProportionModalProps) => {
  const adjustProportion = useAppSelector(adjustBeneficiaryProportionData)
  const dispatch = useAppDispatch()

  const handleDismissScreen = () => {
    dispatch(setAdjustBeneficiaryProportionData(undefined))
  }
  return (
    <ModalFormBuilder
      visible={!!adjustProportion}
      formTitle={'Adjust Proportions'}
      onDismiss={() => handleDismissScreen()}
      screen={<AdjustProportionScreen />}
    />
  )
}

export default AdjustProportionModal
