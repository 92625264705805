import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { Headline, Subheading } from 'components/Typography'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useState } from 'react'
import { Image, Pressable, ScrollView, StatusBar, StyleSheet, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext, clientHash } from 'store/authSlice'
import { UserDto } from 'store/dto/user.dto'
import { useAppSelector } from 'store/hooks'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { ProgressiveImage } from 'components/Utility/ProgressiveImage'
import { AppIllustration } from 'components/Utility/AppIllustration'

const isWeb = platformIsWeb()
// const useAuth0 = Auth0.useAuth0

export const EntryPointDefaultScreen = ({ route, navigation }) => {

  const useCalculator = !!useAppSelector(clientHash)

  const [showDownloadModal, setShowDownloadModal] = useState(null)

  // const { auth0Audience, auth0Scopes, appUrl } = envVariables
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const dispatch = useDispatch()

  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  const insets = useSafeAreaInsets()
  const verticalInset = insets.bottom + insets.top
  const screenHeight = getScreenAppHeight()
  const screenWidth = getScreenAppWidth()
  const useHorizontalLayout = isWeb && screenWidth >= 1000
  const avaiableHeight = scaleNormalizer(screenHeight - verticalInset - 250)

  const [showInfoModal, setShowInfoModal] = useState(false)

  const isLoading = false

  function Heading() {
    return (
      <Headline adjustsFontSizeToFit numberOfLines={2} style={{
        textAlign: useHorizontalLayout ? 'left' : 'center',
        color: Colors.brand.purple1,
        fontSize: Sizing.x30,
        lineHeight: Sizing.x30,
        paddingTop: Sizing.x30,
      }}>{'Pensions without the guesswork'}</Headline>
    )
  }

  function MainImage() {
    return (
      <View style={{
        flexDirection: 'row',
        justifyContent: useHorizontalLayout ? 'flex-end' : 'center',
      }}>
        <View style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
          <AppIllustration
            filename={'store_retirement_meter.png'}
            style={{
              width: scaleNormalizer(280),
              height: useHorizontalLayout ? (screenWidth - scaleNormalizer(300))/2 : avaiableHeight / 2,
              alignSelf: useHorizontalLayout ? 'flex-end' : undefined,
              paddingRight: useHorizontalLayout ? Sizing.x30 : undefined,
            }}
            resizeMode={'contain'}
          />
        </View>
      </View>
    )
  }
  

  function MainMessage() {
    return (
      <></>
    )
  }

  function SubMessage() {
    const subMessage = `Design your pension plan, know how much to save each month, and retire with confidence. It's simple with Jarvis.`

    return (
      <Subheading style={{
        textAlign: useHorizontalLayout ? 'left' : 'center',
        fontSize: Sizing.x15,
        lineHeight: Sizing.x15,
        paddingHorizontal: useHorizontalLayout ? undefined : Sizing.x15,
      }}>{subMessage}</Subheading>
    )
  }

  function IconSection() {
    const ratio = useHorizontalLayout ? 1.5 : 1
    return (
      <>
      </>
    )
  }

  function CtaButton() {
    return (
      <>
        <View style={{
          alignSelf: 'flex-start',
          paddingTop: Sizing.x30,
          width: useHorizontalLayout ? undefined : '100%'
        }}>
          {
            user
              ? <FooterButton
                  mode={'text'}
                  onPress={() => goToMainAppStack()}
                >
                  {`Back to Jarvis`}
                </FooterButton>
              : isWeb ? <FooterButton
                  mode={'text'}
                  onPress={() => setShowDownloadModal(true)}
                  labelStyle={{
                    marginTop: Sizing.x0,
                    marginBottom: Sizing.x0,
                  }}
                  color={Colors.brand.purple1}
                  icon={() => <MaterialCommunityIcons name={'download'} size={Sizing.x20} color={Colors.brand.purple1} />}
                >
                  {`Download Mobile App`}
                </FooterButton>
              : <></>
          }
          {
            !user && useCalculator
              ? <FooterButton
                  mode={'text'}
                  onPress={() => rootNavigate('CalculatorIntroScreen', { allowCancel: true })}
                  labelStyle={{
                    marginTop: Sizing.x0,
                    marginBottom: Sizing.x0,
                  }}
                  color={Colors.brand.purple1}
                  icon={() => <MaterialCommunityIcons name={'calculator'} size={Sizing.x20} color={Colors.brand.purple1} />}
                >
                  {'Try the quick calculator first'}
                </FooterButton>
              : <></>
          }
          <View style={{
            alignSelf: 'flex-start',
            width: '100%'
          }}>
            <FooterButton
              onPress={() => rootNavigate('NewUserCarouselScreen')}
            >{`Let's Go!`}</FooterButton>
          </View>
        </View>

      </>
    )
  }

  return (
    <>
      { isLoading ? <Loading useHolidayGraphics={true} /> :
        <>
          <StatusBar barStyle={'dark-content'} />
          <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
            <SafeAreaView style={[
              backgroundStyles.pageContainer,
              backgroundStyles.heroBackground,
            ]}>
              <View style={[
                layoutStyles.mainContentContainer,
                localStyles.screenContainer,
              ]}>
                <ScrollView
                  contentContainerStyle={layoutStyles.scrollContainerContent}
                  keyboardShouldPersistTaps='handled'
                  showsVerticalScrollIndicator={isWeb}
                >
                  { useHorizontalLayout
                    ?
                      <View style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingLeft: Sizing.x30,
                        width: '80%',
                        alignSelf: 'center',
                      }}>
                        <View style={{
                          ...Flex.row.between,
                        }}>
                          <View style={{
                            ...Flex.column.center,
                            width: '50%',
                          }}>
                            <Pressable onPress={() => setShowInfoModal(true)}>
                              <Image source={require('assets/brand/jarvis-logo-blk-4x.png')} style={{
                                width: Sizing.x200,
                                height: Sizing.x50,
                                resizeMode: 'contain',
                                alignSelf: 'flex-start',
                                // alignSelf: useHorizontalLayout ? 'flex-start' : 'center',
                              }} />
                              </Pressable>
                            <Heading />
                            <IconSection />
                            <MainMessage />
                            <SubMessage />
                            <CtaButton />
                          </View>
                          <View style={{
                            ...Flex.column.start,
                            width: '50%',
                          }}>
                            <MainImage />
                          </View>
                        </View>
                      </View>
                    :
                      <>
                        <View style={Flex.column.around}>
                          <Pressable onPress={() => setShowInfoModal(true)}>
                            <Image source={require('assets/brand/jarvis-logo-blk-4x.png')} style={{
                                width: Sizing.x200,
                                height: Sizing.x50,
                                resizeMode: 'contain',
                                alignSelf: 'center',
                            }} />
                          </Pressable>
                          <Heading />
                          <IconSection />
                          <MainImage />
                          <MainMessage />
                          <SubMessage />
                          <CtaButton />
                        </View>
                      </>
                  }
                </ScrollView>
              </View>
            </SafeAreaView>
            <AppInfoModal
              user={user}
              client={undefined}
              visible={showInfoModal}
              onDismiss={() => setShowInfoModal(false)}
              isUnauthenticated={!user}
            />
            {
              isWeb
                  ? <WebAppDownloadModal
                        visible={showDownloadModal}
                        onDismiss={() => setShowDownloadModal(false)}
                    />
                  : <></>
            }
          </ThemeProvider>
        </>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  screenContainer: {
    paddingTop: Sizing.x30,
  },
})
