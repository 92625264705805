import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupPortfolioDataId, setCurrentAdminGroupPortfolioDataId } from 'store/uxSlice'
import { AdminEditGroupPortfolioScreen } from './Screens/AdminEditGroupPortfolioScreen'

export const AdminEditGroupPortfoliosModal = () => {
  const dispatch = useAppDispatch()
  const currentGroupPortfolio = useAppSelector(currentAdminGroupPortfolioDataId)
  return (
    <ModalFormBuilder
      visible={!!currentGroupPortfolio}
      formTitle='Edit Group Portfolio Details'
      onDismiss={() => dispatch(setCurrentAdminGroupPortfolioDataId(undefined))}
      screen={<AdminEditGroupPortfolioScreen />}
    />
  )

}

