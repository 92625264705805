import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { LightModal } from 'components/Layout'
import { ModalProcessHeader } from 'components/Layout/ModalProcessHeader'
import React from 'react'
import { StatusBar, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { Colors, Flex, Paper } from 'styles'

const Stack = createNativeStackNavigator()

export type ModalProcessStackBuilderProps = {
  visible: boolean
  onDismiss: Function
  dismissConfirmationMessage?: string
  processTitle: string
  initialRouteName: string
  initialParams?: any
  screens: ModalProcessStackBuilderScreen[]
  presentation?: "modal" | "transparentModal" | "containedModal" | "containedTransparentModal" | "fullScreenModal" | "formSheet" | "card"
  theme?: any
  preventHeaderDisable?: boolean
}

export type ModalProcessStackBuilderScreen = {
  navigationId: string
  component: React.ComponentType<any>
  title?: string
}

export const ModalProcessStackBuilder = (props: ModalProcessStackBuilderProps) => {
  const { visible, onDismiss, dismissConfirmationMessage, processTitle, initialRouteName, initialParams, screens, presentation, theme, preventHeaderDisable } = props

  const { colors: themeColors } = Paper.useAppTheme()

  let stackScreens: Stack.Screen[] = []
  const totalScreenCount = screens.length
  const baseInitialParams = {
  }

  const modalProcessNavigationRef = useNavigationContainerRef()

  //Build stackScreens from screens input
  stackScreens = screens.map((processScreen, idx) => {
    const currentScreenPosition = idx + 1
    const isFirstScreen = idx === 0
    const isLastScreen = idx === totalScreenCount - 1
    const nextScreen = isLastScreen ? undefined : screens[currentScreenPosition].navigationId
    return <Stack.Screen
      key={idx}
      name={processScreen.navigationId}
      component={processScreen.component}
      initialParams={{
        nextScreen,
        onDismiss,
        ...baseInitialParams,
        ...initialParams,
      }}
    />
  })

  return (
    <>
      <StatusBar
        barStyle={'dark-content'}
      />
      <Portal>
        <LightModal
          theme={theme}
          visible={visible}
          dismissable={false}
        >
          <View style={{...Flex.column.start }}>
            <ModalProcessHeader
              navigationRef={modalProcessNavigationRef}
              processTitle={processTitle}
              onDismiss={onDismiss}
              dismissConfirmationMessage={dismissConfirmationMessage}
              preventDisable={preventHeaderDisable}
            />
              <NavigationContainer
                documentTitle={{
                  enabled: false,
                }}
              
                ref={modalProcessNavigationRef}
                independent={true}
              >
                <Stack.Navigator
                  initialRouteName={initialRouteName}
                  screenOptions={{
                    headerShown: false,
                    contentStyle:{
                      backgroundColor: Colors.brand.grey4,
                    }
                  }}
                >
                  <Stack.Group screenOptions={{
                    animation: 'slide_from_right',
                    presentation: presentation || 'card',
                  }}>
                    { stackScreens.map((screen, idx) => { return screen })
                    }
                  </Stack.Group>
                </Stack.Navigator>
              </NavigationContainer>
            </View>
        </LightModal>
      </Portal>
    </>
  )
}

export default ModalProcessStackBuilder
