import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editRetirementAgeVisible, setEditRetirementAgeVisible } from 'store/uxSlice'
import { RetirementTargetAgeEditScreen } from './Screens/RetirementTargetAgeEditScreen'

export const RetirementTargetAgeEditModal = () => {

  const dispatch = useAppDispatch()
  const visible = useAppSelector(editRetirementAgeVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle={'Change Target Age'}
      onDismiss={() => dispatch(setEditRetirementAgeVisible(false))}
      screen={<RetirementTargetAgeEditScreen />}
    />
  )
}
