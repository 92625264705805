import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { JAR_NAME_ALL } from 'lib/constants'
import { OnboardingPhase } from 'providers'
import React, { useState } from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation } from 'store/apiSlice'

export const BeneficiariesSetupIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}
  
  const [ updateClient] = useUpdateMeMutation()
  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)


  const next = () => {
    rootNavigate('BeneficiariesSetupProcessStack', route?.params)
  }
  
  const refuse = () => {
    updateClient({
      onboardingFlags: {
        beneficiaries: true,
      },
    })
    ampli.beneficiariesSetup({
      skipped: true,
      beneficiaryCount: 0,
    })
    hideDialog()
    goToOnboardingTasks(fromOnboarding)
  }

  return (      
    <ProcessIntroScreen
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={goToMainAppStack}
      refuseButtonAction={fromOnboarding ? showDialog : undefined}
      refuseButtonText={`No-one you want to benefit?`}
      illustrationFilename={'choose_beneficiaries.png'}
      headline={`Choose Beneficiaries`}
      subHeading={`Decide who should receive any funds left in your ${JAR_NAME_ALL} after you die, in conjunction with your Will`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.BENEFICIARIES} />}
    >
    <ConfirmationDialog
      visible={dialogVisible}
      title={'Are you sure?'}
      content={`We'll tick this task off, but you can always set your beneficiaries in the Legacy screen`}
      onCancel={hideDialog}
      onConfirm={refuse}
      confirmLabel={'Complete task'}
    />
    </ProcessIntroScreen>
  )
}
