import { Text } from 'components/Typography/Text'
import React, { ComponentProps } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { SegmentedButtons } from "react-native-paper"
import { Colors, Sizing, Paper, Typography } from 'styles'
import { ConfirmationDialog } from './ConfirmationDialog'
import { platformIsWeb } from 'lib/platformHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'

type ButtonSetProps = ComponentProps<typeof SegmentedButtons> & {
  backgroundColor?: string
}

export const ButtonSet = (props: ButtonSetProps) => {
  const { style, buttons, ...otherProps } = props

  const { colors: themeColors } = Paper.useAppTheme()

  //This wraps the react-native-paper button so we can control the background
  //colour for disabled state
  return (
    <View style={localStyles.buttonWrap}>
      <SegmentedButtons
        {...otherProps}
        buttons={buttons.map((button, idx) => {
          return {
            ...button,
            checkedColor: themeColors.accent,
            style: {
              opacity: button.disabled ? 0.2 : 1,
            },
            labelStyle: {
              fontSize: scaleNormalizer(12),
              textTransform: 'none',
            }
          }
        })}
        style={{
          borderColor: themeColors.primary,
          borderWidth: Sizing.x1,
          borderRadius: Sizing.x5,
        }}
      />
  </View>
  )
}

const localStyles = StyleSheet.create({
  buttonWrap: {
    ...Platform.select({
      //Prevents buttons being full width on web
      web: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      default: {}
    })
  },
})