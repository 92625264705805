import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { NATIONAL_INSURANCE_NO_MASK } from 'lib/constants'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { useLazyGetGroupSchemeMembersQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentSearchStatus } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerMemberDataId } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerMemberListItem } from '../Components/EmployerMemberListItem'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const EmployerMembersListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const [getMembersQuery, { data: members, isLoading: membersIsLoading, error: memberError, isFetching: membersIsFetching }] = useLazyGetGroupSchemeMembersQuery()
  const [refreshing, setRefreshing] = useState(false)
  // const [cursorPosition, setCursorPosition] = useState(0)

  //Swap results when switching scheme
  useEffect(() => {
    if (currentScheme && isValid) {
      onSubmit(watch())
    }
  }, [currentScheme])

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowMemberDetails = (id: string) => {
    dispatch(setCurrentEmployerMemberDataId(id))
  }

  const membersList = () => {
    let all = []
    if (members) {
      members.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerMemberListItem
        member={item}
        onPressFunction={() => handleShowMemberDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{
    search: string, 
    employeeId: string, 
    nationalInsuranceNo: string, 
    enrolmentStatus: GroupSchemeEnrolmentSearchStatus,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      employeeId: '',
      nationalInsuranceNo: undefined,
      enrolmentStatus: GroupSchemeEnrolmentSearchStatus.ANY,
    },
  })

  const { handleSubmit, reset, trigger, watch, formState: { isValid } } = formObj

  const onSubmit = attributes => {
    const enrolmentStatus = attributes.enrolmentStatus === GroupSchemeEnrolmentSearchStatus.ANY ? undefined : attributes.enrolmentStatus
    getMembersQuery({
      groupSchemeId: currentScheme.id,
      enrolmentStatus,
      search: attributes.search,
      employeeId: attributes.employeeId,
      nationalInsuranceNo: attributes.nationalInsuranceNo ? unformatNationalInsuranceNumber(attributes.nationalInsuranceNo) : undefined,
    })
  }

  const isLoading = membersIsLoading || membersIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      employeeId: '',
      nationalInsuranceNo: '',
      enrolmentStatus: GroupSchemeEnrolmentSearchStatus.ANY,
    })
  }
  
  const isValidNiNumberOrEmpty = (value: string) => {
    if (value === undefined || value === '') {
      return true
    }
    const result = value.match(/^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s?\d){6}\s?[A-D]$/)
    return result ? true : 'Invalid NI Number - please double check'
  }
 
  const employeeId = watch('employeeId')
  const nationalInsuranceNo = watch('nationalInsuranceNo')
  const searchTermNotRequired = employeeId || nationalInsuranceNo

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding members...')} /> :
          <View style={ Flex.column.start } >
            <View style={{ paddingHorizontal: Sizing.x30 }} >
              <ManagedTextInput
                name={'search'}
                label={'Search for member'}
                autoFocus={false}
                formObj={formObj}
                placeholder={'Enter part of name, email, employee identifier or national insurance no'}
                blurOnSubmit={false}
                submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                rules={{
                  required: !searchTermNotRequired,
                  minLength: {
                    value: 3,
                    message: 'Must be at least 3 characters'
                  },
                }} />
              <Collapsible collapsed={searchCollapsed}>
                <View>
                  <ManagedTextInput
                    name={'employeeId'}
                    label={'Employee Identifier'}
                    autoFocus={false}
                    formObj={formObj}
                    placeholder={'Enter full Employee Identifier'}
                    blurOnSubmit={false}
                    onChange={ () => trigger('search') }
                    rules={{
                      required: false,
                      minLength: {
                        value: 3,
                        message: 'Must be at least 3 characters'
                      },
                  }} />
                  <ManagedTextInput
                    name={'nationalInsuranceNo'}
                    autoFocus={false}
                    formObj={formObj}
                    label={'National Insurance Number'}
                    mask={{
                      type: 'custom',
                      options: {
                        mask: NATIONAL_INSURANCE_NO_MASK,
                      }
                    }}
                    // onSelectionChange={(event) => {
                    //   if (event?.nativeEvent?.selection?.start) {
                    //     setCursorPosition(event?.nativeEvent?.selection?.start)
                    //   }
                    // }}
                    // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
                    // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
                    autoCapitalize={'characters'}
                    forceCapitals={true}
                    blurOnSubmit={true}
                    onChange={ () => trigger('search') }
                    rules={{
                      required: false,
                      minLength: {
                        value: NATIONAL_INSURANCE_NO_MASK.length,
                        message: 'Must be exactly 9 characters'
                      },
                      maxLength: {
                        value: NATIONAL_INSURANCE_NO_MASK.length,
                        message: 'Must be exactly 9 characters'
                      },
                      validate: {
                        isValidNiNumberOrEmpty,
                      }
                  }} />
                  <ManagedAutoCompleteInput
                    name={'enrolmentStatus'}
                    label={'Enrolment Status'}
                    selectOnlyMode={true}
                    formObj={formObj}
                    dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentSearchStatus)}
                  />
                  <Button onPress={handleSubmit(onSubmit)} disabled={isLoading || !isValid}>Search</Button>
                  <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                </View>
              </Collapsible>
              <Button
                mode='text'
                color={themeColors.accent}
                icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                onPress={searchCollapsed ? showAdvanced : hideAdvanced}
              >
                {searchCollapsed ? 'Advanced search...' : 'Hide'}
              </Button>
              <ContentDivider />
            </View>
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
            >
              <OptionalSectionList
                sections={membersList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`Please search for a member`}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
