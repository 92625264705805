import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useUpdateRetirementProfileMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { pick } from 'lodash'

export const ChangeRetirementBudget_99_Result = ({ navigation, route }) => {
  const { onDismiss }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingRetirementProfileData: any = useAppSelector(workingRetirementProfile)

  const [ updateRetirementProfile, { data: retirementProfile, error, isLoading } ] = useUpdateRetirementProfileMutation()

  const close = () => {
    dispatch(updateWorkingRetirementProfile(undefined))
    onDismiss()
  }

  //Save on enter
  useEffect(() => {
    saveRp()
  }, [])

  //Close on save
  useEffect(() => {
    if (retirementProfile) {
      ampli.retirementProfileUpdate({
        ...pick(retirementProfile, [
          'asCouple',
          'insideLondon',
          'expensesNetTotalAmount',
          'targetRetirementAge',
          'baseBudgetLevel',
        ]),
      })
      close()
    }
  }, [retirementProfile])

  const saveRp = () => {
    if (workingRetirementProfileData) {
      Logger.info(`Updating retirement profile...`)
      updateRetirementProfile({
        ...workingRetirementProfileData,
      })
    }
  }


  return (      
    <ModalProcessScreen
      buttonTitle={'Continue'}
      error={error}
      errorTryAgain={saveRp}
      errorCancel={onDismiss}
      isLoading={isLoading}
      loadingMessage={['Saving profile...']}
      buttonAction={close}
      showButton={false}    
    >
    </ModalProcessScreen>
  )
}
