import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { pick, isEmpty } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddAffiliateMutation } from 'store/apiSlice'
import { CreateAffiliateDto } from 'store/dto/affiliate.dto'
import { useAppDispatch } from 'store/hooks'
import { setShowAdminAddAffiliateVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export enum NewAffiliateBlockType {
  BLOCKED = 'Blocked By Default',
  NOTBLOCKED = 'Unblocked By Default',
}

export const AddAffiliatesScreen = () => {
  const [addAffiliateMutation, { data: addedAffiliate, isLoading: addAffiliateIsLoading, error: addAffiliateError }] = useAddAffiliateMutation()
  const dispatch = useAppDispatch()

  const isWeb = platformIsWeb()

  const formObj = useForm<{
    name: string
    blockType: NewAffiliateBlockType
    message: string
    siteUrl: string
    logo: string
    isInternal: boolean
    generateApiKey: boolean
    apiDisabled: boolean
    reportingEmail: string
    affiliatePlatformChargeRatio: string
    affiliateGroupPensionChargeRatio: string
    branchQuickLinkPath: string
    customEmailTexts: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      siteUrl: '',
      blockType: NewAffiliateBlockType.NOTBLOCKED,
      message: '',
      logo: '',
      isInternal: false,
      generateApiKey: false,
      reportingEmail: '',
      affiliatePlatformChargeRatio: '0.1',
      affiliateGroupPensionChargeRatio: '0.1',
      branchQuickLinkPath: '',
      customEmailTexts: '',
    },
  })
  //Form refs for focussing
  const nameRef = useRef(null)
  const siteUrlRef = useRef(null)
  const messageRef = useRef(null)
  const affiliatePlatformChargeRatioRef = useRef(null)
  const affiliateGroupPensionChargeRatioRef = useRef(null)
  const reportingEmailRef = useRef(null)
  const branchQuickLinkPathRef = useRef(null)
  const customEmailTextsRef = useRef(null)

  const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger()
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }

  const logo = watch('logo')
  const isInternal = watch('isInternal')
  const generateApiKey = watch('generateApiKey')

  const onSubmit = async attributes => {
    const { isInternal, generateApiKey, customEmailTexts } = attributes
    const customEmailTextsArray = isEmpty(customEmailTexts) ? undefined : customEmailTexts.split(';')
    const data: CreateAffiliateDto = {
      ...pick(attributes, [
        'name',
        'logo',
        'isInternal',
        'apiDisabled',
        'branchQuickLinkPath',
      ]),
      customEmailTexts: customEmailTextsArray,
      generateApiKey,
      message: isInternal ? undefined : attributes.message,
      siteUrl: isInternal ? undefined : attributes.siteUrl,
      reportingEmail: isInternal || !attributes.reportingEmail || attributes.reportingEmail === '' ? undefined : attributes.reportingEmail,
      affiliatePlatformChargeRatio: isInternal ? 0 : parseFloat(attributes.affiliatePlatformChargeRatio),
      affiliateGroupPensionChargeRatio: isInternal ? 0 : parseFloat(attributes.affiliateGroupPensionChargeRatio),
      userIsBlocked:
        attributes.blockType === NewAffiliateBlockType.BLOCKED
          ? true
          : attributes.blockType === NewAffiliateBlockType.NOTBLOCKED
            ? false
            : undefined,
    }
    addAffiliateMutation(data)
  }

  useEffect(() => {
    if (addedAffiliate) {
      close()
    }
  }, [addedAffiliate])

  const close = () => {
    dispatch(setShowAdminAddAffiliateVisible(false))
  }

  const isLoading = addAffiliateIsLoading
  const error: any = addAffiliateError

  const isInternalOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Partner',
    },
    {
      value: true,
      label: 'Internal',
    },
  ]

  const apiDisabledOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Yes',
    },
    {
      value: true,
      label: 'No',
    },
  ]

  const generateApiKeyOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'No, not currently required',
    },
    {
      value: true,
      label: 'Yes, generate an API Key',
    },
  ]

  const isNumeric = (value: string) => {
    return isNaN(parseFloat(value)) ? 'Must be a number': true
  }

  const isInRange = (value: string) => {
    const numValue = parseFloat(value)
    const result = numValue <= 1 && numValue >= 0
    return result ? true : 'Must be between 0 and 1'
  }

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={addAffiliateError ? handleSubmit(onSubmit) : undefined}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={['Saving affiliate...']}
      buttonTitle={'Create Affiliate'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <Paragraph>{'Choose Affiliate Type'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'isInternal'}
        formObj={formObj}
        options={isInternalOptions}
        required={true}
      />
      {
        isInternal ? <></> :
          <>
            <Paragraph>{'Provide the affiliate with access to the Affiliate API?'}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'generateApiKey'}
              formObj={formObj}
              options={generateApiKeyOptions}
              required={false}
            />
            <Paragraph>{'Enable API Access Immediately?'}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'apiDisabled'}
              formObj={formObj}
              options={apiDisabledOptions}
              required={generateApiKey}
              disabled={!generateApiKey}
            />
          </>
      }
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => branchQuickLinkPathRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
        }}/>
      <ManagedTextInput
        ref={branchQuickLinkPathRef}
        name={'branchQuickLinkPath'}
        formObj={formObj}
        label={'Branch Quick Link Path'}
        placeholder={'Branch.io quick link path/alias'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <ManagedAutoCompleteInput
        name={'blockType'}
        formObj={formObj}
        label={'New User Initial Status'}
        selectOnlyMode={true}
        required={true}
        dataSet={enumToAutocompleteOptions(NewAffiliateBlockType)}
      />
      { isInternal ? <></> :
        <>
          <ManagedTextInput
            ref={affiliatePlatformChargeRatioRef}
            name={'affiliatePlatformChargeRatio'}
            formObj={formObj}
            label={'Platform Charge Ratio'}
            blurOnSubmit={false}
            returnKeyType={'done'}
            keyboardType={'numeric'}
            submitHandler={() => affiliateGroupPensionChargeRatioRef.current?.focus()}
            rules={{
              required: true,
              validate: {
                isNumeric,
                isInRange,
              }
            }}
          />
          <ManagedTextInput
            ref={affiliateGroupPensionChargeRatioRef}
            name={'affiliateGroupPensionChargeRatio'}
            formObj={formObj}
            label={'Group Scheme Charge Ratio'}
            blurOnSubmit={false}
            returnKeyType={'done'}
            keyboardType={'numeric'}
            submitHandler={() => reportingEmailRef.current?.focus()}
            rules={{
              required: true,
              validate: {
                isNumeric,
                isInRange,
              }
            }}
          />
          <ManagedTextInput
            ref={reportingEmailRef}
            name={'reportingEmail'}
            formObj={formObj}
            label={'Reporting Email Address'}
            placeholder={'Email to send reports'}
            autoCapitalize={'none'}
            returnKeyType={'next'}
            keyboardType={'email-address'}
            submitHandler={() => messageRef.current?.focus()}
            blurOnSubmit={false}
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Invalid email address"
              },
              required: false,
              minLength: 2,
              maxLength: 40,
            }} />
        <ManagedTextInput
          ref={messageRef}
          name={'message'}
          formObj={formObj}
          label={'Custom Message'}
          placeholder={'For affiliate landing screen'}
          returnKeyType={'next'}
          submitHandler={() => customEmailTextsRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 10,
            maxLength: 100,
          }}/>
        <ManagedTextInput
          ref={customEmailTextsRef}
          name={'customEmailTexts'}
          formObj={formObj}
          label={'Custom Email Texts'}
          placeholder={'Separate multiple paragraphs by a semicolon'}
          returnKeyType={'next'}
          submitHandler={() => siteUrlRef.current?.focus()}
          blurOnSubmit={false}
          rules={{
            required: false,
            minLength: 10,
            maxLength: 500,
          }}/>
        <ManagedTextInput
          ref={siteUrlRef}
          name={'siteUrl'}
          formObj={formObj}
          label={'Website URL'}
          placeholder={'Link to main affiliate website'}
          returnKeyType={'next'}
          blurOnSubmit={false}
          autoCapitalize={'none'}
          keyboardType={'url'}
          rules={{
            pattern: {
              value: /^(http|https):\/\/[^ "]+$/,
              message: "Invalid URL (include http/https)"
            },
            required: false,
          }}/>
        </>
      }
    </ModalProcessScreen>
  )
}

