import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Paragraph } from 'components/Typography'
import { getGroupPension, getPersonalPension } from 'lib/accountHelpers'
import { featureIsEnabledForUser } from 'lib/featureHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetUserAccountsQuery, useGetUserFeaturesQuery } from 'store/apiSlice'
import { AccountDto, AccountType } from 'store/dto/account.dto'
import { FeatureCode } from 'store/dto/feature.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { InvestmentTypeChoice, updateWorkingAccount, workingAccount } from 'store/tempDataSlice'
import { InvestmentTypeModalContent } from '../Components/InvestmentTypeModalContent'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'

export const InvestmentChoice_10_InvestmentTypeChoice = ({ route, navigation }) => {
  const { nextScreen, contextAccount, investmentReferenceAccount }: { nextScreen: string, contextAccount: AccountDto, investmentReferenceAccount: AccountDto }  = route?.params || {}

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching, refetch: refetchAccounts } = useGetUserAccountsQuery()
  const { data: features, error: featuresError, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetUserFeaturesQuery()

  const isLoading = accountsIsLoading || featuresIsLoading
  const error: any = accountsError || featuresError

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const hasBothPensions = personalPension && groupPension
  
  const contextAccountIsPersonalPension = contextAccount && contextAccount.accountType === AccountType.PERSONAL_PENSION
  const contextAccountHasFollowingGroupPension = contextAccountIsPersonalPension && groupPension && groupPension.usePersonalPensionInvestmentChoice
  const contextAccountIsGroupPension = contextAccount && contextAccount.accountType === AccountType.GROUP_PENSION
  const contextAccountIsFollowingPersonalPension = personalPension && contextAccountIsGroupPension && contextAccount.usePersonalPensionInvestmentChoice
  const contextAccountIsNotFollowingPersonalPension = personalPension && contextAccountIsGroupPension && !contextAccount.usePersonalPensionInvestmentChoice
  
  const groupPortfolioChoiceDisabled = !featureIsEnabledForUser(features, FeatureCode.GROUP_PENSION)
  const assetAllocationChoiceDisabled = !featureIsEnabledForUser(features, FeatureCode.FUND_CHOICE)

  const existingInvestmentTypeChoice = 
    investmentReferenceAccount?.groupPortfolioId
      ? InvestmentTypeChoice.GROUP_PORTFOLIO
      : investmentReferenceAccount?.investmentPlanId
        ? InvestmentTypeChoice.PLAN
        : investmentReferenceAccount?.assetAllocation
          ? InvestmentTypeChoice.ASSET_ALLOCATION
          : undefined

  //Temp state
  const dispatch = useAppDispatch()
  const workingAccountData = useAppSelector(workingAccount)

  //Setup form
  const formObj = useForm<{ investmentTypeChoice: InvestmentTypeChoice }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      investmentTypeChoice: workingAccountData?.investmentTypeChoice || existingInvestmentTypeChoice
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const { investmentTypeChoice } = attributes
    dispatch(updateWorkingAccount({
      //Use the group pension id if we are unfollowing
      accountId: contextAccount
        ? investmentTypeChoice === InvestmentTypeChoice.UNFOLLOW_PERSONAL
          ? groupPension.id
          : contextAccount.id
        : undefined,
      //Set usePersonalPensionInvestmentChoice is needed
      usePersonalPensionInvestmentChoice:
        contextAccount && investmentTypeChoice === InvestmentTypeChoice.UNFOLLOW_PERSONAL
          ? false
          : contextAccount && investmentTypeChoice === InvestmentTypeChoice.FOLLOW_PERSONAL
            ? true
            : undefined,
      //Set accountDescriptor for subsequent screens
      accountDescriptor: contextAccount
        ? investmentTypeChoice === InvestmentTypeChoice.UNFOLLOW_PERSONAL
          ? JAR_NAME_GROUP
          : contextAccountIsNotFollowingPersonalPension && investmentTypeChoice !== InvestmentTypeChoice.FOLLOW_PERSONAL
            ? JAR_NAME_GROUP
            : hasBothPensions
              ? JAR_NAME_ALL
              : JAR_NAME_PERSONAL
        : JAR_NAME_ALL,
      //Reset everything
      investmentPlanId: undefined,
      groupPortfolioId: undefined,
      assetAllocation: undefined,
      investmentPlanName: undefined,
      groupPortfolioName: undefined,
      ...attributes
    }))
    navigation.navigate(
      investmentTypeChoice === InvestmentTypeChoice.PLAN
        ? 'PlanChoice'
        : investmentTypeChoice === InvestmentTypeChoice.ASSET_ALLOCATION
          ? 'AssetAllocation'
          : investmentTypeChoice === InvestmentTypeChoice.GROUP_PORTFOLIO
            ? 'GroupPortfolioChoice'
            : investmentTypeChoice === InvestmentTypeChoice.FOLLOW_PERSONAL
              ? 'FollowPersonalPension'
              : nextScreen
      )
  }


  let options: ManagedCardChoiceInputOption[] = [
    {
      value: InvestmentTypeChoice.PLAN,
      title: existingInvestmentTypeChoice === InvestmentTypeChoice.PLAN
        ? `Change your plan`
        : existingInvestmentTypeChoice
          ? `Switch to a plan`
          : `Choose a plan`,
      description: `Choose a ready-made investment plan`,
      illustrationFilename: 'choose_your_jarvis_plan.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.PLAN}
        illustrationFilename={'choose_your_jarvis_plan.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={!assetAllocationChoiceDisabled}
      />,
      modalProceedLabel: 'Choose a Plan',
      modalCancelLabel: 'Go Back',
    },
    {
      value: InvestmentTypeChoice.ASSET_ALLOCATION,
      title: existingInvestmentTypeChoice === InvestmentTypeChoice.ASSET_ALLOCATION
          ? `Modify your investments`
          : assetAllocationChoiceDisabled
            ? `Coming soon!`
            : existingInvestmentTypeChoice
              ? `Switch to build your own`
              : `Build your own`,
      description: `Build your own plan from individual funds`,
      illustrationFilename: 'control_your_financial_future.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.ASSET_ALLOCATION}
        illustrationFilename={'control_your_financial_future.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={assetAllocationChoiceDisabled}
      />,
      disabled: assetAllocationChoiceDisabled,
      modalProceedLabel: 'Start Building',
      modalCancelLabel: 'Go Back',
    },
    {
      value: InvestmentTypeChoice.GROUP_PORTFOLIO,
      title:
        existingInvestmentTypeChoice === InvestmentTypeChoice.GROUP_PORTFOLIO
          ? `Change your managed portfolio`
          : groupPortfolioChoiceDisabled
          ? `Coming soon!`
          : existingInvestmentTypeChoice
            ? `Switch to a managed portfolio`
            : `Choose a managed portfolio`,
      description: `Choose a discretionary managed portfolio`,
      illustrationFilename: 'plan_retirement.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.GROUP_PORTFOLIO}
        illustrationFilename={'plan_retirement.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={assetAllocationChoiceDisabled}
      />,
      disabled: groupPortfolioChoiceDisabled,
      modalProceedLabel: 'Choose a Portfolio',
      modalCancelLabel: 'Go Back',
    },
  ]

  if (contextAccountIsNotFollowingPersonalPension) {
    options.push({
      value: InvestmentTypeChoice.FOLLOW_PERSONAL,
      title: `Follow Personal Jar`,
      description: `Follow your Personal Jar investment strategy`,
      illustrationFilename: 'man_saving_in_piggy_bank.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.FOLLOW_PERSONAL}
        illustrationFilename={'man_saving_in_piggy_bank.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={!assetAllocationChoiceDisabled}
      />,
      modalProceedLabel: 'Follow Personal Jar',
      modalCancelLabel: 'Go Back',
    })
  } else if (contextAccountIsFollowingPersonalPension) {
    options.push({
      value: InvestmentTypeChoice.UNFOLLOW_PERSONAL,
      title: `Choose a separate strategy`,
      description: `Choose a separate investment strategy for your Workplace Jar`,
      illustrationFilename: 'control_your_financial_future.png',
      modalContentFunction: () => <InvestmentTypeModalContent
        investmentType={InvestmentTypeChoice.UNFOLLOW_PERSONAL}
        illustrationFilename={'control_your_financial_future.png'}
        groupPortfolioChoiceDisabled={groupPortfolioChoiceDisabled}
        assetAllocationChoiceDisabled={!assetAllocationChoiceDisabled}
      />,
      modalProceedLabel: 'Choose Separate Strategy',
      modalCancelLabel: 'Go Back',
    })
  }


  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={accountsError ? refetchAccounts : refetchFeatures}
      buttonTitle={isValid ? 'Next' : 'Make Selection'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      showButton={true}
      headline={contextAccount
        ? `Ok, what would you like to do?`
        : `Ok, how do you want to invest?`
      }
      subHeading={'Please consider your personal experience in making investments'}
    >
      {
        hasBothPensions ?
        <Paragraph>
          {
            contextAccountHasFollowingGroupPension || contextAccountIsFollowingPersonalPension
              ? `Your investment choice currently applies to both your Personal and Workplace Jars`
              : contextAccountIsNotFollowingPersonalPension
                ? `Your investment choice currentlyapplies ONLY to your Workplace Jar`
                : `Your investment choice currentlyapplies ONLY to your Personal Jar`
          }
        </Paragraph>
        : <></>
      }
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'investmentTypeChoice'}
        submitHandler={handleSubmit(onSubmit)}
        required={true}
        options={options}
      />
    </ProcessScreen>
  )
}
