import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE } from 'lib/constants'
import React from 'react'
import { useForm } from 'react-hook-form'
import { UserDto, UserMetadata } from 'store/dto/user.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'

export const ClientSetup_06_DateOfBirth = ({ route, navigation }) => {
  const { nextScreen, user, metadata }: { nextScreen: string, user: UserDto, metadata: UserMetadata } = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingClientData = useAppSelector(workingClient)

  //Setup form
  const formObj = useForm<{ birthDate: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      birthDate: workingClientData?.birthDate || metadata?.birthDate || user?.metadata?.birthDate || '',
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingClient(attributes))
    navigation.navigate(nextScreen)
  }

  return (
    <ProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`What's your date of birth?`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.DATE_OF_BIRTH} />}
    >      
      <ManagedDateInput
        name={'birthDate'}
        autoFocus={true}
        formObj={formObj}
        submitHandler={handleSubmit(onSubmit)}
        blurOnSubmit={true}
        required={true}
        mustBeInPast={true}
        notOlderThanYears={MAX_CLIENT_AGE}
        notYoungerThanYears={MIN_CLIENT_AGE}
        tryParseTwoDigitYear={true}
        showCurrentAgeMessage={true}
      />
    </ProcessScreen>
  )
}
