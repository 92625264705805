import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useAddBankConnectionMutation } from 'store/apiSlice'
import { CreateBankConnectionDto, MoneyHubBankConnectionPaymentType } from 'store/dto/bank-connection.dto'
import { ContributionSource } from 'store/dto/base.dto'
import { useAppDispatch } from 'store/hooks'
import { setShowAdminAddBankConnectionVisible } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AddBankConnectionsScreen = () => {
  const [addBankConnectionMutation, { data: addedBankConnection, isLoading: addBankConnectionIsLoading, error: addBankConnectionError }] = useAddBankConnectionMutation()
  const dispatch = useAppDispatch()

  const formObj = useForm<CreateBankConnectionDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      moneyHubId: '',
      moneyHubName: '',
      moneyHubBankRef: '',
      name: '',
      description: '',
      logo: '',
      contributionSources: [],
      paymentTypes: [],
      isBeta: false,
    },
  })
  //Form refs for focussing
  const moneyHubIdRef = useRef(null)
  const moneyHubNameRef = useRef(null)
  const moneyHubBankRefRef = useRef(null)
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)

  const { handleSubmit, register, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger()
  }

  const handleClearImage = () => {    
    setValue('logo', null, { shouldDirty: true})
  }

  const logo = watch('logo')

  const onSubmit = async attributes => {
    addBankConnectionMutation(attributes)
  }

  useEffect(() => {
    if (addedBankConnection) {
      close()
    }
  }, [addedBankConnection])

  const close = () => {
    dispatch(setShowAdminAddBankConnectionVisible(false))
  }

  const isLoading = addBankConnectionIsLoading
  const error: any = addBankConnectionError

  const isBetaOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'No',
    },
    {
      value: true,
      label: 'Yes',
    },
  ]

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={addBankConnectionError ? handleSubmit(onSubmit) : undefined}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={['Saving bank connection...']}
      buttonTitle={'Create Bank Connection'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ManagedTextInput
        ref={moneyHubIdRef}
        name={'moneyHubId'}
        formObj={formObj}
        label={'MoneyHub ID'}
        placeholder={'Identifier from Money Hub'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => moneyHubNameRef.current?.focus()}
        rules={{
          required: true,
          minLength: 32,
          maxLength: 32,
        }}/>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => descriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={descriptionRef}
        name={'description'}
        formObj={formObj}
        label={'Description'}
        placeholder={'Optional description shown to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => moneyHubNameRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={moneyHubNameRef}
        name={'moneyHubName'}
        formObj={formObj}
        label={'Money Hub Name (internal)'}
        placeholder={'Name as known by Money Hub'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => moneyHubBankRefRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <ManagedTextInput
        ref={moneyHubBankRefRef}
        name={'moneyHubBankRef'}
        formObj={formObj}
        label={'Money Hub Bank Reference (internal)'}
        placeholder={'Bank reference as known by Money Hub'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
      }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Paragraph>{'Supported Payment Types'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'paymentTypes'}
        options={enumToAutocompleteOptions(MoneyHubBankConnectionPaymentType)}
      />
      <Paragraph>{'Supported Contribution Sources'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'contributionSources'}
        options={enumToAutocompleteOptions(ContributionSource)}
      />
      <Subheading>{'Beta Status (not visible to clients)'}</Subheading>
      <ManagedSimpleChoiceInput
        name={'isBeta'}
        formObj={formObj}
        options={isBetaOptions}
        required={true}
      />
    </ModalProcessScreen>
  )
}

