import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React, { useEffect } from 'react'
import { useUpdateGroupSchemeEnrolmentMutation } from 'store/apiSlice'
import { GroupSchemeEnrolmentContributionForecastTreatment, GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { useAppSelector } from 'store/hooks'
import { workingSetupSchemeEnrolment } from 'store/tempDataSlice'

export const SchemeEnrolmentChangeForecastTreatment_99_Result = ({ navigation, route }) => {
  const { onDismiss, resultHandlerFunction, enrolment }: { onDismiss: any, resultHandlerFunction: Function, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto }  = route?.params || {}

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  const [ updateEnrolment, { data: updatedEnrolment, error: updateEnrolmentError, isLoading: updateEnrolmentIsLoading } ] = useUpdateGroupSchemeEnrolmentMutation()

  const close = () => {
    onDismiss()
  }

  //On enter, call resultHandlerFunction if provided, else save...
  useEffect(() => {
    if (resultHandlerFunction) {
      resultHandlerFunction()
      close()
    } else {
      save()
    }
  }, [])

  const save = () => {
    const { contributionForecastTreatment, customEmployerContributionAmount, customPayrollFrequency } = workingSetupSchemeEnrolmentData
    const customTreatment = contributionForecastTreatment === GroupSchemeEnrolmentContributionForecastTreatment.CUSTOM
    const payload = {
      id: enrolment.id,
      // name,
      contributionForecastTreatment,
      customEmployerContributionAmount: customTreatment ? customEmployerContributionAmount : null,
      customPayrollFrequency: customTreatment ? customPayrollFrequency : null,
    }
    updateEnrolment(payload)
  }

  //Close on success 
  useEffect(() => {
    close()
  }, [updatedEnrolment])

  return (
    <ModalProcessScreen
      isLoading={updateEnrolmentIsLoading}
      loadingMessage={['Configuring income...']}
      error={updateEnrolmentError}
      errorTryAgain={save}
      buttonTitle={'Continue'}
      errorCancel={onDismiss}
      buttonAction={close}
      showButton={true}
    >
    </ModalProcessScreen>
  )
}
