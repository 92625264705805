import { ProcessIntroScreen } from 'components/ScreenTemplates/ProcessIntroScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { goToMainAppStack, goToOnboardingTasks, rootNavigate } from 'lib/RootNavigation'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'
import { OnboardingPhase } from 'providers'
import React from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation } from 'store/apiSlice'

export const BulkTransferIntroScreen = ({ route, navigation }) => {
  const { fromOnboarding }: { fromOnboarding: OnboardingPhase } =  route?.params || {}

  const [ updateClient] = useUpdateMeMutation()
  const [dialogVisible, setDialogVisible] = React.useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)


  const next = () => {
    rootNavigate('BulkTransferProcessStack', route?.params)
  }

  const refuse = () => {
    updateClient({
      onboardingFlags: {
        consolidate: true,
      },
    })
    ampli.transfersSetup({
      skipped: true,
    })
    hideDialog()
    goToOnboardingTasks(fromOnboarding)
  }

  return (
    <ProcessIntroScreen
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      cancelButtonAction={fromOnboarding ? () => goToOnboardingTasks(fromOnboarding) : goToMainAppStack}
      refuseButtonAction={fromOnboarding ? showDialog : undefined}
      refuseButtonText={`Nothing to consolidate?`}
      illustrationFilename={'simplify_your_portfortlio.png'}
      headline={`Simplify your retirement planning`}
      subHeading={`Consolidate your old pensions into your ${JAR_NAME_PERSONAL} to take control of your future`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.CONSOLIDATION_RATIONALE} />}
    >
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`We'll tick this task off, but you can always add/consolidate old pensions in the Transfers area`}
        onCancel={hideDialog}
        onConfirm={refuse}
        confirmLabel={'Complete task'}
      />
    </ProcessIntroScreen>
  )
}
