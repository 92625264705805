import { Paragraph, Subheading } from 'components/Typography'
import { SegmentBadge } from 'components/Typography/SegmentBadge'
import { Text } from 'components/Typography/Text'
import { format } from 'date-fns'
import { formatCurrencyAmount, formatPercentageAmount } from 'lib/generalHelpers'
import { floor, maxBy, minBy } from 'lodash'
import React from 'react'
import { StyleSheet } from 'react-native'
import { ClientClientSegmentDto } from 'store/dto/client.dto'
import { Colors, Sizing } from 'styles'
import { TieredChargeTable } from './TieredChargeTable'
import { UnborderedTable } from './UnborderedTable'
import { ContentDivider } from 'components/Layout/ContentDivider'

type ChargeBreakdownProps = {
  accountDescriptor: string
  segment: ClientClientSegmentDto
  initialFeePercentage: number
  annualFeePercentage: number
}

export const ChargeBreakdown = (props: ChargeBreakdownProps) => {
  const { accountDescriptor, segment, initialFeePercentage, annualFeePercentage } = props

  const platformChargeTiers = segment?.platformCharge?.tiers || []
  const discount = segment?.platformChargeDiscount || 1

  const maxPlatformFee = maxBy(platformChargeTiers, 'rate')
  const minPlatformFee = minBy(platformChargeTiers, 'rate')
  const maxStandardTotalFee = annualFeePercentage + (maxPlatformFee?.rate || 0)
  const minStandardTotalFee = annualFeePercentage + (minPlatformFee?.rate || 0)
  const maxDiscountedTotalFee = annualFeePercentage + floor((maxPlatformFee?.rate || 0) * discount, 2)
  const minDiscountedTotalFee = annualFeePercentage + floor((minPlatformFee?.rate || 0) * discount, 2)

  const segmentHasConditions = segment?.requiredBalance && segment?.monthsToReachRequiredBalance && segment?.dateToReachRequiredBalance

  return (
    <>
      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'All-in Annual Charge'}</Subheading>
      {
        segment?.platformChargeDiscount !== 0
          ? <>
              <Paragraph style={localStyles.text}>
                <Paragraph style={{ textDecorationLine: 'line-through' }}>{`${formatPercentageAmount(minStandardTotalFee, 2)} - ${formatPercentageAmount(maxStandardTotalFee, 2)}`}</Paragraph>
                <Paragraph style={{ fontWeight: '900', textDecorationLine: 'none' }}>{` ${formatPercentageAmount(minDiscountedTotalFee, 2)} - ${formatPercentageAmount(maxDiscountedTotalFee, 2)}`}</Paragraph>
              </Paragraph>
              
            </>
          : <Paragraph style={[localStyles.text, { fontWeight: '900' }]}>{`${formatPercentageAmount(minStandardTotalFee, 2)} - ${formatPercentageAmount(maxStandardTotalFee, 2)}`}</Paragraph>
      }

      <ContentDivider />
      <Subheading style={localStyles.subTitle}>{'Charges Breakdown'}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Monthly Subscription Fee`,
            value: <Text style={{ fontWeight: '900' }}>{formatCurrencyAmount(0, 0)}</Text>
          },
          {
            label: <Text style={{ fontWeight: '900' }}>{`Annual Platform charges *`}</Text>,
            value: ``
          },
        ]}
      />
      {
        segment?.isPromotion
        ? <>
            <Paragraph>
              {`You receive a `}
              <Paragraph style={{ fontWeight: '900' }}>{formatPercentageAmount(segment?.platformChargeDiscount * 100, 0)}</Paragraph>
              {` discount on our standard Platform charges. ${segmentHasConditions ? `Conditions Apply *` : ''}`}
            </Paragraph>
            <SegmentBadge
              segment={segment}
              withoutInfoModal={true}
              containerStyle={{
                paddingVertical: Sizing.x10,
              }}
            />
          </>
        : <></>
      }
      <TieredChargeTable
        tiers={platformChargeTiers}
        discount={segment?.platformChargeDiscount || 0}
      />
      <UnborderedTable
        data={[
          {
            label: <Text style={{ fontWeight: '900' }}>{`Fund Charges`}</Text>,
            value: ``
          }
        ]}
      />
      <UnborderedTable
        data={[
          {
            label: `Initial Charge`,
            value: <Text style={{ fontWeight: '900' }}>{formatPercentageAmount(initialFeePercentage, 2)}</Text>
          },
          {
            label: `Annual Charge`,
            value: <Text style={{ fontWeight: '900' }}>{formatPercentageAmount(annualFeePercentage, 2)}</Text>
          },
        ]}
      />
      {
        segmentHasConditions
        ? <Paragraph style={localStyles.smallText}>{`* To continue benefiting from the ${segment?.badgeName} discount, your ${accountDescriptor} balance needs to reach at least ${formatCurrencyAmount(segment?.requiredBalance, 2)} within ${segment?.monthsToReachRequiredBalance} months of becoming a client (by ${format(new Date(segment?.dateToReachRequiredBalance),'do MMMM yyyy')})`}</Paragraph>
          : <></>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    textAlign: 'left',
    fontSize: Sizing.x10,
  },
})