import { Paragraph } from "components/Typography"
import { Button } from "components/Utility/Button"
import { ExternalLinkButton } from "components/Utility/ExternalLinkButton"
import { NamedInformation, NamedInformationButton } from "components/Utility/InformationButton"
import { addDays, addMonths, format, formatISO, isAfter, isBefore, isDate, isValid, parseISO, setDate, setMonth, startOfMonth, subDays, subMonths, subWeeks, subYears } from "date-fns"
import { goToOnboardingTasks, mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { getGroupPension, getPersonalPension } from "lib/accountHelpers"
import { ANNUAL_ALLOWANCE_AMOUNT, JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL, PENDING_TRANSFER_CONSIDERED_STALE_WEEKS } from "lib/constants"
import { scaleNormalizer } from "lib/scaleHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import { composeIntercomMessage } from "lib/intercomHelpers"
import { openAppStoreReview } from "lib/linkingHelpers"
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { filterAssetsInScopeForTransfer, filterEditableAssets, filterUnacknowledgedCompletedTraces, filterUnacknowledgedCompletedTransfers } from 'lib/retirementAssetHelpers'
import { filterEditableIncomes } from "lib/retirementIncomeHelpers"
import { compact, groupBy, mapValues, minBy, orderBy, sumBy } from 'lodash'
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { Image, Platform } from "react-native"
import { useGetBeneficiariesQuery, useGetMeSchemeInvitesQuery, useGetInvitesQuery, useGetMeQuery, useGetPreferencesQuery, useGetRetirementAssetsQuery, useGetRetirementIncomesQuery, useGetRetirementProfileQuery, useGetSpouseQuery, useGetStatePensionCurrentQuery, useGetUserAccountsQuery, useGetInvitesAsTargetQuery, useGetOpenSchemeInviteByIdQuery, useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PensionTransferStatus, PublicPendingSchemeEnrolmentDto } from "store/dto/account.dto"
import { ContributionSource } from "store/dto/base.dto"
import { AmlStatus, ClientBaseDto } from "store/dto/client.dto"
import { InviteStatus } from "store/dto/invite.dto"
import { RetirementAssetDto, RetirementAssetTransferAttempt, RetirementAssetTransferStatus } from "store/dto/retirement-asset.dto"
import { RetirementIncomeDto } from "store/dto/retirement-income.dto"
import { UserDto } from 'store/dto/user.dto'
import { useAppDispatch } from "store/hooks"
import { setEditRetirementBudgetVisible, setShareModalVisible } from "store/uxSlice"
import { Colors } from "styles"
import { OnboardingPhase, useGuidanceContext } from "./guidance.context"
import { Logger } from "lib/logger"
import { AppIllustration } from "components/Utility/AppIllustration"

export type SuggestionsContextType = {
  suggestions: SimpleSuggestion[]
  getSuggestionsForScreen: Function
  getSuggestionByIdentifier: Function
  getSuggestionCountPerScreen: Function
}

const BASE_CONTEXT: SuggestionsContextType = {
  suggestions: [],
  getSuggestionsForScreen: () => {},
  getSuggestionByIdentifier: () => {},
  getSuggestionCountPerScreen: () => {},
}

// NOTE - do NOT change the values for this enum!!
//The frontend stores dismissals in the data model using these as keys
//so if they are changed, previous user dismissals will be ignored!
export enum SuggestionIdentifier {
  //General
  ONBOARDING_VERIFY  = 'onboarding_verify',
  ONBOARDING_PENSION  = 'onboarding_pension',
  ONBOARDING_ASSETS  = 'onboarding_assets',
  ONBOARDING_CONTRIBUTE  = 'onboarding_contribute',
  ONBOARDING_CONSOLIDATE  = 'onboarding_consolidate',
  ONBOARDING_PROFILE  = 'onboarding_profile',
  ONBOARDING_INCOMES  = 'onboarding_incomes',
  ONBOARDING_BENEFICIARIES  = 'onboarding_beneficiaries',
  APP_REVIEW = 'app_review',
  REVIEW_RETIREMENT_ASSETS = 'assets_review_annual',
  SUGGEST_CONSOLIDATE = 'transfer_old_pensions',
  CONTRIBUTE_END_PERSONAL_TAX_YEAR = 'contribute_end_personal_tax_year',
  CONTRIBUTE_END_COMPANY_YEAR = 'contribute_end_company_year',
  CONTRIBUTE_STALE = 'contribute_stale',
  SHARE_FRIENDS = 'add_friend',
  REVIEW_STATE_PENSION = 'incomes_review_state_pension',
  REVIEW_RETIREMENT_INCOMES = 'incomes_review_annual',
  REVIEW_RETIREMENT_PROFILE = 'retirement_profile_review',
  SUGGEST_SPOUSE_INVITE = 'invite_partner',
  SUGGEST_BENEFICIARIES = 'choose_beneficiaries',
  REVIEW_BENEFICIARIES = 'beneficiaries_review',
  TRANSFER_PENDING_STALE = 'transfer_pending_stale',
  TRANSFER_EXECUTION_ACKNOWLEDGE = 'transfer_acknowledge',
  TRANSFER_TRACE_ACKNOWLEDGE = 'trace_acknowledge',
  SCHEME_ENROLMENT_PENDING = 'enrolment_pending',
  SCHEME_INVITE_OPEN = 'scheme_invite_open',
  PARTNER_INVITE_OPEN = 'partner_invite_open',
}

export interface SimpleSuggestion {
  identifier: SuggestionIdentifier
  priority: number
  screen?: MainAppNavScreen
  illustrationFilename?: string
  imageSource?: any
  notificationCount?: number
  title: string
  shortDescription: string
  description: string | ReactNode
  buttonLabel?: string
  onProceed: any
  proceedButtonLabel?: string
  dismissalAction?: Function
  dismissalValue?: boolean | string
  dismissalConfirmationMessage?: string
  dismissalButtonLabel?: string
  dismissalOnProceed?: boolean
  dismissalValueForProceed?: boolean | string
}

const suggestionPriorities = {  
  //Scheme enrolments/invites
  [SuggestionIdentifier.SCHEME_ENROLMENT_PENDING]: 1,
  [SuggestionIdentifier.SCHEME_INVITE_OPEN]: 12,

  //Onboarding
  [SuggestionIdentifier.ONBOARDING_ASSETS]: 11,
  [SuggestionIdentifier.ONBOARDING_VERIFY]: 12,
  [SuggestionIdentifier.ONBOARDING_PENSION]: 13,
  [SuggestionIdentifier.ONBOARDING_CONTRIBUTE]: 14,
  [SuggestionIdentifier.ONBOARDING_CONSOLIDATE]: 15,
  [SuggestionIdentifier.ONBOARDING_PROFILE]: 16,
  [SuggestionIdentifier.ONBOARDING_INCOMES]: 17,
  [SuggestionIdentifier.ONBOARDING_BENEFICIARIES]: 18,

  //Transfers
  [SuggestionIdentifier.TRANSFER_PENDING_STALE]: 20,
  [SuggestionIdentifier.TRANSFER_EXECUTION_ACKNOWLEDGE]: 21,
  [SuggestionIdentifier.TRANSFER_TRACE_ACKNOWLEDGE]: 22,

  //Contributions
  [SuggestionIdentifier.CONTRIBUTE_END_PERSONAL_TAX_YEAR]: 30,
  [SuggestionIdentifier.CONTRIBUTE_END_COMPANY_YEAR]: 31,
  [SuggestionIdentifier.CONTRIBUTE_STALE]: 32,
  
  //Onboarding gaps
  [SuggestionIdentifier.SUGGEST_CONSOLIDATE]: 40,
  [SuggestionIdentifier.SUGGEST_SPOUSE_INVITE]: 41,
  [SuggestionIdentifier.SUGGEST_BENEFICIARIES]: 42,

  //Periodic Review
  [SuggestionIdentifier.REVIEW_RETIREMENT_PROFILE]: 100,
  [SuggestionIdentifier.REVIEW_STATE_PENSION]: 101,
  [SuggestionIdentifier.REVIEW_RETIREMENT_ASSETS]: 102,
  [SuggestionIdentifier.REVIEW_RETIREMENT_INCOMES]: 103,
  [SuggestionIdentifier.REVIEW_BENEFICIARIES]: 104,

  //Others
  [SuggestionIdentifier.SHARE_FRIENDS]: 200,
  [SuggestionIdentifier.APP_REVIEW]: 201,
}



export const SuggestionsContext = createContext<SuggestionsContextType>(BASE_CONTEXT)

export const SuggestionsProvider = (props: { user: UserDto, ready: boolean, children: ReactNode }) => {
  const { user, ready, children } = props

  const today = new Date()

  const { incompleteOnboardingPhases } = useGuidanceContext()

  const dispatch = useAppDispatch()

  const [suggestions, setSuggestions] = useState<SimpleSuggestion[]>([])

  const { data: client, isLoading: clientIsLoading, error: clientError } = useGetMeQuery(undefined, { skip: !(ready && user) })

  const skip = !(user && client)
  
  const { data: preferences, isLoading: prefIsLoading, error: prefError, isFetching: prefIsFetching } = useGetPreferencesQuery(undefined, { skip })
  const { data: spouse, isLoading: spouseIsLoading, error: spouseError } = useGetSpouseQuery(undefined, { skip })
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading } = useGetUserAccountsQuery(undefined, { skip })
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading } = useGetRetirementProfileQuery(undefined, { skip })
  const { data: assets, isLoading: assetsIsLoading, error: assetsError } = useGetRetirementAssetsQuery(undefined, { skip })
  const { data: incomes, isLoading: incomesIsLoading, error: incomesError } = useGetRetirementIncomesQuery(undefined, { skip })
  const { data: beneficiaries, error: beneficiariesError, isLoading: beneficiariesIsLoading, isFetching: beneficiariesIsFetching, refetch: refetchBeneficiaries } = useGetBeneficiariesQuery(undefined, { skip })
  const { data: invitesAsInvitor, error: invitesAsInvitorError, isLoading: invitesAsInvitorIsLoading, refetch: refetchInvitesAsInvitor } = useGetInvitesQuery(undefined, { skip })
  const { data: invitesAsTarget, error: invitesAsTargetError, isLoading: invitesAsTargetIsLoading, refetch: refetchInvitesAsTarget } = useGetInvitesAsTargetQuery(undefined, { skip })
  const { data: currentStatePension, error: currentStatePensionError, isLoading: currentStatePensionIsLoading, refetch: refetchCurrentStatePension } = useGetStatePensionCurrentQuery(undefined, { skip })
  const { data: schemeInvites, isLoading: schemeInvitesIsLoading, error: schemeInvitesError } = useGetMeSchemeInvitesQuery(undefined, { skip })
  const { data: openSchemeInvite, isLoading: openSchemeInviteIsLoading, isFetching: openSchemeInviteIsFetching, error: openSchemeInviteError } = useGetOpenSchemeInviteByIdQuery(user?.preferredSchemeInviteId, { skip: !user?.preferredSchemeInviteId })
  const { data: pendingEnrolment, isSuccess: pendingEnrolmentIsSuccess, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, refetch: refetchPendingEnrolment } = useGetPendingEnrolmentByIdQuery(user?.preferredSchemeEnrolmentId, { skip: !user?.preferredSchemeEnrolmentId })

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const hasAnyPension = !!personalPension || !!groupPension

  const firstCreatedIncome: RetirementIncomeDto = incomes ? minBy(incomes, 'createdAt') : undefined
  const firstCreatedAsset: RetirementAssetDto = assets ? minBy(assets, 'createdAt') : undefined

  const dismissals = preferences?.dismissals

  const contactSupport = async () => {
    await composeIntercomMessage()
  }

  const isDismissed = (identifier: SuggestionIdentifier) => {
    if (!dismissals) {
      return false
    }
    const dismissalValue = dismissals[identifier]
  
    //Undefined
    if (dismissalValue === undefined) {
      return false
    }
    
    //Boolean values
    if (typeof dismissalValue === 'boolean') {
      return dismissalValue
    }
  
    //Dates
    const date = parseISO(dismissalValue)
    if (isValid(date)) {
      return isBefore(new Date(), date)
    }
  
    //Else assume not dismissed
    return false
  }  

  const getSuggestionsForScreen = (screen: MainAppNavScreen): SimpleSuggestion[] => {
    return suggestions.filter(suggestion => {
      return suggestion.screen === screen
    })
  }

  const getSuggestionByIdentifier = (identifier: SuggestionIdentifier): SimpleSuggestion => {
    return suggestions.find(suggestion => {
      return suggestion.identifier === identifier
    })
  }

  const getSuggestionCountPerScreen = (screen: MainAppNavScreen): any => {
    // return countBy(suggestions, 'screen')
    const grouped = groupBy(suggestions, 'screen')
    return mapValues(grouped, function(s) {
      return sumBy(s, function(s) {
        return s.notificationCount ? s.notificationCount : 1
      })
    })
  }

  const getSuggestionOnboardingVerify = (): SimpleSuggestion => {
    //No suggestion if no client or client has an amlStatus
    if (!client || client?.amlStatus) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_VERIFY,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_VERIFY),
      illustrationFilename: 'magnifying_glass.png',
      title: 'Verify Identity',
      shortDescription: `Get instantly approved to open your ${JAR_NAME_PERSONAL}`,
      description: `By completing identity verification, we can instantly approve you to open a Jarvis ${JAR_NAME_PERSONAL}.\n\nIt's only 5 questions and takes just a couple of minutes!\n\nYou'll need your National Insurance Number to hand.`,
      onProceed: () => rootNavigate('ClientVerificationIntroScreen'),
      proceedButtonLabel: `Verify Now!`,
    }
  }
  
  const getSuggestionOnboardingPension = (): SimpleSuggestion => {
    //No suggestion if no client or client not approved, or already has a personal pension
    if (!client || client?.amlStatus !== AmlStatus.PASS || personalPension) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_PENSION,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_PENSION),
      illustrationFilename: 'choose_your_jarvis_plan.png',
      title: `Open ${JAR_NAME_PERSONAL}`,
      shortDescription: `Pick an investment plan that's right for you`,
      description: `Get your ${JAR_NAME_PERSONAL} set up and choose an investment strategy that works for you.\n\nIt's just a few questions and takes just a couple of minutes.\n\nYou shouldn't need any additional information - just an idea of how you would like to invest.`,
      onProceed: () => rootNavigate('InvestmentChoiceIntroScreen'),
      proceedButtonLabel: `Get Started!`,
    }
  }
    
  const getSuggestionOnboardingContribute = (): SimpleSuggestion => {
    //No suggestion if no client or no personal pension or already completed contribution onboarding
    if (!client || !personalPension || client?.onboardingFlags?.contribute) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_CONTRIBUTE,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_CONTRIBUTE),
      illustrationFilename: 'regular_contributions.png',
      title: `Set Up Contributions`,
      shortDescription: `Contribute with one-off or regular contributions`,
      description: `Start contributing to your ${JAR_NAME_PERSONAL} by making one-off contributions to your, or setting up a regular contributions.\n\nIf you've completed the 'Plan Your Retirement' card, Jarvis can even help you decide how much to contribute to achieve your retirement goals!\n\nYou'll the bank details from where you want to contribute, and we can seamlessly connect you to your bank to complete the setup.`,
      onProceed: () => rootNavigate('ContributionsIntroScreen'),
      proceedButtonLabel: `Start Contributing!`,
    }
  }
  
  const getSuggestionOnboardingConsolidate = (): SimpleSuggestion => {
    //No suggestion if no client or no personal pension or already completed consolidation onboarding
    if (!client || !personalPension || client?.onboardingFlags?.consolidate) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_CONSOLIDATE,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_CONSOLIDATE),
      illustrationFilename: 'consolidate.png',
      title: `Transfer Pensions`,
      shortDescription: `Consolidate old pensions quickly and easily`,
      description: `Start consolidating old pensions to your ${JAR_NAME_PERSONAL} to take full control of your retirement, and gather everything into one place.\n\nWe can electronically transfer the vast majority of defined contribution pensions if you know the provider and your pension reference number.`,
      onProceed: () => rootNavigate('BulkTransferIntroScreen'),
      proceedButtonLabel: `Start Consolidating!`,
    }
  }
  
  const getSuggestionOnboardingAssets = (): SimpleSuggestion => {
    //No suggestion if no client or already completed asset onboarding
    if (!client || client?.onboardingFlags?.assets) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_ASSETS,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_ASSETS),
      illustrationFilename: 'capture_old_pensions.png',
      title: 'Capture Old Pensions',
      shortDescription: `Record other personal pensions and savings`,
      description: `Capture the details of any old defined contribution pensions, or any other assets you are holding to fund your retirement.\n\nIt's quick and easy to capture the details, and helps Jarvis give you an accurate view of your future.\n\nIt's also easy to consolidate old pensions into Jarvis when you are ready.`,
      onProceed: () => rootNavigate('RetirementAssetSetupIntroScreen'),
      proceedButtonLabel: `Get Started!`,
    }
  }
    
  const getSuggestionOnboardingProfile = (): SimpleSuggestion => {
    //No suggestion if no client or already has retirement profile
    if (!client || retirementProfile) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_PROFILE,
      screen: MainAppNavScreen.DASHBOARD_RETIREMENT,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_PROFILE),
      illustrationFilename: 'set_your_budget.png',
      title: 'Define Retirement Lifestyle',
      shortDescription: `See what budget you'll need in your retirement`,
      description: `Answer a few questions about the when, where and who for your retirement, and Jarvis will help you define a realistic budget for what you'll need in retirement.\n\nIt only takes a couple of minutes, and you'll immediately get an idea of what you might need.\n\nDefining your retirement budget is the first step in planning your retirement and achieving your goals!`,
      onProceed: () => rootNavigate('RetirementProfileSetupIntroScreen'),
      proceedButtonLabel: `Get Started!`,
    }
  }

  const getSuggestionOnboardingIncomes = (): SimpleSuggestion => {
    //No suggestion if no client or no retirement progile or already completed income onboarding
    if (!client || !retirementProfile || client?.onboardingFlags?.incomes) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_INCOMES,
      screen: MainAppNavScreen.DASHBOARD_RETIREMENT,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_INCOMES),
      illustrationFilename: 'capture_retirement_income.png',
      title: 'Add Retirement Incomes',
      shortDescription: `Record your state pension and retirement incomes`,
      description: `Capture the details of your state pension along with income from any defined benefit pensions, or other sources of income you are planning for in retirement.\n\nIt's quick to add the details, and helps Jarvis give you an accurate view of your future.`,
      onProceed: () => rootNavigate('RetirementIncomeSetupIntroScreen'),
      proceedButtonLabel: `Get Started!`,
    }
  }

  const getSuggestionOnboardingBeneficiaries = (): SimpleSuggestion => {
    //No suggestion if no client or no pension accounts or already completed beneficiary onboarding
    if (!client || !hasAnyPension || client?.onboardingFlags?.beneficiaries) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.ONBOARDING_BENEFICIARIES,
      screen: MainAppNavScreen.DASHBOARD_LEGACY,
      priority: getPriority(SuggestionIdentifier.ONBOARDING_BENEFICIARIES),
      illustrationFilename: 'choose_beneficiaries.png',
      title: 'Choose Your Beneficiaries',
      shortDescription: `Decide who should inherit any residual savings`,
      description: `By deciding who will inherit any capital left in your ${JAR_NAME_ALL} when you die, you'll have peace of mind that your wishes have been recorded.\n\nIf you're lucky enough to generate surplus, based on your retirment planning, Jarvis can also help you understand how much each beneficary might inherit.`,
      onProceed: () => rootNavigate('BeneficiariesSetupIntroScreen'),
      proceedButtonLabel: `Get Started!`,
    }
  }

  const getSuggestionConsolidate = (): SimpleSuggestion => {
    //No suggestion if no client/pension/assets or dismissed
    if (!client || !personalPension || !assets || isDismissed(SuggestionIdentifier.SUGGEST_CONSOLIDATE)) {
      return undefined
    }

    //No suggestion if onboarding phase not complete
    const consolidateOnboardingComplete = !incompleteOnboardingPhases?.includes(OnboardingPhase.CONSOLIDATE)
    if (!consolidateOnboardingComplete) {
      return undefined
    }

    const transferableAssets = filterAssetsInScopeForTransfer(assets, client)

    //No suggestion if no transferable assets
    if (!transferableAssets?.length) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.SUGGEST_CONSOLIDATE,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.SUGGEST_CONSOLIDATE),
      illustrationFilename: 'consolidate.png',
      title: 'Simplify your Portfolio',
      shortDescription: `Consolidate old pensions quickly and easily`,
      description: `Quickly and simply transfer your old workplace and personal pensions into your Jarvis plan for free.`,
      onProceed: () => rootNavigate('BulkTransferIntroScreen'),
      proceedButtonLabel: `Consolidate Now!`,
      dismissalValue: formatISO(addMonths(new Date(), 6)),
      dismissalConfirmationMessage: `We'll remind you again in 6 months in case anything has changed`,
      dismissalButtonLabel: `No thanks`
    }
  }

  const getPriority = (identifier: SuggestionIdentifier) => {
    return suggestionPriorities[identifier] || 1000
  }

  const getSuggestionPendingStaleTransfers = (): SimpleSuggestion => {
    //No suggestion if no client/pension/assets or dismissed
    if (!client || !personalPension || !assets) {
      return undefined
    }

    const inProgressStatuses: RetirementAssetTransferStatus[] = [
      RetirementAssetTransferStatus.INITIATED,
      RetirementAssetTransferStatus.ACTIVE,
      RetirementAssetTransferStatus.WITH_SUPPORT,
    ]

    const pendingStaleTransfers = assets ? assets.filter(asset => {
      const { transferAttempts, transferProgressConfirmed } = asset
      if (transferProgressConfirmed) {
        return false
      }
      const isInProgress = inProgressStatuses.includes(asset?.transferStatus)
      if (!isInProgress) {
        return false
      }
      const sortedAttempts = orderBy(transferAttempts, ['updatedAt'], ['desc'])
      const currentTransferAttempt: RetirementAssetTransferAttempt = sortedAttempts?.length ? sortedAttempts[0] : undefined
      const isWithCedingProvider = currentTransferAttempt?.transferStatus === PensionTransferStatus.WITH_PREVIOUS_PROVIDER
      const lastUpdate = currentTransferAttempt?.updatedAt ? new Date(currentTransferAttempt?.updatedAt) : new Date()
      const isStale = isBefore(lastUpdate, subWeeks(new Date(), PENDING_TRANSFER_CONSIDERED_STALE_WEEKS))

      return isWithCedingProvider && isStale
    }) : []

    //No suggestion if no pending stale transfer assets
    if (!pendingStaleTransfers?.length) {
      return undefined
    }

    const multiple = pendingStaleTransfers?.length > 1
  
    return {
      identifier: SuggestionIdentifier.TRANSFER_PENDING_STALE,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      buttonLabel: `View Transfers`,
      priority: getPriority(SuggestionIdentifier.TRANSFER_PENDING_STALE),
      illustrationFilename: 'magnifying_glass.png',
      notificationCount: pendingStaleTransfers?.length,
      title: `Pending Transfer${multiple ? 's' : ''}!`,
      shortDescription: `We haven't heard back from your old pension provider${multiple ? 's' : ''}`,
      description: <>
        <Paragraph>{multiple
          ? `For ${pendingStaleTransfers?.length} of your transfers, we haven't heard back from the old providers.`
          : `For your transfer of old pension '${pendingStaleTransfers[0]?.name}', we haven't heard back from your old provider.`}</Paragraph>
        <AppIllustration filename={'clock.png'} style={{
          width: scaleNormalizer(80),
          height: scaleNormalizer(80),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        <Paragraph>{`You may wish to contact the provider to check they are processing our request for transfer.`}</Paragraph>
        <Paragraph>{`If you have already heard from the provider, you can let us know in the Transfers area to dismiss this suggestion.`}</Paragraph>
      </>,  
      onProceed: () => mainAppNavigate(MainAppNavScreen.TRANSFERS),
      proceedButtonLabel: 'View Transfers',
      //No dismissal posisble for this one
    }    
  }

  const getSuggestionCompletedTransfers = (): SimpleSuggestion => {
    //No suggestion if no client/pension/assets or dismissed
    if (!client || !personalPension || !assets) {
      return undefined
    }

    const unacknowledgedTransferAssets = filterUnacknowledgedCompletedTransfers(assets, client)

    //No suggestion if no unacknowledged transfer assets
    if (!unacknowledgedTransferAssets?.length) {
      return undefined
    }

    const multiple = unacknowledgedTransferAssets?.length > 1
  
    return {
      identifier: SuggestionIdentifier.TRANSFER_EXECUTION_ACKNOWLEDGE,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      buttonLabel: `View Transfers`,
      priority: getPriority(SuggestionIdentifier.TRANSFER_EXECUTION_ACKNOWLEDGE),
      illustrationFilename: 'thanks.png',
      notificationCount: unacknowledgedTransferAssets?.length,
      title: `Transfer${multiple ? 's' : ''} Complete!`,
      shortDescription: `Your old pension${multiple ? 's have' : ' has'} been transferred`,
      description: multiple
        ? `Your ${unacknowledgedTransferAssets?.length} old pensions have now been transferred into your Jarvis plan, and the funds received from your old providers are now part of your Jarvis balance.`
        : `Your old pension '${unacknowledgedTransferAssets[0]?.name}' has now been transferred into your Jarvis plan, and the funds received from your old provider are now part of your Jarvis balance.`,
      onProceed: () => mainAppNavigate(MainAppNavScreen.TRANSFERS),
      proceedButtonLabel: 'View Transfers',
      //No dismissal posisble for this one
    }    
  }

  const getSuggestionOpenPartnerInvites = (): SimpleSuggestion => {
    //No suggestion if no client
    if (!client) {
      return undefined
    }

    const openPartnerInvites = invitesAsTarget ? invitesAsTarget.filter(invite => {
      return invite.status === InviteStatus.OPEN
    }) : []

    //No suggestion if no open invites
    if (!openPartnerInvites?.length) {
      return undefined
    }

    const multiple = openPartnerInvites?.length > 1
    const singleInvite = !multiple ? openPartnerInvites[0] : undefined
    const invitorName = singleInvite?.from
    ? singleInvite?.from?.firstName && singleInvite?.from?.surname
      ? `${singleInvite?.from?.firstName} ${singleInvite?.from?.surname}`
      : singleInvite?.from?.email
    : 'Someone'

    return {
      identifier: SuggestionIdentifier.PARTNER_INVITE_OPEN,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      buttonLabel: `Review${multiple ? 's' : ''}`,
      priority: getPriority(SuggestionIdentifier.SCHEME_INVITE_OPEN),
      illustrationFilename: 'accept_invitation.png',
      notificationCount: openPartnerInvites?.length,
      title: `New Partner Invite${multiple ? 's' : ''}!`,
      shortDescription: multiple
      ? `You have multiple invites to link Jarvis accounts`
      : `${invitorName} invited you to link Jarvis accounts`,
      description: <>
        <Paragraph>{multiple
          ? `You have been invited link accounts by multiple people.`
          : `You have been invited by ${invitorName} to link accounts.`
        }</Paragraph>
      </>,  
      onProceed: () => rootNavigate('InviteAcceptIntroScreen'),
      proceedButtonLabel: `Review${multiple ? 's' : ''}`,
      //No dismissal posisble for this one
    }
  }


  const getSuggestionPendingEnrolments = (): SimpleSuggestion => {
    //No suggestion if no client/group pension or dismissed
    if (!client || !groupPension) {
      return undefined
    }

    let groupPensionPendingEnrolments: (GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto)[] = []
    groupPensionPendingEnrolments = groupPension.groupSchemeEnrolments ? groupPension.groupSchemeEnrolments.filter(enrolment => {
      return enrolment.isVerified === false
    }) : []

    //See if the user preferred pending enrolment is already included (any enrolment for the groupPension)
    const pendingEnrolmentAlreadyPresent = pendingEnrolment ? groupPensionPendingEnrolments.find(enrolment => {
      return enrolment.id === pendingEnrolment.id
    }) : undefined

    //If not, insert it
    if (pendingEnrolment && !pendingEnrolmentAlreadyPresent) {
      groupPensionPendingEnrolments.unshift(pendingEnrolment)
    }

    //No suggestion if no pending enrolments
    if (!groupPensionPendingEnrolments?.length) {
      return undefined
    }

    const multiple = groupPensionPendingEnrolments?.length > 1
    const singleEnrolment = !multiple ? groupPensionPendingEnrolments[0] : undefined
    const logo = singleEnrolment?.groupScheme?.logo
    const organizationDisplayName = singleEnrolment?.groupScheme?.organizationDisplayName
  
    return {
      identifier: SuggestionIdentifier.SCHEME_ENROLMENT_PENDING,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      buttonLabel: `Manage Employers`,
      priority: getPriority(SuggestionIdentifier.SCHEME_ENROLMENT_PENDING),
      illustrationFilename: logo ? undefined : 'envelope_with_report.png',
      imageSource: logo ? { uri: logo } : undefined,
      notificationCount: groupPensionPendingEnrolments?.length,
      title: `New Scheme Enrolment${multiple ? 's' : ''}!`,
      shortDescription: multiple
        ? `You have been enrolled in new workplace pension schemes`
        : `You have been enrolled by ${organizationDisplayName}`,
      description: <>
        <Paragraph>{multiple
          ? `You have been enrolled in ${groupPensionPendingEnrolments?.length} new workplace pension schemes.`
          : `You have been enrolled by ${organizationDisplayName} in their workplace pension scheme.`
        }</Paragraph>
        <Paragraph>{multiple
          ? `Visit your ${JAR_NAME_GROUP} screen to manage these employers and see what they are contributing into your ${JAR_NAME_ALL}`
          : `Visit your ${JAR_NAME_GROUP} screen to manage this employer and see what they are contributing into your ${JAR_NAME_ALL}`
        }</Paragraph>
      </>,  
      onProceed: () => mainAppNavigate(MainAppNavScreen.GROUP_PENSION),
      proceedButtonLabel: 'Manage Employers',
      //No dismissal posisble for this one
    }
  }

  const getSuggestionOpenSchemeInvites = (): SimpleSuggestion => {
    //No suggestion if no client
    if (!client) {
      return undefined
    }

    const openSchemeInvites = schemeInvites ? schemeInvites.filter(schemeInvite => {
      return schemeInvite.status === InviteStatus.OPEN
    }) : []

    //No suggestion if no open scheme invites
    if (!openSchemeInvites?.length) {
      return undefined
    }

    const multiple = openSchemeInvites?.length > 1
    const singleSchemeInvite = !multiple ? openSchemeInvites[0] : undefined
    const logo = singleSchemeInvite?.groupScheme?.logo
    const organizationDisplayName = singleSchemeInvite?.groupScheme?.organizationDisplayName
  
    return {
      identifier: SuggestionIdentifier.SCHEME_INVITE_OPEN,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      buttonLabel: `Manage Employers`,
      priority: getPriority(SuggestionIdentifier.SCHEME_INVITE_OPEN),
      illustrationFilename: logo ? undefined : 'envelope_with_report.png',
      imageSource: logo ? { uri: logo } : undefined,
      notificationCount: openSchemeInvites?.length,
      title: `New Scheme Invite${multiple ? 's' : ''}!`,
      shortDescription: multiple
        ? `You have been invited to new workplace pension schemes`
        : `You have been invited by ${organizationDisplayName}`,
      description: <>
        <Paragraph>{multiple
          ? `You have been invited to ${openSchemeInvites?.length} new workplace pension schemes.`
          : `You have been invited by ${organizationDisplayName} to join their workplace pension scheme.`
        }</Paragraph>
        <Paragraph>{multiple
          ? `Visit your ${JAR_NAME_GROUP} screen to manage these employers and request enrolment`
          : `Visit your ${JAR_NAME_GROUP} screen to manage this employer and request enrolment`
        }</Paragraph>
      </>,  
      onProceed: () => mainAppNavigate(MainAppNavScreen.GROUP_PENSION),
      proceedButtonLabel: 'Manage Employers',
      //No dismissal posisble for this one
    }
  }

  const getSuggestionCompletedTraces = (): SimpleSuggestion => {
    //No suggestion if no client/pension/assets or dismissed
    if (!client || !personalPension || !assets) {
      return undefined
    }

    const unacknowledgedTraceAssets = filterUnacknowledgedCompletedTraces(assets, client)

    //No suggestion if no unacknowledged trace assets
    if (!unacknowledgedTraceAssets?.length) {
      return undefined
    }

    const multiple = unacknowledgedTraceAssets?.length > 1
  
    return {
      identifier: SuggestionIdentifier.TRANSFER_TRACE_ACKNOWLEDGE,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      buttonLabel: `Make Decision${multiple ? 's' : ''}`,
      priority: getPriority(SuggestionIdentifier.TRANSFER_TRACE_ACKNOWLEDGE),
      illustrationFilename: 'magnifying_glass.png',
      notificationCount: unacknowledgedTraceAssets?.length,
      title: `Pension Finding Results`,
      shortDescription: `We finished looking for your pension${multiple ? 's' : ''}`,
      description: `We have finished looking for your ${unacknowledgedTraceAssets?.length} old pension${multiple ? 's' : ''} - please review the results in the Transfers area.`,
      onProceed: () => mainAppNavigate(MainAppNavScreen.TRANSFERS),
      proceedButtonLabel: 'Review Results',
      //No dismissal posisble for this one
    }    
  }
 
  const getSuggestionAddBeneficiaries = (): SimpleSuggestion => {
    //No suggestion if no client/pension or dismissed
    if (!client || !hasAnyPension || isDismissed(SuggestionIdentifier.SUGGEST_BENEFICIARIES)) {
      return undefined
    }

    //No suggestion if onboarding phase not complete
    const familyOnboardingComplete = !incompleteOnboardingPhases?.includes(OnboardingPhase.FAMILY)

    if (!familyOnboardingComplete) {
      return undefined
    }

    //No suggestion if no pensions, or not completed onboarding or has beneficiaries
    if (!client?.onboardingFlags?.beneficiaries || beneficiaries?.nominations?.length) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.SUGGEST_BENEFICIARIES,
      screen: MainAppNavScreen.DASHBOARD_LEGACY,
      priority: getPriority(SuggestionIdentifier.SUGGEST_BENEFICIARIES),
      illustrationFilename: 'choose_beneficiaries.png',
      title: 'Choose Beneficiaries',
      shortDescription: `Ensure loved ones are looked after`,
      description: `Name up to four individuals or charities to inherit any residual savings in your Jarvis plan.`,
      onProceed: () => rootNavigate('BeneficiariesSetupIntroScreen'),
      proceedButtonLabel: `Choose Now!`,
      dismissalValue: formatISO(addMonths(new Date(), 12)),
      dismissalConfirmationMessage: `We'll remind you again in 12 months in case anything has changed`,
      dismissalButtonLabel: `No thanks`
    }
  }

  const getSuggestionInviteParter = (): SimpleSuggestion => {
    //No suggestion if no client/pension/spouse or dismissed
    if (!client || !personalPension || !spouse || isDismissed(SuggestionIdentifier.SUGGEST_SPOUSE_INVITE)) {
      return undefined
    }

    //No suggestion if client isn't verified
    if (client.amlStatus !== AmlStatus.PASS) {
      return undefined
    }

    //No suggestion if spouse has user
    if (spouse?.userId) {
      return undefined
    }

    const openInvites = invitesAsInvitor ? invitesAsInvitor.filter(invite => {
      return invite.status === InviteStatus.OPEN
    }) : []
    const hasOpenInvite = openInvites?.length

    //No suggestion if open invite
    if (hasOpenInvite) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.SUGGEST_SPOUSE_INVITE,
      screen: MainAppNavScreen.DASHBOARD_RETIREMENT,
      priority: getPriority(SuggestionIdentifier.SUGGEST_SPOUSE_INVITE),
      illustrationFilename: 'invite.png',
      title: `Invite ${spouse?.firstName}`,
      shortDescription: `Connect accounts to plan retirement together`,
      description: `Send an invitation to ${spouse?.firstName} to join Jarvis, so you can work together on your retirement planning.`,
      onProceed: () => rootNavigate('SpouseInviteIntroScreen'),
      proceedButtonLabel: `Invite Now!`,
      dismissalValue: formatISO(addMonths(new Date(), 6)),
      dismissalConfirmationMessage: `No problem, we won't ask you again for 6 months, but you can still share with friends from your Profile area.`,
      dismissalButtonLabel: `No thanks`
    }
  }

  const getSuggestionAppReview = (): SimpleSuggestion => {
    //No suggestion if no client or dismissed
    if (!client || isDismissed(SuggestionIdentifier.APP_REVIEW)) {
      return undefined
    }

    // //No suggestion if not android/ios
    const platform = Platform.OS
    const reviewPlatforms = ['android', 'ios']
    if (!reviewPlatforms.includes(platform)) {
      return undefined
    }

    //No suggestion unless has a pension AND at least one finished planning AND at least one of planning, contributions or consolidation is complete)
    const planningComplete = retirementProfile && client?.onboardingFlags?.incomes && client?.onboardingFlags?.assets 
    const relevant = hasAnyPension && (planningComplete || client?.onboardingFlags?.contribute || client?.onboardingFlags?.consolidate)
    if (!relevant) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.APP_REVIEW,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.APP_REVIEW),
      illustrationFilename: 'star.png',
      title: `Leave a review`,
      shortDescription: `Tell us what you think about Jarvis`,
      description: <>
        <Paragraph>{`We love user feedback and understanding what you like best about the app!`}</Paragraph>
        <Paragraph>{`It helps others get an idea of which features are most useful, and helps us shape the future of the app.`}</Paragraph>
        <Paragraph style={{ fontWeight: 'bold' }}>{`If you're having any issues with the app, please don't hesitate to contact support so we can assist.`}</Paragraph>
        <Button
            mode={'text'}
            color={Colors.brand.purple1}
            onPress={contactSupport}
          >
            {'Contact Support'}
        </Button>
        <Paragraph>{`Click below to nip into the ${platform === 'ios' ? 'App' : 'Play'} Store and leave your review.`}</Paragraph>
        </>,
      onProceed: () => openAppStoreReview(),
      proceedButtonLabel: `Leave Review`,
      dismissalValue: formatISO(addMonths(new Date(), 6)),
      dismissalButtonLabel: `Not right now`,
      dismissalOnProceed: true,
      dismissalValueForProceed: true, //Never ask again if they proceed
    }
  }

  const getSuggestionFriendShare = (): SimpleSuggestion => {
    //No suggestion if no client or dismissed
    if (!client || isDismissed(SuggestionIdentifier.SHARE_FRIENDS)) {
      return undefined
    }

    //No suggestion unless opened pension or finished planning
    const planningComplete = retirementProfile && client?.onboardingFlags?.incomes && client?.onboardingFlags?.assets 
    const relevant = hasAnyPension || planningComplete
    if (!relevant) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.SHARE_FRIENDS,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.SHARE_FRIENDS),
      illustrationFilename: 'megaphone.png',
      title: `Spread the word`,
      shortDescription: `Tell friends and family about Jarvis`,
      description: <>
        <Paragraph>{`We would really appreciate your support in growing the Jarvis community. Quickly introduce friends, family and colleagues to Jarvis.`}</Paragraph>
        <AppIllustration filename={'financial_forecasting.png'} style={{
          width: scaleNormalizer(80),
          height: scaleNormalizer(80),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        <Paragraph>{`Click 'Share Now!' to get a QR code, share a download, or let them try out our quick calculator.`}</Paragraph>
        </>,
      onProceed: () => dispatch(setShareModalVisible(true)),
      proceedButtonLabel: `Share Now!`,
      dismissalValue: formatISO(addMonths(new Date(), 6)),
      dismissalButtonLabel: `Not right now`
    }
  }

  const getSuggestionAnnualIncomeReview = (): SimpleSuggestion => {
    //No suggestion if no client/incomes or dismissed
    if (!client || !incomes?.length || isDismissed(SuggestionIdentifier.REVIEW_RETIREMENT_INCOMES)) {
      return undefined
    }

    //No suggestion if no editable incomes
    const editableIncomes = filterEditableIncomes(incomes, client, spouse)
    if (!editableIncomes?.length) {
      return undefined
    }

    // No suggestion if less than a year since first income was created
    if (firstCreatedIncome && new Date(firstCreatedIncome?.createdAt) > subYears(today, 1)) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.REVIEW_RETIREMENT_INCOMES,
      screen: MainAppNavScreen.DASHBOARD_RETIREMENT,
      priority: getPriority(SuggestionIdentifier.REVIEW_RETIREMENT_INCOMES),
      illustrationFilename: 'task_list.png',
      title: `Annual Income Review`,
      shortDescription: `Check your retirement incomes are up-to-date`,
      notificationCount: editableIncomes?.length,
      description: <>
        <Paragraph>{`Regular retirement incomes are the best way to reduce the burden of savings today, so small changes can make a big difference to when you can achieve your goals.`}</Paragraph>
        <AppIllustration filename={'clock.png'} style={{
          width: scaleNormalizer(80),
          height: scaleNormalizer(80),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        <Paragraph>{`It's been about a year since you entered your incomes, or your last review, so we would recommend a quick check to check everything is correct.`}</Paragraph>
        </>,
      onProceed: () => mainAppNavigate(MainAppNavScreen.REGULAR_INCOMES),
      proceedButtonLabel: `Review now`,
      dismissalValue: formatISO(addMonths(new Date(), 12)),
      dismissalConfirmationMessage: `We'll remind you again in about a year`,
      dismissalButtonLabel: `No thanks`,
      dismissalOnProceed: true,
    }
  }    

  const getSuggestionAnnualAssetReview = (): SimpleSuggestion => {
    //No suggestion if no client/assets or dismissed
    if (!client || !assets?.length || isDismissed(SuggestionIdentifier.REVIEW_RETIREMENT_ASSETS)) {
      return undefined
    }

    //No suggestion if no editable assets
    const editableAssets = filterEditableAssets(assets, client, spouse)
    if (!editableAssets?.length) {
      return undefined
    }

    // No suggestion if less than a year since first asset was created
    if (firstCreatedAsset && new Date(firstCreatedAsset?.createdAt) > subYears(today, 1)) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.REVIEW_RETIREMENT_ASSETS,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.REVIEW_RETIREMENT_ASSETS),
      illustrationFilename: 'task_list.png',
      title: `Annual Asset Review`,
      shortDescription: `Check your other assets values are up-to-date`,
      notificationCount: editableAssets?.length,
      description: <>
        <Paragraph>{`The value of other pensions and assets can change over time, so to ensure your forecasting and suggestions are accurate, it's important to keep them up-to-date.`}</Paragraph>
        <AppIllustration filename={'clock.png'} style={{
          width: scaleNormalizer(80),
          height: scaleNormalizer(80),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        <Paragraph>{`It's been about a year since you entered your assets, or your last review, so we would recommend a quick check to check everything is correct.`}</Paragraph>
        </>,
      onProceed: () => mainAppNavigate(MainAppNavScreen.RETIREMENT_ASSETS),
      proceedButtonLabel: `Review now`,
      dismissalValue: formatISO(addMonths(new Date(), 12)),
      dismissalConfirmationMessage: `We'll remind you again in about a year`,
      dismissalButtonLabel: `No thanks`,
      dismissalOnProceed: true,
    }
  }

  const getSuggestionStatePensionReview = (): SimpleSuggestion => {
    //No suggestion if no client or no current state pension
    if (!client || !currentStatePension) {
      return undefined
    }

    //Derive people under consideration
    //Exclude spouse if they are real user.
    const people = compact([client, spouse?.userId ? undefined : spouse])

    //No suggestion if all state pensions are zero (exclude spouse)
    const totalStatePensionCount = sumBy(people, function(person: ClientBaseDto) {
      return person?.statePensionAmount > 0 ? 1 : 0
    })
    if (totalStatePensionCount === 0) {
      return undefined
    }

    const dismissals = preferences?.dismissals || []
    const dismissalValue = dismissals[SuggestionIdentifier.REVIEW_STATE_PENSION]
    const parsedDate = dismissalValue ? parseISO(dismissalValue) : undefined
    const dismissalDate = isValid(parsedDate) ? parsedDate : undefined
    const currentStatePensionDate = currentStatePension?.fromDate ? new Date(currentStatePension?.fromDate) : undefined

    //No suggestion if dismissal since latest state pension date
    if (currentStatePensionDate && dismissalDate) {
      if (isAfter(dismissalDate, currentStatePensionDate)) {
        return undefined
      }
    }
    //Filter clients depending on the state
    const clientsWithStaleStatePension = people.filter((person: ClientBaseDto) => {
      if (!person?.statePensionUpdatedAt) {
        return false
      }
      const statePensionUpdatedDate = new Date(person?.statePensionUpdatedAt)
      if (isAfter(statePensionUpdatedDate, currentStatePensionDate)) {
        return false
      }
      return true
    })

    if (clientsWithStaleStatePension.length === 0) {
      return undefined
    }
    
    return {
      identifier: SuggestionIdentifier.REVIEW_STATE_PENSION,
      screen: MainAppNavScreen.DASHBOARD_RETIREMENT,
      priority: getPriority(SuggestionIdentifier.REVIEW_STATE_PENSION),
      illustrationFilename: 'task_list.png',
      title: `State Pension Review`,
      shortDescription: `Check your state pension amount${spouse ? 's are' : ' is'} up to date`,
      notificationCount: totalStatePensionCount,
      description: <>
        <Paragraph>{`The UK goverment changed the State Pension amount ${currentStatePensionDate ? `on ${format(currentStatePensionDate, 'do MMMM yyyy')}` : 'recently'}.`}</Paragraph>
        {
          currentStatePension ?
            <Paragraph style={{ fontWeight: 'bold' }}>{`The new Full Amount is ${formatCurrencyAmount(currentStatePension?.maxAmount)}.`}</Paragraph>
            : <></>
        }
        <ExternalLinkButton
          url={`https://www.gov.uk/new-state-pension`}
        >{`More Information`}
        </ExternalLinkButton>
        <Paragraph>{`We recommend reviewing the state pension amount${spouse ? 's' : ''} you have recorded and updating it to reflect the new government provision.`}</Paragraph>
        </>,
      onProceed: () => mainAppNavigate(MainAppNavScreen.REGULAR_INCOMES),
      proceedButtonLabel: `Review now`,
      dismissalValue: formatISO(addDays(new Date(), 1)), //Dismissal is set to tomorrow, essentially dismissing until at least tomorrow
      dismissalConfirmationMessage: `We'll let you know the next time the Full State Pension amount changes`,
      dismissalButtonLabel: `No thanks`,
      dismissalOnProceed: true,
    }
  }

  const getSuggestionRetirementProfileReview = (): SimpleSuggestion => {
    //No suggestion if no client/profile or dismissed
    if (!client || !retirementProfile || isDismissed(SuggestionIdentifier.REVIEW_RETIREMENT_PROFILE)) {
      return undefined
    }

    //No suggestion if retirement profile less than a year old
    const retirementProfileCreatedAtDate = retirementProfile?.createdAt ? new Date(retirementProfile?.createdAt) : undefined
    if (retirementProfileCreatedAtDate && retirementProfileCreatedAtDate > subYears(today, 1)) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.REVIEW_RETIREMENT_PROFILE,
      screen: MainAppNavScreen.DASHBOARD_RETIREMENT,
      priority: getPriority(SuggestionIdentifier.REVIEW_RETIREMENT_PROFILE),
      illustrationFilename: 'financial_forecasting.png',
      title: `Review Retirement Lifestyle`,
      shortDescription: `Check your target choices and update your budget`,
      description: <>
        <Paragraph>{`Deciding the lifestyle you want to achieve in retirement, and setting an accurate budget is key to planning.`}</Paragraph>
        <Paragraph>{`Jarvis uses the 'Retirement Living Standards' data from the Pension And Lifetime Savings Association (PLSA) to suggest budgets, which are updated over time.`}</Paragraph>
        <Paragraph style={{ fontWeight: 'bold' }}>{`As such, the suggested budget amounts may have changed.`}</Paragraph>
        <Paragraph>{`It's been about a year since you defined your profile, or your last review, so we would recommend checking your lifestyle and budget are accurate.`}</Paragraph>
        <ExternalLinkButton
          url={`https://www.retirementlivingstandards.org.uk/`}
        >{`Retirement Living Standards`}
        </ExternalLinkButton>
        </>,
      onProceed: () => dispatch(setEditRetirementBudgetVisible(true)),
      proceedButtonLabel: `Review Now`,
      dismissalValue: formatISO(addMonths(new Date(), 12)),
      dismissalConfirmationMessage: `We'll remind you again in about a year`,
      dismissalButtonLabel: `No thanks`,
      dismissalOnProceed: true,
    }
  }

  const getSuggestionContributeEndPersonalTaxYear = (): SimpleSuggestion => {
    //No suggestion if no client or dismissed
    if (!client || isDismissed(SuggestionIdentifier.CONTRIBUTE_END_PERSONAL_TAX_YEAR)) {
      return undefined
    }

    //No suggestion if outside date range
    const today = new Date()
    const newTaxYearStartDate = setDate(setMonth(today, 3), 6) //6th April this year, exclusive
    const oldTaxYearEndDate = subDays(newTaxYearStartDate, 1)
    const horizonStartDate = startOfMonth(subMonths(newTaxYearStartDate, 1)) //1st of previous month (1st March)
    if (today < horizonStartDate || today > newTaxYearStartDate) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.CONTRIBUTE_END_PERSONAL_TAX_YEAR,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.CONTRIBUTE_END_PERSONAL_TAX_YEAR),
      illustrationFilename: 'man_saving_in_piggy_bank.png',
      title: `End Of Tax Year`,
      shortDescription: `Use your Pension Annual Allowance before it's too late`,
      description: <>
        <Paragraph>{`The personal tax year is coming to an end on ${format(oldTaxYearEndDate, 'do MMMM')}, which marks the last chance to use any remaining Pension Annual Allowance you may have.`}</Paragraph>
        <Paragraph style={{ fontWeight: 'bold' }}>{`The government Pension Annual Allowance is currently set at ${formatCurrencyAmount(ANNUAL_ALLOWANCE_AMOUNT)}.`}</Paragraph>
        <NamedInformationButton name={NamedInformation.PENSION_ANNUAL_ALLOWANCE} />
        <Paragraph>{`If you have remaining allowance, and have spare cash to save towards your retirement, you may want to make a one-off contribution before the tax year end.`}</Paragraph>
        <Paragraph style={{ fontWeight: 'bold'}}>{`Always speak to an accountant to understand what is right for you.`}</Paragraph>
      </>,
      onProceed: () => rootNavigate('ContributionsIntroScreen'),
      proceedButtonLabel: `Contribute Now`,
      dismissalValue: formatISO(addMonths(new Date(), 6)),
      dismissalConfirmationMessage: `We'll remind you again next year`,
      dismissalButtonLabel: `No thanks`,
      dismissalOnProceed: true,
    }
  }


  const getSuggestionContributeEndCompanyYear = (): SimpleSuggestion => {
    //No suggestion if no client or dismissed
    if (!client || isDismissed(SuggestionIdentifier.CONTRIBUTE_END_COMPANY_YEAR)) {
      return undefined
    }

    //No suggestion if not employer contributions
    if (client?.contributionConfiguration?.source !== ContributionSource.EMPLOYER) {
      return undefined
    }

    //No suggestion if no accounting refernces
    const accountingReferenceMonth = client?.contributionConfiguration?.employer?.accountingReferenceMonth
    const accountingReferenceDay = client?.contributionConfiguration?.employer?.accountingReferenceDay

    if (!accountingReferenceMonth || !accountingReferenceDay) {
      return undefined
    }

    //No suggestion if outside date range
    const today = new Date()

    const endCompanyTaxYearDate = setDate(setMonth(today, accountingReferenceMonth - 1), accountingReferenceDay)
    if (!isDate(endCompanyTaxYearDate) || !isValid(endCompanyTaxYearDate)) {
      return undefined
    }

    const horizonStartDate = subMonths(endCompanyTaxYearDate, 2) //2 months before company year end
    if (today < horizonStartDate || today > endCompanyTaxYearDate) {
      return undefined
    }

    const companyName = client?.contributionConfiguration?.employer?.companyName

    return {
      identifier: SuggestionIdentifier.CONTRIBUTE_END_COMPANY_YEAR,
      screen: MainAppNavScreen.DASHBOARD_TODAY,
      priority: getPriority(SuggestionIdentifier.CONTRIBUTE_END_COMPANY_YEAR),
      illustrationFilename: 'man_saving_in_piggy_bank.png',
      title: `End Of Company Year`,
      shortDescription: `Don't forget to reduce your Corporation Tax bill`,
      description: <>
        <Paragraph>{`Based on our records, the company year for ${companyName ? `'${companyName}'` : `your limited company`} is coming to an end on ${format(endCompanyTaxYearDate, 'do MMMM')}, which marks the last chance make savings on your Corporation Tax bill, by making contributions to your pension.`}</Paragraph>
        <Paragraph>{`If you have spare cash in your business to save towards your retirement, you may want to make a one-off contribution before the company year end.`}</Paragraph>
        <Paragraph style={{ fontWeight: 'bold'}}>{`Always speak to an accountant to understand what is right for you and your business.`}</Paragraph>
      </>,
      onProceed: () => rootNavigate('ContributionsIntroScreen'),
      proceedButtonLabel: `Contribute Now`,
      dismissalValue: formatISO(addMonths(new Date(), 6)),
      dismissalConfirmationMessage: `We'll remind you again next year`,
      dismissalButtonLabel: `No thanks`,
      dismissalOnProceed: true,
    }
  }

  const getSuggestionBeneficiaryReview = (): SimpleSuggestion => {
    //No suggestion if no client/pension/assets or dismissed
    if (!client || !hasAnyPension || isDismissed(SuggestionIdentifier.REVIEW_BENEFICIARIES)) {
      return undefined
    }

    //No suggestion for first 2 years since opening the pension
    if (personalPension && isAfter(new Date(personalPension?.createdAt), subYears(new Date(), 2))) {
      return undefined
    }

    //No suggestion if onboarding phase not complete
    const beneficiariesOnboardingComplete = !incompleteOnboardingPhases?.includes(OnboardingPhase.FAMILY)
    if (!beneficiariesOnboardingComplete) {
      return undefined
    }

    return {
      identifier: SuggestionIdentifier.REVIEW_BENEFICIARIES,
      screen: MainAppNavScreen.DASHBOARD_LEGACY,
      priority: getPriority(SuggestionIdentifier.REVIEW_BENEFICIARIES),
      illustrationFilename: 'organise_family.png',
      title: 'Review Your Beneficiaries',
      shortDescription: `Check you have the right people or charities recorded`,
      description: <>
        <Paragraph>{`It's good to regularly review who you have nominated as beneficiaries of any remaining capital in your Jarvis SIPP, in the event of your death.`}</Paragraph>
        <AppIllustration filename={'clock.png'} style={{
          width: scaleNormalizer(80),
          height: scaleNormalizer(80),
          resizeMode: 'contain',
          alignSelf: 'center',
        }} />
        <Paragraph>{`It's been a couple of years since you setup or your pension, or did your last review of beneficiaries, so we would recommend a quick check to ensure your current wishes declared.`}</Paragraph>
      </>,
      onProceed: () => rootNavigate('BeneficiariesSetupIntroScreen'),
      proceedButtonLabel: `Review Now`,
      dismissalValue: formatISO(addMonths(new Date(), 24)),
      dismissalConfirmationMessage: `We'll remind you again in 2 years in case anything has changed`,
      dismissalButtonLabel: `No thanks`
    }
  }

  //Recalculate suggestions on change
  useEffect(() => {
    //Skip if not everything loaded
    if (!allEntitiesLoaded()) {
      Logger.debug('Skipping...allEntitiesLoaded is false')
      return
    }
    
    const newSuggestions: Array<SimpleSuggestion | undefined> = compact([
      getSuggestionOnboardingAssets(),
      getSuggestionOnboardingVerify(),
      getSuggestionOnboardingPension(),
      getSuggestionOnboardingContribute(),
      getSuggestionOnboardingConsolidate(),
      getSuggestionOnboardingProfile(),
      getSuggestionOnboardingIncomes(),
      getSuggestionOnboardingBeneficiaries(),
      getSuggestionConsolidate(),
      getSuggestionCompletedTransfers(),
      getSuggestionCompletedTraces(),
      getSuggestionAddBeneficiaries(),
      getSuggestionInviteParter(),
      getSuggestionAppReview(),
      getSuggestionFriendShare(),
      getSuggestionAnnualIncomeReview(),
      getSuggestionAnnualAssetReview(),
      getSuggestionStatePensionReview(),
      getSuggestionRetirementProfileReview(),
      getSuggestionContributeEndPersonalTaxYear(),
      getSuggestionContributeEndCompanyYear(),
      getSuggestionBeneficiaryReview(),
      getSuggestionPendingStaleTransfers(),
      getSuggestionOpenPartnerInvites(),
      getSuggestionPendingEnrolments(),
      getSuggestionOpenSchemeInvites(),
    ])

    Logger.debug({ suggestionCount: newSuggestions.length }, 'Showing suggestions')

    setSuggestions(newSuggestions)
  },[
    incompleteOnboardingPhases,
    preferences,
    client,
    spouse,
    accounts,
    retirementProfile,
    assets,
    incomes,
    beneficiaries,
    invitesAsInvitor,
    invitesAsTarget,
    currentStatePension,
    schemeInvites,
  ])

  //Function to check if all entities have finished loading
  const allEntitiesLoaded = () => {
    const result = !skip
      && (!!preferences || !!prefError)
      && (!!client || !!clientError)
      && (spouse || spouse === null || !!spouseError) //Null to account for when server returns 204 for no spouse
      && (!!accounts || !!accountsError)
      && (!!retirementProfile || !!rpError)
      && (!!assets)
      && (!!incomes)
      && (!!beneficiaries || !!beneficiariesError)
      && (!!invitesAsInvitor || !!invitesAsInvitorError)
      && (!!invitesAsTarget || !!invitesAsTargetError)
      && (!!currentStatePension || !!currentStatePensionError)
      && (!!schemeInvites)
    // Logger.debug({
    //   result,
    //   skip,
    //   preferences: !!preferences,
    //   prefError: !! prefError,
    //   client: !!client,
    //   clientError: !!clientError,
    //   spouse: !!spouse,
    //   spouseError: !!spouseError,
    //   accounts: !!accounts,
    //   accountsError: !!accountsError,
    //   retirementProfile: !!retirementProfile,
    //   rpError: !!rpError,
    //   assets: !!assets,
    //   incomes: !!incomes,
    //   beneficiaries: !!beneficiaries,
    //   beneficiariesError: !!beneficiariesError,
    //   invitesAsInvitor: !!invitesAsInvitor,
    //   invitesAsInvitorError: !!invitesAsInvitorError,
    //   currentStatePension: !!currentStatePension,
    //   currentStatePensionError: !!currentStatePensionError,
    //   schemeInvites: !!schemeInvites,
    // }, '##################')

    return result
  }

  return (
    <SuggestionsContext.Provider value={{
      suggestions,
      getSuggestionsForScreen,
      getSuggestionByIdentifier,
      getSuggestionCountPerScreen,
    }}>
      {children}
    </SuggestionsContext.Provider>
  )
}

export const useSuggestionsContext = () => useContext(SuggestionsContext)