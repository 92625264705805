import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { useURL } from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'
import { rootNavigate } from 'lib/RootNavigation'
import { setUserWithToken } from 'lib/authHelpers'
import { Logger } from 'lib/logger'
import { getStackScreenForAppContext } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { openInbox } from 'react-native-email-link'
import { useDispatch, useSelector } from 'react-redux'
import { loginParams } from 'store/authSlice'
import { AUTHTYPE } from 'store/dto/base.dto'
import { useAppSelector } from 'store/hooks'
import { usePasswordlessStartMutation, usePasswordlessTokenMutation } from 'store/passwordlessSlice'
import { workingRegistration } from 'store/tempDataSlice'
import { Flex, Sizing, Typography } from 'styles'

WebBrowser.maybeCompleteAuthSession()

const isWeb = platformIsWeb()

export const Authentication_04_Submission = ({ route, navigation }) => {
  const appContext = useSelector((state: any) => state.auth.appContext)

  const loginConfig = useAppSelector(loginParams)
  const { authType, autoLogin } = loginConfig || {}

  const nextRoute = getStackScreenForAppContext(appContext)

  const dispatch = useDispatch()
  const authUser = useSelector((state: any) => state.auth.user)
  
  const url = isWeb ? undefined : useURL()

  //Setup form for passwordless code entry
  const formObj = useForm<{ code: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      code: '',
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid } } = formObj

  //State
  const [resendDone, setResendDone] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const workingRegistrationData = useAppSelector(workingRegistration)
  const [getPasswordlessToken, { data: passwordlessToken, error: passwordlessTokenError, isLoading: passwordlessTokenIsLoading }] = usePasswordlessTokenMutation()
  const [doPasswordlessStart, { error: passwordlessStartError }] = usePasswordlessStartMutation()
  const { email } = workingRegistrationData || {}

  //Function to retrieve auth token using code
  const passwordlessGetToken = (attributes) => {
    getPasswordlessToken({ email, code: attributes.code })
  }

  const codeValue = watch('code')
  useEffect(() => {
    setCodeError(false)
  }, [codeValue])
  
  //Set codeError on passwordlessTokenError
  useEffect(() => {
    if (passwordlessTokenError) {
      setCodeError(true)
    }
  }, [passwordlessTokenError])


  //Hook to handle magic link URL for native
  // useEffect(() => {
  //   if (!isWeb && url) {
  //     const { code, email } = parsePasswordlessLoginFromUrl(url)
  //     if (code && email) {
  //       setValue('code', code, { shouldDirty: true, shouldValidate: true })
  //       passwordlessGetToken(watch())
  //     }
  //   }
  // }, [url])

  //Function to re-initiate passwordless login
  const retryPasswordlessLogin = () => {
    setValue('code', '')
    setResendDone(true)
    setCodeError(false)
    doPasswordlessStart({ email })
  }

  //Hook to handle result from passwordless token exchange
  useEffect(() => {
    if (passwordlessToken) {
      Logger.debug(`Passwordless got token - setting user...`)
      setUserWithToken(passwordlessToken, 'email', dispatch, undefined, email)
      Logger.debug(`Navigating to ${nextRoute} (App context: ${appContext})`)
      setValue('code', '')
      rootNavigate(nextRoute)
    }
  }, [passwordlessToken])

  const isLogin = authType === AUTHTYPE.LOGIN ? true : false

  const codeEntered = !!watch('code')

  return (
    <ProcessScreen
      buttonTitle={!isLogin ? 'Create Account' : 'Login'}
      buttonAction={handleSubmit(passwordlessGetToken)}
      enableButton={isValid && !codeError && !passwordlessTokenIsLoading && !passwordlessToken}
      headline={autoLogin
        ? `Ok, please enter the code below`
        : `Please check your email`
      }
      subHeading={autoLogin
        ? `It's the 6-digit code we sent to ${email}`
        : isWeb
          ? `Click the magic link from this device, or enter the 6-digit code`
          : `Tap the magic link from this device, or enter the 6-digit code`
      }
    >
      <ManagedTextInput
        name={'code'}
        mask={{
          type: 'custom',
          options: {
            mask: '999999',
          }
        }}
        placeholder={'- - - - - -'}
        returnKeyType={isValid ? 'done' : undefined}
        hideClearButton={true}
        keyboardType={'numeric'}
        style={Typography.defined.inputLarge}
        autoFocus={true}
        formObj={formObj}
        submitHandler={handleSubmit(passwordlessGetToken)}
        blurOnSubmit={true}
        rules={{
          required: true,
          minLength: {
            value: 6,
            message: 'Enter all 6 characters'
          },
          maxLength: {
            value: 6,
            message: 'Code must only be 6 characters'
          },
        }}
        informationMessage={codeError ? 'Code is invalid or has expired' : undefined}
        informationMessageIsError={true}
      />
      <>
        <View style={{
          ...Flex.row.around,
        }}>
          { !isWeb &&
            <View style={{
              width: isWeb ? undefined : '50%',
              paddingRight: Sizing.x5,
            }}>
              <View style={localStyles.buttonContainer}>
                <Button
                  icon={'email-outline'}
                  disabled={codeEntered}
                  mode={'contained'}
                  onPress={() => openInbox() }>{'Open email'}</Button>
              </View>
            </View>
          }
          <View style={{
            width: isWeb ? undefined : '50%',
            paddingLeft: Sizing.x5,
          }}>
            <View style={localStyles.buttonContainer}>
            <Button
                icon={'send'}
                disabled={!codeError && (resendDone || codeEntered)}
                mode={'contained'}
                onPress={retryPasswordlessLogin}>{'Resend code'}</Button>
            </View>
          </View>
        </View>

        {authUser.error ? <Paragraph>{authUser.error}</Paragraph> : <></>}

      </>
    </ProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingVertical: Sizing.x20,
  }
})