import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddBankConnectionVisible, showAdminAddBankConnectionVisible } from 'store/uxSlice'
import { AddBankConnectionsScreen } from './Screens/AddBankConnectionsScreen'

export const AddBankConnectionsModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddBankConnectionVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Bank Connection'
      onDismiss={() => dispatch(setShowAdminAddBankConnectionVisible(false))}
      screen={<AddBankConnectionsScreen />}
    />
  )
}

