import { ModalProcessScreen } from 'components/Layout'
import { concat, round } from 'lodash'
import React, { useEffect } from 'react'
import { useUpdateBeneficiariesMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBeneficiary, workingBeneficiary } from 'store/tempDataSlice'
import { addBeneficiaryData, setAddBeneficiaryData } from 'store/uxSlice'
import { batch } from 'react-redux'
import { ampli } from 'src/ampli'
import { UpdateBeneficiariesDto } from 'store/dto/account.dto'
import { JAR_NAME_ALL } from 'lib/constants'

export const BeneficiariesAddBeneficiary_99_Result = ({ route, navigation }) => {
  const { onDismiss } = route?.params || {}
  const addBeneficiaryDataData = useAppSelector(addBeneficiaryData)
  const { allNominations } = addBeneficiaryDataData || {}
  const dispatch = useAppDispatch()
  const workingBeneficiaryData = useAppSelector(workingBeneficiary)
  const [updateBeneficiaries, { data: beneficiaries, error, isLoading }] = useUpdateBeneficiariesMutation()

  const close = () => {
    batch(() => {
      dispatch(setAddBeneficiaryData(undefined))
      dispatch(updateWorkingBeneficiary(undefined))
    })
  }

  useEffect(() => {
    savePension()
  }, [])

  //Save on enter
  const savePension = () => {
    let oldNominations = allNominations || []
    let newNomination = {
      title: workingBeneficiaryData?.title,
      firstName: workingBeneficiaryData?.firstName,
      surname: workingBeneficiaryData?.surname,
      beneficiaryRelationship: workingBeneficiaryData?.beneficiaryRelationship,
      beneficiaryProportion: workingBeneficiaryData?.beneficiaryProportion,
    }
    
    if (workingBeneficiaryData) {
      //Set proportions...
      //Handle first new nomination
      if (!oldNominations.length) {
        newNomination.beneficiaryProportion = 1
      }
      //Handle even split
      else if (workingBeneficiaryData.evenSplit) {
        const newCount = oldNominations.length + 1
        const newProportion = round(1 / newCount, 4)
        //Update old nominations
        oldNominations = oldNominations.map(nomination => {
          return {
            ...nomination,
            beneficiaryProportion: newProportion
          }
        })
        //Update new nomination
        newNomination.beneficiaryProportion = round(1 - (newProportion * (newCount - 1)), 4)
      }

      const nominations = concat(oldNominations, newNomination)

      const payload: UpdateBeneficiariesDto = {
        nominations,
      }
      //Update beneficiaries, merging in the new one
      updateBeneficiaries(payload)
    }
  }

  //Close on save
  useEffect(() => {
    if (beneficiaries) {
      const { nominations } = beneficiaries || {}
      ampli.beneficiariesChange({
        beneficiaryCount: nominations ? nominations.length : 0,
        beneficiaryRelationships: nominations ? nominations.map(nomination => {
          return nomination.beneficiaryRelationship
        }) : [],
        changeReason: 'add',
      })
      close()
    }
  }, [beneficiaries])

  return (
    <ModalProcessScreen
      buttonTitle={'Continue'}
      error={error}
      errorTryAgain={savePension}
      errorCancel={onDismiss}
      isLoading={isLoading}
      loadingMessage={['Saving beneficiary...']}
      buttonAction={close}
      showButton={true}
      headline={`Perfect - that's all we need to know`}
      subHeading={`We'll consider ${workingBeneficiaryData?.firstName || 'them'} a named beneficiary for your ${JAR_NAME_ALL}`}
    >
    </ModalProcessScreen>
  )
}
