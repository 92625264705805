import { addDays, isAfter, startOfDay } from 'date-fns'
import { GroupSchemeEnrolmentDto, GroupSchemeEnrolmentStatus, GroupSchemeExtendedEnrolmentStatus, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { GroupSchemeMemberDto } from 'store/dto/group-scheme.dto'

export const deriveAvailableStatusChangeRequestType = (enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, pendingRequestTypes: GroupSchemeRequestType[]): GroupSchemeRequestType => {
  const { enrolmentStatus } = enrolment || {}
  if (enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE) {
    const pendingOptOut = pendingRequestTypes.includes(GroupSchemeRequestType.OPT_OUT)
    const pendingCease = pendingRequestTypes.includes(GroupSchemeRequestType.CEASE_MEMBERSHIP)
    return pendingOptOut || pendingCease
      ? undefined
      : isWithinOptOutWindow(enrolment)
        ? GroupSchemeRequestType.OPT_OUT
        : GroupSchemeRequestType.CEASE_MEMBERSHIP
  } else {
    const pendingOptIn = pendingRequestTypes.includes(GroupSchemeRequestType.OPT_IN)
    return pendingOptIn ? undefined : GroupSchemeRequestType.OPT_IN
  }
}

export const deriveExtendedEnrolmentStatus = (enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, pendingRequestTypes: GroupSchemeRequestType[]): GroupSchemeExtendedEnrolmentStatus => {
  const { enrolmentStatus } = enrolment || {}
  if (enrolmentStatus === GroupSchemeEnrolmentStatus.ACTIVE) {
    const pendingOptOut = pendingRequestTypes.includes(GroupSchemeRequestType.OPT_OUT)
    const pendingCease = pendingRequestTypes.includes(GroupSchemeRequestType.CEASE_MEMBERSHIP)
    return pendingOptOut
      ? GroupSchemeExtendedEnrolmentStatus.REQUESTED_OPT_OUT
      : pendingCease
      ? GroupSchemeExtendedEnrolmentStatus.REQUESTED_CEASE
      : GroupSchemeExtendedEnrolmentStatus.ACTIVE
  } else {
    const pendingOptIn = pendingRequestTypes.includes(GroupSchemeRequestType.OPT_IN)
    return pendingOptIn
      ? GroupSchemeExtendedEnrolmentStatus.REQUESTED_OPT_IN
      : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT
      ? GroupSchemeExtendedEnrolmentStatus.OPTED_OUT
      : GroupSchemeExtendedEnrolmentStatus.INACTIVE
  }
}

export const isWithinOptOutWindow = (enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto | GroupSchemeMemberDto ): boolean => {
  const { autoEnrolmentWindowOptOutDate } = enrolment || {}
  const now = new Date()
  const optOutBoundaryDate = autoEnrolmentWindowOptOutDate ? addDays(startOfDay(new Date(autoEnrolmentWindowOptOutDate)), 1) : undefined
  return !!optOutBoundaryDate && isAfter(optOutBoundaryDate, now)
}