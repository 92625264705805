import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetAssetCategoryQuery, useUpdateAssetCategoryMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminAssetCategoryDataId, setCurrentAdminAssetCategoryDataId } from 'store/uxSlice'
import { Colors, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AdminEditAssetCategoryScreen = () => {
  const currentAssetCategoryId = useAppSelector(currentAdminAssetCategoryDataId)

  const { data: assetCategory, isLoading: assetCategoryIsLoading, error: assetCategoryError } = useGetAssetCategoryQuery(currentAssetCategoryId, { skip: !currentAssetCategoryId } )
  const [updateAssetCategory, { data: updatedAssetCategory, isLoading: assetCategoryUpdateIsLoading, error: assetCategoryUpdateError }] = useUpdateAssetCategoryMutation()
  const dispatch = useAppDispatch()

  const isWeb = platformIsWeb()

  const formObj = useForm<{
    name: string
    shortDescription: string
    longDescription: string
    priority: number
    logo: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: assetCategory?.name,
      shortDescription: assetCategory?.shortDescription,
      longDescription: assetCategory?.longDescription,
      priority: assetCategory?.priority,
      logo: assetCategory?.logo,
    }
  })
  const { handleSubmit, register, setValue, setError, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger()
  }

  const handleClearImage = () => {
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')

  useEffect(() => {
    if (assetCategory) {
      setValue('name', assetCategory?.name)
      setValue('shortDescription', assetCategory?.shortDescription)
      setValue('longDescription', assetCategory?.longDescription)
      setValue('logo', assetCategory?.logo)
      setValue('priority', assetCategory?.priority)
    }
  }, [assetCategory])

  //Form refs for focussing
  const nameRef = useRef(null)
  const shortDescriptionRef = useRef(null)
  const longDescriptionRef = useRef(null)
  const priorityRef = useRef(null)

  const onSubmit = async attributes => {
    await updateAssetCategory({ id: assetCategory.id, ...attributes })
  }

  useEffect(() => {
    if (updatedAssetCategory) {
      close()
    }
  }, [updatedAssetCategory])

  const close = () => {
    dispatch(setCurrentAdminAssetCategoryDataId(undefined))
  }

  const isLoading = assetCategoryIsLoading ||  assetCategoryUpdateIsLoading
  const error: any = assetCategoryError || assetCategoryUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const name = assetCategory?.name

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={assetCategoryUpdateIsLoading ? ['Saving assetCategory...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: assetCategory?.id,
            copyableValue: true,
          },
          {
            label: `Internal Code`,
            value: assetCategory?.code,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => shortDescriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }}/>
      <ManagedTextInput
        ref={shortDescriptionRef}
        name={'shortDescription'}
        formObj={formObj}
        label={'Short Description'}
        placeholder={'Short Description for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => longDescriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
        }}/>
      <ManagedTextInput
        ref={longDescriptionRef}
        name={'longDescription'}
        formObj={formObj}
        label={'Long Description'}
        placeholder={'Long Description for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 200,
        }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Paragraph>{'Priority (for display order)'}</Paragraph>
      <ManagedIntegerInput
        ref={priorityRef}
        name={'priority'}
        formObj={formObj}
        label={'Priority'}
        returnKeyType={'done'}
        blurOnSubmit={true}
        required={true}
        minValue={1}
        maxValue={1000}
      />
    </ModalProcessScreen>
  )
}

