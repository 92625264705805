import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Logger } from 'lib/logger'
import { toUpper, trim, trimEnd, trimStart, isNil } from 'lodash'
import React, { ComponentProps, forwardRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Keyboard, Platform, TextStyle, View } from 'react-native'
import { TextInputMask, TextInputMaskOptionProp, TextInputMaskTypeProp } from 'react-native-masked-text'
import { TextInput } from 'react-native-paper'
import { Sizing, Paper, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'

export type ManagedTextInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  name: string
  rules: any
  mask?: ManagedTextInputMaskProps
  submitHandler?: any
  hideClearButton?: boolean
  informationMessage?: string
  informationMessageIsError?: boolean
  autoTrim?: 'start' | 'end' | 'both' | 'none'
  forceCapitals?: boolean
}

type ManagedTextInputMaskProps = {
  type: TextInputMaskTypeProp
  options: TextInputMaskOptionProp 
}

export const ManagedTextInput = forwardRef((props: ManagedTextInputProps, ref: any) => {
  const { name, mask, label, style, rules, formObj, submitHandler, hideClearButton, placeholder, returnKeyType, disabled, left, informationMessage, informationMessageIsError, autoTrim, forceCapitals } = props
  const { control, trigger, setValue, formState: { errors, isValid } } = formObj

  const { colors: themeColors } = Paper.useAppTheme()

  const trimming = autoTrim || 'both'
  
  const fieldErrors = errors[name]
  const hasError = fieldErrors ? true : false

  const clearValue = () => {
    setValue(name, '', { shouldDirty: true })
    trigger(name)
  }

  //Derive the best placeholder
  const getPlaceholder = () => {
    if (placeholder) {
      return placeholder
    }
    if (mask) {
      switch(mask?.type) {
        case 'custom':
          return  mask.options.mask
        case 'money':
          return `${mask.options.unit}9${mask.options.separator},999`
        default:
          return placeholder
      }
    }
  }

  const centered = !label

  const textInputStyles: TextStyle = centered ? { textAlign: 'center' } : {}

  const textInputProps: ComponentProps<typeof TextInput> = {
    ...props,
    theme: { colors: { text: themeColors.inputText }},
    style: [Typography.defined.input, textInputStyles, style],
    placeholder: getPlaceholder(),
    mode: 'flat',
    error: hasError,
  }

  if (mask) {
    textInputProps.render = renderProps => {
      return <TextInputMask
        type={mask.type}
        options={mask.options}
        refInput={ref}
        {...renderProps}
      />
      }
  }

  const getBestReturnKeyType = (textInputProps: ComponentProps<typeof TextInput>, isValid: boolean) => {
    const { returnKeyType, keyboardType } = textInputProps
    //Always use 'done' for numeric keypad on iOS when valid
    if (Platform.OS === 'ios' && keyboardType === 'numeric') {
      return isValid ? 'done' : undefined
    }
    return returnKeyType ? returnKeyType : 'done'
  }

  const doForceCapitalize = text => {
    return forceCapitals ? toUpper(text) : text
  }

  const doTrim = text => {
    if (text) {
      const trimmed = 
        trimming === 'both' ? trim(text)
        : trimming === 'start' ? trimStart(text)
        : trimming === 'end' ? trimEnd(text)
        : text
      return trimmed
    }
    return text
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <>            
            <TextInput
              {...textInputProps}
              returnKeyType={getBestReturnKeyType(textInputProps, isValid)}
              ref={mask ? undefined : ref}
              value={value}
              onBlur={onBlur}
              onEndEditing={(e: any) => {
                //Trim
                const text = e?.nativeEvent?.text
                onChange(doTrim(text))
              }}
              onChangeText={text => {
                onChange(doForceCapitalize(text))
              }}
              onSubmitEditing={() => {
                //Trim
                onChange(doTrim(value))
                if(submitHandler) {
                  try {
                    submitHandler()
                  } catch(e) {
                    Logger.error(`Submit handler failed`, e)
                  }
                } else {
                  Keyboard.dismiss()
                }
              }}
              left={
                left
                  ? left
                  : !disabled && value && centered && !hideClearButton
                    ? <TextInput.Icon icon='' style={{ backgroundColor: 'transparent' }}/>
                    : undefined
              }
              right={
                !disabled && !isNil(value) && !hideClearButton ?
                  <TextInput.Icon icon={'close-circle'} size={Sizing.x20} color={themeColors.primary} onPress={clearValue} forceTextInputFocus={false} style={{ backgroundColor: 'transparent' }}/>
                : undefined
              }
            />
            <InputErrorMessages formObj={formObj} name={name} informationMessage={informationMessage} informationMessageIsError={informationMessageIsError} />
          </>
        )}
        name={name}
      />
    </View>
  )
})