import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useDeleteRetirementIncomeMutation, useGetMeQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { editRetirementIncome, setEditRetirementIncome } from 'store/uxSlice'
import { RetirementIncomesEditIncomeScreen } from './Screens/RetirementIncomesEditIncomeScreen'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { Logger } from 'lib/logger'
import { ampli } from 'src/ampli'
import { pick } from 'lodash'

export type RetirementAssetsEditIncomeModalProps = {
  income: RetirementIncomeDto
  onDismiss?: any
}

export const RetirementIncomesEditIncomeModal = (props: RetirementAssetsEditIncomeModalProps) => {
  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditRetirementIncome(undefined))

  const income = props.income
  const onDismiss = props.onDismiss ? props.onDismiss : globalDismiss
  
  const [ deleteIncome ] = useDeleteRetirementIncomeMutation()

  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading } = useGetRetirementProfileQuery()
  const { data: me, error: meError, isLoading: meIsLoading } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading } = useGetSpouseQuery()

  //Disabled if spouse with a real user
  const isSpouse = income?.clientId === spouse?.id
  const spouseControlled = isSpouse && !!spouse?.userId  

  const handleDelete = () => {
    Logger.info(`Deleting income...`)
    ampli.retirementIncomeDelete({
      ...pick(income, [
        'incomeType',
        'annualIncomeAmount',
        'incomeStartDate',
        'incomeStartAge',
      ]),
      isSpouse,
    })
    deleteIncome(income.id)
    onDismiss()
  }

  const error: any = rpError || meError
  const isLoading = rpIsLoading || meIsLoading || spouseIsLoading

  return (
    <ModalFormBuilder
      isLoading={isLoading}
      error={error}
      visible={!!income}
      formTitle='Update Income'
      onDismiss={onDismiss}
      onDelete={spouseControlled ? undefined : handleDelete}
      deleteDialogText='Really delete this income?'
      screen={<RetirementIncomesEditIncomeScreen
        income={income}
        onDismiss={onDismiss}
        disabled={spouseControlled}
        me={me}
        spouse={spouse}
        retirementProfile={retirementProfile}
      />}
    />
  )
}