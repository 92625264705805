import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Subheading } from 'components/Typography'
import { unformatNationalInsuranceNumber } from 'lib/clientHelpers'
import { MAX_CLIENT_AGE, MIN_CLIENT_AGE, NATIONAL_INSURANCE_NO_MASK } from 'lib/constants'
import { getActionDate } from 'lib/dateHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMembersQuery } from 'store/apiSlice'
import { Address, Gender, Title } from 'store/dto/base.dto'
import { CreateGroupSchemeJobDto, GroupSchemeEnrolmentPayrollFrequency, GroupSchemeJobDataSetFormat, GroupSchemeJobType } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setShowEmployerAddEnrolmentJobVisible, setSnackbarData } from 'store/uxSlice'

export const EmployerAddEnrolmentJobScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  // const [cursorPosition, setCursorPosition] = useState(0)

  const [addMemberJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError }] = useAddGroupSchemeJobWithDataMutation()

  const [niNumberToCheck, setNiNumberToCheck] = useState(undefined)
  const [matchingMembers, setMatchingMembers] = useState(undefined)

  const { data: members, isLoading: membersIsLoading, error: membersError, isFetching: membersIsFetching, refetch: refetchMembers } = useGetGroupSchemeMembersQuery({
    groupSchemeId: currentScheme?.id,
    nationalInsuranceNo: niNumberToCheck
  }, { skip: !niNumberToCheck})
  
  const dispatch = useAppDispatch()

  const formObj = useForm<{
    nationalInsuranceNo: string
    employeeEmail: string
    employeeId?: string
    title?: Title
    firstName: string
    surname: string
    gender: Gender
    birthDate: string
    addressBuilding: string
    addressStreet1: string
    addressTown: string
    addressPostCode: string
    employmentStartDate?: string
    enrolmentDate?: string
    autoEnrolmentWindowOptOutDate?: string
    payrollFrequency?: GroupSchemeEnrolmentPayrollFrequency
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      nationalInsuranceNo: '',
      employeeEmail: '',
      employeeId: '',
      firstName: '',
      surname: '',
      birthDate: '',
      addressBuilding: '',
      addressStreet1: '',
      addressTown: '',
      addressPostCode: '',
    },
  })
  //Form refs for focussing
  const nationalInsuranceNoRef = useRef(null)
  const employeeEmailRef = useRef(null)
  const employeeIdRef = useRef(null)
  const firstNameRef = useRef(null)
  const surnameRef = useRef(null)
  const birthDateRef = useRef(null)
  const addressBuildingRef = useRef(null)
  const addressStreet1Ref = useRef(null)
  const addressTownRef = useRef(null)
  const addressPostCodeRef = useRef(null)
  const employmentStartDateRef = useRef(null)
  const enrolmentDateRef = useRef(null)
  const autoEnrolmentWindowOptOutDateRef = useRef(null)

  const { handleSubmit, setValue, setError, trigger, watch, formState: { isDirty, isValid } } = formObj

  const onSubmit = async attributes => {
    const { nationalInsuranceNo, addressBuilding, addressStreet1, addressTown, addressPostCode, ...remaining } = attributes
    const addressDetail: Address = {
      building: addressBuilding,
      street1: addressStreet1,
      town: addressTown,
      postCode: addressPostCode,
      country: 'GB',
    } 
    const data: CreateGroupSchemeJobDto = {
      groupSchemeId: currentScheme.id,
      jobType: GroupSchemeJobType.MEMBER,
      dataSetFormat: GroupSchemeJobDataSetFormat.JSON,
      memberRecords: [
        {
          nationalInsuranceNo: unformatNationalInsuranceNumber(nationalInsuranceNo),
          addressDetail,
          ...remaining,
        }
      ]
    }
    addMemberJob(data)
  }

  const nationalInsuranceNo = watch('nationalInsuranceNo')
  
  //Update niNumberToCheck when value changes
  useEffect(() => {  
    setMatchingMembers(null)
    setNiNumberToCheck(nationalInsuranceNo && nationalInsuranceNo.length === NATIONAL_INSURANCE_NO_MASK.length ? unformatNationalInsuranceNumber(nationalInsuranceNo) : undefined)
  }, [nationalInsuranceNo])

  //Force refetch when NI number to check changes
  //NOTE: Without doing this, there seems to be a race condition of some kind with form error
  //states becoming out of sync, resulting in the error messages not being displayed consistently
  useEffect(() => {  
    if (niNumberToCheck) {
      refetchMembers()
    }    
  }, [niNumberToCheck])

  //Update matchingMembers based on check result
  useEffect(() => {  
    if (membersError || membersIsLoading || membersIsFetching) {
      setMatchingMembers(null)
    } else if (members) {
      setMatchingMembers(members)
    }
  }, [members, membersError, membersIsLoading, membersIsFetching])

  //Trigger validation when available changes
  useEffect(() => {
    if (matchingMembers !== undefined) {
      trigger('nationalInsuranceNo')
    }
  }, [matchingMembers])

  useEffect(() => {
    if (addedJob) {
      dispatch(setSnackbarData({
        message: `Member Enrolment Job added!`,
        subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
        iconName: 'check-circle-outline',
        duration: 5000,
      }))
      close()
    }
  }, [addedJob])

  const close = () => {
    dispatch(setShowEmployerAddEnrolmentJobVisible(false))
  }

  const isLoading = addJobIsLoading
  const error: any = addJobError

  const isValidNiNumber = (value: string) => {
    if (value === undefined) {
      return true
    }
    const result = value.match(/^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s?\d){6}\s?[A-D]$/)
    return result ? true : 'Invalid NI Number - please double check'
  }

  const isNotEnrolledNiNumber = () => {
    if (matchingMembers === null) {
      return 'Validating...'
    }
    return matchingMembers && matchingMembers?.length === 0 ? true : `Existing scheme member exists for this national insurance number.`
  }

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={addJobError ? handleSubmit(onSubmit) : undefined}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={['Creating enrolment job...']}
      buttonTitle={'Request Enrolment'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <Subheading>{'Identification'}</Subheading>
      <ManagedTextInput
        ref={nationalInsuranceNoRef}
        name={'nationalInsuranceNo'}
        autoFocus={false}
        formObj={formObj}
        label={'National Insurance Number'}
        mask={{
          type: 'custom',
          options: {
            mask: NATIONAL_INSURANCE_NO_MASK,
          }
        }}
        // onSelectionChange={(event) => {
        //   if (event?.nativeEvent?.selection?.start) {
        //     setCursorPosition(event?.nativeEvent?.selection?.start)
        //   }
        // }}
        // keyboardType={cursorPosition < 2 || cursorPosition > 10 ? 'default' : 'numeric'}
        // autoCapitalize={cursorPosition < 2 || cursorPosition > 10 ? 'characters' : undefined}
        autoCapitalize={'characters'}
        forceCapitals={true}
        returnKeyType={'next'}
        submitHandler={() => employeeEmailRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          maxLength: {
            value: NATIONAL_INSURANCE_NO_MASK.length,
            message: 'Must be exactly 9 characters'
          },
          validate: {
            isValidNiNumber,
            isNotEnrolledNiNumber,
          }
      }} />
      <ManagedTextInput
        ref={employeeEmailRef}
        name={'employeeEmail'}
        keyboardType='email-address'
        formObj={formObj}
        label={'Email Address'}
        placeholder={'Their email address'}
        autoCapitalize={'none'}
        returnKeyType={'next'}
        submitHandler={() => employeeIdRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid email address"
          },
          required: true,
          minLength: 2,
          maxLength: 100,
      }} />
      <ManagedTextInput
        ref={employeeIdRef}
        name={'employeeId'}
        formObj={formObj}
        label={'Employee ID'}
        placeholder={'Your payroll system identifier'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: false,
          minLength: 2,
          maxLength: 40,
      }}/>
      <Subheading>{'Personal Details'}</Subheading>
      <ManagedAutoCompleteInput
        name={'title'}
        formObj={formObj}
        label={'Title'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(Title)}
        required={true}
      />
      <ManagedTextInput
        ref={firstNameRef}
        name={'firstName'}
        formObj={formObj}
        label={'First Name'}
        placeholder={'Their first name'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => surnameRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
        }}/>
      <ManagedTextInput
        ref={surnameRef}
        name={'surname'}
        formObj={formObj}
        label={'Surname'}
        placeholder={'Their surname'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }}/>
      <ManagedAutoCompleteInput
        required={true}
        formObj={formObj}
        name={'gender'}
        label={'Gender'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(Gender)}
      />
      <ManagedDateInput
        ref={birthDateRef}
        name={'birthDate'}
        formObj={formObj}
        label={'Date of Birth'}
        blurOnSubmit={false}
        required={true}
        submitHandler={() => addressBuildingRef.current?.focus()}
        mustBeInPast={true}
        notOlderThanYears={MAX_CLIENT_AGE}
        notYoungerThanYears={MIN_CLIENT_AGE}
        tryParseTwoDigitYear={true}
        showCurrentAgeMessage={true}
      />
      <Subheading>{'Address'}</Subheading>
      <ManagedTextInput
        ref={addressBuildingRef}
        name={`addressBuilding`}
        formObj={formObj}
        label={'Building Number/Name'}
        placeholder={'Enter building number/name'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => addressStreet1Ref.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={addressStreet1Ref}
        name={`addressStreet1`}
        formObj={formObj}
        label={'Street'}
        placeholder={'Enter street'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => addressTownRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={addressTownRef}
        name={`addressTown`}
        formObj={formObj}
        label={'Town'}
        placeholder={'Enter town/locality'}
        returnKeyType={'next'}
        autoCapitalize={'words'}
        submitHandler={() => addressPostCodeRef.current?.focus()}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={addressPostCodeRef}
        name={`addressPostCode`}
        formObj={formObj}
        label={'Post Code'}
        placeholder={'Enter postcode'}
        autoCapitalize={'characters'}
        returnKeyType={'next'}
        submitHandler={() => employmentStartDateRef.current?.focus()}
        rules={{
          required: true,
          minLength: 1,
          maxLength: 10,
        }} />
      <Subheading>{'Employment/Enrolment Details'}</Subheading>
      <ManagedDateInput
        ref={employmentStartDateRef}
        name={'employmentStartDate'}
        formObj={formObj}
        label={'Employment Start Date'}
        blurOnSubmit={false}
        required={false}
        submitHandler={() => enrolmentDateRef.current?.focus()}
      />
      <ManagedDateInput
        ref={enrolmentDateRef}
        name={'enrolmentDate'}
        formObj={formObj}
        label={'Pension Scheme Enrolment Date'}
        blurOnSubmit={false}
        required={false}
        submitHandler={() => autoEnrolmentWindowOptOutDateRef.current?.focus()}
      />
      <ManagedDateInput
        ref={autoEnrolmentWindowOptOutDateRef}
        name={'autoEnrolmentWindowOptOutDate'}
        formObj={formObj}
        label={'Auto Enrolment Opt-Out Window End Date'}
        blurOnSubmit={false}
        required={true}
      />
      <ManagedAutoCompleteInput
        required={false}
        formObj={formObj}
        name={'payrollFrequency'}
        label={'Payroll Cycle Frequency'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
      />
    </ModalProcessScreen>
  )
}

