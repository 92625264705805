import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { Image } from 'react-native'
import { GroupSchemeDto } from 'store/dto/group-scheme.dto'
import { Paper, Sizing } from 'styles'

type AdminGroupSchemeListItemProp = {
  groupScheme: GroupSchemeDto
  onPressFunction?: () => void
}
export const AdminGroupSchemeListItem = (props: AdminGroupSchemeListItemProp) => {
  const { groupScheme, onPressFunction } = props
  const { name, organization, employerPensionSchemeReference, status } = groupScheme

  const { name: employerName, companyNo, logo } = organization

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = status
  const subTitle = `${employerName} / ${companyNo}`
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{employerPensionSchemeReference}</Text>}
    />
  )
}
