import { GroupSchemeDto } from 'store/dto/group-scheme.dto'

export const canUserAdministerScheme = (scheme: GroupSchemeDto, userId: string) => {
  const { organization } = scheme || {}
  const { users, primaryContactUser } = organization || {}

  if (!userId) {
    return false
  }

  //Primary
  if (primaryContactUser?.id === userId) {
    return true
  }

  //General users
  if (!users) {
    return false
  }

  return !!users.find(user => {
    return user.id === userId
  })
}

export const canUserExecutePaymentsForScheme = (scheme: GroupSchemeDto, userId: string) => {
  const { organization } = scheme || {}
  const { primaryContactUser, financeUser } = organization || {}
  return userId && (primaryContactUser?.id === userId || financeUser?.id === userId)
}
