import { PaymentMethod } from './account.dto';
import { Address, BaseDatedDto, SimpleUserDto } from './base.dto';
import { BankDetails } from './client.dto';

export enum GroupOrganizationStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface GroupOrganizationDto extends BaseDatedDto {
  type: string
  id: string
  name: string
  displayName: string
  logo: string
  organizationType: string
  status: GroupOrganizationStatus
  companyNo: string
  payeReference: string
  taxReference: string
  registeredOfficeAddressDetail: Address
  tradingAddressDetail: Address
  advisorName: string
  advisorEmail: string
  affiliateId?: string
  bankId: string
  bankDetails: BankDetails
  apiKey?: string
  apiDisabled: boolean
  mandateStatus?: GroupOrganizationMandateStatus
  defaultContributionMethod: PaymentMethod
  primaryContactUser: SimpleUserDto
  financeUser?: SimpleUserDto
  users?: SimpleUserDto[]
}

export interface GroupOrganizationFilterDto {
  search?: string
  status?: GroupOrganizationStatus
}

export interface CreateGroupOrganizationDto {
  name: string
  displayName: string
  logo: string
  companyNo: string
  payeReference?: string
  taxReference?: string
  registeredOfficeAddressDetail: Address
  tradingAddressDetail: Address
  advisorName: string
  advisorEmail: string
  primaryContactFirstName: string
  primaryContactSurname: string
  primaryContactEmail: string
  affiliateId?: string
  bankId: string
  bankDetails: BankDetails
  generateApiKey?: string
  apiDisabled: boolean
  financeUserFirstName?: string
  financeUserSurname?: string
  financeUserEmail?: string
  mandateStatus?: GroupOrganizationMandateStatus
}

export interface UpdateGroupOrganizationDto {
  id: string
  name?: string
  displayName?: string
  logo?: string
  status?: GroupOrganizationStatus
  payeReference?: string
  taxReference?: string
  affiliateId?: string
  apiDisabled?: boolean
  financeUserFirstName?: string
  financeUserSurname?: string
  financeUserEmail?: string
  mandateStatus?: GroupOrganizationMandateStatus
  defaultContributionMethod?: PaymentMethod
}

export interface GroupOrganizationMandateDto {
  type: string
  id: string
  organizationId: string
  status: GroupOrganizationMandateStatus
  contributionSortCode?: string
  contributionAccountLast4?: string
  url?: string
  setupUrl?: string
}

export interface CreateGroupOrganizationMandateDto {
  id: string
  sendEmail: boolean
}

export enum GroupOrganizationSearchStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ANY = 'Any',
}

export enum GroupOrganizationMandateStatus {
  REQUESTED = 'Requested',
  NOT_REQUESTED = 'Not Requested',
  NOT_REQUIRED = 'Not Required',
  AWAITING_AUTH = 'Awaiting Authorisation',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}