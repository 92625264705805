import { Text } from 'components/Typography/Text'
import { rootNavigate } from 'lib/RootNavigation'
import { getOnboardingPlanMessages } from 'lib/loadingHelpers'
import { groupBy, last, first, remove } from 'lodash'
import { OnboardingPhase, OnboardingState, useGuidanceContext } from 'providers/guidance.context'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Paragraph, ThemeProvider } from 'react-native-paper'
import { useGetCurrentUserQuery, useGetInvitesAsTargetQuery, useGetMeQuery, useGetOpenSchemeInviteByIdQuery, useGetPendingEnrolmentByIdQuery } from 'store/apiSlice'
import { Flex, Paper, Sizing, Typography } from 'styles'
import { NamedInformation, NamedInformationButton } from './InformationButton'
import { Loading } from './Loading'
import { OnboardingCard } from './OnboardingCard'
import { Button } from './Button'

export const OnboardingProgress = () => {

  const [remainingExpanded, setRemainingExpanded] = useState(false)
  const expandRemaining = () => setRemainingExpanded(true)
  const collapseRemaining = () => setRemainingExpanded(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const { onboardingStates, suggestedOnboardingPhase, incompleteOnboardingPhases } = useGuidanceContext()

  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery()
  const { data: client, error: clientError, isLoading: clientIsLoading } = useGetMeQuery()
  const { data: invitesAsTarget, error: invitesAsTargetError, isLoading: invitesAsTargetIsLoading, refetch: refetchInvitesAsTarget } = useGetInvitesAsTargetQuery()
  const { data: openSchemeInvite, isSuccess: openSchemeInviteIsSuccess, error: openSchemeInviteError, isLoading: openSchemeInviteIsLoading, refetch: refetchOpenSchemeInvite } = useGetOpenSchemeInviteByIdQuery(user?.preferredSchemeInviteId, { skip: !user?.preferredSchemeInviteId })
  const { data: pendingEnrolment, isSuccess: pendingEnrolmentIsSuccess, error: pendingEnrolmentError, isLoading: pendingEnrolmentIsLoading, refetch: refetchPendingEnrolment } = useGetPendingEnrolmentByIdQuery(user?.preferredSchemeEnrolmentId, { skip: !user?.preferredSchemeEnrolmentId })

  const hasOpenInvite = invitesAsTarget?.length

  //Hide if phase not yet determined or all of
  //1. no invites
  //2. no scheme invite
  //3. onboarding complete
  const hide = suggestedOnboardingPhase === undefined || suggestedOnboardingPhase === OnboardingPhase.COMPLETE

  type ExtendedOnboardingState = OnboardingState & {
    noProgressBar?: boolean
    onPress?: any
    isDismissed?: boolean
  }

  //If invite(s) include a card for this
  let inviteOnboardingState: ExtendedOnboardingState = undefined
  //Only if not hidden...
  if (!hide) {
    //Visible if:
    //1. There is currently hasOpenInvite
    //2. Within this app session, onboarding has been active AND an invite has been processed

    if (hasOpenInvite || client?.onboardingFlags?.acceptedinvite) {
      const multiple = invitesAsTarget?.length > 1
      const singleInvite = invitesAsTarget?.length === 1 ? invitesAsTarget[0] : undefined
    
      const invitorName = singleInvite?.from
        ? singleInvite?.from?.firstName && singleInvite?.from?.surname
          ? `${singleInvite?.from?.firstName} ${singleInvite?.from?.surname}`
          : singleInvite?.from?.email
        : 'Someone'

      inviteOnboardingState = {
        onboardingPhase: OnboardingPhase.CONNECT,
        title: `Accept Partner Invitation`,
        subTitle: '',
        shortMessage: multiple
          ? `You have received multiple invitations to link Jarvis accounts`
          : `${invitorName} sent an invitation to link Jarvis accounts`,
        illustrationFilename: 'accept_invitation.png',
        isLoading: false,
        error: null,
        complete: !hasOpenInvite,
        isDismissed: !hasOpenInvite,
        totalSteps: 1,
        pendingSteps: 0,
        notRequiredSteps: 0,
        completedSteps: 0,
        remainingSteps: 0,
        steps: [],
        completeButtonTitle: '',
        completeAction: () => {},
        onPress: hasOpenInvite ? () => rootNavigate('InviteAcceptIntroScreen') : () => {},
      }
    }
  }

  //If scheme invite include a card for this...
  let schemeInviteOnboardingState: ExtendedOnboardingState = undefined
  //Only if not hidden...
  if (!hide) {
    //Visible if:
    //1. There is currently an openSchemeInvite
    //2. A scheme invited has been recorded in onboardingFlags (shown as complete)
    if (openSchemeInvite || client?.onboardingFlags?.aceptedschemeinvite) {
      const { groupScheme } = openSchemeInvite || {}
      const { organizationName, logo } = groupScheme || {}

      schemeInviteOnboardingState = {
        onboardingPhase: OnboardingPhase.SCHEME_CONNECT,
        title: 'Workplace Scheme Invite',
        subTitle: '',
        shortMessage: `${organizationName} invited you to join their workplace scheme`,
        illustrationFilename: logo ? undefined : 'envelope_with_report.png',
        imageSource: logo ? { uri: logo } : undefined,
        isLoading: false,
        error: null,
        complete: !!client?.onboardingFlags?.aceptedschemeinvite,
        isDismissed: !!client?.onboardingFlags?.aceptedschemeinvite,
        totalSteps: 1,
        pendingSteps: 0,
        notRequiredSteps: 0,
        completedSteps: 0,
        remainingSteps: 0,
        steps: [],
        completeButtonTitle: '',
        completeAction: () => {},
        onPress: openSchemeInvite
          ? () => rootNavigate('SchemeInviteAcceptIntroScreen', { schemeInvite: openSchemeInvite })
          : () => {},
      }
    }
  }

  //If pending scheme enrolment include a card for this...
  let schemeEnrolmentOnboardingState: ExtendedOnboardingState = undefined
  //Only if not hidden...
  if (!hide) {
    //Visible if:
    //1. There is currently a pendingEnrolment
    //2. A scheme enrolment has been recorded in onboardingFlags (shown as complete)
    if (pendingEnrolment || client?.onboardingFlags?.setupscheme) {
      const { groupScheme } = pendingEnrolment || {}
      const { organizationName, logo } = groupScheme || {}

      schemeEnrolmentOnboardingState = {
        onboardingPhase: OnboardingPhase.SCHEME_ENROL,
        title: 'Connect Employer',
        subTitle: '',
        shortMessage: `${organizationName} has enrolled you in their workplace scheme`,
        imageSource: logo ? { uri: logo } : undefined,
        illustrationFilename: logo ? undefined : 'envelope_with_report.png',
        isLoading: false,
        error: null,
        complete: !!client?.onboardingFlags?.setupscheme,
        isDismissed: !!client?.onboardingFlags?.setupscheme,
        totalSteps: 1,
        pendingSteps: 0,
        notRequiredSteps: 0,
        completedSteps: 0,
        remainingSteps: 0,
        steps: [],
        completeButtonTitle: '',
        completeAction: () => {},
        onPress: pendingEnrolment
          ? () => rootNavigate('SchemeEnrolmentSetupIntroScreen', { enrolment: pendingEnrolment })
          : () => {},
      }
    }
  }

  //Map the onboarding states to items
  const onboardingPathwayItems: ExtendedOnboardingState[] = onboardingStates ? onboardingStates.map(state => {
    return {
      ...state,
      isDismissed: state?.onboardingPhase === OnboardingPhase.PROFILE || !incompleteOnboardingPhases?.includes(state?.onboardingPhase)
    }
  }) : []

  //Add the scheme enrolment step if needed
  if (schemeEnrolmentOnboardingState) {
    //Adds in second place (or at end if no items)
    //This accounts for the fake 'Create Profile' card (see guidance.context)
    onboardingPathwayItems.splice(1, 0, schemeEnrolmentOnboardingState)
  }

  //Add the scheme invite step if needed
  if (schemeInviteOnboardingState) {
    //Adds in second place (or at end if no items)
    //This accounts for the fake 'Create Profile' card (see guidance.context)
    onboardingPathwayItems.splice(1, 0, schemeInviteOnboardingState)
  }

  //Add the invite step if needed
  if (inviteOnboardingState) {
    //Adds in second place (or at end if no items)
    //This accounts for the fake 'Create Profile' card (see guidance.context)
    onboardingPathwayItems.splice(1, 0, inviteOnboardingState)
  }

  //Derive the suggested step index, accounting for the invite and scheme invite steps
  const adjustedSuggestedPhase =
    inviteOnboardingState && !inviteOnboardingState?.complete
    ? OnboardingPhase.CONNECT 
    : schemeInviteOnboardingState && !schemeInviteOnboardingState?.complete
    ? OnboardingPhase.SCHEME_CONNECT
    : schemeEnrolmentOnboardingState && !schemeEnrolmentOnboardingState?.complete
    ? OnboardingPhase.SCHEME_ENROL
    : suggestedOnboardingPhase

  //Show loading if onboarding phase has been determined but states not yet derived
  const isLoading = suggestedOnboardingPhase !== undefined && onboardingPathwayItems?.length === 0

  const groupedPathwayItems = groupBy(onboardingPathwayItems, 'isDismissed')
  const dismissedPathwayItems = groupedPathwayItems[true] || []
  const remainingPathwayItems = groupedPathwayItems[false] || []
  const currentPathwayItems = remove(remainingPathwayItems,  function(i) {
    return i.onboardingPhase === adjustedSuggestedPhase
  })

  return (
    <>
      {
        hide ? <></> :
          <View style={{ paddingBottom: Sizing.x10 }}>
            <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
              <>
                <ThemeProvider theme={Paper.lightTheme}>
                  <View style={Flex.row.center} >
                    <View style={{ width: Sizing.x30, paddingRight: Sizing.x10 }}>
                    </View>
                    <Text style={localStyles.pathwayTitle}>{'Get Organised'}</Text>
                    <View style={{ width: Sizing.x30, paddingLeft: Sizing.x10 }}>
                      <NamedInformationButton name={NamedInformation.ORGANISE_RETIREMENT} iconOnly={true} iconOnlySize={Sizing.x15} />
                    </View>
                  </View>
                  {
                    isLoading ?
                      <View style={{ paddingVertical: Sizing.x20 }}>
                        <Loading size={Sizing.x20} message={getOnboardingPlanMessages()} messageNoLoop={true} />
                      </View>
                    : <></>
                  }
                </ThemeProvider>
                {
                  isLoading ? <></> :
                    <>
                      {
                        dismissedPathwayItems?.length > 0
                        ? <OnboardingCard
                            key={`complete-0`}
                            isFirst={true}
                            isLast= {false}
                            isComplete={true}
                            isDismissed={true}
                            isCurrent={false}
                            isActive={false}
                            onPress={() => {}}
                            state={{
                              onboardingPhase: OnboardingPhase.DUMMY,
                              title: `${last(dismissedPathwayItems)?.title}${dismissedPathwayItems?.length > 1 ? `, and ${dismissedPathwayItems?.length - 1} other step${dismissedPathwayItems?.length > 2 ? 's' : ''}` : ``}`,
                              subTitle: ``,
                              shortMessage: ``,
                              illustrationFilename: 'thanks.png',
                              isLoading: false,
                              error: null,
                              complete: true,
                              totalSteps: 0,
                              pendingSteps: 0,
                              notRequiredSteps: 0,
                              completedSteps: 0,
                              remainingSteps: 0,
                              steps: [],
                              completeButtonTitle: ``,
                              completeAction: () => {},
                            }}
                          />
                        : dismissedPathwayItems.map((stateItem, idx) => {
                            return (
                              <OnboardingCard
                                key={`complete-${idx}`}
                                isFirst={true}
                                isLast= {false}
                                isComplete={false}
                                isDismissed={true}
                                isCurrent={false}
                                isActive={false}
                                noProgressBar={stateItem.noProgressBar}
                                onPress={stateItem.onPress}
                                state={stateItem}
                              />
                            )
                          })
                      }
                      {
                        currentPathwayItems.map((stateItem, idx) => {
                          return (
                            <OnboardingCard
                              key={`incomplete-${idx}`}
                              isFirst={false}
                              isLast= {remainingPathwayItems?.length === 0}
                              isComplete={stateItem?.complete}
                              isDismissed={false}
                              isCurrent={stateItem?.onboardingPhase === adjustedSuggestedPhase}
                              isActive={true}
                              noProgressBar={stateItem.noProgressBar}
                              onPress={stateItem.onPress}
                              state={stateItem}
                            />
                          )
                        })
                      }
                      {
                        remainingPathwayItems?.length > 0 && !remainingExpanded 
                        ? <OnboardingCard
                            key={`remaining-0`}
                            isFirst={false}
                            isLast= {true}
                            isComplete={false}
                            isCollapsed={true}
                            isDismissed={false}
                            isCurrent={false}
                            isActive={false}
                            onPress={expandRemaining}
                            state={{
                              onboardingPhase: OnboardingPhase.DUMMY,
                              title: `${first(remainingPathwayItems)?.title}${remainingPathwayItems?.length > 1 ? `, and ${remainingPathwayItems?.length - 1} other step${remainingPathwayItems?.length > 2 ? 's' : ''}` : ''}`,
                              subTitle: ``,
                              shortMessage: ``,
                              illustrationFilename: 'thanks.png',
                              isLoading: false,
                              error: null,
                              complete: false,
                              totalSteps: 0,
                              pendingSteps: 0,
                              notRequiredSteps: 0,
                              completedSteps: 0,
                              remainingSteps: 0,
                              steps: [],
                              completeButtonTitle: ``,
                              completeAction: () => {},
                            }}
                          />
                        : remainingPathwayItems.map((stateItem, idx) => {
                          return (
                            <OnboardingCard
                              key={`incomplete-${idx}`}
                              isFirst={false}
                              isLast= {idx === remainingPathwayItems?.length - 1 }
                              isComplete={stateItem?.complete}
                              isDismissed={false}
                              isCurrent={stateItem?.onboardingPhase === adjustedSuggestedPhase}
                              isActive={stateItem?.completedSteps > 0}
                              noProgressBar={stateItem.noProgressBar}
                              onPress={stateItem.onPress}
                              state={stateItem}
                            />
                          )
                        })
                      }
                      {
                        remainingPathwayItems?.length > 0
                          ? <Button
                              icon={remainingExpanded ? 'chevron-double-up' : 'chevron-double-down'}
                              mode={'text'}
                              color={themeColors.accent}
                              onPress={remainingExpanded ? collapseRemaining : expandRemaining}
                            >{remainingExpanded ? 'Hide other options' : 'Show all options'}</Button>
                          : <></>
                      }
                    </>
                }
              </>
            </ThemeProvider>
          </View>
        }
    </>
  )
}

const localStyles = StyleSheet.create({
  pathwayTitle: {
    ...Typography.fontSize.x15,
    paddingBottom: Sizing.x10,
  },
})