import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerRequestsListScreen } from './Screens/EmployerRequestsListScreen'

export const EmployerRequestsSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Requests'}
    >
      <EmployerRequestsListScreen />
    </SectionSimpleContainer>
    )
  }
  

