import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { EmployerContributionSet } from 'components/Utility/EmployerContributionSet'
import { getActionDate } from 'lib/dateHelpers'
import { isWithinOptOutWindow } from 'lib/enrolmentHelpers'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useAddGroupSchemeJobWithDataMutation, useGetGroupSchemeMembersQuery } from 'store/apiSlice'
import { CreateGroupSchemeJobDto, CreateGroupSchemeJobRecordContributionDto, GroupSchemeJobDataSetFormat, GroupSchemeJobType, GroupSchemeMemberDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setShowEmployerAddContributionJobVisible, setSnackbarData } from 'store/uxSlice'
import { orderBy, concat } from 'lodash'
import { GroupSchemeEnrolmentStatus } from 'store/dto/account.dto'
import { Image, View } from 'react-native'
import { Colors, Sizing } from 'styles'
import { InformationButton } from 'components/Utility/InformationButton'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { AppIllustration } from 'components/Utility/AppIllustration'

export const ACTIVE_MEMBER_COUNT_ALERT = 25
export const ACTIVE_MEMBER_COUNT_MAX = 30

export enum EmployerContributionSetChoice {
  NIL = 'No Contributions',
  LAST = 'Use Last Amounts',
  NEW = 'Set New Amounts'
}

export type WrappedContributionRecord = {
  id?: string,
  data: ContributionRecord
}

export type ContributionRecord = Pick<CreateGroupSchemeJobRecordContributionDto,
  'nationalInsuranceNo' |
  'employeeEmail' |
  'employeeId' |
  'firstName' |
  'surname' |
  'gender' |
  'birthDate' |
  'employerContributionAmount' |
  'employeeContributionAmount'
> & {
  lastEmployerContributionAmount: number
  lastEmployeeContributionAmount: number
  enrolmentEndDate: string
  autoEnrolmentOptOutDate: string
  autoEnrolmentWindowOptOutDate: string
  choice: EmployerContributionSetChoice
  inOptOutWindow: boolean
}

export const EmployerAddContributionJobScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  // const [cursorPosition, setCursorPosition] = useState(0)

  const [addContributionJob, { data: addedJob, isLoading: addJobIsLoading, error: addJobError }] = useAddGroupSchemeJobWithDataMutation()

  const { data: members, isLoading: membersIsLoading, error: membersError, isFetching: membersIsFetching, refetch: refetchMembers } = useGetGroupSchemeMembersQuery({
    groupSchemeId: currentScheme?.id,
    enrolmentStatus: GroupSchemeEnrolmentStatus.ACTIVE,
  })
  
  const dispatch = useAppDispatch()

  const mapMembersToContributionRecords = (members: GroupSchemeMemberDto[]): WrappedContributionRecord[] => {
    return members ? orderBy(members, [function(m: GroupSchemeMemberDto) { return `${m.client?.firstName} ${m.client?.surname}` }], ['asc']).map((member: GroupSchemeMemberDto): WrappedContributionRecord => {
      const isCeased = !!member?.enrolmentEndDate
      const isOptedOut = !!member?.autoEnrolmentOptOutDate
      const inOptOutWindow = isWithinOptOutWindow(member)
      const choice: EmployerContributionSetChoice = isCeased || isOptedOut || inOptOutWindow ? EmployerContributionSetChoice.NIL : undefined
      return {
        id: member?.id,
        data: {
          nationalInsuranceNo: member?.client?.nationalInsuranceNo,
          employeeEmail: member?.employeeEmail,
          employeeId: member?.employeeId,
          firstName: member?.client?.firstName,
          surname: member?.client?.surname,
          gender: member?.client?.gender,
          birthDate: member?.client?.birthDate,
          lastEmployerContributionAmount: member?.lastEmployerContributionAmount || 0,
          lastEmployeeContributionAmount: member?.lastEmployeeContributionAmount || 0,
          employerContributionAmount: 0,
          employeeContributionAmount: 0,
          enrolmentEndDate: member?.enrolmentEndDate,
          autoEnrolmentOptOutDate: member?.autoEnrolmentOptOutDate,
          autoEnrolmentWindowOptOutDate: member?.autoEnrolmentWindowOptOutDate,
          choice,
          inOptOutWindow,
        }
      }
    }) : []
  }

  const formObj = useForm<{
    wrappedContributionRecords: WrappedContributionRecord[]
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      wrappedContributionRecords: [],
    },
  })

  const noMembers = members && !members?.length
  const tooManyMembers = members && members?.length > ACTIVE_MEMBER_COUNT_MAX
  const memberCountAlert = !tooManyMembers && members && members?.length > ACTIVE_MEMBER_COUNT_ALERT

  useEffect(() => {
    if (members && !tooManyMembers) {
      setValue('wrappedContributionRecords', mapMembersToContributionRecords(members))
    }
  }, [members])

  const { handleSubmit, setValue, setError, trigger, watch, formState: { isValid, errors } } = formObj

  const onSubmit = async attributes => {
    const { wrappedContributionRecords }: { wrappedContributionRecords: WrappedContributionRecord[] } = attributes
    const contributionRecords: CreateGroupSchemeJobRecordContributionDto[] = wrappedContributionRecords.filter(wcr => {
      return wcr.data?.choice !== EmployerContributionSetChoice.NIL
    }).map(wcr => {
      const { data } = wcr
      const {
        nationalInsuranceNo,
        employeeEmail,
        employeeId,
        firstName,
        surname,
        employerContributionAmount,
        employeeContributionAmount,
      } = data || {}
      return {
        nationalInsuranceNo,
        employeeEmail,
        employeeId,
        firstName,
        surname,
        employerContributionAmount,
        employeeContributionAmount,
      }
    })

    const data: CreateGroupSchemeJobDto = {
      groupSchemeId: currentScheme.id,
      jobType: GroupSchemeJobType.CONTRIBUTION,
      dataSetFormat: GroupSchemeJobDataSetFormat.JSON,
      contributionRecords,
    }
    addContributionJob(data)
  }

  useEffect(() => {
    if (addedJob) {
      dispatch(setSnackbarData({
        message: `Contribution Job added!`,
        subMessage: `We'll send you a message when it has been processed${addedJob?.expectedCompleteAt ? ` (estimated completion at ${getActionDate(addedJob?.expectedCompleteAt )})` : ``}`,
        iconName: 'check-circle-outline',
        duration: 5000,
      }))
      close()
    }
  }, [addedJob])

  const close = () => {
    dispatch(setShowEmployerAddContributionJobVisible(false))
  }

  const isLoading = addJobIsLoading || membersIsLoading || membersIsFetching
  const error: any = addJobError || membersError

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={addJobError ? handleSubmit(onSubmit) : refetchMembers}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={addJobIsLoading ? ['Creating contribution job...'] : ['Retrieving active members...']}
      buttonTitle={noMembers || tooManyMembers ? 'Close' : 'Submit Contributions'}
      buttonAction={noMembers || tooManyMembers ? close : handleSubmit(onSubmit)}
      showButton={true}
      enableButton={noMembers || tooManyMembers || isValid}
    >
      {
        noMembers
        ? <>
            <Subheading>{'You do not currently have any active members'}</Subheading>
            <View style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
              <AppIllustration
                filename={'magnifying_glass.png'}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Paragraph>{'If you have recently requested enrolment for new members, you may need to wait for those jobs to complete before you can submit contributions.'}</Paragraph>
          </>
        : <>
            {
              memberCountAlert || tooManyMembers
              ? <>
                  <InformationButton
                    buttonTitle={tooManyMembers
                      ? 'You have surpassed the member limit for manual submission'
                      : 'You are approaching the member limit for manual submission'
                    }
                    noStartCase={true}
                    title={'Manual Contribution Submission Limits'}
                    iconName={'alert-box-outline'}
                    iconColor={tooManyMembers ? Colors.brand.red2 : Colors.warning.s400}
                    texts={concat(
                      [
                        `Manual submission of contributions via the Jarvis Employer Portal is intended for use by small businesses with up to ${ACTIVE_MEMBER_COUNT_MAX} active members.`,
                      ],
                      tooManyMembers
                        ? [
                            `You currently have ${members?.length} active members in this pension scheme, which is over this limit.`,
                            `To continue using manual submissions, you musyt reduce the number of active members in your scheme.`,
                          ]
                        : [
                            `You currently have ${members?.length} active members in this pension scheme, and are therefore approaching this limit.`,
                            `You will be able to continue using manual submission up until you have ${ACTIVE_MEMBER_COUNT_MAX} active members. Beyond this, manual submission of contributions will not be available.`,
                          ],
                      [
                        
                        `You can upload submissions for larger numbers of active members using a PAPDIS file, or integrating your payroll system with our APIs.`,
                        `Please contact Jarvis support to discuss these options in more detail.`,
                      ]
                    )}
                  />
                </>
              : <></>
            }
            {
              tooManyMembers
                ? <>
                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}>
                      <AppIllustration
                        filename={'support.png'}
                        style={{
                          width: Sizing.x200,
                          height: Sizing.x200,
                        }}
                        resizeMode={'contain'}
                      />
                    </View>
                    <Paragraph>{'Please click the link above for more information, or contact Jarvis Support.'}</Paragraph>
                  </>
                : <>
                    <Subheading>{'Please enter contributions amounts below for your scheme members'}</Subheading>
                    <Paragraph>{'Members who have opted out or ceased membership are not listed.'}</Paragraph>
                    <Paragraph>{'Members who are still within their Auto Enrolment Opt Out Window are shown, but you may not submit contributions until the window has ended.'}</Paragraph>
                    <ContentDivider />
                    <EmployerContributionSet
                      formObj={formObj}
                      name={'wrappedContributionRecords'}
                    />
                  </>
            }
          </>  
      }      
    </ModalProcessScreen>
  )
}

