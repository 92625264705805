import { Address, BaseDatedDto, ContributionSource, Gender, Title } from "./base.dto"

export enum MaritalStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
  DIVORCED = 'Divorced',
  WIDOWED = 'Widowed',
}

export enum EmploymentStatus {
  EMPLOYED = 'Employed',
  RETIRED = 'Retired',
  SELF_EMPLOYED = 'Self Employed',
  UNEMPLOYED = 'Unemployed',
  OTHER = 'Other',
}

export enum AmlStatus {
  PASS = 'pass',
  FAIL = 'fail',
  REFER = 'refer',
}
export class BankDetails {
  sortCode: string
  accountNumber: string
  accountName?: string
}

export class ClientEmployer {
  companyName: string
  companyRegNo: string
  addressDetail: Address
  accountingReferenceMonth?: number
  accountingReferenceDay?: number
}
export interface CompanyDto{
  id: string
  name: string
  addressDetail: Address
  accountingReferenceMonth?: number
  accountingReferenceDay?: number
}
export class ContributionConfiguration {
  bankId: string
  paymentReference?: string
  source: ContributionSource
  bankDetails: BankDetails
  employer?: ClientEmployer
}

export interface ClientBaseDto extends BaseDatedDto {
  id: string
  type: string
  title: Title
  firstName: string
  surname: string
  gender: Gender
  birthDate: string
  userId?: string
  spouseUserId?: string
  email?: string
  picture: string
  avatar?: string
  statePensionDate: string
  statePensionAge: number
  statePensionAmount: number
  statePensionUpdatedAt: string
  isSpouse?: boolean
}

export interface ClientAnyDto extends ClientBaseDto {
  hasSecclClient: boolean
  secclClientId?: string
  nationality?: string
  nationalInsuranceNo?: string
  addressDetail?: Address
  maritalStatus?: MaritalStatus
  employmentStatus?: EmploymentStatus
  onboardingFlags: any
  amlReference?: string
  amlStatus?: AmlStatus
  amlOverrideUserId?: string
  amlCrossReference?: string
  contributionBusinessAmlReference?: string
  contributionBusinessAmlStatus?: AmlStatus
  raindropId?: string
  previousFirstName?: string
  previousSurname?: string
  hasPreviousName?: boolean
}

export interface ClientMeDto extends ClientAnyDto {
  lastSurvivorDeathDate: string
  lastSurvivorDeathAge: number
  contributionConfiguration?: ContributionConfiguration
  retirementAge?: number
  currency?: string
  taxDomicile?: string
  language?: string
  affiliate?: ClientAffiliateDto
  segment?: ClientClientSegmentDto
  raindropId?: string
  previousFirstName?: string
  previousSurname?: string
  hasPreviousName?: boolean
  termsAgreed?: boolean
}

export interface ClientSpouseDto extends ClientBaseDto {
  lifeExpectancy: number
  lastSurvivorDeathDate: string
  lastSurvivorDeathAge: number
  hasActiveAccounts: boolean
  totalPendingContributionValue: number
  totalPendingTaxReclaimValue: number
  totalCurrentValue: number
  totalMonthlyContribution: number
}

export class ClientAffiliateDto {
  id: string
  ref: string
  name: string
}
export interface ClientClientSegmentDto {
  name: string
  description: string
  platformCharge: PlatformCharge
  platformChargeDiscount: number
  isPromotion: boolean
  badgeName?: string
  requiredBalance?: number
  monthsToReachRequiredBalance?: number
  secclSchemeProductId: string
  dateToReachRequiredBalance?: string
  termsUrl?: string
  minLumpSumAmount: number
  minRegularContributionAmount: number
  isDefault?: boolean
  isGroupDefault?: boolean
}

export interface PlatformCharge {
  minimumAmount?: number
  tiers: PlatformChargeTier[]
}

export interface PlatformChargeTier {
  fromAmount: number
  toAmount?: number
  rate: number
}

export interface CreateSpouseDto {
  title: Title
  firstName: string
  surname: string
  gender: Gender
  birthDate: string
  statePensionAmount?: number
}

export interface CreateClientMeDto extends CreateSpouseDto {
  nationality: string
  nationalInsuranceNo: string
  addressDetail: Address
  maritalStatus: MaritalStatus
  employmentStatus: EmploymentStatus
  inviteId?: string
  previousFirstName?: string
  previousSurname?: string
  hasPreviousName?: boolean
  termsAgreed?: boolean
}

export interface UpdateSpouseDto {
  title?: Title
  firstName?: string
  surname?: string
  gender?: Gender
  birthDate?: string
  statePensionAmount?: number
  avatar?: string
}

export interface UpdateClientMeDto extends UpdateSpouseDto {
  nationality?: string
  addressDetail?: Address
  maritalStatus?: MaritalStatus
  employmentStatus?: EmploymentStatus
  contributionConfiguration?: ContributionConfiguration
  onboardingFlags?: any
  previousFirstName?: string
  previousSurname?: string
  hasPreviousName?: boolean
  termsAgreed?: boolean
}

export interface NiCheckResultDto {
  available: boolean
}

export interface ClientFilterDto {
  search?: string
  secclClientId?: string
  amlReference?: string
  amlStatus?: ClientSearchAmlStatus
}

export interface ClientAmlUpdateResultDto {
  id: string
  amlReference: string
  amlStatus: AmlStatus
}

export interface ClientBusinessVerificationUpdateResultDto {
  id: string
  contributionBusinessAmlReference: string
  contributionBusinessAmlStatus: AmlStatus
}

export interface ClientSignatureDto {
  signature: string
}

export interface UpdateClientSignatureDto {
  signature: string
}

export enum ClientSearchAmlStatus {
  PASS = 'pass',
  FAIL = 'fail',
  REFER = 'refer',
  ANY = 'Any'
}