import { Headline, Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import React from 'react'
import { Image, ImageStyle, Pressable, ScrollView, StatusBar, StyleProp, StyleSheet, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Flex, Paper, Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { AppIllustration } from 'components/Utility/AppIllustration'

export type ProcessIntroScreenProps = {
  children?: any
  error?: any
  errorTryAgain?: any
  isLoading?: boolean
  loadingMessage?: string[]
  illustrationFilename?: string
  imageSource?: any
  imageSize?: number
  imageAction?: any
  headline?: string
  subHeading?: string
  subHeadingInfo?: React.ReactNode
  buttonTitle: string
  buttonAction: any
  showButton?: boolean
  enableButton?: boolean
  cancelButtonAction?: any
  cancelButtonText?: string
  refuseButtonAction?: any
  refuseButtonText?: string
  imageStyle?: StyleProp<ImageStyle>
  removeHeightPadding?: boolean

}

const isWeb = platformIsWeb()

export const ProcessIntroScreen = (props: ProcessIntroScreenProps) => {
  const { children, error, errorTryAgain, isLoading, loadingMessage, imageSource, illustrationFilename, imageSize, imageAction, headline, subHeading, subHeadingInfo, buttonTitle, buttonAction, showButton, enableButton, cancelButtonAction, cancelButtonText, refuseButtonAction, refuseButtonText, imageStyle, removeHeightPadding } = props

  const viewConstraints: any = getScreenWidthConstraints()

  function Content() {
    return (
      <View style={[
        {
          ...Flex.column.start,
          paddingHorizontal: Sizing.x30,
        },
        viewConstraints,
      ]}>
        <Pressable
          onPress={imageAction}
          style={{
            paddingTop: removeHeightPadding ? 0 : Sizing.x50,
          }}>
            {
              imageSource
                ? <Image source={imageSource} style={[
                    {
                      width: '100%',
                      height: imageSize || Sizing.x100,
                      resizeMode: 'contain',
                      alignSelf: 'center'
                    },
                  ]} />
                : <AppIllustration filename={illustrationFilename || 'info.png'} style={[
                    {
                      width: '100%',
                      height: Sizing.x100,
                      resizeMode: 'contain',
                      alignSelf: 'center'
                    },
                  ]} />
            }
        </Pressable>
        <View style={{
          paddingTop: removeHeightPadding ? 0 : Sizing.x50,
        }}>
          <View style={localStyles.headlineContainer}>
            {headline ? <Headline adjustsFontSizeToFit numberOfLines={2} style={{ alignSelf: 'center'}}>{headline}</Headline> : <></>}
          </View>
          {subHeading || subHeadingInfo ?
            <View style={localStyles.subHeadingContainer}>
              {subHeading
                ? <Subheading adjustsFontSizeToFit numberOfLines={3} style={{ alignSelf: 'center'}}>{subHeading}</Subheading>
                : <></>
              }
              {subHeadingInfo}
            </View>
            : <></>
          }
          {children}
        </View>
      </View>
    )
  }

  return (
    <>
      <StatusBar barStyle={'dark-content'} />
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <SafeAreaView style={[
          backgroundStyles.pageContainer,
          backgroundStyles.heroBackground,
        ]}>
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.processIntroScreenContainer,
          ]}>
            {
              error ? <ErrorScreen errorTryAgain={errorTryAgain} error={error?.data} /> : isLoading ? <Loading message={loadingMessage} useHolidayGraphics={true} /> :
                <>
                  <ScrollView
                    contentContainerStyle={layoutStyles.scrollContainerContent}
                    keyboardShouldPersistTaps='handled'
                    showsVerticalScrollIndicator={isWeb}
                  >
                    <Content /> 
                  </ScrollView>

                  {refuseButtonAction ?
                    <FooterButton
                      mode='text'
                      onPress={refuseButtonAction}
                    >{refuseButtonText ? refuseButtonText : "I don't want to!"}</FooterButton>
                    : <></>
                  }
                  {cancelButtonAction ?
                    <FooterButton
                      mode='text'
                      onPress={cancelButtonAction}
                    >{cancelButtonText ? cancelButtonText : "Cancel"}</FooterButton>
                    : <></>
                  }
                  {showButton ?
                    <FooterButton
                      onPress={buttonAction}
                      disabled={enableButton !== undefined && !enableButton}
                    >{buttonTitle}</FooterButton>
                    : <></>
                  }
                </>
            }
          </View>
        </SafeAreaView>
      </ThemeProvider>
    </>
  )
}

const localStyles = StyleSheet.create({
  processIntroScreenContainer: {
    paddingTop: Sizing.x30,
  },
  headlineContainer: {
    paddingBottom: Sizing.x20,
  },
  subHeadingContainer: {

  }
})