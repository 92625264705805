import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { Subheading } from 'components/Typography'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { envVariables } from 'environment'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { difference, sumBy } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { useGetRetirementAssetsQuery } from 'store/apiSlice'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingBulkTransfer, workingBulkTransfer } from 'store/tempDataSlice'
import { Flex, Sizing, Typography } from 'styles'

export const BulkTransfer_04_TracingConfirmation = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  const dispatch = useAppDispatch()
  const workingBulkTransferData = useAppSelector(workingBulkTransfer)

  const tracingCount = workingBulkTransferData?.tracingCount
  const executionCount = workingBulkTransferData?.executionCount

  const { personalPensionMemberDeclarationUrl, pensionFindingTermsUrl } = envVariables

  const { data: assets, isLoading, error, refetch } = useGetRetirementAssetsQuery()

  const assetsIdsToTrace = workingBulkTransferData?.tracingAssetIds || []
  const assetsToTrace = assets ? assets.filter(asset => {
    return assetsIdsToTrace.includes(asset.id)
  }) : []
  
  //Setup form
  const formObj = useForm<{
    transferTermsAgreed: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      transferTermsAgreed: []
    },
  })
  const { handleSubmit, setValue, trigger, watch, formState: { isDirty, isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingBulkTransfer({
      transferTermsAgreed: true
    }))
    navigation.navigate(executionCount > 0 ? nextScreen : 'Results')
  }

  enum Option {
    DECLARATION = 'DECLARATION',
    FINDING_TERMS = 'FINDING_TERMS'
  }

  const options: ManagedMultipleChoiceInputOption[] = [
    {
      value: Option.DECLARATION,
      label: 'Member Declaration',
      linkUrl: personalPensionMemberDeclarationUrl,
      linkIsPdf: true,
    },
    {
      value: Option.FINDING_TERMS,
      label: 'Pension Finding Terms',
      linkUrl: pensionFindingTermsUrl,
      linkIsPdf: true,
    }
  ]
  
  const requiredOptions = [
    Option.DECLARATION,
    Option.FINDING_TERMS,
  ]

  const termsAgreed = watch('transferTermsAgreed')
  const unchecked = difference(requiredOptions, termsAgreed)
  const hasConfirmedAll = unchecked.length === 0

  type AssetsForTraceListProps = {
    assets: RetirementAssetDto[]
  }

  const AssetsForTraceList = (props: AssetsForTraceListProps) => {
    const { assets } = props
    
    const overallAmount = sumBy(assets, 'currentValue')
  
    return (
      <View style={localStyles.listContainer}>
        {
          assets ? assets.map((asset, idx) => {
            return (
              <View key={idx}>
                <View style={localStyles.itemContainer}>
                  <View style={{flex:1}}>
                    <Subheading numberOfLines={1} style={{textAlign:'left' }} >{asset.name}</Subheading>
                  </View>
                  <View style={localStyles.amountContainer}>
                    <Subheading>{formatCurrencyAmount(asset.currentValue)}</Subheading>
                  </View>
                </View>
              <ContentDivider />
            </View>
            )
          }) : <></>
        }
        <View style={localStyles.itemContainer}>
        <View>
          <Subheading style={{ ...Typography.fontWeight.bold }}>Estimated Total</Subheading>
        </View>
        <View style={localStyles.amountContainer}>
          <Subheading style={{ ...Typography.fontWeight.bold }}>{formatCurrencyAmount(overallAmount)}</Subheading>
        </View>
        </View>
        <ContentDivider />
      </View>
    )
  }


  return (
    <ProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={
        hasConfirmedAll
          ? `Find ${tracingCount} Pension${tracingCount === 1 ? '' : 's'}`
          : 'Please Confirm'
      }
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasConfirmedAll}
      headline={`Confirm Pension Finding`}
      subHeading={`Please confirm you have read and agree to the following documents`}
      subHeadingInfo={<NamedInformationButton name={NamedInformation.TRACING_OUTCOMES} buttonTitle={`What happens next?`} />}
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'transferTermsAgreed'}
        options={options}
      />
      <Subheading style={{ paddingTop: Sizing.x20 }}>{'Pensions to be found'}</Subheading>
      <AssetsForTraceList
        assets={assetsToTrace}
      />
    </ProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20,
  },
  header: {
    ...Flex.row.between,
  },
  iconContainer: {
    // paddingHorizontal: Sizing.x10,
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start,
  },
  amountContainer: {
    ...Flex.override.right,
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between,
  },
})