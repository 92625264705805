
export enum SecclNotificationStatus {
  OPEN = 'Open',
  CLOSED = 'Closed',
}

//Combination of backend LocalExceptionSystemArea plus Seccl systemAreas
export enum ExceptionSystemArea {
  TRANSACTIONS = 'Transactions',
  GROUP_SCHEMES = 'Group Schemes',
  INTERFACE = 'Interface',
  PORTFOLIO = 'Portfolio',
}

export enum ExceptionSystemAreaSearch {
  ANY = 'Any',
  TRANSACTIONS = 'Transactions',
  GROUP_SCHEMES = 'Group Schemes',
  INTERFACE = 'Interface',
  PORTFOLIO = 'Portfolio',
}

export enum SystemExceptionSource {
  JARVIS = 'Jarvis',
  SECCL = 'Seccl',
}

export enum SystemExceptionSourceSearch {
  ANY = 'Any',
  JARVIS = 'Jarvis',
  SECCL = 'Seccl',
}

export interface ExceptionDto {
  id: string
  type: string
  source: SystemExceptionSource
  status: SecclNotificationStatus
  createdAt: string
  message: string
  errorMessage?: string
  systemArea: string
  systemAreaAction: string
  eventType?: string
  externalSystem?: string
  externalSystemAction?: string
  notes?: string
  referencedEntities?: ExceptionReferencedEntityDto[]
  validationErrors?: ExceptionValidationErrorDto[]
}

export interface ExceptionReferencedEntityDto {
  entityName: string
  entityId: string
}

export interface ExceptionValidationErrorDto {
  errorMessage: string
  fieldName: string
  type: string
}

export class ExceptionFilterDto {
  source?: SystemExceptionSourceSearch
  status?: SecclNotificationStatus
  systemArea?: ExceptionSystemAreaSearch
}

export class CloseMultipleExceptionsDto {
  exceptionIds: string[]
}
