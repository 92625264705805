import { envVariables } from "environment"
import { parse } from 'expo-linking'
import { has, pick, replace } from 'lodash'
import { Linking, Platform } from "react-native"
import { AppContext, LinkParams, LoginParams } from "store/authSlice"
import { URL_PATH_FEDERATED } from "./authHelpers"
import { AdminAppNavScreen, EmployerAppNavScreen, MainAppNavScreen } from "./navigationHelpers"

const { appStoreUrls } = envVariables
const baseUrl = appStoreUrls[Platform.OS]

const appContextUrlMap = {
  client: AppContext.CLIENT,
  admin: AppContext.ADMIN,
  employer: AppContext.EMPLOYER,
}

export const openAppStoreReview = () => {
  Linking.openURL(Platform.OS === 'ios'
    ? `${baseUrl}?action=write-review`
    : `${baseUrl}&showAllReviews=true`
  )
}

export const determineAppContextFromUrl = (url: string) => {
  if (!url) {
    return
  }
  const parsed = parse(url)

  //Use path
  const path = replace(parsed.path, 'expo-development-client/', '')
  if (has(appContextUrlMap, path)) {
    
    return appContextUrlMap[path]
  }

}

export const parseRealPathFromUrl = (url: string) => {
  const parsed = parse(url)
  const path = parsed.path
  return replace(path, 'expo-development-client/', '')
}

export const pickLinkParams = (params: any): LinkParams => {
  if (!params) {
    return {}
  }
  return pick(params, [
    'affiliateCode',
    'affiliateRef',
    'affiliateClientRef',
    'preferredInviteId',
    'preferredSchemeInviteId',
    'preferredSchemeEnrolmentId',
  ])
}

export const pickLoginParams = (params: any): LoginParams => {
  if (!params) {
    return {}
  }
  return pick(params, [
    'emailAddress',
    'authType',
    'autoLogin',
  ])
}

export const getLinkingMap = (): any => {
  return {
    prefixes: [
      'http://localhost:19006',
      'exp+jarvis-frontend-2://192.168.7.155:8081/expo-development-client',
      'https://local.getjarvis.dev',
      'https://appdev.getjarvis.dev',
      'https://app.getjarvis.dev',
      'https://app.getjarvis.app',
    ],
    config: {
      initialRouteName: 'EntryPointDefaultScreen',
      screens: {
        //Entry points
        EntryPointAdminScreen: 'admin',
        EntryPointDefaultScreen: 'client',
        EntryPointEmployerScreen: 'employer',
        EntryPointAffiliateScreen: 'affiliate',
        EntryPointPartnerScreen: 'partner-invite',
        EntryPointSchemeEnrolmentScreen: 'scheme-enrolment',
        EntryPointSchemeInviteScreen: 'scheme-invite',
        //Auth
        AuthenticationFederatedLoginScreen: URL_PATH_FEDERATED,
        AuthenticationProcessStack: 'login',
        AuthenticationLogoutScreen: 'logout',
        NewUserCarouselScreen: 'intro',
        //Onboarding
        OnboardingStatusScreen: 'onboarding', //Used with query params to determine the relevant card
        //Calculator
        CalculatorIntroScreen: 'calculator',
        CalculatorProcessStack: 'calculator-tool',
        CalculatorHeroScreen: 'calculator-result',
        //Processes
        //All disabled for now as no "protection" on these to check for a user
        // InviteAcceptIntroScreen: 'process/partner-invite', //Changed to entry points
        // SchemeInviteAcceptIntroScreen: 'process/scheme-invite', //Changed to entry points
        // SchemeEnrolmentSetupIntroScreen: 'process/scheme-enrolment', //Changed to entry points
        // ClientVerificationIntroScreen: 'process/verification',
        // InvestmentChoiceIntroScreen: 'process/investment-choice',
        // RetirementAssetSetupIntroScreen: 'process/retirement-assets',
        // RetirementProfileSetupIntroScreen: 'process/retirement-profile',
        // RetirementIncomeSetupIntroScreen: 'process/retirement-incomes',
        // BulkTransferIntroScreen: 'process/transfers',
        // BulkRetransferIntroScreen: 'process/retransfers',
        // BeneficiariesSetupIntroScreen: 'process/beneficiaries',
        // SpouseInviteIntroScreen: 'process/spouse-invite',
        // ContributionsIntroScreen: 'process/contributions',
        //Admin stack
        AdminAppStack: {
          path: 'admin-portal',
          screens: {
            [AdminAppNavScreen.DASHBOARD]: 'dashboard',
            [AdminAppNavScreen.FINANCIALS]: 'financials',
            [AdminAppNavScreen.USERS]: 'users',
            [AdminAppNavScreen.CLIENTS]: 'clients',
            [AdminAppNavScreen.TRANSFERS]: 'transfers',
            [AdminAppNavScreen.BRANDS]: 'brands',
            [AdminAppNavScreen.PROVIDERS]: 'providers',
            [AdminAppNavScreen.AFFILIATES]: 'affiliates',
            [AdminAppNavScreen.INVESTMENT_PLANS]: 'investment-plans',
            [AdminAppNavScreen.GROUP_PORTFOLIOS]: 'group-portfolios',
            [AdminAppNavScreen.ASSETS]: 'assets',
            [AdminAppNavScreen.ASSET_CATEGORIES]: 'asset-categories',
            [AdminAppNavScreen.BANK_CONNECTIONS]: 'banks',
            [AdminAppNavScreen.FEATURES]: 'features',
            [AdminAppNavScreen.GROUP_ORGANIZATIONS]: 'group-organizations',
            [AdminAppNavScreen.GROUP_SCHEMES]: 'group-schemes',
            [AdminAppNavScreen.EXCEPTIONS]: 'exceptions',
          }
        },
        //Employer stack
        EmployerAppStack: {
          path: 'employer-portal',
          screens: {
            [EmployerAppNavScreen.DASHBOARD]: 'dashboard',
            [EmployerAppNavScreen.ENROLMENTS]: 'enrolments',
            [EmployerAppNavScreen.CONTRIBUTIONS]: 'contributions',
            [EmployerAppNavScreen.PAYMENTS]: 'payments',
            [EmployerAppNavScreen.REQUESTS]: 'requests',
            [EmployerAppNavScreen.INVITES]: 'invites',
            [EmployerAppNavScreen.MEMBERS]: 'members',
            [EmployerAppNavScreen.HISTORY_ENROLMENTS]: 'history/enrolments',
            [EmployerAppNavScreen.HISTORY_CONTRIBUTIONS]: 'history/contributions',
            [EmployerAppNavScreen.HISTORY_PAYMENTS]: 'history/payments',
            [EmployerAppNavScreen.HISTORY_REQUESTS]: 'history/requests',
          }
        },
        //Main app stack
        MainAppStack: {
          screens: {
            [MainAppNavScreen.DASHBOARD]: {
              path: 'dashboard',
              screens: {
                [MainAppNavScreen.DASHBOARD_TODAY]: 'today',
                [MainAppNavScreen.DASHBOARD_RETIREMENT]: 'retirement',
                [MainAppNavScreen.DASHBOARD_LEGACY]: 'legacy',
              }
            },
            [MainAppNavScreen.PROFILE]: 'profile',
            [MainAppNavScreen.SPOUSE]: 'partner',
            [MainAppNavScreen.PERSONAL_PENSION]: 'pension',
            [MainAppNavScreen.GROUP_PENSION]: 'group-pension',
            [MainAppNavScreen.CONTRIBUTIONS]: 'contributions',
            [MainAppNavScreen.TRANSFERS]: 'transfers',
            [MainAppNavScreen.RETIREMENT_ASSETS]: 'assets',
            [MainAppNavScreen.REGULAR_INCOMES]: 'incomes',
            [MainAppNavScreen.DOCUMENTS]: 'documents',
            [MainAppNavScreen.STATEMENTS]: 'statements',
          }
        },
      },
    },
  }
}
