import { ProcessScreen } from 'components/Layout'
import { BulletItem } from 'components/Typography/BulletItem'
import { addDays, isAfter, startOfDay } from 'date-fns'
import { formatUkDate } from 'lib/dateHelpers'
import React, { useState } from 'react'
import { Image } from 'react-native'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { WorkingSchemeEnrolmentRequest, updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Sizing } from 'styles'
import { concat, lowerCase } from 'lodash'
import { SchemeEnrolmentSetContributionRequestAddProcessStack } from 'features/ModalProcesses/SchemeEnrolmentSetContributionRequestAdd/SchemeEnrolmentSetContributionRequestAddProcessStack'
import { Subheading } from 'components/Typography'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { AppIllustration } from 'components/Utility/AppIllustration'

export const SchemeEnrolmentSetup_04_Contributions = ({ route, navigation }) => {
  const { nextScreen, enrolment, client }: { nextScreen: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)
  const currentRequests = workingSetupSchemeEnrolmentData?.requests || []
  const currentContributionRequest = currentRequests.find(request => {
    return request.requestType === GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
  })

  const [addRequest, setAddRequest] = useState(false)

  const { groupScheme, autoEnrolmentWindowOptOutDate } = enrolment || {}
  const { enabledRequestTypes, defaultContributionFrequency } = groupScheme || {}

  const contributionAmountRequestEnabled = enabledRequestTypes && enabledRequestTypes.includes(GroupSchemeRequestType.SET_GROSS_CONTRIBUTION)

  const now = new Date()
  const optOutBoundaryDate = autoEnrolmentWindowOptOutDate ? addDays(startOfDay(new Date(autoEnrolmentWindowOptOutDate)), 1) : undefined
  const withinOptOutWindow = !!optOutBoundaryDate && isAfter(optOutBoundaryDate, now)

  const dispatch = useAppDispatch()

  const handleAddRequest = (request: Partial<WorkingSchemeEnrolmentRequest>) => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return request.requestType !== GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
    }) : []
    const newRequest: WorkingSchemeEnrolmentRequest = {
      requestType: request.requestType,
      data: request.data,
    }
    const requests = concat(otherRequests, newRequest)
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests,
    }))
    setAddRequest(false)
  }

  const handleRemoveRequest = () => {
    const otherRequests = workingSetupSchemeEnrolmentData?.requests ? workingSetupSchemeEnrolmentData?.requests.filter(request => {
      return request.requestType !== GroupSchemeRequestType.SET_GROSS_CONTRIBUTION
    }) : []
    dispatch(updateWorkingSetupSchemeEnrolment({
      requests: otherRequests,
    }))
  }

  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <>
      {
        addRequest ?
          <SchemeEnrolmentSetContributionRequestAddProcessStack
            onDismiss={() => setAddRequest(false)}
            resultHandlerFunction={handleAddRequest}
            enrolment={enrolment}
          />
        :
          <ProcessScreen
            isLoading={false}
            error={undefined}
            buttonTitle={currentContributionRequest ? 'Continue' : 'Next'}
            buttonAction={next}
            enableButton={true}
            headline={`Contributions from your employer`}
            subHeading={`These should start automatically as they run payroll`}
            allowTextButton={contributionAmountRequestEnabled || !!currentContributionRequest}
            textButtonTitle={currentContributionRequest
              ? `Remove contribution request`
              : `Request specific contribution amount`}
            textButtonAction={currentContributionRequest
              ? handleRemoveRequest
              : () => setAddRequest(true)}
            footerInfo={
              currentContributionRequest
              ? <Subheading>{`We will send a request to your employer to contribute ${formatCurrencyAmount(currentContributionRequest?.data?.employerContributionAmount, 2)} each ${lowerCase(currentContributionRequest?.data?.contributionFrequency)} payroll run.`}</Subheading>
              : contributionAmountRequestEnabled
              ? <Subheading>{`Your employer allows you to request a change to the gross contribution made each payroll run (${defaultContributionFrequency}).`}</Subheading>
              : undefined
            }
          >
            <AppIllustration
              filename={'regular_contributions.png'} style={[
              {
                width: Sizing.x100,
                height: Sizing.x100,
                resizeMode: 'contain',
                alignSelf: 'center'
              },
            ]} />
            {
              withinOptOutWindow
                ? <>  
                    <BulletItem style={{ textAlign: 'left' }}>{`They will start when your enrolment 'Opt Out' period ends on ${formatUkDate(optOutBoundaryDate)}.`}</BulletItem>
                    <BulletItem style={{ textAlign: 'left' }}>{`At this point, you should receive back-dated contributions covering the 'Opt Out' period.`}</BulletItem>
                  </>
                : <>
                    <BulletItem style={{ textAlign: 'left' }}>{`Contributions from your employer should start arriving soon.`}</BulletItem>
                    <BulletItem style={{ textAlign: 'left' }}>{`This depends on your employer's payroll cycle - check with them if you are unsure.`}</BulletItem>
                  </>
            }
            <BulletItem style={{ textAlign: 'left' }}>{`The amount your receive will depend on your employer's scheme and your earnings.`}</BulletItem>
          </ProcessScreen>
      }
    </>
  )
}
