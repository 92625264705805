import { MaterialCommunityIcons } from "@expo/vector-icons"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { CardModal } from "components/Layout/CardModal"
import { Paragraph, Subheading } from "components/Typography"
import { BulletItem } from "components/Typography/BulletItem"
import { FooterButton } from 'components/Utility/FooterButton'
import { envVariables } from "environment"
import { getDiagnosticInformation, getDiagnosticString, InformationSectionKey } from "lib/appInfoHelpers"
import { composeIntercomMessage, composeIntercomMessageForUnregistered } from "lib/intercomHelpers"
import React, { useState } from 'react'
import { Platform, StyleSheet, View, Image } from "react-native"
import { ScrollView } from 'react-native'
import { ClientMeDto } from "store/dto/client.dto"
import { UserDto } from "store/dto/user.dto"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { Button } from "./Button"
import { openURL } from 'expo-linking'
import { ShareButton } from 'components/Utility/ShareButton'
import { platformIsWeb } from "lib/platformHelpers"
import { openAppStoreReview } from "lib/linkingHelpers"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { ContentDivider } from "components/Layout/ContentDivider"
import { Portal } from "react-native-paper"
import { WebAppDownloadModal } from "./WebAppDownloadModal"

type AppInfoModalProps = {
  user: UserDto
  client?: ClientMeDto
  visible: boolean
  onDismiss: any
  isUnauthenticated?: boolean
}

export const AppInfoModal = (props: AppInfoModalProps) => {
  const { user, client, onDismiss, visible, isUnauthenticated } = props

  const [showDownloadModal, setShowDownloadModal] = useState(null)

  const isWeb = platformIsWeb()

  const { appStoreUrls, marketingUrl, supportUrl, privacyUrl } = envVariables
  const url = appStoreUrls[Platform.OS]

  const relevantSections = [
    InformationSectionKey.DATE,
    InformationSectionKey.USER,
    InformationSectionKey.APP,
    InformationSectionKey.DEVICE
  ]

  const informationArray = isUnauthenticated ? getDiagnosticInformation() : getDiagnosticInformation(user, client)

  const sendInformationViaIntercom = async () => {
    const infoBody = getDiagnosticString(informationArray, relevantSections)

    let initialMessage = `----- Jarvis App Information -----\n\n`
    initialMessage += infoBody
    initialMessage += `\n---------------------------------------\n`
    initialMessage += `Please hit send button!\n`
    initialMessage += `---------------------------------------\n`
    if (user) {
      await composeIntercomMessage(initialMessage)
    } else {
      await composeIntercomMessageForUnregistered(initialMessage)
    }
  }

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>          
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>          
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <JarvisLogoType size={Sizing.x60} color='black' />
              </View>
              <ExternalLinkButton
                labelStyle={{ textTransform: 'none' }}
                url={marketingUrl}
              >{'getjarvis.app'}</ExternalLinkButton>
              {
                isWeb
                  ? <Button
                      mode={'text'}
                      onPress={() => setShowDownloadModal(true)}
                      labelStyle={{
                        textDecorationLine: 'none',
                        marginTop: Sizing.x0,
                        marginBottom: Sizing.x0,
                      }}
                      color={Colors.brand.purple1}
                      icon={() => <MaterialCommunityIcons name={'download'} size={Sizing.x20} color={Colors.brand.purple1} />}
                    >
                      {`Download Mobile App`}
                    </Button>
                  : <></>
              }
              {
                user
                  ? <ShareButton
                      user={user}
                      onDismiss={onDismiss}
                      campaign={'app_info_modal'}>
                        {`Tell friends about Jarvis`}
                      </ShareButton>
                  : <></>
              }
              <Image
                  source={require('assets/external/fscs-logo1-purp.png')}
                  style={{
                    marginTop: Sizing.x20,
                    width: Sizing.x80,
                    height: Sizing.x80,
                    alignSelf: 'center',
                  }}
                  resizeMode={'contain'}
                />
            </View>

            {
              informationArray.map((section, sectionIdx) => {
                return (
                  <View key={sectionIdx}>
                    {
                      section.key === InformationSectionKey.DATE || (section.needsAuthenticatedUser && isUnauthenticated) ? <></> :
                      <>
                        <ContentDivider />
                        <Subheading style={localStyles.subTitle}>{section.sectionTitle}</Subheading>
                        {
                          section.items.map((item, itemIdx) => {
                            return (
                              <BulletItem key={itemIdx} style={localStyles.text}>{`${item.title}: ${item.value}`}</BulletItem>
                            )
                          })
                        }
                        {
                          !isWeb && section.key === InformationSectionKey.APP
                            ? <>
                                <Button onPress={() => openURL(url)} mode={'text'} >{'Check for Updates'}</Button>
                                {
                                  user
                                    ? <Button onPress={openAppStoreReview} mode={'text'} >{'Leave App Review'}</Button>
                                    : <></>
                                }                              
                              </>
                            : <></>
                        }
                      </>
                    }
                    {
                      isWeb && section.key === InformationSectionKey.SUPPORT
                        ? <>
                            <ExternalLinkButton
                              iconName={'help-circle-outline'}
                              iconColor={Colors.brand.purple1}
                              url={supportUrl}
                            >
                              {'Support'}
                            </ExternalLinkButton>
                            <ExternalLinkButton
                              iconName={'book-lock-outline'}
                              iconColor={Colors.brand.purple1}
                              url={privacyUrl}
                            >
                              {'Privacy Policy'}
                            </ExternalLinkButton>    
                          </>
                        : <></>
                    }
                  </View>
                )
              })
            }
          </ScrollView>
          {
            isWeb ? <></>  : <FooterButton onPress={sendInformationViaIntercom} mode={'text'} >{'Send to Support'}</FooterButton>
          }
          <FooterButton mode={'text'} onPress={onDismiss} >{'Close'}</FooterButton>
          {
            isWeb
                  ? <Portal>
                      <WebAppDownloadModal
                        visible={showDownloadModal}
                        onDismiss={() => setShowDownloadModal(false)}
                      />
                    </Portal>
                    : <></>
          }
        </View>
      </>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})