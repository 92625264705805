import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, CURRENT_ENTITY_OPTION_VALUE, CURRENT_SEARCH_TERM_OPTION_VALUE, ManagedAutoCompleteInput, isSpecialValue } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL, MAX_ASSET_CURRENT_VALUE, MAX_ASSET_REGULAR_CONTRIBUTIONS, PERSONAL_TAX_RELIEF_FACTOR } from 'lib/constants'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { Logger } from 'lib/logger'
import { getAssetCategory, isActiveOrRejectedTransfer, isLockedForTransfer, isOfTransferableType, RetirementAssetCategory } from 'lib/retirementAssetHelpers'
import { pick, orderBy } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { ampli } from 'src/ampli'
import { useGetPensionBrandsQuery, useLazyGetEmployersQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { ContributionSource } from 'store/dto/base.dto'
import { ClientSpouseDto } from 'store/dto/client.dto'
import { EmployerDto } from 'store/dto/reference-data.dto'
import { RetirementAssetDto, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { Colors, Sizing, Flex } from 'styles'
import { concat } from 'lodash'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'

interface RetirementAssetsEditAssetScreenProps {
  asset: RetirementAssetDto
  onDismiss: any
  spouseControlled: boolean
  spouse: ClientSpouseDto
}

export const RetirementAssetsEditAssetScreen = (props: RetirementAssetsEditAssetScreenProps) => {
  const { onDismiss, spouseControlled, spouse, asset } = props

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const [ updateAsset, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading } ] = useUpdateRetirementAssetMutation()

  const {
    pensionProviderBrandKey: existingPensionProviderBrandKey,
    pensionProviderBrandName: existingPensionProviderBrandName,
    employerId: existingEmployerId,
    employerName: existingEmployerName,
    employerIsPublicSector: existingEmployerIsPublicSector,
  } = asset || {}

  const existingEmployerOption: AutoCompleteItem = existingEmployerName ? {
    value: existingEmployerId ? existingEmployerId : CURRENT_ENTITY_OPTION_VALUE,
    label: existingEmployerName,
    description: existingEmployerId ? 'Existing choice' : 'Existing employer name set by you',
    extraData: {
      isPublicSector: existingEmployerIsPublicSector,
    }
  } : undefined

  const existingBrandOption: AutoCompleteItem = existingPensionProviderBrandName && !existingPensionProviderBrandKey ? {
    value: CURRENT_ENTITY_OPTION_VALUE,
    label: existingPensionProviderBrandName,
    description: 'Existing pension brand name set by you',
  } : undefined

  const [ getEmployers, { data: employers, error: employersError, isLoading: employersIsLoading, isFetching: employersIsFetching }] = useLazyGetEmployersQuery()
  const [searchTerm, setSearchTerm] = useState<string>(undefined)
  const [publicSectorLocked, setPublicSectorLocked] = useState(!!existingEmployerId)
  const [realEmployerOptions, setRealEmployerOptions] = useState<AutoCompleteItem[]>([])
  const [dummyEmployerOption, setDummyEmployerOption] = useState<AutoCompleteItem>(existingEmployerOption)
  const [dummyBrandOption, setDummyBrandOption] = useState<AutoCompleteItem>(existingBrandOption)
  const [employerOptions, setEmployerOptions] = useState<AutoCompleteItem[]>(existingEmployerOption ? [existingEmployerOption] : [])

  //Trigger getEmployers...
  const getSuggestions = useCallback(async q => {
    setSearchTerm(q)
    const filterToken = q.toLowerCase()
    if (typeof filterToken !== 'string' || filterToken.length < 3) {
      setRealEmployerOptions([])
      return
    }
    getEmployers(filterToken, true) //Get employers, preferring cache
  }, [])

  //Update realEmployerOptions when employers chanegs
  useEffect(() => {
    let options: AutoCompleteItem[] = []
    if (employers) {
      options = employers.map(employer => ({
        value: employer.identifier,
        label: employer.legalName,
        extraData: {
          isPublicSector: employer.isLocalAuthority,
        }
      }))
    }
    if (searchTerm) {
      options.push({
        value: CURRENT_SEARCH_TERM_OPTION_VALUE,
        label: searchTerm,
        description: 'Use this employer name',
      })
    }
    setRealEmployerOptions(options)
  }, [employers])

  //Update employerOptions when real/dummy chaneg
  useEffect(() => {
    const combinedOptions: AutoCompleteItem[] = dummyEmployerOption
      ? concat(dummyEmployerOption, realEmployerOptions)
      : realEmployerOptions
    setEmployerOptions(combinedOptions)
  }, [realEmployerOptions, dummyEmployerOption])
  
  const isLoading = brandsIsLoading || updateAssetIsLoading
  const error = brandsError || updateAssetError

  const belongsToSpouse = spouse && asset?.clientId === spouse?.id
  const isTransferableType = asset ? isOfTransferableType(asset) : false

  //Form refs for focussing
  const nameRef = useRef()
  const currentValueRef = useRef()
  const monthlyContributionAmountRef = useRef()

  //Setup form
  const formObj = useForm<{
    name: string
    assetType: RetirementAssetType
    employerId: string
    employerName: string
    employerIsPublicSector: boolean
    pensionProviderBrandKey: string
    pensionProviderBrandName: string
    externalProviderReference: string
    currentValue: number
    monthlyContributionAmount: number
    contributionSource: ContributionSource
    isTransferable: boolean
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: asset?.name, 
      assetType: asset?.assetType,
      employerId: existingEmployerId ? existingEmployerId : existingEmployerOption ? CURRENT_ENTITY_OPTION_VALUE : undefined,
      employerName: asset?.employerName,
      employerIsPublicSector: asset?.employerIsPublicSector !== undefined ? asset?.employerIsPublicSector : undefined,
      pensionProviderBrandKey: existingPensionProviderBrandKey ?  existingPensionProviderBrandKey : existingBrandOption ? CURRENT_ENTITY_OPTION_VALUE : undefined,
      pensionProviderBrandName: asset?.pensionProviderBrandName,
      externalProviderReference: asset?.externalProviderReference,
      currentValue: asset?.currentValue,
      monthlyContributionAmount: asset?.monthlyContributionAmount,
      contributionSource: asset?.contributionSource,
      isTransferable: asset?.isTransferable
    },
  })
  const { handleSubmit, watch, setValue, formState: { errors, isDirty, isValid} } = formObj

  // console.log({ isValid, errors, isDirty })
  
  const personalContribution = watch('contributionSource') === ContributionSource.PERSONAL
  const factor = personalContribution ? 1 + PERSONAL_TAX_RELIEF_FACTOR : 1

  const onSubmit = attributes => {
    const { employerId, pensionProviderBrandKey } = attributes || {}
    const payload = {
      id: asset.id,
      ...attributes,
      employerId: isSpecialValue(employerId) ? null : employerId,
      pensionProviderBrandKey: isSpecialValue(pensionProviderBrandKey) ? null : pensionProviderBrandKey,
      contributionSource: attributes.contributionSource || null,
    }
    Logger.info(`Updating asset`, asset.id)
    updateAsset(payload)
  }

  //Close on save result
  useEffect(() => {
    if (updatedAsset) {
      ampli.retirementAssetUpdate({
        ...pick(updatedAsset, [
          'assetType',
          'isTransferable',
          'currentValue',
          'monthlyContributionAmount',
          'contributionSource',
          'employerId',
          'employerName',
          'employerIsPublicSector',
          'pensionProviderBrandKey',
          'pensionProviderBrandName',
        ]),
        isSpouse: belongsToSpouse,
        pensionProviderBrandName: asset?.pensionProviderBrandName,
      })
      onDismiss()
    }
  }, [updatedAsset])

  //Build brand autocomplete options
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  if (dummyBrandOption) {
    brandOptions.unshift(dummyBrandOption)
  }

  const monthlyContributionAmount = watch('monthlyContributionAmount')  
  const percentage = `${PERSONAL_TAX_RELIEF_FACTOR * 100}%`

  const assetCategory = getAssetCategory(asset?.assetType)

  const isPension = assetCategory === RetirementAssetCategory.PENSIONS
  const isWorkplace = asset.assetType === RetirementAssetType.WORKPLACE
  const isSaving = assetCategory === RetirementAssetCategory.SAVINGS
  const isTransferLocked = isLockedForTransfer(asset)
  
  const disabled = spouseControlled || isTransferLocked

  const publicSectorOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes`
    },
    {
      value: false,
      label: 'No'
    }
  ]

  const transferOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: `Yes, maybe`
    },
    {
      value: false,
      label: 'No, never'
    }
  ]

  const employerName = watch('employerName')

  const contributionSourceOptions: AutoCompleteItem[] = [
    { value: ContributionSource.PERSONAL, label: 'Personal Funds'},
    { value: ContributionSource.EMPLOYER, label: 'Limited Company'},
  ]

  const handleEmployerSelect = (item: AutoCompleteItem) => {
    const isPublicSector = item?.extraData?.isPublicSector === undefined ? null : item?.extraData?.isPublicSector
    setValue('employerIsPublicSector', isPublicSector, { shouldDirty: true, shouldValidate: true })
    const publicIsLocked = item?.value && !isSpecialValue(item?.value)
    setPublicSectorLocked(publicIsLocked)
  }

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving asset...'] : undefined}
      error={error}
      errorTryAgain={brandsError ? refetchBrands : handleSubmit(onSubmit)}
      errorCancel={onDismiss}
      buttonTitle={disabled ? 'Close' : 'Save'}
      buttonAction={disabled ? onDismiss : handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid && (!isWorkplace || employerName) && !updateAssetIsLoading}
    >
      {
        spouseControlled
        ? <Paragraph>{`This information is read only because you have linked your account with ${spouse?.firstName}.`}</Paragraph>        
        : isTransferLocked ?
        <Paragraph>{`You cannot edit this pension as it ${isActiveOrRejectedTransfer(asset) ? 'is currently being found or transferred' : 'has been transferred'} to your ${JAR_NAME_PERSONAL}}.`}</Paragraph>
        : <></>
      }
      <ManagedAutoCompleteInput
        name={'assetType'}
        formObj={formObj}
        label={'Asset Type'}
        selectOnlyMode={true}
        required={true}
        disabled={true}
        dataSet={enumToAutocompleteOptions(RetirementAssetType)}
      />
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Enter a name for this asset'}
        blurOnSubmit={true}
        disabled={disabled}
        returnKeyType={'next'}
        submitHandler={isPension ? undefined : () => currentValueRef?.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      { isWorkplace ?
        <>
          <ManagedAutoCompleteInput
            name={'employerId'}
            allowFreeText={true}
            textCaptureFieldName={'employerName'}
            unknownItemUseDescription={'Use this employer name'}
            unknownItemSelectedDescription={'Employer name specified by you'}
            formObj={formObj}
            placeholder={'Tap to search...'}
            searchPlaceholder={'Enter 3 or more characters...'}
            label={'Employer'}
            modalTitle={'Find/Enter Employer'}
            dataSet={employerOptions}
            dataTriggerFunction={getSuggestions}
            selectTriggerFunction={handleEmployerSelect}
            required={isWorkplace}
            disabled={disabled}
            isLoading={employersIsLoading || employersIsFetching}
          />
          <Paragraph>{`Public sector employer?`}</Paragraph>
          <ManagedSimpleChoiceInput
            name={'employerIsPublicSector'}
            formObj={formObj}
            options={publicSectorOptions}
            required={isWorkplace}
            disabled={disabled || publicSectorLocked}
            informationMessage={publicSectorLocked ? 'We already know this from our records' : undefined}
          />
        </>
        : undefined
      }
      { isPension ?
        <ManagedAutoCompleteInput
          name={'pensionProviderBrandKey'}
          allowFreeText={true}
          textCaptureFieldName={'pensionProviderBrandName'}
          unknownItemUseDescription={'Use this name'}
          unknownItemSelectedDescription={'Name specified by you'}
          formObj={formObj}
          placeholder={'Tap to search or enter...'}
          label={'Pension Brand'}
          modalTitle={'Find/Enter Pension Brand'}
          dataSet={brandOptions}
          required={!isWorkplace}
          disabled={disabled}
        />
        : undefined
      }
      <ManagedCurrencyInput
        ref={currentValueRef}
        name={'currentValue'}
        formObj={formObj}
        label={isPension || isSaving ? 'Current Value' : 'Value at retirement'}
        placeholder={isPension || isSaving ? 'Enter current value' : 'Enter value at retirement'}
        returnKeyType={'next'}
        required={true}
        minValue={0}
        maxValue={MAX_ASSET_CURRENT_VALUE}
        blurOnSubmit={false}
        disabled={disabled}
        // submitHandler={isPension ? () => monthlyContributionAmountRef?.current?.focus() : undefined }
      />
      { isPension ?
        <>
          <ManagedCurrencyInput
            ref={monthlyContributionAmountRef}
            name={'monthlyContributionAmount'}
            formObj={formObj}
            label={isWorkplace ? 'Overall Monthly Contributions' : 'Monthly Contributions'}
            placeholder={'Enter monthly contribution amount'}
            returnKeyType={'next'}
            minValue={0}
            maxValue={MAX_ASSET_REGULAR_CONTRIBUTIONS}
            blurOnSubmit={false}
            disabled={disabled}
            informationMessage={
              isWorkplace ? `Total amount from employer and ${belongsToSpouse ? spouse?.firstName : 'yourself'}`
              : personalContribution && monthlyContributionAmount ? `Including ${percentage} government topup: ${formatCurrencyAmount(monthlyContributionAmount * factor, 2)}` : undefined}
          />
        </>
        : undefined
      }
      { watch('monthlyContributionAmount') && isPension && !isWorkplace ?
        <ManagedAutoCompleteInput
          name={'contributionSource'}
          formObj={formObj}
          label={'Contribution Source'}
          selectOnlyMode={true}
          required={true}
          dataSet={contributionSourceOptions}
          disabled={disabled}
        />
        : undefined
      }
      { isTransferableType && !belongsToSpouse ? 
        <>
          <Paragraph>{`Are you potentialy interested in transferring this pension to your ${JAR_NAME_ALL}?`}</Paragraph>
          <ManagedSimpleChoiceInput
            name={'isTransferable'}
            formObj={formObj}
            options={transferOptions}
            required={false}
            disabled={disabled}
          />
        </>
        : <></>
      }
    </ModalProcessScreen>
  )
}
