import { MaterialCommunityIcons } from "@expo/vector-icons"
import { JarvisLogoType } from "components/Brand/JarvisLogoType"
import { CardModal } from "components/Layout/CardModal"
import { Subheading } from "components/Typography"
import { BulletItem } from "components/Typography/BulletItem"
import { FooterButton } from 'components/Utility/FooterButton'
import { envVariables } from "environment"
import { JAR_NAME_ALL, WORKPLACE_SCHEME_NAME } from "lib/constants"
import React from 'react'
import { ScrollView, StyleSheet, View } from "react-native"
import { Colors, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import { ExternalLinkButton } from "./ExternalLinkButton"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from "lib/platformHelpers"

type GroupSchemeInfoModalProps = {
  visible: boolean
  onDismiss: any
}

const isWeb = platformIsWeb()

export const GroupSchemeInfoModal = (props: GroupSchemeInfoModalProps) => {
  const { onDismiss, visible } = props

  const { marketingUrl } = envVariables

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          <ScrollView
            contentContainerStyle={{
              paddingVertical: Sizing.x20,
            }}
            showsVerticalScrollIndicator={true}
          >
            <View>
              <View style={{
                paddingVertical: Sizing.x20,
              }}>
                <JarvisLogoType size={Sizing.x60} color='black' />
              </View>
              <Subheading>{WORKPLACE_SCHEME_NAME}</Subheading>
            </View>
            <ContentDivider />

            <Subheading style={localStyles.subTitle}>{`About the Scheme`}</Subheading>
            <BulletItem style={localStyles.text}>{`The ${WORKPLACE_SCHEME_NAME} has been specifcally designed to cater for contractors and freelancers who may also work through umbrella companies.`}</BulletItem>
            <BulletItem style={localStyles.text}>{`It is an integral part of your ${JAR_NAME_ALL}, and allows you to take contributions from any number of participating employers alongside personal contributions.`}</BulletItem>
            <BulletItem style={localStyles.text}>{`No more scattered pension pots - everything in one place, and complete control over your future retirement!`}</BulletItem>
            <ExternalLinkButton
              url={marketingUrl}
              usePdfViewer={false}
            >
              {`Find out more`}
            </ExternalLinkButton>
          </ScrollView>
          <FooterButton onPress={onDismiss} >{'Close'}</FooterButton>
        </View>
      </>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  title: {
    textAlign: 'left',
  },
  subTitle: {
    textAlign: 'left',
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
  smallText: {
    fontSize: Sizing.x10,
  },
})