import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminClientDataId, setCurrentAdminClientDataId } from 'store/uxSlice'
import { AdminClientEditScreen } from './Screens/AdminClientEditScreen'

export const AdminClientEditModal = () => {
  const dispatch = useAppDispatch()
  const currentClient = useAppSelector(currentAdminClientDataId)
  return (
    <ModalFormBuilder
      visible={!!currentClient}
      formTitle='Client Verification'
      onDismiss={() => dispatch(setCurrentAdminClientDataId(undefined))}
      screen={<AdminClientEditScreen />}
    />
  )

}

