import { ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Subheading } from 'components/Typography'
import { ActionCard } from 'components/Utility/ActionCard'
import { InformationButton } from 'components/Utility/InformationButton'
import { InvestmentAssetModalContent } from 'components/Utility/InvestmentAssetModalContent'
import { Logger } from 'lib/logger'
import { groupBy, orderBy, keys } from 'lodash'
import React from 'react'
import { SectionList, View } from 'react-native'
import { useGetAssetCategoriesQuery, useGetAssetsQuery, useGetMeQuery } from 'store/apiSlice'
import { AssetDto } from 'store/dto/asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAssetAllocations, workingAssetAllocations } from 'store/tempDataSlice'
import { Typography } from 'styles'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

interface AssetSectionList {
  title: string,
  data: {
    assets: AssetDto[]
  }[]
}

export const InvestmentAssetAdd_02_FundChoice = ({ route, navigation }) => {
  const { nextScreen, currentAssetAllocation, accountDescriptor }  = route?.params || {}
  
  let options: ManagedCardChoiceInputOption[] = []
  const dispatch = useAppDispatch()
  const workingAssetAllocationsData = useAppSelector(workingAssetAllocations)
  
  const { data: client, isLoading: clientIsLoading, error: clientError, refetch: refetchClient } = useGetMeQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetAssetsQuery()
  const { data: assetCategories, isLoading: assetCategoriesIsLoading, error: assetCategoriesError, refetch: refetchAssetCategories } = useGetAssetCategoriesQuery()
  const isLoading = clientIsLoading || assetsIsLoading || assetCategoriesIsLoading
  const error: any = clientError || assetsError || assetCategoriesError

  const selectedCategoryId = workingAssetAllocationsData?.assetCategoryId
  const selectedCategory = selectedCategoryId ? assetCategories.find(category => {
    return category.id === selectedCategoryId
  }) : undefined

  const currentAssetAllocationIds = currentAssetAllocation ? currentAssetAllocation.map(allocation => {
    return allocation.assetId
  }) : []

  const filteredAssets = assets ? assets.filter(asset => {
    //Exclude anything that isn't enabled
    if (!asset.isEnabled) {
      return false
    }
    //Exclude anything already allocated
    if (currentAssetAllocationIds.includes(asset.id)) {
      return false
    }
    const assetCategoryIds = asset?.assetCategories ? asset?.assetCategories.map(category => {
      return category.id
    }) : []
    return !selectedCategoryId || assetCategoryIds.includes(selectedCategoryId)
  }) : []

  const assetsAvailable = filteredAssets?.length > 0

  const groupedFilteredAssets = groupBy(orderBy(filteredAssets, 'assetTypeName'), 'assetTypeName')

  const alreadyHasAssets = currentAssetAllocation?.length

  const assetsList: AssetSectionList[] = []
  keys(groupedFilteredAssets).forEach(key => {
    assetsList.push({
      title: key,
      data: groupedFilteredAssets[key]
    })
  })

  const selectInvestmentAsset = (investmentAsset: AssetDto) => {
    dispatch(updateWorkingAssetAllocations({
      newAllocation: {
       assetId: investmentAsset?.id,
       proportion:  1
      }
    }))
   next()
  }

  const next = () => {
    navigation.navigate(alreadyHasAssets ? nextScreen : 'Results')
  }

  const goBack = () => {
    navigation.goBack()
  }

  const doButtonSelect = () => {
    const selectedAsset = assets ? assets.find(asset => {
      return asset.id === workingAssetAllocationsData?.newAllocation?.assetId
    }) : undefined
    if (selectedAsset) {
      selectInvestmentAsset(selectedAsset)
    }
  }

  const renderHeader = (section: any) => {
    return <Subheading style={Typography.defined.sectionListHeader}>{section?.title || ''} </Subheading>
  }

  const InvestmentAssetSection = ({ item }) => {
    const imageSource = item?.logo
      ? { uri: item?.logo }
      : require('assets/icons/default_provider.png')
    return (
      <ActionCard
        imageSource={imageSource}
        title={item?.shortName}
        description={item?.longName}
        size={'small'}
        onPress={() => selectInvestmentAsset(item)}
        buttonLabel={''}
        confirmationModalContentFunction={() => <InvestmentAssetModalContent
          accountDescriptor={accountDescriptor}
          asset={item}
          segment={client?.segment}
        />}
        confirmationModalProceedLabel={`Select Fund`}
        confirmationModalCancelLabel={`Choose Another`}
        selected={item?.id === workingAssetAllocationsData?.newAllocation?.assetId}
      />
    )
  }

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Loading funds...']}
      error={error}
      errorTryAgain={clientError ? refetchClient : assetsError ? refetchAssets : refetchAssetCategories}
      buttonTitle={assetsAvailable
          ? alreadyHasAssets
            ? 'Next'
            : 'Finish'
          : 'Go Back'
        }
      buttonAction={assetsAvailable
        ? doButtonSelect
        : goBack
      }
      showButton={true}
      enableButton={!!workingAssetAllocationsData?.newAllocation?.assetId || !assetsAvailable}
      headline={assetsAvailable ? `Ok, please select a fund` : `Sorry, no more funds!`}
      subHeading={assetsAvailable
        ? `These are ${selectedCategory ? '': 'all '}the ${alreadyHasAssets ? 'other ' : ''}funds ${selectedCategory ? `in the ${selectedCategory.name} category`: 'we have available'}`
        : selectedCategory
          ? `We have no other funds in the ${selectedCategory.name} category`
          : `We have no other funds available`
      }
      subHeadingInfo={selectedCategory ?
        <InformationButton
          title={selectedCategory.name}
          texts={[
            selectedCategory.longDescription
          ]}
          imageSource={{ uri: selectedCategory?.logo }}
          buttonTitle={`About ${selectedCategory.name}`} />
        : undefined
      }
    >
      <SectionList
        showsVerticalScrollIndicator={isWeb}
        stickySectionHeadersEnabled={false}
        sections={assetsList}
        // keyExtractor={({ id }, index) => id}
        renderSectionHeader={({ section }) => (
          section?.data?.length > 0 ? renderHeader(section) : <></>
        )}
        renderItem={({ item }) => <InvestmentAssetSection item={item} />}
      />
    </ModalProcessScreen>
  )
}
