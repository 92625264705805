import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { envVariables } from 'environment'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Paper } from 'styles'
import { useDeleteBusinessVerificationResultMutation, useDeleteClientVerificationResultMutation, useGetClientQuery, useUpdateBusinessVerificationResultMutation, useUpdateClientVerificationResultMutation, useVerifyClientMutation } from 'store/apiSlice'
import { AmlStatus, ClientAmlUpdateResultDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminClientDataId, setCurrentAdminClientDataId } from 'store/uxSlice'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { UnborderedTable } from 'components/Utility/UnborderedTable'

export const AdminClientEditScreen = () => {
  const currentClientId = useAppSelector(currentAdminClientDataId)

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [deleteBusinessDialogVisible, setDeleteBusinessDialogVisible] = useState(false)

  const { isLoading: clientIsLoading, error: clientError, data: client, refetch: refetchClient } = useGetClientQuery(String(currentClientId), { skip: !currentClientId } )
  const [verifyClient, { isLoading: verifyClientIsLoading, error: verifyClientError }] = useVerifyClientMutation()
  const [updateClientVerificationResult, { isLoading: updateClientVerificationResultIsLoading, error: updateClientVerificationResultError }] = useUpdateClientVerificationResultMutation()
  const [deleteClientVerificationResult, { isLoading: deleteClientVerificationResultIsLoading, error: deleteClientVerificationResultError }] = useDeleteClientVerificationResultMutation()
  const [updateBusinessVerificationResult, { isLoading: updateBusinessVerificationResultIsLoading, error: updateBusinessVerificationResultError }] = useUpdateBusinessVerificationResultMutation()
  const [deleteBusinessVerificationResult, { isLoading: deleteBusinessVerificationResultIsLoading, error: deleteBusinessVerificationResultError }] = useDeleteBusinessVerificationResultMutation()
  const dispatch = useAppDispatch()

  const { smartSearchDashboardUrl } = envVariables
  
  const clientDataReadyForVerification = client?.nationality && client?.addressDetail && client?.nationalInsuranceNo && client?.maritalStatus && client?.employmentStatus
  const canCreateVerifiedClient = clientDataReadyForVerification && !client?.secclClientId && client?.amlStatus === AmlStatus.PASS
  const canUpdateVerificationResult = !canCreateVerifiedClient && clientDataReadyForVerification && !client?.secclClientId
  const canDeleteVerificationResult = clientDataReadyForVerification && !client?.secclClientId && [AmlStatus.REFER, AmlStatus.FAIL].includes(client?.amlStatus)
  
  const clientVerifiedButBusinessNot = !!client?.secclClientId && !!client?.contributionBusinessAmlStatus && client?.contributionBusinessAmlStatus !== AmlStatus.PASS
  const canUpdateBusinessVerificationResult = clientVerifiedButBusinessNot
  const canDeleteBusinessVerificationResult = clientVerifiedButBusinessNot
  
  const showFullForm = !!client?.secclClientId && !!client?.contributionBusinessAmlStatus
  
  const formObj = useForm<{
    amlReference: string,
    amlStatus: AmlStatus | '',
    secclClientId: string,
    contributionBusinessAmlReference: string,
    contributionBusinessAmlStatus: AmlStatus | '',
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      amlReference: client?.amlReference,
      amlStatus: client?.amlStatus,
      contributionBusinessAmlReference: client?.contributionBusinessAmlReference,
      contributionBusinessAmlStatus: client?.contributionBusinessAmlStatus,
      secclClientId: client?.secclClientId,
    }
  })
  const { handleSubmit, setValue, formState: { isDirty, isValid, errors } } = formObj

  useEffect(() => {
    if (client) {
      setValue('amlReference', client?.amlReference)
      setValue('amlStatus', client?.amlStatus)
      setValue('contributionBusinessAmlReference', client?.contributionBusinessAmlReference)
      setValue('contributionBusinessAmlStatus', client?.contributionBusinessAmlStatus)
      setValue('secclClientId', client?.secclClientId)
    }
  }, [client])

  const onSubmit = async attributes => {
    await updateClientVerificationResult({
      id: client.id,
      amlReference: attributes?.amlReference,
      amlStatus: attributes?.amlStatus,
    })
  }

  const onSubmitBusiness = async attributes => {
    await updateBusinessVerificationResult({
      id: client.id,
      contributionBusinessAmlReference: attributes?.contributionBusinessAmlReference,
      contributionBusinessAmlStatus: attributes?.contributionBusinessAmlStatus,
    })
  }

  const close = () => {
    dispatch(setCurrentAdminClientDataId(undefined))
  }

  const isLoading = clientIsLoading || verifyClientIsLoading || updateClientVerificationResultIsLoading || deleteClientVerificationResultIsLoading || updateBusinessVerificationResultIsLoading || deleteBusinessVerificationResultIsLoading
  const error: any = clientError || verifyClientError || updateClientVerificationResultError || deleteClientVerificationResultError || updateBusinessVerificationResultError || deleteBusinessVerificationResultError

  const handleDeleteClientVerificationResult = async () => {
    setDeleteDialogVisible(false)
    deleteClientVerificationResult(client.id)
  }

  const handleDeleteBusinessVerificationResult = async () => {
    setDeleteBusinessDialogVisible(false)
    deleteBusinessVerificationResult(client.id)
  }

  const handleVerifyClient = async () => {
    verifyClient(client.id)
  }

  const { colors: themeColors } = Paper.useAppTheme()

  const smartSearchUrl = client?.amlStatus && client?.amlCrossReference ? `${smartSearchDashboardUrl}/subjects?name-ref=${client?.amlCrossReference}` : smartSearchDashboardUrl

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={clientIsLoading ? undefined : ['Saving...']}
      buttonTitle={clientVerifiedButBusinessNot ? 'Set Business Verification Result' : canCreateVerifiedClient ? 'Create SECCL Client' : canUpdateVerificationResult ? 'Set AML Result' : 'Close'}
      buttonAction={clientVerifiedButBusinessNot ? handleSubmit(onSubmitBusiness) : canCreateVerifiedClient ? handleVerifyClient : canUpdateVerificationResult ? handleSubmit(onSubmit) : close}
      showButton={true}
      enableButton={canCreateVerifiedClient
        ? true
        : clientVerifiedButBusinessNot
          ? isDirty && isValid
          : canUpdateVerificationResult
            ? isDirty && isValid
            : true
      }
      allowTextButton={canDeleteVerificationResult || canDeleteBusinessVerificationResult}
      textButtonAction={canDeleteVerificationResult ? () => setDeleteDialogVisible(true) : () => setDeleteBusinessDialogVisible(true)}
      textButtonTitle={canDeleteVerificationResult ? `Clear AML Result` : `Clear Business Verification Result`}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: client?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: `${client?.firstName} ${client?.surname}`,
          },
          {
            label: `Email`,
            value: client?.email,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status/Actions`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Current Status`,
            value: clientVerifiedButBusinessNot ? `Client is verified, but their business has not`
              : client?.secclClientId ? `Client has been verified and SECCL client created`
              : !clientDataReadyForVerification ? `Client is still onboarding and has not yet attempted verification`
              : client?.amlStatus === AmlStatus.PASS  ? `Client verification has been manually set`
              : !client?.amlStatus ? `Client verification has been reset`
              : `Client verification has failed`,
          },
          {
            label: `Required Action`,
            value: clientVerifiedButBusinessNot ? `Verify the client's business to enable contributions`
              : client?.secclClientId ? `n/a`
              : !clientDataReadyForVerification ? `n/a`
              : client?.amlStatus === AmlStatus.PASS  ? `Client needs to be created in SECCL`
              : !client?.amlStatus ? `Either set verification status manually or inform client they can retry`
              : `nvestigate manually and set verification status`,
          },
        ]}
        noContentDivider={true}
      />
      <ExternalLinkButton
        url={smartSearchUrl}
        directToBrowser={true}
      >{
        client?.secclClientId ? `View SmartSearch Case`
        : !clientDataReadyForVerification ? `Go to SmartSearch`
        : client?.amlStatus === AmlStatus.PASS  ? `View SmartSearch Case`
        : !client?.amlStatus ? `Go to SmartSearch`
        : `View SmartSearch Case`
      }</ExternalLinkButton>
      <ContentDivider />
      <ManagedAutoCompleteInput
        name={'amlStatus'}
        formObj={formObj}
        label={'AML Status'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(AmlStatus)}
        disabled={!canUpdateVerificationResult}
        required={canUpdateVerificationResult}
      />
      <ManagedTextInput
        name={'amlReference'}
        formObj={formObj}
        label={'AML Reference'}
        placeholder={'SmartSearch AML Reference'}
        disabled={!canUpdateVerificationResult}
        rules={{
          required: canUpdateVerificationResult,
          minLength: 4,
          maxLength: 12,
        }} />
      <ManagedTextInput
        name={'secclClientId'}
        formObj={formObj}
        label={'SECCL Client Id'}
        placeholder={'<Not Yet Created>'}
        disabled={true}
        rules={{
        }} />
      <ConfirmationDialog
        visible={deleteDialogVisible}
        title={'Are you sure?'}
        content={'This will reset to allow the client to retry Identify Verification. You will also still be able to manually set the AML status after doing this.'}
        onCancel={() => setDeleteDialogVisible(false)}
        onConfirm={handleDeleteClientVerificationResult}
      />
      { showFullForm
      ? <>
        <ContentDivider />
        <ManagedAutoCompleteInput
          name={'contributionBusinessAmlStatus'}
          formObj={formObj}
          label={'Business Contribution Verification Status'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(AmlStatus)}
          disabled={!canUpdateBusinessVerificationResult}
          required={canUpdateBusinessVerificationResult}
        />
        <ManagedTextInput
          name={'contributionBusinessAmlReference'}
          formObj={formObj}
          label={'Business Contribution Verification Reference'}
          placeholder={'SmartSearch Watch List Only Reference'}
          disabled={!canUpdateBusinessVerificationResult}
          rules={{
            required: canUpdateBusinessVerificationResult,
            minLength: 4,
            maxLength: 12,
          }} />
          <ConfirmationDialog
            visible={deleteBusinessDialogVisible}
            title={'Are you sure?'}
            content={'This will reset to allow the client to retry Contribution Setup for a business. You will NOT be able to manually override the verification status after doing this - the client will need to self-serve and repeat the contribution setup process.'}
            onCancel={() => setDeleteBusinessDialogVisible(false)}
            onConfirm={handleDeleteBusinessVerificationResult}
          />
        </>
        : <></>
      }
    </ModalProcessScreen>
  )
}

