import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ContributionSource } from 'store/dto/base.dto'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetsAddAsset_07_ContributionChoice = ({ route, navigation }) => {
  const { nextScreen, forTransfer, client }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = React.useState(false)
  
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ contributionChoice: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      contributionChoice: workingRetirementAssetData?.contributionChoice
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const checkOnSubmit = attributes => {
    if (forTransfer && attributes.contributionChoice && workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE) {
      setShowDialog(true)
    } else {
      onSubmit(attributes)
    }
  }

  const onSubmit = attributes => {
    setShowDialog(false)
    dispatch(updateWorkingRetirementAsset({
      contributionSource: workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE ? ContributionSource.EMPLOYER : null,
      ...attributes,
    }))
    //Skip contribution screens if no or skip source if workplace
    navigation.navigate(attributes.contributionChoice
      ? workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE
        ? 'ContributionAmount' : nextScreen
      : 'Value')  
  }

  const contributionOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    }
  ]

  return (
    <ModalProcessScreen
      buttonTitle={'Next'}
      buttonAction={handleSubmit(checkOnSubmit)}
      enableButton={isValid}
      headline={workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE
        ? `Is your employer still paying into your pension?`
        : `Is it receiving regular contributions?`
      }
      subHeading={workingRetirementAssetData?.assetType === RetirementAssetType.WORKPLACE
        ? `This would usually be the case if ${client?.isSpouse ? `${client?.firstName} is` : `you are`} still employed by ${workingRetirementAssetData?.employerName}`
        : `Either from ${client?.isSpouse ? client?.firstName : `you`} or ${client?.isSpouse ? `their` : `your`} own limited company`
      }
    >
      <ManagedSimpleChoiceInput
        name={'contributionChoice'}
        formObj={formObj}
        options={contributionOptions}
        submitHandler={handleSubmit(checkOnSubmit)}
        required={true}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={`We're sorry`}
        content={`We cannot currently accept transfers of workplace pensions with active contributions. You may still add this pension, but it will not be available for transfer until you mark the contributions as ceased.`}
        onConfirm={handleSubmit(onSubmit)}
      />
    </ModalProcessScreen>
  )
}
