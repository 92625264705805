import { Subheading } from 'components/Typography'
import { identity, isNaN, pickBy, round, sum } from 'lodash'
import React, { ComponentProps } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Keyboard, StyleProp, StyleSheet, TextStyle, View } from 'react-native'
import { TextInput } from 'react-native-paper'
import { Flex, Paper, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { InputErrorMessages } from './InputErrorMessages'

export type ManagedProportionInputProps = ComponentProps<typeof TextInput> & {
  formObj: UseFormReturn<any>
  name: string
  labels: string[]
  labelStyle?: StyleProp<TextStyle>
  labelsNumberOfLines?: number
  submitHandler?: any
  minValue?: number
  maxValue?: number
  informationMessage?: string
  informationMessageIsError?: boolean
}

export const ManagedProportionInput = (props: ManagedProportionInputProps) => {
  const { labels, labelStyle, labelsNumberOfLines, formObj, name, minValue, maxValue, style, informationMessage, informationMessageIsError } = props
  const { control, trigger, setValue, formState: { errors, isValid } } = formObj
  const { colors: themeColors } = Paper.useAppTheme()

  const fieldErrors = errors[name]
  const hasError = fieldErrors ? true : false

  const textInputStyles: TextStyle = {
    textAlign: 'right',
    justifyContent: 'center'
  }

  const textInputProps: ComponentProps<typeof TextInput> = {
    ...props,
    theme: {
      colors: {
        text: themeColors.inputText
      }
    },
    style: [Typography.defined.input, textInputStyles, style],
    mode: 'flat',
    error: hasError
  }

  const valuesNotLessThan = (proportions: number[]) => {
    const tooSmall = proportions.filter(p => {
      return p < minValue
    })
    return !tooSmall.length
      ? true
      : `Values must be more than ${minValue * 100}%`
  }

  const valuesNotMoreThan = (proportions: number[]) => {
    const tooLarge = proportions.filter(p => {
      return p > maxValue
    })
    return !tooLarge.length
      ? true
      : `Values must be less than ${maxValue * 100}%`
  }

  const valuesAddUp = (proportions: number[]) => {
    const total = round(sum(proportions), 2)
    const hint = total > 1
      ? `${round(((total - 1) * 100),2).toString()}% over`
      : `${round(((1 - total) * 100),2).toString()}% under`
    return total === 1
      ? true
      : `Values must add up to 100% (${hint})`
  }

  const customValidatorObj: any = pickBy(
    {
      valuesAddUp,
      valuesNotLessThan: minValue ? valuesNotLessThan : undefined,
      valuesNotMoreThan: maxValue ? valuesNotMoreThan : undefined,
    },
    identity
  )

  const getUpdatedValues = (allValues: string[], newValue: string, idx: number) => {
    let newValues = allValues
    newValues[idx] = newValue
    return newValues
  }

  return (
    <View style={layoutStyles.inputContainer}>
      <Controller
        control={control}
        rules={{
          validate: customValidatorObj
        }}
        render={({ field: { onChange, value: proportions } }) => {
          return (
            <>
              {
                proportions.map((proportion, idx) => {
                  const valueToSet = proportion === 0 ? '' : round((proportion * 100),2).toString()
                  return (
                  <View style={localStyles.rowContainerStyle}>
                    <View
                      style={[localStyles.labelContainerStyle, {
                      }]}>
                      <Subheading
                        numberOfLines={labelsNumberOfLines || 1}
                        style={[{
                          ...Typography.defined.input,
                          color: themeColors.inputText,
                          textAlign: 'left',
                        }, labelStyle]}>
                        {labels[idx]}
                      </Subheading>
                  </View>
                  <View style={localStyles.inputContainerStyle}>
                    <TextInput
                        {...textInputProps}
                        value={valueToSet}
                        returnKeyType={'done'}
                        keyboardType={'numeric'}
                        onChangeText={text => {
                          const fixedValue = round((parseFloat(text) / 100), 2)
                          const newValue = isNaN(fixedValue) ? 0 : fixedValue
                          const updatedValues = getUpdatedValues(proportions, newValue, idx)
                          onChange(updatedValues)
                          trigger()
                        }}
                        onSubmitEditing={() => {
                          Keyboard.dismiss()
                        }}
                        right={
                          <TextInput.Icon icon={'percent-outline'} color={themeColors.primary} forceTextInputFocus={false} />
                        }
                      />
                    </View>
              
              </View>
              )
            })
            }
            <InputErrorMessages formObj={props.formObj} name={props.name} informationMessage={informationMessage} informationMessageIsError={informationMessageIsError} />
          </>
          )}
        }
        name={props.name}
      />
    </View>
  )
}

const localStyles = StyleSheet.create({
  rowContainerStyle: {
    ...Flex.row.start,
  },
  labelContainerStyle: {
    ...Flex.override.fill,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputContainerStyle: {
    ...Flex.override.right,
  }
})