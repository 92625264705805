import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { Button } from 'components/Utility/Button'
import React from 'react'
import { Paper } from 'styles'
import { RetirementAssetTransferStepIdentifier } from 'store/uxSlice'
import { TaskList } from '../Components/TaskList'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useGetPensionBrandsQuery } from 'store/apiSlice'

export const RetirementAssetTransfer_00_Intro = ({route, navigation}) => {
  const { nextScreen, onDismiss, asset, client, isRetransfer, isFailedTrace }  = route?.params || {}

  const dispatch = useAppDispatch()

  const { colors: themeColors } = Paper.useAppTheme()

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const isLoading = brandsIsLoading
  const error = brandsError

  const currentlySelectedBrand = brands ? brands.find(brand => {
    return brand?.key === asset?.pensionProviderBrandKey
  }) : undefined

  let nextTask = RetirementAssetTransferStepIdentifier.PROVIDER
  //Derive next task...
  if (!isRetransfer) {
    if (asset?.tracingRequested) {
      nextTask = RetirementAssetTransferStepIdentifier.TRACING
    } else {
      if (asset?.pensionProviderId) {
        nextTask = RetirementAssetTransferStepIdentifier.REFERENCE
      }
      if (asset?.externalProviderReference || asset?.useClientPreviousNameForTransfer) {
        nextTask = RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS
      }
      //Assume restart if all data provided
      if (asset?.transferAddress && asset?.useClientPreviousNameForTransfer === false ) {
        nextTask = RetirementAssetTransferStepIdentifier.PROVIDER
      }
    }
  }

  const goToProviderStep = () => {
    dispatch(updateWorkingRetirementAsset({
      tracingRequested: false,
    }))
    navigation.navigate('ProviderBrand')
  }

  const next = () => {
    let nextStepScreen = 'ProviderBrand'

    if (nextTask === RetirementAssetTransferStepIdentifier.REFERENCE) {
      nextStepScreen = 'Reference'
    } else if (nextTask === RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS) {
      nextStepScreen = 'PersonalDetailsAddressChoice'
    } else if (nextTask === RetirementAssetTransferStepIdentifier.TRACING) {
      nextStepScreen = asset?.assetType === RetirementAssetType.WORKPLACE
        ? 'TracingEmployerStartDate'
          : currentlySelectedBrand?.raindropId
            ? 'TracingReference'
            : 'TracingProvider'
    }
    
    navigation.navigate(
      isRetransfer
        ? nextScreen
        : nextStepScreen
    )
  }

  const tasks: RetirementAssetTransferStepIdentifier[] = [
    RetirementAssetTransferStepIdentifier.PROVIDER,
    RetirementAssetTransferStepIdentifier.REFERENCE,
    RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS,
  ]
  if (asset?.tracingRequested) {
    tasks.push(RetirementAssetTransferStepIdentifier?.TRACING)
  }

  //Determine pendingSteps
  const pendingSteps: RetirementAssetTransferStepIdentifier[] = []
  if (asset?.tracingRequested) {
    if (!asset?.pensionProviderId) {
      pendingSteps.push(RetirementAssetTransferStepIdentifier.PROVIDER)
    }  
    if (!asset?.externalProviderReference) {
      pendingSteps.push(RetirementAssetTransferStepIdentifier.REFERENCE)
    }
  }
  
  return (
    <ModalProcessIntroScreen
      isLoading={isLoading}
      error={error}
      onDismiss={onDismiss}
      buttonTitle={isRetransfer
        ? 'Review Information'
        : nextTask === RetirementAssetTransferStepIdentifier.PROVIDER
          ? 'Start'
          : 'Continue'
      }
      buttonAction={next}
      showButton={true}
      illustrationFilename={'task_list.png'}
      headline={isRetransfer
        ? `Let's see if we can fix the problem`
        : isFailedTrace
          ? `Add details for transfer`
          : nextTask === RetirementAssetTransferStepIdentifier.REFERENCE
            ? `Ok, two more steps to complete`
            : nextTask === RetirementAssetTransferStepIdentifier.PERSONAL_DETAILS
              ? asset?.tracingRequested
                ? `Ok, let's check the details`
                : `Ok, just one more step to complete`
              : `Let's get your pension ready for transfer`
      }
      subHeading={isRetransfer
        ? `Transfers can be rejected if information doesn't match the old provider's records`
        : isFailedTrace
          ? `We couldn't find this pension, but you can attempt transfer if you now know the details`
          : nextTask !== RetirementAssetTransferStepIdentifier.PROVIDER
            ? undefined
            : `For a speedy transfer, we just need 3 pieces of information`
      }
    >
      {
        !isRetransfer && nextTask !== RetirementAssetTransferStepIdentifier.PROVIDER
        ? <Button
            mode={'text'}
            color={themeColors.accent}
            onPress={goToProviderStep}
          >
            {'Start Again Instead'}
          </Button>
        : <></>
      }
      <TaskList
        asset={asset}
        allTasks={tasks}
        pendingSteps={pendingSteps}
        nextTask={nextTask}
        nextTaskAction={next}
        isRetransfer={isRetransfer}
      />

    </ModalProcessIntroScreen>
  )
}

