import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminInvestmentPlanDataId, setCurrentAdminInvestmentPlanDataId } from 'store/uxSlice'
import { AdminEditInvestmentPlanScreen } from './Screens/AdminEditInvestmentPlanScreen'

export const AdminEditInvestmentPlansModal = () => {
  const dispatch = useAppDispatch()
  const currentInvestmentPlan = useAppSelector(currentAdminInvestmentPlanDataId)
  return (
    <ModalFormBuilder
      visible={!!currentInvestmentPlan}
      formTitle='Edit Investment Plan Details'
      onDismiss={() => dispatch(setCurrentAdminInvestmentPlanDataId(undefined))}
      screen={<AdminEditInvestmentPlanScreen />}
    />
  )

}

