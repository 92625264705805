import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_35_TracingEmployerShortPeriod = ({ route, navigation }) => {
  const { nextScreen, asset }: { nextScreen: string, asset: RetirementAssetDto }  = route?.params || {}
  //Temp state
  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = useState(false)
  
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ employerShortDuration: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employerShortDuration: workingRetirementAssetData?.employerShortDuration || asset?.employerShortDuration
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    setShowDialog(false)
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: true,
    }))

    //Navigate to result if short
    navigation.navigate(attributes?.employerShortDuration ? 'Result' : nextScreen)
  }

  const softOnSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: true,
    }))
  }

  const durationOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Yes',
    },
    {
      value: true,
      label: 'No, less than 3 months',
    },
  ]

  const employerShortDuration = watch('employerShortDuration')

  return (
    <ModalProcessScreen
      buttonTitle={'Finish'}
      buttonAction={employerShortDuration ? () => setShowDialog(true) : handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`Did you work there for more than 3 months?`}
      subHeading={`You are unlikely to have a pension for a short employment period`}
    >
      <ManagedSimpleChoiceInput
        name={'employerShortDuration'}
        formObj={formObj}
        options={durationOptions}
        submitHandler={handleSubmit(softOnSubmit)}
        required={true}
        informationMessage={employerShortDuration ? `We can only find pensions where your employment lasted more than 3 months` : undefined}
        informationMessageIsError={employerShortDuration}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Are you sure?'}
        content={`We can only find workplace pension where you were employed for more than 3 months.\n\nYou can save your answers so far, but we cannot proceed unless you worked there for a longer period.\n\nPlease double check your records if you are certain you had a pension, please answer "Yes" to this question.`}
        confirmLabel={`Save`}
        cancelLabel={`Go Back`}
        onConfirm={handleSubmit(onSubmit)}
      />
    </ModalProcessScreen>
  )
}