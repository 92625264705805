import { ModalProcessScreen } from 'components/Layout'
import { DashboardHero } from 'components/Typography'
import React, { useEffect } from 'react'
import { ampli } from 'src/ampli'
import { useUpdateMeMutation, useUpdateRetirementProfileMutation } from 'store/apiSlice'
import { Colors } from 'styles'
import { pick } from 'lodash'
import { Logger } from 'lib/logger'

export const MakeContributions_39_TargetRetirementAgeSave = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}

  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()
  const [updateRp, { data: updatedRp, error: updatedRpError, isLoading: updatedRpIsLoading } ] = useUpdateRetirementProfileMutation()
  const isLoading = updatedRpIsLoading || clientUpdateIsLoading || !updateClient
  const error: any = updatedRpError || clientUpdateError

  //Save on enter
  useEffect(() => {
    saveRp()
  }, [])

  //Save client when RP saved
  useEffect(() => {
    if (updatedRp) {
      ampli.retirementProfileUpdate({
        ...pick(updatedRp, [
          'asCouple',
          'insideLondon',
          'expensesNetTotalAmount',
          'targetRetirementAge',
          'baseBudgetLevel',
        ]),
      })
      saveClient()
    }
  }, [updatedRp])

  const saveRp = () => {
    updateRp({
      targetRetirementAge: contributionsData?.newTargetRetirementAge
    })
  }

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        contribute: true,
      },
    })
  }


  //Clear data and either close or next step
  const next = () => {
    navigation.navigate(nextScreen)
  }

  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={['Updating target retirement age...']}
      error={error}
      errorTryAgain={updatedRp ? saveClient : saveRp}
      errorCancel={onDismiss}
      buttonTitle={'Next'}
      buttonAction={next}
      showButton={true}
      headline={`Ok, we've adjusted your retirement target`}
      subHeading={`You financial forecasts will now use this new target`}
    >
      <DashboardHero style={{ color: Colors.brand.purple1 }}>{updatedRp?.targetRetirementAge}</DashboardHero>
    </ModalProcessScreen>
  )
}
