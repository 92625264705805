import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { FooterButton } from "components/Utility/FooterButton"
import { getHorizontalScale, getScale, getScreenAppWidth, getVerticalScale, scaleNormalizer } from "lib/scaleHelpers"
import React, { useRef, useState } from 'react'
import { StyleSheet, View } from "react-native"
import SignatureScreen from 'react-native-signature-canvas'
import { ClientSignatureDto } from "store/dto/client.dto"
import { Colors, Flex, Sizing } from "styles"
import { layoutStyles } from "styles/common"
import * as signatureBackground from './signatureBackground.json'
import { Image } from 'react-native'

type ClientSignatureModalProps = {
  clientSignature: ClientSignatureDto
  visible: boolean
  onDismiss: any
  onSave: Function
}

export const ClientSignatureModal = (props: ClientSignatureModalProps) => {
  const { clientSignature, onDismiss, onSave, visible } = props

  // const [isValid, setIsValid] = useState(undefined)
  const [signature, setSignature] = useState(undefined)

  const signRef = useRef()
  
  const signaturePadWidth = getScreenAppWidth() - Sizing.x60
  const signaturePadHeight = scaleNormalizer(260)

  const handleEnd = () => {
    signRef.current.readSignature()
  }

  const handleOk = (data) => {
    // checkDimensions(data)
    setSignature(data)
  }

  const handleClear = () => {
    signRef.current.clearSignature();
    setSignature(undefined)
  }

  // Called after ref.current.getData()
  const handleSubmit = () => {
    onSave(signature)
  }
  
  const webStyle = `.m-signature-pad--footer
    .save {
        display: none;
    }
    .clear {
        display: none;
    }

    .m-signature-pad--body {
      position: absolute;
      border: 0px solid #f4f4f4;
    }

    .m-signature-pad {
      position: absolute;
      font-size: 10px;
      border: 0px solid #e8e8e8;
      background-color: #fff;
    }

    body, html {
      font-family: Helvetica, Sans-Serif;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      width: ${signaturePadWidth}px;
      height: ${signaturePadHeight}px;
    }
  `

  const bgSrc = signatureBackground.base64

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <>
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>          
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1}/>
          </View>
        </View>
        <View style={{...
          Flex.column.start,
          ...Flex.override.fill,
        }}>
          <View style={{
           ...Flex.column.end,
           ...Flex.override.fill,
          }}>
            <View style={{
              backgroundColor: Colors.neutral.white,
              borderRadius: Sizing.x10,
              paddingTop: Sizing.x10,
              width: signaturePadWidth + Sizing.x20,
              height: signaturePadHeight + Sizing.x20,
              alignSelf: 'center',
              marginBottom: Sizing.x50,
            }}>
              <View style={{
                width: signaturePadWidth,
                height: signaturePadHeight,
                alignSelf: 'center',
                justifyContent: 'center'
              }}>
                <SignatureScreen
                  ref={signRef}
                  bgSrc={bgSrc}
                  bgHeight={signaturePadHeight}
                  bgWidth={signaturePadWidth}
                  // dataURL={dataUrl}
                  onOK={handleOk}
                  onEnd={handleEnd}
                  webStyle={webStyle}
                  autoClear={false}
                  descriptionText={''}
                  trimWhitespace={true}
                />
              </View>
            </View>
          </View>
          <View style={{ ...Flex.override.bottom }}>
            <FooterButton onPress={handleClear} mode={'text'} >{'Clear'}</FooterButton>
            <FooterButton onPress={handleSubmit} disabled={!signature} mode={'contained'} >{'Save Signature'}</FooterButton>
          </View>
        </View>
      </>
    </CardModal>
  )
}
