import { Text } from 'components/Typography/Text'
import React, { ComponentProps } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Button as PaperButton } from "react-native-paper"
import { Colors, Sizing, Paper, Typography } from 'styles'
import { ConfirmationDialog } from './ConfirmationDialog'
import { platformIsWeb } from 'lib/platformHelpers'

type ButtonProps = ComponentProps<typeof PaperButton> & {
  confirmation?: ButtonConfirmationProps
  backgroundColor?: string
}

type ButtonConfirmationProps = {
  requiresConfirmation: boolean
  dialogTitle?: string
  dialogContent?: string
}

export const Button = (props: ButtonProps) => {
  const { disabled, children, confirmation, mode, labelStyle, color, backgroundColor, icon, onPress, style } = props

  const [dialogVisible, setDialogVisible] = React.useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const { themeType } = themeColors

  const disabledBackgroundColor = themeType === 'dark' ? Colors.neutral.white : Colors.neutral.s900
  const disabledForegroundColor = themeType === 'dark' ? Colors.neutral.s900 : Colors.neutral.white

  const modeToUse = mode || 'contained'

  const handleConfirm = () => {
    hideDialog()
    onPress(undefined)
  }

  const isWeb = platformIsWeb()

  //This wraps the react-native-paper button so we can control the background
  //colour for disabled state
  return (
    <View style={localStyles.buttonWrap}>
      <PaperButton
        {...props}
        onPress={confirmation?.requiresConfirmation ? showDialog : props.onPress}
        mode={modeToUse}
        contentStyle={{
          padding: Sizing.x3,
          width: modeToUse === 'contained' && isWeb ? Sizing.x300 : undefined,
          backgroundColor: modeToUse !== 'contained' ? undefined : disabled ? disabledBackgroundColor : backgroundColor ? backgroundColor : themeColors.primary,
        }}
        style={[
          style,
          {
            borderRadius: Sizing.x10,
            opacity: disabled ? 0.2 : 1,
          }]}
        labelStyle={[
          Typography.defined.buttonLabel,
          labelStyle,
          {
            color: disabled ? disabledForegroundColor : color ? color : themeColors.secondary
          },
        ]}
      >
        { modeToUse === 'text'
          ? <Text style={{
            color: disabled ? disabledForegroundColor : color ? color : themeColors.primary,
            ...Typography.defined.textButtonLabel,
          }}>{children}</Text>
          : children
        }        
      </PaperButton>
      {
        confirmation?.requiresConfirmation
        ? 
          <ConfirmationDialog
            visible={dialogVisible}
            title={confirmation?.dialogTitle}
            content={confirmation?.dialogContent}
            onCancel={hideDialog}
            onConfirm={handleConfirm}
          />
        : <></>
      }
  </View>
  )
}

const localStyles = StyleSheet.create({
  buttonWrap: {
    ...Platform.select({
      //Prevents buttons being full width on web
      web: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      default: {}
    })
  },
})