import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { WebHeader } from 'components/Layout/WebHeader'
import { Text } from 'components/Typography/Text'
import { envVariables } from 'environment'
import * as Application from 'expo-application'
import { getInitialURL, parse, useURL } from 'expo-linking'
import * as Notifications from 'expo-notifications'
import { AuthenticationLogoutScreen } from 'features/Authentication/AuthenticationLogoutScreen'
import { AuthenticationProcessStack } from 'features/Authentication/AuthenticationProcessStack'
import { CalculatorHeroScreen } from 'features/Calculator/CalculatorHeroScreen'
import { CalculatorIntroScreen } from 'features/Calculator/CalculatorIntroScreen'
import { CalculatorProcessStack } from 'features/Calculator/CalculatorProcessStack'
import { EnrolmentsFunctionalAreaStack } from 'features/FunctionalAreas/Enrolments/EnrolmentsFunctionalAreaStack'
import { ClientSetupHeroScreen } from 'features/Onboarding/ClientSetup/ClientSetupHeroScreen'
import { ClientSetupIntroScreen } from 'features/Onboarding/ClientSetup/ClientSetupIntroScreen'
import { ClientSetupProcessStack } from 'features/Onboarding/ClientSetup/ClientSetupProcessStack'
import { ClientVerificationHeroScreen } from 'features/Onboarding/ClientVerification/ClientVerificationHeroScreen'
import { ClientVerificationIntroScreen } from 'features/Onboarding/ClientVerification/ClientVerificationIntroScreen'
import { ClientVerificationProcessStack } from 'features/Onboarding/ClientVerification/ClientVerificationProcessStack'
import { OnboardingPhaseConsolidateHeroScreen } from 'features/Onboarding/OnboardingPhaseConsolidateHeroScreen'
import { OnboardingPhaseContributeHeroScreen } from 'features/Onboarding/OnboardingPhaseContributeHeroScreen'
import { OnboardingPhaseFamilyHeroScreen } from 'features/Onboarding/OnboardingPhaseFamilyHeroScreen'
import { OnboardingPhasePlanHeroScreen } from 'features/Onboarding/OnboardingPhasePlanHeroScreen'
import { OnboardingStatusScreen } from 'features/Onboarding/OnboardingStatusScreen'
import { RetirementAssetSetupHeroScreen } from 'features/Onboarding/RetirementAssetSetup/RetirementAssetSetupHeroScreen'
import { RetirementAssetSetupIntroScreen } from 'features/Onboarding/RetirementAssetSetup/RetirementAssetSetupIntroScreen'
import { RetirementAssetSetupProcessStack } from 'features/Onboarding/RetirementAssetSetup/RetirementAssetSetupProcessStack'
import { RetirementIncomeSetupHeroScreen } from 'features/Onboarding/RetirementIncomeSetup/RetirementIncomeSetupHeroScreen'
import { RetirementIncomeSetupIntroScreen } from 'features/Onboarding/RetirementIncomeSetup/RetirementIncomeSetupIntroScreen'
import { RetirementIncomeSetupProcessStack } from 'features/Onboarding/RetirementIncomeSetup/RetirementIncomeSetupProcessStack'
import { RetirementProfileSetupHeroScreen } from 'features/Onboarding/RetirementProfileSetup/RetirementProfileSetupHeroScreen'
import { RetirementProfileSetupIntroScreen } from 'features/Onboarding/RetirementProfileSetup/RetirementProfileSetupIntroScreen'
import { RetirementProfileSetupProcessStack } from 'features/Onboarding/RetirementProfileSetup/RetirementProfileSetupProcessStack'
import { BeneficiariesSetupHeroScreen } from 'features/Processes/BeneficiariesSetup/BeneficiariesSetupHeroScreen'
import { BeneficiariesSetupIntroScreen } from 'features/Processes/BeneficiariesSetup/BeneficiariesSetupIntroScreen'
import { BeneficiariesSetupProcessStack } from 'features/Processes/BeneficiariesSetup/BeneficiariesSetupProcessStack'
import { BulkRetransferHeroScreen } from 'features/Processes/BulkRetransfer/BulkRetransferHeroScreen'
import { BulkRetransferIntroScreen } from 'features/Processes/BulkRetransfer/BulkRetransferIntroScreen'
import { BulkRetransferProcessStack } from 'features/Processes/BulkRetransfer/BulkRetransferProcessStack'
import { BulkTransferHeroScreen } from 'features/Processes/BulkTransfer/BulkTransferHeroScreen'
import { BulkTransferIntroScreen } from 'features/Processes/BulkTransfer/BulkTransferIntroScreen'
import { BulkTransferProcessStack } from 'features/Processes/BulkTransfer/BulkTransferProcessStack'
import { ContributionsHeroScreen } from 'features/Processes/Contributions/ContributionsHeroScreen'
import { ContributionsIntroScreen } from 'features/Processes/Contributions/ContributionsIntroScreen'
import { ContributionsProcessStack } from 'features/Processes/Contributions/ContributionsProcessStack'
import { InvestmentChoiceHeroScreen } from 'features/Processes/InvestmentChoice/InvestmentChoiceHeroScreen'
import { InvestmentChoiceIntroScreen } from 'features/Processes/InvestmentChoice/InvestmentChoiceIntroScreen'
import { InvestmentChoiceProcessStack } from 'features/Processes/InvestmentChoice/InvestmentChoiceProcessStack'
import { InviteAcceptHeroScreen } from 'features/Processes/InviteAccept/InviteAcceptHeroScreen'
import { InviteAcceptIntroScreen } from 'features/Processes/InviteAccept/InviteAcceptIntroScreen'
import { InviteAcceptProcessStack } from 'features/Processes/InviteAccept/InviteAcceptProcessStack'
import { SchemeEnrolmentSetupHeroScreen } from 'features/Processes/SchemeEnrolmentSetup/SchemeEnrolmentSetupHeroScreen'
import { SchemeEnrolmentSetupIntroScreen } from 'features/Processes/SchemeEnrolmentSetup/SchemeEnrolmentSetupIntroScreen'
import { SchemeEnrolmentSetupProcessStack } from 'features/Processes/SchemeEnrolmentSetup/SchemeEnrolmentSetupProcessStack'
import { SchemeInviteAcceptHeroScreen } from 'features/Processes/SchemeInviteAccept/SchemeInviteAcceptHeroScreen'
import { SchemeInviteAcceptIntroScreen } from 'features/Processes/SchemeInviteAccept/SchemeInviteAcceptIntroScreen'
import { SchemeInviteAcceptProcessStack } from 'features/Processes/SchemeInviteAccept/SchemeInviteAcceptProcessStack'
import { SpouseInviteHeroScreen } from 'features/Processes/SpouseInvite/SpouseInviteHeroScreen'
import { SpouseInviteIntroScreen } from 'features/Processes/SpouseInvite/SpouseInviteIntroScreen'
import { SpouseInviteProcessStack } from 'features/Processes/SpouseInvite/SpouseInviteProcessStack'
import { navigateFromUrl, rootNavigate, rootNavigationRef } from 'lib/RootNavigation'
import { identifyAmplitudeUser } from 'lib/amplitudeHelpers'
import { getCachedToken, getLastUserContext, logoutUserFromState, parsePasswordlessLoginFromUrl, setUserWithToken, urlPathIsFederated, userIsAdministrator, userIsReady, verifyBiometricsIfAvailable } from 'lib/authHelpers'
import { LINK_PARAMS_KEY_NAME } from 'lib/constants'
import { firstQueryParam } from 'lib/generalHelpers'
import { loginToIntercom } from 'lib/intercomHelpers'
import { determineAppContextFromUrl, getLinkingMap, parseRealPathFromUrl, pickLinkParams, pickLoginParams } from 'lib/linkingHelpers'
import { getAppCreateUserMessages, getAppLoadingMessages, getLoginLoadingMessages } from 'lib/loadingHelpers'
import { getJsonItemFromStore, removeJsonItemFromStore, setJsonItemInStore } from 'lib/localStoreHelpers'
import { Logger } from 'lib/logger'
import { getEntryScreenForAppContext, getStackScreenForAppContext } from 'lib/navigationHelpers'
import { isEmpty, pick } from 'lodash'
import { AppUpgradeScreen, BiometricLockScreen, LoadingScreen, ServerErrorScreen, ServerOfflineScreen, UserBlockedScreen } from 'navigation/stacks/Gates'
import Auth0 from 'platform-lib/auth0'
import branch from 'platform-lib/branch'
import { AnalyticsProvider } from 'providers/analytics.context'
import { CalendarProvider } from 'providers/calendar.context'
import { GuidanceProvider } from 'providers/guidance.context'
import { SuggestionsProvider } from 'providers/suggestions.context'
import React, { useEffect, useState } from 'react'
import { Linking, View } from 'react-native'
import { Portal, Snackbar } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { batch, useDispatch, useSelector } from 'react-redux'
import { lt } from 'semver'
import { ampli } from 'src/ampli'
import { useCreateCurrentUserMutation, useDeleteTokenForCurrentUserMutation, useGetCurrentUserQuery, useGetStatusQuery, useUpdateCurrentUserMutation } from 'store/apiSlice'
import { AppContext, LinkParams, linkParams, LoginParams, loginParams, pushToken, setAppContext, setClientHash, setHaveAppUser, setLinkParams, setLoginParams, setNeedsClientUpgrade, setUser } from 'store/authSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { usePasswordlessTokenMutation } from 'store/passwordlessSlice'
import { setIntercomUnreadMessageCount, setSnackbarData, snackbarData } from 'store/uxSlice'
import { Colors, Sizing } from 'styles'
import { AdminAppStack } from './AdminAppStack'
import { EmployerAppStack } from './EmployerAppStack'
import { EntryPointAdminScreen } from './EntryPoints/EntryPointAdminScreen'
import { EntryPointAffiliateScreen } from './EntryPoints/EntryPointAffiliateScreen'
import { EntryPointDefaultScreen } from './EntryPoints/EntryPointDefaultScreen'
import { EntryPointEmployerScreen } from './EntryPoints/EntryPointEmployerScreen'
import { EntryPointPartnerScreen } from './EntryPoints/EntryPointPartnerScreen'
import { EntryPointSchemeEnrolmentScreen } from './EntryPoints/EntryPointSchemeEnrolmentScreen'
import { EntryPointSchemeInviteScreen } from './EntryPoints/EntryPointSchemeInviteScreen'
import { NewUserCarouselScreen } from './EntryPoints/NewUserCarouselScreen'
import { PasswordlessErrorScreen } from './Gates/PasswordlessErrorScreen'
import { MainAppStack } from './MainAppStack'
import { AuthenticationFederatedLoginScreen } from 'features/Authentication/AuthenticationFederatedLoginScreen'
import { platformIsWeb } from 'lib/platformHelpers'
import { Paragraph, Subheading } from 'components/Typography'
import { getAllNormalizationParams } from 'lib/scaleHelpers'
import { AUTHTYPE } from 'store/dto/base.dto'

//NOTES
//authUser refers to the user that owns the JWT being used to prove identity
//user is the user record from the backend

const Auth0Provider = Auth0.Auth0Provider
const useAuth0 = Auth0.useAuth0

const Stack = createNativeStackNavigator()

// const CHECK_APP_VERSION_IN_DEV = false

export enum AppEntryLock {
  UNDECIDED = 'UNDECIDED',
  SERVER_UNREACHABLE = 'SERVER_UNREACHABLE',
  SERVER_OFFLINE = 'SERVER_OFFLINE',
  APP_UPGRADE_REQUIRED = 'APP_UPGRADE_REQUIRED',
  BIOMETRICS = 'BIOMETRICS',
  USER_NOT_YET_CREATED = 'USER_NOT_YET_CREATED',
  PASSWORDLESS_TOKEN_LOADING = 'PASSWORDLESS_TOKEN_LOADING',
  PASSWORDLESS_TOKEN_ERROR = 'PASSWORDLESS_TOKEN_ERROR',
  USER_BLOCKED = 'USER_BLOCKED',
}

const isWeb = platformIsWeb()

const normalization = getAllNormalizationParams()

export const RootStack = () => {
  // Logger.debug('RootStack...')
  Logger.debug({ normalization }, 'Scale normalization')

  const { auth0Domain, auth0ClientId, auth0Audience, auth0Scopes, appUrl  } = envVariables

  //Hooks
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const url = useURL()
  const { isAuthenticated, getAccessTokenSilently, clearSession, clearCredentials, logout } = useAuth0()
  
  //Central state
  const pushTokenValue = useAppSelector(pushToken)
  const linkConfig = useAppSelector(linkParams)
  const loginConfig = useAppSelector(loginParams)
  const snackbar = useAppSelector(snackbarData)
  const serverUnavailable = useSelector((state: any) => state.auth.serverUnavailable)
  const needsClientUpgrade = useSelector((state: any) => state.auth.needsClientUpgrade)
  const authUser = useSelector((state: any) => state.auth.user)
  const haveAppUser = useSelector((state: any) => state.auth.haveAppUser)
  const appContext = useSelector((state: any) => state.auth.appContext)
  const userHasClient = useSelector((state: any) => state.auth.userHasClient)
  //Decide if user is ready (authenticated)
  const authUserReady = userIsReady(authUser)

  //Local state
  const [token, setToken] = useState<any>(undefined)
  const [appEntryLock, setAppEntryLock] = useState<AppEntryLock>(AppEntryLock.UNDECIDED)
  const [lastAppContext, setLastAppContext] = useState<AppContext>(undefined)
  const [biometricsCancelled, setBiometricsCancelled] = useState(false)
  const [waitingForBiometrics, setWaitingForBiometrics] = useState(false)
  const [lastEmail, setLastEmail] = useState(undefined)

  //Remote state
  const { data: status, isLoading: statusIsLoading, isFetching: statusIsFetching, error: statusError, refetch: refetchStatus } = useGetStatusQuery(undefined, { skip: serverUnavailable })
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !status || !authUserReady || serverUnavailable || needsClientUpgrade })

  //Remote mutations
  const [ createCurrentUser, { data: newCurrentUser, error: userCreateError, isLoading: userCreateIsLoading } ] = useCreateCurrentUserMutation()
  const [ updateCurrentUser, { data: updatedUser, isLoading: userUpdateIsLoading, error: userUpdateError }] = useUpdateCurrentUserMutation()
  const [ deletePushToken ] = useDeleteTokenForCurrentUserMutation()
  const [ getPasswordlessToken , { data: passwordlessToken, error: passwordlessTokenError, isLoading: passwordlessTokenIsLoading, reset: passwordlessReset }] = usePasswordlessTokenMutation()

  //LOCAL TOKEN HANDLING - START

  //Looks for local credentials
  //Results in auto-login if available and user passes any biometric checks
  useEffect(()=>{
    getLocalCredentials()
  },[])

  //Handle change in isAuthenticated for web
  useEffect(() => {
    // Logger.info({ isAuthenticated }, `########## Detected change in isAuthenticated`)
    if (isWeb) {
      if (isAuthenticated) {
        Logger.info(`(Web) change in isAuthenticated - logging in...`)
        dispatch(
          setUser({
            localToken: true,
            loginMethod: undefined,
            data: null,
            loggingIn: true,
            token: null,
            refreshToken: null,
            error: `Logging in...`,
            impersonation: null,
          })
        ) 
        const getAccessToken = async () => {
          await getAccessTokenSilently({
            audience: auth0Audience,
            redirectUri: isWeb ? appUrl : undefined,
            scope: auth0Scopes,
          }).then(async accessToken => {
            Logger.info(`Got token (web) - setting user...`)
            const tokenSet = {
              accessToken,
              refreshToken: null, //We are not given this from getAccessTokenSilently
            }
            await setUserWithToken(tokenSet, 'browser-session', dispatch)
          }).catch(e => {
            Logger.error(`Error getting token`, e)
          })
        }
        getAccessToken()
      }
    }
  }, [isAuthenticated])

  //When we have a user set haveAppUser
  useEffect(()=>{
    if (user && !haveAppUser) {
      Logger.debug(`Setting haveAppUser`)
      dispatch(setHaveAppUser(true))
    }
  },[user])

  //When haveAppUser, go to the relevant stack
  useEffect(()=>{
    if (haveAppUser) {
      const stackScreen = getStackScreenForAppContext(appContext)
      Logger.debug(`Navigating to stack screen (${stackScreen})...`)
      rootNavigate(stackScreen)
    }
  },[haveAppUser])

  //On load, look for a token in store
  useEffect(()=>{
    const lookForToken = async () => {
      //Look for token in SecureStore/AsyncStorage
      const cachedToken = await getCachedToken()
      if (cachedToken) {
        // Logger.debug(`Setting cached token...`)
        setToken(cachedToken)
      } else {
        Logger.info(`No cached token.`)
      }
    }
    lookForToken()
  },[])

  const doLogout = () => {
    Logger.info('Performing logout...')
    ampli.clientLogout()
    if (pushTokenValue) {
      Logger.debug('Deleting push token...')
      deletePushToken(pushTokenValue)
    }
    logoutUserFromState(dispatch, clearSession, clearCredentials, logout)
  }

  //Get credentials using biometrics
  const getLocalCredentials = async () => {
    setBiometricsCancelled(false)
    //Look for token in SecureStore/AsyncStorage
    const token = await getCachedToken()
    if (token) {
      setWaitingForBiometrics(true)
      const result = await verifyBiometricsIfAvailable()
      if (result) {
        Logger.debug(`Biometrics passed or skipped...`)
        await setUserWithToken(token, 'device-token', dispatch)
        setWaitingForBiometrics(false)
        //PA-1657 REMOVE
        // rootNavigateBack()
      } else {
        Logger.debug(`Biometrics failed or cancelled - logout...`)
        setBiometricsCancelled(true)
      }
    } else {
      setBiometricsCancelled(true)
    }
  }

  const logoutFromBiometrics = () => {
    setBiometricsCancelled(false)
    setWaitingForBiometrics(false)
    Logger.debug(`Cancelling biometrics and logout...`)
    doLogout()
  }
  
  //LOCAL TOKEN HANDLING - END

  //PASSWORDLESS LOGIN HANDLING - START

  //When have new/changed URL, look for passwordless content
  useEffect(() => {
    if (url) {
      Logger.debug(`Checking URL for passwordless credentials...`)
      const { code, email } = parsePasswordlessLoginFromUrl(url)
      if (code && email) {
        Logger.debug(`Using passwordless credentials...`)
        setLastEmail(email)
        getPasswordlessToken({ email, code })
      }
    }
  }, [url])

  //When get a new token from passwordless login, set the user in state
  useEffect(() => {
    if (passwordlessToken) {
      setUserWithToken(passwordlessToken, 'email', dispatch, undefined, lastEmail)
    }
  }, [passwordlessToken])

  //PASSWORDLESS LOGIN HANDLING - END

  //LINK PARAM HANDLING - START

  //Capture branch params on entry
  useEffect(() => {
    if (!isWeb) {
      branch.subscribe({
        onOpenStart: ({
            uri,
            cachedInitialEvent
        }) => {
            Logger.debug({
              uri,
              cachedInitialEvent,
            }, 'Branch.io subscribe onOpenStart')
        },
        onOpenComplete: ({
            error,
            params,
            uri
        }) => {
            if (error) {
                Logger.error({
                  uri,
                  error,
                }, 'Branch.io subscribe onOpenComplete, Error opening uri')
                // captureEvent({
                //   message: 'Branch.io subscribe onOpenComplete, Error opening url',
                //   extra: {
                //     uri,
                //     error,
                //   }
                // })
            }
            else if (
              params?.affiliateCode //Only affiliateCode; affiliateRef/affiliateClientRef irrelevant without it
              || params?.preferredInviteId
              || params?.preferredSchemeInviteId 
              || params?.preferredSchemeEnrolmentId
              || params?.emailAddress
              || params?.autoLogin
            ) {
              const newLinkParams: LinkParams = pickLinkParams(params)
              const newLoginParams: LoginParams = pickLoginParams(params)
                Logger.debug({ newLinkParams, newLoginParams }, `Capturing new linkParams/loginParams from branch`)
                batch(() => {
                  dispatch(setLinkParams(newLinkParams))
                  dispatch(setLoginParams(newLoginParams))
                })
                navigateToEntryPointForParams({ ...newLinkParams, ...newLoginParams}, 'branch')
                // captureEvent({
                //   message: `Branch.io subscribe onOpenComplete - finished`,
                // })
            }
            return
        },
      })
    }
  }, [])

  //For native - capture link params (if any) when the url changes
  useEffect(()=>{
    //Note this needs !isWeb to prevent issues when web app is embedded (e.g. iframe calculator on other sites)
    //Otherwise this is triggered causing the iframe to "react" and go to the affiliate entry point
    if (url && !isWeb) {
      // captureEvent({
      //   message: 'UseEffect triggered by url change',
      //   extra: {
      //     url,
      //   }
      // })
      Logger.debug({ url }, `Capturing link/login params from initial url...`)
      captureLinkLoginParamsFromUrl(url)
      const parsed = parse(url)
      navigateToEntryPointForParams(parsed.queryParams, 'url')
    }
  },[url])

  //Capture link params from initial URL
  useEffect(()=>{
    const processInitialUrl = async () => {
      const initialUrl = await getInitialURL()
      if (initialUrl) {
        Logger.debug({ initialUrl }, `Capturing link/login params from initial url...`)
        captureLinkLoginParamsFromUrl(initialUrl)
        const parsed = parse(initialUrl)
        navigateToEntryPointForParams(parsed.queryParams, 'initialUrl')
      }
    }
    processInitialUrl()
  },[])
  
  //Load linkParams from secure store on load
  useEffect(() => {
    const loadLinkParamsFromStore = async () => {
      Logger.debug(`Looking for linkParams in store...`)
      const storedLinkParams = await getJsonItemFromStore(LINK_PARAMS_KEY_NAME)
      if (storedLinkParams) {
        Logger.debug({ storedLinkParams }, `Loading linkParams from secure store`)
        const update = {
          ...storedLinkParams,
          preferExisting: true,
        }
        dispatch(setLinkParams(update))
      }
    }
    loadLinkParamsFromStore()
  },[])

  //Clear linkParams and loginParams when created/updated user
  //linkParams will have been stored to the user record
  //loginParams should be cleared for next login cycle
  useEffect(()=>{
    if (newCurrentUser || updatedUser) {
      Logger.debug('Clearing linkParams/loginParams...')
      batch(() => {
        dispatch(setLinkParams(undefined))
        dispatch(setLoginParams(undefined))
      })
      removeJsonItemFromStore(LINK_PARAMS_KEY_NAME)
    }
  },[newCurrentUser, updatedUser])

  //Store linkParams to secure store when they change
  useEffect(()=>{
    const storeLinkParamsInSecureStore = async () => {
      if (linkConfig) {
        Logger.debug({ linkConfig }, `Storing changed link params in secure store`)
        await setJsonItemInStore(LINK_PARAMS_KEY_NAME, linkConfig)
      }
    }
    storeLinkParamsInSecureStore()
  },[linkConfig])

  //Reinstate linkParams (for web, because login is via redirect)
  const onRedirectCallback = (linkConfig: LinkParams) => {
    const newParams: LinkParams = pickLinkParams(linkConfig)
    Logger.debug({ newParams }, `Reinstating linkParams after redirect`)
    dispatch(setLinkParams(newParams))
  }

  const captureLinkLoginParamsFromUrl = url => {
    if (!url) {
      return
    }
    const parsed = parse(url)
    const { queryParams } = parsed || {}
    const { 
      affiliateCode,
      affiliateRef,
      affiliateClientRef,
      preferredInviteId,
      preferredSchemeInviteId,
      preferredSchemeEnrolmentId,
      emailAddress,
      autoLogin,
      authType,
    } = queryParams

    //Merge params with previous (so we don't lose anything)
    const newLinkParams: LinkParams = {
      affiliateCode: firstQueryParam(affiliateCode),
      affiliateRef: firstQueryParam(affiliateRef),
      affiliateClientRef: firstQueryParam(affiliateClientRef),
      preferredInviteId: firstQueryParam(preferredInviteId),
      preferredSchemeInviteId: firstQueryParam(preferredSchemeInviteId),
      preferredSchemeEnrolmentId: firstQueryParam(preferredSchemeEnrolmentId),
    }

    const newLoginParams: LoginParams = {
      emailAddress: firstQueryParam(emailAddress),
      autoLogin: firstQueryParam(autoLogin),
      authType: firstQueryParam(authType) === 'login'
        ? AUTHTYPE.LOGIN
        : firstQueryParam(authType) === 'register'
          ? AUTHTYPE.REGISTER
          : undefined,
    }

    Logger.debug({ newLinkParams }, `Capturing new linkParams from url`)
    Logger.debug({ newLoginParams }, `Capturing new loginParams from url`)
    batch(() => {
      dispatch(setLinkParams(newLinkParams))
      dispatch(setLoginParams(newLoginParams))
    })
  }
  
  //LINK PARAM HANDLING - END

  //USER STATE DETERMINATION - START

  //Determine if user is admin
  const userIsAdmin = userIsAdministrator(authUser)
  
  //Determine if user has group organizations
  const userHasGroupOrganizations = user?.groupOrganizationIds?.length ? true : false

  //Check if user is blocked 
  const userIsBlocked = user?.isBlocked === true

  //Try to create the user if doesn't exist
  useEffect(()=>{
    if (userError && userError?.status === 404 ) {
      Logger.debug({ linkConfig }, `Creating current user...`)
      createCurrentUser({
        ...linkConfig,
    })
    }
  },[userError, linkConfig])

  //Try to update the user if we have changed non-empty linkConfig and a user
  useEffect(()=>{
    if (user && !isEmpty(linkConfig)) {
      Logger.debug({ linkConfig }, `Updating user link params...`)
      updateCurrentUser(linkConfig)
    }
  },[linkConfig, user])

  //USER STATE DETERMINATION - END

  //APP VERSION CHECKS - START

  //Version upgrade detection
  useEffect(()=>{
    Logger.debug(`Checking minimum version required...`)
    if (status) {
      let needsUpgrade = false
      const newVersionRequired = !isWeb && status && lt(Application.nativeApplicationVersion, status?.versionInfo?.minClientVersion)
      if (newVersionRequired) {
        needsUpgrade = newVersionRequired
      }
      Logger.debug({ needsUpgrade }, `Setting needsClientUpgrade`)
      appDispatch(setNeedsClientUpgrade(needsUpgrade))
    }
  },[status])

  //APP VERSION CHECKS - END

  //APP CONTEXT DERIVATION - START

  //On load, look for lastUserContext
  useEffect(()=>{
    const lookForLastUserContext = async () => {
      //Look for token in SecureStore/AsyncStorage
      const lastContext = await getLastUserContext()
      if (lastContext) {
        setLastAppContext(lastContext)
      }
    }
    lookForLastUserContext()
  },[])

  //Derive appContext
  useEffect(()=>{
    const setContext = async () => {
      if (appContext) {
        Logger.debug({ appContext }, `App context already set - ignoring `)
        return
      } 
      if (lastAppContext) {
        const initialUrl = await Linking.getInitialURL()

        const urlAppContext = determineAppContextFromUrl(initialUrl)

        let newContext: AppContext

        //Parsed from URL
        if (urlAppContext) {
          Logger.debug({ urlAppContext }, `Used appContext from initial URL`)
          newContext = urlAppContext
        //Admin without a previous choice
        } else if (userIsAdmin && !lastAppContext) {
          newContext = AppContext.ADMIN
        //Group Org user without a previous choice
        } else if (userHasGroupOrganizations && !lastAppContext) {
          newContext = AppContext.EMPLOYER
        //Use lastAppContext if we have it 
        } else if (lastAppContext) {
          newContext = lastAppContext
        //Else assume main app
        } else {
          newContext = AppContext.CLIENT
        }
        Logger.debug({ newContext }, `Setting appContext.`)
        appDispatch(setAppContext(newContext))
      } else {
        Logger.debug(`Waiting for lastAppContext...`)
      }
    }
    setContext()
  },[authUser, haveAppUser, lastAppContext])

  //APP CONTEXT DERIVATION - END

  //CLIENT HASH EXTRACTION - START

  //Store clientHash when we get it
  useEffect(()=>{
    if (status) {
      appDispatch(setClientHash(status.clientHash))
    }
  },[authUser, status])

  //CLIENT HASH EXTRACTION - END

  //AMPLITUDE REPORTING - START

  //Always identify amplitude user when haveAppUser
  useEffect(()=>{
    if (haveAppUser) {
      identifyAmplitudeUser(user)
    }
  },[haveAppUser])

  //Report login for amplitude when we haveAppUser
  useEffect(()=>{
    if (haveAppUser) {
      ampli.clientLogin({
        authProvider: authUser?.loginMethod,
      })
    }
  },[haveAppUser])
  
  //Report client signup for amplitude when newCurrentUser
  useEffect(()=>{
    if (newCurrentUser) {
      ampli.clientSignup({
        authProvider: authUser?.loginMethod,
        signupDate: newCurrentUser?.createdAt,
        userIsHeld: false, //No longer relevant - beta mode removed
        ...pick(newCurrentUser, [
          'affiliateId',
          'affiliateRef',
        ])
      })
    }
  },[newCurrentUser])

  //AMPLITUDE REPORTING - END

  //INTERCOM - START

  //Login user to Intercom when have user
  if (!isWeb) {
    useEffect(()=>{
      if (haveAppUser) {
        loginToIntercom(user, (count) => appDispatch(setIntercomUnreadMessageCount(count)))
      }
    },[haveAppUser])
  }

  //INTERCOM - END

  //DERIVE APP ENTRY LOCK - START

  useEffect(()=>{
    let entryLock: AppEntryLock
    if (serverUnavailable) {
      entryLock = AppEntryLock.SERVER_OFFLINE
    } else if (statusError) {
      entryLock = AppEntryLock.SERVER_UNREACHABLE
    } else if (!status || statusIsLoading || userIsLoading || !!statusError) {
      entryLock = AppEntryLock.UNDECIDED
    } else if (needsClientUpgrade) {
      entryLock = AppEntryLock.APP_UPGRADE_REQUIRED
    } else if (waitingForBiometrics) {
      entryLock = AppEntryLock.BIOMETRICS
    } else if (!!userError && userError?.status === 404) {
      entryLock = AppEntryLock.USER_NOT_YET_CREATED
    } else if (passwordlessTokenIsLoading) {
      entryLock = AppEntryLock.PASSWORDLESS_TOKEN_LOADING
    } else if (passwordlessTokenError) {
      entryLock = AppEntryLock.PASSWORDLESS_TOKEN_ERROR
    } else if (userIsBlocked) {
      entryLock = AppEntryLock.USER_BLOCKED
    }
    Logger.debug({ entryLock }, `Setting entry lock`)
    setAppEntryLock(entryLock)
  },[
    token,
    serverUnavailable,
    needsClientUpgrade,
    status,
    statusError,
    passwordlessToken,
    passwordlessTokenIsLoading,
    passwordlessTokenError,
    user,
    userError,
    waitingForBiometrics,
  ])

  const navigateToEntryPointForParams = (
    linkLoginParams: LinkParams & LoginParams,
    ref: string
  ) => {
    Logger.debug({
      linkLoginParams,
      ref,
    }, `Checking URL params for entry point navigation...`)

    const {
      affiliateCode,
      preferredInviteId,
      preferredSchemeInviteId,
      preferredSchemeEnrolmentId,
      autoLogin,
    } = linkLoginParams

    let screen = null
    let params = undefined
    if (autoLogin) {
      screen = 'AuthenticationProcessStack'
    } else if (preferredInviteId) {
      screen = `EntryPointPartnerScreen`
      params = { preferredInviteId, user }
    } else if (preferredSchemeInviteId) {
      screen = `EntryPointSchemeInviteScreen`
      params = { preferredSchemeInviteId, user }
    } else if (preferredSchemeEnrolmentId) {
      screen = `EntryPointSchemeEnrolmentScreen`
      params = { preferredSchemeEnrolmentId, user }
    } else if (affiliateCode) {
      screen = `EntryPointAffiliateScreen`
      params = { affiliateCode, user }
    }

    if (screen) {
      Logger.debug({ screen, params }, `Navigating to entry screen`)
      rootNavigate(screen, params)

    } else {
      Logger.debug(`No entry screen detected for URL`)
    }

    return screen
  }

  //DERIVE APP ENTRY LOCK - END

  //DEEP LINK HANDLING - START

  //Get the linking map
  const linkingMap = getLinkingMap()

  //Build the linking config
  const linkingConfig = {
    ...linkingMap,
    async getInitialURL() {
      // First, you may want to do the default deep link handling
      // Check if app was opened from a deep link
      const initialUrl = await Linking.getInitialURL()

      if (initialUrl != null) {
        return initialUrl
      }

      // Handle URL from expo push notifications
      const response = await Notifications.getLastNotificationResponseAsync()

      const initialPushUrl = response?.notification.request.content.data.url

      return initialPushUrl
    },
    subscribe(listener) {
      const onReceiveURL = ({ url }: { url: string }) => {
        let useListener = true
        if (url) {
          const parsed = parse(url)
          const entryPointNavigated = navigateToEntryPointForParams(parsed.queryParams, 'navigation')
          if (!entryPointNavigated) {
            Logger.debug({ url }, `RootStack:navigateFromUrl...`)
            navigateFromUrl(url)
            useListener = false
          }
        }

        listener(url)
      }

      // Listen to incoming links from deep linking
      const eventListenerSubscription = Linking.addEventListener('url', onReceiveURL)

      // Listen to expo push notifications
      const subscription = Notifications.addNotificationResponseReceivedListener(response => {
        const newPushUrl = response.notification.request.content.data.url

        // Let React Navigation handle the URL
        listener(newPushUrl)
      })

      return () => {
        // Clean up the event listeners
        eventListenerSubscription.remove()
        subscription.remove()
      }
    },
  }

  //DEEP LINK HANDLING - END

  // DETERMINE ENTRY POINT - START

  //Re-derive ent
  //Update lastAppContext when appContext changes
  useEffect(()=>{    
    if (appContext) {
      setLastAppContext(appContext)
    }
  },[appContext])

  const deriveInitialRouteName = () => {
    const path = url ? parseRealPathFromUrl(url) : ''
    const routeName =  user ? 'MainAppStack'
      : isWeb && urlPathIsFederated(path) ? 'AuthenticationFederatedLoginScreen'
      : appContext === AppContext.ADMIN ? 'EntryPointAdminScreen'
      : appContext === AppContext.EMPLOYER ? 'EntryPointEmployerScreen'
      : loginConfig?.autoLogin ? 'AuthenticationProcessStack'
      : linkConfig?.preferredInviteId ? 'EntryPointPartnerScreen'
      : linkConfig?.preferredSchemeInviteId ? 'EntryPointSchemeInviteScreen'
      : linkConfig?.preferredSchemeEnrolmentId ? 'EntryPointSchemeEnrolmentScreen'
      : linkConfig?.affiliateCode ? 'EntryPointAffiliateScreen'
      : 'EntryPointDefaultScreen'

    // Logger.debug({ routeName }, '### deriveInitialRouteName')
    return routeName
  }

  const initialRouteName = deriveInitialRouteName()

  // Logger.debug({ linkConfig }, '####### Current link params')

  // DETERMINE ENTRY POINT - END

  return (
    <>    
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={isWeb ? `${appUrl}/federated` : undefined}
        onRedirectCallback={onRedirectCallback}
      >
        <AnalyticsProvider user={user} ready={userHasClient} >
          <Portal.Host>
            {/* <WebHeader /> */}
            {
              //Gate screens shown until we have authenticated user loaded from the backend
              appEntryLock === AppEntryLock.UNDECIDED ? <LoadingScreen messages={getAppLoadingMessages()} />
              : appEntryLock === AppEntryLock.SERVER_UNREACHABLE ? <ServerErrorScreen />
              : appEntryLock === AppEntryLock.SERVER_OFFLINE ? <ServerOfflineScreen />
              : appEntryLock === AppEntryLock.APP_UPGRADE_REQUIRED ? <AppUpgradeScreen />
              : appEntryLock === AppEntryLock.BIOMETRICS ? <BiometricLockScreen biometricsCancelled={biometricsCancelled} doLogout={logoutFromBiometrics} retryBiometrics={getLocalCredentials} />
              : appEntryLock === AppEntryLock.USER_NOT_YET_CREATED ? <LoadingScreen messages={getAppCreateUserMessages() }/>
              : appEntryLock === AppEntryLock.PASSWORDLESS_TOKEN_LOADING ? <LoadingScreen messages={getLoginLoadingMessages()} reset={passwordlessReset} />
              : appEntryLock === AppEntryLock.PASSWORDLESS_TOKEN_ERROR ? <PasswordlessErrorScreen reset={passwordlessReset} />
              : appEntryLock === AppEntryLock.USER_BLOCKED ? <UserBlockedScreen doLogout={doLogout} />
              :
              //Run app with Root Stack...
              <GuidanceProvider ready={userHasClient}>
                <SuggestionsProvider user={user} ready={userHasClient}>
                  <CalendarProvider user={user} ready={userHasClient}>
                    <NavigationContainer
                      documentTitle={{
                        enabled: false,
                      }}
                      ref={rootNavigationRef}
                      linking={linkingConfig}
                      fallback={<LoadingScreen messages={getAppLoadingMessages()} />}
                    >
                      <Stack.Navigator
                        id={'Root'}
                        screenOptions={{
                          headerShown: false,
                          animation: 'slide_from_right',
                        }}
                        initialRouteName={initialRouteName}
                      >
                        {/* Entry screens */}
                        <Stack.Screen name='EntryPointDefaultScreen' component={EntryPointDefaultScreen} />
                        <Stack.Screen name='EntryPointAdminScreen' component={EntryPointAdminScreen} />
                        <Stack.Screen name='EntryPointEmployerScreen' component={EntryPointEmployerScreen} />
                        <Stack.Screen name='EntryPointAffiliateScreen' component={EntryPointAffiliateScreen} initialParams={{ affiliateCode: linkConfig?.affiliateCode }} />
                        <Stack.Screen name='EntryPointPartnerScreen' component={EntryPointPartnerScreen} initialParams={{ preferredInviteId: linkConfig?.preferredInviteId }} />
                        <Stack.Screen name='EntryPointSchemeInviteScreen' component={EntryPointSchemeInviteScreen} initialParams={{ preferredSchemeInviteId: linkConfig?.preferredSchemeInviteId }} />
                        <Stack.Screen name='EntryPointSchemeEnrolmentScreen' component={EntryPointSchemeEnrolmentScreen} initialParams={{ preferredSchemeEnrolmentId: linkConfig?.preferredSchemeEnrolmentId }} />
                        <Stack.Screen name='NewUserCarouselScreen' component={NewUserCarouselScreen} />

                        {/* Authentication */}
                        <Stack.Screen name='AuthenticationProcessStack' component={AuthenticationProcessStack} initialParams={{ appContext, ...loginConfig }} />
                        <Stack.Screen name='AuthenticationFederatedLoginScreen' component={AuthenticationFederatedLoginScreen} />

                        {/* App Contexts */}
                        <Stack.Screen name="AdminAppStack" component={AdminAppStack} />
                        <Stack.Screen name="EmployerAppStack" component={EmployerAppStack} />
                        <Stack.Screen name="MainAppStack" component={MainAppStack} />

                        {/* Onboarding handling */}
                        <Stack.Screen name="OnboardingStatusScreen" component={OnboardingStatusScreen} />
                        <Stack.Screen name="OnboardingPhasePlanHeroScreen" component={OnboardingPhasePlanHeroScreen} />
                        <Stack.Screen name="OnboardingPhaseContributeHeroScreen" component={OnboardingPhaseContributeHeroScreen} />
                        <Stack.Screen name="OnboardingPhaseConsolidateHeroScreen" component={OnboardingPhaseConsolidateHeroScreen} />
                        <Stack.Screen name="OnboardingPhaseFamilyHeroScreen" component={OnboardingPhaseFamilyHeroScreen} />

                        {/* Process Stacks */}
                        <Stack.Screen name="ClientSetupIntroScreen" component={ClientSetupIntroScreen} />
                        <Stack.Screen name="ClientSetupProcessStack" component={ClientSetupProcessStack} />
                        <Stack.Screen name="ClientSetupHeroScreen" component={ClientSetupHeroScreen} />

                        <Stack.Screen name="ClientVerificationIntroScreen" component={ClientVerificationIntroScreen} />
                        <Stack.Screen name="ClientVerificationProcessStack" component={ClientVerificationProcessStack} />
                        <Stack.Screen name="ClientVerificationHeroScreen" component={ClientVerificationHeroScreen} />

                        <Stack.Screen name="InvestmentChoiceIntroScreen" component={InvestmentChoiceIntroScreen} />
                        <Stack.Screen name="InvestmentChoiceProcessStack" component={InvestmentChoiceProcessStack} />
                        <Stack.Screen name="InvestmentChoiceHeroScreen" component={InvestmentChoiceHeroScreen} />

                        <Stack.Screen name="RetirementAssetSetupIntroScreen" component={RetirementAssetSetupIntroScreen} />
                        <Stack.Screen name="RetirementAssetSetupProcessStack" component={RetirementAssetSetupProcessStack} />
                        <Stack.Screen name="RetirementAssetSetupHeroScreen" component={RetirementAssetSetupHeroScreen} />

                        <Stack.Screen name="RetirementProfileSetupIntroScreen" component={RetirementProfileSetupIntroScreen} />
                        <Stack.Screen name="RetirementProfileSetupProcessStack" component={RetirementProfileSetupProcessStack} />
                        <Stack.Screen name="RetirementProfileSetupHeroScreen" component={RetirementProfileSetupHeroScreen} />

                        <Stack.Screen name="RetirementIncomeSetupIntroScreen" component={RetirementIncomeSetupIntroScreen} />
                        <Stack.Screen name="RetirementIncomeSetupProcessStack" component={RetirementIncomeSetupProcessStack} />
                        <Stack.Screen name="RetirementIncomeSetupHeroScreen" component={RetirementIncomeSetupHeroScreen} />

                        <Stack.Screen name="BulkTransferIntroScreen" component={BulkTransferIntroScreen} />
                        <Stack.Screen name="BulkTransferProcessStack" component={BulkTransferProcessStack} />
                        <Stack.Screen name="BulkTransferHeroScreen" component={BulkTransferHeroScreen} />

                        <Stack.Screen name="BulkRetransferIntroScreen" component={BulkRetransferIntroScreen} />
                        <Stack.Screen name="BulkRetransferProcessStack" component={BulkRetransferProcessStack} />
                        <Stack.Screen name="BulkRetransferHeroScreen" component={BulkRetransferHeroScreen} />

                        <Stack.Screen name="BeneficiariesSetupIntroScreen" component={BeneficiariesSetupIntroScreen} />
                        <Stack.Screen name="BeneficiariesSetupProcessStack" component={BeneficiariesSetupProcessStack} />
                        <Stack.Screen name="BeneficiariesSetupHeroScreen" component={BeneficiariesSetupHeroScreen} />

                        <Stack.Screen name="SpouseInviteIntroScreen" component={SpouseInviteIntroScreen} />
                        <Stack.Screen name="SpouseInviteProcessStack" component={SpouseInviteProcessStack} />
                        <Stack.Screen name="SpouseInviteHeroScreen" component={SpouseInviteHeroScreen} />

                        <Stack.Screen name="InviteAcceptIntroScreen" component={InviteAcceptIntroScreen} />
                        <Stack.Screen name="InviteAcceptProcessStack" component={InviteAcceptProcessStack} />
                        <Stack.Screen name="InviteAcceptHeroScreen" component={InviteAcceptHeroScreen} />

                        <Stack.Screen name="SchemeInviteAcceptIntroScreen" component={SchemeInviteAcceptIntroScreen} />
                        <Stack.Screen name="SchemeInviteAcceptProcessStack" component={SchemeInviteAcceptProcessStack} />
                        <Stack.Screen name="SchemeInviteAcceptHeroScreen" component={SchemeInviteAcceptHeroScreen} />

                        <Stack.Screen name="SchemeEnrolmentSetupIntroScreen" component={SchemeEnrolmentSetupIntroScreen} />
                        <Stack.Screen name="SchemeEnrolmentSetupProcessStack" component={SchemeEnrolmentSetupProcessStack} />
                        <Stack.Screen name="SchemeEnrolmentSetupHeroScreen" component={SchemeEnrolmentSetupHeroScreen} />

                        <Stack.Screen name="ContributionsIntroScreen" component={ContributionsIntroScreen} />
                        <Stack.Screen name="ContributionsProcessStack" component={ContributionsProcessStack} />
                        <Stack.Screen name="ContributionsHeroScreen" component={ContributionsHeroScreen} />

                        <Stack.Screen name="CalculatorIntroScreen" component={CalculatorIntroScreen} />
                        <Stack.Screen name="CalculatorProcessStack" component={CalculatorProcessStack} />
                        <Stack.Screen name="CalculatorHeroScreen" component={CalculatorHeroScreen} />

                        {/* Functional area stacks */}
                        <Stack.Screen name="EnrolmentsFunctionalAreaStack" component={EnrolmentsFunctionalAreaStack} />
                        
                        {/* Logout route */}
                        <Stack.Screen name='AuthenticationLogoutScreen' component={AuthenticationLogoutScreen} />
                      </Stack.Navigator>
                    </NavigationContainer>
                    <Snackbar
                      visible={!!snackbar}
                      onDismiss={() => appDispatch(setSnackbarData(undefined))}
                      duration={snackbar?.duration || 500}
                      wrapperStyle={{
                        top: insets.top,
                        paddingHorizontal: Sizing.x10,
                      }}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                      theme={{
                        colors: {
                          onSurface: snackbar?.isError ? Colors.warning.s400 : Colors.brand.grey3,
                          // surface: snackbar?.isError ? Colors.brand.red1 : Colors.brand.purple2, // Not working?
                        }
                      }}
                    >
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}>
                        {
                          snackbar?.iconName
                            ? <View style={{
                                paddingRight: Sizing.x10,
                                flexDirection: 'column',
                                justifyContent: 'center',
                              }}>
                                <MaterialCommunityIcons name={snackbar?.iconName} size={Sizing.x15} color={Colors.neutral.white} />
                              </View>
                            : <></>
                        }
                        <View style={{
                          flexGrow: 1,
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                          {
                            snackbar?.subMessage
                              ? <>
                                  <Subheading style={{ textAlign: 'left', padding: Sizing.x0 }}>{snackbar?.message}</Subheading>
                                  <Paragraph style={{ textAlign: 'left', padding: Sizing.x0 }}>{snackbar?.subMessage}</Paragraph>
                                </>
                              : <Paragraph style={{ textAlign: 'left', padding: Sizing.x0 }}>{snackbar?.message}</Paragraph>
                          }
                        </View>
                        <View style={{
                          paddingRight: Sizing.x10,
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}>
                          <MaterialCommunityIcons name={'close'} size={Sizing.x15} color={Colors.neutral.white} onPress={() => appDispatch(setSnackbarData(undefined))} />
                        </View>
                      </View>
                    </Snackbar>
                  </CalendarProvider>
                </SuggestionsProvider>
              </GuidanceProvider>
            }
          </Portal.Host>
        </AnalyticsProvider>
      </Auth0Provider>
    </>
  )
}