import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminPensionProviderDataId, setCurrentAdminPensionProviderDataId } from 'store/uxSlice'
import { AdminEditPensionProviderScreen } from './Screens/AdminEditPensionProviderScreen'

export const AdminEditPensionProvidersModal = () => {
  const dispatch = useAppDispatch()
  const currentPensionProvider = useAppSelector(currentAdminPensionProviderDataId)
  return (
    <ModalFormBuilder
      visible={!!currentPensionProvider}
      formTitle='Edit Pension Provider Details'
      onDismiss={() => dispatch(setCurrentAdminPensionProviderDataId(undefined))}
      screen={<AdminEditPensionProviderScreen />}
    />
  )

}

