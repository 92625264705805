import { AutoCompleteItem, CURRENT_ENTITY_OPTION_VALUE, CURRENT_SEARCH_TERM_OPTION_VALUE, ManagedAutoCompleteInput, isSpecialValue } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLazyGetEmployersQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { concat } from 'lodash'

export const RetirementAssetsAddAsset_02_Employer = ({ route, navigation }) => {
  const { nextScreen, forTransfer, client }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  //Setup form
  const formObj = useForm<{ employerId: string, employerName: string, employerIsPublicSector: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employerId: workingRetirementAssetData?.employerId,
      employerName: workingRetirementAssetData?.employerName,
      employerIsPublicSector: workingRetirementAssetData?.employerIsPublicSector,
    },
  })
  const { handleSubmit, watch, setValue, formState: { isValid} } = formObj

  const employerId = watch('employerId')
  const employerName = watch('employerName')

  const checkOnSubmit = attributes => {
    if (forTransfer && attributes.employerIsPublicSector) {
      setShowDialog(true)
    } else {
      onSubmit(attributes)
    }
  }

  const onSubmit = attributes => {
    setShowDialog(false)
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      employerId: isSpecialValue(employerId)  ? null : employerId,
    }))
    if (attributes.employerIsPublicSector === null) {
      navigation.navigate(nextScreen)
    } else {
      navigation.navigate('Brand')
    }
  }

  //Build autocomplete options
  const [ getEmployers, { data: employers, error: employersError, isLoading: employersIsLoading, isFetching: employersIsFetching }] = useLazyGetEmployersQuery()
  const [showDialog, setShowDialog] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>(undefined)
  const [realEmployerOptions, setRealEmployerOptions] = useState<AutoCompleteItem[]>([])
  const [dummyEmployerOption, setDummyEmployerOption] = useState<AutoCompleteItem>(undefined)
  const [employerOptions, setEmployerOptions] = useState<AutoCompleteItem[]>([])

  //Trigger getEmployers...
  const getSuggestions = useCallback(async q => {
    setSearchTerm(q)
    const filterToken = q.toLowerCase()
    if (typeof filterToken !== 'string' || filterToken.length < 3) {
      setRealEmployerOptions([])
      return
    }
    getEmployers(filterToken, true) //Get employers, preferring cache
  }, [])

  //Update realEmployerOptions when employers chanegs
  useEffect(() => {
    let options: AutoCompleteItem[] = []
    if (employers) {
      options = employers.map(employer => ({
        value: employer.identifier,
        label: employer.legalName,
        extraData: {
          isPublicSector: employer.isLocalAuthority,
        }
      }))
    }
    if (searchTerm) {
      options.push({
        value: CURRENT_SEARCH_TERM_OPTION_VALUE,
        label: searchTerm,
        description: 'Use this employer name',
      })
    }
    setRealEmployerOptions(options)
  }, [employers])

  //Update dummyEmployerOption when employerId/employerName chanegs
  useEffect(() => {
    let option: AutoCompleteItem
    if (employerName && !employerId) {
      option = {
        value: CURRENT_ENTITY_OPTION_VALUE,
        label: employerName,
        description: 'Current name set by you'
      }
    }
    setDummyEmployerOption(option)

  }, [employerId, employerName])

  //Update employerOptions when real/dummy chaneg
  useEffect(() => {
    const combinedOptions: AutoCompleteItem[] = dummyEmployerOption
      ? concat(dummyEmployerOption, realEmployerOptions)
      : realEmployerOptions
    setEmployerOptions(combinedOptions)
  }, [realEmployerOptions, dummyEmployerOption])
  
  const handleSelect = (item: AutoCompleteItem) => {
    const value = item?.extraData?.isPublicSector === undefined ? null : item?.extraData?.isPublicSector
    setValue('employerIsPublicSector', value)
  }

  return (
    <ModalProcessScreen
      buttonTitle={employerName ? `Next` : `Make a Selection`}
      buttonAction={handleSubmit(checkOnSubmit)}
      enableButton={!!employerName}
      headline={`Who is or was ${client?.isSpouse ? `their` : `your`} employer?`}
    >
      <ManagedAutoCompleteInput
        name={'employerId'}
        placeholder={'Tap to search...'}
        searchPlaceholder={'Enter 3 or more characters...'}
        allowFreeText={true}
        textCaptureFieldName={'employerName'}
        unknownItemUseDescription={'Use this employer name'}
        unknownItemSelectedDescription={'Employer name specified by you'}
        formObj={formObj}
        modalTitle={'Find/Enter Employer'}
        required={true}
        dataSet={employerOptions}
        dataTriggerFunction={getSuggestions}
        selectTriggerFunction={handleSelect}
        isLoading={employersIsLoading || employersIsFetching}
        minSearchTermLength={3}
        searchTermRequiredMessage='Tap above to search for your employer'
        noResultsMessage={'No matching employers found'}
        />
        <ConfirmationDialog
          visible={showDialog}
          onCancel={() => setShowDialog(false)}
          title={`We're sorry`}
          content={`Our records indicate that '${employerName}' is a public sector employer.\n\nWe cannot currently accept transfers of pensions from the public sector as you may lose out on important benefits. You may still add this pension, but it will not be available for transfer.`}
          onConfirm={handleSubmit(onSubmit)}
        />
    </ModalProcessScreen>
  )
}
