import { ProcessScreen } from 'components/Layout'
import { Headline, Paragraph } from 'components/Typography'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'
import { composeIntercomMessage } from 'lib/intercomHelpers'
import { Logger } from 'lib/logger'
import { goToDashboard, rootNavigate } from 'lib/RootNavigation'
import React, { useEffect } from 'react'
import { Image } from 'react-native'
import { useUpdateMeMutation, useVerifyGroupSchemeEnrolmentMutation } from 'store/apiSlice'
import { GroupSchemeEnrolmentDto, PublicPendingSchemeEnrolmentDto, VerifySchemeEnrolmentDto } from 'store/dto/account.dto'
import { AmlStatus, ClientMeDto } from 'store/dto/client.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingSetupSchemeEnrolment, workingSetupSchemeEnrolment } from 'store/tempDataSlice'
import { Paper, Sizing } from 'styles'

export const SchemeEnrolmentSetup_99_Result = ({ route, navigation }) => {
  const { nextRoute, enrolment, client }: { nextRoute: string, enrolment: PublicPendingSchemeEnrolmentDto | GroupSchemeEnrolmentDto, client: ClientMeDto }  = route?.params || {}

  const verifiedNationalInsuranceNo = client?.amlStatus === AmlStatus.PASS ? client?.nationalInsuranceNo : undefined

  const dispatch = useAppDispatch()

  const { colors: themeColors } = Paper.useAppTheme()

  const workingSetupSchemeEnrolmentData = useAppSelector(workingSetupSchemeEnrolment)

  const [ verifyEnrolment, { data: verifiedEnrolment, isLoading: verifyEnrolmentIsLoading, error: verifyEnrolmentError} ] = useVerifyGroupSchemeEnrolmentMutation()
  const [updateClient, { data: updatedClient, error: clientUpdateError, isLoading: clientUpdateIsLoading }] = useUpdateMeMutation()

  const isLoading = verifyEnrolmentIsLoading || clientUpdateIsLoading
  const error: any = verifyEnrolmentError || clientUpdateError

  const wrongNino = verifyEnrolmentError && verifyEnrolmentError?.status === 403

  //Save client onboardingFlags on enter
  useEffect(() => {
    if (workingSetupSchemeEnrolmentData) {
      saveClient()
    }
  }, [])

  const saveClient = () => {
    Logger.info(`Updating Client...`)
    updateClient({
      onboardingFlags: {
        setupscheme: true,
      },
    })
  }
  
  //Verify enrolment when client saved
  useEffect(() => {
    if (updatedClient) {
      verify()
    }
  }, [updatedClient])

  const verify = () => {
    Logger.info(`Verifying enrolment...`)
    const payload: VerifySchemeEnrolmentDto = {
      id: enrolment?.id,
      ...workingSetupSchemeEnrolmentData,
      nationalInsuranceNo: workingSetupSchemeEnrolmentData?.nationalInsuranceNo,
    }
    Logger.debug({ payload })
    verifyEnrolment(payload)
  }

  //End verified
  useEffect(() => {
    if (verifiedEnrolment) {
      end()
    }
  }, [verifiedEnrolment])

  const end = () => {
    const { requests } = workingSetupSchemeEnrolmentData || {}

    //Clear temp data
    dispatch(updateWorkingSetupSchemeEnrolment(undefined))
    rootNavigate(nextRoute, { enrolment, requests })
  }

  const cancel = () => {
    //Clear temp data
    dispatch(updateWorkingSetupSchemeEnrolment(undefined))
    goToDashboard()
  }

  const goBack = () => {
    navigation.goBack()
  }

  return (
    <ProcessScreen
      headline={wrongNino ? `Sorry, we couldn't verify you` : ''}
      subHeading={wrongNino
        ? verifiedNationalInsuranceNo
          ? `The National Insurance Number associated with your Jarvis account doesn't match`
          : `The National Insurance Number you entered doesn't match`
        : ''
      }
      buttonTitle={wrongNino
        ? verifiedNationalInsuranceNo
          ? `Cancel`
          : `Go back and change it`
        : ''
      }
      buttonAction={verifiedNationalInsuranceNo ? cancel : goBack}
      showButton={!isLoading && wrongNino}
      error={error && !wrongNino}
      errorTryAgain={verifyEnrolmentError ? verify : saveClient}
      isLoading={isLoading}
      loadingMessage={['Linking employer...']}
      allowTextButton={!isLoading && wrongNino}
      textButtonAction={verifiedNationalInsuranceNo ? () => composeIntercomMessage('') : cancel}
      textButtonTitle={verifiedNationalInsuranceNo ? 'Contact Support ' : 'Cancel for now'}
    >
      {
        wrongNino ?
        <>
        <AppIllustration filename={'magnifying_glass.png'} style={[
          {
            width: Sizing.x100,
            height: Sizing.x100,
            resizeMode: 'contain',
            alignSelf: 'center'
          },
        ]} />
        <Paragraph>{`Your employer's record has a differnet National Insurance Number.`}</Paragraph>
        <Paragraph>{verifiedNationalInsuranceNo
          ? `Your National Insurance Number on our records:`
          : `National Insurance Number your entered:`
        }</Paragraph>
        <Headline style={{ color: themeColors.accent }}>{formatNationalInsuranceNumber(workingSetupSchemeEnrolmentData?.nationalInsuranceNo)}</Headline>
        <Paragraph>{`Please double-check - if yours is correct, you may need to speak to your employer to check their records.`}</Paragraph>
      </>
          : <></>
      }
    </ProcessScreen>
  )
}
