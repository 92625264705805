import { Loading } from 'components/Utility/Loading'
import { Logger } from 'lib/logger'
import { round, sum } from 'lodash'
import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { ampli } from 'src/ampli'
import { useUpdateBeneficiariesMutation } from 'store/apiSlice'
import { Nomination } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { editBeneficiaryData, setEditBeneficiaryData } from 'store/uxSlice'
import { EditBeneficiaryEditScreen } from './Screens/EditBeneficiaryEditScreen'

export type EditBeneficiaryEditModalProps = {
}

const EditBeneficiaryEditModal = (props: EditBeneficiaryEditModalProps) => {
  const dispatch = useAppDispatch()
  const editBeneficiary = useAppSelector(editBeneficiaryData)

  const { allNominations, editIndex } = editBeneficiary || {}

  const [updateBeneficiaries, { isLoading }] = useUpdateBeneficiariesMutation()

  const handleDismissScreen = () => {
    dispatch(setEditBeneficiaryData(undefined))
  }

  const handleDelete = async () => {
    const otherNominations = allNominations && allNominations?.length > 1 ? allNominations?.filter(
      (data: Nomination, index: number) => editIndex !== index
    ) : []
    const deleteNomination = allNominations && allNominations.length >= editIndex + 1 ? allNominations[editIndex] : undefined

    //Return if don't have something to delete
    if (!deleteNomination) {
      Logger.error(`Could not find deleteNomination`)
      return
    }

    const ratio = 1 / (1 - deleteNomination.beneficiaryProportion)

    const newProportions = []

    otherNominations.forEach((nomination, idx) => {
      if (idx < otherNominations.length - 1) {
        const newProportion = round(nomination.beneficiaryProportion * ratio, 2)
        newProportions.push(newProportion)
      }
    })
    const newLastProportion = round(1 - sum(newProportions),2)
    newProportions.push(newLastProportion)

    const newBeneficiaryData = otherNominations.map((nomination, idx) => {
      return {
        ...nomination,
        beneficiaryProportion: newProportions[idx]
      }
    })

    await updateBeneficiaries({
      nominations: newBeneficiaryData,
    })

    ampli.beneficiariesChange({
      beneficiaryCount: newBeneficiaryData ? newBeneficiaryData.length : 0,
      beneficiaryRelationships: newBeneficiaryData ? newBeneficiaryData.map(nomination => {
        return nomination.beneficiaryRelationship
      }) : [],
      changeReason: 'delete',
    })
    dispatch(setEditBeneficiaryData(undefined))
  }
  return (
    <ModalFormBuilder
      visible={!!editBeneficiary}
      formTitle="Update Beneficiary"
      onDismiss={() => handleDismissScreen()}
      onDelete={handleDelete}
      deleteDialogText={`We will automatically adjust the allocated proportions for any other beneficiaries`}
      screen={<>{isLoading ? <Loading /> : <EditBeneficiaryEditScreen />}</>}
    />
  )
}

export default EditBeneficiaryEditModal
