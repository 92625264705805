import { ManagedCurrencyInput } from 'components/Inputs/ManagedCurrencyInput'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { differenceInYears } from 'date-fns'
import { MAX_INCOME_ANNUAL_AMOUNT, MAX_TARGET_RETIREMENT_AGE } from 'lib/constants'
import { Logger } from 'lib/logger'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useUpdateRetirementIncomeMutation } from 'store/apiSlice'
import { ClientMeDto, SpouseDto } from 'store/dto/client.dto'
import { RetirementIncomeDto, RetirementIncomeType } from 'store/dto/retirement-income.dto'
import { RetirementProfileDto } from 'store/dto/retirement-profile.dto'
import { pick } from 'lodash'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { deriveMinimumTargetRetirementAge } from 'lib/clientHelpers'

interface RetirementIncomesEditAssetScreenProps {
  income: RetirementIncomeDto
  onDismiss: any
  disabled: boolean
  me: ClientMeDto
  spouse: SpouseDto
  retirementProfile: RetirementProfileDto
}

export const RetirementIncomesEditIncomeScreen = (props: RetirementIncomesEditAssetScreenProps) => {
  const { onDismiss, disabled, me, spouse, retirementProfile, income } = props

  const [ updateIncome, { data: updatedIncome, error: updateIncomeError, isLoading: updateIncomeIsLoading } ] = useUpdateRetirementIncomeMutation()

  const belongsToSpouse = spouse && income?.clientId === spouse?.id

  const targetMeAge = retirementProfile?.targetRetirementAge || me.statePensionAge
  //Age to show
  const ageDifference = belongsToSpouse ? differenceInYears(new Date(me.birthDate), new Date(spouse?.birthDate)) : 0
  const relevantAge = targetMeAge + ageDifference

  //Form refs for focussing
  const nameRef = useRef()
  // const incomeTypeRef = useRef()
  const annualIncomeAmountRef = useRef()
  const incomeStartAgeRef = useRef()

  //Setup form
  const formObj = useForm<{
    name: string
    incomeType: RetirementIncomeType
    annualIncomeAmount: number
    incomeStartAge: number,
    age: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: income?.name,
      incomeType: income?.incomeType,
      annualIncomeAmount: income?.annualIncomeAmount,
      incomeStartAge: income?.incomeStartAge || retirementProfile.targetRetirementAge,
      age: income?.incomeStartAge ? 'no' : 'yes'
    },
  })
  const { handleSubmit, trigger, formState: { isDirty, isValid},watch } = formObj
  
  const onSubmit = attributes => {
    Logger.info(`Updating income...`)
    const { incomeStartAge, ...remaining } = attributes
    const payload = {
      id: income.id,
      ...remaining,
      incomeStartAge: ageWatch === 'yes' ? null : incomeStartAge
    }
    updateIncome(payload)
  }

  //Close on save result
  useEffect(() => {
    if (updatedIncome) {
      ampli.retirementIncomeUpdate({
        ...pick(updatedIncome, [
          'incomeType',
          'annualIncomeAmount',
          'incomeStartDate',
          'incomeStartAge',
        ]),
        isSpouse: belongsToSpouse,
      })
      onDismiss()
    }
  }, [updatedIncome])

  const ageWatch=watch('age')
  const ageOptions: ManagedSimpleChoiceItem[] = [
    {
      value: 'yes',
      label: `Yes`
    },
    {
      value: 'no',
      label: 'No'
    }
  ]

  useEffect(() => {
    if (ageWatch) {
      trigger('age')
    }
  }, [ageWatch])

  const minTargetRetirementAge = deriveMinimumTargetRetirementAge(me)

  return (
    <ModalProcessScreen
      isLoading={updateIncomeIsLoading}
      loadingMessage={['Saving income...']}
      error={updateIncomeError}
      errorTryAgain={handleSubmit(onSubmit)}
      buttonTitle={disabled ? 'Close' : 'Save'}
      buttonAction={disabled ? onDismiss : handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid && !updateIncomeIsLoading}
    >
      {
        disabled
        ? <Paragraph>{`This information is read only because you have linked your account with ${spouse?.firstName}.`}</Paragraph>
        : <></>
      }
      <ManagedAutoCompleteInput
        name={'incomeType'}
        formObj={formObj}
        label={'Income Type'}
        selectOnlyMode={true}
        required={true}
        disabled={true}
        dataSet={enumToAutocompleteOptions(RetirementIncomeType)}
      />
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Enter a name for this income'}
        blurOnSubmit={false}
        disabled={disabled}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      <ManagedCurrencyInput
        ref={annualIncomeAmountRef}
        name={'annualIncomeAmount'}
        formObj={formObj}
        label={'Annual Income Amount'}
        placeholder={'The amount received each year'}
        required={true}
        minValue={0}
        maxValue={MAX_INCOME_ANNUAL_AMOUNT}
        blurOnSubmit={false}
        disabled={disabled}
      />
      {
        income?.incomeType === RetirementIncomeType.OTHER
        ? <>
            <Paragraph>{`Will the income have started when ${belongsToSpouse ? 'they' : 'you'} retire?`}</Paragraph>
            <ManagedSimpleChoiceInput
              name={'age'}
              formObj={formObj}
              options={ageOptions}
              required={true}
              disabled={disabled}
            />
          </>
        : <></>
      }
     {
      [RetirementIncomeType.OTHER, RetirementIncomeType.DEFINED_BENEFIT].includes(income?.incomeType)
      && ageWatch === 'no'
      ? <>
          <Paragraph>{'Age when income starts'}</Paragraph>
            <ManagedIntegerInput
              ref={incomeStartAgeRef}
              name={'incomeStartAge'}
              formObj={formObj}
              label={'Starting Age'}
              placeholder={'Enter age at which this income will start'}
              blurOnSubmit={false}
              minValue={minTargetRetirementAge + ageDifference}
              maxValue={MAX_TARGET_RETIREMENT_AGE + ageDifference}
              disabled={disabled}
            />
          </>
      : <></>
      }
    </ModalProcessScreen>
  )
}