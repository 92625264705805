import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getGroupSchemePaymentListInfo } from 'lib/groupSchemePaymentHelpers'
import React from 'react'
import { GroupSchemePaymentCollectionStatus, GroupSchemePaymentDto, GroupSchemePaymentStatus } from 'store/dto/group-scheme.dto'
import { Paper, Sizing } from 'styles'

type EmployerPaymentListItemProp = {
  payment: GroupSchemePaymentDto
  onPressFunction?: () => void
  canAdminister: boolean
  canExecutePayments: boolean
}
export const EmployerPaymentListItem = (props: EmployerPaymentListItemProp) => {
  const { payment, onPressFunction, canAdminister, canExecutePayments } = props
  const { collectionStatus } = payment

  const { colors: themeColors } = Paper.useAppTheme()

  const { iconName, reference, amountString, status, statusMessage } = getGroupSchemePaymentListInfo(payment, canAdminister, canExecutePayments)

  const errorStyle = {
    color:
      status === GroupSchemePaymentStatus.PAID && (
        collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_INSUFFICIENT_FUNDS
        || collectionStatus === GroupSchemePaymentCollectionStatus.FAILED_NOT_AUTHORISED)
      ? themeColors.error
      : themeColors.primary
  }

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={reference}
      titleRight={amountString}
      titleRightStyle={{ color: themeColors.primary }}
      subTitle={status}
      subTitleStyle={errorStyle}
      titleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{statusMessage}</Text>}
      subTitleRightStyle={errorStyle}
    />
  )
}
