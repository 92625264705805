import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupOrganizationDataId, setCurrentAdminGroupOrganizationDataId } from 'store/uxSlice'
import { AdminEditGroupOrganizationScreen } from './Screens/AdminEditGroupOrganizationScreen'

export const AdminEditGroupOrganizationsModal = () => {
  const dispatch = useAppDispatch()
  const currentGroupOrganization = useAppSelector(currentAdminGroupOrganizationDataId)
  return (
    <ModalFormBuilder
      visible={!!currentGroupOrganization}
      formTitle='Edit Group Organization Details'
      onDismiss={() => dispatch(setCurrentAdminGroupOrganizationDataId(undefined))}
      screen={<AdminEditGroupOrganizationScreen />}
    />
  )

}

