import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { getScreenAppHeight, scaleNormalizer } from 'lib/scaleHelpers'
import { OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { ScrollView, View } from 'react-native'
import { useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { onIncomeSpouseView, setAddRetirementIncomeClient, setAddSpouseVisible, setAddStatePensionClient, setEditRetirementIncome, setEditStatePensionClient } from 'store/uxSlice'
import { Sizing } from 'styles'
import { RetirementIncomesList } from '../Components/RetirementIncomesList'

export const RetirementIncomesScreen = () => {
  const dispatch = useAppDispatch()

  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { error: incomesError, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

  const isLoading = spouseIsLoading
  const error: any = incomesError

  const onSpouseTab = useAppSelector(onIncomeSpouseView)
  
  const appScreenHeight = getScreenAppHeight()
  const navigatorHeight = appScreenHeight - scaleNormalizer(250)

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchSpouse} /> :
        <OnboardingGuard
          explanation={'Your Retirement Incomes screen will help you keep track of the ongoing incomes you will receive in retirement'}
          subExplanation={`You can record incomes once you have defined your retirement lifestyle and budget.`}
          onboardingSteps={[
            OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
            OnboardingStateStepIdentifier.INCOMES,
          ]}
        >
          <>
            <ScrollView style={{paddingHorizontal: Sizing.x30}}>
              <View style={{paddingTop: Sizing.x30}}>
                <Subheading>Keep track of the ongoing income that you will still receive during retirement</Subheading>
              </View>
                <RetirementIncomesList
                  addIncomeStateFunction={(client: ClientBaseDto) => dispatch(setAddRetirementIncomeClient(client))}
                  editIncomeStateFunction={(income: RetirementIncomeDto) => dispatch(setEditRetirementIncome(income))}
                  addStatePensionStateFunction={(client: ClientBaseDto) => dispatch(setAddStatePensionClient(client))}
                  editStatePensionStateFunction={(client: ClientBaseDto) => dispatch(setEditStatePensionClient(client))}
                  addSpouseStateFunction={(visible: boolean) => dispatch(setAddSpouseVisible(visible))}
                  navigatorHeight={navigatorHeight}
                  refreshFunctions={[refetchIncomes]}
                />
            </ScrollView>
            {
              onSpouseTab && !spouse ?
              <FooterButton
                onPress={() => dispatch(setAddSpouseVisible(true))}
              >{`Add Partner`}
              </FooterButton>
              : <></>
            }
        </>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}
