import { Logger } from 'lib/logger'
import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useGetStateBenefitQuery, useUpdateMeMutation, useUpdateSpouseMutation } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { setEditStatePensionClient } from 'store/uxSlice'
import { RetirementIncomesEditStatePensionScreen } from './Screens/RetirementIncomesEditStatePensionScreen'

export type RetirementIncomesEditStatePensionModalProps = {
  client: ClientBaseDto
  onDismiss?: any
}

export const RetirementIncomesEditStatePensionModal = (props: RetirementIncomesEditStatePensionModalProps) => {
  const { client } = props

  const dispatch = useAppDispatch()
  const globalDismiss = () => dispatch(setEditStatePensionClient(undefined))

  const visible = !!client
  const onDismiss = props.onDismiss || globalDismiss
  
  const [ updateMe ] = useUpdateMeMutation()
  const [ updateSpouse ] = useUpdateSpouseMutation()

  const handleDelete = () => {
    const payload = {
      statePensionAmount: 0
    }
    Logger.info(`Deleting state pension...`)
    if (client?.isSpouse) {
      Logger.info(`Updating spouse...`)
      updateSpouse(payload)
    } else {
      Logger.info(`Updating client...`)
      updateMe(payload)
    }
    onDismiss()
  }

  const { data: stateBenefit, error, isLoading } = useGetStateBenefitQuery(
    {
      birthDate: client?.birthDate,
      gender: client?.gender
    },
    {skip: !client}
  )

  //Disabled if spouse with a real user
  const disabled = client?.isSpouse && !!client?.userId

  return (
    <ModalFormBuilder
      isLoading={isLoading}
      error={error}
      visible={visible}
      formTitle='Update State Pension'
      onDismiss={onDismiss}
      onDelete={disabled ? undefined : handleDelete}
      deleteDialogText='Really delete state pension?'
      screen={<RetirementIncomesEditStatePensionScreen
        client={client}
        onDismiss={onDismiss}
        disabled={disabled}
        stateBenefit={stateBenefit}

      />}
    />
  )
}
