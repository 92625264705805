import { MaterialCommunityIcons } from '@expo/vector-icons'
import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { ProcessHeaderTitle } from 'components/Typography/ProcessHeaderTitle'
import { platformIsWeb } from 'lib/platformHelpers'
import { getProcessProgress, ProcessProgress } from 'lib/navigationHelpers'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useRef, useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { Modal, ProgressBar } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useAppSelector } from 'store/hooks'
import { headerEnabled } from 'store/uxSlice'
import { Colors, Paper, Flex, Sizing } from 'styles'
import { getScreenWidthConstraints } from 'lib/scaleHelpers'

export type ProcessHeaderProps = {
  navigationRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
  sectionName: string
  previousRoute: string
  removeProgressBar?:boolean
  routeParams: any
}

export const ProcessHeader = (props: ProcessHeaderProps) => {
  const { navigationRef, sectionName, previousRoute, removeProgressBar, routeParams } = props
  
  const [ processProgress, setProcessProgress ] = useState<ProcessProgress>()

  //Update progress when state changes
  navigationRef.addListener('state', (e) => {
    const currentRouteState = navigationRef.getRootState()
    setProcessProgress(getProcessProgress(currentRouteState, true))
  })

  const goBack = () => {
    if (navigationRef.canGoBack()) {
      navigationRef.goBack()
    } else {
      rootNavigate(previousRoute, routeParams)
    }
  }

  const isWeb = platformIsWeb()
  const { colors: themeColors } = Paper.useAppTheme()

  const visible = processProgress && !processProgress.isLast
  const headerActive = useAppSelector(headerEnabled)
  const jarvisLogo = isWeb ? require('assets/brand/jarvis-logo-blk-4x.png') : require('assets/brand/jarvis-logo-wht-4x.png')

  const insets = useSafeAreaInsets()

  const viewConstraints: any = getScreenWidthConstraints()
  
  return (
    <>
      <View style={[{
          ...localStyles.processHeaderContainer,
          backgroundColor: isWeb ? Colors.brand.grey4 : Colors.gradient.grad_purple3,
          paddingTop: insets.top
        },
        viewConstraints
      ]}>
        <View style={localStyles.headerRow}>
          <View style={localStyles.headerIconContainer}>
            { visible ?
              <MaterialCommunityIcons name="chevron-left" size={Sizing.x40} color={themeColors.primary} onPress={goBack} />
              : <></>
            }
          </View>
          <View style={localStyles.headerTitleContainer}>
            { visible ?
              <>
                {
                  removeProgressBar ?
                  <View>
                    <Image source={jarvisLogo} style={{
                      width: Sizing.x120,
                      height: Sizing.x30,
                      alignSelf: 'center',
                    }}
                    resizeMode={'contain'}
                    />
                </View>
                :
                  <ProcessHeaderTitle>{sectionName}</ProcessHeaderTitle>}
              </>
              : <></>
            }
          </View>
          <View style={localStyles.headerIconContainer}>
          </View>
        </View>

        <View style={localStyles.progressBarContainer}>

          { visible ?
            <>
              {
                removeProgressBar ? <></> :
                <ProgressBar
                  color={themeColors.progressBar}
                  style={[
                    localStyles.headerProgressBar,
                    {
                      backgroundColor: themeColors.progressBarBackground,
                    }
                  ]}
                  progress={processProgress?.progress}
                />
              }
            </>
            : <></>
          }
        </View>
      </View>
      <Modal visible={!headerActive} dismissable={false}><></></Modal>
    </>
  )
}

const localStyles = StyleSheet.create({
  processHeaderContainer: {
    ...Flex.column.center,
    height: Sizing.x120,
    paddingTop: Sizing.x10,
  },
  headerRow: {
    alignContent: 'center',
    ...Flex.row.between,
    paddingHorizontal: Sizing.x10,
  },
  headerIconContainer: {
    height: Sizing.x40,
    width: Sizing.x50,
    alignItems: 'center'
  },
  headerTitleContainer: {
    alignSelf: 'center',
    // flex: 1,
    paddingHorizontal: Sizing.x20,
  },
  headerProgressBar: {
    marginTop: Sizing.x10,
    height: Sizing.x10,
    borderRadius: Sizing.x60,
  },
  progressBarContainer: {
    alignSelf: 'center',
    width: '40%',
  }
})