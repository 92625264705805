import { ModalProcessScreen } from 'components/Layout'
import { concat, round } from 'lodash'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingAssetAllocations, workingAssetAllocations } from 'store/tempDataSlice'

export const InvestmentAssetAdd_99_Result = ({ route, navigation }) => {
  const { onDismiss, onFinish } = route?.params || {}
  const dispatch = useAppDispatch()
  const workingAssetAllocationsData = useAppSelector(workingAssetAllocations)
  const { existingAllocations, newAllocation, evenSplit } = workingAssetAllocationsData || {}

  const close = () => {
    dispatch(updateWorkingAssetAllocations(undefined))
    onDismiss()
  }

  useEffect(() => {
    let newAllocationSet = concat(existingAllocations || [], newAllocation)

    //Handle even split
    if (evenSplit) {
      const newCount = existingAllocations?.length + 1
      const newProportion = round(1 / newCount, 4)
      const updatedAllocations = existingAllocations.map(allocation => {
        return {
          ...allocation,
          proportion: newProportion
        }
      })
      //Update new allocation
      const newAssetProportion = round(1 - (newProportion * (newCount - 1)), 4)

      newAllocationSet = concat(updatedAllocations || [], {
        ...newAllocation,
        proportion: newAssetProportion,
      })
    }


    onFinish(newAllocationSet)
    dispatch(updateWorkingAssetAllocations(undefined))
  }, [])

  return (
    <ModalProcessScreen
      buttonTitle={''}
      buttonAction={close}
      showButton={false}
      headline={``}
    >
    </ModalProcessScreen>
  )
}
