import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { ActionCardModal } from 'features/Dashboard/Components/ActionCardModal'
import { InvestmentAssetAddProcessStack } from 'features/ModalProcesses/InvestmentAssetAdd/InvestmentAssetAddProcessStack'
import InvestmentAssetAdjustAllocationModal from 'features/ModalProcesses/InvestmentAssetAdjustAllocation/InvestmentAssetAdjustAllocationModal'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { concat, isArray, round, sum } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Portal } from 'react-native-paper'
import { useGetAssetsQuery, useGetMeQuery } from 'store/apiSlice'
import { AssetDto } from 'store/dto/asset.dto'
import { AssetAllocationDto } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { InvestmentTypeChoice, updateWorkingAccount, workingAccount } from 'store/tempDataSlice'
import Row from '../Components/Row'
import { InvestmentAssetModalContent } from 'components/Utility/InvestmentAssetModalContent'
import { Typography } from 'styles'
import { Logger } from 'lib/logger'
import { INVESTMENT_CHOICE_MAX_FUND_COUNT } from 'lib/constants'

export const InvestmentChoice_13_AssetAllocation = ({ route, navigation }) => {
  const { contextAccount }  = route?.params || {}

  const dispatch = useAppDispatch()
  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, isFetching: assetsIsFetching, refetch: refetchAssets } = useGetAssetsQuery()

  const isLoading = clientIsLoading || assetsIsLoading
  const error: any = clientError || assetsError

  const workingAccountData = useAppSelector(workingAccount)

  const assetAllocation = workingAccountData?.assetAllocation || []

  const existingInvestmentTypeChoice = 
    contextAccount?.groupPortfolioId
      ? InvestmentTypeChoice.GROUP_PORTFOLIO
      : contextAccount?.investmentPlanId
          ? InvestmentTypeChoice.PLAN
          : contextAccount?.assetAllocation
            ? InvestmentTypeChoice.ASSET_ALLOCATION
            : undefined

  //Use existing pension assetAllocation if available 
  useEffect(() => {
    if (assets && contextAccount) {
      dispatch(updateWorkingAccount({
        assetAllocation: contextAccount?.assetAllocation
      }))
    }
  }, [assets])
  
  const investmentAssetRows = assetAllocation ? assetAllocation.map((allocation: AssetAllocationDto, id: number) => {
    const asset = assets ? assets.find(asset => {
      return allocation.assetId === asset.id
    }) : undefined

    return (
      asset ?
        <Row
          pressable={true}
          key={id}
          onPressFunction={() => setShowAssetDetails(asset)}
          mainText={asset?.shortName}
          rightText={formatPercentageAmount(round(allocation.proportion * 100, 2), 2)}
        />
        : <></>
    )
  }) : []

  const [showAssetDetails, setShowAssetDetails] = useState<AssetDto>(undefined)
  const [addInvestmentAssetVisible, setAddInvestmentAssetVisible] = useState<boolean>(false)
  const [adjustProportionsVisible, setAdjustProportionsVisible] = useState<boolean>(false)

  const goToPlanChoice = () => {
    navigation.navigate('PlanChoice')
  }

  const onSubmit = () => {
    //Navigate to Opt Out for new pension, else Terms
    navigation.navigate(contextAccount ? 'Terms' : 'OptOut')
  }

  const handleNewAssetAllocation = (assetAllocation: AssetAllocationDto[]) => {
    dispatch(updateWorkingAccount({
      assetAllocation
    }))
    setAddInvestmentAssetVisible(false)
  }

  const handleDeleteAsset = (asset: AssetDto) => {
    const { id } = asset
    const otherAllocations = assetAllocation.filter(allocation => {
      return allocation.assetId !== id
    })
    const deleteAllocation = assetAllocation.find(allocation => {
      return allocation.assetId === id
    })

    if (!deleteAllocation) {
      Logger.error(`Could not find toDelete`)
      return
    }

    const ratio = 1 / (1 - deleteAllocation.proportion)
    const newAllocations = []

    otherAllocations.forEach((allocation, idx) => {
      if (idx < otherAllocations.length - 1) {
        const newAllocation = round(allocation.proportion * ratio, 2)
        newAllocations.push(newAllocation)
      }
    })
    const newLastAllocation = round(1 - sum(newAllocations),2)
    newAllocations.push(newLastAllocation)

    const newAssetAllocations = otherAllocations.map((allocation, idx) => {
      return {
        ...allocation,
        proportion: newAllocations[idx]
      }
    })

    //TODO - handle proportions
    dispatch(updateWorkingAccount({
      assetAllocation: newAssetAllocations
    }))
    setShowAssetDetails(undefined)
  }

  const handleAdjustAllocations = (assetAllocation: AssetAllocationDto[]) => {
    dispatch(updateWorkingAccount({
      assetAllocation
    }))
    setAdjustProportionsVisible(false)
  }

  const hasAssets = isArray(assetAllocation) && assetAllocation.length > 0
  const hasMultipleAssets = isArray(assetAllocation) && assetAllocation.length > 1
    
  return (
    <>
      {
        addInvestmentAssetVisible ?
        <InvestmentAssetAddProcessStack
          accountDescriptor={workingAccountData?.accountDescriptor}
          currentAssetAllocation={assetAllocation}
          onDismiss={() => setAddInvestmentAssetVisible(false)}
          onFinish={handleNewAssetAllocation}
        />
        : adjustProportionsVisible ?
        <InvestmentAssetAdjustAllocationModal
          assetAllocation={assetAllocation}
          onDismiss={() => setAdjustProportionsVisible(false)}
            onAdjustFunction={handleAdjustAllocations}
          />
          :
          <ProcessScreen
          isLoading={isLoading}
          error={error}
          errorTryAgain={clientError ? refetchClient : refetchAssets}
          buttonTitle={'Next'}
          buttonAction={onSubmit}
          enableButton={!isLoading && !!hasAssets}
          showButton={true}
          allowTextButton={!isLoading && hasMultipleAssets}
          textButtonTitle={hasMultipleAssets
            ? 'Adjust allocations'
            : hasAssets
              ? ''
              : 'Pick a plan instead'}
          textButtonAction={
            hasMultipleAssets
              ? () => setAdjustProportionsVisible(true)
              : hasAssets
                ? undefined
                : goToPlanChoice
            }
          headline={existingInvestmentTypeChoice === InvestmentTypeChoice.ASSET_ALLOCATION
            ? `Make changes to your portfolio`
            : `Create your own portfolio`}
          subHeading={`You can choose up to ${INVESTMENT_CHOICE_MAX_FUND_COUNT} funds in which to invest your pension`}
        >
          {
            investmentAssetRows
          }
          {
            investmentAssetRows?.length >= INVESTMENT_CHOICE_MAX_FUND_COUNT
            ? <></>
            : <Row
                pressable={true}
                onPressFunction={() => setAddInvestmentAssetVisible(true)}
                mainText={'Add Fund'}
                iconName={'plus'}
              />
          }
          {
            <Row
              mainText={'Total Allocated'}
              rightText={assetAllocation?.length ? '100%' : '0%'}
              otherStyle={{ ...Typography.fontWeight.bold }}
            />
          }
          <Portal>
            <ActionCardModal
              content={() => <InvestmentAssetModalContent
                accountDescriptor={workingAccountData?.accountDescriptor}
                asset={showAssetDetails}
                segment={client?.segment}
              />}
              visible={!!showAssetDetails}
              onDismiss={() => setShowAssetDetails(undefined)}
              onProceed={() => setShowAssetDetails(undefined)}
              proceedLabel={`Close`}
              onCancel={() => handleDeleteAsset(showAssetDetails)}
              cancelLabel={`Remove Fund`}
              cancelConfirmationMessage={`Remove this fund from your selection? We will distribute the allocation to any other funds you have chosen.`}
            />
          </Portal>
        </ProcessScreen>
      }
    </>
  )
}