import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Colors, Paper, Sizing, Flex } from 'styles'
import { useBlockUserMutation, useGetFeaturesQuery, useGetGroupOrganizationsQuery, useGetUserQuery, useGetUserSupportAccessQuery, useUnblockUserMutation, useUpdateUserMutation } from 'store/apiSlice'
import { Gender, Title } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminUserDataId, setCurrentAdminUserDataId } from 'store/uxSlice'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UserPermissions, hasAnyPermission, setImpersonatedUser } from 'lib/authHelpers'
import { user as adminUser } from 'store/authSlice'
import { format, isBefore } from 'date-fns'
import { platformIsWeb } from 'lib/platformHelpers'
import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { startCase, orderBy } from 'lodash'
import { FeatureStatus } from 'store/dto/feature.dto'
import { ManagedAutoCompleteMultipleInput } from 'components/Inputs/ManagedAutoCompleteMultipleInput'
import { Image, View } from 'react-native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { UnborderedTable } from 'components/Utility/UnborderedTable'

export const AdminEditUserScreen = () => {
  const currentUserId = useAppSelector(currentAdminUserDataId)
  const currentAdminUser = useAppSelector(adminUser)

  const isWeb = platformIsWeb()

  const isSysAdmin = hasAnyPermission(currentAdminUser, [UserPermissions['administer:system']])
  const isUserAdmin = hasAnyPermission(currentAdminUser, [UserPermissions['administer:user']])

  const [dialogVisible, setDialogVisible] = useState(false)
  const showDialog = () => setDialogVisible(true)
  const hideDialog = () => setDialogVisible(false)

  const { isLoading: userIsLoading, error: userError, data: user, refetch: refetchUser } = useGetUserQuery(String(currentUserId), { skip: !currentUserId } )
  const { isSuccess: supportAccessIsSuccess, isLoading: userSupportAccessIsLoading, isFetching: userSupportAccessIsFetching, error: userSupportAccessError, data: userSupportAccess, refetch: refetchUserSupportAccess } = useGetUserSupportAccessQuery(String(currentUserId), { skip: !currentUserId } )
  const [updateUser, { data: updatedUser, isLoading: userUpdateIsLoading, error: userUpdateError }] = useUpdateUserMutation()
  const [blockUser, { isLoading: blockIsLoading, error: blockError }] = useBlockUserMutation()
  const [unblockUser, { isLoading: unblockIsLoading, error: unblockError }] = useUnblockUserMutation()
  const dispatch = useAppDispatch()

  const { data: features, isLoading: featuresIsLoading, error: featuresError, refetch: refetchFeatures } = useGetFeaturesQuery()
  const { data: organizations, isLoading: organizationsIsLoading, error: organizationsError, refetch: refetchOrganizations } = useGetGroupOrganizationsQuery()

  const supportAccessGranted = supportAccessIsSuccess && userSupportAccess && userSupportAccess?.supportAccessUntilDate && isBefore(new Date(), new Date(userSupportAccess?.supportAccessUntilDate))

  const allowImpersonation = isSysAdmin || isUserAdmin && supportAccessGranted

  const formObj = useForm<{
    title: Title
    firstName: string
    surname: string
    gender: Gender,
    email: string
    overrideFeatureCodes: string[]
    groupOrganizationIds: string[]
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: user?.email,
      gender: user?.metadata?.gender,
      firstName: user?.metadata?.firstName,
      surname: user?.metadata?.surname,
      title: user?.metadata?.title,
      overrideFeatureCodes: user ? user?.featureOverrides?.map(feature => {
        return feature.code
      }) : [],
      groupOrganizationIds: user?.groupOrganizationIds ? user?.groupOrganizationIds : [],
    },
  })
  const { handleSubmit, watch, setValue, formState: { isDirty, isValid } } = formObj

  //Form refs for focussing
  const firstNameRef = useRef(null)
  const surnameRef = useRef(null)
  
  const onSubmit = async attributes => {
    let data = {
      email: attributes.email,
      overrideFeatureCodes: attributes.overrideFeatureCodes,
      groupOrganizationIds: attributes.groupOrganizationIds,
      metadata: {
        firstName: attributes.firstName,
        surname: attributes.surname,
        title: attributes.title,
        gender: attributes.gender,
        birthDate: undefined,
      }
    }

    await updateUser({ id: user.id, ...data })
  }

  useEffect(() => {
    if (updatedUser) {
      close()
    }
  }, [updatedUser])

  const close = () => {
    dispatch(setCurrentAdminUserDataId(undefined))
  }

  useEffect(() => {
    if (user) {
      setValue('email', user?.email)
      setValue('gender', user?.metadata?.gender)
      setValue('firstName', user?.metadata?.firstName)
      setValue('surname', user?.metadata?.surname)
      setValue('title', user?.metadata?.title)
      setValue('overrideFeatureCodes', user ? user?.featureOverrides?.map(feature => {
        return feature.code
      }) : [])
      setValue('groupOrganizationIds', user?.groupOrganizationIds ? user?.groupOrganizationIds : [])
    }
  }, [user])

  const isLoading = userIsLoading || userSupportAccessIsLoading || userSupportAccessIsFetching || unblockIsLoading || blockIsLoading || userUpdateIsLoading || featuresIsLoading || organizationsIsLoading
  const error: any = userError || unblockError || blockError || userUpdateError || featuresError || organizationsError

  const handleBlockUser = async () => {
    blockUser(user.id)
  }

  const handleUnblockUser = async () => {
    unblockUser(user.id)
  }

  const impersonateUser = async () => {
    setImpersonatedUser(user, currentAdminUser, dispatch)
    close()
  }

  const { colors: themeColors } = Paper.useAppTheme()

  const name = user?.metadata?.firstName && user?.metadata?.surname
    ? `${user?.metadata?.firstName} ${user?.metadata?.surname}`
    : user?.metadata?.firstName || user?.metadata?.surname || 'Unknown Name'

  const overrideFeatureOptions: ManagedMultipleChoiceInputOption[] = features ? features.map(feature => {
    const actionDescription =
      feature.status === FeatureStatus.OPT_IN ? 'Select to enable for user' :
      feature.status === FeatureStatus.OPT_OUT ? 'Select to disable for user' :
      'Setting not relevant'
    return {
      value: feature.code,
      label: `${feature.name} (${startCase(feature.status)} - ${actionDescription})`,

    }
  }) : []

  const organizationOptions: AutoCompleteItem[] = organizations ? orderBy(organizations, ['name'], ['asc']).map(organization => {
    return {
      value: organization?.id,
      label: organization?.name,
      icon: organization?.logo
      ? () => <Image source={{ uri: organization?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'office-building'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }

  }) : []

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      errorTryAgain={userUpdateError ? handleSubmit(onSubmit) : userError ? refetchUser : userSupportAccessError ? refetchUserSupportAccess : organizationsError ?  refetchOrganizations : refetchFeatures}
      isLoading={isLoading}
      loadingMessage={unblockIsLoading || blockIsLoading || userUpdateIsLoading ? ['Saving user...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
      allowTextButton={true}
      textButtonAction={() => user?.isBlocked === true ? handleUnblockUser() : user?.isBlocked === false ? handleBlockUser() : handleUnblockUser()}
      textButtonTitle={user?.isBlocked === true ? "Unblock user" : user?.isBlocked === false ? "Block user" : `Need a decision - Unblock`}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: user?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: name,
          },
          {
            label: `Email`,
            value: user?.email,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status/Actions`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Current Status`,
            value: user?.isBlocked == true ? `User account has been explicitly blocked`
            : user?.isBlocked == false ? `User account is unblocked and can access the application`
            : `User account is held awaiting decision`,
          },
          {
            label: `Required Action`,
            value: user?.isBlocked == true ? `Only unblock if agreed internally`
            : user?.isBlocked == false ? `n/a`
            : `Unblock to grant access to the application`,
          },
        ]}
        noContentDivider={true}
      />

      {
        isSysAdmin || isUserAdmin
          ? <>
              <ContentDivider />
              <Subheading style={{ color: themeColors.primary }}>{`Impersonation`}</Subheading>
              {
                allowImpersonation
                  ? isWeb
                    ? <Paragraph>{`To impersonate this user, please log in to the Jarvis app on Android/iOS with your Admin credentials, and navigate to this screen.`}</Paragraph>
                    : <>
                        {
                          isSysAdmin
                            ? <Paragraph>{`As System Admin, you may impersonate this user.`}</Paragraph>
                            : <Paragraph>{`User has granted support access for impersonation until ${format(new Date(userSupportAccess?.supportAccessUntilDate), 'do MMMM')}.`}</Paragraph>
                        }
                        <Button
                          mode={'text'}
                          onPress={showDialog}
                        >
                          {`Impersonate User`}
                        </Button>
                      </>
                  : isUserAdmin
                    ? <>
                        <Paragraph>{`To impersonate this user, please ask them to 'Grant Support Access' from within the Profile screen in the Jarvis app.`}</Paragraph>
                        <Button
                          mode={'text'}
                          onPress={refetchUserSupportAccess}
                        >
                          {`Re-check Access`}
                        </Button>
                      </>
                    : <Paragraph>{`You do not have the required permissions to impersonate users.`}</Paragraph>
              }
            </>
          : <></>
       }

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedAutoCompleteInput
        name={'title'}
        formObj={formObj}
        label={'Title'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(Title)}
      />
      <ManagedTextInput
        name={'email'}
        keyboardType='email-address'
        formObj={formObj}
        label={'Email Address'}
        placeholder={'Their email address'}
        autoCapitalize={'none'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid email address"
          },
          required: true,
          minLength: 2,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={firstNameRef}
        name={'firstName'}
        formObj={formObj}
        label={'First Name'}
        placeholder={'Their legal first name'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{          
          minLength: 2,
          maxLength: 40,
        }} />
      <ManagedTextInput
        ref={surnameRef}
        name={'surname'}
        formObj={formObj}
        label={'Last Name'}
        placeholder={'Their legal surname'}
        returnKeyType={'next'}
        blurOnSubmit={true}
        rules={{
          minLength: 2,
          maxLength: 40,
        }} />
      <ManagedAutoCompleteInput
        name={'gender'}
        formObj={formObj}
        label={'Gender'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(Gender)}
      />
      <Paragraph>{'Feature Override'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'overrideFeatureCodes'}
        options={overrideFeatureOptions}
      />
      <Paragraph>{'Group Scheme Administration'}</Paragraph>
      <ManagedAutoCompleteMultipleInput
        name={'groupOrganizationIds'}
        formObj={formObj}
        label={'Group Organization Access'}
        modalTitle={'Select Group Organizations'}
        placeholder={'Select organizations to which the user will have access'}
        searchPlaceholder={'Search for group organizations'}
        dataSet={organizationOptions}
        required={false}
      />
      <ConfirmationDialog
        visible={dialogVisible}
        title={'Are you sure?'}
        content={`Switch into the main app and impersonate this user?`}
        onCancel={hideDialog}
        onConfirm={impersonateUser}
      />

    </ModalProcessScreen>
  )
}

