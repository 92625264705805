import { MaterialCommunityIcons } from "@expo/vector-icons"
import { CardModal } from "components/Layout/CardModal"
import { ContentDivider } from "components/Layout/ContentDivider"
import { Headline, Subheading, Paragraph } from "components/Typography"
import { ErrorScreen } from "components/Utility/ErrorScreen"
import { ExternalLinkText } from "components/Utility/ExternalLinkText"
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from "components/Utility/Loading"
import { JAR_NAME_GROUP, JAR_NAME_PERSONAL } from "lib/constants"
import { getActionDate } from "lib/dateHelpers"
import { getScreenAppWidth } from "lib/scaleHelpers"
import { replaceDoubleNewLineWithBr } from "lib/messageHelpers"
import React, { useEffect } from 'react'
import { Platform, View } from "react-native"
import { ScrollView } from 'react-native'
import { ThemeProvider } from "react-native-paper"
import RenderHtml from 'react-native-render-html'
import { useGetMessageQuery, useGetUserAccountsQuery, useUpdateMessageMutation } from "store/apiSlice"
import { AccountType } from "store/dto/account.dto"
import { MessageDto, MessageStatus } from "store/dto/message.dto"
import { Colors, Flex, Paper, Sizing } from "styles"
import { layoutStyles } from "styles/common"

type MessageInfoModalProps = {
  message: MessageDto
  visible: boolean
  onDismiss: any
}

export const MessageInfoModal = (props: MessageInfoModalProps) => {
  const { onDismiss, visible, message } = props

  const relatedAccountId = message?.payload?.accountId

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery(undefined, { skip: !relatedAccountId })
  const { data: messageWithDocument, error: messageError, isLoading: messageIsLoading, isFetching: messageIsFetching, refetch: refetchMessage } = useGetMessageQuery(message?.id, { skip: !message })

  const relatedAccount = relatedAccountId && accounts ? accounts.find(account => {
    return account.secclAccountId === relatedAccountId
  }) : undefined

  const accountDescriptor =
    relatedAccount?.accountType === AccountType.PERSONAL_PENSION ? JAR_NAME_PERSONAL
    : relatedAccount?.accountType === AccountType.GROUP_PENSION ? JAR_NAME_GROUP
    : undefined

  const [updateMessage] = useUpdateMessageMutation()

  const setStatusAndClose = (status: MessageStatus) => {
    updateMessage({
      id: message?.id,
      status,
      accountId: relatedAccountId,
    })
    onDismiss()
  }

  //Refresh message on open (gets a new document URL)
  useEffect(() => {
    refetchMessage()
  }, [])

  const screenWidth = getScreenAppWidth()
  //Calculate contentWidth from screenWidth minus CardModal padding and cardModalContentContainer padding
  const contentWidth = screenWidth - (Sizing.x10 * 2) - (Sizing.x10 * 2)
  const source = {
    html: `<div>${replaceDoubleNewLineWithBr(message?.payload?.body)}</div>`
  }

  return (
    <ThemeProvider theme={Paper.darkTheme}>
      <CardModal visible={visible} onDismiss={onDismiss} >
        <View style={layoutStyles.cardModalTitleContainer}>
          <View></View>
          <View style={layoutStyles.cardModalTitleTextContainer}></View>
          <View>
            <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
          </View>
        </View>
        <View style={layoutStyles.cardModalContentContainer}>
          {
            accountsIsLoading ? <Loading /> : accountsError ? <ErrorScreen error={accountsError?.data} errorTryAgain={refetchAccounts} /> :
              <>
                <ScrollView
                  contentContainerStyle={{
                    paddingVertical: Sizing.x20,
                  }}
                  showsVerticalScrollIndicator={true}
                >
                  <View style={layoutStyles.cardModalContentContainer}>
                    <View style={{ paddingBottom: Sizing.x20 }}>
                      <Headline>{message?.subject}</Headline>
                      <Subheading>{getActionDate(message?.created)}</Subheading>
                      {
                        accountDescriptor
                          ? <Paragraph>{`This message relates to your ${accountDescriptor}`}</Paragraph>
                          : <></>
                      }
                      {
                        message?.payload?.id
                        ? 
                          <View style={{
                            ...Flex.row.center,
                            paddingTop: Sizing.x10,
                          }}>
                            <View style={{ paddingHorizontal: Sizing.x10}}>
                              <MaterialCommunityIcons name={'paperclip'} size={Sizing.x20} />
                            </View>
                              <ExternalLinkText
                                style={{ color: Colors.brand.purple1}}
                                url={messageWithDocument?.payload?.documentUrl}
                                usePdfViewer={true}
                                disabled={messageIsLoading || messageIsFetching || !!messageError}
                                //SECCL PDFs not currently served with application/pdf so WebView cannot show them
                                directToBrowser={Platform.OS === 'ios' ? true : false}
                              >{
                                messageIsLoading || messageIsFetching ? `Loading...`
                                : messageError ? `Error retrieving attachment`
                                : 'Open Attachment'
                              }</ExternalLinkText>
                          </View>
                        : <></>
                      }
                    </View>
                    <ContentDivider />
                    <View style={{ paddingTop: Sizing.x20 }}>
                      <RenderHtml
                        contentWidth={contentWidth}
                        source={source}
                      />
                    </View>
                  </View>
                </ScrollView>
                <FooterButton
                  onPress={
                    message?.status === MessageStatus.UNREAD ? () => setStatusAndClose(MessageStatus.ARCHIVED)
                    : () => setStatusAndClose(MessageStatus.UNREAD)

                  }
                  mode={'text'}
                >
                  {
                    message?.status === MessageStatus.UNREAD ? 'Archive' : 'Mark Unread'
                  }
                </FooterButton>
                <FooterButton
                  onPress={
                    message?.status === MessageStatus.UNREAD ? () => setStatusAndClose(MessageStatus.READ)
                    : message?.status === MessageStatus.READ ? () => setStatusAndClose(MessageStatus.ARCHIVED)
                    : onDismiss

                  }
                >
                  {
                    message?.status === MessageStatus.UNREAD ? 'Mark Read'
                    : message?.status === MessageStatus.READ ? 'Archive'
                    : 'Close'
                  }
                </FooterButton>
              </>
          }
        </View>

      </CardModal>
    </ThemeProvider>
  )
}
