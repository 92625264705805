import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddGroupSchemeVisible, showAdminAddGroupSchemeVisible } from 'store/uxSlice'
import { AddGroupSchemesScreen } from './Screens/AddGroupSchemesScreen'

export const AddGroupSchemesModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddGroupSchemeVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Group Scheme'
      onDismiss={() => dispatch(setShowAdminAddGroupSchemeVisible(false))}
      screen={<AddGroupSchemesScreen />}
    />
  )
}

