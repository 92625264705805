import { ProcessHeroCarouselData, ProcessHeroScreen } from 'components/ScreenTemplates/ProcessHeroScreen'
import { goToDashboard, mainAppNavigate } from 'lib/RootNavigation'
import { getGroupPension } from 'lib/accountHelpers'
import { JAR_NAME_GROUP } from 'lib/constants'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import React from 'react'
import { useGetUserAccountsQuery } from 'store/apiSlice'
import { PublicGroupSchemeInviteDto } from 'store/dto/group-scheme.dto'

export const SchemeInviteAcceptHeroScreen = ({ route, navigation }) => {
  const { schemeInvite, accept }: { schemeInvite: PublicGroupSchemeInviteDto, accept: boolean } =  route?.params || {}

  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const workplacePension = getGroupPension(accounts)

  const { groupScheme } = schemeInvite || {}
  const { organizationName, schemeName, companyNo, primaryContactUser } = groupScheme || {}
  const { email, firstName, surname } = primaryContactUser || {}

  const carouselScreens: ProcessHeroCarouselData[] = accept
  ? [
      {
        illustrationFilename: 'thanks.png',
        headline: `All done for now!`,
        subHeading: `You've accepted the invitation from ${organizationName} to join their workplace scheme`,
      },
      {
        illustrationFilename: 'invite.png',
        headline: `We've sent them a request to enrol`,
        subHeading: `We'll let you know when your employer processes your enrolment`,
      },
      {
        illustrationFilename: 'record_an_existing_pension.png',
        headline: workplacePension
          ? `Everything in one place`
          : `We'll get everything set up`,
        subHeading: workplacePension
        ? `When enrolled, your employer will be connected to your ${JAR_NAME_GROUP}`
        : `When enrolled, your ${JAR_NAME_GROUP} will be setup and your employer connected`,
      },
      {
        illustrationFilename: 'control_your_financial_future.png',
        headline: `Control at your fingertips`,
        subHeading: `You'll then be able configure contributions from your employer`,
      },
    ]
  : [
    {
      illustrationFilename: 'thanks.png',
      headline: `Ok, maybe another time`,
      subHeading: `You've declined the invitation from ${organizationName}`,
    },
    {
      illustrationFilename: 'support.png',
      headline: `If you change your mind...`,
      subHeading: `Please contact your employer to enrol you or send another invite`,
    },
    {
      illustrationFilename: 'info.png',
      headline: `Automatic Enrolment`,
      subHeading: `Remember, your employer may still automatically enrol you due to UK goverment rules`,
    },
  ]

  return (
    <ProcessHeroScreen
      isLoading={accountsIsLoading}
      error={accountsError}
      errorTryAgain={refetchAccounts}
      carouselScreens={carouselScreens}
      onFinish={() => mainAppNavigate(MainAppNavScreen.GROUP_PENSION)}
      finishButtonLabel={'Finish'}
    />
  )
}
