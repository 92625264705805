import { SectionSimpleContainer } from 'navigation/stacks/SectionSimpleContainer'
import React from 'react'
import { EmployerMembersListScreen } from './Screens/EmployerMembersListScreen'

export const EmployerMemberSectionScreen = ({route}) => {
  return (
    <SectionSimpleContainer
      sectionTitle={'Members'}
    >
      <EmployerMembersListScreen />
    </SectionSimpleContainer>
  )
}

