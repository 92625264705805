import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from 'components/Typography/Text'
import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { getGroupSchemePaymentListInfo } from 'lib/groupSchemePaymentHelpers'
import React from 'react'
import { GroupSchemePaymentDto } from 'store/dto/group-scheme.dto'
import { Paper, Sizing } from 'styles'

type EmployerHistoryPaymentListItemProp = {
  payment: GroupSchemePaymentDto
  onPressFunction?: () => void
}
export const EmployerHistoryPaymentListItem = (props: EmployerHistoryPaymentListItemProp) => {
  const { payment, onPressFunction } = props

  const { colors: themeColors } = Paper.useAppTheme()

  const { iconName, reference, amountString, status, statusMessage } = getGroupSchemePaymentListInfo(payment, false, false)

  return (
    <DetailedListItem
      icon={<MaterialCommunityIcons name={iconName} size={Sizing.x25} color={themeColors.primary} />}
      onPress={onPressFunction}
      title={reference}
      titleRight={amountString}
      titleRightStyle={{ color: themeColors.primary }}
      subTitle={status}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      subTitleRight={<Text>{statusMessage}</Text>}
    />
  )
}
