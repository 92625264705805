import { buildAddressDescription } from 'lib/addressHelpers'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph, Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { formatUkDate, getActionDate } from 'lib/dateHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { orderBy, startCase, compact } from 'lodash'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet } from 'react-native'
import { useGetRetirementAssetAsTransferQuery, useUpdateRetirementAssetTransferInformationMutation } from 'store/apiSlice'
import { RetirementAssetTransferAttempt, RetirementAssetTransferRejectionReason, RetirementAssetTransferStatus, RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminTransferDataId, setCurrentAdminTransferDataId } from 'store/uxSlice'
import { Paper, Sizing } from 'styles'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { formatNationalInsuranceNumber } from 'lib/clientHelpers'

export const AdminTransferEditScreen = () => {
  const currentTransferId = useAppSelector(currentAdminTransferDataId)

  const { isLoading: transferIsLoading, error: transferError, data: transfer, refetch: refetchTransfer } = useGetRetirementAssetAsTransferQuery(String(currentTransferId), { skip: !currentTransferId } )
  const [updateTransferInformation, { isLoading: updateTransferInformationIsLoading, error: updateTransferInformationError }] = useUpdateRetirementAssetTransferInformationMutation()
  const dispatch = useAppDispatch()

  const { transferAttempts } = transfer || {}
  
  const sortedAttempts = transferAttempts ? orderBy(transferAttempts, ['updatedAt'], ['desc']) : []
  const currentTransferAttempt: RetirementAssetTransferAttempt = sortedAttempts.length ? sortedAttempts[0] : undefined

  const formObj = useForm<{
    transferNotes: string,
    transferRejectionReason: RetirementAssetTransferRejectionReason | '',
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      transferNotes: transfer?.transferNotes,
      transferRejectionReason: transfer?.transferRejectionReason,
    }
  })
  const { handleSubmit, setValue, formState: { isDirty, isValid, errors } } = formObj

  useEffect(() => {
    if (transfer) {
      setValue('transferNotes', transfer?.transferNotes)
      setValue('transferRejectionReason', transfer?.transferRejectionReason)
    }
  }, [transfer])

  const onSubmit = async attributes => {
    await updateTransferInformation({
      id: transfer.id,
      transferNotes: attributes?.transferNotes,
      transferRejectionReason: attributes?.transferRejectionReason,
    })
  }

  const close = () => {
    dispatch(setCurrentAdminTransferDataId(undefined))
  }

  const isLoading = transferIsLoading || updateTransferInformationIsLoading
  const error: any = transferError || updateTransferInformationError

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={transferIsLoading ? undefined : ['Saving...']}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Identifier`,
            value: transfer?.id,
            copyableValue: true,
          },
          {
            label: `Current SECCL Transfer Link Id`,
            value: transfer?.currentSecclLinkId,
            copyableValue: true,
          },
          {
            label: `Client Asset Name`,
            value: transfer?.name,
          },
          {
            label: `Asset Name`,
            value: startCase(transfer?.assetType),
          },
          transfer?.assetType === RetirementAssetType.WORKPLACE ? {
            label: `Employer Name`,
            value: transfer?.employerName,
          } : undefined
        ])}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Client`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: transfer?.clientId,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: `${transfer?.clientFirstName} ${transfer?.clientSurname}`
          },
          {
            label: `Date of Birth`,
            value: transfer?.clientBirthDate ? formatUkDate(new Date(transfer?.clientBirthDate)) : '',
          },
          {
            label: `Email`,
            value: transfer?.clientEmail,
            copyableValue: true,
          },
          {
            label: `National Insurance No`,
            value: formatNationalInsuranceNumber(transfer?.clientNationalInsuranceNo),
            copyableValue: transfer?.clientNationalInsuranceNo,
          },
          {
            label: `Transfer Address`,
            value: buildAddressDescription(transfer?.transferAddress, true),
          },
          {
            label: `Current Address`,
            value: buildAddressDescription(transfer?.clientCurrentAddress, true),
          },
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Ceding Provider`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: transfer?.pensionProvider?.id,
            copyableValue: true,
          },
          {
            label: `Origo Identifier`,
            value: transfer?.pensionProvider?.origoId,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: transfer?.pensionProvider?.name,
          },
          {
            label: `Description`,
            value: transfer?.pensionProvider?.description || 'n/a',
          },
        ]}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Transfer Information`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Ceding Provider Reference`,
            value: transfer?.externalProviderReference,
            copyableValue: true,
          },
          {
            label: `Client Estimated Value`,
            value: formatCurrencyAmount(transfer?.currentValue),
          },
          {
            label: `Current Estimated Value`,
            value: currentTransferAttempt ? formatCurrencyAmount(currentTransferAttempt?.transferValue, 2) : 'n/a',
          },
        ]}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status`}</Subheading>
      <UnborderedTable
        data={compact([
          {
            label: `Current Status`,
            value: startCase(transfer?.transferStatus),
          },
          {
            label: `Requested Date`,
            value: transfer?.transferInitiationDate ? formatUkDate(new Date(transfer?.transferInitiationDate)) : 'n/a',
          },
          {
            label: `Client Progress Confirmed?`,
            value: transfer?.transferProgressConfirmed ? 'Yes' : 'No',
          },
          transfer?.transferStatus === RetirementAssetTransferStatus.COMPLETED ? [
            {
              label: `Completion Date`,
              value: transfer?.transferCompletionDate ? formatUkDate(new Date(transfer?.transferCompletionDate)) : 'n/a',
            },
            {
              label: `Client Outcome Acknowledged?`,
              value: transfer?.completedTransferAcknowledged ? 'Yes' : 'No',
            }]
          :  transfer?.transferStatus === RetirementAssetTransferStatus.ABANDONED ? []
          : [
            {
              label: `Open For`,
              value: `${transfer?.transferOpenDays} day${transfer?.transferOpenDays === 1 ? '' : 's'}`,
            },
            {
              label: `Stale For`,
              value: `${transfer?.transferStaleDays} day${transfer?.transferStaleDays === 1 ? '' : 's'}`,
            }
          ],
        ])}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Transfer Log`}</Subheading>
      {
        currentTransferAttempt?.statusChanges?.length
          ? <Paragraph>{`Status updates for the transfer are shown below:`}</Paragraph>
          : <Paragraph>{`No status updates available yet`}</Paragraph>
      }
      {
        currentTransferAttempt?.statusChanges?.map(((data, idx) => (
          <>
            <BulletItem key={idx} style={localStyles.text}>{getActionDate(data?.statusDate, true)}: {data?.transferStatus || data?.status} - {data?.description}</BulletItem>
          </>
        )))
      }

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedAutoCompleteInput
        name={'transferRejectionReason'}
        formObj={formObj}
        label={'Transfer Rejection Reason'}
        selectOnlyMode={true}
        dataSet={enumToAutocompleteOptions(RetirementAssetTransferRejectionReason)}
        required={false}
      />
      <ManagedTextInput
        name={'transferNotes'}
        formObj={formObj}
        label={'Transfer Notes'}
        placeholder={'Enter any client-facing notes'}
        multiline={true}
        style={{
          height: Sizing.x300,
        }}
        rules={{
          minLength: 1,
          maxLength: 1000,
        }} />
    </ModalProcessScreen>
  )
}

const localStyles = StyleSheet.create({
  text: {
    textAlign: 'left',
  },
})