import { MaterialCommunityIcons } from '@expo/vector-icons'
import { InputErrorMessages } from 'components/Inputs/InputErrorMessages'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedIntegerInput } from 'components/Inputs/ManagedIntegerInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ExternalLinkButton } from 'components/Utility/ExternalLinkButton'
import { ImagePickerButton } from 'components/Utility/ImagePickerButton'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { envVariables } from 'environment'
import { YAHOO_QUOTE_BASE_URL } from 'lib/constants'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { createBranchLink } from 'lib/linkHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { pick, sortBy } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Clipboard, Image, View } from 'react-native'
import { useGetInvestmentPlanQuery, useUpdateInvestmentPlanMutation } from 'store/apiSlice'
import { UpdateInvestmentPlanDto } from 'store/dto/investment-plan.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminInvestmentPlanDataId, setCurrentAdminInvestmentPlanDataId, setSnackbarData } from 'store/uxSlice'
import { Colors, Paper, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'

export const AdminEditInvestmentPlanScreen = () => {
  const currentInvestmentPlanId = useAppSelector(currentAdminInvestmentPlanDataId)

  const { data: investmentPlan, isLoading: investmentPlanIsLoading, error: investmentPlanError } = useGetInvestmentPlanQuery(currentInvestmentPlanId, { skip: !currentInvestmentPlanId } )
  const [updateInvestmentPlan, { data: updatedInvestmentPlan, isLoading: investmentPlanUpdateIsLoading, error: investmentPlanUpdateError }] = useUpdateInvestmentPlanMutation()
  const dispatch = useAppDispatch()

  const isWeb = platformIsWeb()

  const copyToClipboard = async (text) => {
    await Clipboard.setString(text)
    dispatch(setSnackbarData({
      message: `Copied: "${text}"`,
      iconName: 'content-copy',
    }))
  }

  const formObj = useForm<{
    name: string
    shortDescription: string
    longDescription: string
    priority: number
    sustainabilityRating: number
    assetId: string
    logo: string
    riskStatements: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: investmentPlan?.name,
      shortDescription: investmentPlan?.shortDescription,
      longDescription: investmentPlan?.longDescription,
      priority: investmentPlan?.priority,
      sustainabilityRating: investmentPlan?.sustainabilityRating,
      assetId: investmentPlan?.assetId,
      riskStatements: investmentPlan?.riskStatements ? investmentPlan?.riskStatements.join(';') : '',
      logo: investmentPlan?.logo,
    }
  })
  const { handleSubmit, register, setValue, setError, watch, trigger, formState: { isDirty, isValid } } = formObj
  register('logo', { required: true })

  const handleImagePick = (base64: string) => {
    setValue('logo', base64, { shouldDirty: true}) 
    trigger()
  }

  const handleClearImage = () => {
    setValue('logo', null, { shouldDirty: true})
  }
  
  const logo = watch('logo')

  useEffect(() => {
    if (investmentPlan) {
      setValue('name', investmentPlan?.name)
      setValue('shortDescription', investmentPlan?.shortDescription)
      setValue('longDescription', investmentPlan?.longDescription)
      setValue('priority', investmentPlan?.priority)
      setValue('sustainabilityRating', investmentPlan?.sustainabilityRating)
      setValue('assetId', investmentPlan?.assetId)
      setValue('riskStatements', investmentPlan?.riskStatements ? investmentPlan?.riskStatements.join(';') : '')
      setValue('logo', investmentPlan?.logo)
    }
  }, [investmentPlan])

  //Form refs for focussing
  const nameRef = useRef(null)
  const shortDescriptionRef = useRef(null)
  const longDescriptionRef = useRef(null)
  const priorityRef = useRef(null)
  const sustainabilityRatingRef = useRef(null)
  const riskStatementsRef = useRef(null)

  const onSubmit = async attributes => {
    const { riskStatements } = attributes
    const riskStatementsArray = riskStatements.split(';')

    await updateInvestmentPlan({
      id: investmentPlan.id,
      ...attributes,
      riskStatements: riskStatementsArray,
    })
  }

  useEffect(() => {
    if (updatedInvestmentPlan) {
      close()
    }
  }, [updatedInvestmentPlan])

  const close = () => {
    dispatch(setCurrentAdminInvestmentPlanDataId(undefined))
  }

  const isLoading = investmentPlanIsLoading ||  investmentPlanUpdateIsLoading
  const error: any = investmentPlanError || investmentPlanUpdateError

  const { colors: themeColors } = Paper.useAppTheme()

  const asset = investmentPlan?.asset
  const yahooUrl = `${YAHOO_QUOTE_BASE_URL}${asset?.yahooSymbol}`

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      isLoading={isLoading}
      loadingMessage={investmentPlanUpdateIsLoading ? ['Saving investment plan...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={handleSubmit(onSubmit)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: investmentPlan?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: investmentPlan?.name,
          },
          {
            label: `Code`,
            value: investmentPlan?.code,
          }
        ]}
        noContentDivider={true}
      />

      <Subheading style={{ color: themeColors.primary }}>{`Underlying Asset`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Asset Identifier`,
            value: investmentPlan?.assetId,
            copyableValue: true,
          },
          {
            label: `Full Name`,
            value: asset?.longName,
          },
          {
            label: `Fund Manager`,
            value: asset?.manager,
          },
          {
            label: `ISIN`,
            value: asset?.isin,
            copyableValue: true,
          },
          {
            label: `Yahoo Symbol`,
            value: asset?.yahooSymbol,
            copyableValue: true,
          },
        ]}
        noContentDivider={true}
      />
      <ExternalLinkButton
          url={yahooUrl}
      >
        {`More Information`}
      </ExternalLinkButton>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => shortDescriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 20,
      }}/>
      <ManagedTextInput
        ref={shortDescriptionRef}
        name={'shortDescription'}
        formObj={formObj}
        label={'Short Description'}
        placeholder={'Short Description for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => longDescriptionRef.current?.focus()}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 100,
        }}/>
      <ManagedTextInput
        ref={longDescriptionRef}
        name={'longDescription'}
        formObj={formObj}
        label={'Long Description'}
        placeholder={'Long Description for display to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 200,
        }}/>
      <View style={layoutStyles.inputContainer}>
        <ImagePickerButton
          disabled={!!logo}
          mode={'contained'}
          successHandler={handleImagePick}
          quality={1}
        >{logo ? `Change Logo` : `Choose Logo`}</ImagePickerButton>
        {
          logo ? <></> : <InputErrorMessages formObj={formObj} name={'logo'} informationMessage={'Required'} informationMessageIsError={true} />
        }
      </View>
      {
        logo ?
          <View style={{
            alignItems: 'center',
            paddingVertical: Sizing.x10,
          }}> 
            <View style={{
              borderRadius: Sizing.x5,
              borderStyle: 'dashed',
              borderColor: Colors.neutral.s400,
              borderWidth: Sizing.x2,
            }}>
              <Image
                source={{ uri: logo }}
                style={{
                  width: Sizing.x200,
                  height: Sizing.x200,
                }}
                resizeMode={'contain'}
              />
            </View>
            <Button mode={'text'} onPress={handleClearImage}>{'Remove logo'}</Button>
          </View>
        : <></>
      }
      <Paragraph>{'Priority (for display order)'}</Paragraph>
      <ManagedIntegerInput
        ref={priorityRef}
        name={'priority'}
        formObj={formObj}
        label={'Priority'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => sustainabilityRatingRef.current?.focus()}
        required={true}
        minValue={1}
        maxValue={1000}
      />
      <Paragraph>{'Sustainability Rating (out of 5)'}</Paragraph>
      <ManagedIntegerInput
        ref={sustainabilityRatingRef}
        name={'sustainabilityRating'}
        formObj={formObj}
        label={'Sustainability Rating'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => riskStatementsRef.current?.focus()}
        required={true}
        minValue={1}
        maxValue={5}
      />
      <ManagedTextInput
        ref={riskStatementsRef}
        name={'riskStatements'}
        formObj={formObj}
        label={'Risk Statements'}
        placeholder={'Separate multiple statements by a semicolon'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 200,
        }}/>
    </ModalProcessScreen>
  )
}

