import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { Image, ScrollView, View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery, useLazyGetRetirementAssetsAsTransfersQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { identity, pickBy, orderBy } from 'lodash'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { RetirementAssetTransferFilterDto, RetirementAssetTransferSearchTransferStatus, RetirementAssetTransferSearchType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminTransferDataId } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'
import { AdminTransferListItem } from '../Components/AdminTransferListItem'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { isNil, isNaN, isInteger } from 'lodash'
import { Paragraph } from 'components/Typography'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

const isValidNumberOfDays = (value: string) => {
  if (isNil(value) || value === '') {
    return true
  }
  const intVal = parseFloat(value)
  if(isNaN(intVal)) {
    return 'Must be a number'
  }
  if(!isInteger(intVal)) {
    return 'Must be a whole number'
  }
  if (intVal < 1) {
    'Must be at least 1'
  } else if (intVal > 99) {
    'Must be at most 99'
  }
  return true
}

export const AdminTransfersListScreen = () => {
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()
  const [getTransfersQuery, { data: transfersData, isLoading: transfersIsLoading, error: transfersError, isFetching: transfersIsFetching }] = useLazyGetRetirementAssetsAsTransfersQuery()
  const [refreshing, setRefreshing] = useState(false)

  const providerOptions: AutoCompleteItem[] = providers && brands ? orderBy(providers, ['name'], ['asc']).map(provider => {
    const primaryBrand = brands.find(brand => {
      return brand.key === provider.primaryBrandKey
    })
    return {
      value: provider?.id,
      label: provider?.name,
      description: provider?.description,
      icon: primaryBrand?.logo
      ? () => <Image source={{ uri: primaryBrand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }

  }) : []

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowTransferDetails = (id: string) => {
    dispatch(setCurrentAdminTransferDataId(id))
  }

  const transfersList = () => {
    let all = []
    if (transfersData) {
      transfersData.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    const providerOption = providerOptions ? providerOptions.find(option => {
      return option.value === item.pensionProviderId
    }) : undefined
    return (
      <AdminTransferListItem
        transfer={item}
        providerOption={providerOption}
        onPressFunction={() => handleShowTransferDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{
    search: string,
    transferStatus: RetirementAssetTransferSearchTransferStatus,
    assetType: RetirementAssetTransferSearchType,
    pensionProviderId: string,
    currentSecclLinkId: string,
    minTransferOpenDays: string,
    minTransferStaleDays: string,
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      transferStatus: RetirementAssetTransferSearchTransferStatus.ANY,
      assetType: RetirementAssetTransferSearchType.ANY,
      pensionProviderId: '',
      currentSecclLinkId: '',
      minTransferOpenDays: '',
      minTransferStaleDays: '',
    },
  })

  const { handleSubmit, reset, watch } = formObj

  const onSubmit = attributes => {
    const transferStatus = attributes.transferStatus === RetirementAssetTransferSearchTransferStatus.ANY ? undefined : attributes.transferStatus
    const assetType = attributes.assetType === RetirementAssetTransferSearchType.ANY ? undefined : attributes.assetType
    getTransfersQuery(pickBy({
      ...attributes,
      minTransferOpenDays: attributes?.minTransferOpenDays ? parseInt(attributes.minTransferOpenDays) : undefined,
      minTransferStaleDays: attributes?.minTransferStaleDays ? parseInt(attributes.minTransferStaleDays) : undefined,
      transferStatus,
      assetType,
    }, identity))
  }

  const isLoading = transfersIsLoading || transfersIsFetching || brandsIsLoading || providersIsLoading

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      transferStatus: RetirementAssetTransferSearchTransferStatus.ANY,
      assetType: RetirementAssetTransferSearchType.ANY,
      pensionProviderId: '',
      currentSecclLinkId: '',
      minTransferOpenDays: '',
      minTransferStaleDays: '',
    })
  }

  const transferStatus = watch('transferStatus')
  const assetType = watch('assetType')
  const currentSecclLinkId = watch('currentSecclLinkId')
  const pensionProviderId = watch('pensionProviderId')
  const searchTermRequired = transferStatus === RetirementAssetTransferSearchTransferStatus.ANY && assetType === RetirementAssetTransferSearchType.ANY && currentSecclLinkId === '' && pensionProviderId === ''

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:transfer']]}
      >
        {
          isLoading ? <Loading message={transfersIsLoading || transfersIsFetching ? getLookingLoadingMessages('Finding transfers...') : undefined} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for transfer'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of client email/name, provider brand, reference, notes'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                    required: searchTermRequired,
                    minLength: searchTermRequired ? {
                      value: 3,
                      message: 'Must be at least 3 characters if no other filter is set'
                    } : undefined,
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                      <ManagedAutoCompleteInput
                        name={'transferStatus'}
                        label={'Transfer Status'}
                        selectOnlyMode={true}
                        formObj={formObj}
                        dataSet={enumToAutocompleteOptions(RetirementAssetTransferSearchTransferStatus)}
                      />
                      <ManagedTextInput
                        name={'minTransferOpenDays'}
                        formObj={formObj}
                        label={'Minimum Open Days'}
                        placeholder={'Minimum number of days since transfer was requested '}
                        rules={{
                          validate: {
                            isValidNumberOfDays,
                          }
                        }}
                      />
                      <ManagedTextInput
                        name={'minTransferStaleDays'}
                        formObj={formObj}
                        label={'Minimum Stale Days'}
                        placeholder={'Minimum number of days without any updates'}
                        rules={{
                          validate: {
                            isValidNumberOfDays,
                          }
                        }}
                      />
                      <ManagedAutoCompleteInput
                        name={'assetType'}
                        label={'Pension Type'}
                        selectOnlyMode={true}
                        formObj={formObj}
                        dataSet={enumToAutocompleteOptions(RetirementAssetTransferSearchType)}
                      />
                      <ManagedTextInput
                        name={'currentSecclLinkId'}
                        label={'SECCL Transfer Link Id'}
                        autoFocus={false}
                        formObj={formObj}
                        placeholder={'Enter SECCL transfer link Id'}
                        blurOnSubmit={false}
                        autoCapitalize={'characters'}
                        rules={{
                          minLength: 9,
                          maxLength: 9,
                        }} />
                      <ManagedAutoCompleteInput
                        name={'pensionProviderId'}
                        label={'Pension Provider'}
                        formObj={formObj}
                        dataSet={providerOptions}
                      />
                      <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                      <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={isWeb}
              >
                <OptionalSectionList
                  sections={transfersList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for a transfer`}
                />
              </ScrollView>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
