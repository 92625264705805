import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ScrollView, View } from 'react-native'
import Collapsible from 'react-native-collapsible'
import { useLazyGetGroupSchemePaymentsQuery } from 'store/apiSlice'
import { GroupSchemePaymentSearchStatus } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentEmployerPaymentDataId } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { EmployerHistoryPaymentListItem } from '../Components/EmployerHistoryPaymentListItem'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const EmployerHistoryPaymentsListScreen = () => {
  const currentScheme = useAppSelector(currentGroupScheme)
  const [getPaymentsQuery, { data: payments, isLoading: paymentsIsLoading, error: paymentError, isFetching: paymentsIsFetching }] = useLazyGetGroupSchemePaymentsQuery()
  const [refreshing, setRefreshing] = useState(false)

  //Swap results when switching scheme
  useEffect(() => {
    if (currentScheme && isValid) {
      onSubmit(watch())
    }
  }, [currentScheme])

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowPaymentDetails = (id: string) => {
    dispatch(setCurrentEmployerPaymentDataId(id))
  }

  const paymentsList = () => {
    let all = []
    if (payments) {
      payments.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <EmployerHistoryPaymentListItem
        payment={item}
        onPressFunction={() => handleShowPaymentDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{
    search: string, 
    status: GroupSchemePaymentSearchStatus }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      status: GroupSchemePaymentSearchStatus.ANY,
    },
  })

  const { handleSubmit, reset, watch, formState: { isValid} } = formObj

  const onSubmit = attributes => {
    const status = attributes.status === GroupSchemePaymentSearchStatus.ANY ? undefined : attributes.status
    getPaymentsQuery({
      groupSchemeId: currentScheme.id,
      status,
      search: attributes.search,
    })
  }

  const isLoading = paymentsIsLoading || paymentsIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      status: GroupSchemePaymentSearchStatus.ANY,
    })
  }
 
  return (
    <MainAppScreen>
      {
        isLoading ? <Loading message={getLookingLoadingMessages('Finding payments...')} /> :
          <View style={ Flex.column.start } >
            <View style={{ paddingHorizontal: Sizing.x30 }} >
              <ManagedTextInput
                name={'search'}
                label={'Search payment reference'}
                autoFocus={false}
                formObj={formObj}
                placeholder={'Enter full or partial payment reference'}
                blurOnSubmit={false}
                submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                rules={{
                  required: true,
                  minLength:{
                    value: 3,
                    message: 'Must be at least 3 characters'
                  },
                }} />
              <Collapsible collapsed={searchCollapsed}>
                <ScrollView>
                  <ManagedAutoCompleteInput
                    name={'status'}
                    label={'Payment Status'}
                    selectOnlyMode={true}
                    formObj={formObj}
                    dataSet={enumToAutocompleteOptions(GroupSchemePaymentSearchStatus)}
                  />
                  <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                  <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                </ScrollView>
              </Collapsible>
              <Button
                mode='text'
                color={themeColors.accent}
                icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                onPress={searchCollapsed ? showAdvanced : hideAdvanced}
              >
                {searchCollapsed ? 'Advanced search...' : 'Hide'}
              </Button>
              <ContentDivider />
            </View>
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
            >
              <OptionalSectionList
                sections={paymentsList()}
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={refreshing}
                noDataMessage={`Please search for a payment`}
              />
            </ScrollView>
          </View>
      }
    </MainAppScreen>
  )
}
