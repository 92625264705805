import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useFocusEffect } from '@react-navigation/native'
import { Headline, Subheading } from 'components/Typography'
import { AppInfoModal } from 'components/Utility/AppInfoModal'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { WebAppDownloadModal } from 'components/Utility/WebAppDownloadModal'
import { changeAppContext, goToMainAppStack, rootNavigate } from 'lib/RootNavigation'
import { userIsReady } from 'lib/authHelpers'
import { getScreenAppHeight, getScreenAppWidth, scaleNormalizer } from 'lib/scaleHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { useEffect, useState } from 'react'
import { Image, Pressable, ScrollView, StatusBar, StyleSheet, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery, useGetOpenInviteByIdQuery } from 'store/apiSlice'
import { AppContext, setLoginParams } from 'store/authSlice'
import { UserDto } from 'store/dto/user.dto'
import { useAppDispatch } from 'store/hooks'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { AppIllustration } from 'components/Utility/AppIllustration'

const isWeb = platformIsWeb()

export const EntryPointPartnerScreen = ({ route, navigation }) => {
  const { preferredInviteId } = route.params || {}
  
  const [showDownloadModal, setShowDownloadModal] = useState(null)

  const dispatch = useAppDispatch()

  const insets = useSafeAreaInsets()
  const verticalInset = insets.bottom + insets.top
  const screenHeight = getScreenAppHeight()
  const screenWidth = getScreenAppWidth()
  const useHorizontalLayout = isWeb && screenWidth >= 1000
  const avaiableHeight = scaleNormalizer(screenHeight - verticalInset - 250)

  const { data: openInvite, isLoading: openInviteIsLoading, isFetching: openInviteIsFetching, error: openInviteError } = useGetOpenInviteByIdQuery(preferredInviteId, { skip: !preferredInviteId })
  const authUser = useSelector((state: any) => state.auth.user)
  const authUserReady = userIsReady(authUser)
  const { data: user, isLoading: userIsLoading, isFetching: userIsFetching, error: userError } = useGetCurrentUserQuery(undefined, { skip: !authUserReady})

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.CLIENT, user, dispatch, false)
  })

  //Set loginParams emailAddress when get an invite without a user
  useEffect(() => {
    if (openInvite && userError) {
      dispatch(setLoginParams({
        emailAddress: openInvite?.email,
      }))
    }
  }, [openInvite, userError])

  const [showInfoModal, setShowInfoModal] = useState(false)

  const isLoading = openInviteIsLoading || openInviteIsFetching || userIsLoading || userIsFetching

  function Heading() {
    return (
      <Headline adjustsFontSizeToFit numberOfLines={2} style={{
        textAlign: useHorizontalLayout ? 'left' : 'center',
        color: Colors.brand.purple1,
        fontSize: Sizing.x30,
        lineHeight: Sizing.x30,
        paddingTop: Sizing.x30,
      }}>{'Pensions without the guesswork'}</Headline>
    )
  }

  function MainImage() {
    return (
      <View style={{
        flexDirection: 'row',
        justifyContent: useHorizontalLayout ? 'flex-end' : 'center',
      }}>
        <View style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
          <AppIllustration
            filename={openInvite
              ? 'envelope_with_star.png'
              : 'magnifying_glass.png'
            }
            style={{
              width: scaleNormalizer(280),
              height: useHorizontalLayout ? (screenWidth - scaleNormalizer(300))/2 : avaiableHeight / 2,
              alignSelf: useHorizontalLayout ? 'flex-end' : undefined,
              paddingRight: useHorizontalLayout ? Sizing.x30 : undefined,
            }}
            resizeMode={'contain'}
          />
        </View>
      </View>
    )
  }
  

  function MainMessage() {
    const mainMessage =
      openInvite
        ? `${openInvite?.from?.firstName} ${openInvite?.from?.surname} has invited you to plan your retirement together`
        : `Sorry, it looks like that invitation is no longer available`
    return (
      <>
        {
          mainMessage
            ? <Subheading style={{
                ...Typography.fontWeight.bold,
                textAlign: useHorizontalLayout ? 'left' : 'center',
                paddingHorizontal: useHorizontalLayout ? undefined : Sizing.x15,
                fontSize: Sizing.x15,
                lineHeight: Sizing.x15,
              }}>{mainMessage}</Subheading>
            : <></>
        }
      </>
    )
  }

  function SubMessage() {
    const subMessage =
      user ? '' :
      openInvite
        ? `Design your pension plan, know how much to save each month, and retire with confidence. It's simple with Jarvis.`
        : isWeb
          ? `You can still create a Jarvis account here and we'll give you the links to download our app for Android or iOS.`
          : `You can still create a Jarvis account and we'll try to find your partner invite when you're logged in.`

    return (
      <Subheading style={{
        textAlign: useHorizontalLayout ? 'left' : 'center',
        fontSize: Sizing.x15,
        lineHeight: Sizing.x15,
        paddingHorizontal: useHorizontalLayout ? undefined : Sizing.x15,
      }}>{subMessage}</Subheading>
    )
  }

  function IconSection() {
    return (
      <>
        {
          openInvite
            ? <Subheading style={{...Typography.fontWeight.bold, textAlign: useHorizontalLayout ? 'left' : 'center'}}>{`Welcome ${openInvite?.firstName} ${openInvite?.surname}!`}</Subheading>
            : <></>
        }
      </>
    )
  }

  function CtaButton() {
    return (
      <>
        <View style={{
          alignSelf: 'flex-start',
          paddingTop: Sizing.x30,
          width: useHorizontalLayout ? undefined : '100%'
        }}>
          <View style={{
            alignSelf: 'flex-start',
            width: '100%'
          }}>
            {
              user && openInvite
                ? <FooterButton
                    mode={'text'}
                    onPress={() => goToMainAppStack()}
                  >
                    {`Back to Jarvis`}
                  </FooterButton>
                  : isWeb ? <FooterButton
                      mode={'text'}
                      onPress={() => setShowDownloadModal(true)}
                      labelStyle={{
                        textDecorationLine: 'none',
                        marginTop: Sizing.x0,
                        marginBottom: Sizing.x0,
                      }}
                      color={Colors.brand.purple1}
                      icon={() => <MaterialCommunityIcons name={'download'} size={Sizing.x20} color={Colors.brand.purple1} />}
                    >
                      {`Download Mobile App`}
                    </FooterButton>
                  : <></>
            }
            <FooterButton
              onPress={
                user && openInvite && !isWeb ? () => rootNavigate('InviteAcceptIntroScreen')
                : user ? () => goToMainAppStack()
                : () => rootNavigate('NewUserCarouselScreen')
              }
            >
              {
                user && openInvite ? `Respond to Invite`
                : user ? `Back to Jarvis` 
                : `Let's Go!`
              }
            </FooterButton>
          </View>
        </View>

      </>
    )
  }

  return (
    <>
      { isLoading ? <Loading useHolidayGraphics={true} /> :
        <>
          <StatusBar barStyle={'dark-content'} />
          <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
            <SafeAreaView style={[
              backgroundStyles.pageContainer,
              backgroundStyles.heroBackground,
            ]}>
              <View style={[
                layoutStyles.mainContentContainer,
                localStyles.screenContainer,
              ]}>
                <ScrollView
                  contentContainerStyle={layoutStyles.scrollContainerContent}
                  keyboardShouldPersistTaps='handled'
                  showsVerticalScrollIndicator={isWeb}
                >
                  { useHorizontalLayout
                    ?
                      <View style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingLeft: Sizing.x30,
                        width: '80%',
                        alignSelf: 'center',
                      }}>
                        <View style={{
                          ...Flex.row.between,
                        }}>
                          <View style={{
                            ...Flex.column.center,
                            width: '50%',
                          }}>
                            <Pressable onPress={() => setShowInfoModal(true)}>
                              <Image source={require('assets/brand/jarvis-logo-blk-4x.png')} style={{
                                width: Sizing.x200,
                                height: Sizing.x50,
                                resizeMode: 'contain',
                                alignSelf: 'flex-start',
                                // alignSelf: useHorizontalLayout ? 'flex-start' : 'center',
                              }} />
                              </Pressable>
                              <Heading />
                            <IconSection />
                            <MainMessage />
                            <SubMessage />
                            <CtaButton />
                          </View>
                          <View style={{
                            ...Flex.column.start,
                            width: '50%',
                          }}>
                            <MainImage />
                          </View>
                        </View>
                      </View>
                    :
                      <>
                        <View style={Flex.column.around}>
                          <Pressable onPress={() => setShowInfoModal(true)}>
                            <Image source={require('assets/brand/jarvis-logo-blk-4x.png')} style={{
                                width: Sizing.x200,
                                height: Sizing.x50,
                                resizeMode: 'contain',
                                alignSelf: 'center',
                            }} />
                          </Pressable>
                          <Heading />
                          <IconSection />
                          <MainImage />
                          <MainMessage />
                          <SubMessage />
                          <CtaButton />
                        </View>
                      </>
                  }
                </ScrollView>
              </View>
            </SafeAreaView>
            <AppInfoModal
              user={user}
              client={undefined}
              visible={showInfoModal}
              onDismiss={() => setShowInfoModal(false)}
              isUnauthenticated={!user}
            />
            {
              isWeb
                  ? <WebAppDownloadModal
                        visible={showDownloadModal}
                        onDismiss={() => setShowDownloadModal(false)}
                    />
                  : <></>
            }
          </ThemeProvider>
        </>
      }
    </>
  )
}

const localStyles = StyleSheet.create({
  screenContainer: {
    paddingTop: Sizing.x30,
  },
})
