import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminExceptionDataId, setCurrentAdminExceptionDataId } from 'store/uxSlice'
import { AdminExceptionEditScreen } from './Screens/AdminExceptionEditScreen'

export const AdminExceptionEditModal = () => {
  const dispatch = useAppDispatch()
  const currentException = useAppSelector(currentAdminExceptionDataId)
  return (
    <ModalFormBuilder
      visible={!!currentException}
      formTitle='Exception Management'
      onDismiss={() => dispatch(setCurrentAdminExceptionDataId(undefined))}
      screen={<AdminExceptionEditScreen />}
    />
  )

}

