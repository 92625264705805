import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddUserVisible, showAdminAddUserVisible } from 'store/uxSlice'
import { AddUsersScreen } from './Screens/AddUsersScreen'

export const AddUsersModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddUserVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add/invite New User'
      onDismiss={() => dispatch(setShowAdminAddUserVisible(false))}
      screen={<AddUsersScreen />}
    />
  )
}

