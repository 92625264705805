import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { filterStatementMessages } from 'lib/messageHelpers'
import { refreshUsingFunctions } from 'lib/refreshHelpers'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useGetMessagesQuery } from 'store/apiSlice'
import { MessageDto, MessageType } from 'store/dto/message.dto'
import { StatementInfoModal } from './StatementInfoModal'
import { StatementListItem } from './StatementListItem'

interface ReturnType {
  title: string,
  data: MessageDto[]
}

type StatementListProps = {
}

export const StatementList = (props: StatementListProps) => {

  const { data: messages, isLoading: messagesIsLoading, error: messagesError, refetch } = useGetMessagesQuery({})
  const [refreshing, setRefreshing] = useState(false)

  const isLoading = messagesIsLoading
  const error: any = messagesError

  const [modalItem, setModalItem] = useState(undefined)

  const statementMessages = filterStatementMessages(messages)

  const statementList = (): ReturnType[] => {
    let generated = []
    let quarterly = []
    let tax = []
    let other = []

    if (statementMessages) {
      statementMessages.map((data, id) => {
        if (data?.messageType === MessageType.VALUATION_STATEMENT) {
          generated.push(data)
        } else if (data?.messageType === MessageType.QUARTERLY_STATEMENT) {
          quarterly.push(data)
        } else if (data?.messageType === MessageType.TAX_STATEMENT) {
          tax.push(data)
        } else {
          other.push(data)
        }
      })
      
      return [
        { title: 'Client Generated', data: generated },
        { title: 'Quarterly', data: quarterly },
        { title: 'Tax Statements', data: tax },
        { title: 'Other Statemebts', data: other },
      ]
    }
    return []
  }
  
  const Item = ({ item }) => {
    return (
      <StatementListItem
        message={item}
        onPressFunction={() => setModalItem(item)}
      />
    )
  }

  const onRefresh = async () => {
    await refreshUsingFunctions(
      [refetch],
      refreshing,
      setRefreshing,
    )
  }

  return (
    <>
      {
        isLoading
          ? <Loading  message={getLookingLoadingMessages('Loading your statements...')} messageNoLoop={true} />
          : error ? <ErrorScreen error={error?.data} errorTryAgain={onRefresh} /> :
        <>
          <View style={{ flex: 1 }} >
            <OptionalSectionList
              sections={statementList()}
              renderItem={({item})=><Item item={item}/>}
              onRefresh={onRefresh}
              refreshing={refreshing}
              noDataMessage={`You haven't yet requested or generated any statements.`}
            />
          </View>
          { modalItem ?
            <Portal>
              <StatementInfoModal
                visible={!!modalItem}
                message={modalItem}
                onDismiss={() => setModalItem(undefined)}
              />
            </Portal>
            : <></>
          }
        </>
      }
    </>
  )
}
