import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedMultipleChoiceInput } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { UnborderedTable } from 'components/Utility/UnborderedTable'
import { isNilOrEmptyString } from 'lib/generalHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { orderBy, sortBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetGroupPortfoliosQuery, useGetGroupSchemeQuery, useGetPensionBrandsQuery, useUpdateGroupSchemeMutation } from 'store/apiSlice'
import { GroupSchemeRequestType, PaymentMethod } from 'store/dto/account.dto'
import { GroupOrganizationMandateStatus } from 'store/dto/group-organization.dto'
import { GroupSchemeEnrolmentPayrollFrequency, GroupSchemeSalarySacrificeMode, GroupSchemeStatus, OverrideGroupOrganizationPaymentMethod } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupSchemeDataId, setCurrentAdminGroupSchemeDataId } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing } from 'styles'

export const AdminEditGroupSchemeScreen = () => {
  const currentGroupSchemeId = useAppSelector(currentAdminGroupSchemeDataId)

  const [updateDialogVisible, setUpdateDialogVisible] = useState(false)

  const { data: groupScheme, isLoading: groupSchemeIsLoading, error: groupSchemeError } = useGetGroupSchemeQuery(currentGroupSchemeId, { skip: !currentGroupSchemeId } )
  const [updateGroupScheme, { data: updatedGroupScheme, isLoading: groupSchemeUpdateIsLoading, error: groupSchemeUpdateError }] = useUpdateGroupSchemeMutation()
  const [activateGroupScheme, { data: activatedGroupScheme, isLoading: groupSchemeActivationIsLoading, error: groupSchemeActivationError }] = useUpdateGroupSchemeMutation()
  const [inactivateGroupScheme, { data: inactivatedGroupScheme, isLoading: groupSchemeInactivationIsLoading, error: groupSchemeInactivationError }] = useUpdateGroupSchemeMutation()

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()

  const { data: groupPortfolios, isLoading: groupPortfoliosIsLoading, error: groupPortfoliosError, refetch: refetchGroupPortfolios } = useGetGroupPortfoliosQuery()

  const dispatch = useAppDispatch()

  const isWeb = platformIsWeb()

  const mandatedRequestTypes: GroupSchemeRequestType[] = [
    GroupSchemeRequestType.OPT_OUT,
    GroupSchemeRequestType.OPT_IN,
    GroupSchemeRequestType.CEASE_MEMBERSHIP,
  ]

  const availableRequestTypes: GroupSchemeRequestType[] = [
    GroupSchemeRequestType.OPT_IN,
    GroupSchemeRequestType.OPT_OUT,
    GroupSchemeRequestType.CEASE_MEMBERSHIP,
    GroupSchemeRequestType.SET_GROSS_CONTRIBUTION,
  ]

  const formObj = useForm<{
    name: string
    tprLetterCode: string
    dutiesStartDate?: string
    defaultEmployerContributionPercentage: number
    defaultEmployeeContributionPercentage: number
    salarySacrificeMode: GroupSchemeSalarySacrificeMode
    isAutoEnrolment: boolean
    defaultPayrollFrequency: GroupSchemeEnrolmentPayrollFrequency
    defaultContributionFrequency: GroupSchemeEnrolmentPayrollFrequency
    defaultGroupPortfolioId: string
    defaultEnrolmentName: string
    defaultTreatContributionsAsRegular: boolean
    previousPensionProviderBrandKey?: string
    previousPensionProviderBrandName?: string
    enabledRequestTypes?: GroupSchemeRequestType[]
    defaultContributionMethod?: OverrideGroupOrganizationPaymentMethod
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: groupScheme?.name,
      tprLetterCode: groupScheme?.tprLetterCode,
      dutiesStartDate: groupScheme?.dutiesStartDate,
      defaultEmployerContributionPercentage: groupScheme?.defaultEmployerContributionPercentage,
      defaultEmployeeContributionPercentage: groupScheme?.defaultEmployeeContributionPercentage,
      salarySacrificeMode: groupScheme?.salarySacrificeMode,
      isAutoEnrolment: groupScheme?.isAutoEnrolment,
      defaultPayrollFrequency: groupScheme?.defaultPayrollFrequency,
      defaultContributionFrequency: groupScheme?.defaultContributionFrequency,
      defaultGroupPortfolioId: groupScheme?.defaultGroupPortfolioId,
      defaultEnrolmentName: groupScheme?.defaultEnrolmentName,
      defaultTreatContributionsAsRegular: groupScheme?.defaultTreatContributionsAsRegular,
      previousPensionProviderBrandKey: groupScheme?.previousPensionProviderBrandKey,
      previousPensionProviderBrandName: groupScheme?.previousPensionProviderBrandName,
      enabledRequestTypes: groupScheme?.enabledRequestTypes || [],
      defaultContributionMethod: groupScheme?.defaultContributionMethod === PaymentMethod.DIRECT_DEBIT
        ? OverrideGroupOrganizationPaymentMethod.DIRECT_DEBIT
        : groupScheme?.defaultContributionMethod === PaymentMethod.BANK_TRANSFER
        ? OverrideGroupOrganizationPaymentMethod.BANK_TRANSFER
        : OverrideGroupOrganizationPaymentMethod.USE_PARENT,
    }
  })
  const { handleSubmit, setValue, setError, watch, trigger, formState: { isDirty, isValid } } = formObj

  useEffect(() => {
    if (groupScheme) {
      setValue('name', groupScheme?.name)
      setValue('tprLetterCode', groupScheme?.tprLetterCode)
      setValue('dutiesStartDate', groupScheme?.dutiesStartDate)
      setValue('defaultEmployerContributionPercentage', groupScheme?.defaultEmployerContributionPercentage)
      setValue('defaultEmployeeContributionPercentage', groupScheme?.defaultEmployeeContributionPercentage)
      setValue('salarySacrificeMode', groupScheme?.salarySacrificeMode)
      setValue('isAutoEnrolment', groupScheme?.isAutoEnrolment)
      setValue('defaultPayrollFrequency', groupScheme?.defaultPayrollFrequency)
      setValue('defaultContributionFrequency', groupScheme?.defaultContributionFrequency)
      setValue('defaultGroupPortfolioId', groupScheme?.defaultGroupPortfolioId)
      setValue('defaultEnrolmentName', groupScheme?.defaultEnrolmentName)
      setValue('defaultTreatContributionsAsRegular', groupScheme?.defaultTreatContributionsAsRegular)
      setValue('previousPensionProviderBrandKey', groupScheme?.previousPensionProviderBrandKey)
      setValue('previousPensionProviderBrandName', groupScheme?.previousPensionProviderBrandName)
      setValue('enabledRequestTypes', groupScheme?.enabledRequestTypes || [])
      setValue('defaultContributionMethod', groupScheme?.defaultContributionMethod === PaymentMethod.DIRECT_DEBIT
        ? OverrideGroupOrganizationPaymentMethod.DIRECT_DEBIT
        : groupScheme?.defaultContributionMethod === PaymentMethod.BANK_TRANSFER
        ? OverrideGroupOrganizationPaymentMethod.BANK_TRANSFER
        : OverrideGroupOrganizationPaymentMethod.USE_PARENT)
    }
  }, [groupScheme])

  //Form refs for focussing
  const nameRef = useRef(null)
  const tprLetterCodeRef = useRef(null)
  const dutiesStartDateRef = useRef(null)
  const defaultEmployerContributionPercentageRef = useRef(null)
  const defaultEmployeeContributionPercentageRef = useRef(null)
  const defaultEnrolmentNameRef = useRef(null)

  const onSubmit = async attributes => {
    const {
      defaultEmployerContributionPercentage,
      defaultEmployeeContributionPercentage,
      dutiesStartDate,
      defaultContributionMethod,
      ...remaining
    } = attributes
    await updateGroupScheme({
      id: groupScheme.id,
      dutiesStartDate: dutiesStartDate && dutiesStartDate !== '' ? dutiesStartDate : undefined,
      defaultEmployerContributionPercentage: defaultEmployerContributionPercentage && defaultEmployerContributionPercentage !== ''
        ? parseFloat(defaultEmployerContributionPercentage)
        : undefined,
      defaultEmployeeContributionPercentage: defaultEmployeeContributionPercentage && defaultEmployeeContributionPercentage !== ''
        ? parseFloat(defaultEmployeeContributionPercentage)
        : undefined,
      defaultContributionMethod: defaultContributionMethod === OverrideGroupOrganizationPaymentMethod.DIRECT_DEBIT
        ? PaymentMethod.DIRECT_DEBIT
        : defaultContributionMethod === OverrideGroupOrganizationPaymentMethod.BANK_TRANSFER
        ? PaymentMethod.BANK_TRANSFER
        : null,
      ...remaining,
    })
    setUpdateDialogVisible(false)
  }

  const handleSetActive = async () => {
    await activateGroupScheme({
      id: groupScheme.id,
      status: GroupSchemeStatus.ACTIVE,
    })
  }
    
  const handleSetInactive = async () => {
    await inactivateGroupScheme({
      id: groupScheme.id,
      status: GroupSchemeStatus.INACTIVE,
    })
  }

  useEffect(() => {
    if (updatedGroupScheme) {
      close()
    }
  }, [updatedGroupScheme])

  const close = () => {
    dispatch(setCurrentAdminGroupSchemeDataId(undefined))
  }

  const isLoading = groupPortfoliosIsLoading || groupSchemeIsLoading || brandsIsLoading || groupSchemeUpdateIsLoading || groupSchemeActivationIsLoading || groupSchemeInactivationIsLoading
  const error: any = groupPortfoliosError || groupSchemeError || brandsError || groupSchemeUpdateError || groupSchemeActivationError || groupSchemeInactivationError

  const { colors: themeColors } = Paper.useAppTheme()

  const groupPortfolioOptions: AutoCompleteItem[]  = groupPortfolios ? sortBy(groupPortfolios.map((groupPortfolio): AutoCompleteItem => {
    return {
      value: groupPortfolio.id,
      label: groupPortfolio.name,
      description: groupPortfolio.description,
      icon: () => <Image source={{ uri: groupPortfolio?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
    }
  }), 'name')  : []

  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  const defaultTreatContributionsAsRegularOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'Treat as one-off contributions'
    },
    {
      value: true,
      label: 'Treat as regular contributions',
    },
  ]
  
  const isAutoEnrolmentOptions: ManagedSimpleChoiceItem[] = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ]

  const isNumeric = (value: string) => {
    if (isNilOrEmptyString(value)) {
      return true
    }
    return isNaN(parseFloat(value)) ? 'Must be a number': true
  }

  const isInRange = (value: string) => {
    if (isNilOrEmptyString(value)) {
      return true
    }
    const numValue = parseFloat(value)
    const result = numValue <= 100 && numValue >= 0
    return result ? true : 'Must be between 0 and 100'
  }

  return (
    <ModalProcessScreen
      error={error}
      errorCancel={close}
      errorTryAgain={groupPortfoliosError ? refetchGroupPortfolios
        : brandsError ? refetchBrands
        : groupSchemeActivationError ? handleSetActive
        : groupSchemeInactivationError ? handleSetInactive
        : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={groupSchemeUpdateIsLoading ? ['Saving group scheme...'] : undefined}
      buttonTitle={'Save'}
      buttonAction={() => setUpdateDialogVisible(true)}
      showButton={true}
      enableButton={isDirty && isValid}
    >
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Identification`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupScheme?.id,
            copyableValue: true,
          },
          {
            label: `Pension Scheme Reference`,
            value: groupScheme?.employerPensionSchemeReference,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />
      
      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Status`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Current Status`,
            value: groupScheme?.status,
            copyableValue: true,
          },
          {
            label: `Explanation`,
            value: groupScheme?.status === GroupSchemeStatus.ACTIVE
            ? `Employer invites have been sent and schemes is ready to use.`
            : `Scheme is inactive and can no longer be accessed by the employer.`,
          }
        ]}
        noContentDivider={true}
      />
      <Button
          onPress={groupScheme?.status === GroupSchemeStatus.ACTIVE ? handleSetInactive : handleSetActive}
          mode={'text'}
          confirmation={{
            requiresConfirmation: true,
            dialogTitle: 'Are you sure?',
            dialogContent: groupScheme?.status === GroupSchemeStatus.ACTIVE
              ? 'This will prevent access by the employer to this scheme.\n\nThis change must also be communicated to Digi.'
              : 'This will re-enable access by the employer to this scheme\n.An invite will be (re)sent to the primary contact.\n\nThis change must also be communicated to Digi.'
          }}
        >
          {
            groupScheme?.status === GroupSchemeStatus.ACTIVE
              ? 'Deactivate Scheme'
              : 'Reactivate Scheme'
          }
        </Button>

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Owning Organization`}</Subheading>
      <UnborderedTable
        data={[
          {
            label: `Identifier`,
            value: groupScheme?.organization?.id,
            copyableValue: true,
          },
          {
            label: `Name`,
            value: groupScheme?.organization?.name,
          },
          {
            label: `Company No`,
            value: groupScheme?.organization?.companyNo,
            copyableValue: true,
          }
        ]}
        noContentDivider={true}
      />

      <ContentDivider />
      <Subheading style={{ color: themeColors.primary }}>{`Edit Details`}</Subheading>
      <ManagedTextInput
        ref={nameRef}
        name={'name'}
        formObj={formObj}
        label={'Name'}
        placeholder={'Scheme name visible to users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
        }}/>
      <ManagedTextInput
        ref={tprLetterCodeRef}
        name={'tprLetterCode'}
        formObj={formObj}
        label={'TPR Letter Code'}
        placeholder={'The Pensions Regulator Letter Code'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        submitHandler={() => dutiesStartDateRef.current?.focus()}
        rules={{
          required: false,
          minLength: 10,
          maxLength: 10,
      }}/>
      <Paragraph>{'Auto Enrolment Duties Start Date'}</Paragraph>
      <ManagedDateInput
        ref={dutiesStartDateRef}
        name={'dutiesStartDate'}
        formObj={formObj}
        blurOnSubmit={true}
        required={false}
        mustBeInPast={true}
      />
      <Paragraph>{'Contribution Configuration'}</Paragraph>
      <ManagedTextInput
        ref={defaultEmployerContributionPercentageRef}
        name={'defaultEmployerContributionPercentage'}
        formObj={formObj}
        label={'Default Employer Contribution Percentage'}
        blurOnSubmit={false}
        returnKeyType={'done'}
        keyboardType={'numeric'}
        submitHandler={() => defaultEmployeeContributionPercentageRef.current?.focus()}
        rules={{
          required: false,
          validate: {
            isNumeric,
            isInRange,
          }
        }}
      />
      <ManagedTextInput
        ref={defaultEmployeeContributionPercentageRef}
        name={'defaultEmployeeContributionPercentage'}
        formObj={formObj}
        label={'Default Employee Contribution Percentage'}
        blurOnSubmit={false}
        returnKeyType={'done'}
        keyboardType={'numeric'}
        rules={{
          required: false,
          validate: {
            isNumeric,
            isInRange,
          }
        }}
      />
      <ManagedAutoCompleteInput
        name={'salarySacrificeMode'}
        formObj={formObj}
        label={'Salary Sacrifice Mode'}
        selectOnlyMode={true}
        required={true}
        dataSet={enumToAutocompleteOptions(GroupSchemeSalarySacrificeMode)}
      />
      <Paragraph>{'Is an Auto Enrolment Scheme?'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'isAutoEnrolment'}
        formObj={formObj}
        options={isAutoEnrolmentOptions}
        required={true}
    />
      <Paragraph>{'Payroll Configuration'}</Paragraph>
      <ManagedAutoCompleteInput
        name={'defaultPayrollFrequency'}
        formObj={formObj}
        label={'Default Payroll Frequency'}
        selectOnlyMode={true}
        required={false}
        dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
      />
      <ManagedAutoCompleteInput
        name={'defaultContributionFrequency'}
        formObj={formObj}
        label={'Default Contribution Frequency'}
        selectOnlyMode={true}
        required={true}
        dataSet={enumToAutocompleteOptions(GroupSchemeEnrolmentPayrollFrequency)}
      />
      {
        groupScheme?.organization?.mandateStatus === GroupOrganizationMandateStatus.ACTIVE
          ? <>
              <Paragraph>{'Override Default Contribution Method?'}</Paragraph>
              <ManagedSimpleChoiceInput
                name={'defaultContributionMethod'}
                formObj={formObj}
                options={enumToAutocompleteOptions(OverrideGroupOrganizationPaymentMethod)}
                required={true}
              />
            </>
          : <></>
      }
      <Paragraph>{'Investment Configuration'}</Paragraph>
      <ManagedAutoCompleteInput
        name={'defaultGroupPortfolioId'}
        formObj={formObj}
        label={'Default Group Portfolio'}
        modalTitle={'Select Default Group Portfolio'}
        placeholder={'Select an default portfolio for new accounts'}
        searchPlaceholder={'Search for a group portfolio'}
        dataSet={groupPortfolioOptions}
        required={true}
      />
      <Paragraph>{'Member Enrolment Configuration'}</Paragraph>
      <ManagedTextInput
        ref={defaultEnrolmentNameRef}
        name={'defaultEnrolmentName'}
        formObj={formObj}
        label={'Default Account Name'}
        placeholder={'Default name for new accounts visible to end users'}
        returnKeyType={'next'}
        blurOnSubmit={false}
        rules={{
          required: true,
          minLength: 3,
          maxLength: 30,
      }}/>
      <Paragraph>{'Allowed request types from members'}</Paragraph>
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'enabledRequestTypes'}
        options={enumToAutocompleteOptions(GroupSchemeRequestType, mandatedRequestTypes, availableRequestTypes)}
      />
      <Paragraph>{'Forecasting Default Contribution Treatment'}</Paragraph>
      <ManagedSimpleChoiceInput
        name={'defaultTreatContributionsAsRegular'}
        formObj={formObj}
        options={defaultTreatContributionsAsRegularOptions}
        required={true}
      />
      <Paragraph>{'Previous Pension Provider Brand'}</Paragraph>
      <ManagedAutoCompleteInput
        name={'previousPensionProviderBrandKey'}
        placeholder={'Tap to search or enter...'}
        textCaptureFieldName={'previousPensionProviderBrandName'}
        allowFreeText={true}
        unknownItemUseDescription={'Use this name'}
        unknownItemSelectedDescription={'Name specified by you'}
        formObj={formObj}
        modalTitle={'Find/Enter Pension Brand'}
        required={false}
        dataSet={brandOptions}
        />
      <ConfirmationDialog
        visible={updateDialogVisible}
        title={'Are you sure?'}
        content={`Any relevant changes must be communicated to Digi.`}
        onCancel={() => setUpdateDialogVisible(false)}
        onConfirm={handleSubmit(onSubmit)}
      />
    </ModalProcessScreen>
  )
}

