import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Paragraph, Subheading } from 'components/Typography'
import React, { ComponentProps } from 'react'
import { RefreshControl, ScrollView, SectionList, View } from 'react-native'
import { Flex, Paper, Sizing, Typography } from 'styles'
import { platformIsWeb } from 'lib/platformHelpers'
import { Button } from './Button'
import { ContentDivider } from 'components/Layout/ContentDivider'

export type OptionalSectionListProps = ComponentProps<typeof SectionList> & {
  noDataMessage: string
  noRefresh?: boolean
}

export const OptionalSectionList = (props: OptionalSectionListProps) => {
  const { stickySectionHeadersEnabled, sections, noDataMessage, noRefresh } = props 

  const isWeb = platformIsWeb()

  const checkHasData = (sections) => {
    return sections.some(section => {
      return section.data.length > 0
    })
  }

  const { colors: themeColors } = Paper.useAppTheme()

  const hasData = checkHasData(sections)

  const renderHeader = (section: any) => {
    const { title, headerTools } = section
    return (
      <>
        <Subheading style={Typography.defined.sectionListHeader}>{title || ''} </Subheading>
        {
          headerTools || <></>
        }
      </>
    )    
  }

  const renderFooter = (section: any) => {
    return (
      <ContentDivider style={{ marginHorizontal: Sizing.x0 }}/>
    )    
  }

  //This guarantees that the list is rendered initially
  //It seems that if there are lots of sections, and the first ones are empty
  //then the result may be nothing rendered
  //This ensures that initialNumToRender is set to at least the number
  //of sections * 2 - "1" for the header, and "1" for the data (which could be empty)
  const initialNumToRender = sections.length * 2

  return (
    <View style={{
        paddingHorizontal: Sizing.x30, 
        width: '100%',
        height: isWeb ? undefined : '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}>
      { hasData ?
        <>
          <SectionList
            {...props}
            initialNumToRender={initialNumToRender}
            stickySectionHeadersEnabled={stickySectionHeadersEnabled || false}
            renderSectionHeader={({ section }) => (
              section.data.length > 0 ? renderHeader(section) : <></>          
            )}
            renderSectionFooter={({ section }) => (
              section.data.length > 0 ? renderFooter(section) : <></>          
            )}
            showsVerticalScrollIndicator={isWeb}
          />
          {
            noRefresh ? <></> : isWeb
              ? <View style={{...Flex.row.center, alignContent: 'flex-end' }}>
                  <Button mode='text' onPress={props.onRefresh}>{'Refresh'}</Button>
                </View>
              : <></>
          }
        </>
        :
        <ScrollView
          // bounces={false}
          showsVerticalScrollIndicator={isWeb}
          refreshControl={noRefresh ? undefined :
            <RefreshControl
              refreshing={props.refreshing}
              onRefresh={props.onRefresh}
            />
          }
        >
         <View style={{...Flex.column.start, paddingTop: Sizing.x50 }}>
          <View style={Flex.row.center}>
            <MaterialCommunityIcons name="information-outline" size={Sizing.x100} color={themeColors.accent} />
          </View>
          <View style={{ paddingHorizontal: Sizing.x50 }}>
            <Paragraph>{noDataMessage}</Paragraph>
            <Paragraph></Paragraph>
            <View style={{...Flex.row.center, alignContent: 'flex-end' }}>
              {
                isWeb ? <></> : <MaterialCommunityIcons style={{ paddingTop: Sizing.x2 }} name={'chevron-double-down'} size={Sizing.x20} color={themeColors.accent} />
              }
              {
                noRefresh ? <></> : isWeb 
                  ? <Button mode='text' onPress={props.onRefresh}>{'Refresh'}</Button>
                  : <Paragraph style={{ color: themeColors.accent }}>{'Pull to Refresh'}</Paragraph>
              }
            </View>
          </View>
        </View>
        </ScrollView>
      }
    </View>
  )
}
