import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { batch } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_24_PersonalDetailsPreviousNameChoice = ({ route, navigation }) => {
  const { nextScreen, client, asset, isRetransfer }  = route?.params || {}
  const [showDialog, setShowDialog] = useState(false)

  const clientHasPreviousName = client?.hasPreviousName
  
  //Temp state
  const dispatch = useAppDispatch()
  
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)
  
  const tracingRequested = workingRetirementAssetData?.tracingRequested

  //Setup form
  const formObj = useForm<{ useClientPreviousNameForTransfer: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      useClientPreviousNameForTransfer: workingRetirementAssetData?.useClientPreviousNameForTransfer
        ? workingRetirementAssetData?.useClientPreviousNameForTransfer
        : asset?.useClientPreviousNameForTransfer
    },
  })
  const { handleSubmit, watch, formState: { isValid, isDirty } } = formObj
  
  const onSubmit = attributes => {
    setShowDialog(false)
    batch(() => {
      dispatch(updateWorkingRetirementAsset({
        ...attributes,
      }))
    })

    //Navigate to previous name screens or skip if already collected
    navigation.navigate(attributes?.useClientPreviousNameForTransfer && !clientHasPreviousName
      ? nextScreen
      : tracingRequested
        ? 'TracingIntro'
        : 'Result')
  }

  const softOnSubmit = attributes => {
    if (attributes?.useClientPreviousNameForTransfer || !workingRetirementAssetData?.tracingRequested) {
      batch(() => {
        dispatch(updateWorkingRetirementAsset({
          ...attributes,
        }))
      })  
    } else {
      onSubmit(attributes)
    }
  }

  const previousNameOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: clientHasPreviousName ? `${client?.firstName} ${client?.surname}` : `Yes, ${client?.firstName} ${client?.surname}`
    },
    {
      value: true,
      label: clientHasPreviousName ? `${client?.previousFirstName} ${client?.previousSurname}` : 'No, a different name',
    },
  ]

  const useClientPreviousNameForTransfer = watch('useClientPreviousNameForTransfer')

  return (
    <ModalProcessScreen
      buttonTitle={isRetransfer
        ? isDirty
          ? `Change Name`
          : `It's Correct`
        : !useClientPreviousNameForTransfer && !tracingRequested
          ? 'Finish'
          : 'Next'
      }
      buttonAction={!tracingRequested && useClientPreviousNameForTransfer 
        ? () => setShowDialog(true)
        : handleSubmit(onSubmit)
      }
      enableButton={isValid}
      headline={isRetransfer
        ? `Could they know you by a previous name?`
        : clientHasPreviousName
          ? `Which name would they know you by?`
          : `Would this provider know you by your current name?`
      }
      subHeading={isRetransfer
        ? `Transfers are usually rejected if the your doesn't match`
        : `They need to have the same name on their records to allow the transfer`
      }
    >
      <ManagedSimpleChoiceInput
        name={'useClientPreviousNameForTransfer'}
        formObj={formObj}
        options={previousNameOptions}
        submitHandler={handleSubmit(softOnSubmit)}
        required={true}
        informationMessage={tracingRequested && useClientPreviousNameForTransfer
          ? `Once we find your pension, we will be in touch to discuss updating your old provider with your current name`
          : useClientPreviousNameForTransfer
            ? `We can only transfer pensions where your old provider has your current name on their records`
            : undefined
        }
        informationMessageIsError={!tracingRequested && useClientPreviousNameForTransfer}
      />
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'Your old provider needs your current name'}
        content={`At present, we can only transfer your pension if your old provider has your current name on record.\n\n${clientHasPreviousName
            ? `If you choose to continue, we'll put this transfer on hold until you change your details with the old provider.`
            : `Continue by telling us your old name we'll let you know what to do next.`
        }`}
        confirmLabel={`Continue`}
        cancelLabel={`Go Back`}
        onConfirm={handleSubmit(onSubmit)}
      />
    </ModalProcessScreen>
  )
}