import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import React from 'react'
import { useAppDispatch } from 'store/hooks'

export const RetirementAssetsAddAsset_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss, onlyPensions, client }  = route?.params || {}

  const next = () => {
     navigation.navigate(nextScreen)
  } 
  
  //Preload data

  return (      
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'record_an_existing_pension.png'}
      headline={`Record an existing pension ${onlyPensions ? '' : 'or asset'}`}
      subHeading={onlyPensions
        ? `This can be any contribution-based pension owned by ${client?.isSpouse ? `${client?.firstName}` : 'you'}`
        : `This can be any type of asset that ${client?.isSpouse ? `${client?.firstName} is` : 'you are'} keeping for retirement`
      }
      subHeadingInfo={onlyPensions ? undefined : <NamedInformationButton name={NamedInformation.OTHER_ASSET_TYPES} />}
    >
    </ModalProcessIntroScreen>
  )
}
