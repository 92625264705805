import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminAffiliateDataId, setCurrentAdminAffiliateDataId } from 'store/uxSlice'
import { AdminEditAffiliateScreen } from './Screens/AdminEditAffiliateScreen'

export const AdminEditAffiliatesModal = () => {
  const dispatch = useAppDispatch()
  const currentAffiliate = useAppSelector(currentAdminAffiliateDataId)
  return (
    <ModalFormBuilder
      visible={!!currentAffiliate}
      formTitle='Edit Affiliate Details'
      onDismiss={() => dispatch(setCurrentAdminAffiliateDataId(undefined))}
      screen={<AdminEditAffiliateScreen />}
    />
  )

}

