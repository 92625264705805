import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminAssetCategoryDataId, setCurrentAdminAssetCategoryDataId } from 'store/uxSlice'
import { AdminEditAssetCategoryScreen } from './Screens/AdminEditAssetCategoriesScreen'

export const AdminEditAssetCategoriesModal = () => {
  const dispatch = useAppDispatch()
  const currentAssetCategory = useAppSelector(currentAdminAssetCategoryDataId)
  return (
    <ModalFormBuilder
      visible={!!currentAssetCategory}
      formTitle='Edit Asset Category Details'
      onDismiss={() => dispatch(setCurrentAdminAssetCategoryDataId(undefined))}
      screen={<AdminEditAssetCategoryScreen />}
    />
  )

}

