import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Paragraph, Subheading } from 'components/Typography'
import { goToOnboardingTasks } from 'lib/RootNavigation'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { OnboardingState } from 'providers/guidance.context'
import React, { useState } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import { Card, ProgressBar } from 'react-native-paper'
import { Colors, Flex, Sizing, Typography, Paper } from 'styles'
import { ConfirmationDialog } from './ConfirmationDialog'
import { ShakeBox } from './ShakeBox'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { Text } from 'components/Typography/Text'
import { NotificationDot } from './NotificationDot'
import { AppIllustration } from './AppIllustration'

export type OnboardingCardProps = {
  state: OnboardingState
  isFirst?: boolean
  isLast?: boolean
  isComplete?: boolean
  isDismissed?: boolean
  isActive?: boolean
  isCurrent?: boolean
  isCollapsed?: boolean
  noProgressBar?: boolean
  onPress?: any
}

export const OnboardingCard = (props: OnboardingCardProps) => {
  const { state, isFirst, isLast, isComplete, isDismissed, isActive, isCurrent, isCollapsed, onPress, noProgressBar } = props
  const [dialogVisible, setDialogVisible] = useState(false)
  const doneSteps = state.completedSteps + state.notRequiredSteps
  const totalSteps = state?.totalSteps
  const progress = totalSteps > 0 ? doneSteps / totalSteps : 0
  const isOnly = isFirst && isLast

  const { colors: themeColors } = Paper.darkThemeOnLightGrey

  const handlePress = () => {
    setDialogVisible(false)
    if (onPress) {
      onPress()
     } else {
      goToOnboardingTasks(state?.onboardingPhase)
     }
  }

  return (
    <View style={localStyles.cardOuterContainer}>
      {
        isOnly ? <View style={{ width: Sizing.x10}}></View> :
        <View style={localStyles.cardGulley}>
          <View style={[localStyles.cardPath, { borderBottomLeftRadius: Sizing.x5, borderBottomRightRadius: Sizing.x5, backgroundColor: isFirst ? undefined : isCurrent || isComplete || isDismissed ? Colors.neutral.white : Colors.brand.grey2 }]}></View>
          <MaterialCommunityIcons
            style={{
              paddingVertical: Sizing.x2, 
              alignSelf: 'center',
            }}
            name={isComplete || isDismissed ? 'check-circle-outline' : isCurrent || isActive ? 'checkbox-blank-circle' : 'clock-outline' }
            size={scaleNormalizer(24)}
            color={isCurrent ? Colors.brand.red3 : isDismissed || isComplete ? Colors.neutral.white : Colors.brand.grey2}
          />
          <View style={[localStyles.cardPath, {
            borderTopLeftRadius: Sizing.x5,
            borderTopRightRadius: Sizing.x5,
            backgroundColor: isLast ? undefined : isDismissed ? Colors.neutral.white : isCurrent || !isComplete ? Colors.brand.grey2 : Colors.neutral.white
          }]}></View>
        </View>
      }
      <View style={localStyles.cardInnerContainer}>
        <View style={{ flex: 1 }}>
          {
            isDismissed
            ? <View style={localStyles.dismissedCard}>
                <View style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}>
                  <Text style={[
                    localStyles.titleStyle,
                    {
                      color: Colors.neutral.white,
                      paddingVertical: Sizing.x0,
                    },
                    ]}
                    numberOfLines={1}
                  >{`${state.title}`}</Text>
                </View>
              </View>
            : isCollapsed
            ? <Pressable
                style={localStyles.dismissedCard}
                onPress={handlePress}
              >
                <View style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}>
                  <Subheading style={[
                    localStyles.titleStyle,
                    {
                      color: Colors.brand.grey2,
                      paddingVertical: Sizing.x0,
                    },
                    ]}
                    numberOfLines={1}
                  >{`${state.title}`}</Subheading>
                </View>
              </Pressable>
            :
              <ShakeBox
                disabled={!isCurrent}
                initialDelayMs={2000}
                loopDelayMs={5000}
                distance={2}
                duration={50}
                vertical={false}
              >
              <Card style={[
                localStyles.smallCard,
                {
                  backgroundColor: Colors.neutral.white,
                  opacity: isCurrent || isComplete || isActive ? 1 : 0.7,
                }
              ]} onPress={!isCurrent && !isActive
                  ? () => setDialogVisible(true)
                  : handlePress
              }>
                { isCurrent
                    ? <NotificationDot
                        top={scaleNormalizer(-15)}
                        right={scaleNormalizer(-10)}
                        count={0}  
                      /> 
                    : <></>
                }
                <View style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  flex: 1,
                }}>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}>
                    <View style={localStyles.iconContainer}>
                      {
                        state.imageSource
                          ? <Image
                              style={{
                                width: Sizing.x60,
                                height: Sizing.x60,
                                alignSelf: 'center',
                              }}
                              resizeMode={'contain'}
                              source={state.imageSource}
                            />
                          : <AppIllustration
                              style={{
                                width: Sizing.x60,
                                height: Sizing.x60,
                                alignSelf: 'center',
                              }}
                              resizeMode={'contain'}
                              filename={state.illustrationFilename || 'info.png'}
                            />

                      }
                    </View>
                    <View style={{
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      flex: 1,
                    }}>
                      <Subheading style={[
                          localStyles.titleStyle,
                          {
                            color: isCurrent ? themeColors.onSurfaceTitle : undefined,
                          },
                          // isCurrent ? Typography.fontWeight.bold : {},
                        ]}
                        numberOfLines={1}
                      >
                        {state.title}
                      </Subheading>
                      {
                        !isCurrent
                        ?
                          <Paragraph style={localStyles.descriptionStyle}>{
                            isComplete
                              ? `All done! Tap for results`
                              : isActive
                                ? `Continue with this step`
                                : `Skip ahead to this step`}</Paragraph>
                        :
                        noProgressBar ? <></> :
                        <View style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          flex: 1,
                        }}>
                          <View style={localStyles.progressBarContainer}>
                            <ProgressBar
                              color={themeColors.progressBar}
                              style={[
                                localStyles.progressBar,
                                {
                                  backgroundColor: themeColors.progressBarBackground,
                                }
                              ]}
                              progress={progress}
                            />
                          </View>
                          <View style={localStyles.stepsContainer}>
                            <Subheading>{`${doneSteps} / ${totalSteps}`}</Subheading>
                          </View>
                        </View>
                      }
                    </View>
                  </View>
                  {
                    isCurrent
                      ? <View style={localStyles.shortMessageContainer}>
                          <Paragraph style={{
                            ...localStyles.descriptionStyle,
                          }}>{state.shortMessage}</Paragraph>
                        </View>
                      : <></>
                  }
                </View>
              </Card>
            </ShakeBox>
          }

        </View>
      </View>
      {
        !isComplete && !isCurrent ?
          <ConfirmationDialog
            visible={dialogVisible}
            title={`Are you sure?`}
            content={`We recommend completing the checklists in order, so that Jarvis can give you the best experience, but you're free to tackle them however you like!`}
            onConfirm={handlePress}
            onCancel={() => setDialogVisible(false)}
            confirmLabel={'Continue'}
            cancelLabel={'Cancel'}
          />
          : <></>
      }
    </View>
  )
}

const localStyles = StyleSheet.create({
  cardOuterContainer: {
    paddingRight: Sizing.x10,
    ...Flex.row.start,
    // height: scaleNormalizer(130),
  },
  cardInnerContainer: {
    paddingVertical: scaleNormalizer(8),
    flex: 1,
    // height: scaleNormalizer(130),
  },
  cardGulley: {
    width: Sizing.x30,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardPath: {
    alignSelf: 'center',
    width: scaleNormalizer(6),
    flex: 1,
  },
  dismissedCard: {
    paddingLeft: Sizing.x5,
    paddingRight: Sizing.x20,
    borderRadius: Sizing.x15,
  },
  smallCard: {
    // position: 'absolute',
    // width: '100%',
    borderRadius: Sizing.x15,
    paddingLeft: Sizing.x5,
    paddingRight: Sizing.x5,
    paddingVertical: Sizing.x10,
    // height: scaleNormalizer(120),
  },
  iconContainer: {
    width: Sizing.x80,
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 0,
  },
  titleStyle: {
    ...Typography.fontSize.x15,
    lineHeight: Sizing.x15,
    paddingLeft: Sizing.x0,
    marginTop: Sizing.x2,
    textAlign: 'left',
  },
  titleStepsStyle: {
    textAlign: 'right',
  },
  progressBarContainer: {
    paddingRight: Sizing.x10,
    flex: 1,
    justifyContent: 'center',
  },
  stepsContainer: {
    paddingRight: Sizing.x15,
  },
  shortMessageContainer: {
    paddingHorizontal: Sizing.x15,
  },
  progressBar: {
    height: Sizing.x10,
    borderRadius: Sizing.x60,
  },
  descriptionStyle: {
    paddingLeft: Sizing.x0,
    textAlign: 'left'
  }
})