import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminFeatureDataId, setCurrentAdminFeatureDataId } from 'store/uxSlice'
import { AdminFeatureEditScreen } from './Screens/AdminFeatureEditScreen'

export const AdminFeatureEditModal = () => {
  const dispatch = useAppDispatch()
  const currentFeature = useAppSelector(currentAdminFeatureDataId)
  return (
    <ModalFormBuilder
      visible={!!currentFeature}
      formTitle='Feature Management'
      onDismiss={() => dispatch(setCurrentAdminFeatureDataId(undefined))}
      screen={<AdminFeatureEditScreen />}
    />
  )

}

