import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerMemberDataId, setCurrentEmployerMemberDataId } from 'store/uxSlice'
import { EmployerMemberEditScreen } from './Screens/EmployerMemberEditScreen'

export const EmployerMemberEditModal = () => {
  const dispatch = useAppDispatch()
  const currentClient = useAppSelector(currentEmployerMemberDataId)
  return (
    <ModalFormBuilder
      visible={!!currentClient}
      formTitle='Member Details'
      onDismiss={() => dispatch(setCurrentEmployerMemberDataId(undefined))}
      screen={<EmployerMemberEditScreen />}
    />
  )

}

