import { scaleNormalizer } from 'lib/scaleHelpers';
import { TextStyle } from 'react-native';
import { systemWeights } from 'react-native-typography';
import * as Sizing from './sizing';

type Defined =
  | 'headline'
  | 'subheading'
  | 'text'
  | 'paragraph'
  | 'dashboardHero'
  | 'processHeaderTitle'
  | 'modalProcessHeaderTitle'
  | 'radioButtonLabel'
  | 'input'
  | 'inputLabel'
  | 'inputFooter'
  | 'inputLarge' 
  | 'dashboardTabLabel'
  | 'dashboardTabDotText'
  | 'notificationDotText'
  | 'mainAppHeaderTitle'
  | 'mainAppSubHeaderTitle'
  | 'mainAppDrawerGroupTitle'
  | 'mainAppDrawerItem'
  | 'buttonLabel'
  | 'textButtonLabel'
  | 'sectionListHeader'
  
export const defined: Record<Defined, TextStyle> = {
  dashboardHero: {
    fontFamily: 'LabGrotesque-Medium',
    fontWeight: '400',
    fontSize: scaleNormalizer(100),
    textAlign: 'center',
  },
  headline: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(28),
    lineHeight: scaleNormalizer(35),
    letterSpacing: 0,
    textAlign: 'center',
    paddingHorizontal: Sizing.x5,
    paddingVertical: Sizing.x5,
  },
  subheading: {
    fontFamily: 'LabGrotesque-Regular',
    fontSize: scaleNormalizer(16),
    lineHeight: scaleNormalizer(23),
    letterSpacing: 0.2,
    textAlign: 'center',
    paddingHorizontal: Sizing.x5,
    paddingVertical: Sizing.x5,
  },
  text: {
    fontFamily: 'LabGrotesque-Regular',
    fontSize: scaleNormalizer(14),
    lineHeight: scaleNormalizer(18),
    letterSpacing: 0.2,
    textAlign: 'center',
  },
  paragraph: {
    fontFamily: 'LabGrotesque-Regular',
    fontSize: scaleNormalizer(14),
    lineHeight: scaleNormalizer(18),
    letterSpacing: 0.2,
    textAlign: 'center',
    paddingHorizontal: Sizing.x5,
    paddingVertical: Sizing.x5,
  },
  mainAppDrawerGroupTitle: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(13),
    textTransform: 'uppercase',
  },
  mainAppDrawerItem: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(13),
    textTransform: 'uppercase',
  },
  mainAppHeaderTitle: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(15),
    lineHeight: scaleNormalizer(18),
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  mainAppSubHeaderTitle: {
    paddingTop: Sizing.x5,
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(13),
    lineHeight: scaleNormalizer(15),
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  processHeaderTitle: {
    fontFamily: 'LabGrotesque-Bold',
    fontSize: scaleNormalizer(11),
    lineHeight: scaleNormalizer(18),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  modalProcessHeaderTitle: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(16),
    lineHeight: scaleNormalizer(18),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  radioButtonLabel: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(18),
    lineHeight: scaleNormalizer(29),
  },
  input: {
    fontFamily: 'LabGrotesque-Light',
    fontSize: scaleNormalizer(18),
    lineHeight: scaleNormalizer(23),
  },
  inputLabel: {
    fontFamily: 'LabGrotesque-Light',
    fontSize: scaleNormalizer(12),
    lineHeight: scaleNormalizer(12),
  },
  inputFooter: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(11),
    lineHeight: scaleNormalizer(18),
  },
  inputLarge: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(30),
  },
  dashboardTabLabel: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(12),
    textTransform: 'capitalize',
  },
  dashboardTabDotText: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(10),
  },
  notificationDotText: {
    fontFamily: 'LabGrotesque-Medium',
    fontSize: scaleNormalizer(10),
  },
  buttonLabel: {
    fontFamily: 'LabGrotesque-Bold',
    fontSize: scaleNormalizer(15),
    letterSpacing: 0.2,
    textTransform: 'capitalize',
  },
  textButtonLabel: {
    fontFamily: 'LabGrotesque-Regular',
    fontSize: scaleNormalizer(14),
    lineHeight: scaleNormalizer(14),
    letterSpacing: 0.2,
    textAlign: 'center',
    verticalAlign: 'bottom',
  },
  sectionListHeader: {
    fontFamily: 'LabGrotesque-Regular',
    fontSize: scaleNormalizer(16),
    lineHeight: scaleNormalizer(23),
    letterSpacing: 0.2,
    textAlign: 'center',
    paddingHorizontal: Sizing.x5,
    paddingTop: Sizing.x20,
    paddingBottom: Sizing.x5,
    textTransform: 'uppercase',
  },
};

type FontSize = "x10" | "x15" | "x18" | "x20" | "x30" | "x40"
export const fontSize: Record<FontSize, TextStyle> = {
  x10: {
    fontSize: scaleNormalizer(10)
  },
  x15: {
    fontSize: scaleNormalizer(15)
  },
  x18: {
    fontSize: scaleNormalizer(18)
  },
  x20: {
    fontSize: scaleNormalizer(20)
  },
  x30: {
    fontSize: scaleNormalizer(30)
  },
  x40: {
    fontSize: scaleNormalizer(40)
  },
}

type FontWeight = 'regular' | 'semibold' | 'bold';
export const fontWeight: Record<FontWeight, TextStyle> = {
  regular: {
    ...systemWeights.regular
  },
  semibold: {
    ...systemWeights.semibold
  },
  bold: {
    ...systemWeights.bold
  }
}