import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { Loading } from 'components/Utility/Loading'
import { PieChartCategory, PieChartWithLegend } from 'components/Utility/PieChartWithLegend'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { getGroupPension, getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { formatPercentageAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { concat } from 'lodash'
import { OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useGetBeneficiariesQuery, useGetMeQuery, useGetSpouseQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { BeneficiaryRelationship } from 'store/dto/account.dto'
import { useAppDispatch } from 'store/hooks'
import { setAddBeneficiaryData, setAdjustBeneficiaryProportionData, setEditBeneficiaryData } from 'store/uxSlice'
import { Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const LegacyScreen = () => {

  const dispatch = useAppDispatch()

  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading } = useGetUserAccountsQuery()

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const hasAnyPension = !!personalPension || !!groupPension

  const { data: beneficiaries, error: beneficiariesError, isLoading: beneficiariesIsLoading, isFetching: beneficiariesIsFetching, refetch: refetchBeneficiaries } = useGetBeneficiariesQuery(undefined, { skip: !hasAnyPension })
      
  const refetchAll = () => {
    refetchClient()
  }
  
  const isLoading = clientIsLoading || spouseIsLoading || beneficiariesIsLoading || accountsIsLoading
  const error: any = clientError || accountsError || beneficiariesError
  
  const nominations = beneficiaries?.nominations || []

  let tableData: UnborderedTableRow[] = []

  tableData.push({
    label: 'Beneficiary',
    value: 'Proportion',
    isHeader: true,
  })

  tableData = concat(tableData, nominations ? nominations.map((nomination, idx) => {
    const value = formatPercentageAmount(nomination.beneficiaryProportion * 100, 2)

    return {
      label: `${nomination.firstName} ${nomination.beneficiaryRelationship === BeneficiaryRelationship.CHARITY ? '' : nomination.surname}`,
      value,
      isTotal: false,
      linkFunction: () => dispatch(setEditBeneficiaryData({
        editIndex: idx,
        allNominations: nominations,
        currentNomination: nomination,
      }))
    }
  }) : [])

  
  //Add total rows
  tableData.push({
    label: 'Overall',
    value: formatPercentageAmount(100),
    isTotal: true,
  })

  const { colors: themeColors } = Paper.useAppTheme()
  
  const categories: PieChartCategory[] = nominations.map(nomination => {
    const name = `${nomination.firstName} ${nomination.beneficiaryRelationship === BeneficiaryRelationship.CHARITY ? '' : nomination.surname}`
    return {
      name,
      items: [
        {
          x: '',
          y: nomination.beneficiaryProportion,
        }
      ]
    }
  })
  const dataSet = {
    categories,
  }

  const subExplanation = !!groupPension
    ? undefined
    : `You can define beneficiaries once you have verified your identity and opened your ${JAR_NAME_PERSONAL}, or have a ${JAR_NAME_GROUP} by connecting an employer.`

  const onboardingSteps = !!groupPension
    ? [
        OnboardingStateStepIdentifier.BENEFICIARIES,
      ] 
    : [
        OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
        OnboardingStateStepIdentifier.PLAN_CHOICE,
        OnboardingStateStepIdentifier.BENEFICIARIES,
      ]

  return (
    <MainAppScreen>
      {
        isLoading
        ? <Loading message={getLookingLoadingMessages('Loading your lifestyle...')} />
        : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
        <OnboardingGuard
          explanation={`Your Legacy screen will show you the benficiaries you have nominated to receive any remaining assets in your ${JAR_NAME_ALL}`}
          subExplanation={subExplanation}
          onboardingSteps={onboardingSteps}
        >
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.pageContainer,
            ]} >
            <RefreshableScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
              refreshFunctions={[
                refetchBeneficiaries,
              ]}
            >
              {
                client
                  ? <>
                      <Subheading style={Typography.defined.sectionListHeader}>{'Life Expectancy'} </Subheading>
                      {
                        spouse
                          ? <Paragraph>{`Considering both your and ${spouse ? `${spouse?.firstName}'s` : `your partner's`} age and gender, your combined life expectancy is around:`}</Paragraph>
                          : <Paragraph>{`Considering your age and gender, your life expectancy is around:`}</Paragraph>
                      }
                      
                      <View style={{ paddingTop: Sizing.x10 }}>
                        <Paragraph
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={{
                            fontFamily: 'LabGrotesque-Medium',
                            fontWeight: '400',
                            lineHeight: scaleNormalizer(100),
                            fontSize: scaleNormalizer(100),
                            textAlign: 'center',
                          }}>
                            {client?.lastSurvivorDeathAge}
                        </Paragraph>
                      </View>
                      {
                        spouse
                          ? <NamedInformationButton name={NamedInformation.COMBINED_LIFE_EXPECTANCY} buttonTitle={'What does this mean?'} />
                          : <NamedInformationButton name={NamedInformation.LIFE_EXPECTANCY} buttonTitle={'What does this mean?'} />
                      }
                      <ContentDivider />
                    </>
                  : <></>
              }
              {
                beneficiaries
                ? <>
                    <Subheading style={Typography.defined.sectionListHeader}>{'Your Beneficiaries'} </Subheading>
                    <Paragraph>{
                      nominations?.length
                        ? `You currently have ${nominations?.length} defined ${nominations?.length === 1 ? 'beneficiary' : 'beneficiaries'}`
                        : `You don't yet have any beneficiaries defined`
                    }
                    </Paragraph>
                    {
                      nominations?.length
                        ? <UnborderedTable
                            isLoading={beneficiariesIsLoading}
                            error={error}
                            data={tableData}
                          />
                        : <></>
                    }
                    {!nominations || nominations?.length < 4 ?
                      <Button
                        mode={'text'}                        
                        color={themeColors.accent}
                        onPress={() => dispatch(setAddBeneficiaryData({ allNominations: nominations }))}>
                          {`Add Beneficiary`}
                        </Button>
                      : <Paragraph>{`You cannot add more than 4 beneficiaries`}</Paragraph>
                    }
                    <ContentDivider />

                    {
                      nominations?.length > 1
                        ? <>
                            <Subheading style={Typography.defined.sectionListHeader}>{'Beneficiary Breakdown'} </Subheading>
                            <View style={{ marginLeft: -Sizing.x30 }}>
                              <PieChartWithLegend dataSet={dataSet} />
                            </View>
                            <View style={{ paddingHorizontal: Sizing.x30 }}>
                              {
                                nominations && nominations?.length > 1 ?
                                  <Button
                                    mode={'text'}
                                    color={themeColors.accent}
                                    onPress={() => dispatch(setAdjustBeneficiaryProportionData({ nominations }))}
                                  >
                                    {`Adjust Proportions`}
                                  </Button>
                                  : <></>
                              }
                              {/* <Paragraph>{`Estimated Surplus Capital: ${formatCurrencyAmount(surplusAmount)}`}</Paragraph> */}
                            </View>
                            <ContentDivider />
                          </>
                        : <></>
                    }
                  </>
                : <></>
              }
            </RefreshableScrollView>
          </View>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})