import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingPreviousPension, workingPreviousPension } from 'store/tempDataSlice'

export const SchemeEnrolmentPreviousPensionAdd_99_Result = ({ navigation, route }) => {
  const { onDismiss, onComplete, client }  = route?.params || {}
  const dispatch = useAppDispatch()

  const workingPreviousPensionData = useAppSelector(workingPreviousPension)

  const close = () => {
    dispatch(updateWorkingPreviousPension(undefined))
    onDismiss()
  }

  //Call onComplete on enter and then close
  useEffect(() => {
    onComplete(workingPreviousPensionData)
    close()
  }, [])

  return (
    <ModalProcessScreen
      buttonTitle={'Continue'}
      errorCancel={onDismiss}
      buttonAction={close}
      showButton={false}
    >
    </ModalProcessScreen>
  )
}
