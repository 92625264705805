import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminBankConnectionDataId, setCurrentAdminBankConnectionDataId } from 'store/uxSlice'
import { AdminEditBankConnectionScreen } from './Screens/AdminEditBankConnectionScreen'

export const AdminEditBankConnectionsModal = () => {
  const dispatch = useAppDispatch()
  const currentBankConnection = useAppSelector(currentAdminBankConnectionDataId)
  return (
    <ModalFormBuilder
      visible={!!currentBankConnection}
      formTitle='Edit Bank Connection Details'
      onDismiss={() => dispatch(setCurrentAdminBankConnectionDataId(undefined))}
      screen={<AdminEditBankConnectionScreen />}
    />
  )

}

