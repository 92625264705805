import { ManagedSimpleChoiceInput, ManagedSimpleChoiceItem } from 'components/Inputs/ManagedSimpleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { SpouseAddProcessStack } from 'features/ModalProcesses/SpouseAdd/SpouseAddProcessStack'
import { SpouseEditModal } from 'features/ModalProcesses/SpouseEdit/SpouseEditModal'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetSpouseQuery } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementProfile, workingRetirementProfile } from 'store/tempDataSlice'
import { Paper } from 'styles'

export const RetirementProfileSetup_02_SpouseChoice = ({ route, navigation }) => {
  const { nextScreen } =  route?.params || {}
  const [addSpouse, setAddSpouse] = useState(false)  
  const [editSpouse, setEditSpouse] = useState(false)
  
  const dispatch = useAppDispatch()

  // const disableHeader = addSpouse || editSpouse
  // dispatch(enableHeader(!disableHeader))
  
  //Temp state
  const workingRetirementProfileData = useAppSelector(workingRetirementProfile)

  const { data: spouse, isLoading: spouseIsLoading, refetch } = useGetSpouseQuery()

  //Setup form
  const formObj = useForm<{ asCouple: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      asCouple: workingRetirementProfileData?.asCouple || spouse ? true : undefined
    },
  })
  const { handleSubmit, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    if (attributes.asCouple && !spouse) {
      setAddSpouse(true)
    } else {
      dispatch(updateWorkingRetirementProfile(attributes))
      navigation.navigate(nextScreen)
    }
  }

  const spouseOptions: ManagedSimpleChoiceItem[] = [
    {
      value: false,
      label: 'On your own'
    },
    {
      value: true,
      label: spouse ? `With ${spouse?.firstName}` : 'With a partner',
    },
  ]

  return (
    <>
      {
        addSpouse ?
        <SpouseAddProcessStack
          visible={addSpouse}
          onDismiss={() => setAddSpouse(false)}
          theme={Paper.darkThemeOnLightGrey}
        />
        : editSpouse ?
        <SpouseEditModal
          visible={editSpouse}
          onDismiss={() => setEditSpouse(false)}
          theme={Paper.darkThemeOnLightGrey}
        />
        :
        <ProcessScreen
          isLoading={spouseIsLoading}
          errorTryAgain={refetch}
          buttonTitle={'Next'}
          buttonAction={handleSubmit(onSubmit)}
          enableButton={isValid}
          allowTextButton={!!spouse}
          textButtonTitle={`Change ${spouse?.firstName}`}
          textButtonAction={() => setEditSpouse(true)}
          headline={`With whom will you be retiring?`}
          subHeading={`Retiring alone or with someone else affects the kind of budget you'll need`}
        >
          <ManagedSimpleChoiceInput
            name={'asCouple'}
            formObj={formObj}
            options={spouseOptions}
            submitHandler={handleSubmit(onSubmit)}
            required={true}
          />    
        </ProcessScreen>
      }
    </>
  )
}
