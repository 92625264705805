import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerJobDataId, setCurrentEmployerJobDataId } from 'store/uxSlice'
import { EmployerJobEditScreen } from './Screens/EmployerJobEditScreen'

export const EmployerJobEditModal = () => {
  const dispatch = useAppDispatch()
  const currentClient = useAppSelector(currentEmployerJobDataId)
  return (
    <ModalFormBuilder
      visible={!!currentClient}
      formTitle='Upload Details'
      onDismiss={() => dispatch(setCurrentEmployerJobDataId(undefined))}
      screen={<EmployerJobEditScreen />}
    />
  )

}

