import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminUserDataId, setCurrentAdminUserDataId } from 'store/uxSlice'
import { AdminEditUserScreen } from './Screens/AdminEditUserScreen'

export const AdminEditUsersModal = () => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(currentAdminUserDataId)
  return (
    <ModalFormBuilder
      visible={!!currentUser}
      formTitle='Edit User Details'
      onDismiss={() => dispatch(setCurrentAdminUserDataId(undefined))}
      screen={<AdminEditUserScreen />}
    />
  )

}

