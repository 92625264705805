import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminGroupSchemeDataId, setCurrentAdminGroupSchemeDataId } from 'store/uxSlice'
import { AdminEditGroupSchemeScreen } from './Screens/AdminEditGroupSchemeScreen'

export const AdminEditGroupSchemesModal = () => {
  const dispatch = useAppDispatch()
  const currentGroupScheme = useAppSelector(currentAdminGroupSchemeDataId)
  return (
    <ModalFormBuilder
      visible={!!currentGroupScheme}
      formTitle='Edit Group Scheme Details'
      onDismiss={() => dispatch(setCurrentAdminGroupSchemeDataId(undefined))}
      screen={<AdminEditGroupSchemeScreen />}
    />
  )

}

