import { DetailedListItem } from 'components/Utility/DetailedListItem'
import React from 'react'
import { Image } from 'react-native'
import { BankConnectionDto } from 'store/dto/bank-connection.dto'
import { Paper, Sizing } from 'styles'

type AdminBankConnectionListItemProp = {
  bank: BankConnectionDto
  onPressFunction?: () => void
}
export const AdminBankConnectionListItem = (props: AdminBankConnectionListItemProp) => {
  const { bank, onPressFunction } = props
  const { name, logo, description, moneyHubId } = bank

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = moneyHubId
  const subTitle = description
  
  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={name}
      titleRight={titleRight}
      subTitle={subTitle}
      titleStyle={{ color: themeColors.primary }}
      subTitleStyle={{ color: themeColors.primary }}
      titleRightStyle={{ color: themeColors.primary }}
    />
  )
}
