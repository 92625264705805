import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowEmployerAddEnrolmentJobVisible, showEmployerAddEnrolmentJobVisible } from 'store/uxSlice'
import { EmployerAddEnrolmentJobScreen } from './Screens/EmployerAddEnrolmentJobScreen'

export const EmployerAddEnrolmentJobModal = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showEmployerAddEnrolmentJobVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Member'
      onDismiss={() => dispatch(setShowEmployerAddEnrolmentJobVisible(false))}
      screen={<EmployerAddEnrolmentJobScreen />}
    />
  )
}

