import { ModalProcessIntroScreen } from 'components/Layout/ModalProcessIntroScreen'
import React from 'react'
import { GroupSchemeEnrolmentDto, GroupSchemeRequestType, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'

export const SchemeEnrolmentChangeStatusRequestAdd_00_Intro = ({ route, navigation }) => {
  const { nextScreen, onDismiss, enrolment, requestType }: { nextScreen: string, onDismiss: any, enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto, requestType: GroupSchemeRequestType }  = route?.params || {}

  const next = () => {
     navigation.navigate(nextScreen)
  } 
  
  return (
    <ModalProcessIntroScreen
      onDismiss={onDismiss}
      buttonTitle={'Start'}
      buttonAction={next}
      showButton={true}
      illustrationFilename={'envelope_with_star.png'}
      headline={requestType === GroupSchemeRequestType.OPT_IN
        ? 'Request Opt In'
        : requestType === GroupSchemeRequestType.OPT_OUT
        ? 'Request Opt Out'
        : 'Request to Leave Scheme'
      }
      subHeading={requestType === GroupSchemeRequestType.OPT_IN
        ? `Make a request to your employer to opt back in to their pension scheme`
        : requestType === GroupSchemeRequestType.OPT_OUT
        ? `Make a request to your employer to opt out of their workplace pension scheme`
        : `Make a request to your employer to leave their workplace pension scheme`
      }
    >
    </ModalProcessIntroScreen>
  )
}
