import { useFocusEffect } from '@react-navigation/native'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Subheading } from 'components/Typography'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { envVariables } from 'environment'
import { goToLogout, rootNavigate } from 'lib/RootNavigation'
import { setUserWithToken } from 'lib/authHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import { getEntryScreenForAppContext } from 'lib/navigationHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import Auth0 from 'platform-lib/auth0'
import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'store/authSlice'
import { Flex, Paper } from 'styles'
import { layoutStyles } from 'styles/common'
import { AppIllustration } from 'components/Utility/AppIllustration'

const isWeb = platformIsWeb()
const useAuth0 = Auth0.useAuth0

export const AuthenticationFederatedLoginScreen = ({ route, navigation }) => {
  // Logger.info(`########## Federated login...`)

  const dispatch = useDispatch()
  const { auth0Audience, auth0Scopes, appUrl } = envVariables
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const [loginTimedOut, setLoginTimedOut] = useState<boolean>(false)

  //Hook to reset on focus
  useFocusEffect(() => {
    if (!loginTimedOut) {
        setTimeout(() => timeoutLogin(), 5000)
    }
  })

  const timeoutLogin = () => {
    Logger.error(`Login timed out!`)
    setLoginTimedOut(true)
  }

  const appContext = useSelector((state: any) => state.auth.appContext)

  const { colors: themeColors } = Paper.useAppTheme()

  //Handle change in isAuthenticated
  useEffect(() => {
    Logger.info({ isAuthenticated }, `Detected change in isAuthenticated`)
    if (isWeb && isAuthenticated) {
      Logger.info(`(Web) change in isAuthenticated - logging in...`)
      dispatch(
        setUser({
          localToken: true,
          loginMethod: undefined,
          data: null,
          loggingIn: true,
          token: null,
          refreshToken: null,
          error: `Logging in...`,
          impersonation: null,
        })
      ) 
      const getAccessToken = async () => {
        await getAccessTokenSilently({
          audience: auth0Audience,
          redirectUri: isWeb ? appUrl : undefined,
          scope: auth0Scopes,
        }).then(async accessToken => {
          Logger.info(`Got token (web) - setting user...`)
          const tokenSet = {
            accessToken,
            refreshToken: null, //We are not given this from getAccessTokenSilently
          }
          await setUserWithToken(tokenSet, 'browser-session', dispatch)
        }).catch(e => {
          Logger.error(`Error getting token`, e)
        })
      }
      getAccessToken()
    } else {
      const entryScreen = getEntryScreenForAppContext(appContext)
      rootNavigate(entryScreen)
    }
  }, [isAuthenticated])

  return (
    <BackgroundGradientPurple>
      <SafeAreaView style={layoutStyles.mainContentContainer} >
        <View style={{
          ...Flex.override.fill,
          ...Flex.column.center,
          flexGrow: 1,
        }}>
          {
            !loginTimedOut ? <Loading useHolidayGraphics={true} />
            : 
              <>
                  <AppIllustration
                    filename={'magnifying_glass.png'}
                    style={{
                      alignSelf: 'center', 
                      width: scaleNormalizer(200),
                      height: scaleNormalizer(200),
                    }}
                    resizeMode={'contain'}
                  />
                  <Subheading>{`Sorry, we could not log you in.`}
                  </Subheading>
                  <FooterButton
                    mode='text'
                    color={isWeb ? undefined : themeColors.accent}
                    onPress={goToLogout}>
                    {'Return to Login'}
                  </FooterButton>

              </>
          }
        </View>
      </SafeAreaView>
    </BackgroundGradientPurple>
  )
}
