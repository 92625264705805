import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddAffiliateVisible, showAdminAddAffiliateVisible } from 'store/uxSlice'
import { AddAffiliatesScreen } from './Screens/AddAffiliatesScreen'

export const AddAffiliatesModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddAffiliateVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Affiliate'
      onDismiss={() => dispatch(setShowAdminAddAffiliateVisible(false))}
      screen={<AddAffiliatesScreen />}
    />
  )
}

