import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { BudgetDto } from 'store/dto/reference-data.dto'
import { RetirementBudgetCategoryEditScreen } from './Screens/RetirementBudgetCategoryEditScreen'

type RetirementBudgetCategoryEditModalProps = {
  formObj: UseFormReturn<any>
  budget: BudgetDto
  onDismiss: any
  theme?: any
}
export const RetirementBudgetCategoryEditModal = (props: RetirementBudgetCategoryEditModalProps) => {
  const { formObj, budget, onDismiss } = props

  return (
    <ModalFormBuilder
      theme={props.theme}
      visible={!!budget}
      formTitle={budget.name}
      onDismiss={onDismiss}
      screen={<RetirementBudgetCategoryEditScreen formObj={formObj} budget={budget} onDismiss={onDismiss} />}
    />
  )
}
