import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowEmployerImportInvitesVisible, showEmployerImportInvitesVisible } from 'store/uxSlice'
import { EmployerImportInvitesScreen } from './Screens/EmployerImportInvitesScreen'

export const EmployerImportInvitesModal = () => {
    const dispatch = useAppDispatch()
    const visible = useAppSelector(showEmployerImportInvitesVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Bulk Import Invites'
      onDismiss={() => dispatch(setShowEmployerImportInvitesVisible(false))}
      screen={<EmployerImportInvitesScreen />}
    />
  )
}

