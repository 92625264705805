import { platformIsWeb } from 'lib/platformHelpers'
import React, { ComponentProps, useState } from 'react'
import { Image, Linking, TouchableOpacity } from 'react-native'
import { Paper } from 'styles'
import { ExternalLinkModal } from './ExternalLinkModal'

type ExternalLinkImageProps = ComponentProps<typeof Image> & {
  url: string
  directToBrowser?: boolean
  usePdfViewer?: boolean
  useGoogleReader?: boolean
}
export const ExternalLinkImage = (props: ExternalLinkImageProps) => {
  const { url, directToBrowser, usePdfViewer, useGoogleReader, style, resizeMode, source } = props
  const [externalVisible, setExternalVisible] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const openWebView = () => {
    setExternalVisible(true)
  }

  const closeWebView = () => {
    setExternalVisible(false)
  }

  const openInBrowser = () => { 
    if (isWeb) {
      window.open(url)
    } else {
      closeWebView()
      Linking.openURL(url)
    }
  }

  const isWeb = platformIsWeb()

  return (
    
    <>
      <TouchableOpacity
        onPress={isWeb || directToBrowser  ? openInBrowser : openWebView}
      >
        <Image
          style={style}
          resizeMode={resizeMode}
          source={source}
        />
      </TouchableOpacity>
      {externalVisible && 
        <ExternalLinkModal
          url={url}
          onDismiss={closeWebView}
          usePdfViewer={usePdfViewer}
          useGoogleReader={useGoogleReader}
        />        
      }
    </>
  )
}