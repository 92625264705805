import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Subheading } from 'components/Typography'
import { BulletItem } from 'components/Typography/BulletItem'
import { addYears, format, startOfYear } from 'date-fns'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import React from 'react'
import { useForm } from 'react-hook-form'
import { ClientMeDto } from 'store/dto/client.dto'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { max } from 'lodash'
import { View } from 'react-native'
import { Colors, Sizing } from 'styles'

const MIN_START_YEAR = 1990
const MIN_CLIENT_AGE = 18
export const RetirementAssetTransfer_33_TracingEmployerStartDate = ({ route, navigation }) => {
  const { nextScreen, asset, client }: { nextScreen: string, asset: RetirementAssetDto, client: ClientMeDto }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const client18BirthdayYear = parseInt(format(startOfYear(addYears(new Date(client?.birthDate), MIN_CLIENT_AGE)), 'yyyy'))
  const startYear = max([client18BirthdayYear, MIN_START_YEAR])


  //Build autocomplete options
  const yearOptions: AutoCompleteItem[] = []
  const currentYear = parseInt(format(new Date(), 'yyyy'))
  for (let i = startYear; i <= currentYear; i++) {
    yearOptions.push({
      value: i,
      label: i.toString(),
    })
  }

  const bestStartDate = workingRetirementAssetData?.employerStartDate || asset?.employerStartDate

  //Setup form
  const formObj = useForm<{ employerStartDate: number }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      employerStartDate: bestStartDate ? parseInt(format(new Date(bestStartDate), 'yyyy')) : undefined,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      employerStartDate: `${attributes?.employerStartDate}-01-01`,
      tracingRequested: true
    }))
    navigation.navigate(nextScreen)
  }

  const employerStartDate = watch('employerStartDate')
  const ageAtStartDate = employerStartDate ? calculateAgeAtDate(`${employerStartDate}-01-01`, client?.birthDate) : 0

  return (
    <ModalProcessScreen
      buttonTitle={`Next`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={`When did you start your employment?`}
      subHeading={`The approximate year you began working for ${asset?.employerName ? asset?.employerName : 'them'}`}
    >
      <ManagedAutoCompleteInput
        name={'employerStartDate'}
        placeholder={'Tap to enter year...'}
        formObj={formObj}
        modalTitle={'Enter Start Year'}
        required={true}
        dataSet={yearOptions}
        informationMessage={employerStartDate
          ? `When you were ${ageAtStartDate} or ${ageAtStartDate + 1} years old`
          : undefined
        }
      />
      <View style={{ paddingTop: Sizing.x10 }}>
        <Subheading style={{ textAlign: 'left', color: Colors.brand.purple1 }}>{`We can find pensions where:`}</Subheading>
        <BulletItem style={{ textAlign: 'left' }}>{`Your employment began during or after ${MIN_START_YEAR}`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`Your employment has now ceased`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`You were at least 22 years old when your employment ceased`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`You were employed for more than 3 months`}</BulletItem>
        <Subheading style={{ textAlign: 'left', color: Colors.brand.purple1 }}>{`We are unable to find pensions where:`}</Subheading>
        <BulletItem style={{ textAlign: 'left' }}>{`Your employment was Public Sector`}</BulletItem>
        <BulletItem style={{ textAlign: 'left' }}>{`You worked there for less than 2 years before the year 2000`}</BulletItem>
      </View>
    </ModalProcessScreen>
  )
}
