import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerPaymentDataId, setCurrentEmployerPaymentDataId } from 'store/uxSlice'
import { EmployerPaymentEditScreen } from './Screens/EmployerPaymentEditScreen'

export const EmployerPaymentEditModal = () => {
  const dispatch = useAppDispatch()
  const currentClient = useAppSelector(currentEmployerPaymentDataId)
  return (
    <ModalFormBuilder
      visible={!!currentClient}
      formTitle='Payment Details'
      onDismiss={() => dispatch(setCurrentEmployerPaymentDataId(undefined))}
      screen={<EmployerPaymentEditScreen />}
    />
  )

}

