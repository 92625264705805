import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { PensionProviderModalContent, PensionProviderModalContentSection } from 'components/Utility/PensionProviderModalContent'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetRetirementAssetQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Sizing } from 'styles'
import { ProviderInfoButton } from '../Components/ProviderInfoButton'

export const RetirementAssetTransfer_32_TracingReference = ({ route, navigation }) => {
  const { nextScreen, asset }: { nextScreen: string, asset: RetirementAssetDto }  = route?.params || {}
  
  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching } = useGetRetirementAssetQuery(asset.id)
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()

  const [updateAssetReference, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()

  const error = latestAssetError || brandsError || providersError || updateAssetError
  const isLoading = latestAssetIsLoading || latestAssetIsFetching || brandsIsLoading || providersIsLoading || updateAssetIsLoading

  const alreadyHadReference = latestAsset?.externalProviderReference

  const assetProvider = providers && latestAsset ? providers.find(provider => {
    return provider.id === latestAsset?.pensionProviderId
  }) : undefined

  const assetProviderPrimaryBrand = assetProvider && brands ? brands.find(brand => {
    return brand.key === assetProvider.primaryBrandKey
  }) : undefined

  const providerImageSource = assetProviderPrimaryBrand?.logo ? { uri: assetProviderPrimaryBrand?.logo } : require('assets/icons/default_provider.png')

  const referenceFormatInformation = assetProvider?.referenceFormatInformation
  const noFormatInformation = isEmpty(referenceFormatInformation)

  const referenceSummary: string[] = referenceFormatInformation?.summary ? referenceFormatInformation?.summary.split('|') : []
  const singleLineSummary = referenceSummary.length === 1

  //Setup form
  const formObj = useForm<{ externalProviderReference: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      externalProviderReference: workingRetirementAssetData?.externalProviderReference || asset?.externalProviderReference,
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: true
    }))
    //Skip save if no changed...
    if (asset?.externalProviderReference === attributes?.externalProviderReference) {
      end()
    } else {
      Logger.info(`Updating asset reference...`)
      updateAssetReference({
        id: asset?.id,
        externalProviderReference: attributes?.externalProviderReference,
      })
    }
  }

  //Navigate on save result
  useEffect(() => {
    if (updatedAsset) {
      end()
    }
  }, [updatedAsset])

  const handleSkip = () => {
    dispatch(updateWorkingRetirementAsset({
      tracingRequested: true
    }))
    end()
  }

  const end = () => {
    navigation.navigate('Result')
  }

  const externalProviderReference = watch('externalProviderReference')
  
  return (
    <ModalProcessScreen
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : undefined}
      error={error}
      errorTryAgain={refetchProviders}
      buttonTitle={alreadyHadReference
        ? `Confirm & Finish`
        : `Finish`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={isValid}
      headline={alreadyHadReference
          ? 'Is your pension reference correct?'
          : `Do you know your pension reference?`
      }
      subHeading={`This could help speed up the search for your pension`
      }
      allowTextButton={!externalProviderReference}
      textButtonTitle={`I don't know it`}
      textButtonAction={handleSkip}
    >
      <ManagedTextInput
        name={'externalProviderReference'}
        autoFocus={false}
        formObj={formObj}
        returnKeyType={'next'}
        placeholder={'Enter reference'}
        autoCapitalize={'characters'}
        blurOnSubmit={true}
        informationMessage={noFormatInformation
          ? `Typically 6-16 characters long, and could contain numbers and/or letters, sometimes with separating dashes or slashes`
          : singleLineSummary
            ? referenceSummary[0]
            : `See below for typical formats`
        }
        rules={{
          required: true,
          minLength: 2,
          maxLength: 40,
      }} />
      {
        assetProvider ?
          <View style={{ paddingTop: Sizing.x20 }}>
            <ProviderInfoButton
              modalContent={<PensionProviderModalContent
                provider={assetProvider}
                imageSource={providerImageSource}
                sections={[
                  PensionProviderModalContentSection.REFERENCE,
                  PensionProviderModalContentSection.CONTACT,
                  PensionProviderModalContentSection.GENERAL,
                ]}
              />}
            >{`Reference & Contact Details`}</ProviderInfoButton>
            <Paragraph>{`You can also check any recent emails or documents for your reference number, or contact the provider directly.`}</Paragraph>
            <Image source={providerImageSource} style={{
              width: scaleNormalizer(70),
              height: scaleNormalizer(70),
              resizeMode: 'contain',
              alignSelf: 'center',
            }} />
          </View>
          : <></>
      }
    </ModalProcessScreen>
  )
}
