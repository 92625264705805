import { MaterialCommunityIcons } from '@expo/vector-icons'
import { platformIsWeb } from 'lib/platformHelpers'
import React, { ComponentProps, useState } from 'react'
import { Linking } from 'react-native'
import { Sizing, Paper } from 'styles'
import { Button } from './Button'
import { ExternalLinkModal } from './ExternalLinkModal'
import { Logger } from 'lib/logger'
import axios from 'axios'
import fileDownload from 'js-file-download'

type ExternalLinkButtonProps = ComponentProps<typeof Button> & {
  url: string
  directToBrowser?: boolean
  usePdfViewer?: boolean
  useGoogleReader?: boolean
  iconName?: any
  iconColor?: string
  downloadAsFile?: boolean
  downloadFilename?: string
}


export const ExternalLinkButton = (props: ExternalLinkButtonProps) => {
  const { url, children, directToBrowser, usePdfViewer, useGoogleReader, iconName, iconColor, downloadAsFile, downloadFilename } = props
  const [externalVisible, setExternalVisible] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()

  const openWebView = () => {
    setExternalVisible(true)
  }

  const closeWebView = () => {
    setExternalVisible(false)
  }

  const openInBrowser = () => {
    if (isWeb) {
      if (downloadAsFile) {
        axios.get(url, {
          responseType: 'blob',
        }).then(res => {
          fileDownload(res.data, downloadFilename || 'download')
        })
      } else {
        window.open(url)
      }
    } else {
      closeWebView()
      Linking.openURL(url)
    }
  }

  const labelStyle = props?.labelStyle || {}

  const isWeb = platformIsWeb()
  return (
    <>
      <Button
        {...props}
        labelStyle={[
          {
            textDecorationLine: 'underline',
          },
          labelStyle,
        ]}
        mode='text'
        icon={() => <MaterialCommunityIcons name={iconName ? iconName : 'link-variant'} size={Sizing.x20} color={iconColor ? iconColor : themeColors.accent} />}
        onPress={isWeb || directToBrowser ? openInBrowser : openWebView}
      >{children}</Button>
      {externalVisible && 
        <ExternalLinkModal
          url={url}
          onDismiss={closeWebView}
          usePdfViewer={usePdfViewer}
          useGoogleReader={useGoogleReader}
        />
      }
    </>
  )
}