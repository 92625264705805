import { DetailedListItem } from 'components/Utility/DetailedListItem'
import { formatUkDate } from 'lib/dateHelpers'
import React from 'react'
import { Image } from 'react-native'
import { GroupSchemeEnrolmentDto, GroupSchemeEnrolmentStatus, PublicPendingSchemeEnrolmentDto } from 'store/dto/account.dto'
import { Paper, Sizing } from 'styles'

type EnrolmentListItemProp = {
  enrolment: GroupSchemeEnrolmentDto | PublicPendingSchemeEnrolmentDto
  onPressFunction?: () => void
}
export const EnrolmentListItem = (props: EnrolmentListItemProp) => {
  const { enrolment, onPressFunction } = props
  
  const {
    groupScheme,
    isVerified,
    enrolmentStatus,
    employmentStartDate,
    enrolmentDate,
    enrolmentEndDate,
    autoEnrolmentOptOutDate,
  } = enrolment || {}
  const { organizationDisplayName, logo } = groupScheme || {}

  const { colors: themeColors } = Paper.useAppTheme()

  const titleRight = enrolmentStatus

  const subTitle =
    !isVerified ? `Requires Set up`
    : enrolmentStatus === GroupSchemeEnrolmentStatus.INACTIVE
      ? enrolmentEndDate
        ? `Left scheme on: ${formatUkDate(new Date(enrolmentEndDate))}`
        : `Previously left scheme`
    : enrolmentStatus === GroupSchemeEnrolmentStatus.OPTED_OUT
      ? autoEnrolmentOptOutDate
        ? `Opted out on: ${formatUkDate(new Date(enrolmentEndDate))}`
        : `Previously opted out`
    : enrolmentDate ? `Enrolled: ${formatUkDate(new Date(enrolmentDate))}`
    : employmentStartDate ? `Started: ${formatUkDate(new Date(employmentStartDate))}`
    : `Previously enrolled`

  const style = !isVerified
    ? { color: themeColors.accent }
    : enrolmentStatus !== GroupSchemeEnrolmentStatus.ACTIVE
    ? { color: themeColors.disabled }
    : undefined

  return (
    <DetailedListItem
      icon={<Image source={{ uri: logo }} style={{
        width: Sizing.x25,
        height: Sizing.x25,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />}
      onPress={onPressFunction}
      title={organizationDisplayName}
      titleStyle={style}
      subTitleStyle={style}
      titleRight={titleRight}
      titleRightStyle={style}
      subTitle={subTitle}
    />
  )
}
