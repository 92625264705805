import { LinearGradient } from 'expo-linear-gradient'
import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { Sizing } from 'styles'
import { applyOpacity } from 'styles/colors'

export const ScreenScrollFader = (props: { backgroundColor: string, bottomEnd?: boolean }) => {
  const { backgroundColor, bottomEnd } = props

  //Not working for web - stubbed out for now
  const isWeb = platformIsWeb()

  const colors = isWeb ? [] : bottomEnd
  ? [
    applyOpacity(backgroundColor, 0),
    backgroundColor,
  ] :
  [
    backgroundColor,
    applyOpacity(backgroundColor, 0),
  ]

  return (
    <>
      { isWeb ? <></> :
        <LinearGradient style={{
          height: Sizing.x10,
          width: '100%',
          zIndex: 20000,
          top: bottomEnd ? undefined : 0,
          bottom: bottomEnd ? 0 : undefined,
          alignSelf: bottomEnd ? 'flex-end' : undefined,
          left: 0,
          position: 'absolute',
        }}
          colors={colors}
          locations={[0, 1]}
        />
      }
    </>
  )
}
