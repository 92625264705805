import { ManagedAddressInput } from 'components/Inputs/ManagedAddressInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { Address } from 'store/dto/base.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'

export const RetirementAssetTransfer_22_PersonalDetailsTransferAddress = ({ route, navigation }) => {
  const { nextScreen, asset }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()
  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: user, isLoading, error, refetch } = useGetCurrentUserQuery()

  //Setup form
  const formObj = useForm<{ transferAddress: Address }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      transferAddress: workingRetirementAssetData?.transferAddress
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid} } = formObj

  const handleEnterManually=()=>{
    navigation.navigate(nextScreen, {
      manualEntry: true
    })

  }
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({
      ...attributes,
      tracingRequested: true,
    }))
    navigation.navigate(nextScreen)
  }

  //Function for ManagedAddressInput to use to call onSubmit without params
  const onSelect = () => {
    onSubmit(watch())
  }  


  return (
    <ModalProcessScreen
      isLoading={isLoading}
      error={error}
      errorTryAgain={refetch}
      buttonTitle={`Next`}
      buttonAction={handleSubmit(onSubmit)}
      headline={`Ok - what was your address?`}
      subHeading={`This should be the UK address the old provider has on their records`}
      allowTextButton={true}
      textButtonTitle={'Enter Manually'}
      textButtonAction={handleEnterManually}
    >
      <ManagedAddressInput
        onSelect={onSelect}
        googlePlacesApiKey={user?.gpak}
        formObj={formObj}
        autoFocus={true}
        name={'transferAddress'}
        countryRestriction={'gb'}
        required={true}
      />
    </ModalProcessScreen>
  )
}
