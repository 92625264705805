import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ManagedCardChoiceInput, ManagedCardChoiceInputOption } from 'components/Inputs/ManagedCardChoiceInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { PensionProviderModalContent } from 'components/Utility/PensionProviderModalContent'
import { Logger } from 'lib/logger'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useGetPensionBrandsQuery, useGetRetirementAssetQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { PensionProviderDto } from 'store/dto/pension-provider.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Colors, Sizing } from 'styles'

export const RetirementAssetTransfer_03_ProviderSuggestions = ({ route, navigation }) => {
  const { nextScreen, client, asset }  = route?.params || {}
  
  let options: ManagedCardChoiceInputOption[] = []

  //Temp state
  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)
  const { suggestedProviders } = workingRetirementAssetData || {}

  const singleSuggestion = suggestedProviders ? suggestedProviders.length === 1 : false  

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const [updateAssetProvider, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()

  const error = latestAssetError || brandsError  || updateAssetError
  const isLoading = latestAssetIsLoading || latestAssetIsFetching || brandsIsLoading || updateAssetIsLoading

  //Setup form
  const formObj = useForm<{ pensionProviderId: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionProviderId: workingRetirementAssetData?.pensionProviderId
        ? workingRetirementAssetData?.pensionProviderId
        : singleSuggestion
          ? suggestedProviders[0].id
          : asset?.pensionProviderId,
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    dispatch(updateWorkingRetirementAsset({...
      attributes,
      tracingRequested: false,
    }))
    //Skip save if no changed...
    if (asset?.pensionProviderId === attributes?.pensionProviderId) {
      end()
    } else {
      Logger.info(`Updating asset provider (from suggestion)...`)
      updateAssetProvider({
        id: asset?.id,
        tracingRequested: false,
        pensionProviderId: attributes?.pensionProviderId,
      })
    }
  }

  //Navigate on finish
  useEffect(() => {
    if (updatedAsset) {
      end()
    }
  }, [updatedAsset])

  const end = () => {
    navigation.navigate('ReferenceIntro')
  }

  const goToAllProviders = () => {
    navigation.navigate(nextScreen, { fromSuggestions: true })
  }

  const buildOptions = (providers: PensionProviderDto[]) => {
    options = providers.map(provider => {

      const providerBrand = brands.find(brand => {
        return brand.key === provider.primaryBrandKey
      })

      const imageSource = providerBrand?.logo ? { uri: providerBrand?.logo } : require('assets/icons/default_provider.png')

      return {
        value: provider.id,
        title: provider.name,
        description: provider.description,
        icon: () => { return <MaterialCommunityIcons name={'bank-outline'} size={Sizing.x40} color={Colors.neutral.white}/> },
        imageSource,
        modalContentFunction: () => <PensionProviderModalContent provider={provider} imageSource={imageSource} />,
        modalProceedLabel: 'Select Provider',
        modalCancelLabel: 'Choose Another',
      }
    })
  }

  //Extract providers when available
  if (latestAsset) {
    buildOptions(suggestedProviders ? suggestedProviders : [])
  }

  const pensionProviderId = watch('pensionProviderId')

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={!latestAsset ? refetchLatestAsset : !brands ? refetchBrands : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : ['Finding providers...']}
      buttonTitle={singleSuggestion ? 'Confirm' : pensionProviderId ? `Next` : `Make a Selection`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={!!pensionProviderId}
      headline={singleSuggestion
          ? `Ok, we think this is the current provider`
          : `Ok, there are a ${suggestedProviders && suggestedProviders.length === 2 ? 'couple of': 'few'} possible options`
      }
      subHeading={singleSuggestion
        ? `We think that your pension is currently looked after by ${suggestedProviders[0]?.name}`
        : `For ${latestAsset?.pensionProviderBrandName}, we need to identify the current provider and their line of business`
      }      
      subHeadingInfo={<NamedInformationButton name={NamedInformation.PENSION_PROVIDER_CHANGES} buttonTitle={'About Pension Providers'} />}
      allowTextButton={true}
      textButtonTitle={singleSuggestion ? `I don't think it's right` : `I don't think it's any of these`}
      textButtonAction={goToAllProviders}
    >
      <ManagedCardChoiceInput
        formObj={formObj}
        name={'pensionProviderId'}
        required={true}
        submitHandler={handleSubmit(onSubmit)}
        options={options}
      />
    </ModalProcessScreen>
  )
}
