import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setShowAdminAddGroupOrganizationVisible, showAdminAddGroupOrganizationVisible } from 'store/uxSlice'
import { AddGroupOrganizationsScreen } from './Screens/AddGroupOrganizationsScreen'

export const AddGroupOrganizationsModal = () => {
    const dispatch = useAppDispatch()
    const visible=useAppSelector(showAdminAddGroupOrganizationVisible)

  return (
    <ModalFormBuilder
      visible={visible}
      formTitle='Add New Group Organization'
      onDismiss={() => dispatch(setShowAdminAddGroupOrganizationVisible(false))}
      screen={<AddGroupOrganizationsScreen />}
    />
  )
}

