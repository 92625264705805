import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { BackgroundGradientPurple } from 'components/Background/BackgroundGradientPurple'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { FooterButton } from 'components/Utility/FooterButton'
import { EmployerDashboardSectionScreen } from 'features/EmployerSections/Dashboard/EmployerDashboardSectionScreen'
import { EmployerHistoryPaymentSectionScreen } from 'features/EmployerSections/HistoryPayments/EmployerHistoryPaymentSectionScreen'
import { EmployerHistoryRequestSectionScreen } from 'features/EmployerSections/HistoryRequests/EmployerHistoryRequestSectionScreen'
import { EmployerHistoryEnrolmentSectionScreen } from 'features/EmployerSections/HistoryEnrolments/EmployerHistoryEnrolmentSectionScreen'
import { EmployerInvitesSectionScreen } from 'features/EmployerSections/Invites/EmployerInvitesSectionScreen'
import { EmployerMemberSectionScreen } from 'features/EmployerSections/Members/EmployerMemberSectionScreen'
import { EmployerPaymentsSectionScreen } from 'features/EmployerSections/Payments/EmployerPaymentsSectionScreen'
import { EmployerRequestsSectionScreen } from 'features/EmployerSections/Requests/EmployerRequestsSectionScreen'
import { ModalProcesses } from 'features/ModalProcesses/ModalProcesses'
import { changeAppContext, goToLogout, rootNavigate } from 'lib/RootNavigation'
import { platformIsWeb } from 'lib/platformHelpers'
import { getLastGroupSchemeId, setLastGroupSchemeId } from 'lib/employerPortalHelpers'
import { getScreenAppWidth, webMaxWidth } from 'lib/scaleHelpers'
import { Logger } from 'lib/logger'
import { EmployerAppNavScreen } from 'lib/navigationHelpers'
import { DrawerScreen, DrawerScreenGroup } from 'navigation/components/CustomDrawerItemList'
import React, { useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery, useGetMeGroupSchemesQuery } from 'store/apiSlice'
import { GroupSchemeDto } from 'store/dto/group-scheme.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentGroupScheme, setCurrentGroupScheme, setSnackbarData } from 'store/uxSlice'
import { Colors, Flex, Sizing, Typography } from 'styles'
import { EmployerAppStackDrawerContent } from './Components/EmployerAppStackDrawerContent'
import { LoadingScreen } from './Gates'
import { useFocusEffect } from '@react-navigation/native'
import { AppContext } from 'store/authSlice'
import { EmployerEnrolmentsSectionScreen } from 'features/EmployerSections/Enrolments/EmployerEnrolmentsSectionScreen'
import { EmployerContributionsSectionScreen } from 'features/EmployerSections/Contributions/EmployerContributionsSectionScreen'
import { EmployerHistoryContributionSectionScreen } from 'features/EmployerSections/HistoryContributions/EmployerHistoryContributionSectionScreen'
import { MIN_WIDTH_FOR_PERMANENT_MENU } from 'lib/constants'
import { AppIllustration } from 'components/Utility/AppIllustration'
import { concat } from 'lodash'
import { canUserAdministerScheme } from 'lib/groupSchemeHelpers'

const screenWidth = getScreenAppWidth()

const Drawer = createDrawerNavigator()
const DRAWER_ICON_SIZE = 20

export const EmployerAppStack = () => {
  Logger.info(`#### Stack: EmployerApp`)

  const dispatch = useAppDispatch()

  //Determine if user is admin
  const authUser = useSelector((state: any) => state.auth.user)
  const currentScheme = useAppSelector(currentGroupScheme)
  
  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()
  const { data: schemes, isLoading: schemesIsLoading, error: schemesError, isFetching: schemesIsFetching, refetch: refetchSchemes } = useGetMeGroupSchemesQuery()

  const canAdminister = canUserAdministerScheme(currentScheme, user?.id)

  //Determine if user has group organizations
  const userHasGroupOrganizations = user?.groupOrganizationIds?.length ? true : false

  const [lastSchemeId, setLastSchemeId] = useState<string>(undefined)

  //Hook to force user context on entry
  useFocusEffect(() => {
    changeAppContext(AppContext.EMPLOYER, user, dispatch, false)
  })

  useEffect(()=>{
    const loadLastGroupScheme = async () => {
      const lastGroupSchemeId = await getLastGroupSchemeId()
      if (lastGroupSchemeId) {
        setLastSchemeId(lastGroupSchemeId)
      } else {
        Logger.info(`No last group scheme.`)
        setLastSchemeId(null)
      }
    }
    loadLastGroupScheme()
  },[])

  const noActiveSchemes = userHasGroupOrganizations && schemes && schemes?.length === 0
  const isLoading = userIsLoading || schemesIsLoading || schemesIsFetching

  useEffect(()=>{
    //Derive the bests scheme to use - last used or first from list

    if (schemes?.length && lastSchemeId !== undefined) {
      let bestScheme: GroupSchemeDto
      if (lastSchemeId) {
        bestScheme = schemes.find(scheme => {
          return scheme.id === lastSchemeId
        })
      }
      if (!bestScheme) {
        bestScheme = schemes[0]
      }
      if (bestScheme) {
        changeGroupScheme(bestScheme)
      }
    }
  },[schemes, lastSchemeId])

  const changeGroupScheme = async (groupScheme: GroupSchemeDto) => {
    Logger.debug(`Changing group scheme: ${groupScheme.id}`)
    dispatch(setCurrentGroupScheme(groupScheme))
    setLastGroupSchemeId(groupScheme.id)
  }

  const isWeb = platformIsWeb()
  
  const mainDrawerScreenGroup: DrawerScreenGroup = {
    id: '01_main',
    title: 'Main',
    renderFlat: true,
  }

  const historyDrawerScreenGroup: DrawerScreenGroup = {
    id: '02_history',
    title: 'History',
    renderFlat: false,
  }

  const screenGroupMap = {
    [EmployerAppNavScreen.DASHBOARD]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.ENROLMENTS]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.CONTRIBUTIONS]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.PAYMENTS]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.REQUESTS]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.INVITES]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.MEMBERS]: mainDrawerScreenGroup,
    [EmployerAppNavScreen.HISTORY_ENROLMENTS]: historyDrawerScreenGroup,
    [EmployerAppNavScreen.HISTORY_CONTRIBUTIONS]: historyDrawerScreenGroup,
    [EmployerAppNavScreen.HISTORY_PAYMENTS]: historyDrawerScreenGroup,
    [EmployerAppNavScreen.HISTORY_REQUESTS]: historyDrawerScreenGroup,
  }
  
  const screens: DrawerScreen[] = concat([
      {
        name: EmployerAppNavScreen.DASHBOARD,
        component: EmployerDashboardSectionScreen,
        title: 'Dashboard',
        icon: 'view-dashboard',
      },
    ],
    canAdminister ? [
      {
        name: EmployerAppNavScreen.INVITES,
        component: EmployerInvitesSectionScreen,
        title: 'Invite Employees',
        icon: 'email-send-outline',
      },
      {
        name: EmployerAppNavScreen.ENROLMENTS,
        component: EmployerEnrolmentsSectionScreen,
        title: 'Enrol Members',
        icon: 'book-account-outline',
      },
      {
        name: EmployerAppNavScreen.CONTRIBUTIONS,
        component: EmployerContributionsSectionScreen,
        title: 'Submit Contributions',
        icon: 'table-account',
      },
    ] : [],
    [
      {
        name: EmployerAppNavScreen.PAYMENTS,
        component: EmployerPaymentsSectionScreen,
        title: 'Pending Payments',
        icon: 'credit-card-outline',
      },
    ],
    canAdminister ? [
      {
        name: EmployerAppNavScreen.REQUESTS,
        component: EmployerRequestsSectionScreen,
        title: 'Employee Requests',
        icon: 'account-alert-outline',
      },
      {
        name: EmployerAppNavScreen.MEMBERS,
        component: EmployerMemberSectionScreen,
        title: 'Search Members',
        icon: 'account-search-outline',
      },
      {
        name: EmployerAppNavScreen.HISTORY_ENROLMENTS,
        component: EmployerHistoryEnrolmentSectionScreen,
        title: 'Enrolment History',
        icon: 'book-clock-outline',
      },
      {
        name: EmployerAppNavScreen.HISTORY_CONTRIBUTIONS,
        component: EmployerHistoryContributionSectionScreen,
        title: 'Contribution History',
        icon: 'table-clock',
      },
    ] : [],
    [
      {
        name: EmployerAppNavScreen.HISTORY_PAYMENTS,
        component: EmployerHistoryPaymentSectionScreen,
        title: 'Payment History',
        icon: 'credit-card-clock-outline',
      },
    ],
    canAdminister ? [
      {
        name: EmployerAppNavScreen.HISTORY_REQUESTS,
        component: EmployerHistoryRequestSectionScreen,
        title: 'Request History',
        icon: 'account-clock-outline',
      },
    ] : [],
  )

  function getDrawerIcon(iconName, focused) {
    return (
      <MaterialCommunityIcons
        name={iconName}
        size={DRAWER_ICON_SIZE}
        color={focused ? Colors.brand.purple2 : Colors.brand.grey2}
      />
    )
  }

  return (
    <>
      {
        isLoading ? <LoadingScreen messages={[`Preparing the Employer Portal...`]} />
        :
        <Portal.Host>
          {
            noActiveSchemes ?
              <BackgroundGradientPurple>
                <SafeAreaView style={{
                  ...Flex.column.center,
                  ...Flex.override.fill
                }}>
                  <View style={{
                    paddingTop: Sizing.x30,
                    paddingHorizontal: Sizing.x30,
                    ...Flex.column.center,
                    ...Flex.override.fill,
                  }}>
                    <Headline>{'Welcome to the Jarvis Employer Portal'}</Headline>
                    <Subheading>{'The portal enables you to administer your workplace pension schemes'}</Subheading>
                    <AppIllustration filename={'clock.png'} style={{
                      width: '100%',
                      height: Sizing.x200,
                      resizeMode: 'contain',
                      alignSelf: 'center'
                    }}/>
                    <Paragraph>{`We're working on getting your workplace scheme setup, and we'll be in touch as soon as your scheme is ready.`}</Paragraph>
                    <Paragraph>{`If you have received an email from Jarvis to say your scheme is now ready, please click below to re-check.`}</Paragraph>
                    <Button
                        mode='text'
                        onPress={refetchSchemes}
                      >
                        {'Check Now'}
                    </Button>
                    <FooterButton
                        mode='contained'
                        onPress={goToLogout}
                      >
                        {'Logout'}
                    </FooterButton>
                  </View>
                </SafeAreaView>
              </BackgroundGradientPurple> 
            : userHasGroupOrganizations ?
              <>
                <Drawer.Navigator
                  id="AdminApp"
                  initialRouteName={EmployerAppNavScreen.DASHBOARD}
                  drawerContent={(props) => <EmployerAppStackDrawerContent
                    {...props}
                    screens={screens}
                    changeSchemeFunction={changeGroupScheme}
                    screenGroupMap={screenGroupMap}
                  />}
                  screenOptions={{
                    headerShown: false,
                    headerShadowVisible: false,
                    drawerActiveTintColor: Colors.brand.purple2,
                    drawerType: isWeb ? screenWidth < MIN_WIDTH_FOR_PERMANENT_MENU ? 'front' : 'permanent' : 'slide',
                    drawerStyle: {
                      backgroundColor: Colors.brand.purplex0,
                      width: Sizing.x300,
                    },
                    drawerLabelStyle: Typography.defined.mainAppDrawerItem,
                    sceneContainerStyle: isWeb ? {
                      backgroundColor: isWeb ? Colors.brand.grey4 : undefined,
                      padding: Sizing.x20,
                      maxWidth: webMaxWidth,
                    } : undefined
                  }}>
                  <Drawer.Group>
                    {
                      screens.map((screen, idx) => {

                        return (
                          <Drawer.Screen
                            key={idx}
                            name={screen.name}
                            component={screen.component}
                            options={{
                              title: screen.title,
                              drawerIcon: ({ focused, color, size }) => getDrawerIcon(screen.icon, focused),
                              drawerItemStyle: {
                                display: screen.hide ? 'none' : undefined,
                              },
                            }}
                          />
                        )
                      })
                    }
                  </Drawer.Group>
                </Drawer.Navigator>
                <ModalProcesses/>
              </>
            :
              <BackgroundGradientPurple>
                <SafeAreaView style={{
                  ...Flex.column.center,
                  ...Flex.override.fill
                }}>
                  <View style={{
                    paddingTop: Sizing.x30,
                    paddingHorizontal: Sizing.x30,
                    ...Flex.column.center,
                    ...Flex.override.fill,
                  }}>
                    <Headline>{`Sorry, you don't have access`}</Headline>
                    <Subheading>{`Access to the Jarvis Employer Portal is restricted.`}</Subheading>
                    <AppIllustration filename={'magnifying_glass.png'} style={{
                      width: '100%',
                      height: Sizing.x200,
                      resizeMode: 'contain',
                      alignSelf: 'center'
                    }}/>
                    <FooterButton
                      mode='contained'
                      onPress={goToLogout}
                    >
                      {user ? 'Switch User' : 'Go to Login'}
                    </FooterButton>
                  </View>
                </SafeAreaView>
              </BackgroundGradientPurple> 
          }
        </Portal.Host>
      }
    </>
  )
}
