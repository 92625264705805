import { MessageDto, MessageStatus, MessageType } from 'store/dto/message.dto'
import { envVariables } from 'environment'

const { appUrl } = envVariables

export const getMessageIconName = (message: MessageDto) => {
  if (!message) {
    return ''
  }
  const { status } = message
  if (status === MessageStatus.ARCHIVED) {
    return 'archive-outline'
  } else if (status === MessageStatus.READ) {
    return 'email-open-outline'
  } else {
    return 'email-outline'
  }
}

export const getStatementIconName = (message: MessageDto) => {
  if (!message) {
    return ''
  }
  const { messageType } = message
  if (messageType === MessageType.QUARTERLY_STATEMENT) {
    return 'calendar-clock'
  } else if (messageType === MessageType.VALUATION_STATEMENT) {
    return 'account-wrench-outline'
  } else {
    return 'file-document-outline'
  }
}

export const getStatementDescription = (message: MessageDto) => {
  if (!message) {
    return ''
  }
  const { messageType } = message
  if (messageType === MessageType.QUARTERLY_STATEMENT) {
    return `Quarterly Statement`
  } else if (messageType === MessageType.VALUATION_STATEMENT) {
    return `Valuation Statement`
  } else if (messageType === MessageType.TAX_STATEMENT) {
    return `Tax Statement`
  } else {
    return 'Other Statement'
  }
}

export const getMessageBodyPreview = (message: MessageDto) => {
  const body = message?.payload?.body
  if (!body) {
    return ''
  }
  const length = 100
  return `${body.substring(0,length)}...`.replace(/<[^>]+>/g, ' ')
}

export const replaceNewLinesWithBr = (text: string) => {  
  if (!text) {
    return ''
  }
  return text.replace(/\n/g, '<br />')
}

export const fixAndWrapSecclHtml = (text: string) => {  
  if (!text) {
    return ''
  }
  return `<div>${text.replace(/\n\n/g, '<br /><br />')}</div>`
}

export const replaceDoubleNewLineWithBr = (text: string) => {  
  if (!text) {
    return ''
  }
  return text.replace(/\n\n/g, '<br /><br />')
}


export const removeNewLines = (text: string) => {  
  if (!text) {
    return ''
  }
  return text.replace(/\n/g, '')
}

export const filterStatementMessages = (messages: MessageDto[], exclude?: boolean) => {
  const statementMessageTypes = [
    MessageType.VALUATION_STATEMENT,
    MessageType.QUARTERLY_STATEMENT,
    MessageType.TAX_STATEMENT,
  ]
  return messages ? messages.filter(message => {
    if (exclude) {
      return !statementMessageTypes.includes(message.messageType)
    } else {
      return statementMessageTypes.includes(message.messageType)
    }
  }) : []
}

export const wrapSecclMessageHtmlContent = (content): string => {
  const htmlStyles = `<style type="text/css">
    @font-face {
      font-family: 'Lab Grotesque Regular';
      font-style: normal;
      font-weight: 500;
      src: local('Lab Grotesque Regular'), url('${appUrl}/fonts/LabGrotesque-Regular.woff') format('woff');
    }
    body{
        color:#4F028F;
        font-size:3rem;
        font-family: Lab Grotesque Regular;
    }
    </style>`
  return `<html><head>` + htmlStyles + `</head><body>` + removeNewLines(replaceDoubleNewLineWithBr(content)) + `</body></head>`
}