import { ManagedMultipleChoiceInput, ManagedMultipleChoiceInputOption } from 'components/Inputs/ManagedMultipleChoiceInput'
import { ProcessScreen } from 'components/ScreenTemplates/ProcessScreen'
import { envVariables } from 'environment'
import { difference } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingClient, workingClient } from 'store/tempDataSlice'


export const ClientSetup_07_Terms = ({ route, navigation }) => {
  const { nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  //Setup form
  const formObj = useForm<{ termsAgreed: string[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      termsAgreed: []
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = () => {
    dispatch(updateWorkingClient({
      termsAgreed: true
    }))
    navigation.navigate(nextScreen)
  }

  const { termsUrl, privacyUrl } = envVariables

  enum Option {
    TERMS = 'TERMS',
    PRIVACY = 'PRIVACY',
  }

  const options: ManagedMultipleChoiceInputOption[] = [
    {
      value: Option.TERMS,
      label: `Terms & Conditions`,
      linkUrl: termsUrl,
      linkIsPdf: true,
    },
    {
      value: Option.PRIVACY,
      label: `Privacy Policy`,
      linkUrl: privacyUrl,
    },
  ]

  const requiredOptions = [
    Option.TERMS,
    Option.PRIVACY,
  ]
  
  const termsAgreed = watch('termsAgreed')
  const unchecked = difference(requiredOptions, termsAgreed)
  const hasConfirmedAll = unchecked.length === 0

  return (
    <ProcessScreen
      buttonTitle={hasConfirmedAll ? 'Create My Profile' : 'Please Confirm'}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={hasConfirmedAll}
      headline={`Important Information`}
      subHeading={`Please confirm you agree to the Jarvis Terms & Conditions and Privacy Policy`}
    >
      <ManagedMultipleChoiceInput
        formObj={formObj}
        name={'termsAgreed'}
        options={options}
        noSeparator={true}
      />
    </ProcessScreen>
  )
}
