import { AutoCompleteItem, CURRENT_ENTITY_OPTION_VALUE, ManagedAutoCompleteInput, isSpecialValue } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Logger } from 'lib/logger'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGetPensionBrandsQuery, useLazyGetRetirementAssetSuggestedProvidersQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { RetirementAssetType } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Image, View } from 'react-native'
import { Colors, Sizing, Flex } from 'styles'
import { orderBy } from 'lodash'
import { MaterialCommunityIcons } from '@expo/vector-icons'

export const RetirementAssetTransfer_02_ProviderBrand = ({ route, navigation }) => {
  const { asset }  = route?.params || {}

  const alreadyHadBrandName = asset?.pensionProviderBrandName
  const alreadyHadBrandKey = asset?.pensionProviderBrandKey

  const {
    pensionProviderBrandKey: existingPensionProviderBrandKey,
    pensionProviderBrandName: existingPensionProviderBrandName,
  } = asset || {}

  const existingBrandOption: AutoCompleteItem = existingPensionProviderBrandName && !existingPensionProviderBrandKey ? {
    value: CURRENT_ENTITY_OPTION_VALUE,
    label: existingPensionProviderBrandName,
    description: 'Existing pension brand name set by you',
  } : undefined
  
  //Temp state
  const dispatch = useAppDispatch()

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)
  
  const [dummyBrandOption, setDummyBrandOption] = useState<AutoCompleteItem>(existingBrandOption)

  //Build autocomplete options
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const brandOptions: AutoCompleteItem[] = brands ? orderBy(brands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  }) : []

  if (dummyBrandOption) {
    brandOptions.unshift(dummyBrandOption)
  }

  const [updateAssetBrand, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()
  const [getSuggestedProviders, { data: suggestedProviders, error: suggestedProvidersError, isLoading: suggestedProvidersIsLoading, isFetching: suggestedProvidersIsFetching }] = useLazyGetRetirementAssetSuggestedProvidersQuery()

  const error = brandsError || suggestedProvidersError || updateAssetError
  const isLoading = brandsIsLoading || suggestedProvidersIsLoading || updateAssetIsLoading || suggestedProvidersIsFetching

  //Setup form
  const formObj = useForm<{ pensionProviderBrandKey: string, pensionProviderBrandName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionProviderBrandKey: workingRetirementAssetData?.pensionProviderBrandKey
        ? workingRetirementAssetData?.pensionProviderBrandKey
        : asset?.pensionProviderBrandKey
          ? asset?.pensionProviderBrandKey
          : existingBrandOption
            ? CURRENT_ENTITY_OPTION_VALUE
            : undefined,
      pensionProviderBrandName: workingRetirementAssetData?.pensionProviderBrandName || asset?.pensionProviderBrandName, 
    },
  })
  const { handleSubmit, watch, formState: { isValid} } = formObj
  
  const onSubmit = attributes => {
    const workingUpdate = {
      ...attributes,
      pensionProviderBrandKey: isSpecialValue(pensionProviderBrandKey) ? null : pensionProviderBrandKey,
      tracingRequested: false,
    }
    //Clear provider if brand changed
    if (asset?.pensionProviderId && attributes?.pensionProviderBrandKey !== asset?.pensionProviderBrandKey) {
      workingUpdate.pensionProviderId = null
    }
    
    dispatch(updateWorkingRetirementAsset(workingUpdate))
    Logger.info(`Updating asset brand... ${attributes?.pensionProviderBrandName}`)
    updateAssetBrand({
      id: asset?.id,
      tracingRequested: false,
      pensionProviderBrandKey: isSpecialValue(pensionProviderBrandKey) ? null : pensionProviderBrandKey,
      pensionProviderBrandName: attributes?.pensionProviderBrandName,
    })
  }

  //Get suggested providers when we have updated asset
  useEffect(() => {
    if (updatedAsset) {
      getSuggestedProviders(updatedAsset?.id, false)
    }
  }, [updatedAsset])

  //Navigate when we have suggested providers
  useEffect(() => {
    if (suggestedProviders) {
      if (suggestedProviders.length === 0) {
        navigation.navigate('ProviderList')
      } else  {
        dispatch(updateWorkingRetirementAsset({
          suggestedProviders,
        }))
        navigation.navigate('ProviderSuggestions')
      }
    }
  }, [suggestedProviders])

  const pensionProviderBrandKey = watch('pensionProviderBrandKey')
  const pensionProviderBrandName = watch('pensionProviderBrandName')
  
  const isWorkplace = asset?.assetType === RetirementAssetType.WORKPLACE

  const selectedProviderBrand = brands ? brands.find(brand => {
    return brand.key === pensionProviderBrandKey
  }) : undefined

  const sameBrand = alreadyHadBrandKey && asset?.pensionProviderBrandKey !== pensionProviderBrandKey

  const goToProviders = () => {
    dispatch(updateWorkingRetirementAsset({
      tracingRequested: false,
      pensionProviderBrandKey: null,
      pensionProviderBrandName: null,
    }))
    navigation.navigate('ProviderList')
  }

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={brandsError ? refetchBrands : updateAssetError ? handleSubmit(onSubmit) : () => getSuggestedProviders(updatedAsset?.id)}
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : brandsIsLoading ? ['Fetching pension brands...'] : ['Finding providers...']}
      buttonTitle={alreadyHadBrandName
        ? sameBrand
          ? 'Change'
          : 'Confirm'
        : pensionProviderBrandName
          ? `Confirm`
          : `Make a Selection`}
      buttonAction={handleSubmit(onSubmit)}
      enableButton={!!pensionProviderBrandName}
      headline={isWorkplace
          ? `Who did your employer use for your pension?`
          : `With whom did you set up your pension?`
      }
      subHeading={asset?.pensionProviderBrandName
        ? isWorkplace
          ? `You previously told us that your employer used ${asset?.pensionProviderBrandName}`
          : `You previously told us you set up your pension with ${asset?.pensionProviderBrandName}`
        : isWorkplace
          ? `The provider may have changed since you worked there, but enter the name you know`
          : `The provider may may have changed since, but enter the name you know`
      }
      allowTextButton={!pensionProviderBrandName}
      textButtonTitle={`I don't know`}
      textButtonAction={goToProviders}
    >
      <ManagedAutoCompleteInput
        name={'pensionProviderBrandKey'}
        allowFreeText={true}
        placeholder={'Tap to search or enter...'}
        textCaptureFieldName={'pensionProviderBrandName'}
        unknownItemUseDescription={'Use this name'}
        unknownItemSelectedDescription={'Name specified by you'}
        formObj={formObj}
        modalTitle={'Find/Enter Pension Brand'}
        required={true}
        dataSet={brandOptions}
      />
      {
        selectedProviderBrand?.logo
          ? <View style={{
              paddingTop: Sizing.x20,
            }}>
              <Image source={{ uri: selectedProviderBrand?.logo} } style={{
                width: Sizing.x150,
                height: Sizing.x150,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            </View>
          : <></>
      }
    </ModalProcessScreen>
  )
}
