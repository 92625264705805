import { FooterButton } from 'components/Utility/FooterButton'
import React, { useRef, useState } from 'react'
import { Image, ScrollView, StatusBar, StyleSheet, View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AUTHTYPE } from 'store/dto/base.dto'
import { Flex, Paper, Sizing } from 'styles'
import { backgroundStyles, layoutStyles } from 'styles/common'
import { NewUserCarousel } from '../Components/NewUserCarousel'
import { JAR_NAME_ALL } from 'lib/constants'
import { platformIsWeb } from 'lib/platformHelpers'
import { useAppDispatch } from 'store/hooks'
import { setLoginParams } from 'store/authSlice'

const isWeb = platformIsWeb()

export const NewUserCarouselScreen = ({ route, navigation: { navigate } }) => {
  
  const dispatch = useAppDispatch()

  //Build carousel data
  const carouselData = [
    {
      illustrationFilename: 'store_budget.png',
      headline: `Quickly work out what you need`,
      subHeading: `Create a realistic budget for your desired retirement lifestyle`,
    },
    {
      illustrationFilename: 'store_assets_and_incomes.png',
      headline: `Capture everything you have`,
      subHeading: `Capture your pensions, retirement assets and incomes all in one place`,
    },
    {
      illustrationFilename: 'store_contribute.png',
      headline: `Know exactly how much to save`,
      subHeading: `Smart suggestions make it effortless to understand how to achieve your goals`,
    },
    {
      illustrationFilename: 'store_consolidate.png',
      headline: `Easily consolidate old pensions`,
      subHeading: `Simplify your retirement planning by transferring your old pensions into your ${JAR_NAME_ALL}`,
    },
  ]
  
  const [carouselComplete, setCarouselComplete] = useState(false)
  const [skipped, setSkipped] = useState<boolean>(false)
  const carouselRef = useRef()

  const snapToNext = () => {
    carouselRef?.current?.next()
  }

  const onCompletion = () => {
    setCarouselComplete(true)
  }

  const goToLogin = () => {
    dispatch(setLoginParams({ authType: AUTHTYPE.LOGIN }))
    navigate('AuthenticationProcessStack')
  }
  
  const goToRegister = () => {
    dispatch(setLoginParams({ authType: AUTHTYPE.REGISTER }))
    navigate('AuthenticationProcessStack')
  }
  
  return (   
    <>
      <StatusBar barStyle={'dark-content'} />
      <ThemeProvider theme={Paper.darkThemeOnLightGrey}>
        <SafeAreaView style={[
          backgroundStyles.pageContainer,
          backgroundStyles.heroBackground,
        ]}>
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.screenContainer,
          ]}>
            <ScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              keyboardShouldPersistTaps='handled'
              showsVerticalScrollIndicator={isWeb}
            >
              <View style={{
                ...Flex.column.start,
                paddingHorizontal: Sizing.x30,
              }}>
                {/* <Image source={require('assets/brand/jarvis-logo-blk-4x.png')} style={{
                    width: Sizing.x200,
                    height: Sizing.x50,
                    resizeMode: 'contain',
                    alignSelf: 'center',
                    // alignSelf: useHorizontalLayout ? 'flex-start' : 'center',
                  }} /> */}
                <View>
                  <NewUserCarousel
                    ref={carouselRef}
                    data={carouselData}
                    onCompletion={onCompletion}
                    skipped={skipped}
                  />
                </View>
              </View>
            </ScrollView>
            <FooterButton
              mode='text'
              onPress={goToLogin}
            >{'Already have an account? Log in'}</FooterButton>
            <FooterButton
              onPress={carouselComplete ? goToRegister : snapToNext}
            >{carouselComplete ? 'Create Account' : 'Next'}</FooterButton>
          </View>
        </SafeAreaView>
      </ThemeProvider>
    </>
  )
}

const localStyles = StyleSheet.create({
  screenContainer: {
    paddingTop: Sizing.x30,
  },
})
