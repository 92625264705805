import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedDateInput } from 'components/Inputs/ManagedDateInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { ModalProcessScreen } from 'components/Layout'
import { Paragraph } from 'components/Typography'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { Logger } from 'lib/logger'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { ampli } from 'src/ampli'
import { useUpdateSpouseMutation } from 'store/apiSlice'
import { Gender, Title } from 'store/dto/base.dto'
import { SpouseDto } from 'store/dto/client.dto'
import { pick } from 'lodash'

export type SpouseEditScreenProps = {
  spouse: SpouseDto
  onDismiss: any
}

export const SpouseEditScreen = (props: SpouseEditScreenProps) => {
  const { spouse, onDismiss } = props

  const [ updateSpouse, { data: updatedSpouse, isLoading, error} ] = useUpdateSpouseMutation()

  const disabled = !!spouse?.userId

  //Form refs for focussing
  const titleRef = useRef()
  const firstNameRef = useRef()
  const surnameRef = useRef()
  const genderRef = useRef()
  const birthDateRef = useRef()

  //Setup form
  const formObj = useForm<{
    title: Title
    firstName: string
    surname: string
    gender: Gender
    birthDate: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: spouse?.title,
      firstName: spouse?.firstName,
      surname: spouse?.surname,
      gender: spouse?.gender,
      birthDate: spouse?.birthDate,
    },
  })
  const { handleSubmit, watch, formState: { isDirty, isValid} } = formObj
  
  const onSubmit = attributes => {
    Logger.info(`Updating spouse...`)
    updateSpouse({
      id: spouse?.id,
      ...attributes,
    })
    
  }

  useEffect(() => {
    if (updatedSpouse) {
      ampli.spouseUpdate(pick(updatedSpouse, [
        'gender',
        'birthDate',
        'age',
      ]))
      onDismiss()
    }
  }, [updatedSpouse])

  return (
      <ModalProcessScreen
        error={error}
        isLoading={isLoading}
        loadingMessage={['Saving partner...']}
        errorTryAgain={error ? handleSubmit(onSubmit) : undefined}
        errorCancel={onDismiss}
        buttonTitle={disabled ? 'Close' : 'Save'}
        buttonAction={disabled ? onDismiss : handleSubmit(onSubmit)}
        showButton={true}
        enableButton={isDirty && isValid}
      >
        {
          disabled
          ? <Paragraph>{`This information is read only because you have linked your account with ${spouse?.firstName}.`}</Paragraph>
          : <></>
        }
        
        <ManagedAutoCompleteInput
          name={'title'}
          formObj={formObj}
          label={'Title'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Title)}
          disabled={disabled}
          required={true}
        />
        <ManagedTextInput
          ref={firstNameRef}
          name={'firstName'}
          formObj={formObj}
          label={'First Name'}
          placeholder={'Their legal first name'}
          returnKeyType={'next'}
          submitHandler={() => surnameRef.current?.getElement().focus()}
          blurOnSubmit={false}
          disabled={disabled}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedTextInput
          ref={surnameRef}
          name={'surname'}
          formObj={formObj}
          label={'Last Name'}
          placeholder={'Their legal surname'}
          returnKeyType={'next'}
          blurOnSubmit={true}
          disabled={disabled}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 40,
        }} />
        <ManagedAutoCompleteInput
          name={'gender'}
          formObj={formObj}
          label={'Gender'}
          selectOnlyMode={true}
          dataSet={enumToAutocompleteOptions(Gender)}
          disabled={disabled}
          required={true}
        />
        <ManagedDateInput
          ref={birthDateRef}
          name={'birthDate'}
          formObj={formObj}
          label={'Date of Birth'}
          blurOnSubmit={false}
          required={true}
          disabled={disabled}
          tryParseTwoDigitYear={true}
          showCurrentAgeMessage={true}
        />
      </ModalProcessScreen>
  )
}
