import { platformIsWeb } from 'lib/platformHelpers'
import React from 'react'
import { Linking, View } from 'react-native'
import { Portal, ThemeProvider } from "react-native-paper"
import { SafeAreaView } from 'react-native-safe-area-context'
// import PDFReader from '@bildau/rn-pdf-reader'
import PDFReader from 'shim/@bildau/rn-pdf-reader/index'
import { Colors, Flex, Paper } from 'styles'
import { Button } from './Button'
import { GenericWebView } from "./GenericWebView"

type ExternalLinkModalProps = {
  url: string
  onDismiss: any
  usePdfViewer?: boolean
  useGoogleReader?: boolean
}

export const ExternalLinkModal = (props: ExternalLinkModalProps) => {
  const { url, onDismiss, usePdfViewer, useGoogleReader } = props

  const openInBrowser = () => { 
    if (isWeb) {
      window.open(url)
    } else {
      onDismiss()
      Linking.openURL(url)
    }
  }

  const isWeb = platformIsWeb()

  return (
    <Portal>
      <ThemeProvider theme={Paper.darkTheme}>
        <View style={{
          backgroundColor: Colors.neutral.white,
          // padding: Sizing.x5,
        }}>
          <SafeAreaView>
            <View style={{
              ...Flex.row.around,
            }}>
              <Button mode='text' onPress={onDismiss}>Back to Jarvis</Button>
              <Button mode='text' onPress={openInBrowser}>Open in Browser</Button>
            </View>
            <View style={{
              height: '100%',
              width: '100%',
            }}
            >
              {
                usePdfViewer
                  ? <PDFReader
                      source={{
                        uri: url,
                      }}
                      useGoogleReader={useGoogleReader}
                      withScroll={true}
                    />
                  : <GenericWebView uri={url}></GenericWebView>
              }            
            </View>
          </SafeAreaView>
        </View>
      </ThemeProvider>
    </Portal>

  )
}