import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useLazyGetFeaturesQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { FeatureSearchFeatureStatus } from 'store/dto/feature.dto'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminFeatureDataId } from 'store/uxSlice'
import { Flex, Paper, Sizing } from 'styles'
import { AdminFeatureListItem } from '../Components/AdminFeatureListItem'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminFeaturesListScreen = () => {
  const [getFeaturesQuery, { data: features, isLoading: featuresIsLoading, error: featuresError, isFetching: featuresIsFetching }] = useLazyGetFeaturesQuery()
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowFeatureDetails = (id: string) => {
    dispatch(setCurrentAdminFeatureDataId(id))
  }

  const featuresList = () => {
    let all = []
    if (features) {
      features.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <AdminFeatureListItem
        feature={item}
        onPressFunction={() => handleShowFeatureDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<{ search: string, status: FeatureSearchFeatureStatus }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      status: FeatureSearchFeatureStatus.ANY,
    },
  })

  const { handleSubmit, reset } = formObj

  const onSubmit = attributes => {
    const status = attributes.status === FeatureSearchFeatureStatus.ANY ? undefined : attributes.status
    getFeaturesQuery({
      ...attributes,
      status,
    })
  }

  const isLoading = featuresIsLoading || featuresIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  // const handleAddFeature = () => {
  //   dispatch(setShowAdminAddFeatureVisible(true))
  // }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      status: FeatureSearchFeatureStatus.ANY,
    })
  }

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:system']]}
      >
        {
          isLoading ? <Loading message={getLookingLoadingMessages('Finding features...')} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for feature'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of code, name or description'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                    <ManagedAutoCompleteInput
                      name={'status'}
                      label={'Status'}
                      selectOnlyMode={true}
                      formObj={formObj}
                      dataSet={enumToAutocompleteOptions(FeatureSearchFeatureStatus)}
                    />
                    <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                    <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={isWeb}
              >
                <OptionalSectionList
                  sections={featuresList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for a feature`}
                />
              </ScrollView>
              {/* <FooterButton
                onPress={handleAddFeature}
              >
                {'Add New Feature'}
              </FooterButton> */}
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
