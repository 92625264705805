import { Loading } from 'components/Utility/Loading'
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useLazyGetClientsQuery } from 'store/apiSlice'
import { layoutStyles } from 'styles/common'

import { ManagedTextInput } from 'components/Inputs/ManagedTextInput'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Button } from 'components/Utility/Button'
import { OptionalSectionList } from 'components/Utility/OptionalSectionList'
import { identity, pickBy } from 'lodash'
import { useForm } from 'react-hook-form'
import Collapsible from 'react-native-collapsible'
import { AmlStatus, ClientFilterDto, ClientSearchAmlStatus } from 'store/dto/client.dto'
import { useAppDispatch } from 'store/hooks'
import { setCurrentAdminClientDataId } from 'store/uxSlice'
import { Sizing, Paper, Flex } from 'styles'
import { AdminClientListItem } from '../Components/AdminClientListItem'
import { ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { enumToAutocompleteOptions } from 'lib/inputHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { AdminGuard } from 'features/AdminSections/AdminGuard'
import { UserPermissions } from 'lib/authHelpers'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const AdminClientsListScreen = () => {
  const [getClientsQuery, { data: clientsData, isLoading: clientsIsLoading, error: clientsError, isFetching: clientsIsFetching }] = useLazyGetClientsQuery()
  const [refreshing, setRefreshing] = useState(false)

  const { colors: themeColors } = Paper.useAppTheme()
  const dispatch = useAppDispatch()

  const handleShowClientDetails = (id: string) => {
    dispatch(setCurrentAdminClientDataId(id))
  }

  const clientsList = () => {
    let all = []
    if (clientsData) {
      clientsData.map((data, id) => {
        all.push(data)
      })
      return [{ title: 'Results', data: all }]
    }
    return []
  }

  const renderItem = ({ item }) => {
    return (
      <AdminClientListItem
        client={item}
        onPressFunction={() => handleShowClientDetails(item?.id)}
      />
    )
  }

  const formObj = useForm<ClientFilterDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
      amlReference: '',
      secclClientId: '',
      amlStatus: ClientSearchAmlStatus.ANY
    },
  })

  const { handleSubmit, reset, watch } = formObj

  const onSubmit = attributes => {
    const amlStatus = attributes.amlStatus === ClientSearchAmlStatus.ANY ? undefined : attributes.amlStatus
    getClientsQuery(pickBy({
      ...attributes,
      amlStatus,
    }, identity))
  }

  const isLoading = clientsIsLoading || clientsIsFetching

  const onRefresh = () => {
    setRefreshing(true)
    handleSubmit(onSubmit)
    setRefreshing(false)
  }

  const [searchCollapsed, setSearchCollapsed] = useState(true)

  const hideAdvanced = () => {
    setSearchCollapsed(true)
    resetSearch()
  }

  const showAdvanced = () => {
    setSearchCollapsed(false)
  }

  const resetSearch = () => {
    reset({
      search: '',
      amlReference: '',
      secclClientId: '',
      amlStatus: ClientSearchAmlStatus.ANY,
    })
  }

  const amlStatus = watch('amlStatus')
  const amlReference = watch('amlReference')
  const secclClientId = watch('secclClientId')
  const searchTermRequired = amlStatus === ClientSearchAmlStatus.ANY && amlReference === '' && secclClientId === ''

  return (
    <MainAppScreen>
      <AdminGuard
        requiredPermissions={[UserPermissions['administer:client']]}
      >
        {
          isLoading ? <Loading message={getLookingLoadingMessages('Finding clients...')} /> :
            <View style={ Flex.column.start } >
              <View style={{ paddingHorizontal: Sizing.x30 }} >
                <ManagedTextInput
                  name={'search'}
                  label={'Search for client'}
                  autoFocus={false}
                  formObj={formObj}
                  placeholder={'Enter part of email/name'}
                  blurOnSubmit={false}
                  submitHandler={searchCollapsed ? handleSubmit(onSubmit) : undefined}
                  rules={{
                    required: searchTermRequired,
                    minLength: searchTermRequired ? {
                      value: 3,
                      message: 'Must be at least 3 characters if no other filter is set'
                    } : undefined,
                  }} />
                <Collapsible collapsed={searchCollapsed}>
                  <View>
                    <ManagedAutoCompleteInput
                      name={'amlStatus'}
                      label={'AML Status'}
                      selectOnlyMode={true}
                      formObj={formObj}
                      dataSet={enumToAutocompleteOptions(ClientSearchAmlStatus)}
                    />
                    <ManagedTextInput
                      name={'secclClientId'}
                      label={'SECCL Client Id'}
                      autoFocus={false}
                      formObj={formObj}
                      placeholder={'Enter SECCL client Id'}
                      blurOnSubmit={false}
                      autoCapitalize={'characters'}
                      rules={{
                        minLength: 7,
                        maxLength: 7,
                      }} />
                    <ManagedTextInput
                      name={'amlReference'}
                      label={'AML Reference'}
                      autoFocus={false}
                      formObj={formObj}
                      placeholder={'Enter AML Reference'}
                      blurOnSubmit={false}
                      rules={{
                        minLength: 4,
                        maxLength: 12,
                      }} />
                    <Button onPress={handleSubmit(onSubmit)} disabled={isLoading}>Search</Button>
                    <Button onPress={resetSearch} mode='text' disabled={isLoading}>Clear filter</Button>
                  </View>
                </Collapsible>
                <Button
                  mode='text'
                  color={themeColors.accent}
                  icon={searchCollapsed ? 'chevron-down' : 'chevron-up'}
                  onPress={searchCollapsed ? showAdvanced : hideAdvanced}
                >
                  {searchCollapsed ? 'Advanced search...' : 'Hide'}
                </Button>
                <ContentDivider />
              </View>
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}
                showsVerticalScrollIndicator={isWeb}
              >
                <OptionalSectionList
                  sections={clientsList()}
                  renderItem={renderItem}
                  onRefresh={onRefresh}
                  refreshing={refreshing}
                  noDataMessage={`Please search for a client`}
                />
              </ScrollView>
            </View>
        }
      </AdminGuard>
    </MainAppScreen>
  )
}
