import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { changeAppContext } from 'lib/RootNavigation'
import { userIsAdministrator } from 'lib/authHelpers'
import { getAppDescriptionForAppContext, getIconForAppContext } from 'lib/navigationHelpers'
import React, { useState } from 'react'
import { Menu } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'store/apiSlice'
import { AppContext } from 'store/authSlice'
import { useAppDispatch } from 'store/hooks'
import { Colors, Paper, Sizing } from 'styles'

export type AppContextSwitcherProps = {
  currentAppContext: AppContext
}

export const AppContextSwitcher = (props: AppContextSwitcherProps) => {
  const { currentAppContext } = props

  //Determine if user is admin
  const authUser = useSelector((state: any) => state.auth.user)
  const userIsAdmin = userIsAdministrator(authUser)

  //Determine if user has group organizations
  const { data: user, error: userError, isLoading: userIsLoading, refetch: refetchUser } = useGetCurrentUserQuery()
  const userHasGroupOrganizations = user?.groupOrganizationIds?.length ? true : false

  const availableAppContexts: AppContext[] = [
    AppContext.CLIENT
  ]

  if (userHasGroupOrganizations) {
    availableAppContexts.push(AppContext.EMPLOYER)
  }

  if (userIsAdmin) {
    availableAppContexts.push(AppContext.ADMIN)
  }

  const [visible, setVisible] = useState(false)
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  const dispatch = useAppDispatch()

  const showSwitcher = userHasGroupOrganizations || userIsAdmin

  const { colors: themeColors } = Paper.useAppTheme()

  return (
    <>
      {
        showSwitcher
          ? <>
              <Button
                mode={'text'}
                onPress={openMenu}
                icon={() => <MaterialIcons name={'arrow-drop-down'} size={Sizing.x15} color={Colors.brand.purple1}/>}
              >
                {getAppDescriptionForAppContext(currentAppContext)}
              </Button>
              <Menu
                visible={visible}
                onDismiss={closeMenu}
                style={{
                  marginLeft: Sizing.x50 * -1
                }}
                anchor={{ x: Sizing.x110, y: Sizing.x50 }}
              >
                <Subheading style={{ color: themeColors.accent }}>{'Switch App'}</Subheading>
                <ContentDivider />
                {
                  availableAppContexts.map((appContext, idx) => {
                    const disabled = appContext === currentAppContext
                    return (
                      <Menu.Item
                        key={idx}
                        titleStyle={{
                          color: disabled ? themeColors.disabled : themeColors.accent
                        }}
                        leadingIcon={() => <MaterialCommunityIcons name={getIconForAppContext(appContext)} size={Sizing.x20} color={disabled ? themeColors.disabled : themeColors.accent}/>}
                        onPress={() => changeAppContext(appContext, user, dispatch, true)}
                        title={getAppDescriptionForAppContext(appContext)}
                        disabled={disabled}
                      />
                    )
                  })
                }
              </Menu>
            </>
          : <></>
      }
    </>
  )
}
