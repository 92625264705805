import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { BudgetTable } from 'components/Utility/BudgetTable'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { formatISO } from 'date-fns'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { calculateAgeAtDate } from 'lib/dateHelpers'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { OnboardingStateStepIdentifier } from 'providers'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useGetBudgetsQuery, useGetMeQuery, useGetRetirementProfileQuery, useGetSpouseQuery } from 'store/apiSlice'
import { useAppDispatch } from 'store/hooks'
import { setEditRetirementAgeVisible, setEditRetirementBudgetVisible } from 'store/uxSlice'
import { Colors, Flex, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

export const RetirementLifestyleScreen = () => {

  const dispatch = useAppDispatch()

  const { data: client, error: clientError, isLoading: clientIsLoading, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: retirementProfile, error: rpError, isLoading: rpIsLoading, isFetching: rpIsFetching, refetch: refetchProfile } = useGetRetirementProfileQuery()
  const { data: budgets, error: budgetsError, isLoading: budgetsIsLoading, isFetching: budgetsIsFetching, refetch: refetchBudgets } = useGetBudgetsQuery({
    asCouple: retirementProfile?.asCouple,
    insideLondon: retirementProfile?.insideLondon,
  }, { skip: !retirementProfile })

  const refetchAll = () => {
    refetchClient()
    refetchSpouse()
    refetchProfile()
    refetchBudgets()
  }
  
  const isLoading = clientIsLoading || spouseIsLoading || rpIsLoading || budgetsIsLoading
  const error: any = clientError || budgetsError
  
  const { colors: themeColors } = Paper.useAppTheme()

  const noSpouseAlert = !spouse && retirementProfile?.asCouple === true
  const spouseAlert = spouse && retirementProfile?.asCouple === false

  const clientAge = client ? calculateAgeAtDate(formatISO(new Date()), client?.birthDate) : 0
  const yearsUntilRetirement = client ? retirementProfile?.targetRetirementAge - clientAge : 0

  return (
    <MainAppScreen>
      {
        isLoading
        ? <Loading message={getLookingLoadingMessages('Loading your lifestyle...')} />
        : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
        <OnboardingGuard
          explanation={`Your Retirement Lifestyle screen will show you how you defined your retirement in terms of the when, who, where and how much?`}
          onboardingSteps={[
            OnboardingStateStepIdentifier.RETIREMENT_PROFILE,
          ]}
        >
          <View style={[
            layoutStyles.mainContentContainer,
            localStyles.pageContainer,
            ]} >
            <RefreshableScrollView
              contentContainerStyle={layoutStyles.scrollContainerContent}
              showsVerticalScrollIndicator={isWeb}
              refreshFunctions={[
                refetchProfile,
              ]}
            >
              {
                retirementProfile
                ? <>
                    <Subheading style={Typography.defined.sectionListHeader}>{'Target Retirement Age'} </Subheading>
                    <Paragraph>{`You're aiming to retire in about ${yearsUntilRetirement} years at age:`}</Paragraph>
                    <View style={{ paddingTop: Sizing.x10 }}>
                      <Paragraph
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={{
                          fontFamily: 'LabGrotesque-Medium',
                          fontWeight: '400',
                          lineHeight: scaleNormalizer(100),
                          fontSize: scaleNormalizer(100),
                          textAlign: 'center',
                        }}>
                          {retirementProfile?.targetRetirementAge}
                      </Paragraph>
                    </View>
                    <Button
                      mode={'text'}
                      color={themeColors.accent}
                      onPress={() => dispatch(setEditRetirementAgeVisible(true))}>
                        {`Change Target Age`
                    }</Button>
                    <ContentDivider />

                    <Subheading style={Typography.defined.sectionListHeader}>{'Monthly Retrirement Budget'} </Subheading>
                    <Paragraph>{`You are currently planning to retire ${retirementProfile?.asCouple ? spouse ? `with ${spouse?.firstName}` : `with your partner` : `on your own`} ${retirementProfile?.insideLondon ? `in London` : `somewhere other than London`}.`}</Paragraph>
                    <BudgetTable
                      isLoading={budgetsIsLoading || rpIsLoading}
                      rows={budgets ? budgets.map(budget => {
                        return {
                          budget,
                        }
                      }) : []}
                      expenses={retirementProfile?.expenses}
                      netMonthlyTotal={(retirementProfile?.expensesNetTotalAmount || 0) / 12}
                    />
                    <Paragraph>{`This budget requires a gross annual income of ${formatCurrencyAmount(retirementProfile ? retirementProfile?.expensesGrossTotalAmount : 0)}`}</Paragraph>
                    {
                      spouseAlert || noSpouseAlert ?
                        <>
                          <View style={Flex.row.center}>
                            <MaterialCommunityIcons name={'alert-rhombus'} size={Sizing.x40} color={Colors.warning.s400} />
                            <Paragraph style={{
                              textAlign: 'left',
                              paddingTop: scaleNormalizer(13),
                              ...Typography.fontWeight.bold
                            }}>
                              {'Budget update recommended!'}
                            </Paragraph>
                          </View>
                          <Paragraph>
                            {
                              spouseAlert
                                ? `You previously defined your budget without a partner, but have since added one. We recommend updating your budget to reflect this change in circumstances.`
                                : `You previously defined your budget with a partner, but have since removed them. We recommend updating your budget to reflect this change in circumstances.`
                            }
                          </Paragraph>
                        </>
                        : <></>
                    }
                    <Button
                      mode={spouseAlert || noSpouseAlert ? 'contained' : 'text'}                      
                      color={themeColors.accent}
                      onPress={() => dispatch(setEditRetirementBudgetVisible(true))}>
                        {`Update Your Budget`}
                      </Button>
                    <ContentDivider />
                  </>
                : <></>
              }
            </RefreshableScrollView>
          </View>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
  pageContainer: {
    paddingHorizontal: Sizing.x30,
  },
})