import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Subheading } from 'components/Typography'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { rootNavigate } from 'lib/RootNavigation'
import { getScreenAppHeight, scaleNormalizer } from 'lib/scaleHelpers'
import { OnboardingStateStepIdentifier } from 'providers'
import React from 'react'
import { ScrollView, View } from 'react-native'
import { useGetMeQuery, useGetUserAccountsQuery, useGetRetirementAssetsQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto } from 'store/dto/client.dto'
import { RetirementAssetDto } from 'store/dto/retirement-asset.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { onAssetSpouseView, setAddRetirementAssetClient, setAddSpouseVisible, setEditRetirementAsset } from 'store/uxSlice'
import { Sizing } from 'styles'
import { RetirementAssetsList } from '../Components/RetirementAssetsList'
import { filterAssetsInScopeForTransfer } from 'lib/retirementAssetHelpers'
import { AccountType } from 'store/dto/account.dto'
import { getPersonalPension } from 'lib/accountHelpers'
import { JAR_NAME_ALL, JAR_NAME_PERSONAL } from 'lib/constants'

export const RetirementAssetsScreen = () => {
  const dispatch = useAppDispatch()

  const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, isFetching: accountsIsFetching } = useGetUserAccountsQuery()
  const { data: assets, isLoading: assetsIsLoading, error: assetsError, refetch: refetchAssets } = useGetRetirementAssetsQuery()

  const personalPension = getPersonalPension(accounts)

  const transferableAssets = filterAssetsInScopeForTransfer(assets, me)
  const hasTransferableAssets = transferableAssets?.length

  const isLoading = meIsLoading || spouseIsLoading || accountsIsLoading || assetsIsLoading
  const error: any = assetsError || meError

  const onSpouseTab = useAppSelector(onAssetSpouseView)

  const appScreenHeight = getScreenAppHeight()
  const navigatorHeight = appScreenHeight - scaleNormalizer(300)

  return (
    <MainAppScreen>
      {
        isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchSpouse} /> :
        <OnboardingGuard
          explanation={`Your Retirement Assets screen will help you track other pensions and transfer them into your ${JAR_NAME_PERSONAL}`}
          onboardingSteps={[
            OnboardingStateStepIdentifier.ASSETS,
          ]}
        >
          <>
            <ScrollView style={{paddingHorizontal: Sizing.x30}}>
              <View style={{paddingTop: Sizing.x30}}>
                <Subheading>{`Keep track of all pensions, savings and other assets that you are keeping for retirement`}</Subheading>
              </View>
                <RetirementAssetsList
                  addAssetStateFunction={(client: ClientBaseDto) => dispatch(setAddRetirementAssetClient(client))}
                  editAssetStateFunction={(asset: RetirementAssetDto) => dispatch(setEditRetirementAsset(asset))}
                  addSpouseStateFunction={(visible: boolean) => dispatch(setAddSpouseVisible(visible))}
                  navigatorHeight={navigatorHeight}
                  refreshFunctions={[refetchAssets]}
                />
            </ScrollView>
            {
              onSpouseTab && !spouse ?
              <FooterButton
                onPress={() => dispatch(setAddSpouseVisible(true))}
              >{`Add Partner`}
              </FooterButton>
              : !onSpouseTab && hasTransferableAssets && personalPension ?
                <FooterButton
                  onPress={() => rootNavigate('BulkTransferIntroScreen')}
                >{`Consolidate Pensions`}
                </FooterButton>
              : <></>
            }
          </>
        </OnboardingGuard>
      }
    </MainAppScreen>
  )
}