import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminTransferDataId, setCurrentAdminTransferDataId } from 'store/uxSlice'
import { AdminTransferEditScreen } from './Screens/AdminTransferEditScreen'

export const AdminTransferEditModal = () => {
  const dispatch = useAppDispatch()
  const currentTransfer = useAppSelector(currentAdminTransferDataId)
  return (
    <ModalFormBuilder
      visible={!!currentTransfer}
      formTitle='Transfer Management'
      onDismiss={() => dispatch(setCurrentAdminTransferDataId(undefined))}
      screen={<AdminTransferEditScreen />}
    />
  )

}

