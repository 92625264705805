import { MaterialCommunityIcons } from '@expo/vector-icons'
import { MainAppHeader } from 'components/Layout/MainAppHeader'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph } from 'components/Typography'
import { Text } from 'components/Typography/Text'
import { ActionCard } from 'components/Utility/ActionCard'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { NamedInformation } from 'components/Utility/InformationButton'
import { Loading } from 'components/Utility/Loading'
import { ManagedCarousel } from 'components/Utility/ManagedCarousel'
import { PieChartCategory, PieChartWithLegend } from 'components/Utility/PieChartWithLegend'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { OnboardingGuard } from 'features/Onboarding/components/OnboardingGuard'
import { mainAppNavigate, rootNavigate } from 'lib/RootNavigation'
import { isNotFoundError } from 'lib/errorHelpers'
import { getScreenAppWidth } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { MainAppNavScreen } from 'lib/navigationHelpers'
import { concat, last } from 'lodash'
import { ModelGoalIdentifier, OnboardingStateStepIdentifier, useGuidanceContext } from 'providers'
import React, { useEffect, useRef } from 'react'
import { View } from 'react-native'
import { ThemeProvider } from 'react-native-paper'
import { useGetBeneficiariesQuery, useGetMeQuery, useGetSpouseQuery, useGetUserAccountsQuery } from 'store/apiSlice'
import { BeneficiaryRelationship } from 'store/dto/account.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setAddBeneficiaryData, setAdjustBeneficiaryProportionData, setEditBeneficiaryData, timelinePinned } from 'store/uxSlice'
import { Colors, Paper, Sizing, Typography } from 'styles'
import { layoutStyles } from 'styles/common'
import { DashboardHeroItem } from '../Components/DashboardHeroItem'
import { DashboardSection } from '../Components/DashboardSection'
import { DashboardSectionSet } from '../Components/DashboardSectionSet'
import { DashboardSwipeableScreen } from '../Components/DashboardSwipeableScreen'
import { SuggestionCardSet } from '../Components/SuggestionCardSet'
import { JAR_NAME_ALL, JAR_NAME_GROUP, JAR_NAME_PERSONAL } from 'lib/constants'
import { getGroupPension, getPersonalPension } from 'lib/accountHelpers'

// Some config
export const DashboardLegacy = ({ route, navigation }) => {

  const timelineIsPinned = useAppSelector(timelinePinned)
  const scrollRef = useRef()
  useEffect(() => {
    if (timelineIsPinned && scrollRef?.current) {
      scrollRef?.current?.scrollTo({ y: 0 })
    }
  }, [timelineIsPinned])

  const dispatch = useAppDispatch()

  const { currentViableAge, currentViableAgeIsLoading, currentViableAgeIsFetching, currentModel, currentModelIsLoading, currentModelIsFetching, currentModelError, getAvailableGoalActionCardProps } = useGuidanceContext()

  const { data: client, isLoading: clientIsLoading, error: clientError, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching } = useGetSpouseQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading } = useGetUserAccountsQuery()

  const personalPension = getPersonalPension(accounts)
  const groupPension = getGroupPension(accounts)
  const hasAnyPension = !!personalPension || !!groupPension

  const { data: beneficiaries, error: beneficiariesError, isLoading: beneficiariesIsLoading, isFetching: beneficiariesIsFetching, refetch: refetchBeneficiaries } = useGetBeneficiariesQuery(undefined, { skip: !hasAnyPension })

  const isLoading = clientIsLoading || spouseIsLoading || beneficiariesIsLoading || accountsIsLoading
  const error: any = clientError || accountsError || beneficiariesError
  const nominations = beneficiaries?.nominations || []

  const targetRetirementAgeIsMet =
    currentModel?.output?.targetRetirementAgeIsMet

  const getSurplusAmount = () => {
    if (!currentModel || !targetRetirementAgeIsMet) {
      return 0
    }
    const lastTerm = last(currentModel.terms)
    return lastTerm.startValue
  }

  const surplusAmount = getSurplusAmount()

  const getAssetValueAtRetirement = () => {
    if (!currentModel) {
      return 0
    }
    const viableRetirementAge = currentModel.output.viableRetirementAge
    const retirementTerm = currentModel.terms.find(term => {
      return term.age === viableRetirementAge
    })
    return retirementTerm ? retirementTerm.startValue : 0
  }

  const assetValueAtRetirement = getAssetValueAtRetirement()
  const totalDrawdown = assetValueAtRetirement - surplusAmount

  function RetirementDrawdownContent() {
    return (
      <View style={{ paddingHorizontal: Sizing.x30 }}>
        <UnborderedTable
          data={[
            {
              label: 'Assets At Retirement',
              value: <Text style={{ color: Colors.brand.red3 }}>
                {currentModel ? formatCurrencyAmount(assetValueAtRetirement) : '?'}
              </Text>,
              iconSource: <MaterialCommunityIcons name={'calendar'} size={Sizing.x20} color={Colors.neutral.white} />,
              isLoading: currentViableAgeIsLoading || currentModelIsLoading,
              isFetching: currentViableAgeIsFetching || currentModelIsFetching,
            },
            {
              label: `${totalDrawdown < 0 ? 'Growth During Retirement' : 'Estimated Total Spend'}${surplusAmount ? ' *' : ''}`,
              value: <Text style={{ color: Colors.brand.red3 }}>
                {currentModel ? formatCurrencyAmount(totalDrawdown * (totalDrawdown < 0 ? -1 : 1)) : '?'}
              </Text>,
              iconSource: <MaterialCommunityIcons name={totalDrawdown < 0 ? 'trending-down' : 'trending-up'} size={Sizing.x20} color={Colors.neutral.white} />,
              isLoading: currentViableAgeIsLoading || currentModelIsLoading,
              isFetching: currentViableAgeIsFetching || currentModelIsFetching,
            },
            {
              label: `Predicted Remaining${surplusAmount ? ' *' : ''}`,
              isTotal: true,
              value: <Text style={{ color: Colors.brand.red3, fontFamily: 'LabGrotesque-Medium' }}>
                {currentModel ? formatCurrencyAmount(surplusAmount) : '?'}
              </Text>,
              iconSource: <MaterialCommunityIcons name={'calculator'} size={Sizing.x20} color={Colors.neutral.white} />,
              isLoading: currentViableAgeIsLoading || currentModelIsLoading,
              isFetching: currentViableAgeIsFetching || currentModelIsFetching,
            }
          ]}
        />
        {
          surplusAmount ?
            <Paragraph style={{ ...Typography.fontSize.x10, textAlign: 'right' }}>
              {`* Assumes ${spouse ? 'Combined ' : ''}Life Expectancy of ${client?.lastSurvivorDeathAge}`}
            </Paragraph>
            : <></>
        }
      </View>
    )
  }

  function BeneficiaryContent() {
    const availableGoalActionCardProps = getAvailableGoalActionCardProps([
      ModelGoalIdentifier.INCREASE_LEGACY_SURPLUS,
    ], 'small')

    const sliderWidth = getScreenAppWidth() - Sizing.x60
    let tableData: UnborderedTableRow[] = []

    tableData.push({
      label: 'Beneficiary',
      value: 'Estimated Inheritance',
      isHeader: true,
    })

    tableData = concat(tableData, nominations ? nominations.map((nomination, idx) => {
      const value = formatCurrencyAmount(surplusAmount * nomination.beneficiaryProportion)

      return {
        label: `${nomination.firstName} ${nomination.beneficiaryRelationship === BeneficiaryRelationship.CHARITY ? '' : nomination.surname}`,
        value,
        isTotal: false,
        linkFunction: () => dispatch(setEditBeneficiaryData({
          editIndex: idx,
          allNominations: nominations,
          currentNomination: nomination,
        }))
      }
    }) : [])


    //Add total rows
    tableData.push({
      label: 'Overall',
      value: formatCurrencyAmount(surplusAmount),
      isTotal: true,
    })

    const renderGoalCard = ({ item, index }) => {
      return (
        <ActionCard
          {...item}
        />
      )
    }

    const subExplanation = !!groupPension
      ? undefined
      : `You can define beneficiaries once you have verified your identity and opened your ${JAR_NAME_PERSONAL}, or have a ${JAR_NAME_GROUP} by connecting an employer.`

    const onboardingSteps = !!groupPension
      ? [
          OnboardingStateStepIdentifier.BENEFICIARIES,
        ] 
      : [
          OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
          OnboardingStateStepIdentifier.PLAN_CHOICE,
          OnboardingStateStepIdentifier.BENEFICIARIES,
        ]

    return (
      <OnboardingGuard
        explanation={`The beneficiaries you define for your ${JAR_NAME_ALL} can be used in conjunction with your will to decide who receives any surplus capital when the time comes.`}
        size={'small'}
        subExplanation={subExplanation}
        onboardingSteps={onboardingSteps}
      >
        <View style={{ paddingHorizontal: Sizing.x30 }}>
          <Paragraph>{
            nominations?.length
              ? `You currently have ${nominations?.length} defined ${nominations?.length === 1 ? 'beneficiary' : 'beneficiaries'}`
              : `You don't yet have any beneficiaries defined`
          }
          </Paragraph>
          {
            nominations
              ? <UnborderedTable
                isLoading={beneficiariesIsLoading}
                error={error}
                data={tableData}
              />
              : <></>
          }
          {!nominations || nominations?.length < 4 ?
            <Button mode='text' onPress={() => dispatch(setAddBeneficiaryData({ allNominations: nominations }))}>{`Add Beneficiary`}</Button>
            : <Paragraph>{`You cannot add more than 4 beneficiaries`}</Paragraph>
          }
        </View>
        <OnboardingGuard
          explanation={`To see options for how to increase the amount money you might be able to leave to your beneficiaries, open your ${JAR_NAME_PERSONAL} today!`}
          size={'small'}
          onboardingSteps={[
            OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
            OnboardingStateStepIdentifier.PLAN_CHOICE,
          ]}
        >
          <Paragraph>{`Jarvis can help you understand and provide options to influence the amount of money you might be able to leave to beneficiaries.`}</Paragraph>
          {
            currentViableAge && availableGoalActionCardProps?.length ?
            <View style={{ paddingHorizontal: Sizing.x30 }}>
              <ManagedCarousel
                loop={false}
                width={sliderWidth}
                height={Sizing.x120}
                data={availableGoalActionCardProps}
                renderItem={renderGoalCard}
                isLoading={currentModelIsLoading}
                isFetching={currentModelIsFetching}
              />
            </View>
            : <View style={{ paddingHorizontal: Sizing.x30 }}>
                <Paragraph>{`In order to do this, your retirement planning needs to show that you will meet your retirement target age, and therefore have surplus capital available.`}</Paragraph>
              </View>
          }
        </OnboardingGuard>
      </OnboardingGuard>
    )
  }

  function InheritanceBreakdownContent() {
    const nominations = beneficiaries?.nominations || []
    const categories: PieChartCategory[] = nominations.map(nomination => {
      const name = `${nomination.firstName} ${nomination.beneficiaryRelationship === BeneficiaryRelationship.CHARITY ? '' : nomination.surname}`
      return {
        name,
        items: [
          {
            x: '',
            y: nomination.beneficiaryProportion,
          }
        ]
      }
    })
    const dataSet = {
      categories,
    }

    const noData = !nominations.length

    const subExplanation = !!groupPension
      ? undefined
      : `You can define beneficiaries once you have verified your identity and opened your ${JAR_NAME_PERSONAL}, or have a ${JAR_NAME_GROUP} by connecting an employer.`

    const onboardingSteps = !!groupPension
      ? [
          OnboardingStateStepIdentifier.BENEFICIARIES,
        ] 
      : [
          OnboardingStateStepIdentifier.IDENTITY_VERIFICATION,
          OnboardingStateStepIdentifier.PLAN_CHOICE,
          OnboardingStateStepIdentifier.BENEFICIARIES,
        ]

    return (
      <>
        <OnboardingGuard
          explanation={'Your Inheritance Breakdown helps you visualise how any remaining surplus capital in your Jarvis plan and other assets would be distributed, based on the beneficiaries you define.'}
          size={'small'}
          subExplanation={subExplanation}
          onboardingSteps={onboardingSteps}
        >
          {
            noData
              ? <>
                  <View style={{ paddingVertical: Sizing.x20 }}>
                    <Paragraph>{`Add some beneficiaries to see how any residual savings would be distributed.`}</Paragraph>
                  </View>
                  <View style={{ paddingHorizontal: Sizing.x30 }}>
                    <Button mode='text' onPress={() => rootNavigate('BeneficiariesSetupIntroScreen')}>{`Add Beneficiaries`}</Button>
                  </View>
                </>
              : <>
                  <PieChartWithLegend dataSet={dataSet} />
                  <View style={{ paddingHorizontal: Sizing.x30 }}>
                    {
                      nominations && nominations?.length > 1 ?
                        <Button mode='text' onPress={() => dispatch(setAdjustBeneficiaryProportionData({ nominations }))}>{`Adjust Proportions`}</Button>
                        : <></>
                    }
                    {/* <Paragraph>{`Estimated Surplus Capital: ${formatCurrencyAmount(surplusAmount)}`}</Paragraph> */}
                  </View>
                </>
          }
        </OnboardingGuard>
        <View style={{ height: Sizing.x100 }}></View>
      </>
    )
  }

  return (
    <>
      <MainAppHeader
        sectionTitle={'Legacy'}
        sectionTitleInfo={NamedInformation.SECTION_DASHBOARD_LEGACY}
      />
        <DashboardSwipeableScreen
          leftNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_RETIREMENT })}
          leftNavigationLabel={'RETIREMENT'}
        >
          {
            isLoading ? <Loading useHolidayGraphics={true} /> : error ? <ErrorScreen error={error?.data} noDashboardButton={true} /> :
              <RefreshableScrollView
                ref={scrollRef}
                refreshFunctions={[
                  refetchClient,
                  refetchBeneficiaries,
                ]}
              >
                <View style={layoutStyles.dashboardMainContent}>
                  <DashboardHeroItem
                    error={clientError}
                    errorTryAgain={refetchClient}
                    isLoading={clientIsLoading}
                    isFetching={clientIsFetching}
                    title={`Plan Until Age`}
                    footer={currentModel ? formatCurrencyAmount(surplusAmount) : ''}
                    heroText={client?.lastSurvivorDeathAge.toString()}
                    leftNavigationFunction={() => mainAppNavigate(MainAppNavScreen.DASHBOARD, { screen: MainAppNavScreen.DASHBOARD_RETIREMENT })}
                  />
                  <SuggestionCardSet screen={MainAppNavScreen.DASHBOARD_LEGACY} />
                </View>

                {
                  //First Dashboard section for suggestions
                  <ThemeProvider theme={Paper.lightThemeOnDarkerPurple}>
                    <DashboardSection
                      visible={true}
                      isLoading={currentModelIsLoading}
                      isFetching={currentModelIsFetching}
                      title={totalDrawdown < 0 ? `Retirement Growth` : `Retirement Drawdown`}
                      titleInfoName={NamedInformation.DASHBOARD_LEGACY_RETIREMENT_DRAWDOWN}
                      content={<RetirementDrawdownContent />}
                      previousColor={Colors.brand.purple0}
                    />
                  </ThemeProvider>
                }


                <DashboardSectionSet
                  sections={[
                    {
                      title: `Beneficiaries`,
                      titleInfoName: NamedInformation.DASHBOARD_LEGACY_BENEFICIARIES,
                      visible: true,
                      isLoading: beneficiariesIsLoading,
                      isFetching: beneficiariesIsFetching,
                      error: beneficiariesError && !isNotFoundError(beneficiariesError),
                      refreshFunction: refetchBeneficiaries,
                      content: <BeneficiaryContent />
                    },
                    {
                      title: `Inheritance Breakdown`,
                      titleInfoName: NamedInformation.DASHBOARD_LEGACY_INHERITANCE_BREAKDOWN,
                      visible: true,
                      isLoading: beneficiariesIsLoading,
                      isFetching: beneficiariesIsFetching,
                      error: beneficiariesError && !isNotFoundError(beneficiariesError),
                      refreshFunction: refetchBeneficiaries,
                      content: <InheritanceBreakdownContent />
                    },
                  ]}
                />
              </RefreshableScrollView>
          }
        </DashboardSwipeableScreen>
    </>
  )
}

