import { BaseDatedDto, ContributionSource } from './base.dto';

export interface BankConnectionDto extends BaseDatedDto {
  id: string
  type: 'bank-connection'
  moneyHubId: string
  moneyHubName: string
  moneyHubBankRef: string
  name: string
  description?: string
  logo?: string
  contributionSources: ContributionSource[]
  paymentTypes: MoneyHubBankConnectionPaymentType[]
  isBeta: boolean
}

export interface BankConnectionFilterDto {
  search?: string
  moneyHubId?: string
}

export interface CreateBankConnectionDto {
  moneyHubId: string
  moneyHubName: string
  moneyHubBankRef: string
  name: string
  description?: string
  logo?: string
  contributionSources: ContributionSource[]
  paymentTypes: MoneyHubBankConnectionPaymentType[]
  isBeta: boolean
}

export interface UpdateBankConnectionDto {
  id: string
  moneyHubName?: string
  moneyHubBankRef?: string
  name?: string
  description?: string
  logo?: string
  contributionSources?: ContributionSource[]
  paymentTypes?: MoneyHubBankConnectionPaymentType[]
  isBeta?: boolean
}

export enum MoneyHubBankConnectionPaymentType {
  DOMESTIC = 'domestic',
  STANDING_ORDER = 'standing_order',
}