import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentEmployerRequestDataId, setCurrentEmployerRequestDataId } from 'store/uxSlice'
import { EmployerRequestEditScreen } from './Screens/EmployerRequestEditScreen'

export const EmployerRequestEditModal = () => {
  const dispatch = useAppDispatch()
  const currentClient = useAppSelector(currentEmployerRequestDataId)
  return (
    <ModalFormBuilder
      visible={!!currentClient}
      formTitle='Request Details'
      onDismiss={() => dispatch(setCurrentEmployerRequestDataId(undefined))}
      screen={<EmployerRequestEditScreen />}
    />
  )

}

