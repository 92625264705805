import { MaterialCommunityIcons } from '@expo/vector-icons'
import { MainAppScreen } from 'components/ScreenTemplates/MainAppScreen'
import { Headline, Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { FooterButton } from 'components/Utility/FooterButton'
import { Loading } from 'components/Utility/Loading'
import { scaleNormalizer } from 'lib/scaleHelpers'
import { rootNavigate } from 'lib/RootNavigation'
import React, { useState } from 'react'
import { Image, Pressable, ScrollView, StyleSheet, View } from 'react-native'
import { Portal } from 'react-native-paper'
import { useDispatch } from 'react-redux'
import { ampli } from 'src/ampli'
import { useDeleteInviteMutation, useGetInvitesQuery, useGetMeQuery, useGetSpouseQuery, useResendInviteMutation } from 'store/apiSlice'
import { InviteStatus } from 'store/dto/invite.dto'
import { setAddSpouseVisible, setEditAvatarClient, setEditSpouseVisible } from 'store/uxSlice'
import { Colors, Flex, Sizing } from 'styles'
import { layoutStyles } from 'styles/common'
import { InviteShareModal } from 'components/Utility/InviteShareModal'
import { AmlStatus } from 'store/dto/client.dto'
import { platformIsWeb } from 'lib/platformHelpers'
import { SuggestionIdentifier, useSuggestionsContext } from 'providers/suggestions.context'
import { SuggestionCard } from 'components/Utility/SuggestionCard'

const isWeb = platformIsWeb()

const RESEND_MINUTES_LIMIT = 1

export const SpouseScreen = () => {

  const { getSuggestionByIdentifier } = useSuggestionsContext()

  const inviteSuggestion = getSuggestionByIdentifier(SuggestionIdentifier.PARTNER_INVITE_OPEN)

  const { data: client, error: clientError, isLoading: clientIsLoading, isFetching: clientIsFetching, refetch: refetchClient } = useGetMeQuery()
  const { data: spouse, isLoading: spouseIsLoading, isFetching: spouseIsFetching, refetch: refetchSpouse } = useGetSpouseQuery()
  const { data: invites, error: invitesError, isLoading: invitesIsLoading, refetch: refetchInvites } = useGetInvitesQuery()

  const [dialogVisible, setDialogVisible] = useState(false)

  const refetchAll = () => {
    refetchClient()
    refetchSpouse()
    refetchInvites()
  }

  const [resendInvite] = useResendInviteMutation()
  const [revokeInvite] = useDeleteInviteMutation()

  const [resendDisabled, setResendDisabled] = useState(false)
  const [shareModalVisible, setShareModalVisible] = useState(false)

  const error: any = invitesError || clientError
  const isLoading = spouseIsLoading || invitesIsLoading || spouseIsFetching || clientIsLoading || clientIsFetching

  const dispatch = useDispatch()

  const clientIsVerified = client?.amlStatus === AmlStatus.PASS

  //Check for open invite
  const openInvites = invites ? invites.filter(invite => {
    return invite.status === InviteStatus.OPEN
  }) : []
  const openInvite = openInvites.length ? openInvites[0] : undefined
  
  const resendOpeninvite = () => {
    if (openInvite) {
      resendInvite(openInvite.id)
      ampli.inviteResend({
        email: openInvite?.email,
        invitorUserId: openInvite?.userId
      })
      setResendDisabled(true)
      setTimeout(() => setResendDisabled(false), RESEND_MINUTES_LIMIT * 60000)
    }
  }

  const revokeOpenInvite = () => {
    if (openInvite) {
      const copy = {...openInvite}
      revokeInvite(openInvite.id)
      ampli.inviteRevoke({
        email: copy?.email,
        invitorUserId: copy?.userId
      })
    }
  }

  return (
    <>
      <MainAppScreen>
        {isLoading ? <Loading /> : error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> :
          <View style={[
            layoutStyles.mainContentContainer
            ]} >
              <ScrollView
                contentContainerStyle={layoutStyles.scrollContainerContent}            
                showsVerticalScrollIndicator={isWeb}
              >
              {
                spouse ?
                <>
                  <View style={localStyles.profileImageContainer}>
                    <Pressable onPress={spouse?.userId ? () => setDialogVisible(true ): () => dispatch(setEditAvatarClient(spouse))}>
                      <Image source={{ uri: spouse?.avatar || spouse?.picture }} style={localStyles.profileImage} />
                    </Pressable>
                  </View>

                  <Headline>{`${spouse?.firstName} ${spouse?.surname}`}</Headline>
                  <Subheading>{`${spouse?.userId ? spouse?.email : openInvite ? openInvite?.email : 'Not yet invited'}`}</Subheading>
                  <View style={localStyles.buttonContainer}>
                    <Button
                      mode='text'
                      color={Colors.brand.red3}
                      onPress={() => dispatch(setEditSpouseVisible(true))}>
                      {'Change Details'}
                    </Button>
                  </View>
                </>
                :
                <>
                  <View style={localStyles.profileImageContainer}>
                    <View style={[localStyles.profileImage, {
                      backgroundColor: Colors.neutral.s400,
                      borderRadius: scaleNormalizer(100),
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }]}>
                      <MaterialCommunityIcons name="account" size={Sizing.x150} color={Colors.neutral.white}/>
                    </View>
                  </View>

                  <Headline>{`Your Partner`}</Headline>
                  <Subheading>{`Not yet added`}</Subheading>
                </>
              }
              {
                inviteSuggestion
                  ? <View style={{
                      paddingHorizontal: Sizing.x20,
                    }}>
                      <SuggestionCard
                        index={0}
                        suggestion={inviteSuggestion}
                        onDismissal={() => {}}
                      />
                    </View>
                  : <></>
              }
              </ScrollView>
              {
                !spouse?.userId ?
                  <View style={{
                    ...Flex.override.bottom,
                    paddingVertical: Sizing.x20,
                    paddingHorizontal: Sizing.x30,

                  }}>
                    <Paragraph>
                      { !spouse
                        ? `Jarvis can help you plan for your retirement with a partner, taking into account your personal circumstances, and all the pensions, savings and incomes you hold between you.`
                        : spouse && openInvite ? `An invititation email has been sent to ${spouse?.firstName} to join Jarvis and connect accounts.`
                        : !clientIsVerified ? `To invite ${spouse?.firstName} to help plan your retirement together, please first verify your identity.`
                        : spouse ? `Invite ${spouse?.firstName} to join Jarvis and get their own pension, and you can work together on your retirement planning.`
                        : <></>
                      }
                    </Paragraph>
                  </View>
                  : <></>
              }
              {
                !spouse ?
                <FooterButton
                  onPress={() => dispatch(setAddSpouseVisible(true))}>
                  {`Add Partner`}
                </FooterButton>
                : spouse?.userId ? <></>
                : openInvite ?
                  <>
                    <FooterButton
                      mode='text'
                      color={Colors.brand.red3}
                      onPress={revokeOpenInvite}
                      confirmation={{
                        requiresConfirmation: true,
                        dialogContent: `If you revoke the invitation, ${openInvite?.firstName} will not be able to accept it.`
                      }}
                    >{`Revoke Invitation`}</FooterButton>
                    <FooterButton
                      mode='text'
                      color={Colors.brand.red3}
                      onPress={resendOpeninvite}
                      disabled={resendDisabled}
                    >{`Resend Invitation Email`}</FooterButton>
                    <FooterButton
                      onPress={() => setShareModalVisible(true)}
                    >{`Share Partner Invitation Link`}</FooterButton>
                  </>
                : clientIsVerified ?
                  <FooterButton
                    onPress={() => rootNavigate('SpouseInviteIntroScreen')}>
                    {`Invite ${spouse?.firstName}`}
                  </FooterButton>
                : <FooterButton
                    onPress={() => rootNavigate('ClientVerificationIntroScreen')}>
                    {`Verify Your Identity`}
                  </FooterButton>
              }
          </View>
        }
        <Portal>
          <InviteShareModal
            invite={openInvite}
            onDismiss={() => setShareModalVisible(false)}
            visible={shareModalVisible}
          />
        </Portal>
        <ConfirmationDialog
          visible={dialogVisible}
          title={'Sorry!'}
          content={`Only ${spouse?.firstName} can change their profile picture.`}
          onConfirm={() => setDialogVisible(false)}
        />
      </MainAppScreen>
    </>
  )
}

const localStyles = StyleSheet.create({
  profileImageContainer: {
    paddingVertical: Sizing.x30,
  },
  profileImage: {
    alignSelf: 'center',
    height: scaleNormalizer(200),
    width: scaleNormalizer(200),
    borderRadius: scaleNormalizer(100),
  },
  buttonContainer: {
    paddingHorizontal: Sizing.x30,
  },
})
