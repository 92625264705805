import { MaterialCommunityIcons } from '@expo/vector-icons'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useFocusEffect } from '@react-navigation/native'
import { ContentDivider } from 'components/Layout/ContentDivider'
import { RefreshableScrollView } from 'components/ScreenTemplates/RefreshableScrollView'
import { Paragraph, Subheading } from 'components/Typography'
import { Button } from 'components/Utility/Button'
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { Loading } from 'components/Utility/Loading'
import { getScreenAppHeight, scaleNormalizer } from 'lib/scaleHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { getLookingLoadingMessages } from 'lib/loadingHelpers'
import { getIncomeCategory, getIncomeIconName, RetirementIncomeCategory, sortIncomes } from 'lib/retirementIncomeHelpers'
import { sumBy } from 'lodash'
import React, { useCallback, useRef } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useGetMeQuery, useGetRetirementIncomesQuery, useGetSpouseQuery } from 'store/apiSlice'
import { ClientBaseDto, ClientMeDto } from 'store/dto/client.dto'
import { RetirementIncomeDto } from 'store/dto/retirement-income.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  onIncomeSpouseView, setOnIncomeSpouseView
} from 'store/uxSlice'
import { Colors, Paper, Flex, Sizing, Typography } from 'styles'

const Tab = createMaterialTopTabNavigator()
interface RetirementIncomesListProps {
  showAddSpouse?: boolean
  addIncomeStateFunction: Function
  editIncomeStateFunction: Function
  addStatePensionStateFunction: Function
  editStatePensionStateFunction: Function
  addSpouseStateFunction: Function
  navigatorHeight: number
  refreshFunctions: Function[]
}

export const RetirementIncomesList = (props: RetirementIncomesListProps) => {
  const { showAddSpouse, addIncomeStateFunction, editIncomeStateFunction, addStatePensionStateFunction, editStatePensionStateFunction, addSpouseStateFunction, navigatorHeight, refreshFunctions  } = props

  const { data: spouse } = useGetSpouseQuery()

  const { colors: themeColors } = Paper.useAppTheme()

  const onSpouseTab = useAppSelector(onIncomeSpouseView)

  type IncomeListProps = {
    client: ClientBaseDto
    otherClient: ClientBaseDto
    incomes: RetirementIncomeDto[]
  }
  const IncomeList = (props: IncomeListProps) => {
    const { client, otherClient, incomes } = props
    
    const clientIncomes = incomes.filter((income) => {
      return income.clientId === client?.id
    })
  
    const otherclientAmount = otherClient
      ? sumBy(
        incomes.filter((income) => {
          return income.clientId !== client?.id
        }),
        'annualIncomeAmount'
      ) + otherClient.statePensionAmount
      : 0
  
    const overallAmount = sumBy(incomes, 'annualIncomeAmount') +
      client?.statePensionAmount +
      (otherClient?.statePensionAmount || 0)
  
    const scrollRef = useRef()

    return (
      <View style={localStyles.listContainer}>
        <RefreshableScrollView
          ref={scrollRef}
          refreshFunctions={refreshFunctions}
          showsVerticalScrollIndicator={true}
          keyboardShouldPersistTaps='always'
        >
          {client?.statePensionAmount ? (
            <StatePensionRow
              client={client}
              onPress={() => editStatePensionStateFunction(client)}
            />
          ) : (
            <>
              { !client?.isSpouse || (client?.isSpouse && !client?.userId) ?
                  <AddRow
                    title={'Add state pension'}
                    onPress={() => addStatePensionStateFunction(client)}
                  /> : <></>
              }
            </>
          )}
          {clientIncomes ? (
            sortIncomes(clientIncomes).map((income, idx) => {
              return (
                <RetirementIncomeRow
                  key={idx}
                  income={income}
                  onPress={() => editIncomeStateFunction(income)}
                />
              )
            })
          ) : (
            <></>
          )}
          { !client?.isSpouse || (client?.isSpouse && !client?.userId) ?
            <AddRow
              title={'Add a retirement income'}
              onPress={() => addIncomeStateFunction(client)}
            /> : <></>
          }
          {otherClient ? (
            <OtherTotalRow otherClient={otherClient} amount={otherclientAmount} />
          ) : (
            <></>
          )}
          <OverallTotalRow amount={overallAmount} />
        </RefreshableScrollView>
      </View>
    )
  }
  
  type AddRowProps = {
    title: string
    onPress?: any
  }
  const AddRow = (props: AddRowProps) => {
    const { title, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    return (
      <View>
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer}>
            <MaterialCommunityIcons
              name={'plus-circle'}
              size={Sizing.x30}
              color={themeColors.primary}
            />
          </View>
          <View>
            <Subheading>{title}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading> </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }
  
  type OtherTotalRowProps = {
    otherClient: ClientBaseDto
    amount: number
  }
  const OtherTotalRow = (props: OtherTotalRowProps) => {
    const { amount, otherClient } = props
    return (
      <View>
        <View style={localStyles.itemContainer}>
          <View>
            <Subheading numberOfLines={1}>{`${otherClient?.firstName}'s Total`}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>{formatCurrencyAmount(amount)}</Subheading>
          </View>
        </View>
        <ContentDivider />
      </View>
    )
  }
  
  type OverallTotalRowProps = {
    amount: number
  }
  const OverallTotalRow = (props: OverallTotalRowProps) => {
    const { amount } = props
    return (
      <View>
        <View style={localStyles.itemContainer}>
          <View>
            <Subheading style={{ ...Typography.fontWeight.bold}}>
              Overall
            </Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading style={{ ...Typography.fontWeight.bold }}>
              {formatCurrencyAmount(amount)}
            </Subheading>
          </View>
        </View>
        <ContentDivider />
      </View>
    )
  }
  
  type StatePensionRowProps = {
    client: ClientBaseDto
    onPress?: any
  }
  const StatePensionRow = (props: StatePensionRowProps) => {
    const { client, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const iconName: any = getIncomeIconName(RetirementIncomeCategory.STATE)
    return (
      <View>
        <Pressable style={localStyles.itemContainer} onPress={onPress}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{'State Pension'}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>
              {formatCurrencyAmount(client?.statePensionAmount)}
            </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }
  
  type RetirementIncomeRowProps = {
    income: RetirementIncomeDto
    onPress?: any
  }
  const RetirementIncomeRow = (props: RetirementIncomeRowProps) => {
    const { income, onPress } = props
    const { colors: themeColors } = Paper.useAppTheme()
    const incomeCategory = getIncomeCategory(income.incomeType)
    const iconName: any = getIncomeIconName(incomeCategory, income.incomeType)
    return (
      <View>
        <Pressable style={localStyles.itemContainer} onPress={income?.id ? onPress : undefined}>
          <View style={localStyles.iconContainer} >
            <MaterialCommunityIcons name={iconName} size={Sizing.x20} color={themeColors.primary}/>
          </View>
          <View style={{flex:1}}>
            <Subheading numberOfLines={1} style={{textAlign:'left' }} >{income.name}</Subheading>
          </View>
          <View style={localStyles.amountContainer}>
            <Subheading>
              {formatCurrencyAmount(income.annualIncomeAmount)}
            </Subheading>
          </View>
        </Pressable>
        <ContentDivider />
      </View>
    )
  }
  
  const ClientScreen = () => {
    //Update UX state when focused
    const dispatch = useAppDispatch()
    useFocusEffect(
      useCallback(() => {
        dispatch(setOnIncomeSpouseView(false))
      }, [])
    )
  
    const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
    const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
    const { data: incomes, error: incomesError, isLoading: incomesIsLoading, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchIncomes()
    }

    const error: any = meError || incomesError
    const isLoading = meIsLoading || incomesIsLoading || spouseIsLoading
  
    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your incomes...')}/> : <></>
            }
          </View>
          :
          <IncomeList
            client={me}
            otherClient={spouse}
            incomes={incomes}
          />
        }
      </>
    )
  }
  
  const SpouseScreen = () => {
    //Update UX state when focused
    const dispatch = useAppDispatch()
    useFocusEffect(
      useCallback(() => {
        dispatch(setOnIncomeSpouseView(true))
      }, [])
    )
  
    const { data: me, error: meError, isLoading: meIsLoading, refetch: refetchMe } = useGetMeQuery()
    const { data: spouse, isLoading: spouseIsLoading, refetch: refetchSpouse } = useGetSpouseQuery()
    const { data: incomes, error: incomesError, isLoading: incomesIsLoading, refetch: refetchIncomes } = useGetRetirementIncomesQuery()

    const refetchAll = () => {
      refetchMe()
      refetchSpouse()
      refetchIncomes()
    }

    const error: any = meError || incomesError
    const isLoading = meIsLoading || incomesIsLoading
  
    return (
      <>
        {
          error || isLoading ?
          <View style={{ height: Sizing.x140 }}>
            {
              error ? <ErrorScreen error={error?.data} errorTryAgain={refetchAll} /> : isLoading ? <Loading message={getLookingLoadingMessages('Loading your incomes...')} /> : <></>
            }
          </View>
          : spouse ?
          <IncomeList
            client={spouse}
            otherClient={me}
            incomes={incomes}
          />
          :
          <View style={{...Flex.column.start}} >
            <View style={{
              marginTop: Sizing.x30,
              marginHorizontal: Sizing.x15,
            }}>
              <Paragraph style={{textAlign: 'center', paddingVertical: Sizing.x20}}>First, add your partner to start creating your shared retirement universe and see everything in one place</Paragraph>
              {
                showAddSpouse ?
                <Button mode='text' onPress={() => addSpouseStateFunction(true)} color={Colors.brand.red3}>Add Partner</Button>
                : <></>
              }
            </View>
          </View>
  
        }
      </>
    )
  }
  
  return (
    <View style={{ height: scaleNormalizer(navigatorHeight)}}>
      <Tab.Navigator
        initialRouteName={onSpouseTab ? 'Spouse' : 'Me'}
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        screenOptions={{
          tabBarActiveTintColor: themeColors.accent,
          tabBarInactiveTintColor: themeColors.primary,
          tabBarStyle: {
            backgroundColor: 'transparent',
          },
          tabBarIndicatorStyle: {
            backgroundColor: themeColors.accent,
          },
        }}
      >
        <Tab.Screen
          name={'Me'}
          component={ClientScreen}
          options={{ title: 'You' }}
        />
        <Tab.Screen
          name={'Spouse'}
          component={SpouseScreen}
          options={{ title: spouse ? spouse?.firstName : 'Partner' }}
        />
      </Tab.Navigator>
    </View>
  )
}

const localStyles = StyleSheet.create({
  listContainer: {
    paddingTop: Sizing.x20
  },
  header: {
    ...Flex.row.between
  },
  iconContainer: {
    paddingRight: Sizing.x5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: Sizing.x10
  },
  itemContainer: {
    paddingTop: Sizing.x10,
    ...Flex.row.start
  },
  amountContainer: {
    ...Flex.override.right
  },
  total: {
    paddingVertical: Sizing.x10,
    ...Flex.row.between
  }
})
