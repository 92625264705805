import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { AssetAllocationDto } from 'store/dto/account.dto'
import { InvestmentAssetAdjustAllocationScreen } from './Screens/InvestmentAssetAdjustAllocationScreen'

export type InvestmentAssetAdjustAllocationModalProps = {
  assetAllocation: AssetAllocationDto[]
  onDismiss: Function
  onAdjustFunction: Function  
}

const InvestmentAssetAdjustAllocationModal = (props: InvestmentAssetAdjustAllocationModalProps) => {
  const { assetAllocation, onDismiss, onAdjustFunction } = props

  return (
    <ModalFormBuilder
      visible={!!assetAllocation}
      formTitle={'Adjust Allocation'}
      onDismiss={onDismiss}
      screen={<InvestmentAssetAdjustAllocationScreen
        assetAllocation={assetAllocation}
        onDismiss={onDismiss}
        onAdjustFunction={onAdjustFunction}
      />}
    />
  )
}

export default InvestmentAssetAdjustAllocationModal
