import { ModalFormBuilder } from 'navigation/stacks/ModalFormBuilder'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { currentAdminPensionBrandDataId, setCurrentAdminPensionBrandDataId } from 'store/uxSlice'
import { AdminEditPensionBrandScreen } from './Screens/AdminEditPensionBrandScreen'

export const AdminEditPensionBrandsModal = () => {
  const dispatch = useAppDispatch()
  const currentPensionBrand = useAppSelector(currentAdminPensionBrandDataId)
  return (
    <ModalFormBuilder
      visible={!!currentPensionBrand}
      formTitle='Edit Pension Brand Details'
      onDismiss={() => dispatch(setCurrentAdminPensionBrandDataId(undefined))}
      screen={<AdminEditPensionBrandScreen />}
    />
  )

}

