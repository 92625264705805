import { ModalProcessScreen } from 'components/Layout'
import { Subheading } from 'components/Typography'
import { BulletItem } from "components/Typography/BulletItem"
import { Text } from 'components/Typography/Text'
import { NamedInformation, NamedInformationButton } from 'components/Utility/InformationButton'
import { MoneyHubInfo } from 'components/Utility/MoneyHubInfo'
import { UnborderedTable, UnborderedTableRow } from 'components/Utility/UnborderedTable'
import { format } from 'date-fns'
import { openURL, parse, useURL } from 'expo-linking'
import { formatOrdinalDay } from 'lib/dateHelpers'
import { formatCurrencyAmount } from 'lib/generalHelpers'
import { platformIsWeb } from 'lib/platformHelpers'
import { captureException } from 'platform-lib/sentry'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import 'react-native-get-random-values'; //this must always be before importing uuid
import { useAddRecurringContributionAuthMutation, useGetBankConnectionsQuery, useGetStatusQuery } from 'store/apiSlice'
import { MoneyHubBankConnectionPaymentType } from 'store/dto/bank-connection.dto'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingContributionSetupPayment, workingContributionPayment, workingContributionSetup } from 'store/tempDataSlice'
import { setSnackbarData } from 'store/uxSlice'
import { Sizing } from 'styles'
import { v4 as uuid } from 'uuid'
import { BankLink } from '../Components/BankLink'

export const MakeContributions_25_RegularConfirm = ({ route, navigation }) => {
  const { nextScreen, contributionsData, onDismiss } = route?.params || {}

  const [state, setState] = useState(uuid())
  const [nonce, setNonce] = useState(uuid())

  const [paymentAuthError, setPaymentAuthError] = useState(false)
  const [processing, setProcessing] = useState(false)

  const { data: status, isLoading: statusIsLoading, error: statusError, refetch: refetchStatus } = useGetStatusQuery()
  const { data: banks, error: banksError, isLoading: banksIsLoading, refetch: refetchBanks } = useGetBankConnectionsQuery()

  const [authRecurringContribution, { data: recurringPaymentData, error: recurringPaymentError, isLoading: recurringPaymentLoading }] = useAddRecurringContributionAuthMutation()

  const isLoading = recurringPaymentLoading || banksIsLoading || processing
  const error: any = recurringPaymentError || banksError
  const workingContributionPaymentData = useAppSelector(workingContributionPayment)

  const dispatch = useAppDispatch()

  const { amount, firstPaymentDate } = workingContributionPaymentData || {}

  const isWeb = platformIsWeb()
  const url = isWeb ? undefined : useURL()
  
  //Go to open banking when we get the authUrl
  useEffect(() => {
    if (recurringPaymentData?.authUrl) {
      setProcessing(true)
      openURL(recurringPaymentData?.authUrl)
    }
  }, [recurringPaymentData])

  //Get the authUrl when user hits proceed
  const doAuthPayment = async () => {
    authRecurringContribution({
      amount: workingContributionPaymentData?.amount,
      firstPaymentDate: workingContributionPaymentData?.firstPaymentDate,
      state,
      nonce,
    })
    //Needed for useEffect below - refactor needed
    dispatch(updateWorkingContributionSetupPayment({
      paymentToExecute: {
        state,
      },
    }))
  }

  const doManualPayment = async () => {
    dispatch(updateWorkingContributionSetupPayment({
      manualSetup: true,
      paymentToExecute: undefined,
    }))
    navigation.navigate(nextScreen)
  }
  
  //Handle the URL on return from open banking
  //Update the working payment data and go to next screen
  useEffect(() => {
    if (url && workingContributionPaymentData?.paymentToExecute) {
      const processedUrl = url.replace('#', '?')
      const parsedUrl = parse(processedUrl)
      if (
        workingContributionPaymentData?.paymentToExecute?.state !== parsedUrl?.queryParams?.state ||
        !parsedUrl?.queryParams?.code ||
        !parsedUrl?.queryParams?.id_token
      ) {
        setPaymentAuthError(true)
        dispatch(setSnackbarData({
          message: `There was an error authorizing the payment`,
          iconName: `alert`,
          duration: 5000
        }))
        setProcessing(false)
        captureException(new Error('Failed standing order creation attempt'), {
          extra: {
            recurringPaymentData,
            parsedUrl,
            contributionsData,
          },
        })

      } else {
        setPaymentAuthError(false)
        dispatch(updateWorkingContributionSetupPayment({
          amount: +workingContributionPaymentData?.amount,
          dayOfMonth: workingContributionPaymentData?.dayOfMonth,
          manualSetup: false,
          paymentToExecute: {
            code: parsedUrl?.queryParams?.code,
            state: parsedUrl?.queryParams?.state,
            nonce,
            id_token: parsedUrl?.queryParams?.id_token,
          }
        }))
        navigation.navigate('RegularSave')
      }
    }
  }, [url])

  const workingContributionData = useAppSelector(workingContributionSetup)
  const contributionConfiguration = contributionsData?.existingContributionConfiguration || workingContributionData

  const contributionBank = banks ? banks.find(bank => {
    return bank.moneyHubId === contributionConfiguration?.bankId
  }) : undefined

  const useOpenBanking = contributionBank && contributionBank?.paymentTypes?.includes(MoneyHubBankConnectionPaymentType.STANDING_ORDER)

  const standingOrderData: UnborderedTableRow[] = [
    {
      label: `Monthly Amount`,
      value: formatCurrencyAmount(amount, 2),
    },
    {
      label: `Reference`,
      value: contributionConfiguration?.paymentReference,
    },
    {
      label: `First Payment`,
      value: firstPaymentDate ? format(new Date(firstPaymentDate), 'do MMMM') : '',
    },
    {
      label: `Future Payments`,
      value: firstPaymentDate ? `On the ${formatOrdinalDay(new Date(firstPaymentDate))}` : '',
    },
  ]

  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={banksError ? refetchBanks : statusError ? refetchStatus : useOpenBanking ? doAuthPayment : () => {}}
      errorCancel={onDismiss}
      isLoading={isLoading}
      loadingMessage={recurringPaymentLoading ? ['Connecting to your bank...'] : processing ? ['Waiting for authorisation...'] : undefined}
      buttonTitle={useOpenBanking ? paymentAuthError ? 'Try Again' : 'Proceed' : 'Next'}
      buttonAction={useOpenBanking ? doAuthPayment : doManualPayment}
      allowTextButton={useOpenBanking && (paymentAuthError || status?.testMode)}
      textButtonAction={doManualPayment}
      textButtonTitle={`Setup Manually Instead`}
      enableButton={!recurringPaymentLoading}
      headline={useOpenBanking
        ? `Ok, we're ready to set that up for you`
        : `Ok, we need to do this manually`
      }
      subHeading={useOpenBanking
        ? `We'll connect to ${contributionBank?.name || 'your bank'} to authorize the standing order`
        : `Unfortunately ${contributionBank?.name || 'your bank'} doesn't support the electronic setup of a regular contribution`
      }
      subHeadingInfo={useOpenBanking ? undefined : <NamedInformationButton name={NamedInformation.OPEN_BANKING_SUPPORT} buttonTitle={'Why not?'} />}
      footerInfo={useOpenBanking ? <MoneyHubInfo /> : undefined}
      showButton={true}
    >
      {
        contributionBank ? <BankLink bank={contributionBank} isError={paymentAuthError} /> : <></>
      }
      {
        useOpenBanking ?
        <View style={{ paddingVertical: Sizing.x10 }}>
          <UnborderedTable
            itemContainerStyle={{
              paddingVertical: Sizing.x5,
            }}
            data={standingOrderData?.map(item => {
              return {
                ...item,
                value: <Text style={{ fontWeight: '900' }}>{item.value}</Text>,
              }
            })}
          />
        </View>
        : <>
            <Subheading style={{textAlign: 'left'}}>{`But don't worry...`}</Subheading>
            <BulletItem style={{textAlign: 'left'}}>{`This just means that you'll need to use your banking app/website to setup a standing order.`}</BulletItem>
            <BulletItem style={{textAlign: 'left'}}>{`On the next screen, we'll provide you with all the details to create the standing order manually with ${contributionBank?.name || 'your bank'}.`}</BulletItem>
            <BulletItem style={{textAlign: 'left'}}>{`Please ensure the standing order details exactly match.`}</BulletItem>
          </>
      }
      
    </ModalProcessScreen>
  )
}
